import Avokado, {
    AvokadoAPIError,
    MenuAssignmentDelete,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { MenuAssignment } from '../models/MenuAssignment'

type DeleteMenuAssignmentParams = MenuAssignment.ID

export interface DeleteMenuAssignmentAction {
    run(params: DeleteMenuAssignmentParams): Promise<void>
}

@injectable()
export class DeleteMenuAssignmentActionLive
    implements DeleteMenuAssignmentAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: DeleteMenuAssignmentParams): Promise<void> {
        const request: MenuAssignmentDelete.Request = params
        await this.avokado.menuAssignment.delete(request)
    }
}

@injectable()
export class DeleteMenuAssignmentActionFake
    implements DeleteMenuAssignmentAction
{
    async run(params: DeleteMenuAssignmentParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class DeleteMenuAssignmentActionFail
    implements DeleteMenuAssignmentAction
{
    async run(params: DeleteMenuAssignmentParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
