import {
    FormControl,
    HStack,
    Tag,
    TagCloseButton,
    Text,
    VStack,
} from '@chakra-ui/react'
import { describeCategory as describe } from 'features/location/mappers/category/InternalMapper'
import { Location } from 'features/location/models/Location'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSection } from 'uikit/form'
import { AutocompletePicker } from 'uikit/form/input'

interface SubcategoriesSectionProps {
    category: Location.Category | undefined
    subcategories: Location.Category[]
    setSubcategories: Dispatch<SetStateAction<Location.Category[]>>
}

export function SubcategoriesSection({
    category,
    subcategories,
    setSubcategories,
}: SubcategoriesSectionProps) {
    const { t } = useTranslation()

    const availableCategories: number[] = useMemo(() => {
        return Object.keys(Location.Category)
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x))
            .filter((x) => category !== x)
            .filter((x) => !subcategories.includes(x))
    }, [category, subcategories])

    const onPick = useCallback((value?: number) => {
        if (value !== undefined) {
            setSubcategories((previous) =>
                [...previous, value].sort((x, y) => x - y)
            )
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const remove = useCallback((value: number) => {
        setSubcategories((previous) => previous.filter((x) => x !== value))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormSection
            title={t('Subcategories')}
            subtitle={t(
                'Additional categories that can be used to describe your venue.'
            )}
        >
            <FormControl>
                <VStack>
                    <AutocompletePicker
                        options={availableCategories}
                        onPick={onPick}
                        optionMapper={(option) => t(describe(option))}
                        placeholder={
                            subcategories.length === 0
                                ? t('Add')
                                : t('Add another')
                        }
                        AvailableRenderer={({ option }) => (
                            <Text>{t(describe(option))}</Text>
                        )}
                    />
                    <HStack
                        wrap="wrap"
                        align="start"
                        w="full"
                        gap={2}
                        spacing={0}
                    >
                        {subcategories.map((subcategory) => (
                            <Tag key={subcategory}>
                                <Text>{t(describe(subcategory))}</Text>
                                <TagCloseButton
                                    onClick={() => remove(subcategory)}
                                />
                            </Tag>
                        ))}
                    </HStack>
                </VStack>
            </FormControl>
        </FormSection>
    )
}
