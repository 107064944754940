import { Font, generate } from '@pdfme/generator'
import { PDFDocument, PageSizes, degrees, rgb } from 'pdf-lib'
import { useState } from 'react'
import { sleep } from 'utils/asyncawait/sleep'
import { parseHexValue } from 'utils/color/parseHexValue'
import { retrieveTemplateValues } from '../mappers/InternalMapper'
import { Codes } from '../models/Codes'
import schema from '../templates/schema.json'

const { android, appclip, ios, location, qrcode, subtitle, table, title } =
    schema

async function loadFont(
    type: 'Regular' | 'Thin' | 'Light' | 'Medium' | 'SemiBold'
) {
    return await fetch(`/fonts/Barlow/Barlow-${type}.ttf`).then((res) =>
        res.arrayBuffer()
    )
}

interface UseGenerateCodesHook {
    isProcessing: boolean
    process: (
        outputName: string,
        inputGenerator: () => Promise<Codes[]>
    ) => Promise<void>
}

export function useGenerateCodes(): UseGenerateCodesHook {
    const [isProcessing, setIsProcessing] = useState(false)

    async function generateBase(backgroundColor: string): Promise<string> {
        // TODO: consider setting pdf title / author / subject / creator / keywords / producer
        const pdf = await PDFDocument.create()
        const page = pdf.addPage()
        const width = PageSizes.A6[1]
        const height = PageSizes.A6[0]
        page.setSize(width, height)
        page.setRotation(degrees(90))

        // TODO: consider adding dynamic / custom border
        const { r, g, b, a } = parseHexValue(backgroundColor)
        page.drawRectangle({
            x: 0,
            y: 0,
            width: width,
            height: height,
            color: rgb(r, g, b),
            opacity: a,
            borderWidth: 24,
            borderColor: rgb(1, 0.27, 0),
        })

        const logoBytes = await fetch('/logo.png').then((res) =>
            res.arrayBuffer()
        )
        const logo = await pdf.embedPng(logoBytes)
        page.drawImage(logo, {
            x: 16,
            y: height - 23 - 73,
            width: 73,
            height: 73,
        })

        return 'data:application/pdf;base64,' + (await pdf.saveAsBase64())
    }

    async function process(
        outputName: string,
        inputGenerator: () => Promise<Codes[]>
    ) {
        if (isProcessing) return
        setIsProcessing(true)

        // ensure that the ui is updated before starting to generate the pdf
        await sleep(100)

        const templateValues = await retrieveTemplateValues()
        const base = await generateBase(templateValues.backgroundColor)

        const template = {
            schemas: [
                {
                    title: { ...title, fontColor: templateValues.textColor },
                    subtitle: {
                        ...subtitle,
                        fontColor: templateValues.textColor,
                    },
                    location: {
                        ...location,
                        fontColor: templateValues.locationNameColor,
                    },
                    table: { ...table, fontColor: templateValues.textColor },
                    ios: { ...ios, fontColor: templateValues.textColor },
                    android: {
                        ...android,
                        fontColor: templateValues.textColor,
                    },
                    appclip,
                    qrcode,
                },
            ],
            basePdf: base,
        }
        const inputs = await inputGenerator()

        const regular = await loadFont('Regular')
        const thin = await loadFont('Thin')
        const light = await loadFont('Light')
        const medium = await loadFont('Medium')
        const semiBold = await loadFont('SemiBold')
        const font: Font = {
            regular: { data: regular, fallback: true },
            thin: { data: thin },
            light: { data: light },
            medium: { data: medium },
            semibold: { data: semiBold },
        }
        const options = { font }

        // TODO: change name to pdf instead of default UUID
        // @ts-ignore
        const pdf = await generate({ template, inputs, options })
        const blob = new Blob([pdf.buffer], { type: 'application/pdf' })

        if (navigator.share && navigator.canShare()) {
            const pdfFile = new File([blob], outputName, {
                type: 'application/pdf',
            })
            navigator.share({
                text: 'Avokado Codes',
                files: [pdfFile],
                title: outputName,
            })
        } else {
            window.open(URL.createObjectURL(blob), '_blank')
        }

        setIsProcessing(false)
    }

    return { isProcessing, process }
}
