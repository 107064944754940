import { Dispatch, SetStateAction, useState } from 'react'

export const NewOrganizationStructureStep = 0

export interface UseNewOrganizationStructureStepFormDataHook {
    structure: 'company' | 'individual' | undefined
    setStructure: Dispatch<SetStateAction<'company' | 'individual' | undefined>>
}

export function useNewOrganizationStructureStepFormData(): UseNewOrganizationStructureStepFormDataHook {
    const [structure, setStructure] = useState<'company' | 'individual'>()

    return { structure, setStructure }
}
