import {
    Button,
    Divider,
    Heading,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    OrderedList,
    Text,
    UnorderedList,
    VStack,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Trans } from 'react-i18next'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

interface TerminalOnboardingContentProps {
    isOpen: boolean
    onClose: () => void
}

export function TerminalOnboardingContent({
    isOpen,
    onClose,
}: TerminalOnboardingContentProps) {
    const t = useT('terminal')
    const screen = useScreenBreakpoint()
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={screen === 'mobile' ? 'full' : '6xl'}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader boxShadow={screen === 'mobile' ? 'md' : undefined}>
                    <Heading>{t('onboarding_title')}</Heading>
                </ModalHeader>
                <ModalBody>
                    <VStack w="full" align="start" spacing={6}>
                        <Heading size="sm">
                            {t('onboarding_turn_device_into_pos_solution')}
                        </Heading>
                        <Divider />
                        <Text fontWeight="semibold">
                            {t('onboarding_what_is_terminal_account_title')}
                        </Text>
                        <Text>
                            {t('onboarding_what_is_terminal_account_desc')}
                        </Text>
                        <Text fontWeight="semibold">
                            {t('onboarding_how_many_and_which_title')}
                        </Text>
                        <Text>{t('onboarding_how_many_and_which_desc')}</Text>
                        <UnorderedList>
                            <ListItem>
                                <Trans
                                    t={t}
                                    i18nKey="onboarding_kitchen_monitor_suggestion"
                                />
                            </ListItem>
                            <ListItem>
                                <Trans
                                    t={t}
                                    i18nKey="onboarding_pizzeria_monitor_suggestion"
                                />
                            </ListItem>
                            <ListItem>
                                <Trans
                                    t={t}
                                    i18nKey="onboarding_bar_counter_register_suggestion"
                                />
                            </ListItem>
                        </UnorderedList>
                        <Text>{t('onboarding_choose_names_suggestion')}</Text>
                        <Text fontWeight="semibold">
                            {t(
                                'onboarding_difference_between_terminal_types_title'
                            )}
                        </Text>
                        <OrderedList>
                            <ListItem>
                                <Trans
                                    t={t}
                                    i18nKey="onboarding_monitor_description"
                                />
                            </ListItem>
                            <ListItem>
                                <Trans
                                    t={t}
                                    i18nKey="onboarding_register_description"
                                />
                            </ListItem>
                        </OrderedList>
                        <Text fontWeight="semibold">
                            {t('onboarding_tips_title')}
                        </Text>
                        <UnorderedList>
                            <ListItem>
                                <Trans
                                    t={t}
                                    i18nKey="onboarding_devices_suggestion"
                                />
                            </ListItem>
                            <ListItem>
                                <Trans
                                    t={t}
                                    i18nKey="onboarding_customize_suggestion"
                                />
                            </ListItem>
                        </UnorderedList>
                        <Text fontWeight="semibold">
                            {t('onboarding_ready_to_setup_title')}
                        </Text>
                        <Text>{t('onboarding_setup_steps_description')}</Text>
                    </VStack>
                </ModalBody>
                <ModalFooter
                    boxShadow={
                        screen === 'mobile'
                            ? '0 -2px 5px rgba(0, 0, 0, 0.1)'
                            : undefined
                    }
                >
                    <Button onClick={onClose}>
                        {t('onboarding_lets_get_started_button')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
