import Avokado, {
    AvokadoAPIError,
    OrganizationCreate,
} from '@avokadoapp/avokado-ts'
import { Address } from 'features/address/models/Address'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapOrganizationToModel } from '../mappers/SDKMapper'
import { Organization } from '../models/Organization'

export type CreateOrganizationParams =
    | CompanyCreateOrganizationParams
    | IndividualCreateOrganizationParams

interface CompanyCreateOrganizationParams {
    structure: 'company'
    name: string
    locale: string
    timeZone: string
    address: Address
    taxId: string
}

interface IndividualCreateOrganizationParams {
    structure: 'individual'
    name: string
    locale: string
    timeZone: string
    address: Address
}

export interface CreateOrganizationAction {
    run(params: CreateOrganizationParams): Promise<Organization>
}

@injectable()
export class CreateOrganizationActionLive implements CreateOrganizationAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateOrganizationParams): Promise<Organization> {
        const request: OrganizationCreate.Request = {
            name: params.name,
            locale: params.locale,
            timeZone: params.timeZone,
            billingAddress: params.address,
            structure: params.structure,
            taxId: 'taxId' in params ? params.taxId : undefined,
            onboard: false,
        }
        const response = await this.avokado.organization.create(request)
        this.avokado.resetSession()
        return mapOrganizationToModel(response, 'admin')
    }
}

@injectable()
export class CreateOrganizationActionFake implements CreateOrganizationAction {
    async run(params: CreateOrganizationParams): Promise<Organization> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class CreateOrganizationActionFail implements CreateOrganizationAction {
    async run(params: CreateOrganizationParams): Promise<Organization> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
