import Avokado, {
    AvokadoAPIError,
    MenuEntryUpdate,
} from '@avokadoapp/avokado-ts'
import {
    mapAllergensToModel,
    mapFeaturesToModel,
} from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import _ from 'lodash'
import { sleep } from 'utils/asyncawait/sleep'

interface UpdateMenuEntryParams {
    menu: Menu
    section: Menu.Section
    entry: Menu.Section.Entry
    description?: string
    price?: number
    extrasIds?: number[]
    allergens?: Menu.Section.Entry.Allergen[]
    features?: Menu.Section.Entry.Feature[]
}

export interface UpdateMenuEntryAction {
    run(params: UpdateMenuEntryParams): Promise<Menu>
}

@injectable()
export class UpdateMenuEntryActionLive implements UpdateMenuEntryAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateMenuEntryParams): Promise<Menu> {
        const { menu, section, entry } = params
        const request: MenuEntryUpdate.Request = {
            organizationId: entry.id.organizationId,
            menuId: entry.id.menuId,
            menuSectionId: entry.id.sectionId,
            menuEntryId: entry.id.entryId,
            description: _.isEqual(params.description, entry.description)
                ? undefined
                : params.description,
            price: _.isEqual(params.price, entry.price)
                ? undefined
                : params.price,
            extrasIds: _.isEqual(
                params.extrasIds,
                entry.extras.map((extra) => extra.id.extraId)
            )
                ? undefined
                : params.extrasIds,
            allergens: _.isEqual(params.allergens, entry.allergens)
                ? undefined
                : _.sum(params.allergens),
            features: _.isEqual(params.features, entry.features)
                ? undefined
                : _.sum(params.features),
        }
        const response = await this.avokado.menuEntry.update(request)
        return {
            ...menu,
            sections: menu.sections.map((otherSection) => {
                if (_.isEqual(otherSection.id, section.id)) {
                    return {
                        ...section,
                        entries: section.entries.map((otherEntry) => {
                            if (_.isEqual(otherEntry.id, entry.id)) {
                                return {
                                    ...entry,
                                    description: response.description,
                                    price: response.price,
                                    allergens: mapAllergensToModel(
                                        response.allergens
                                    ),
                                    features: mapFeaturesToModel(
                                        response.features
                                    ),
                                    extras: response.extrasIds
                                        .map((extraId) =>
                                            menu.extras.get(extraId)
                                        )
                                        .filter(
                                            (extra): extra is Menu.Extra =>
                                                extra !== undefined
                                        ),
                                }
                            } else {
                                return otherEntry
                            }
                        }),
                    }
                } else {
                    return otherSection
                }
            }),
        }
    }
}

@injectable()
export class UpdateMenuEntryActionFake implements UpdateMenuEntryAction {
    async run(params: UpdateMenuEntryParams): Promise<Menu> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateMenuEntryActionFail implements UpdateMenuEntryAction {
    async run(params: UpdateMenuEntryParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
