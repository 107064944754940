import useAuthContext from 'features/auth/contexts/AuthContext'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorPage } from './ErrorPage'

export default function PageNotFound() {
    const { user } = useAuthContext()
    const { organization } = useOrganizationContext()
    const navigate = useNavigate()

    const home = useMemo(() => {
        if (!user) return '/'
        if (!organization) return '/organizations'
        return `/organizations/${organization.id.organizationId}`
    }, [user, organization])

    return (
        <ErrorPage
            title="Page not found."
            subTitle="The requested resource could not be found. Please make sure that the entered URL is correct."
            primaryActionLabel="Take me home"
            onPrimaryAction={() => navigate(home)}
        />
    )
}
