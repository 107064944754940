import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Collapse,
    Grid,
    Heading,
    Icon,
    Input,
    Text,
    VStack,
    createIcon,
    useColorModeValue,
} from '@chakra-ui/react'
import { Location } from 'features/location/models/Location'
import useT from 'localization/hooks/useT'
import { FaTv } from 'react-icons/fa'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import useFormNotification from 'utils/hooks/useFormNotification'
import {
    useTerminalFormController,
    useTerminalFormData,
} from '../hooks/useTerminalForm'
import { Terminal } from '../models/Terminal'

const KitchenSet = createIcon({
    displayName: 'KitchenSet',
    viewBox: '0 0 576 512',
    d: 'M240 144a96 96 0 10-192 0 96 96 0 10192 0zm44.4 32c-14.5 64.1-71.9 112-140.4 112C64.5 288 0 223.5 0 144S64.5 0 144 0c68.5 0 125.9 47.9 140.4 112h71.8c8.8-9.8 21.6-16 35.8-16h104c26.5 0 48 21.5 48 48s-21.5 48-48 48H392c-14.2 0-27-6.2-35.8-16h-71.8zM144 80a64 64 0 110 128 64 64 0 110-128zm256 160c13.3 0 24 10.7 24 24v8h96c13.3 0 24 10.7 24 24s-10.7 24-24 24H280c-13.3 0-24-10.7-24-24s10.7-24 24-24h96v-8c0-13.3 10.7-24 24-24zM288 464V352h224v112c0 26.5-21.5 48-48 48H336c-26.5 0-48-21.5-48-48zM48 320h128c26.5 0 48 21.5 48 48s-21.5 48-48 48h-16c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32v-80c0-8.8 7.2-16 16-16zm128 64c8.8 0 16-7.2 16-16s-7.2-16-16-16h-16v32h16zM24 464h176c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24z',
})

interface TerminalFormProps {
    location: Location
    terminals: Terminal[]
    dismiss: () => void
    onSave: (terminal: Terminal) => void
}

export function TerminalForm({
    location,
    terminals,
    onSave,
}: TerminalFormProps) {
    const t = useT('terminal')
    const formData = useTerminalFormData()
    const controller = useTerminalFormController(
        location.id,
        terminals,
        formData,
        onSave
    )
    const screen = useScreenBreakpoint()
    const borderColor = useColorModeValue(
        '--chakra-colors-gray-300',
        '--chakra-colors-gray-500'
    )

    useFormNotification('terminal-create', controller)

    const size = screen === 'mobile' ? 70 : 100

    return (
        <>
            <VStack mt={4}>
                <Heading size="sm" alignSelf="start" mt={8} mb={2}>
                    {t('create_type_label')}
                </Heading>

                <Grid
                    templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    w="full"
                    gap={6}
                >
                    <Box
                        tabIndex={0}
                        flex={1}
                        height="2xs"
                        onClick={() => formData.setIsMonitor(false)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === 'Space')
                                formData.setIsMonitor(false)
                        }}
                        borderRadius="lg"
                        border={`1px solid var(${borderColor})`}
                        transition="box-shadow 0.2s ease-in-out"
                        boxShadow={
                            !formData.isMonitor
                                ? '0 0 5px #FFa550, 0 0 5px #FFa550, 0 0 5px #FFa550, 0 0 10px #FFa550'
                                : undefined
                        }
                    >
                        <VStack
                            width="full"
                            p={4}
                            justify="center"
                            h="full"
                            spacing={4}
                        >
                            <Heading size="md">
                                {t('create_register_title')}
                            </Heading>
                            <Icon w={`${size}px`} h={`${size}px`} as={FaTv} />
                            <Text textAlign="center" w="full">
                                {t('create_register_description')}
                            </Text>
                        </VStack>
                    </Box>

                    <Box
                        tabIndex={0}
                        flex={1}
                        height="2xs"
                        onClick={() => formData.setIsMonitor(true)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === 'Space')
                                formData.setIsMonitor(true)
                        }}
                        borderRadius="lg"
                        border={`1px solid var(${borderColor})`}
                        transition="box-shadow 0.2s ease-in-out"
                        boxShadow={
                            formData.isMonitor
                                ? '0 0 5px #FFa550, 0 0 5px #FFa550, 0 0 5px #FFa550, 0 0 10px #FFa550'
                                : undefined
                        }
                    >
                        <VStack
                            width="full"
                            p={4}
                            justify="center"
                            h="full"
                            spacing={4}
                        >
                            <Heading size="md">
                                {t('create_monitor_title')}
                            </Heading>
                            <Icon
                                w={`${size}px`}
                                h={`${size}px`}
                                as={KitchenSet}
                            />
                            <Text textAlign="center" w="full">
                                {t('create_monitor_description')}
                            </Text>
                        </VStack>
                    </Box>
                </Grid>

                <Heading size="sm" alignSelf="start" mt={8}>
                    {t('create_name_label')}
                </Heading>

                <Grid
                    templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    w="full"
                    gap={6}
                >
                    <Text>{t('create_name_description')}</Text>
                    <Input
                        isReadOnly={controller.result === 'success'}
                        type="text"
                        placeholder={
                            formData.isMonitor
                                ? t('create_name_monitor_placeholder')
                                : t('create_name_register_placeholder')
                        }
                        maxLength={20}
                        borderRadius="full"
                        value={formData.name}
                        onChange={(e) => formData.setName(e.target.value)}
                    />
                </Grid>
                <Box
                    as={Collapse}
                    w="full"
                    in={controller.result !== undefined}
                >
                    <Alert status={controller.result} borderRadius={8}>
                        <AlertIcon />
                        {screen === 'mobile' && (
                            <Box w="full">
                                <AlertTitle>
                                    {controller.result === 'success'
                                        ? t('create_alert_success_title')
                                        : t('create_alert_error_title')}
                                </AlertTitle>
                                <AlertDescription>
                                    {controller.result === 'success'
                                        ? t('create_alert_success_description')
                                        : t('create_alert_error_description')}
                                </AlertDescription>
                            </Box>
                        )}
                        {screen !== 'mobile' && (
                            <>
                                <AlertTitle>
                                    {controller.result === 'success'
                                        ? t('create_alert_success_title')
                                        : t('create_alert_error_title')}
                                </AlertTitle>
                                <AlertDescription>
                                    {controller.result === 'success'
                                        ? t('create_alert_success_description')
                                        : t('create_alert_error_description')}
                                </AlertDescription>
                            </>
                        )}
                    </Alert>
                </Box>
                <Box
                    as={Collapse}
                    w="full"
                    in={
                        controller.nameIsAlreadyInUse &&
                        controller.result !== 'success'
                    }
                >
                    <Alert status="warning" borderRadius={8}>
                        <AlertIcon />
                        {screen === 'mobile' && (
                            <Box w="full">
                                <AlertTitle>
                                    {t(
                                        'create_alert_name_already_in_use_warning_title'
                                    )}
                                </AlertTitle>
                                <AlertDescription>
                                    {t(
                                        'create_alert_name_already_in_use_warning_description'
                                    )}
                                </AlertDescription>
                            </Box>
                        )}
                        {screen !== 'mobile' && (
                            <>
                                <AlertTitle>
                                    {t(
                                        'create_alert_name_already_in_use_warning_title'
                                    )}
                                </AlertTitle>
                                <AlertDescription>
                                    {t(
                                        'create_alert_name_already_in_use_warning_description'
                                    )}
                                </AlertDescription>
                            </>
                        )}
                    </Alert>
                </Box>
                {controller.result === 'success' && (
                    <Button alignSelf="end" mt={8} onClick={controller.reset}>
                        {t('add_another_button')}
                    </Button>
                )}
                {controller.result !== 'success' && (
                    <Button
                        alignSelf="end"
                        mt={8}
                        isLoading={controller.isProcessing}
                        isDisabled={!controller.canSubmit}
                        onClick={controller.submit}
                    >
                        {controller.result === 'error'
                            ? t('try_again_button')
                            : t('create_button')}
                    </Button>
                )}
            </VStack>

            {/* <FormStructure>
                <FormSection
                    title="Nome"
                    subtitle='Dai un nome al tuo terminale come "Cassa" o
                            "Cucina". Lo vedi solo tu e ti serve per
                            riconoscerli.'
                >
                    <FormControl>
                        <Input
                            type="text"
                            placeholder="Name"
                            maxLength={20}
                            value={formData.name}
                            onChange={(e) => formData.setName(e.target.value)}
                        />
                        <Text fontSize="xs" color="label2" textAlign="end">
                            {formData.name.length}/20
                        </Text>
                    </FormControl>
                </FormSection>

                <FormSection
                    title="Type"
                    subtitle="Che tipologia di terminale vuoi creare?"
                >
                    <FormControl>
                        <RadioGroup
                            onChange={(value) => {
                                formData.setIsMonitor(value === 'monitor')
                            }}
                            value={formData.isMonitor ? 'monitor' : 'register'}
                        >
                            <HStack spacing={0} gap={8}>
                                <Radio value="register">Register</Radio>
                                <Radio value="monitor">Monitor</Radio>
                            </HStack>
                        </RadioGroup>
                    </FormControl>
                </FormSection>

                <GridItem colSpan={3} display="flex" justifyContent="end">
                    <ButtonGroup>
                        {terminals.length > 0 && (
                            <Button onClick={dismiss}>Cancel</Button>
                        )}
                        <Button
                            isLoading={controller.isProcessing}
                            isDisabled={!controller.canSubmit}
                            onClick={controller.submit}
                        >
                            Submit
                        </Button>
                    </ButtonGroup>
                </GridItem>
            </FormStructure> */}
        </>
    )
}
