import * as yup from 'yup'

export const passwordValidation = yup.object().shape({
    password: yup
        .string()
        .required('password_required_field_error')
        .min(8, 'password_too_short_error')
        .max(99, 'password_too_long_error')
        .matches(/[0-9]/, 'password_missing_required_character_type_error')
        .matches(/[a-z]/, 'password_missing_required_character_type_error')
        .matches(/[A-Z]/, 'password_missing_required_character_type_error'),
})

export const emailValidation = yup.object().shape({
    email: yup
        .string()
        .email()
        .required('email_required_field_error')
        .matches(/^[^+]*$/, 'email_invalid_character_+_error'),
})
