import { Geometry } from './Geometry'

export function overlap(g1: Geometry.Shape, g2: Geometry.Shape): boolean {
    if (g1.type === 'circle' && g2.type === 'circle') {
        return (
            squaredDistance(g1.center, g2.center) <=
            Math.pow(g1.radius + g2.radius, 2)
        )
    } else if (g1.type === 'circle' && g2.type === 'rectangle') {
        const x = Math.max(leading(g2), Math.min(g1.center.x, trailing(g2)))
        const y = Math.max(top(g2), Math.min(g1.center.y, bottom(g2)))
        const n = { x, y }
        return squaredDistance(g1.center, n) <= g1.radius * g1.radius
    } else if (g1.type === 'rectangle' && g2.type === 'circle') {
        return overlap(g2, g1)
    } else if (g1.type === 'rectangle' && g2.type === 'rectangle') {
        return !(
            leading(g1) > trailing(g2) ||
            trailing(g1) < leading(g2) ||
            top(g1) > bottom(g2) ||
            bottom(g1) < top(g2)
        )
    } else {
        return false
    }
}

function squaredDistance(p1: Geometry.Point, p2: Geometry.Point): number {
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2)
}

function leading(rect: Geometry.Shape.Rectangle) {
    return rect.center.x - rect.size.width / 2
}

function top(rect: Geometry.Shape.Rectangle) {
    return rect.center.y - rect.size.height / 2
}

function trailing(rect: Geometry.Shape.Rectangle) {
    return rect.center.x + rect.size.width / 2
}

function bottom(rect: Geometry.Shape.Rectangle) {
    return rect.center.y + rect.size.height / 2
}
