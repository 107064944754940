import {
    ArchivedOrderSessionList,
    ArchivedOrderSessionRetrieve,
} from '@avokadoapp/avokado-ts'
import { Receipt } from 'features/finance/models/Receipt'
import { ReceiptCompact } from 'features/finance/models/ReceiptCompact'
import { MenuStore } from 'features/menu/dependencies/menu'
import { Menu } from 'features/menu/models/Menu'
import _ from 'lodash'

export function mapReceiptsToModel(
    payload: ArchivedOrderSessionList.Response
): ReceiptCompact[] {
    return payload.receipts.map((receipt) => {
        return {
            id: {
                organizationId: receipt.organizationId,
                locationId: receipt.locationId,
                receiptId: receipt.id,
            },
            issuingDate: receipt.issuingDate,
        }
    })
}

export function mapReceiptToModel(
    payload: ArchivedOrderSessionRetrieve.Response,
    menuStore: MenuStore
): Receipt {
    const orders = mapOrdersToModels(payload.orders, menuStore)
    const tip = payload.tips.reduce(
        (previous, current) => previous + current.amount,
        0
    )

    const coverCharge =
        payload.coverCharges.length === 0
            ? undefined
            : {
                  price: payload.coverCharges[0].price,
                  amount: payload.coverCharges.length,
              }

    const prices = _.concat(
        payload.orders.flatMap((order) => [
            { [order.id]: order.value },
            ...order.parts.map((part) => ({ [part.id]: part.value })),
        ]),
        payload.tips.map((tip) => ({ [tip.id]: tip.amount })),
        payload.coverCharges.map((coverCharge) => ({
            [coverCharge.id]: coverCharge.price,
        }))
    ).reduce((previous, current) => ({ ...previous, ...current }), {})

    return {
        id: {
            organizationId: payload.orderSession.organizationId,
            locationId: payload.orderSession.locationId,
            receiptId: payload.id,
        },
        currency: payload.currency,
        issuingDate: payload.issuingDate,
        totalAmount:
            tip +
            (coverCharge ? coverCharge.amount * coverCharge.price : 0) +
            orders.reduce(
                (previous, current) =>
                    current.isCanceled ? previous : previous + current.price,
                0
            ),
        tip: tip === 0 ? undefined : tip,
        coverCharge: coverCharge,
        orders: orders,
        payments: mapPaymentsToModels(payload, orders, prices),
    }
}

export function mapOrdersToModels(
    payload: ArchivedOrderSessionRetrieve.Response.Order[],
    menuStore: MenuStore
): Receipt.Order[] {
    return payload.map((order) => {
        const menu = menuStore.retrieve(order.menuId)!
        // TODO: if menu or entry was deleted, use future API to retrieve it
        const entry = menu?.sections
            .find(({ id }) => id.sectionId === order.sectionId)
            ?.entries.find(({ id }) => id.entryId === order.entryId)?.name

        const options = order.selectedOptions
            .flatMap((selected) =>
                menu.extras
                    .get(selected.extraId)
                    ?.options.filter((option) =>
                        selected.selectedOptionsIds.includes(option.id.optionId)
                    )
            )
            // TODO: if extra or option was deleted, use future API to retrieve it
            .filter(
                (option): option is Menu.Extra.Option => option !== undefined
            )
            .map((option) => option.name)

        return {
            id: order.id,
            price: order.value,
            isPartial: false,
            entryName: entry,
            optionNames: options,
            note: order.note,
            isCanceled: order.status.state === 'canceled',
        }
    })
}

export function mapPaymentsToModels(
    payload: ArchivedOrderSessionRetrieve.Response,
    allOrders: Receipt.Order[],
    priceMap: { [key: number]: number }
): Receipt.Payment[] {
    const coverChargeIds = payload.coverCharges.map(
        (coverCharge) => coverCharge.id
    )
    const ordersMap = payload.orders
        .flatMap((rawOrder) => {
            const mapped = allOrders.find((order) => order.id === rawOrder.id)!
            const base = { [rawOrder.id]: mapped }
            const parts = rawOrder.parts.map((part) => ({
                [part.id]: { ...mapped, isPartial: true, price: part.value },
            }))
            return [base, ...parts]
        })
        .reduce((previous, current) => ({ ...previous, ...current }), {})

    return payload.paymentSessions
        .filter((payment) => payment.status !== 'initialized')
        .map((payment) => {
            let coverCharge: Receipt.CoverCharge | undefined
            if (payload.coverCharges.length > 0) {
                const total = payment.itemsIds.filter((id) =>
                    coverChargeIds.includes(id)
                ).length
                if (total > 0) {
                    coverCharge = {
                        price: payload.coverCharges[0].price,
                        amount: total,
                    }
                }
            }

            const tip = payment.itemsIds
                .map(
                    (id) =>
                        payload.tips.find((tip) => tip.id === id)?.amount ?? 0
                )
                .reduce((previous, current) => previous + current, 0)

            const orders = payment.itemsIds
                .map((id) => ordersMap[id])
                .filter((order): order is Receipt.Order => order !== undefined)

            return {
                id: payment.id,
                method: payment.method,
                externalMethod: payment.externalMethod,
                tip: tip === 0 ? undefined : tip,
                coverCharge: coverCharge,
                orders: orders,
                totalAmount: payment.itemsIds.reduce(
                    (previous, current) => previous + (priceMap[current] ?? 0),
                    0
                ),
            }
        })
}
