import { LocationMediaLinksCreate } from '@avokadoapp/avokado-ts'

export function mapExtensionToLocationFormat(
    extension: string
): LocationMediaLinksCreate.Request.Format {
    switch (extension) {
        case 'image/jpeg':
            return 'jpg'
        case 'image/jpg':
            return 'jpg'
        case 'image/png':
            return 'png'

        default:
            throw new Error(`Unsupported extension: ${extension}`)
    }
}
