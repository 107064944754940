import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Collapse,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Kbd,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import { Location } from 'features/location/models/Location'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { Trans } from 'react-i18next'
import {
    useCardReaderFormController,
    useCardReaderFormData,
} from '../hooks/useCardReaderForm'
import { CardReader } from '../models/CardReader'

interface CardReaderConnectProps {
    location: Location
    readers: CardReader[]
    dismiss: () => void
    onCardReaderConnected: () => void
}

export function CardReaderConnect({
    location,
    readers,
    dismiss,
    onCardReaderConnected,
}: CardReaderConnectProps) {
    const t = useT('cardreader')
    const presentHelpModal = useDisclosure()

    const formData = useCardReaderFormData()
    const controller = useCardReaderFormController(
        location.id,
        readers,
        formData
    )

    return (
        <>
            <Modal
                isOpen={presentHelpModal.isOpen}
                onClose={presentHelpModal.onClose}
                isCentered
                size="4xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {t('connect_new_card_reader_help_modal_title')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Trans
                            t={t}
                            i18nKey="connect_new_card_reader_help_modal_description"
                            components={[
                                <Kbd />,
                                <Button
                                    variant="link"
                                    as={Link}
                                    href="mailto:support@avokadoapp.ch"
                                ></Button>,
                            ]}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={presentHelpModal.onClose}>
                            {t(
                                'connect_new_card_reader_help_modal_close_button'
                            )}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <VStack
                flex={1}
                align="flex-start"
                justify="center"
                as={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
            >
                <Heading>{t('connect_new_card_reader_title')}</Heading>
                {readers.length === 0 && (
                    <Text fontSize="sm">
                        {t('connect_new_card_reader_description')}
                    </Text>
                )}
                <VStack align="flex-star" w="full" spacing={0} mt={8}>
                    <FormControl
                        isReadOnly={controller.hasSucceeded}
                        isDisabled={controller.isProcessing}
                    >
                        <FormLabel>
                            {t('connect_new_card_reader_name_label')}
                        </FormLabel>
                        <Input
                            borderRadius="full"
                            type="text"
                            placeholder={t(
                                'connect_new_card_reader_name_placeholder'
                            )}
                            maxLength={20}
                            value={formData.label}
                            onChange={(e) => formData.setLabel(e.target.value)}
                            w="full"
                        />
                    </FormControl>
                </VStack>
                <VStack align="flex-star" w="full" spacing={0} mt={4}>
                    <FormControl
                        isReadOnly={controller.hasSucceeded}
                        isDisabled={controller.isProcessing}
                    >
                        <FormLabel>
                            {t(
                                'connect_new_card_reader_registration_code_label'
                            )}
                        </FormLabel>
                        <Input
                            borderRadius="full"
                            type="text"
                            placeholder={t(
                                'connect_new_card_reader_registration_code_placeholder'
                            )}
                            value={formData.registrationCode}
                            onChange={(e) =>
                                formData.setRegistrationCode(e.target.value)
                            }
                            w="full"
                        />
                    </FormControl>

                    <Box
                        as={Collapse}
                        alignSelf="end"
                        in={!controller.hasSucceeded}
                    >
                        <Text fontSize="smaller">
                            {t('connect_new_card_reader_help_label')}{' '}
                            <Button
                                variant="link"
                                alignSelf="flex-end"
                                fontSize="sm"
                                onClick={presentHelpModal.onOpen}
                            >
                                {t('connect_new_card_reader_help_button')}
                            </Button>
                        </Text>
                    </Box>
                </VStack>

                <Box in={controller.hasSucceeded} as={Collapse} w="full">
                    <Alert status="success" w="full" borderRadius={8} mt={4}>
                        <AlertIcon />
                        <Box w="full">
                            <AlertTitle>
                                {t('connect_new_card_reader_success_title')}
                            </AlertTitle>
                            <AlertDescription>
                                {t(
                                    'connect_new_card_reader_success_description',
                                    {
                                        locationName: location.name,
                                    }
                                )}
                            </AlertDescription>
                        </Box>
                    </Alert>
                </Box>

                <Box in={controller.isUsingUsedLabel} as={Collapse} w="full">
                    <Alert status="warning" w="full" borderRadius={8} mt={4}>
                        <AlertIcon />
                        <Box w="full">
                            <AlertTitle>
                                {t('connect_new_card_used_labels_title')}
                            </AlertTitle>
                            <AlertDescription>
                                {t('connect_new_card_used_labels_description', {
                                    locationName: location.name,
                                })}
                            </AlertDescription>
                        </Box>
                    </Alert>
                </Box>

                <Box in={controller.error !== undefined} as={Collapse} w="full">
                    <Alert status="error" w="full" borderRadius={8} mt={4}>
                        <AlertIcon />
                        {controller.error !== undefined && (
                            <Box w="full">
                                <AlertTitle>
                                    {t(
                                        `connect_new_card_reader_${controller.error?.replace(
                                            '-',
                                            '_'
                                        )}_error_title`
                                    )}
                                </AlertTitle>
                                <AlertDescription>
                                    {t(
                                        `connect_new_card_reader_${controller.error?.replace(
                                            '-',
                                            '_'
                                        )}_error_description`
                                    )}
                                </AlertDescription>
                            </Box>
                        )}
                    </Alert>
                </Box>

                <ButtonGroup alignSelf="flex-end" mt={8}>
                    {readers.length === 0 && !controller.hasSucceeded && (
                        <Button variant="outline" onClick={dismiss}>
                            {t('connect_new_card_reader_cancel_button')}
                        </Button>
                    )}

                    {controller.hasSucceeded && (
                        <Button onClick={onCardReaderConnected}>
                            {t('connect_new_card_reader_close_button')}
                        </Button>
                    )}
                    {!controller.hasSucceeded && (
                        <Button
                            isLoading={controller.isProcessing}
                            isDisabled={
                                !controller.canSubmit ||
                                controller.error === 'missing-location'
                            }
                            onClick={controller.submit}
                        >
                            {t('connect_new_card_reader_connect_button')}
                        </Button>
                    )}
                </ButtonGroup>
            </VStack>
        </>
    )
}
