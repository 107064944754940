import { Location } from 'features/location/models/Location'

export function mapCategoryToModel(
    payload?: number
): Location.Category | undefined {
    if (payload === undefined) {
        return undefined
    }

    for (const key in Location.Category) {
        const value = Location.Category[key]
        if (typeof value === 'number' && value !== 0 && payload === value) {
            return value
        }
    }

    return undefined
}

export function mapSubcategoriesToModel(payload?: number): Location.Category[] {
    if (payload === undefined) {
        return []
    }

    const subcategories: Location.Category[] = []

    for (const key in Location.Category) {
        const value = Location.Category[key]
        if (
            typeof value === 'number' &&
            value !== 0 &&
            (payload & value) === value
        ) {
            subcategories.push(value)
        }
    }

    return subcategories
}
