import { Credentials } from '@aws-sdk/client-cognito-identity'
import { createContext, useContext } from 'react'

interface _AddressContext {
    retrieveAWSCredentials(): Promise<Credentials>
}

const defaultAddressContext: _AddressContext = {
    retrieveAWSCredentials() {
        throw new Error('Context not initialized')
    },
}

export const AddressContext = createContext(defaultAddressContext)
export default function useAddressContext() {
    return useContext(AddressContext)
}
