import { Location } from 'features/location/models/Location'
import _ from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

export interface UseDeliveryPolicyFormDataHook {
    isEnabled: boolean
    hasChanges: boolean
    canSubmit: boolean
    policy: Location.Policy.Delivery | undefined
    freeDeliveryThreshold: number | undefined
    minimumRequiredAmount: number | undefined
    upfrontPaymentThreshold: number | undefined
    setIsEnabled: Dispatch<SetStateAction<boolean>>
    setFreeDeliveryThreshold: Dispatch<SetStateAction<number | undefined>>
    setMinimumRequiredAmount: Dispatch<SetStateAction<number | undefined>>
    setUpfrontPaymentThreshold: Dispatch<SetStateAction<number | undefined>>
}

export function useDeliveryPolicyFormData(
    delivery?: Location.Policy.Delivery
): UseDeliveryPolicyFormDataHook {
    const [isEnabled, setIsEnabled] = useState(delivery !== undefined)

    const [freeDeliveryThreshold, setFreeDeliveryThreshold] = useState(
        delivery?.freeDeliveryThreshold
    )
    const [minimumRequiredAmount, setMinimumRequiredAmount] = useState(
        delivery?.minimumRequiredAmount
    )
    const [upfrontPaymentThreshold, setUpfrontPaymentThreshold] = useState(
        delivery?.upfrontPaymentThreshold
    )

    const policy: Location.Policy.Delivery | undefined = useMemo(() => {
        return isEnabled
            ? {
                  freeDeliveryThreshold,
                  minimumRequiredAmount,
                  upfrontPaymentThreshold,
              }
            : undefined
    }, [
        isEnabled,
        freeDeliveryThreshold,
        minimumRequiredAmount,
        upfrontPaymentThreshold,
    ])

    const hasChanges = useMemo(
        () => !_.isEqual(policy, delivery),
        [policy, delivery]
    )

    // TODO: use new destination APIs
    const canSubmit = true

    return {
        isEnabled,
        hasChanges,
        canSubmit,
        policy,

        freeDeliveryThreshold,
        minimumRequiredAmount,
        upfrontPaymentThreshold,
        setIsEnabled,

        setFreeDeliveryThreshold,
        setMinimumRequiredAmount,
        setUpfrontPaymentThreshold,
    }
}
