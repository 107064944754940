import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Button, VStack, useDisclosure } from '@chakra-ui/react'
import { LocationTabProps } from 'features/location/pages/LocationPage'
import useMenusContext from 'features/menu/contexts/MenusContext'
import LoadingPage from 'infra/navigation/pages/LoadingPage'
import useT from 'localization/hooks/useT'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import MenuAssignmentsCard from './MenuAssignmentCard'
import MenuAssignmentCreationModal from './MenuAssignmentCreationModal'

export function MenuAssignmentTab({
    headerRef,
    location,
    update,
}: LocationTabProps) {
    const t = useT('menuassignment')
    const menusManager = useMenusContext()
    const newMenuAssignmentModal = useDisclosure()

    if (menusManager.isLoading) {
        return <LoadingPage />
    }

    if (menusManager.menus.length === 0) {
        return (
            <Container
                py={8}
                title={t('no_menu_found_title')}
                subtitle={t('no_menu_found_title_subtitle', {
                    locationName: location.name,
                })}
                header={
                    <Button
                        as={Link}
                        to={`/organizations/${location.id.organizationId}/menus/new`}
                        leftIcon={<ExternalLinkIcon />}
                    >
                        {t('no_menu_found_show_me_button')}
                    </Button>
                }
            />
        )
    }

    return (
        <>
            {location.menuAssignments.length === 0 ? (
                <Container
                    py={8}
                    title={t('no_menus_assigned_title', {
                        locationName: location.name,
                    })}
                    subtitle={t('no_menus_assigned_description')}
                    header={
                        <Button onClick={newMenuAssignmentModal.onOpen}>
                            {t('no_menus_assigned_button')}
                        </Button>
                    }
                />
            ) : (
                <VStack w="full" gap={8}>
                    <Container
                        title={t('add_menus_assigned_title', {
                            locationName: location.name,
                        })}
                        subtitle={t('no_menus_assigned_description')}
                        header={
                            <Button onClick={newMenuAssignmentModal.onOpen}>
                                {t('add_menus_assigned_button')}
                            </Button>
                        }
                    />

                    {location.menuAssignments.map((menuAssignment) => (
                        <MenuAssignmentsCard
                            key={menuAssignment.id.menuAssignmentId}
                            menuAssignment={menuAssignment}
                            location={location}
                            onMenuAssignmentUpdated={(updatedAssignment) => {
                                update({
                                    ...location,
                                    menuAssignments:
                                        location.menuAssignments.map(
                                            (assignment) =>
                                                _.isEqual(
                                                    assignment.id,
                                                    updatedAssignment.id
                                                )
                                                    ? updatedAssignment
                                                    : assignment
                                        ),
                                })
                            }}
                        />
                    ))}
                </VStack>
            )}

            <MenuAssignmentCreationModal
                newMenuAssignmentModal={newMenuAssignmentModal}
                location={location}
                onCreated={(newMenuAssignment) => {
                    update({
                        ...location,
                        menuAssignments: [
                            ...location.menuAssignments,
                            newMenuAssignment,
                        ],
                    })
                }}
            />
        </>
    )
}
