import {
    Alert,
    AlertDescription,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Card,
    CardHeader,
    FormControl,
    Grid,
    GridItem,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import useNewMenuTranslationController from 'features/menu/hooks/useNewMenuTranslationController'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import { useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { useListFormatter } from 'utils/types/formatters'

const supportedLanguages = [
    {
        flagCode: 'GB',
        languageCode: 'en',
        localizationKey: 'language_en',
    },
    {
        flagCode: 'IT',
        languageCode: 'it',
        localizationKey: 'language_it',
    },
    {
        flagCode: 'DE',
        languageCode: 'de',
        localizationKey: 'language_de',
    },
    {
        flagCode: 'FR',
        languageCode: 'fr',
        localizationKey: 'language_fr',
    },
    {
        flagCode: 'ES',
        languageCode: 'es',
        localizationKey: 'language_es',
    },
    {
        flagCode: 'JP',
        languageCode: 'ja',
        localizationKey: 'language_ja',
    },
    {
        flagCode: 'KR',
        languageCode: 'ko',
        localizationKey: 'language_ko',
    },
    {
        flagCode: 'CN', // Assuming Simplified Chinese
        languageCode: 'zh',
        localizationKey: 'language_zh',
    },
    {
        flagCode: 'NO',
        languageCode: 'no',
        localizationKey: 'language_no',
    },
    {
        flagCode: 'SE',
        languageCode: 'sv',
        localizationKey: 'language_sv',
    },
    {
        flagCode: 'RU',
        languageCode: 'ru',
        localizationKey: 'language_ru',
    },
    {
        flagCode: 'HI',
        languageCode: 'hi',
        localizationKey: 'language_hi',
    },
]

const flagsByCode = {
    en: '🇬🇧',
    it: '🇮🇹',
    de: '🇩🇪',
    fr: '🇫🇷',
    es: '🇪🇸',
    ja: '🇯🇵',
    ko: '🇰🇷',
    zh: '🇨🇳',
    no: '🇳🇴',
    sv: '🇸🇪',
    ru: '🇷🇺',
    pt: '🇵🇹',
    nl: '🇳🇱',
    hi: '🇮🇳',
}

interface NewTranslationModalProps {
    menu: Menu
    isOpen: boolean
    onClose: () => void
    onSuccess: (menu: Menu) => void
}
export default function NewTranslationModal({
    menu,
    isOpen,
    onClose,
    onSuccess,
}: NewTranslationModalProps) {
    const t = useT('menutranslation')
    const controller = useNewMenuTranslationController({
        menu,
        onComplete: onSuccess,
    })

    const [searchInput, setSearchInput] = useState('')
    const confirmationDialogState = useDisclosure()
    const cancelRef = useRef(null)

    const { i18n } = useTranslation()
    const screen = useScreenBreakpoint()

    const languages = useMemo(() => {
        return supportedLanguages
            .filter(
                (l) =>
                    !menu.availableTranslations.find(
                        (t) => t.locale === l.languageCode
                    )
            )
            .map((l) => ({
                ...l,
                localizedName: t(l.localizationKey),
                isSelected: controller.selectedLanguages.has(l.languageCode),
            }))
            .filter((l) =>
                l.localizedName
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
            )
            .sort((l1, l2) => (l1.localizedName < l2.localizedName ? -1 : 1))
    }, [searchInput, i18n.resolvedLanguage, controller.selectedLanguages])

    const listFormatter = useListFormatter()

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                size="4xl"
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent minH={screen === 'mobile' ? 'full' : 'lg'}>
                    <ModalHeader>
                        <HStack w="full" justify="space-between">
                            <Text>{t('new_translation_modal_title')}</Text>
                            <FormControl w="sm">
                                <Input
                                    type="text"
                                    placeholder={t('search_placeholder')}
                                    value={searchInput}
                                    maxLength={50}
                                    onChange={(evt) =>
                                        setSearchInput(evt.target.value)
                                    }
                                />
                            </FormControl>
                        </HStack>
                    </ModalHeader>

                    <ModalBody>
                        {languages.length === 0 && (
                            <Alert borderRadius="md">
                                <AlertIcon />
                                <Box>
                                    <AlertTitle>
                                        {t('no_language_available_title')}
                                    </AlertTitle>
                                    <AlertDescription>
                                        {t('no_language_available_description')}
                                    </AlertDescription>
                                </Box>
                            </Alert>
                        )}
                        <Grid
                            templateColumns={{
                                base: `repeat(1, 1fr)`,
                                md: `repeat(3, 1fr)`,
                            }}
                            gap={6}
                            w="full"
                        >
                            {languages.map((l) => (
                                <GridItem key={l.languageCode}>
                                    <Card
                                        borderRadius={8}
                                        border={
                                            l.isSelected
                                                ? '0.5px solid var(--chakra-colors-accent)'
                                                : '0.5px solid transparent'
                                        }
                                        bg={
                                            l.isSelected
                                                ? 'sheetBackground3'
                                                : 'sheetBackground2'
                                        }
                                        onClick={() =>
                                            controller.toggle(l.languageCode)
                                        }
                                    >
                                        <CardHeader p={1} ps={2}>
                                            <HStack>
                                                <Text fontSize="4xl">
                                                    {
                                                        // @ts-ignore
                                                        flagsByCode[
                                                            l.languageCode
                                                        ]
                                                    }
                                                </Text>
                                                <Text fontSize="lg">
                                                    {l.localizedName}
                                                </Text>
                                            </HStack>
                                        </CardHeader>
                                    </Card>
                                </GridItem>
                            ))}
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            {t('generic_cancel_button')}
                        </Button>
                        <Button
                            isDisabled={controller.selectedLanguages.size === 0}
                            onClick={confirmationDialogState.onOpen}
                            isLoading={controller.isProcessing}
                        >
                            {controller.selectedLanguages.size > 0
                                ? t('confirm_new_translations_button', {
                                      count: controller.selectedLanguages.size,
                                  })
                                : t('confirm_new_translations_button_disabled')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <AlertDialog
                isOpen={confirmationDialogState.isOpen}
                leastDestructiveRef={cancelRef}
                onClose={confirmationDialogState.onClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('new_translations_dialog_title')}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Trans
                                t={t}
                                i18nKey="new_translations_dialog_description"
                                values={{
                                    count: controller.selectedLanguages.size,
                                    languages: listFormatter.format(
                                        languages
                                            .filter((l) => l.isSelected)
                                            .map((l) => l.localizedName)
                                    ),
                                    menuName: menu.name,
                                }}
                            />
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={confirmationDialogState.onClose}
                                variant="ghost"
                            >
                                {t('generic_cancel_button')}
                            </Button>
                            <Button
                                onClick={() => {
                                    confirmationDialogState.onClose()
                                    controller.submit()
                                }}
                                ml={3}
                            >
                                {t('generic_confirm_button')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
