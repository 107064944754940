import Constants from 'utils/constant/Constants'
import { IdTokenPayload } from '../dependencies/RetrieveUserAction'
import { User } from '../models/User'

export function mapUserToModel(token: IdTokenPayload): User {
    return {
        id: token.sub,
        givenName: token.given_name,
        familyName: token.family_name,
        avatar: token.picture
            ? `https://media.${Constants.Host}/large/U/${token.picture}`
            : undefined,
        email: token.email,
        availableFederatedIdentities: extractFederatedIdentities(token),
    }
}

function extractFederatedIdentities(token: IdTokenPayload) {
    var result: ('apple' | 'google')[] = []
    if (token['custom:apple_id']) {
        result.push('apple')
    }
    if (token['custom:google_id']) {
        result.push('google')
    }
    return result
}
