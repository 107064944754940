import { MenuDependencies } from 'features/menu/dependencies/menu'
import { Menu } from 'features/menu/models/Menu'
import { dependencyContainer } from 'infra/di/inversify.config'
import { Dispatch, SetStateAction, useState } from 'react'
import { cleanUpString } from 'utils/types'

export const NewMenuGeneralStep = 0

export interface UseNewMenuGeneralStepFormDataHook {
    name: string
    description: string
    setName: Dispatch<SetStateAction<string>>
    setDescription: Dispatch<SetStateAction<string>>
}

export function useNewMenuGeneralStepFormData(): UseNewMenuGeneralStepFormDataHook {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    return { name, description, setName, setDescription }
}

export async function handleGeneralStep(
    organizationId: string,
    data: UseNewMenuGeneralStepFormDataHook,
    menu?: Menu
): Promise<Menu> {
    const create = dependencyContainer.get(MenuDependencies.Create)
    const update = dependencyContainer.get(MenuDependencies.Update)

    const name = cleanUpString(data.name)
    const description = cleanUpString(data.description)

    if (!menu) {
        return await create.run({ organizationId, name, description })
    } else if (menu.name === name && (menu.description ?? '') === description) {
        return menu
    } else {
        return await update.run({ menu, name, description })
    }
}
