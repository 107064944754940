import Avokado, {
    AvokadoAPIError,
    TranslationBundleList,
} from '@avokadoapp/avokado-ts'
import TranslationBundle from 'features/menu/models/TranslationBundle'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

type ListTranslationBundlesParams = Organization.ID

export interface ListTranslationBundlesAction {
    run(params: ListTranslationBundlesParams): Promise<TranslationBundle[]>
}

@injectable()
export class ListTranslationBundlesActionLive
    implements ListTranslationBundlesAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(
        params: ListTranslationBundlesParams
    ): Promise<TranslationBundle[]> {
        const request: TranslationBundleList.Request = params
        const response = await this.avokado.translationBundle.list(request)
        return response.bundles
    }
}

@injectable()
export class ListTranslationBundlesActionFake
    implements ListTranslationBundlesAction
{
    async run(
        params: ListTranslationBundlesParams
    ): Promise<TranslationBundle[]> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ListTranslationBundlesActionFail
    implements ListTranslationBundlesAction
{
    async run(
        params: ListTranslationBundlesParams
    ): Promise<TranslationBundle[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
