const LocalStorageKeys = {
    AccessToken: 'AccessToken',
    IdToken: 'IdToken',
    FutureUsageIdToken: 'FutureUsageIdToken',
    RefreshToken: 'RefreshToken',
    CodesTemplate: 'CodesTemplate',

    HideLocationGalleryAlertHelper: 'HideLocationGalleryAlertHelper',
    HideLocationRoomsAlertHelper: 'HideLocationRoomsAlertHelper',
    HideLocationTerminalAlertHelper: 'HideLocationTerminalAlertHelper',
    HideLocationPolicyAlertHelper: 'HideLocationPolicyAlertHelper',

    HasSentCardRequestRequest: 'HasSentCardRequestRequest',
}

export default LocalStorageKeys
