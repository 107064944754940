import { interfaces } from 'inversify'
import { CreateRoomAction } from './CreateRoomAction'
import { CreateTableRoomAction } from './CreateTableRoomAction'
import { DeleteTableRoomAction } from './DeleteTableRoomAction'
import { ListRoomAction } from './ListRoomAction'
import { UpdateRoomAction } from './UpdateRoomAction'
import { UpdateTableRoomAction } from './UpdateTableRoomAction'

interface _RoomDependencies {
    List: interfaces.ServiceIdentifier<ListRoomAction>
    Create: interfaces.ServiceIdentifier<CreateRoomAction>
    Update: interfaces.ServiceIdentifier<UpdateRoomAction>
    CreateTable: interfaces.ServiceIdentifier<CreateTableRoomAction>
    UpdateTable: interfaces.ServiceIdentifier<UpdateTableRoomAction>
    DeleteTable: interfaces.ServiceIdentifier<DeleteTableRoomAction>
}

export const RoomDependencies: _RoomDependencies = {
    List: Symbol('ListRoomAction'),
    Create: Symbol('CreateRoomAction'),
    Update: Symbol('UpdateRoomAction'),
    CreateTable: Symbol('CreateTableRoomAction'),
    UpdateTable: Symbol('UpdateTableRoomAction'),
    DeleteTable: Symbol('DeleteTableRoomAction'),
}

export type {
    CreateRoomAction,
    CreateTableRoomAction,
    ListRoomAction,
    UpdateRoomAction,
    UpdateTableRoomAction,
}
