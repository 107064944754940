import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { emailValidation, passwordValidation } from 'utils/form/validation'
import useAuthContext from '../contexts/AuthContext'

interface UseSignUpFormDataHook {
    givenName: string
    familyName: string
    email: string
    password: string
    setGivenName: Dispatch<SetStateAction<string>>
    setFamilyName: Dispatch<SetStateAction<string>>
    setEmail: Dispatch<SetStateAction<string>>
    setPassword: Dispatch<SetStateAction<string>>
}

export function useSignUpFormData(): UseSignUpFormDataHook {
    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    return {
        givenName,
        familyName,
        email,
        password,
        setGivenName,
        setFamilyName,
        setEmail,
        setPassword,
    }
}

export function useSignUpFormController(
    params: UseSignUpFormDataHook
): UseFormControllerHook<void> {
    const { signUp } = useAuthContext()

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        if (!emailValidation.isValidSync({ email: params.email })) {
            return false
        }

        if (!passwordValidation.isValidSync({ password: params.password })) {
            return false
        }

        return true
    }, [params.email, params.password])

    async function submit() {
        if (isProcessing) return

        setIsProcessing(true)
        try {
            const locale = navigator.language || 'en_GB'
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            await signUp(params.email, params.password, locale, timeZone)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
