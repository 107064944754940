import { Namespace } from 'localization/i18n'
import { RefObject, useMemo } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { Step as StepModel } from '.'
import { GuidedActionDesktop } from './GuidedActionDesktop'
import { GuidedActionMobile } from './GuidedActionMobile'

export interface StepPropsWithRef {
    headerRef: RefObject<HTMLDivElement>
}

interface GuidedActionProps<Context> {
    namespace?: Namespace
    context: Context
    steps: StepModel<Context>[]
    index: number
    isProcessing: boolean
    isDisabled: boolean
    hideControls?: boolean
    hideSkip: boolean
    allowScroll?: boolean
    completion:
        | { modelLink: string; fallbackLink?: string }
        | { label: string; handler: () => void }
    onNext: () => void
    onBack: () => void
    onSkip: (amount: number) => void
}

export function GuidedAction<Context>(props: GuidedActionProps<Context>) {
    const { steps, index } = props
    const currentStep = useMemo(() => {
        return steps[index]
    }, [index]) // eslint-disable-line react-hooks/exhaustive-deps

    const screen = useScreenBreakpoint()

    if (screen === 'tablet' || screen === 'mobile') {
        return <GuidedActionMobile {...props} currentStep={currentStep} />
    } else {
        return <GuidedActionDesktop {...props} currentStep={currentStep} />
    }
}
