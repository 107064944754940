import {
    Button,
    ButtonGroup,
    FormControl,
    GridItem,
    Input,
    TabPanel,
    TabPanels,
} from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FormDivider, FormSection, FormStructure } from 'uikit/form'
import { Scaffold } from 'uikit/scaffold'

import useT from 'localization/hooks/useT'
import { TabEntry } from 'uikit/scaffold/types'
import { LoadingIndicator } from 'utils/loading'
import { MenuContent } from '../components/MenuContent'
import { MenuHeading } from '../components/MenuHeading'
import {
    useNewSectionFormController,
    useNewSectionFormData,
} from '../hooks/new/useNewSectionForm'
import { useMenu } from '../hooks/useMenu'
import { TranslationTab } from './tabs/TranslationTab'

export default function MenuPage() {
    const { menuId } = useParams()
    const { menu, update } = useMenu(menuId)

    if (!menu) {
        return <LoadingIndicator />
    }

    return <MenuPageContent menu={menu} update={update} />
}

export interface MenuPageContentProps {
    menu: Menu
    update: (menu: Menu) => void
}

interface MenuTabEntry extends TabEntry {
    content: (props: MenuPageContentProps) => JSX.Element
}

function MenuPageContent(props: MenuPageContentProps) {
    const t = useT('menu')

    const tabs: MenuTabEntry[] = [
        {
            id: 'general',
            shouldPresentBadge: false,
            name: t('tab_general'),
            content: MenuHeading,
        },
        {
            id: 'entries',
            shouldPresentBadge: false,
            name: t('tab_entries'),
            content: MenuContent,
        },
        {
            id: 'translation',
            shouldPresentBadge: false,
            name: t('tab_menu_translation'),
            content: TranslationTab,
        },
    ]

    return (
        <>
            <Scaffold tabs={tabs} title={props.menu.name}>
                <TabPanels>
                    {tabs.map((tab) => (
                        <TabPanel key={tab.id} p={0}>
                            <tab.content {...props} />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Scaffold>

            {/* <Container py={8} isCompact>
                <MenuHeading {...props} />
            </Container>

            {props.menu.sections.length === 0 ? (
                <Container pb={8} title={'Create first section'} isCompact>
                    <NewSectionForm {...props} />
                </Container>
            ) : (
                <Container pb={8} isCompact>
                    <MenuContent {...props} />
                </Container>
            )} */}
        </>
    )
}

function NewSectionForm({ menu, update }: MenuPageContentProps) {
    const { t } = useTranslation()

    const formData = useNewSectionFormData()
    const controller = useNewSectionFormController(menu, formData, update)

    return (
        <FormStructure>
            <FormSection title={t('Name')} subtitle={'' /* TODO */}>
                <FormControl>
                    <Input
                        type="text"
                        placeholder={t('Name')}
                        maxLength={50}
                        value={formData.name}
                        onChange={(e) => formData.setName(e.target.value)}
                    />
                </FormControl>
            </FormSection>

            <FormDivider />

            <FormSection title={t('Description')} subtitle={'' /* TODO */}>
                <FormControl>
                    <Input
                        type="text"
                        placeholder={t('Description')}
                        maxLength={250}
                        value={formData.description}
                        onChange={(e) =>
                            formData.setDescription(e.target.value)
                        }
                    />
                </FormControl>
            </FormSection>

            <GridItem colSpan={3} display="flex" justifyContent="end">
                <ButtonGroup>
                    <Button
                        isDisabled={!controller.canSubmit}
                        isLoading={controller.isProcessing}
                        onClick={controller.submit}
                    >
                        {t('Create')}
                    </Button>
                </ButtonGroup>
            </GridItem>
        </FormStructure>
    )
}
