import { useSteps } from '@chakra-ui/react'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useOrganizationContext from '../contexts/OrganizationContext'
import { OrganizationDependencies } from '../dependencies'
import { CreateOrganizationParams } from '../dependencies/CreateOrganizationAction'
import { Organization } from '../models/Organization'
import { newOrganizationSteps } from '../pages/NewOrganizationPage'
import {
    NewOrganizationBillingStep,
    UseNewOrganizationBillingStepFormDataHook,
    useNewOrganizationBillingStepFormData,
} from './useNewOrganizationBillingStep'
import {
    NewOrganizationStructureStep,
    UseNewOrganizationStructureStepFormDataHook,
    useNewOrganizationStructureStepFormData,
} from './useNewOrganizationStructureStep'

export interface UseNewOrganizationFormDataHook {
    structure: UseNewOrganizationStructureStepFormDataHook
    billing: UseNewOrganizationBillingStepFormDataHook
}

export function useNewOrganizationFormData(): UseNewOrganizationFormDataHook {
    const structure = useNewOrganizationStructureStepFormData()
    const billing = useNewOrganizationBillingStepFormData()

    return { structure, billing }
}

interface UseNewOrganizationFormControllerHook
    extends UseFormControllerHook<void> {
    activeStep: number
    hideControls: boolean
    hideSkip: boolean

    organization: Organization | undefined

    goBack: () => void
    skip: (by: number) => void
}

export function useNewOrganizationFormController(
    params: UseNewOrganizationFormDataHook
): UseNewOrganizationFormControllerHook {
    // Dependencies
    const create = useInjection(OrganizationDependencies.Create)

    // Manager
    const organizationManager = useOrganizationContext()

    // Navigation
    const navigate = useNavigate()

    // Form data
    const { structure, billing } = params

    // State
    const [organization, setOrganization] = useState<Organization>()
    const [isProcessing, setIsProcessing] = useState(false)

    const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: newOrganizationSteps.length,
    })

    // Button status
    const canSubmit = useMemo(() => {
        switch (activeStep) {
            case NewOrganizationBillingStep:
                if (billing.name === '') return false
                // TODO: validate name:
                // leading space, trailing space, adjacen spaces
                // at least two words

                if (!billing.address) return false
                if (billing.address.street === '') return false
                if (billing.address.municipality === '') return false
                if (billing.address.postalCode === '') return false
                if (billing.address.region === '') return false
                if (billing.address.subregion === '') return false
                if (billing.address.country === '') return false
                if (billing.address.addressNumber === '') return false

                if (structure.structure === 'company') {
                    if (
                        billing.address.country === 'IT' &&
                        !/^\d{11}$/.test(billing.taxId)
                    )
                        return false

                    if (
                        billing.address.country === 'CH' &&
                        !/^\d{3}\.\d{3}\.\d{3}$/.test(billing.taxId)
                    )
                        return false
                }
                break
            default:
                break
        }

        return true
    }, [activeStep, structure, billing])

    const hideControls = useMemo(() => {
        switch (activeStep) {
            case NewOrganizationStructureStep:
                return true
            default:
                break
        }

        return false
    }, [activeStep])

    const hideSkip = useMemo(() => {
        switch (activeStep) {
            default:
                break
        }

        return false
    }, [activeStep])

    // Submit
    useEffect(() => {
        if (structure.structure && activeStep === 0) {
            goToNext()
        }
    }, [structure.structure]) // eslint-disable-line react-hooks/exhaustive-deps

    async function submit() {
        if (activeStep < newOrganizationSteps.length - 1) {
            goToNext()
            return
        }

        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const data: CreateOrganizationParams =
                structure.structure === 'company'
                    ? {
                          structure: 'company',
                          name: billing.name,
                          locale: billing.locale,
                          timeZone: billing.timeZone,
                          address: billing.address!,
                          taxId:
                              billing.address?.country === 'IT'
                                  ? `IT${billing.taxId}`
                                  : `CHE-${billing.taxId} ${billing.swissTaxIdSuffix}`,
                      }
                    : {
                          structure: 'individual',
                          name: billing.name,
                          locale: billing.locale,
                          timeZone: billing.timeZone,
                          address: billing.address!,
                      }
            const organization = await create.run(data)
            organizationManager.add(organization)
            setOrganization(organization)
            navigate(`/organizations/${organization.id.organizationId}`)
        } finally {
            setIsProcessing(false)
        }
    }

    function skip(by: number) {
        if (by <= 0 || activeStep + by > newOrganizationSteps.length) {
            throw new Error('Invalid step count')
        }

        setActiveStep(activeStep + by)
    }

    return {
        activeStep,
        canSubmit,
        hideControls,
        hideSkip,
        isProcessing,
        organization: _.cloneDeep(organization),
        submit,
        goBack: goToPrevious,
        skip,
    }
}
