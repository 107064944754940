import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { useConst, useSteps } from '@chakra-ui/react'
import useLocationsContext from 'features/location/contexts/LocationsContext'
import { Location } from 'features/location/models/Location'
import { newLocationSteps } from 'features/location/pages/NewLocationPage'
import { useNotification } from 'features/notification/hooks/useNotification'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { cleanUpString } from 'utils/types'
import {
    NewLocationGeneralStep,
    UseNewLocationGeneralStepFormDataHook,
    handleGeneralStep,
    useNewLocationGeneralStepFormData,
} from './useNewLocationGeneralStep'

export interface UseNewLocationFormDataHook {
    general: UseNewLocationGeneralStepFormDataHook
}

export function useNewLocationFormData(): UseNewLocationFormDataHook {
    const general = useNewLocationGeneralStepFormData()

    return { general }
}

interface UseNewLocationFormControllerHook extends UseFormControllerHook<void> {
    activeStep: number
    hideControls: boolean
    hideSkip: boolean

    location?: Location

    goBack: () => void
    skip: (by: number) => void
}

export function useNewLocationFormController(
    params: UseNewLocationFormDataHook
): UseNewLocationFormControllerHook {
    // Notification
    const notification = useNotification()

    // Manager
    const organizationManager = useOrganizationContext()
    const locationsManager = useLocationsContext()

    const { organizationId } = useConst(
        () => organizationManager.assertOrganization().id
    )

    // Form data
    const { general } = params

    // State
    const [location, setLocation] = useState<Location>()
    const [isProcessing, setIsProcessing] = useState(false)

    const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: newLocationSteps.length,
    })

    //
    useEffect(() => {
        if (location) locationsManager.update(location)
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

    // Button status
    const canSubmit = useMemo(() => {
        switch (activeStep) {
            case NewLocationGeneralStep:
                const cleanedName = cleanUpString(general.name)
                if (cleanedName.length < 3) return false
                break
            default:
                break
        }

        return true
    }, [activeStep, general])

    const hideControls = useMemo(() => {
        switch (activeStep) {
            default:
                break
        }

        return false
    }, [activeStep])

    const hideSkip = useMemo(() => {
        switch (activeStep) {
            default:
                break
        }

        return false
    }, [activeStep])

    // Submit
    async function handleSubmit() {
        switch (activeStep) {
            case NewLocationGeneralStep:
                return await handleGeneralStep(
                    organizationId,
                    general,
                    location
                )
            default:
                break
        }
    }

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const newLocation = await handleSubmit()
            if (newLocation) {
                setLocation(newLocation)
            }
            goToNext()
        } catch (error: any) {
            let errorTitle = 'Something went wrong' // TODO
            let errorDescription = 'Please try again later' // TODO

            if (error instanceof AvokadoAPIError) {
                switch (error.category) {
                    case 'location.create.quota-limit-reached':
                        errorTitle = 'Limit reached' // TODO
                        errorDescription = 'Please contact us' // TODO
                        break

                    default:
                        break
                }
            }

            notification({
                id: 'new-location-submit',
                title: errorTitle,
                description: errorDescription,
                variant: 'error',
            })
        } finally {
            setIsProcessing(false)
        }
    }

    function skip(by: number) {
        if (by <= 0 || activeStep + by > newLocationSteps.length) {
            throw new Error('Invalid step count')
        }

        setActiveStep(activeStep + by)
    }

    return {
        activeStep,
        canSubmit,
        hideControls,
        hideSkip,
        isProcessing: isProcessing,
        location: _.cloneDeep(location),
        submit,
        goBack: goToPrevious,
        skip,
    }
}
