import { Avatar, Box, HStack, Heading, Text, VStack } from '@chakra-ui/react'
import useLocationsContext from 'features/location/contexts/LocationsContext'
import { AnimatePresence, motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { useMemo } from 'react'
import { FaQuestion } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

export function LocationsRecap() {
    const t = useT('home')

    const screen = useScreenBreakpoint()

    const locationsManager = useLocationsContext()

    const unpublishedLocations = useMemo(
        () =>
            locationsManager.locations.filter(
                (location) => !location.published
            ),
        [locationsManager.locations]
    )

    return (
        <AnimatePresence>
            {unpublishedLocations.length > 0 && (
                <Container
                    isCompact
                    mainContainer
                    title={t('unpublished_locations_title')}
                    subtitle={t('unpublished_locations_subtitle', {
                        count: unpublishedLocations.length,
                    })}
                >
                    <VStack gap={4} align="stretch">
                        {unpublishedLocations.map((location) => (
                            <Box
                                key={location.id.locationId}
                                as={motion.div}
                                p={2}
                                cursor="pointer"
                                bg="pageBackground3"
                                borderRadius="full"
                                boxShadow="md"
                                whileHover={{ scale: 1.02 }}
                                whileFocus={{ scale: 1.02 }}
                            >
                                <HStack
                                    as={Link}
                                    to={`locations/${location.id.locationId}?tab=publishment`}
                                    gap={screen === 'mobile' ? 2 : 8}
                                >
                                    <Avatar
                                        src={location.medias[0]?.small()}
                                        size="xl"
                                        icon={<FaQuestion />}
                                        bg="pageBackground2"
                                        color="label1"
                                    />

                                    <VStack
                                        flex={1}
                                        align="stretch"
                                        spacing={0}
                                    >
                                        <Heading size="lg">
                                            {location.name}
                                        </Heading>
                                        <Text color="label2">
                                            {t(
                                                'unpublished_location_card_message'
                                            )}
                                        </Text>
                                    </VStack>
                                </HStack>
                            </Box>
                        ))}
                    </VStack>
                </Container>
            )}
        </AnimatePresence>
    )
}
