import { useConst } from '@chakra-ui/react'
import { Currency } from 'features/finance/models/Currency'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function usePriceFormatter(
    forcedCurrency?: Currency,
    notation: 'standard' | 'compact' = 'standard'
) {
    const { i18n } = useTranslation()

    const organizationManager = useOrganizationContext()
    const currency = useConst(
        () =>
            forcedCurrency ?? organizationManager.assertOrganization().currency
    )

    const formatter = useMemo(() => {
        return new Intl.NumberFormat(i18n.language, {
            style: 'currency',
            currency: currency,
            notation: notation,
        })
    }, [i18n.language])

    const symbol = useConst(() => {
        switch (currency) {
            case 'eur':
                return '€'

            case 'chf':
                return 'CHF'
        }
    })

    const format = useCallback((value: number) => {
        return formatter.format(value / 100)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return { currency, symbol, format }
}
