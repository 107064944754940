import useT from 'localization/hooks/useT'
import { AppleLoginButton } from 'react-social-login-buttons'

interface SignInWithAppleProps {
    onClick: () => void
    variant: 'continue' | 'signin' | 'signup'
}

export function SignInWithAppleButton(props: SignInWithAppleProps) {
    const t = useT('auth')

    return (
        <AppleLoginButton
            align="center"
            text={t(`social_button_${props.variant}_apple`)}
            style={{
                width: 'var(--chakra-sizes-xs)',
                borderRadius: 99,
                border: '1px solid var(--chakra-colors-gray-300)',
                boxShadow: 'none',
                color: 'black',
            }}
            preventActiveStyles
            onClick={props.onClick}
        />
    )
}
