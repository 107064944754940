import Avokado, {
    AvokadoAPIError,
    LocationGeodecodeAddress,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapLocationToModel } from '../mappers/SDKResponseMapper'
import { Location } from '../models/Location'

interface GeodecodeAddressLocationParams {
    location: Location
    addressLabel: string
}

export interface GeodecodeAddressLocationAction {
    run(params: GeodecodeAddressLocationParams): Promise<Location>
}

@injectable()
export class GeodecodeAddressLocationActionLive
    implements GeodecodeAddressLocationAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: GeodecodeAddressLocationParams): Promise<Location> {
        const { location, addressLabel } = params
        const locationId = location.id
        const request: LocationGeodecodeAddress.Request = {
            organizationId: locationId.organizationId,
            locationId: locationId.locationId,
            addressLabel: addressLabel,
        }

        const response = await this.avokado.location.geodecodeAddress(request)
        return mapLocationToModel(response, location.menuAssignments)
    }
}

@injectable()
export class GeodecodeAddressLocationActionFake
    implements GeodecodeAddressLocationAction
{
    async run(params: GeodecodeAddressLocationParams): Promise<Location> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class GeodecodeAddressLocationActionFail
    implements GeodecodeAddressLocationAction
{
    async run(params: GeodecodeAddressLocationParams): Promise<Location> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
