import { LocationUpdate } from '@avokadoapp/avokado-ts'
import { Schedule } from 'features/calendar/models/Calendar'
import { Location } from 'features/location/models/Location'

type Day = keyof Schedule

export function mapModelToCalendar(
    calendar?: Location.Calendar
): LocationUpdate.Request.Calendar | undefined {
    if (calendar === undefined) {
        return undefined
    }

    const times = []
    for (const day in calendar.weekdays) {
        if (!calendar.weekdays.hasOwnProperty(day)) {
            continue
        }

        const schedule = calendar.weekdays[day as Day]
        if (schedule.type === 'full-day' && schedule.isActive) {
            times.push({
                weekDay: day as Day,
                opensAt: 0,
                closesAt: 0,
            })
        } else if (schedule.type === 'time-range') {
            times.push(
                ...schedule.timeRanges.map((range) => ({
                    weekDay: day as Day,
                    opensAt: range.from.hours * 60 + range.from.minutes,
                    closesAt: range.to.hours * 60 + range.to.minutes,
                }))
            )
        }
    }

    return {
        openingTimes: times,
        scheduledClosingTimes: calendar.scheduledClosures,
        timeZone: 'europeRome', // TODO
    }
}
