import { Place } from '@aws-sdk/client-location'
import { CloseIcon } from '@chakra-ui/icons'
import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiX } from 'react-icons/fi'
import { useAddressSearch } from '../hooks/useAddressSearch'
import { mapPlaceToModel } from '../mappers/AWSResponseMapper'
import { Address } from '../models/Address'

interface AddressSearchInputProps {
    variant?: 'outline' | 'flushed'
    label?: string
    onPick: (address: Address, label: string) => void
    onCancel?: () => void
    autoFocus?: boolean
}

export function AddressSearchInput({
    variant = 'outline',
    label,
    onPick,
    onCancel,
    autoFocus,
}: AddressSearchInputProps) {
    const { t } = useTranslation()

    const { canSearch, search } = useAddressSearch()

    const [query, setQuery] = useState('')
    const [isBouncing, setIsBouncing] = useState(false)
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [suggestions, setSuggestions] = useState<Place[]>([])

    const clear = useCallback(() => {
        setQuery('')
        setShowSuggestions(false)
        setSuggestions([])
    }, [])

    useEffect(() => {
        if (query.length < 3) {
            setIsBouncing(false)
            setShowSuggestions(false)
            setSuggestions([])
            return
        }

        setIsBouncing(true)

        const timeout = setTimeout(async () => {
            setSuggestions(await search(query))
            setIsBouncing(false)
            setShowSuggestions(true)
        }, 1000)

        return () => clearTimeout(timeout)
    }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <VStack w="full" position="relative">
            <FormControl>
                {label && <FormLabel>{label}</FormLabel>}
                <InputGroup>
                    <Input
                        autoFocus={autoFocus}
                        value={query}
                        type="text"
                        placeholder={t('Address')}
                        autoComplete="off"
                        transition="all .25s"
                        isDisabled={!canSearch}
                        variant={variant}
                        borderBottomRadius={
                            variant === 'flushed' || showSuggestions
                                ? 'none'
                                : 'md'
                        }
                        onChange={(e) => setQuery(e.target.value)}
                    />

                    <InputRightElement>
                        {isBouncing ? (
                            <Spinner size="sm" color="label1" borderWidth={1} />
                        ) : (
                            <IconButton
                                icon={<FiX />}
                                aria-label="Cancel"
                                size="xs"
                                variant="ghost"
                                colorScheme="gray"
                                borderRadius="full"
                                isDisabled={!canSearch || query.length === 0}
                                onClick={clear}
                            />
                        )}
                    </InputRightElement>
                </InputGroup>
            </FormControl>
            {onCancel && (
                <Button
                    onClick={onCancel}
                    variant="ghost"
                    alignSelf="end"
                    leftIcon={<CloseIcon />}
                >
                    {t('address_form_cancel_button')}
                </Button>
            )}

            {showSuggestions && (
                <VStack
                    bg="sheetBackground1"
                    borderWidth={1}
                    borderColor="primary"
                    w="full"
                    top={label ? '68px' : 10}
                    borderBottomRadius="md"
                    position="absolute"
                    spacing={0}
                    overflow="hidden"
                    zIndex={1}
                >
                    {suggestions.map((suggestion) => (
                        <HStack
                            key={suggestion.Label!}
                            w="full"
                            py={1}
                            px={3}
                            _hover={{ bg: 'sheetBackground2' }}
                            cursor="pointer"
                            onClick={() =>
                                onPick(
                                    mapPlaceToModel(suggestion),
                                    suggestion.Label ?? ''
                                )
                            }
                        >
                            <Text>{suggestion.Label!}</Text>
                        </HStack>
                    ))}
                    {suggestions.length === 0 && (
                        <HStack w="full" py={1} px={3}>
                            <Text color="label2">
                                {t('address_suggestions_no_results')}
                            </Text>
                        </HStack>
                    )}
                </VStack>
            )}
        </VStack>
    )
}
