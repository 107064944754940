import { Address } from 'features/address/models/Address'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'
import { LocationDependencies } from '../dependencies'
import { Location } from '../models/Location'

interface UseLocationAddressFormDataHook {
    address: Address | undefined
    addressLabel: string | undefined
    setAddress: Dispatch<SetStateAction<Address | undefined>>
    setAddressLabel: Dispatch<SetStateAction<string | undefined>>
}

export function useLocationAddressFormData(
    location: Location
): UseLocationAddressFormDataHook {
    const [address, setAddress] = useState(location.address)
    const [addressLabel, setAddressLabel] = useState<string>()

    return {
        address,
        setAddress,
        addressLabel,
        setAddressLabel,
    }
}

interface UseLocationFormDataHook {
    hasChanges: boolean
    name: string
    category: Location.Category | undefined
    subcategories: Location.Category[]
    capacity: number | undefined
    capabilities: Location.Capability[]
    setName: Dispatch<SetStateAction<string>>
    setCategory: Dispatch<SetStateAction<Location.Category | undefined>>
    setSubcategories: Dispatch<SetStateAction<Location.Category[]>>
    setCapacity: Dispatch<SetStateAction<number | undefined>>
    setCapabilities: Dispatch<SetStateAction<Location.Capability[]>>
}

export function useLocationFormData(
    location: Location
): UseLocationFormDataHook {
    const [name, setName] = useState(location.name)
    const [category, setCategory] = useState(location.category)
    const [subcategories, setSubcategories] = useState(location.subcategories)
    const [capacity, setCapacity] = useState(location.capacity)
    const [capabilities, setCapabilities] = useState(location.capabilities)

    const hasChanges = useMemo(() => {
        return (
            !_.isEqual(name, location.name) ||
            !_.isEqual(category, location.category) ||
            !_.isEqual(subcategories, location.subcategories) ||
            !_.isEqual(capacity, location.capacity) ||
            !_.isEqual(capabilities, location.capabilities)
        )
    }, [name, category, subcategories, capacity, capabilities, location])

    useEffect(() => {
        setSubcategories((previous) =>
            previous.filter((subcategory) => subcategory !== category)
        )
    }, [category])

    return {
        hasChanges,
        name,
        category,
        subcategories,
        capacity,
        capabilities,

        setName,
        setCategory,
        setSubcategories,
        setCapacity,
        setCapabilities,
    }
}

type SubmitGeodecodeRequestFn = (label: string) => Promise<void>

export function useLocationFormController(
    location: Location,
    params: UseLocationFormDataHook,
    addresLabelFormDataHook: UseLocationAddressFormDataHook,
    onSave: (location: Location) => void
): UseFormControllerHook<void> & {
    submitGeodecodeRequest: SubmitGeodecodeRequestFn
} {
    const update = useInjection(LocationDependencies.Update)
    const geodecodeAddress = useInjection(LocationDependencies.GeodecodeAddress)

    const [isProcessing, setIsProcessing] = useState(false)
    const taskFeedback = useTaskFeedback()

    const canSubmit = useMemo(() => {
        if (!params.hasChanges) return false
        if (params.name === '') return false
        return true
    }, [params])

    const submit = useCallback(async () => {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const updated = await update.run({
                location: location,
                name: params.name,
                category: params.category,
                subcategories: params.subcategories,
                capacity: params.capacity,
                capabilities: params.capabilities,
            })
            onSave(updated)
            taskFeedback.succeed()
        } catch (e: unknown) {
            taskFeedback.fail()
        } finally {
            setIsProcessing(false)
        }
    }, [
        params.name,
        params.category,
        params.subcategories,
        params.capacity,
        params.capabilities,
        isProcessing,
        canSubmit,
    ])

    const submitGeodecodeRequest = useCallback(async (label: string) => {
        if (isProcessing) return

        setIsProcessing(true)
        try {
            const updated = await geodecodeAddress.run({
                location: location,
                addressLabel: label,
            })

            onSave(updated)
            taskFeedback.succeed()
            addresLabelFormDataHook.setAddressLabel(undefined)
        } catch (e: unknown) {
            taskFeedback.fail()
        } finally {
            setIsProcessing(false)
        }
    }, [])

    return {
        isProcessing,
        canSubmit,
        submit,
        submitGeodecodeRequest,
        isSuccess: taskFeedback.isSuccess,
        failure: taskFeedback.failure,
    }
}
