import { Menu } from 'features/menu/models/Menu'
import { injectable } from 'inversify'
import 'reflect-metadata'

export interface MenuStore {
    clear: () => void
    contains: (id: number) => boolean
    retrieve: (id: number) => Menu | undefined
    save: (menu: Menu) => void
}

@injectable()
export class MenuStoreLive implements MenuStore {
    private cache = new Map<number, Menu>()

    clear(): void {
        this.cache.clear()
    }

    contains(id: number): boolean {
        return this.cache.has(id)
    }

    retrieve(id: number): Menu | undefined {
        return this.cache.get(id)
    }

    save(menu: Menu): void {
        this.cache.set(menu.id.menuId, menu)
    }
}
