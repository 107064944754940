import { Card, CardBody, Heading, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { describeTerminal as describe } from '../mappers/InternalMapper'
import { Terminal } from '../models/Terminal'

interface TerminalCardProps {
    terminal: Terminal
}

export function TerminalCard({ terminal }: TerminalCardProps) {
    const { t } = useTranslation()

    return (
        <Card bg="pageBackground3" w="full">
            <CardBody>
                <VStack w="full" align="stretch" spacing={0}>
                    <Heading size="sm">{terminal.name}</Heading>
                    <Text color="label2" size="sm">
                        {t(describe(terminal))}
                    </Text>
                </VStack>
            </CardBody>
        </Card>
    )
}
