import { AvokadoSupportChat } from '@avokadoapp/support-chat'
import useAuthContext from 'features/auth/contexts/AuthContext'
import { AuthDependencies } from 'features/auth/dependencies'
import { AuthLayout } from 'features/auth/pages/AuthPage'
import ConfirmSignUpPage from 'features/auth/pages/ConfirmSignUpPage'
import DefaultSignInPage from 'features/auth/pages/DefaultSignInPage'
import EmailSignInPage from 'features/auth/pages/EmailSignInPage'
import PasswordForgottenPage from 'features/auth/pages/PasswordForgottenPage'
import ResetPasswordPage from 'features/auth/pages/ResetPasswordPage'
import SignUpPage from 'features/auth/pages/SignUpPage'
import EventsContextProvider from 'features/event/contexts/EventsContextProvider'
import FinancePage from 'features/finance/pages/FinancePage'
import HomePage from 'features/home/pages/HomePage'
import LocationPage from 'features/location/pages/LocationPage'
import LocationsPage from 'features/location/pages/LocationsPage'
import NewLocationPage from 'features/location/pages/NewLocationPage'
import MenuPage from 'features/menu/pages/MenuPage'
import MenusPage from 'features/menu/pages/MenusPage'
import NewMenuPage from 'features/menu/pages/NewMenuPage'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import NewOrganizationPage from 'features/organization/pages/NewOrganizationPage'
import OrganizationsPage from 'features/organization/pages/OrganizationsPage'
import StripeRefreshPage from 'features/stripe/pages/StripeRefreshPage'
import StripeReturnPage from 'features/stripe/pages/StripeReturnPage'
import { useInjection } from 'inversify-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Constants from 'utils/constant/Constants'
import SessionStorageKeys from 'utils/constant/SessionStorageKeys'
import { useFullName } from 'utils/fullname/useFullName'
import { MainLayout, OrganizationLayout } from './layouts'
import PageNotFound from './pages/PageNotFound'

export function RoutingRules() {
    const auth = useInjection(AuthDependencies.Store)
    const { i18n } = useTranslation()

    const { user } = useAuthContext()

    const fullname = useFullName(user?.givenName, user?.familyName)

    if (!user) {
        return <UnauthenticatedRoutes />
    } else {
        return (
            <EventsContextProvider>
                <AuthenticatedRoutes />
                <AvokadoSupportChat
                    // @ts-ignore
                    language={i18n.resolvedLanguage ?? 'en'}
                    host={Constants.Host}
                    user={{
                        name: fullname,
                        media: user.avatar,
                    }}
                    metadata={undefined}
                    tokenProvider={async () => await auth.assertIdToken()}
                />
            </EventsContextProvider>
        )
    }
}

function AuthenticatedRoutes() {
    const { organization } = useOrganizationContext()
    const { pathname, search } = useLocation()

    useEffect(() => {
        sessionStorage.setItem(
            SessionStorageKeys.CurrentSessionPage,
            `${pathname}${search}`
        )
    }, [pathname, search])

    if (!organization) {
        return (
            <Routes>
                <Route path="" element={<Navigate to="organizations" />} />
                <Route path="organizations" element={<OrganizationLayout />}>
                    <Route path="" element={<OrganizationsPage />} />
                    <Route path="new" element={<NewOrganizationPage />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        )
    } else {
        return (
            <Routes>
                <Route
                    path="organizations/:organizationId"
                    element={<MainLayout />}
                >
                    <Route path="" element={<HomePage />} />
                    <Route path="list" element={<OrganizationsPage />} />
                    <Route path="locations" element={<LocationsPage />} />
                    <Route path="locations/new" element={<NewLocationPage />} />
                    <Route
                        path="locations/:locationId"
                        element={<LocationPage />}
                    />

                    <Route path="menus" element={<MenusPage />} />
                    <Route path="menus/new" element={<NewMenuPage />} />
                    <Route path="menus/:menuId" element={<MenuPage />} />

                    <Route path="finance" element={<FinancePage />} />

                    <Route
                        path="stripe/return"
                        element={<StripeReturnPage />}
                    />
                    <Route
                        path="stripe/refresh"
                        element={<StripeRefreshPage />}
                    />
                </Route>
                <Route path="organizations" element={<OrganizationLayout />}>
                    <Route path="" element={<OrganizationsPage />} />
                    <Route path="new" element={<NewOrganizationPage />} />
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        )
    }
}

function UnauthenticatedRoutes() {
    return (
        <Routes>
            <Route path="" element={<AuthLayout />}>
                <Route path="" element={<DefaultSignInPage />} />
                <Route path="sign-in" element={<EmailSignInPage />} />
                <Route path="sign-up" element={<SignUpPage />} />
                <Route
                    path="password-forgotten"
                    element={<PasswordForgottenPage />}
                />
                <Route path="reset-password" element={<ResetPasswordPage />} />
                <Route path="confirm-sign-up" element={<ConfirmSignUpPage />} />

                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    )
}
