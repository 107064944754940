import {
    AbsoluteCenter,
    Box,
    Button,
    Center,
    Divider,
    HStack,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Progress,
    Text,
    VStack,
    Wrap,
    WrapItem,
    useBoolean,
} from '@chakra-ui/react'
import { SignInWithAppleButton } from 'features/socialauth/apple/SignInWithAppleButton'
import { SignInWithGoogleButton } from 'features/socialauth/google/SignInWithGoogleButton'
import { AnimatePresence, motion } from 'framer-motion'
import { DateDependencies } from 'infra/di/factories/Date'
import { useInjection } from 'inversify-react'
import useT from 'localization/hooks/useT'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import { Outlet, useLocation } from 'react-router-dom'
import Constants from 'utils/constant/Constants'
import ReponsiveComponent, {
    useScreenBreakpoint,
} from 'utils/genericcomponents/ResponsiveComponent'
import useAuthContext from '../contexts/AuthContext'
import useSignInWithAppleProxy from '../hooks/useSignInWithAppleProxy'
import useSignInWithGoogleProxy from '../hooks/useSignInWithGoogleProxy'
import EmailRefreshSessionPage from './EmailRefreshSessionPage'

export function AuthLayout() {
    const { t, i18n } = useTranslation('auth')
    const [animationTrigger, setAnimationTrigger] = useState(false)
    const [isLoading, { on, off }] = useBoolean(false)

    useEffect(() => {
        if (isLoading) window.scroll({ top: 0 })
    }, [isLoading])

    useEffect(() => {
        setTimeout(() => {
            setAnimationTrigger(true)
        }, 400)
    }, [])

    return (
        <VStack
            opacity={animationTrigger ? 1 : 0}
            transition="0.3s opacity ease-in"
            width="full"
            minHeight="100dvh"
            bg="authBackground"
            justify="center"
        >
            <Progress
                isIndeterminate={isLoading}
                value={0}
                bg="authBackground"
                w="full"
            />
            <ReponsiveComponent
                mobile={
                    <MobileComponent
                        onLoadingStarted={on}
                        onLoadingEnded={off}
                    />
                }
                tablet={
                    <TabletComponent
                        onLoadingStarted={on}
                        onLoadingEnded={off}
                    />
                }
                desktop={
                    <DesktopComponent
                        onLoadingStarted={on}
                        onLoadingEnded={off}
                    />
                }
            />
            <Text fontSize="sm" pb={2} px={8} textAlign="center">
                {t('main_accepting_terms_footer')}
            </Text>
            <Wrap spacing={8} pb={2} justify="center" align="center">
                <WrapItem>
                    <Link href={`https://${Constants.Host}`} isExternal>
                        {t('landing_footer_link_about')}
                    </Link>
                </WrapItem>
                <WrapItem>
                    <Link
                        href={`https://terminal.${Constants.Host}`}
                        isExternal
                    >
                        {t('landing_footer_link_terminal')}
                    </Link>
                </WrapItem>
                <WrapItem>
                    <Link
                        href={`https://${Constants.Host}/privacy-policy`}
                        isExternal
                    >
                        {t('landing_footer_link_privacy_policy')}
                    </Link>
                </WrapItem>
                <WrapItem>
                    <Link
                        href={`https://${Constants.Host}/terms-of-service`}
                        isExternal
                    >
                        {t('landing_footer_link_terms_of_service')}
                    </Link>
                </WrapItem>
                <WrapItem>
                    <Link href={`mailto:support@avokadoapp.ch`} isExternal>
                        {t('landing_footer_link_support')}
                    </Link>
                </WrapItem>
                <WrapItem>
                    <Menu>
                        <MenuButton
                            as={Button}
                            variant="link"
                            color="label1"
                            fontWeight="normal"
                        >
                            {t('landing_footer_link_language')}
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() => i18n.changeLanguage('en')}
                                icon={
                                    i18n.resolvedLanguage === 'en' ? (
                                        <FaCheck />
                                    ) : undefined
                                }
                            >
                                English
                            </MenuItem>
                            <MenuItem
                                onClick={() => i18n.changeLanguage('it')}
                                icon={
                                    i18n.resolvedLanguage === 'it' ? (
                                        <FaCheck />
                                    ) : undefined
                                }
                            >
                                Italiano
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </WrapItem>
            </Wrap>
        </VStack>
    )
}

function useSubheaderMessage(): string {
    const { expiredSession } = useAuthContext()
    const t = useT('auth')
    const now = useInjection(DateDependencies.Generator)
    const oneHourAgo = useMemo(() => {
        return new Date(now().getTime() - 60 * 60 * 1000)
    }, [now])

    if (!expiredSession) {
        return t('main_default_subtitle')
    } else if (expiredSession.expiration < oneHourAgo) {
        if (expiredSession.givenName) {
            return t('main_welcome_back_with_given_name_subtitle', {
                givenName: expiredSession.givenName,
            })
        } else {
            return t('main_welcome_back_without_given_name_subtitle')
        }
    } else {
        if (expiredSession.givenName) {
            return t('main_session_expired_with_given_name_subtitle', {
                givenName: expiredSession.givenName,
            })
        } else {
            return t('main_session_expired_without_given_name_subtitle')
        }
    }
}

function DesktopComponent(props: MainOutletProps) {
    const t = useT('auth')
    const subheader = useSubheaderMessage()
    return (
        <HStack height="full" width="full">
            <Center flex="1" flexShrink={1} maxWidth="50%">
                <AvokadoHugeLogo />
            </Center>
            <Box>
                <VStack align="start" gap={16} as={motion.div} layout>
                    <VStack align="start">
                        <Text
                            fontSize="6xl"
                            fontWeight="bold"
                            width="full"
                            as={motion.div}
                            layout="position"
                        >
                            {t('main_title')}
                        </Text>
                        <Text
                            fontSize="2xl"
                            fontWeight="medium"
                            as={motion.div}
                            layout="position"
                            size="xs"
                            pe={4}
                        >
                            {subheader}
                        </Text>
                    </VStack>

                    <MainOutlet {...props} />
                </VStack>
            </Box>
        </HStack>
    )
}

function TabletComponent(props: MainOutletProps) {
    const t = useT('auth')
    const subheader = useSubheaderMessage()
    return (
        <HStack height="full" width="full">
            <Box px={16} w="full">
                <VStack align="start" w="full" gap={8} as={motion.div} layout>
                    <VStack align="start" w="full">
                        <Box maxWidth={48} alignSelf="start">
                            <AvokadoHugeLogo />
                        </Box>

                        <Text
                            fontSize="6xl"
                            fontWeight="bold"
                            width="full"
                            as={motion.div}
                            layout="position"
                        >
                            {t('main_title')}
                        </Text>
                        <Text
                            fontSize="2xl"
                            fontWeight="medium"
                            as={motion.div}
                            layout="position"
                            pe={4}
                        >
                            {subheader}
                        </Text>
                    </VStack>

                    <MainOutlet {...props} />
                </VStack>
            </Box>
        </HStack>
    )
}

function MobileComponent(props: MainOutletProps) {
    const t = useT('auth')
    const subheader = useSubheaderMessage()
    return (
        <VStack
            align="center"
            gap={16}
            as={motion.div}
            layout
            mt={16}
            mb={16}
            w="full"
        >
            <VStack align="center" textAlign="center" w="full">
                <Center maxW={32}>
                    <AvokadoHugeLogo />
                </Center>

                <Text
                    fontSize="6xl"
                    fontWeight="bold"
                    width="full"
                    as={motion.div}
                    layout="position"
                >
                    {t('main_title')}
                </Text>
                <Text
                    fontSize="2xl"
                    fontWeight="medium"
                    as={motion.div}
                    layout="position"
                    px={8}
                >
                    {subheader}
                </Text>
            </VStack>
            <Center>
                <MainOutlet {...props} />
            </Center>
        </VStack>
    )
}

function AvokadoHugeLogo() {
    return <Image width="51%" src="logo.svg" />
}

interface MainOutletProps {
    onLoadingStarted(): void
    onLoadingEnded(): void
}

function MainOutlet(props: MainOutletProps) {
    const { expiredSession, signOut } = useAuthContext()
    const signInWithAppleProxy = useSignInWithAppleProxy()
    const signInWithGoogleProxy = useSignInWithGoogleProxy()
    const t = useT('auth')

    const { pathname } = useLocation()

    const socialButtonVariant = useMemo(() => {
        if (pathname === '/') {
            return 'continue'
        }
        if (pathname === '/sign-in') {
            return 'signin'
        }
        if (pathname === '/sign-up') {
            return 'signup'
        }
        return undefined
    }, [pathname])

    const isSigninIn = useMemo(() => {
        return (
            signInWithAppleProxy.isProcessing ||
            signInWithGoogleProxy.isProcessing
        )
    }, [signInWithAppleProxy.isProcessing, signInWithGoogleProxy.isProcessing])

    useEffect(() => {
        if (isSigninIn) {
            props.onLoadingStarted()
        } else {
            props.onLoadingEnded()
        }
    }, [isSigninIn, props])

    const screen = useScreenBreakpoint()

    return (
        <Box w="full">
            {(screen === 'mobile' ||
                screen === 'desktop' ||
                screen === 'tablet') && (
                <VStack
                    w="xs"
                    opacity={isSigninIn ? 0 : 1}
                    transition="1s opacity ease"
                >
                    <AnimatePresence>
                        {socialButtonVariant && (
                            <VStack w="full" as={motion.div} layout>
                                {(!expiredSession ||
                                    expiredSession.availableFederatedIdentities.includes(
                                        'apple'
                                    )) && (
                                    <SignInWithAppleButton
                                        onClick={signInWithAppleProxy.signIn}
                                        variant={socialButtonVariant}
                                    />
                                )}

                                {(!expiredSession ||
                                    expiredSession.availableFederatedIdentities.includes(
                                        'google'
                                    )) && (
                                    <SignInWithGoogleButton
                                        onClick={signInWithGoogleProxy.onStart}
                                        onSuccess={
                                            signInWithGoogleProxy.onSuccess
                                        }
                                        onError={signInWithGoogleProxy.onError}
                                        variant={socialButtonVariant}
                                    />
                                )}

                                {!expiredSession && (
                                    <Box position="relative" py={8} w="full">
                                        <Divider />
                                        <AbsoluteCenter
                                            bg="authBackground"
                                            px="4"
                                        >
                                            {t('main_or_divider')}
                                        </AbsoluteCenter>
                                    </Box>
                                )}
                            </VStack>
                        )}
                    </AnimatePresence>

                    {expiredSession &&
                        expiredSession.availableFederatedIdentities.length ===
                            0 && <EmailRefreshSessionPage />}

                    {!expiredSession && <Outlet />}
                    {expiredSession && (
                        <>
                            <Box position="relative" py={8} w="full">
                                <Divider />
                                <AbsoluteCenter bg="authBackground" px="4">
                                    {t('main_or_divider')}
                                </AbsoluteCenter>
                            </Box>
                            <Button
                                w="full"
                                type="submit"
                                borderRadius="full"
                                h="48px"
                                variant="outline"
                                border="1px solid var(--chakra-colors-gray-300)"
                                onClick={signOut}
                            >
                                {t('main_change_account_button')}
                            </Button>
                        </>
                    )}
                </VStack>
            )}
        </Box>
    )
}
