import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { TerminalDependencies } from '../dependencies'
import { Terminal } from '../models/Terminal'

interface UseTerminalFormDataHook {
    name: string
    type: Terminal.Type
    isMonitor: boolean
    hasPin: boolean
    setName: Dispatch<SetStateAction<string>>
    setIsMonitor: Dispatch<SetStateAction<boolean>>
    setHasPin: Dispatch<SetStateAction<boolean>>
}

export function useTerminalFormData(): UseTerminalFormDataHook {
    const [name, setName] = useState('')
    const [isMonitor, setIsMonitor] = useState(false)
    const [hasPin, setHasPin] = useState(false)

    const type = useMemo(() => {
        if (isMonitor) {
            return 'monitor'
        } else if (hasPin) {
            return 'register-standard'
        } else {
            return 'register-shared'
        }
    }, [isMonitor, hasPin])

    useEffect(() => {
        if (isMonitor) {
            setHasPin(false)
        }
    }, [isMonitor])

    return {
        name,
        type,
        isMonitor,
        hasPin,
        setName,
        setIsMonitor,
        setHasPin,
    }
}

export function useTerminalFormController(
    id: Location.ID,
    terminals: Terminal[],
    params: UseTerminalFormDataHook,
    onSave: (terminal: Terminal) => void
): UseFormControllerHook<void> & {
    result: 'success' | 'error' | undefined
    reset: () => void
    nameIsAlreadyInUse: boolean
} {
    const create = useInjection(TerminalDependencies.Create)

    const [isProcessing, setIsProcessing] = useState(false)
    const [result, setResult] = useState<'success' | 'error'>()

    const usedNames = useMemo(
        () => terminals.map((terminal) => terminal.name),
        [terminals]
    )

    const canSubmit = useMemo(() => {
        if (params.name === '') return false
        if (usedNames.includes(params.name)) return false
        return true
    }, [usedNames, params.name])

    const nameIsAlreadyInUse = usedNames.includes(params.name)

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const newTerminal = await create.run({
                ...id,
                name: params.name,
                type: params.type,
            })
            onSave(newTerminal)
            setResult('success')
        } catch (e: unknown) {
            setResult('error')
        } finally {
            setIsProcessing(false)
        }
    }

    return {
        isProcessing,
        canSubmit,
        submit,
        result,
        nameIsAlreadyInUse,
        reset: () => {
            params.setName('')
            setResult(undefined)
        },
    }
}
