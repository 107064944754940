import { useNotification } from 'features/notification/hooks/useNotification'
import useT from 'localization/hooks/useT'
import { useEffect } from 'react'
import { TaskFeedbackObservable } from './useTaskFeedback'

interface Labels {
    successTitle?: string
    successDescription?: string
    errorTitle?: string
    errorDescription?: string
}

export default function useFormNotification(
    task: string,
    observing: TaskFeedbackObservable,
    labels?: Labels
) {
    const t = useT()
    const notification = useNotification()

    useEffect(() => {
        if (observing.isSuccess) {
            notification({
                id: task + '-form-success',
                variant: 'success',
                title:
                    labels?.successTitle ??
                    t('form_notification_fallback_succes_title'),
                description: labels?.successDescription ?? null,
            })
        }
    }, [observing.isSuccess])

    useEffect(() => {
        if (observing.failure) {
            if (typeof observing.failure === 'string') {
                notification({
                    id: task + '-form-failure',
                    variant: 'error',
                    title: observing.failure,
                })
            } else if (typeof observing.failure === 'boolean') {
                notification({
                    id: task + '-form-failure',
                    variant: 'error',
                    title: t(
                        'form_notification_fallback_operation_could_not_be_completed'
                    ),
                })
            } else {
                notification({
                    id: task + '-form-failure',
                    variant: 'error',
                    title: observing.failure.title,
                    description: observing.failure.description,
                })
            }
        }
    }, [observing.failure])
}
