import Avokado, {
    AvokadoAPIError,
    MenuSectionMediaLinksCreate,
} from '@avokadoapp/avokado-ts'
import { mapExtensionToLocationFormat } from 'features/media/mappers/SDKRequestMapper'
import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface UploadMediaMenuSectionParams extends Menu.Section.ID {
    media: File
}

export interface UploadMediaMenuSectionAction {
    run(params: UploadMediaMenuSectionParams): Promise<MediaUploadLink>
}

@injectable()
export class UploadMediaMenuSectionActionLive
    implements UploadMediaMenuSectionAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UploadMediaMenuSectionParams): Promise<MediaUploadLink> {
        const request: MenuSectionMediaLinksCreate.Request = {
            organizationId: params.organizationId,
            menuId: params.menuId,
            menuSectionId: params.sectionId,
            format: mapExtensionToLocationFormat(params.media.type),
        }
        return await this.avokado.menuSection.mediaLinksCreate(request)
    }
}

@injectable()
export class UploadMediaMenuSectionActionFake
    implements UploadMediaMenuSectionAction
{
    async run(params: UploadMediaMenuSectionParams): Promise<MediaUploadLink> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UploadMediaMenuSectionActionFail
    implements UploadMediaMenuSectionAction
{
    async run(params: UploadMediaMenuSectionParams): Promise<MediaUploadLink> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
