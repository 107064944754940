export module MediaUploadError {
    export class ProcessingError extends Error {
        moderationLabel?: string

        constructor(
            params:
                | { type: 'generic' }
                | { type: 'prohibited-content'; moderationLabel?: string }
        ) {
            super(`media-upload-error.${params.type}`)
            if (params.type === 'prohibited-content') {
                this.moderationLabel = params.moderationLabel
            }
        }
    }
}
