import {
    FormLabelProps,
    HStack,
    InputProps,
    Tag,
    TagCloseButton,
    VStack,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { AutocompletePicker } from './AutocompletePicker'

interface AutocompleteSelectorProps<T extends Record<number, string>>
    extends Pick<InputProps, 'bg' | 'variant'> {
    enum: T
    value: number[]
    placeholder?: string
    label?: string
    helperText?: string
    labelProps?: FormLabelProps
    setValue: Dispatch<SetStateAction<number[]>>
    SelectedRenderer: (props: { option: number }) => JSX.Element
    AvailableRenderer: (props: { option: number }) => JSX.Element
}

export function AutocompleteSelector<T extends Record<number, string>>({
    enum: enumClass,
    value: selectedCases,
    setValue: setSelectedCases,
    variant,
    placeholder,
    label,
    helperText,
    labelProps,
    SelectedRenderer,
    AvailableRenderer,
    ...props
}: AutocompleteSelectorProps<T>) {
    const availableCases: number[] = useMemo(() => {
        return Object.keys(enumClass)
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x))
            .filter((x) => !selectedCases.includes(x))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCases])

    const add = useCallback((value?: number) => {
        if (value === undefined) return

        setSelectedCases((previous) => {
            return previous.includes(value)
                ? previous
                : [...previous, value].sort((x, y) => x - y)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const remove = useCallback((value: number) => {
        setSelectedCases((previous) => previous.filter((x) => x !== value))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <VStack w="full">
            <AutocompletePicker
                options={availableCases}
                variant={variant}
                placeholder={placeholder}
                helperText={helperText}
                label={label}
                labelProps={labelProps}
                onPick={add}
                optionMapper={(option) => enumClass[option]}
                AvailableRenderer={AvailableRenderer}
                {...props}
            />
            <HStack wrap="wrap" align="start" w="full" gap={2} spacing={0}>
                {selectedCases.map((selectedCase) => (
                    <Tag
                        key={selectedCase}
                        size="lg"
                        borderRadius="full"
                        colorScheme="gray"
                        pl={0}
                    >
                        <SelectedRenderer option={selectedCase} />
                        <TagCloseButton onClick={() => remove(selectedCase)} />
                    </Tag>
                ))}
            </HStack>
        </VStack>
    )
}
