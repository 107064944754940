import { Box, Heading, Portal, Text, VStack } from '@chakra-ui/react'
import MenuPicker from 'features/menu/components/picker/MenuPicker'
import useT from 'localization/hooks/useT'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedActionDesktop'
import { CreateMenuAssignmentController } from './Controller'

export default function SelectMenuStep(
    props: CreateMenuAssignmentController & StepPropsWithRef
) {
    const t = useT('menuassignment')
    return (
        <>
            <Portal containerRef={props.headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {t('new_step_1_select_menu_title')}
                    </Heading>
                    <Text>{t('new_step_1_select_menu_description')}</Text>
                </VStack>
            </Portal>
            <Box h="full" w="full">
                <MenuPicker
                    menus={props.menus}
                    onMenuSelected={props.setSelectedCompactMenu}
                />
            </Box>
        </>
    )
}
