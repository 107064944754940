import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    Text,
    VStack,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { FormEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PasswordInput } from 'uikit/form/input'
import { emailValidation, passwordValidation } from 'utils/form/validation'
import {
    useSignUpFormController,
    useSignUpFormData,
} from '../hooks/useSignUpForm'

export default function SignUpPage() {
    const t = useT('auth')

    const formData = useSignUpFormData()
    const controller = useSignUpFormController(formData)

    const [emailError, setEmailError] = useState<string>()
    const [passwordError, setPasswordError] = useState<string>()

    async function handleSubmit(event: FormEvent<HTMLDivElement>) {
        event.preventDefault()
        setEmailError(undefined)
        setPasswordError(undefined)

        if (controller.canSubmit) {
            try {
                await controller.submit()
            } catch (e: any) {
                // TODO
            }
        } else {
            emailValidation
                .validate({ email: formData.email })
                .catch((e) => setEmailError(t(e.errors[0])))

            passwordValidation
                .validate({ password: formData.password })
                .catch((e) => setPasswordError(t(e.errors[0])))
        }
    }

    useEffect(() => setEmailError(undefined), [formData.email])
    useEffect(() => setPasswordError(undefined), [formData.password])

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack
                as={motion.div}
                align="stretch"
                w="full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <FormControl>
                    <Input
                        autoFocus
                        type="text"
                        placeholder={t('generic_input_given_name')}
                        autoComplete="given-name"
                        bg="authBackground"
                        h="44px"
                        w="xs"
                        focusBorderColor="primary.200"
                        border="1px solid var(--chakra-colors-gray-400)"
                        _placeholder={{ color: 'label2' }}
                        borderRadius="full"
                        value={formData.givenName}
                        onChange={(e) => formData.setGivenName(e.target.value)}
                    />
                </FormControl>

                <FormControl>
                    <Input
                        type="text"
                        placeholder={t('generic_input_family_name')}
                        autoComplete="family-name"
                        bg="authBackground"
                        h="44px"
                        w="xs"
                        focusBorderColor="primary.200"
                        border="1px solid var(--chakra-colors-gray-400)"
                        _placeholder={{ color: 'label2' }}
                        borderRadius="full"
                        value={formData.familyName}
                        onChange={(e) => formData.setFamilyName(e.target.value)}
                    />
                </FormControl>

                <FormControl isInvalid={emailError !== undefined}>
                    <Input
                        type="email"
                        placeholder={t('generic_input_email_address')}
                        autoComplete="email"
                        bg="authBackground"
                        h="44px"
                        w="xs"
                        border="1px solid var(--chakra-colors-gray-400)"
                        _placeholder={{ color: 'label2' }}
                        focusBorderColor="primary.200"
                        borderRadius="full"
                        value={formData.email}
                        onChange={(e) => formData.setEmail(e.target.value)}
                    />
                    <FormErrorMessage>{emailError}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={passwordError !== undefined}>
                    <PasswordInput
                        value={formData.password}
                        setValue={formData.setPassword}
                        h="44px"
                        w="xs"
                        autoComplete="new-password"
                        focusBorderColor="primary.200"
                        borderRadius="full"
                    />
                    <FormErrorMessage>{passwordError}</FormErrorMessage>
                </FormControl>

                <Button
                    w="full"
                    type="submit"
                    isLoading={controller.isProcessing}
                    mt={8}
                    h="48px"
                >
                    {t('generic_button_sign_up')}
                </Button>
                <Text fontSize="xs" color="label2">
                    {t('generic_label_social_signin_suggestion')}
                </Text>

                <HStack pt={4}>
                    <Text fontSize="sm">
                        {t('signup_already_have_an_account_label')}
                    </Text>

                    <Button
                        as={Link}
                        to="/sign-in"
                        display="flex"
                        alignItems="center"
                        variant="unstyled"
                        size="sm"
                        color="label1"
                        _hover={{ color: 'accent' }}
                    >
                        {t('generic_button_sign_in')}
                    </Button>
                </HStack>
            </VStack>
        </Box>
    )
}
