export function subtract(
    minuend: [number, number][],
    subtrahend: [number, number][]
): [number, number][] {
    const difference: [number, number][] = []

    let minuendIndex = 0
    let subtrahendIndex = 0

    while (minuendIndex < minuend.length) {
        const minuendInterval = minuend[minuendIndex]

        if (subtrahendIndex >= subtrahend.length) {
            // If there are no more subtrahend intervals, add the remaining minuend intervals to the difference.
            difference.push(...minuend.slice(minuendIndex))
            break
        }

        const subtrahendInterval = subtrahend[subtrahendIndex]

        if (minuendInterval[1] < subtrahendInterval[0]) {
            // No overlap, add minuend interval to the difference.
            difference.push(minuendInterval)
            minuendIndex++
        } else if (minuendInterval[0] > subtrahendInterval[1]) {
            // Current minuend interval entirely after subtrahend interval, move to the next subtrahend interval.
            subtrahendIndex++
        } else {
            // Overlap between minuend and subtrahend interval, compute the difference.
            if (minuendInterval[0] < subtrahendInterval[0]) {
                difference.push([minuendInterval[0], subtrahendInterval[0] - 1])
            }
            minuendIndex++

            // Adjust the minuend interval based on the overlap.
            if (minuendInterval[1] > subtrahendInterval[1]) {
                minuend[minuendIndex - 1][0] = subtrahendInterval[1] + 1
            } else {
                // If minuend interval is entirely within subtrahend interval, skip it.
                minuendIndex++
            }
        }
    }

    return difference
}
