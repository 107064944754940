import Avokado, { AvokadoAPIError, MenuUpdate } from '@avokadoapp/avokado-ts'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface UpdateTranslatedMenuVisibilityParams {
    menuId: Menu.ID
    language: MenuUpdate.Request.Locale
    isPublished: boolean
}

export interface UpdateTranslatedMenuVisibilityAction {
    run(params: UpdateTranslatedMenuVisibilityParams): Promise<void>
}

@injectable()
export class UpdateTranslatedMenuVisibilityActionLive
    implements UpdateTranslatedMenuVisibilityAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateTranslatedMenuVisibilityParams): Promise<void> {
        const menuId = params.menuId
        const request: MenuUpdate.Request = {
            ...menuId,
            availableTranslations: [
                {
                    isPublished: params.isPublished,
                    locale: params.language,
                },
            ],
        }
        await this.avokado.menu.update(request)
    }
}

@injectable()
export class UpdateTranslatedMenuVisibilityActionFail
    implements UpdateTranslatedMenuVisibilityAction
{
    async run(params: UpdateTranslatedMenuVisibilityParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
