import { RawMedia } from 'features/media/models/RawMedia'
import { MenuEntryDependencies } from 'features/menu/dependencies/entry'
import { Menu } from 'features/menu/models/Menu'
import { useInjection } from 'inversify-react'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { cleanUpString } from 'utils/types'

export interface UseNewEntryFormDataHook {
    name: string
    description: string
    price: number
    allergens: Menu.Section.Entry.Allergen[]
    features: Menu.Section.Entry.Feature[]
    isUploading: boolean
    rawMedia: RawMedia | undefined
    setName: Dispatch<SetStateAction<string>>
    setDescription: Dispatch<SetStateAction<string>>
    setPrice: Dispatch<SetStateAction<number>>
    setAllergens: Dispatch<SetStateAction<Menu.Section.Entry.Allergen[]>>
    setFeatures: Dispatch<SetStateAction<Menu.Section.Entry.Feature[]>>
    setIsUploading: Dispatch<SetStateAction<boolean>>
    setRawMedia: Dispatch<SetStateAction<RawMedia | undefined>>
    reset: () => void
}

export function useNewEntryFormData(): UseNewEntryFormDataHook {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState(0)
    const [allergens, setAllergens] = useState<Menu.Section.Entry.Allergen[]>(
        []
    )
    const [features, setFeatures] = useState<Menu.Section.Entry.Feature[]>([])
    const [isUploading, setIsUploading] = useState(false)
    const [rawMedia, setRawMedia] = useState<RawMedia>()

    const reset = useCallback(() => {
        setName('')
        setDescription('')
        setPrice(0)
        setAllergens([])
        setFeatures([])
        setIsUploading(false)
        setRawMedia(undefined)
    }, [])

    return {
        name,
        description,
        price,
        allergens,
        features,
        isUploading,
        rawMedia,
        setName,
        setDescription,
        setPrice,
        setAllergens,
        setFeatures,
        setIsUploading,
        setRawMedia,
        reset,
    }
}

export function useNewEntryFormController(
    menu: Menu,
    section: Menu.Section,
    params: UseNewEntryFormDataHook,
    onSave: (menu: Menu) => void
): UseFormControllerHook<void> {
    const create = useInjection(MenuEntryDependencies.Create)

    const [isProcessing, setIsProcessing] = useState(false)

    const usedNames = useMemo(
        () => section.entries.map((entry) => entry.name),
        [section]
    )

    const canSubmit = useMemo(() => {
        const cleanedName = cleanUpString(params.name)
        if (cleanedName === '') return false
        if (usedNames.includes(cleanedName)) return false
        if (params.price === 0) return false

        return true
    }, [params])

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)

        try {
            const newMenu = await create.run({
                menu: menu,
                section: section,
                name: cleanUpString(params.name),
                description: cleanUpString(params.description),
                price: params.price,
                allergens: params.allergens,
                features: params.features,
            })
            params.reset()
            onSave(newMenu)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
