import { interfaces } from 'inversify'
import { CreateOrganizationAction } from './CreateOrganizationAction'
import { RetrieveOrganizationAction } from './RetrieveOrganizationAction'
import { VerifyOrganizationAction } from './VerifyOrganizationAction'

interface _OrganizationDependencies {
    Retrieve: interfaces.ServiceIdentifier<RetrieveOrganizationAction>
    Create: interfaces.ServiceIdentifier<CreateOrganizationAction>
    Verify: interfaces.ServiceIdentifier<VerifyOrganizationAction>
}

export const OrganizationDependencies: _OrganizationDependencies = {
    Retrieve: Symbol('RetrieveOrganizationAction'),
    Create: Symbol('CreateOrganizationAction'),
    Verify: Symbol('VerifyOrganizationAction'),
}

export type {
    CreateOrganizationAction,
    RetrieveOrganizationAction,
    VerifyOrganizationAction,
}
