import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useNotification } from 'features/notification/hooks/useNotification'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { PasswordInput } from 'uikit/form/input'
import {
    useSignInFormController,
    useSignInFormData,
} from '../hooks/useSignInForm'

export default function EmailSignInPage() {
    const t = useT('auth')

    const notification = useNotification()

    const formData = useSignInFormData()
    const controller = useSignInFormController(formData)

    const [isError, setIsError] = useState(false)

    async function handleSubmit(event: FormEvent<HTMLDivElement>) {
        event.preventDefault()
        setIsError(false)

        if (controller.canSubmit) {
            try {
                await controller.submit()
            } catch (error: any) {
                notification({
                    id: 'invalid-credentials',
                    title: t(
                        'email_signin_invalid_credentials_notification_title'
                    ),
                    description: t(
                        'email_signin_invalid_credentials_notification_description'
                    ),
                    variant: 'error',
                })
            }
        } else {
            setIsError(true)
        }
    }

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack
                as={motion.div}
                align="stretch"
                w="full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <FormControl isInvalid={isError && formData.email === ''}>
                    <Input
                        type="email"
                        autoFocus
                        placeholder={t('generic_input_email_address')}
                        autoComplete="username"
                        bg="authBackground"
                        h="44px"
                        w="xs"
                        border="1px solid var(--chakra-colors-gray-400)"
                        _placeholder={{ color: 'label2' }}
                        focusBorderColor="primary.200"
                        borderRadius="full"
                        value={formData.email}
                        onChange={(e) => formData.setEmail(e.target.value)}
                    />
                    <FormErrorMessage>
                        {t('email_required_field_error')}
                    </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={isError && formData.password === ''}>
                    <PasswordInput
                        value={formData.password}
                        setValue={formData.setPassword}
                        borderRadius="full"
                        autoComplete="current-password"
                        focusBorderColor="primary.200"
                        h="44px"
                        w="xs"
                    />
                    <FormErrorMessage>
                        {t('password_required_field_error')}
                    </FormErrorMessage>
                </FormControl>

                <Button
                    w="full"
                    type="submit"
                    borderRadius="full"
                    h="48px"
                    isLoading={controller.isProcessing}
                    mt={8}
                >
                    {t('generic_button_sign_in')}
                </Button>
                <Text fontSize="xs" color="label2">
                    {t('generic_label_social_signin_suggestion')}
                </Text>

                <Button
                    as={Link}
                    to="/password-forgotten"
                    display="flex"
                    alignSelf="end"
                    variant="unstyled"
                    size="sm"
                    color="label1"
                    _hover={{ color: 'accent' }}
                >
                    {t('generic_button_password_forgotten')}
                </Button>

                <HStack pt={4}>
                    <Text fontSize="sm">
                        {t('email_signin_dont_have_an_account_label')}
                    </Text>

                    <Button
                        as={Link}
                        to="/sign-up"
                        display="flex"
                        alignItems="center"
                        variant="unstyled"
                        size="sm"
                        color="label1"
                        _hover={{ color: 'accent' }}
                    >
                        {t('generic_button_sign_up')}
                    </Button>
                </HStack>
            </VStack>
        </Box>
    )
}
