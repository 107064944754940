import { interfaces } from 'inversify'
import { CreateMenuExtraAction } from './CreateMenuExtraAction'
import { DeleteMenuExtraAction } from './DeleteMenuExtraAction'
import { UpdateMenuExtraAction } from './UpdateMenuExtraAction'

interface _MenuExtraDependencies {
    Create: interfaces.ServiceIdentifier<CreateMenuExtraAction>
    Update: interfaces.ServiceIdentifier<UpdateMenuExtraAction>
    Delete: interfaces.ServiceIdentifier<DeleteMenuExtraAction>
}

export const MenuExtraDependencies: _MenuExtraDependencies = {
    Create: Symbol('CreateMenuExtraAction'),
    Update: Symbol('UpdateMenuExtraAction'),
    Delete: Symbol('DeleteMenuExtraAction'),
}

export type {
    CreateMenuExtraAction,
    DeleteMenuExtraAction,
    UpdateMenuExtraAction,
}
