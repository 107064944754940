import { PropsWithChildren, useState } from 'react'
import { AuthProxyContext } from './AuthProxyContext'

export default function AuthProxyContextProvider(props: PropsWithChildren) {
    const [email, setEmail] = useState<string>()
    const [password, setPassword] = useState<string>()

    return (
        <AuthProxyContext.Provider
            value={{ email, password, setEmail, setPassword }}
        >
            {props.children}
        </AuthProxyContext.Provider>
    )
}
