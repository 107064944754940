import { useConst } from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useInjection } from 'inversify-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { sleep } from 'utils/asyncawait/sleep'
import { fullParseInt } from 'utils/types'
import useLocationsContext from '../contexts/LocationsContext'
import { LocationDependencies } from '../dependencies'
import { Location } from '../models/Location'

export function useLocation(rawLocationId?: string) {
    const retrieve = useInjection(LocationDependencies.Retrieve)

    const organizationManager = useOrganizationContext()
    const locationsManager = useLocationsContext()

    const navigate = useNavigate()

    const [location, setLocation] = useState<Location>()
    const { organizationId } = useConst(
        () => organizationManager.assertOrganization().id
    )

    useEffect(() => {
        async function init(locationId: number) {
            const id = { organizationId, locationId }
            const stored = locationsManager.retrieve(id)
            setLocation(stored)

            while (true) {
                try {
                    const fetched = await retrieve.run(id)
                    update(fetched)
                    return
                } catch {
                    await sleep(3000)
                }
            }
        }

        const locationId = fullParseInt(rawLocationId)
        if (isNaN(locationId)) {
            navigate('/locations')
        } else {
            init(locationId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const update = useCallback((location: Location) => {
        locationsManager.update(location)
        setLocation(location)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { location, update }
}
