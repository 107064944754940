import { createContext } from 'react'
import { ScaffoldAction } from './types'

interface _ScaffoldContext {
    setPrimaryAction: (a?: ScaffoldAction) => void
    setSecondaryAction: (a?: ScaffoldAction) => void
}

const defaultScaffoldContext: _ScaffoldContext = {
    setPrimaryAction(a) {},
    setSecondaryAction(a) {},
}

export const ScaffoldContext = createContext(defaultScaffoldContext)
