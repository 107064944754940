import { Box, Collapse, Link, Spinner, Text } from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { OrganizationDependencies } from 'features/organization/dependencies'
import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import { Container } from 'uikit/container/Container'

export default function StripeRefreshPage() {
    const verify = useInjection(OrganizationDependencies.Verify)

    const organizationManager = useOrganizationContext()

    const [redirectURL, setRedirectURL] = useState<string>()
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        verify.run(organizationManager.assertOrganization().id).then((url) => {
            window.open(url, '_blank', 'noopener,noreferrer')
            setRedirectURL(url)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true)
        }, 3000)

        return () => clearTimeout(timer)
    }, [])

    return (
        <Box flex={1} display="flex" alignItems="center">
            <Container
                w="50%"
                title="Verification"
                subtitle="You will be automatically redirected shortly"
                header={<Spinner size="xl" />}
            >
                <Collapse in={showButton && redirectURL !== undefined}>
                    <Text>
                        If you are not automatically redirected, please click{' '}
                        <Link fontWeight="medium" href={redirectURL} isExternal>
                            here
                        </Link>{' '}
                        to continue.
                    </Text>
                </Collapse>
            </Container>
        </Box>
    )
}
