import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Collapse,
} from '@chakra-ui/react'
import { mapLocationMediaToModel } from 'features/location/mappers/SDKResponseMapper'
import useT from 'localization/hooks/useT'
import { useCallback } from 'react'
import SortableList, { SortableItem } from 'react-easy-sort'
import { useLocalStorage } from 'react-use'
import { Container } from 'uikit/container/Container'
import LocalStorageKeys from 'utils/constant/LocalStorageKeys'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { RawMedia } from '../../media/models/RawMedia'
import {
    useGalleryFormController,
    useGalleryFormData,
} from '../hooks/useGalleryForm'
import { LocationTabProps } from '../pages/LocationPage'
import GalleryHelperAlert from './gallery/GalleryHelperAlert'
import { GalleryImage } from './gallery/GalleryImage'
import { GalleryImageUploader } from './gallery/GalleryImageUploader'

export default function GalleryTab({
    headerRef,
    location,
    update,
}: LocationTabProps) {
    const t = useT('gallery')

    const formData = useGalleryFormData(location.medias)
    const controller = useGalleryFormController(location, formData, update)

    const [shouldHideAlert, setShouldHideAlert] = useLocalStorage<boolean>(
        LocalStorageKeys.HideLocationGalleryAlertHelper
    )

    const onUploaded = useCallback(
        (rawMedia: RawMedia) => {
            const media = mapLocationMediaToModel(location.id, rawMedia)
            update({ ...location, medias: [...location.medias, media] })
        },
        [location, update, location.medias, location.medias.length]
    )

    const screen = useScreenBreakpoint()
    const gridTemplateColumns = screen === 'mobile' ? '1fr' : 'repeat(3, 1fr)'

    return (
        <>
            <GalleryHelperAlert
                isVisible={!shouldHideAlert}
                onClose={() => setShouldHideAlert(true)}
            />

            <Container
                title={t('container_title', { locationName: location.name })}
                subtitle={t('container_subtitle')}
                header={
                    <ButtonGroup>
                        <Button
                            isDisabled={
                                !controller.canSubmit &&
                                !(
                                    location.medias.length > 0 &&
                                    formData.images.length === 0
                                )
                            }
                            onClick={formData.restore}
                        >
                            {t('restore_button')}
                        </Button>
                        <Button
                            isDisabled={!controller.canSubmit}
                            isLoading={controller.isProcessing}
                            onClick={controller.submit}
                        >
                            {t('save_button')}
                        </Button>
                    </ButtonGroup>
                }
            >
                <Collapse
                    in={
                        location.medias.length > 0 &&
                        formData.images.length === 0
                    }
                >
                    <Alert
                        w="full"
                        variant="left-accent"
                        status="error"
                        borderRadius="lg"
                    >
                        <AlertIcon />
                        <Box w="full">
                            <AlertTitle>
                                {t('cannot_remove_last_image_error_title')}
                            </AlertTitle>
                            <AlertDescription>
                                {t(
                                    'cannot_remove_last_image_error_description'
                                )}
                            </AlertDescription>
                        </Box>
                    </Alert>
                </Collapse>
                <SortableList
                    onSortEnd={formData.rearrange}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: gridTemplateColumns,
                        gridAutoRows: '1fr',
                        gap: 24,
                    }}
                >
                    {formData.images.map((image, index) => (
                        <SortableItem key={image.id}>
                            <Box>
                                <GalleryImage
                                    url={image.large()}
                                    onRemove={() => formData.remove(index)}
                                />
                            </Box>
                        </SortableItem>
                    ))}
                    <GalleryImageUploader
                        {...location.id}
                        onUploadingChanged={formData.setIsUploading}
                        onComplete={onUploaded}
                    />
                </SortableList>
            </Container>
        </>
    )
}
