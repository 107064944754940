import { Receipt } from '../../models/Receipt'

export function describePaymentMethod(
    method: Receipt.Payment.Method,
    externalMethod: string | undefined
) {
    switch (method) {
        case 'cardReader':
            return 'payment_method_card'
        case 'external':
            switch (externalMethod) {
                case 'cash':
                    return 'payment_method_cash'
                case 'card':
                    return 'payment_method_external_card'
                default:
                    return 'payment_method_unknown'
            }
        case 'inApp':
            return 'payment_method_app'
        case 'link':
            return 'payment_method_link'
        case 'tapToPay':
            return 'payment_method_tap_to_pay'
    }
}
