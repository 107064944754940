import {
    Button,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Portal,
    Text,
    VStack,
} from '@chakra-ui/react'
import { UseNewMenuFormDataHook } from 'features/menu/hooks/new/useNewMenuForm'
import useT from 'localization/hooks/useT'
import { useMemo } from 'react'
import { FiX } from 'react-icons/fi'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedAction'

export interface MenuCreateSectionsStepDelegate {
    isProcessing: boolean
}

export function NewMenuCreateSectionsStep({
    headerRef,
    createSections: formData,
    isProcessing,
}: UseNewMenuFormDataHook & MenuCreateSectionsStepDelegate & StepPropsWithRef) {
    const t = useT('menu')

    const placeholders = useMemo(
        () => [
            t('section_name_input_placeholder_1_starters'),
            t('section_name_input_placeholder_2_main_courses'),
            t('section_name_input_placeholder_3_drinks'),
        ],
        [t]
    )

    return (
        <>
            <Portal containerRef={headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {t('new_step_3_sections_title')}
                    </Heading>
                    <Text>{t('new_step_3_sections_description')}</Text>
                </VStack>
            </Portal>

            <VStack w="full">
                {formData.sections.map((section, index) => (
                    <InputGroup key={index}>
                        <Input
                            autoFocus
                            isDisabled={isProcessing}
                            type="text"
                            value={section}
                            placeholder={
                                placeholders[index] ??
                                t('section_name_input_placeholder', {
                                    count: index + 1,
                                })
                            }
                            onChange={(e) =>
                                formData.update(index, e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    formData.add()
                                }
                            }}
                        />
                        <InputRightElement>
                            <IconButton
                                icon={<FiX />}
                                aria-label="Remove"
                                size="xs"
                                variant="ghost"
                                colorScheme="gray"
                                borderRadius="full"
                                isDisabled={
                                    isProcessing ||
                                    formData.sections.length === 1
                                }
                                onClick={() => formData.remove(index)}
                            />
                        </InputRightElement>
                    </InputGroup>
                ))}

                <Button
                    variant="unstyled"
                    size="sm"
                    color="label1"
                    pt={4}
                    _hover={{ color: 'accent' }}
                    onClick={formData.add}
                    isDisabled={isProcessing}
                >
                    {t('add_section_button')}
                </Button>
            </VStack>
        </>
    )
}
