import { Schedule, Weekdays } from 'features/calendar/models/Calendar'
import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

const emptySchedule: Schedule = {
    monday: { type: 'full-day', isActive: false },
    tuesday: { type: 'full-day', isActive: false },
    wednesday: { type: 'full-day', isActive: false },
    thursday: { type: 'full-day', isActive: false },
    friday: { type: 'full-day', isActive: false },
    saturday: { type: 'full-day', isActive: false },
    sunday: { type: 'full-day', isActive: false },
}

type Day = keyof Schedule

function convertMilliseconds(milliseconds: number) {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60))
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))

    return { hours, minutes }
}

export interface UseWeekdayScheduleFormDataHook {
    selectedDays: Day[]
    weekdays: Schedule
    ranges: Weekdays.TimeRangeSchedule.Range[]
    setSelectedDays: Dispatch<SetStateAction<Day[]>>
    toggleDay: (day: Day) => void
    markFullDay: (isOpen?: boolean, day?: Day) => void
    addRange: () => void
    editRange: (index: number, opensAt?: number, closesAt?: number) => void
    removeRange: (index: number) => void
    clear: () => void
    set: (schedule: Schedule) => void
}

export function useWeekdayScheduleFormData(
    weekdaysSchedule: Schedule = emptySchedule
): UseWeekdayScheduleFormDataHook {
    const [weekdays, setWeekdays] = useState(weekdaysSchedule)
    const [selectedDays, setSelectedDays] = useState<Day[]>([])

    const ranges = useMemo(() => {
        const schedules = selectedDays.map((newDay) => weekdays[newDay])
        return (
            schedules.reduce(
                (
                    result: Weekdays.TimeRangeSchedule.Range[] | undefined,
                    schedule: Weekdays.DaySchedule
                ) => {
                    if (schedule.type === 'full-day') {
                        return []
                    } else if (result === undefined) {
                        return schedule.timeRanges
                    } else if (result.length === 0) {
                        return []
                    } else if (!_.isEqual(result, schedule.timeRanges)) {
                        return []
                    } else {
                        return result
                    }
                },
                undefined
            ) ?? []
        )
    }, [selectedDays, weekdays])

    const toggleDay = useCallback((day: Day) => {
        setSelectedDays((previous) =>
            previous.includes(day)
                ? previous.filter((x) => x !== day)
                : [...previous, day]
        )
    }, [])

    const markFullDay = useCallback(
        (isOpen?: boolean, day?: Day) => {
            const schedule: Weekdays.DaySchedule = {
                type: 'full-day',
                isActive: isOpen ?? false,
            }

            setWeekdays((previous) => {
                const copy = (day === undefined ? selectedDays : [day]).reduce(
                    (weekdays, selectedDay) => {
                        weekdays[selectedDay] = schedule
                        return weekdays
                    },
                    { ...previous }
                )

                return _.cloneDeep(copy)
            })
        },
        [selectedDays]
    )

    const addRange = useCallback(() => {
        if (ranges.length >= 3) return

        setWeekdays((previous) => {
            const copy = selectedDays.reduce(
                (weekdays, selectedDay) => {
                    weekdays[selectedDay] = {
                        type: 'time-range',
                        timeRanges: [
                            ...ranges,
                            {
                                from: { hours: 0, minutes: 0 },
                                to: { hours: 0, minutes: 0 },
                            },
                        ],
                    }
                    return weekdays
                },
                { ...previous }
            )
            return _.cloneDeep(copy)
        })
    }, [selectedDays, ranges])

    const editRange = useCallback(
        (index: number, from?: number, to?: number) => {
            const newRange = {
                from:
                    from === undefined
                        ? ranges[index].from
                        : convertMilliseconds(from),
                to:
                    to === undefined
                        ? ranges[index].to
                        : convertMilliseconds(to),
            }

            setWeekdays((previous) => {
                const copy = selectedDays.reduce(
                    (weekdays, selectedDay) => {
                        weekdays[selectedDay] = {
                            type: 'time-range',
                            timeRanges: ranges.map((range, currentIndex) =>
                                currentIndex === index ? newRange : range
                            ),
                        }
                        return weekdays
                    },
                    { ...previous }
                )
                return _.cloneDeep(copy)
            })
        },
        [selectedDays, ranges]
    )

    const removeRange = useCallback(
        (index: number) => {
            setWeekdays((previous) => {
                return selectedDays.reduce(
                    (weekdays, selectedDay) => {
                        weekdays[selectedDay] =
                            ranges.length === 1
                                ? {
                                      type: 'full-day',
                                      isActive: false,
                                  }
                                : {
                                      type: 'time-range',
                                      timeRanges: [
                                          ...ranges.slice(0, index),
                                          ...ranges.slice(index + 1),
                                      ],
                                  }
                        return weekdays
                    },
                    { ...previous }
                )
            })
        },
        [selectedDays, ranges]
    )

    const clear = useCallback(() => {
        setWeekdays({ ...emptySchedule })
    }, [])

    const set = useCallback((schedule: Schedule) => {
        setWeekdays(schedule)
    }, [])

    return {
        selectedDays,
        weekdays,
        ranges,
        setSelectedDays,
        toggleDay,
        markFullDay,
        addRange,
        editRange,
        removeRange,
        clear,
        set,
    }
}
