import { Box, IconButton, Image, LightMode } from '@chakra-ui/react'
import { FiX } from 'react-icons/fi'

interface GalleryImageProps {
    url: string
    onRemove: () => void
}

export function GalleryImage({ url, onRemove }: GalleryImageProps) {
    function handleRemove(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
        // TODO: sometimes clicking x does not work
        event.preventDefault()
        event.stopPropagation()
        onRemove()
    }

    return (
        <Box position="relative" w="full" h="full" maxH="sm">
            <Image
                w="full"
                h="full"
                objectFit="cover"
                src={url}
                userSelect="none"
                pointerEvents="none"
                borderRadius="xl"
            />
            <LightMode>
                <IconButton
                    position="absolute"
                    top={1}
                    right={1}
                    size="sm"
                    colorScheme="blackAlpha"
                    aria-label="Remove"
                    borderRadius="lg"
                    icon={<FiX />}
                    onClick={handleRemove}
                />
            </LightMode>
        </Box>
    )
}
