import Avokado, { AvokadoAPIError, RoomUpdate } from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Geometry } from 'utils/geometry/Geometry'
import { mapRoomToModel } from '../mappers/SDKResponseMapper'
import { Room } from '../models/Room'

interface UpdateRoomParams extends Room.ID {
    isOpen?: boolean
    name?: string
    size?: Geometry.Size
}

export interface UpdateRoomAction {
    run(params: UpdateRoomParams): Promise<Room>
}

@injectable()
export class UpdateRoomActionLive implements UpdateRoomAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateRoomParams): Promise<Room> {
        // TODO pass only changed fields (look at menu update or location update)
        const request: RoomUpdate.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            roomId: params.roomId,
            isOpen: params.isOpen,
            name: params.name,
            size: params.size,
        }
        const response = await this.avokado.room.update(request)
        return mapRoomToModel(response)
    }
}

@injectable()
export class UpdateRoomActionFake implements UpdateRoomAction {
    async run(params: UpdateRoomParams): Promise<Room> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateRoomActionFail implements UpdateRoomAction {
    async run(params: UpdateRoomParams): Promise<Room> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
