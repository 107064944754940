import { useBoolean } from '@chakra-ui/react'
import { loadStripeTerminal } from '@stripe/terminal-js'
import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { CardReaderDependencies } from '../dependencies'
import { CardReader } from '../models/CardReader'

export function useCardReaders(id: Location.ID, stripeId: string | undefined) {
    const fetch = useInjection(CardReaderDependencies.Init)

    const [isLoadingSdk, setIsLoadingSdk] = useBoolean(stripeId !== undefined)
    const [isUpdatingReaders, setIsUpdatingReaders] = useBoolean()
    const [cardReaders, setCardReaders] = useState<CardReader[]>([])

    const terminalServiceTask = useAsync(async () => {
        if (!stripeId) return undefined
        try {
            const StripeTerminal = await loadStripeTerminal()
            if (!StripeTerminal) {
                throw new Error('Reader Configuration Error') // TODO
            }

            return StripeTerminal.create({
                onFetchConnectionToken: async () => {
                    return fetch.run(id)
                },
                onUnexpectedReaderDisconnect: console.error, // TODO
                onConnectionStatusChange: console.log, // TODO
                onPaymentStatusChange: console.log, // TODO
            })
        } finally {
            setIsLoadingSdk.off()
        }
    }, [stripeId])

    async function updateReaders() {
        const terminalService = terminalServiceTask.value
        if (!terminalService) return
        setIsUpdatingReaders.on()
        try {
            const readers = await terminalService.discoverReaders({
                method: 'internet',
                simulated: false,
                location: stripeId,
            })

            if ('discoveredReaders' in readers) {
                setCardReaders(
                    readers.discoveredReaders.map((reader) => ({
                        id: { ...id, cardReaderId: reader.id },
                        label: reader.label,
                        status:
                            reader.status === 'online'
                                ? 'online'
                                : reader.status === 'offline'
                                ? 'offline'
                                : 'unknown',
                        type: reader.device_type,
                        serialNumber: reader.serial_number,
                        softwareVersion: reader.device_sw_version,
                    }))
                )
            } else {
                // TODO
                // readers.error
            }
        } catch (e: unknown) {
        } finally {
            setIsUpdatingReaders.off()
        }
    }

    useEffect(() => {
        if (terminalServiceTask.value) {
            updateReaders()
        }
    }, [terminalServiceTask])

    return { cardReaders, isUpdatingReaders, isLoadingSdk, updateReaders }
}
