import _ from 'lodash'
import { Weekdays } from '../models/Calendar'

function formatComponent(number: number) {
    return _.padStart(number.toString(), 2, '0')
}

export function formatTime(time: Weekdays.TimeRangeSchedule.Time) {
    return `${formatComponent(time.hours)}:${formatComponent(time.minutes)}`
}

export function formatTimeRange(range: Weekdays.TimeRangeSchedule.Range) {
    return `${formatTime(range.from)} - ${formatTime(range.to)}`
}
