export const FadeToLeft = {
    initial: 'initial',
    animate: 'in',
    variants: {
        initial: {
            opacity: 0,
            translateX: 10,
        },
        in: {
            opacity: 1,
            translateX: 0,
        },
        out: {
            opacity: 0,
            translateX: -10,
        },
    },
    transition: {
        type: 'tween',
        ease: 'linear',
        duration: 0.5,
    },
}
