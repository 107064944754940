import { MenuSectionDependencies } from 'features/menu/dependencies/section'
import { Menu } from 'features/menu/models/Menu'
import { useInjection } from 'inversify-react'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'

export interface UseNewSectionFormDataHook {
    name: string
    description: string
    reset: () => void
    setName: Dispatch<SetStateAction<string>>
    setDescription: Dispatch<SetStateAction<string>>
}

export function useNewSectionFormData(): UseNewSectionFormDataHook {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const reset = useCallback(() => {
        setName('')
        setDescription('')
    }, [])

    return {
        name,
        description,
        reset,
        setName,
        setDescription,
    }
}

export function useNewSectionFormController(
    menu: Menu,
    params: UseNewSectionFormDataHook,
    onSave: (menu: Menu) => void
): UseFormControllerHook<void> {
    const create = useInjection(MenuSectionDependencies.Create)

    const [isProcessing, setIsProcessing] = useState(false)

    const usedNames = useMemo(
        () => menu.sections.map((section) => section.name),
        [menu]
    )

    const canSubmit = useMemo(() => {
        if (params.name === '') return false
        if (usedNames.includes(params.name)) return false
        return true
    }, [params])

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)

        try {
            const newMenu = await create.run({
                menu: menu,
                name: params.name,
                description: params.description,
            })
            params.reset()
            onSave(newMenu)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
