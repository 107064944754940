import Avokado, {
    AvokadoAPIError,
    MenuAssignmentUpdate,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import {
    mapModelToAvailabilityTimes,
    mapModelToRestrictions,
} from '../mappers/SDKRequestMapper'
import { mapMenuAssignmentToModel } from '../mappers/SDKResponseMapper'
import { MenuAssignment } from '../models/MenuAssignment'

interface UpdateMenuAssignmentParams extends Omit<MenuAssignment.ID, 'menuId'> {
    restrictions?: MenuAssignment.Restriction[]
    incompatibleMenuIds?: number[]
    availabilities?: MenuAssignment.Availabilities
    isActive?: boolean
    menuId?: number
}

export interface UpdateMenuAssignmentAction {
    run(params: UpdateMenuAssignmentParams): Promise<MenuAssignment>
}

@injectable()
export class UpdateMenuAssignmentActionLive
    implements UpdateMenuAssignmentAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateMenuAssignmentParams): Promise<MenuAssignment> {
        const request: MenuAssignmentUpdate.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            menuAssignmentId: params.menuAssignmentId,
            isActive: params.isActive,
            restrictions:
                params.restrictions !== undefined
                    ? mapModelToRestrictions(params.restrictions)
                    : undefined,
            incompatibleMenusIds: params.incompatibleMenuIds,
            availabilities:
                params.availabilities !== undefined
                    ? mapModelToAvailabilityTimes(params.availabilities)
                    : undefined,
        }
        const response = await this.avokado.menuAssignment.update(request)
        return mapMenuAssignmentToModel(response)
    }
}

@injectable()
export class UpdateMenuAssignmentActionFake
    implements UpdateMenuAssignmentAction
{
    async run(params: UpdateMenuAssignmentParams): Promise<MenuAssignment> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateMenuAssignmentActionFail
    implements UpdateMenuAssignmentAction
{
    async run(params: UpdateMenuAssignmentParams): Promise<MenuAssignment> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
