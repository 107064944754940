import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
    Button,
    Circle,
    Divider,
    GridItem,
    HStack,
    Heading,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    SimpleGrid,
    Spacer,
    Text,
    Tooltip,
    VStack,
    useConst,
    useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useVerifyOrganization } from 'features/organization/hooks/useVerifyOrganization'
import { getLocale } from 'localization/mapper/InternalMapper'
import _ from 'lodash'
import { unparse } from 'papaparse'
import { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'uikit/container/Container'
import { FormSection, FormStructure } from 'uikit/form'
import { MonthPicker, NumberInput } from 'uikit/form/input'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { LoadingIndicator } from 'utils/loading'
import { usePriceFormatter } from 'utils/types/formatters'
import {
    usePayoutPreferencesFormController,
    usePayoutPreferencesFormData,
} from '../hooks/usePayoutForm'
import { usePayoutPreferences } from '../hooks/usePayoutPreferences'
import { usePayouts } from '../hooks/usePayouts'
import { describePayout as describe } from '../mapper/payout/InternalMapper'
import { Payout } from '../models/Payout'
import { PayoutPreferences } from '../models/PayoutPreferences'

export function PayoutTab() {
    const organizationManager = useOrganizationContext()
    const payoutManager = usePayoutPreferences(
        organizationManager.organization!.id
    )

    if (payoutManager.isLoading || !payoutManager.preferences) {
        return <LoadingIndicator />
    }

    return (
        <PayoutContent
            preferences={payoutManager.preferences}
            update={payoutManager.update}
        />
    )
}

interface PayoutContentProps {
    preferences: PayoutPreferences
    update: (preferences: PayoutPreferences) => void
}

function PayoutContent({ preferences, update }: PayoutContentProps) {
    const { t, i18n } = useTranslation('payout')

    const verifyOrganization = useVerifyOrganization('update')

    const organizationManager = useOrganizationContext()

    const formData = usePayoutPreferencesFormData(preferences)
    const controller = usePayoutPreferencesFormController(
        organizationManager.organization!,
        formData,
        update
    )

    const { isLoading, payouts, month, changeMonth } = usePayouts(
        organizationManager.organization!.id
    )

    const exportToCSV = useCallback(() => {
        const data = payouts.map((payout) => {
            const formatter = new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: payout.currency,
            })

            return {
                [t('execution_date_label')]:
                    payout.executionDate.toLocaleDateString(),
                [t('amount_label')]: formatter.format(payout.amount / 100),
                [t('expected_arrival_date_label')]:
                    payout.expectedArrivalDate?.toLocaleDateString(),
                [t('status_label')]: t('status_' + payout.status),
                [t('reference_number_label')]: payout.id.stripeId,
                [t('bank_label')]: payout.destination?.name,
                [t('account_number_label')]: payout.destination?.last4,
                [t('global_trace_id_label')]: payout.id.globalTraceId,
            }
        })

        const filename = format(month, 'MMMM_yyyy', {
            locale: getLocale(i18n.language),
        }).toLowerCase()

        const csv = unparse(data)
        const blob = new Blob([csv], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = filename + '.csv'
        link.click()

        URL.revokeObjectURL(url)
    }, [i18n.language, month, payouts, t])

    return (
        <>
            <Container
                title={t('preferences_title')}
                header={
                    <Button
                        isDisabled={!controller.canSubmit}
                        isLoading={controller.isProcessing}
                        onClick={controller.submit}
                    >
                        {t('save_button')}
                    </Button>
                }
            >
                <FormStructure>
                    <FormSection
                        title={t('interval_input_label')}
                        subtitle={t('interval_input_support')}
                    >
                        <NumberInput
                            isRequired
                            min={1}
                            max={14}
                            value={formData.interval}
                            setValue={formData.setInterval}
                        />
                    </FormSection>
                </FormStructure>
                <Divider />
                <HStack>
                    <VStack w="full" align="start">
                        <Heading size="sm" fontWeight="normal">
                            {t('bank_account_label')}
                        </Heading>
                        <Text color="label2">
                            {t('bank_account_description')}
                        </Text>
                    </VStack>
                    <Spacer />
                    <Button
                        onClick={verifyOrganization.startVerificationProcess}
                        isLoading={verifyOrganization.isProcessing}
                    >
                        {t('bank_account_update_button')}
                    </Button>
                </HStack>
            </Container>

            <Container
                pt={8}
                title={t('past_payouts_title')}
                header={
                    <>
                        <MonthPicker
                            allowedDates="past"
                            isDisabled={isLoading}
                            date={month}
                            onChange={changeMonth}
                        />
                        <Button onClick={exportToCSV}>
                            {t('export_to_csv_button')}
                        </Button>
                    </>
                }
            >
                {isLoading ? (
                    <LoadingIndicator />
                ) : payouts.length === 0 ? (
                    <Text>{t('no_past_payouts_message')}</Text>
                ) : (
                    <VStack w="full" align="stretch">
                        {payouts.map((payout) => (
                            <PayoutCard
                                key={payout.id.payoutId}
                                payout={payout}
                            />
                        ))}
                    </VStack>
                )}
            </Container>
        </>
    )
}

interface PayoutCardProps {
    payout: Payout
}

function PayoutCard({ payout }: PayoutCardProps) {
    const { t, i18n } = useTranslation('payout')

    const screen = useScreenBreakpoint()

    const priceFormatter = usePriceFormatter(payout.currency)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const info = useConst(() => describe(payout))

    return (
        <>
            <HStack
                p={4}
                borderRadius="lg"
                bg="pageBackground3"
                cursor="pointer"
                onClick={onOpen}
            >
                <Text flex={1}>
                    {payout.executionDate.toLocaleDateString(i18n.language, {
                        dateStyle: 'long',
                    })}
                </Text>

                <Text textAlign="end" px={4}>
                    {priceFormatter.format(payout.amount)}
                </Text>

                <Tooltip hasArrow label={t('status_' + payout.status)}>
                    <Circle size={4} bg={info.color} />
                </Tooltip>
            </HStack>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
                <ModalOverlay />
                <ModalContent p={8} borderRadius="2xl" bg="sheetBackground1">
                    <ModalCloseButton
                        size="sm"
                        borderRadius="full"
                        top={2}
                        right={2}
                    />
                    <SimpleGrid columns={screen === 'mobile' ? 1 : 2} gap={8}>
                        <PayoutModalItem
                            label={t('reference_number_label')}
                            value={payout.id.stripeId}
                        />

                        <PayoutModalItem label={t('status_label')}>
                            <HStack>
                                <Circle size={4} bg={info.color} />
                                <Text>{t('status_' + payout.status)}</Text>
                            </HStack>
                        </PayoutModalItem>

                        <PayoutModalItem
                            label={t('execution_date_label')}
                            value={_.capitalize(
                                payout.executionDate.toLocaleDateString(
                                    i18n.language,
                                    { dateStyle: 'full' }
                                )
                            )}
                        />

                        <PayoutModalItem
                            label={t('expected_arrival_date_label')}
                        >
                            {payout.expectedArrivalDate ? (
                                <Text>
                                    {_.capitalize(
                                        payout.expectedArrivalDate.toLocaleDateString(
                                            i18n.language,
                                            { dateStyle: 'full' }
                                        )
                                    )}
                                </Text>
                            ) : (
                                <HStack>
                                    <Tooltip
                                        hasArrow
                                        label={t(
                                            'expected_arrival_date_not_available_explanation'
                                        )}
                                        bg="sheetBackground2"
                                    >
                                        <QuestionOutlineIcon />
                                    </Tooltip>

                                    <Text
                                        fontWeight="light"
                                        fontStyle="italic"
                                        color="label2"
                                    >
                                        {t(
                                            'expected_arrival_date_not_available'
                                        )}
                                    </Text>
                                </HStack>
                            )}
                        </PayoutModalItem>

                        {payout.destination && (
                            <>
                                <PayoutModalItem
                                    label={t('bank_label')}
                                    value={payout.destination.name}
                                />

                                <PayoutModalItem
                                    label={t('account_number_label')}
                                    value={`●●●● ${payout.destination.last4}`}
                                />
                            </>
                        )}

                        <PayoutModalItem label={t('global_trace_id_label')}>
                            {payout.id.globalTraceId ? (
                                <Text>{payout.id.globalTraceId}</Text>
                            ) : (
                                <HStack>
                                    <Tooltip
                                        hasArrow
                                        label={t(
                                            'global_trace_id_not_available_explanation'
                                        )}
                                        bg="sheetBackground2"
                                    >
                                        <QuestionOutlineIcon />
                                    </Tooltip>

                                    <Text
                                        fontWeight="light"
                                        fontStyle="italic"
                                        color="label2"
                                    >
                                        {t('global_trace_id_not_available')}
                                    </Text>
                                </HStack>
                            )}
                        </PayoutModalItem>
                    </SimpleGrid>
                </ModalContent>
            </Modal>
        </>
    )
}

type PayoutModalItemProps =
    | PayoutModalValueItemProps
    | PayoutModalChildrenItemProps

interface PayoutModalValueItemProps {
    label: string
    value: string
}

interface PayoutModalChildrenItemProps {
    label: string
    children: ReactNode
}

function PayoutModalItem({ label, ...props }: PayoutModalItemProps) {
    const content: ReactNode =
        'value' in props ? <Text>{props.value}</Text> : props.children

    return (
        <GridItem>
            <VStack w="full" align="stretch" spacing={0}>
                <Text color="label2" fontWeight="semibold" fontSize="sm">
                    {label.toUpperCase()}
                </Text>
                {content}
            </VStack>
        </GridItem>
    )
}
