import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import axios from 'axios'
import {
    ConfigurationDependencies,
    ConfigurationStore,
} from 'features/config/dependencies'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Cognito } from '../models/CognitoModels'

interface ForgotPasswordParams {
    email: string
}

export interface ForgotPasswordAction {
    run(params: ForgotPasswordParams): Promise<void>
}

@injectable()
export class ForgotPasswordActionLive implements ForgotPasswordAction {
    @inject(ConfigurationDependencies.Store) private config!: ConfigurationStore

    async run(params: ForgotPasswordParams): Promise<void> {
        const configuration = await this.config.configuration()

        const request: Cognito.Request.ForgotPassword = {
            Username: params.email,
            ClientId: configuration.aws.userPool.dashboardClientId,
        }

        await axios.post(
            `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
            request,
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target':
                        'AWSCognitoIdentityProviderService.ForgotPassword',
                },
            }
        )
    }
}

@injectable()
export class ForgotPasswordActionFake implements ForgotPasswordAction {
    async run(params: ForgotPasswordParams): Promise<void> {
        await sleep(3000)
    }
}

@injectable()
export class ForgotPasswordActionFail implements ForgotPasswordAction {
    async run(params: ForgotPasswordParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
