import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'
import { Labels } from './ScheduleForm'

interface FullDayLabelProps {
    isCheckedOpen: boolean
    timeRangesCount: number
    selectedDaysCount: number
    labels: Labels
}

export default function FullDayLabel({
    isCheckedOpen,
    timeRangesCount,
    labels,
    selectedDaysCount,
}: FullDayLabelProps) {
    const isCheckedClosed = !isCheckedOpen && timeRangesCount === 0
    if ((!isCheckedClosed && !isCheckedOpen) || selectedDaysCount === 0) {
        return null
    }

    return (
        <Alert
            status={isCheckedOpen ? 'success' : 'warning'}
            borderRadius={8}
            boxShadow="sm"
        >
            <AlertIcon />
            <AlertDescription>
                {isCheckedOpen
                    ? labels.fullDayActiveAlert
                    : labels.fullDayInactiveAlert}
            </AlertDescription>
        </Alert>
    )
}
