import {
    Card,
    CardBody,
    CardHeader,
    Center,
    Grid,
    GridItem,
    HStack,
    Heading,
    Text,
} from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import useOrganizationContext from '../contexts/OrganizationContext'
import { Organization } from '../models/Organization'

export default function OrganizationsPage() {
    const { t } = useTranslation()

    const organizationManager = useOrganizationContext()
    const navigate = useNavigate()

    const handleSelection = useCallback((organization: Organization) => {
        organizationManager.load(organization.id.organizationId)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organizationManager.organizations.length === 0) {
            navigate('new')
        } else if (organizationManager.organizations.length === 1) {
            handleSelection(organizationManager.organizations[0])
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Center flex={1}>
            <Container
                isCompact
                title={t('Your organizations')}
                subtitle={t('Which organization would you like to manage?')}
                w="4xl"
                my={8}
            >
                <Grid
                    templateColumns="repeat(auto-fill, minmax(300px,1fr))"
                    w="full"
                    gap={6}
                >
                    {organizationManager.organizations.map((organization) => (
                        <GridItem key={organization.id.organizationId}>
                            <Card
                                bg="pageBackground3"
                                cursor="pointer"
                                onClick={() => handleSelection(organization)}
                            >
                                <CardHeader>
                                    <HStack align="end">
                                        <Heading size="md">
                                            {organization.name}
                                        </Heading>
                                        <Text fontSize="sm">
                                            #{organization.id.organizationId}
                                        </Text>
                                    </HStack>
                                </CardHeader>
                                <CardBody>
                                    <Text>{`${t('Enter as')} ${
                                        organization.role
                                    }`}</Text>
                                </CardBody>
                            </Card>
                        </GridItem>
                    ))}
                </Grid>
            </Container>
        </Center>
    )
}
