import { DateDependencies, DateGenerator } from 'infra/di/factories/Date'
import { inject, injectable } from 'inversify'
import jwt_decode from 'jwt-decode'
import 'reflect-metadata'
import LocalStorageKeys from 'utils/constant/LocalStorageKeys'
import { AuthDependencies, RefreshTokenAction } from '.'
import { AuthError } from '../errors'

export interface AuthStore {
    getAccessToken(): Promise<string | undefined>
    getIdToken(): Promise<string | undefined>
    getRefreshToken(): Promise<string | undefined>
    setAccessToken(accessToken: string | null): void
    setIdToken(idToken: string | null): void
    setRefreshToken(refreshToken: string | null): void
    assertAccessToken(): Promise<string>
    assertIdToken(): Promise<string>
    assertRefreshToken(): Promise<string>
    getFutureUsageIdToken(): string | null
    removeFutureUsageIdToken(): void
}

interface TokenPayload {
    exp: number
}

@injectable()
export class AuthStoreLive implements AuthStore {
    @inject(DateDependencies.Generator) private date!: DateGenerator
    @inject(AuthDependencies.RefreshToken) private refresh!: RefreshTokenAction

    async getAccessToken(): Promise<string | undefined> {
        const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken)
        if (!accessToken) return undefined

        const now = this.date().getTime() / 1000 // convert to seconds
        const expiresAt = jwt_decode<TokenPayload>(accessToken).exp
        const expiresIn = expiresAt - now

        try {
            if (expiresIn < 300) {
                const tokenSet = await this.refresh.run({
                    refreshToken: await this.assertRefreshToken(),
                })
                this.setAccessToken(tokenSet.accessToken)
                this.setIdToken(tokenSet.idToken)
                return tokenSet.accessToken
            }

            return accessToken
        } catch (e) {
            if (e instanceof AuthError.SessionExpired) {
                return
            }

            throw e
        }
    }

    async getIdToken(): Promise<string | undefined> {
        const idToken = localStorage.getItem(LocalStorageKeys.IdToken)
        if (!idToken) return undefined

        const now = this.date().getTime() / 1000 // convert to seconds
        const expiresAt = jwt_decode<TokenPayload>(idToken).exp
        const expiresIn = expiresAt - now

        try {
            if (expiresIn < 300) {
                const tokenSet = await this.refresh.run({
                    refreshToken: await this.assertRefreshToken(),
                })
                this.setAccessToken(tokenSet.accessToken)
                this.setIdToken(tokenSet.idToken)
                return tokenSet.idToken
            }

            return idToken
        } catch (e) {
            if (e instanceof AuthError.SessionExpired) {
                return
            }

            throw e
        }
    }

    async getRefreshToken(): Promise<string | undefined> {
        return localStorage.getItem(LocalStorageKeys.RefreshToken) ?? undefined
    }

    setAccessToken(accessToken: string | null): void {
        if (accessToken) {
            localStorage.setItem(LocalStorageKeys.AccessToken, accessToken)
        } else {
            localStorage.removeItem(LocalStorageKeys.AccessToken)
        }
    }

    setIdToken(idToken: string | null): void {
        if (idToken) {
            localStorage.setItem(LocalStorageKeys.IdToken, idToken)
            localStorage.setItem(LocalStorageKeys.FutureUsageIdToken, idToken)
        } else {
            localStorage.removeItem(LocalStorageKeys.IdToken)
        }
    }

    setRefreshToken(refreshToken: string | null): void {
        if (refreshToken) {
            localStorage.setItem(LocalStorageKeys.RefreshToken, refreshToken)
        } else {
            localStorage.removeItem(LocalStorageKeys.RefreshToken)
        }
    }

    async assertAccessToken(): Promise<string> {
        const accessToken = await this.getAccessToken()
        if (!accessToken) {
            throw new AuthError.NotSignedIn()
        }
        return accessToken
    }

    async assertIdToken(): Promise<string> {
        const idToken = await this.getIdToken()
        if (!idToken) {
            throw new AuthError.NotSignedIn()
        }
        return idToken
    }

    async assertRefreshToken(): Promise<string> {
        const refreshToken = await this.getRefreshToken()
        if (!refreshToken) {
            throw new AuthError.NotSignedIn()
        }
        return refreshToken
    }

    getFutureUsageIdToken(): string | null {
        return localStorage.getItem(LocalStorageKeys.FutureUsageIdToken)
    }

    removeFutureUsageIdToken(): void {
        localStorage.removeItem(LocalStorageKeys.FutureUsageIdToken)
    }
}
