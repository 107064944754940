import useLocationsContext from 'features/location/contexts/LocationsContext'
import useMenusContext from 'features/menu/contexts/MenusContext'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { OrganizationDependencies } from '../dependencies'
import { ManagedOrganization } from '../models/ManagedOrganization'
import { Organization } from '../models/Organization'
import { OrganizationContext } from './OrganizationContext'

export default function OrganizationContextProvider(props: PropsWithChildren) {
    const retrieve = useInjection(OrganizationDependencies.Retrieve)

    const locationsManager = useLocationsContext()
    const menusManager = useMenusContext()

    const location = useLocation()
    const navigate = useNavigate()

    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [organization, setOrganization] = useState<Organization>()

    const assertOrganization = useCallback(() => {
        if (organization) return organization
        throw new Error('Organization was null')
    }, [organization])

    const init = useCallback(
        async (managedOrganizations: ManagedOrganization[]) => {
            const tasks = managedOrganizations.map(async (organization) => {
                return await retrieve.run(organization)
            })

            setOrganizations(await Promise.all(tasks))
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const load = useCallback(
        (id: string, redirect?: string) => {
            const organization = organizations.find(
                (organization) => organization.id.organizationId === id
            )

            if (organization) {
                locationsManager.load(id)
                menusManager.load(id)
                setOrganization(organization)

                navigate(redirect ?? `organizations/${id}`)
            }
        },
        [organizations] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const add = useCallback((organization: Organization) => {
        locationsManager.load(organization.id.organizationId)
        menusManager.load(organization.id.organizationId)

        setOrganizations((previous) =>
            _.sortBy(
                [...previous, organization],
                (organization) => organization.id.organizationId
            )
        )

        setOrganization(organization)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const clear = useCallback(() => {
        setOrganizations([])
        setOrganization(undefined)
        locationsManager.clear()
        menusManager.clear()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (organization) return

        const matches = location.pathname.match(/\/organizations\/(\d{8})/)

        if (matches && matches.length === 2) {
            const organizationId = matches[1]
            load(organizationId, location.pathname + location.search)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, organizations])

    return (
        <OrganizationContext.Provider
            value={{
                organization,
                organizations,
                assertOrganization,
                init,
                load,
                add,
                clear,
            }}
        >
            {props.children}
        </OrganizationContext.Provider>
    )
}
