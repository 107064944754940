import {
    Alert,
    AlertDescription,
    AlertTitle,
    Box,
    Button,
    Center,
    Collapse,
    Link,
    ListItem,
    Modal,
    ModalContent,
    ModalOverlay,
    OrderedList,
    Stack,
    UnorderedList,
    VStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import _ from 'lodash'
import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { useDetectDevice } from 'utils/mobile/isSmartphone'
import { useTerminalProvision } from '../hooks/useTerminalProvision'

interface ConnectTerminalProps {
    isVisible: boolean
}

export function ConnectTerminal({ isVisible }: ConnectTerminalProps) {
    const t = useT('terminal')

    const { code, validity, isProcessing, retrieve } = useTerminalProvision()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [millisecondsLeft, setMillisecondsLeft] = useState(0)
    const [isExpired, setIsExpired] = useState(true)

    useEffect(() => {
        if (validity) {
            const now = new Date()
            setIsExpired(false)
            setMillisecondsLeft(validity.to.getTime() - now.getTime())
        }
    }, [validity])

    useEffect(() => {
        const interval = setInterval(() => {
            if (!validity) return

            const now = new Date()
            if (now > validity.to) {
                if (isExpired) return
                setIsExpired(true)
                onClose()
            }

            const millisecondsLeft = validity.to.getTime() - now.getTime()
            setMillisecondsLeft(millisecondsLeft)
        }, 250)

        return () => clearInterval(interval)
    }, [isExpired, validity])

    const device = useDetectDevice()
    const screen = useScreenBreakpoint()

    return (
        <>
            <Container
                title={t('provisioning_instructions_title')}
                isVisible={isVisible}
                headerProps={{ align: 'start' }}
            >
                <Stack
                    direction={screen === 'mobile' ? 'column' : 'row'}
                    flex={1}
                >
                    <VStack gap={8} flex={1} align="center" justify="center">
                        {device !== undefined && (
                            <LinkButtonComponent
                                isExpired={isExpired}
                                retrieve={retrieve}
                                onOpen={onOpen}
                                code={code}
                                millisecondsLeft={millisecondsLeft}
                                isProcessing={isProcessing}
                            />
                        )}
                        {device === undefined && (
                            <QRCodeComponent
                                isExpired={isExpired}
                                retrieve={retrieve}
                                onOpen={onOpen}
                                code={code}
                                millisecondsLeft={millisecondsLeft}
                                isProcessing={isProcessing}
                            />
                        )}
                    </VStack>
                    <VStack flex={1}>
                        {device === 'iPhone' && <IPhoneInstructions />}
                        {device === 'android' && <AndroidInstructions />}
                        {device === undefined && <DesktopInstructions />}
                    </VStack>
                </Stack>
            </Container>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="full">
                <ModalOverlay />
                <ModalContent
                    bg="transparent"
                    pointerEvents="none"
                    h="full"
                    p={8}
                >
                    <Center w="full" h="full">
                        <Box
                            filter={isExpired ? 'blur(5px)' : 'unset'}
                            borderRadius="3xl"
                            overflow="hidden"
                        >
                            <QRCodeSVG
                                size={512}
                                value={code ?? ''}
                                includeMargin
                            />
                        </Box>
                    </Center>
                </ModalContent>
            </Modal>
        </>
    )
}

function DesktopInstructions() {
    const t = useT('terminal')
    return (
        <OrderedList w="full" spacing={8}>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_desktop_1" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_desktop_2" />
            </ListItem>
            <Alert status="info" w="full" borderRadius={8}>
                <Box w="full">
                    <AlertTitle>
                        {t('provisioning_instructions_note')}
                    </AlertTitle>
                    <AlertDescription>
                        {t('provisioning_instructions_desktop_2_note')}
                    </AlertDescription>
                </Box>
            </Alert>
            <ListItem>
                <Trans
                    t={t}
                    i18nKey="provisioning_instructions_generated_terminal_code"
                    components={[
                        <Button
                            variant="link"
                            as={Link}
                            href="https://terminal.avokadoapp.ch"
                            isExternal
                        />,
                    ]}
                />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_desktop_4" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_choose" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_done" />
            </ListItem>
        </OrderedList>
    )
}

function IPhoneInstructions() {
    const t = useT('terminal')
    return (
        <OrderedList w="full" spacing={8}>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_iphone_1" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_iphone_2" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_iphone_2_16" />
                <UnorderedList spacing={4} mt={4}>
                    <ListItem>
                        <Trans
                            t={t}
                            i18nKey="provisioning_instructions_iphone_2_16_1"
                        />
                    </ListItem>
                    <ListItem>
                        <Trans
                            t={t}
                            i18nKey="provisioning_instructions_iphone_2_16_2"
                        />
                    </ListItem>
                </UnorderedList>
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_iphone_3" />
            </ListItem>
            <Alert status="info" w="full" borderRadius={8}>
                <Box w="full">
                    <AlertTitle>
                        {t('provisioning_instructions_note')}
                    </AlertTitle>
                    <AlertDescription>
                        {t('provisioning_instructions_iphone_note')}
                    </AlertDescription>
                </Box>
            </Alert>
            <ListItem>
                <Trans
                    t={t}
                    i18nKey="provisioning_instructions_generated_terminal_code"
                    components={[
                        <Button
                            variant="link"
                            as={Link}
                            href="https://terminal.avokadoapp.ch"
                            isExternal
                        />,
                    ]}
                />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_iphone_5" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_choose" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_done" />
            </ListItem>
        </OrderedList>
    )
}

function AndroidInstructions() {
    const t = useT('terminal')
    return (
        <OrderedList w="full" spacing={8}>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_android_1" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_android_2" />
            </ListItem>
            <Alert status="info" w="full" borderRadius={8}>
                <Box w="full">
                    <AlertTitle>
                        {t('provisioning_instructions_note')}
                    </AlertTitle>
                    <AlertDescription>
                        {t('provisioning_instructions_android_2_note')}
                    </AlertDescription>
                </Box>
            </Alert>
            <ListItem>
                <Trans
                    t={t}
                    i18nKey="provisioning_instructions_generated_terminal_code"
                    components={[
                        <Button
                            variant="link"
                            as={Link}
                            href="https://terminal.avokadoapp.ch"
                            isExternal
                        />,
                    ]}
                />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_android_4" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_choose" />
            </ListItem>
            <ListItem>
                <Trans t={t} i18nKey="provisioning_instructions_done" />
            </ListItem>
        </OrderedList>
    )
}

interface QRCodeComponentProps {
    isExpired: boolean
    onOpen: () => void
    code: string | undefined
    isProcessing: boolean
    retrieve: () => Promise<void>
    millisecondsLeft: number
}

function QRCodeComponent({
    isExpired,
    onOpen,
    code,
    retrieve,
    isProcessing,
    millisecondsLeft,
}: QRCodeComponentProps) {
    const t = useT('terminal')
    const fgColor = useColorModeValue('black', 'white')
    return (
        <>
            <Box
                filter={isExpired ? 'blur(5px)' : 'unset'}
                borderRadius="md"
                overflow="hidden"
                onClick={() => {
                    if (isExpired) return
                    onOpen()
                }}
                cursor={isExpired ? 'default' : 'pointer'}
                transition="0.4s filter ease-in-out"
            >
                <QRCodeSVG
                    size={350}
                    value={code ?? ''}
                    fgColor={fgColor}
                    bgColor="transparent"
                />
            </Box>
            <Button
                minW={300}
                isLoading={isProcessing}
                isDisabled={!isExpired}
                onClick={retrieve}
                mb={8}
            >
                {isExpired
                    ? t('generate_qr_code_button')
                    : t('qr_code_lifetime_sections', {
                          count: _.floor(millisecondsLeft / 1000),
                      })}
            </Button>
        </>
    )
}

function LinkButtonComponent({
    isExpired,
    code,
    retrieve,
    isProcessing,
    millisecondsLeft,
}: QRCodeComponentProps) {
    const t = useT('terminal')
    const device = useDetectDevice()
    return (
        <>
            <Button
                w="full"
                alignSelf="end"
                isLoading={isProcessing}
                isDisabled={!isExpired}
                onClick={retrieve}
                variant="outline"
                mt={4}
            >
                {isExpired
                    ? t('generate_clip_link_button')
                    : t('qr_code_lifetime_sections', {
                          count: _.floor(millisecondsLeft / 1000),
                      })}
            </Button>
            <Box
                as={Collapse}
                in={code !== undefined && code !== '' && !isExpired}
                w="full"
            >
                <Button as={Link} isExternal href={code} w="full">
                    {device === 'iPhone' && t('open_clip_link_button')}
                    {device === 'android' && t('open_instant_link_button')}
                </Button>
            </Box>
        </>
    )
}
