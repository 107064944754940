import { Location } from 'features/location/models/Location'

export function describeCategory(category: Location.Category) {
    switch (category) {
        case Location.Category.Restaurant:
            return 'category_restaurant'
        case Location.Category.RestaurantPizzeria:
            return 'category_restaurant_pizzeria'
        case Location.Category.Pizzeria:
            return 'category_pizzeria'
        case Location.Category.Sushi:
            return 'category_sushi'
        case Location.Category.FastFood:
            return 'category_fast_food'
        case Location.Category.Tavern:
            return 'category_tavern'
        case Location.Category.Trattoria:
            return 'category_trattoria'
        case Location.Category.Bar:
            return 'category_bar'
        case Location.Category.Bistrot:
            return 'category_bistrot'
        case Location.Category.Pub:
            return 'category_pub'
        case Location.Category.Steakhouse:
            return 'category_steakhouse'
        case Location.Category.Grotto:
            return 'category_grotto'
        case Location.Category.Imbiss:
            return 'category_imbiss'
        case Location.Category.Rotisserie:
            return 'category_rotisserie'
        case Location.Category.HamburgerShop:
            return 'category_hamburger_shop'
        case Location.Category.KebabShop:
            return 'category_kebab_shop'
        case Location.Category.Piadineria:
            return 'category_piadineria'
        case Location.Category.SandwichShop:
            return 'category_sandwich_shop'
        case Location.Category.IceCreamParlor:
            return 'category_ice_cream_parlor'
        case Location.Category.PastryShop:
            return 'category_pastry_shop'
        case Location.Category.MeatSpecialties:
            return 'category_meat_specialties'
        case Location.Category.SeafoodSpecialties:
            return 'category_seafood_specialties'
        case Location.Category.LocalCuisine:
            return 'category_local_cuisine'
        case Location.Category.MediterraneanCuisine:
            return 'category_mediterranean_cuisine'
        case Location.Category.ItalianCuisine:
            return 'category_italian_cuisine'
        case Location.Category.FrenchCuisine:
            return 'category_french_cuisine'
        case Location.Category.GreekCuisine:
            return 'category_greek_cuisine'
        case Location.Category.AmericanCuisine:
            return 'category_american_cuisine'
        case Location.Category.MexicanCuisine:
            return 'category_mexican_cuisine'
        case Location.Category.ChineseCuisine:
            return 'category_chinese_cuisine'
        case Location.Category.ThaiCuisine:
            return 'category_thai_cuisine'
        case Location.Category.IndianCuisine:
            return 'category_indian_cuisine'
    }
}
