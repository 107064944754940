import { useInjection } from 'inversify-react'
import _ from 'lodash'
import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { LocationDependencies } from '../dependencies'
import { Location } from '../models/Location'
import { LocationCompact } from '../models/LocationCompact'
import { LocationsContext } from './LocationsContext'

export default function LocationsContextProvider(props: PropsWithChildren) {
    const list = useInjection(LocationDependencies.List)

    const [isLoading, setIsLoading] = useState(false)
    const [locations, setLocations] = useState<LocationCompact[]>([])
    const cache = useMemo(() => new Map<number, Location>(), [])

    const load = useCallback(async (id: string) => {
        setIsLoading(true)
        cache.clear()
        setLocations(await list.run({ organizationId: id }))
        setIsLoading(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const clear = useCallback(() => {
        setIsLoading(false)
        setLocations([])
        cache.clear()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieve = useCallback((id: Location.ID) => {
        const stored = cache.get(id.locationId)
        if (stored) return _.cloneDeep(stored)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const update = useCallback((location: Location) => {
        const safeLocation = _.cloneDeep(location)
        cache.set(safeLocation.id.locationId, safeLocation)
        setLocations((previous) => {
            if (
                previous.find(
                    (item) => item.id.locationId === safeLocation.id.locationId
                )
            ) {
                return previous.map((item) => {
                    if (item.id.locationId === safeLocation.id.locationId) {
                        return {
                            id: safeLocation.id,
                            name: safeLocation.name,
                            published: safeLocation.published,
                            medias: safeLocation.medias,
                        }
                    } else {
                        return item
                    }
                })
            } else {
                return [
                    ...previous,
                    {
                        id: safeLocation.id,
                        name: safeLocation.name,
                        published: safeLocation.published,
                        medias: safeLocation.medias,
                    },
                ]
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LocationsContext.Provider
            value={{ locations, isLoading, load, retrieve, update, clear }}
        >
            {props.children}
        </LocationsContext.Provider>
    )
}
