import Avokado, {
    AvokadoAPIError,
    VerificationLinksCreate,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Organization } from '../models/Organization'

interface VerifyOrganizationParams extends Organization.ID {
    type?: 'onboarding' | 'update'
}

export interface VerifyOrganizationAction {
    run(params: VerifyOrganizationParams): Promise<string>
}

@injectable()
export class VerifyOrganizationActionLive implements VerifyOrganizationAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: VerifyOrganizationParams): Promise<string> {
        const request: VerificationLinksCreate.Request = {
            organizationId: params.organizationId,
            type: params.type ?? 'onboarding',
        }
        const response = await this.avokado.verificationLinks.create(request)
        return response.url
    }
}

@injectable()
export class VerifyOrganizationActionFake implements VerifyOrganizationAction {
    async run(params: VerifyOrganizationParams): Promise<string> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class VerifyOrganizationActionFail implements VerifyOrganizationAction {
    async run(params: VerifyOrganizationParams): Promise<string> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
