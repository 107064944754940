import { PayoutList } from '@avokadoapp/avokado-ts'
import { Payout } from 'features/finance/models/Payout'

export function mapPayoutsToModel(payload: PayoutList.Response): Payout[] {
    return payload.payouts.map((payout) => {
        return {
            id: {
                organizationId: payout.organizationId,
                payoutId: payout.id,
                stripeId: payout.stripeId,
                globalTraceId: payout.globalTraceId,
            },
            amount: payout.amount,
            currency: payout.currency,
            status: payout.status,
            executionDate: payout.creationDate,
            expectedArrivalDate: payout.expectedArrivalDate,
            destination: payout.destination
                ? {
                      name: payout.destination.name,
                      last4: payout.destination.last4,
                      type: payout.destination.type,
                  }
                : undefined,
        }
    })
}
