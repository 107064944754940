import { CredentialResponse } from 'features/socialauth/google/types'
import { useState } from 'react'
import useAuthContext from '../contexts/AuthContext'

export default function useSignInWithGoogleProxy() {
    const [isProcessing, setIsProcessing] = useState(false)
    const [hasFailed, setHasFailed] = useState(false)

    const auth = useAuthContext()

    async function onSuccess(response: CredentialResponse) {
        const credential = response.credential
        if (!credential) return
        setIsProcessing(true)
        const locale = navigator.language || 'en_GB'
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        try {
            await auth.socialSignIn(credential, locale, timeZone)
        } catch (e: any) {
            setHasFailed(true)
        } finally {
            setIsProcessing(false)
        }
    }

    async function onError() {
        console.log('error')
        setIsProcessing(false)
    }

    function onStart() {
        setHasFailed(false)
    }

    return {
        isProcessing,
        hasFailed,
        onStart,
        onSuccess,
        onError,
    }
}
