import {
    NumberInput as ChakraNumberInput,
    FormControl,
    FormLabelProps,
    InputGroup,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInputField,
    NumberInputStepper,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { fullParseInt } from 'utils/types'
import { InputLabels } from './InputLabels'

interface BaseNumberInputProps {
    title?: string
    description?: string
    placeholder?: string
    labelProps?: FormLabelProps
    min?: number
    max?: number
    isDisabled?: boolean
}

interface NumberInputProps {
    value: number | undefined
    isRequired?: false
    setValue: (value: number | undefined) => void
}

interface RequiredNumberInputProps {
    value: number
    isRequired: true
    setValue: (value: number) => void
}

export function NumberInput({
    value,
    title,
    description,
    placeholder,
    setValue,
    labelProps,
    min = 0,
    max = 100,
    isRequired = false,
    isDisabled = false,
}: (NumberInputProps | RequiredNumberInputProps) & BaseNumberInputProps) {
    const [rawValue, setRawValue] = useState(
        value === undefined ? (isRequired ? '0' : '') : value.toString()
    )

    useEffect(() => {
        if (value === undefined) return
        if (value.toString() === rawValue) return

        const parsed = fullParseInt(rawValue)
        if (isNaN(parsed) || parsed > max || parsed < min) return

        if (min <= value && value <= max) {
            setRawValue(value.toString())
        }
    }, [value])

    return (
        <FormControl>
            <InputLabels
                title={title}
                description={description}
                {...labelProps}
            />

            <InputGroup>
                <ChakraNumberInput
                    w="full"
                    min={min}
                    max={max}
                    isDisabled={isDisabled}
                    value={rawValue}
                    onChange={(rawValue, value) => {
                        setRawValue(rawValue)
                        if (isNaN(value)) {
                            // @ts-ignore
                            setValue(isRequired ? 0 : undefined)
                        } else {
                            setValue(Math.max(min, Math.min(value, max)))
                        }
                    }}
                >
                    <NumberInputField placeholder={placeholder ?? title} />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </ChakraNumberInput>
            </InputGroup>
        </FormControl>
    )
}
