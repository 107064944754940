import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useAuthContext from '../contexts/AuthContext'

interface UseSignInFormDataHook {
    email: string
    password: string
    setEmail: Dispatch<SetStateAction<string>>
    setPassword: Dispatch<SetStateAction<string>>
}

export function useSignInFormData(): UseSignInFormDataHook {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    return {
        email,
        password,
        setEmail,
        setPassword,
    }
}

export function useSignInFormController(
    params: UseSignInFormDataHook
): UseFormControllerHook<void> {
    const { signIn } = useAuthContext()

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        if (params.email === '') return false
        if (params.password === '') return false
        return true
    }, [params])

    async function submit() {
        if (isProcessing) return

        setIsProcessing(true)
        try {
            await signIn(params.email, params.password)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
