import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { useBoolean } from '@chakra-ui/react'
import { useInjection } from 'inversify-react'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { Location } from '../../location/models/Location'
import { CardReaderDependencies } from '../dependencies'
import { CardReader } from '../models/CardReader'

interface UseCardReaderFormDataHook {
    label: string
    registrationCode: string
    setLabel: Dispatch<SetStateAction<string>>
    setRegistrationCode: Dispatch<SetStateAction<string>>
}

export function useCardReaderFormData(): UseCardReaderFormDataHook {
    const [label, setLabel] = useState('')
    const [registrationCode, setRegistrationCode] = useState('')

    return { label, registrationCode, setLabel, setRegistrationCode }
}

type UseCardReaderFormControllerError = 'invalid-code' | 'missing-location'

export function useCardReaderFormController(
    id: Location.ID,
    readers: CardReader[],
    params: UseCardReaderFormDataHook
): UseFormControllerHook<void> & {
    error: UseCardReaderFormControllerError | undefined
    hasSucceeded: boolean
    reset: () => void
    isUsingUsedLabel: boolean
} {
    const attachCardReader = useInjection(CardReaderDependencies.Attach)

    const [isProcessing, setIsProcessing] = useState(false)
    const [error, setError] = useState<UseCardReaderFormControllerError>()
    const [hasSucceeded, setHasSucceeded] = useBoolean()

    const usedLabels = useMemo(
        () => readers.map((reader) => reader.label),
        [readers]
    )

    const canSubmit = useMemo(() => {
        if (params.label === '') return false
        if (!/^[a-z]+-[a-z]+-[a-z]+$/.test(params.registrationCode))
            return false
        return true
    }, [params.label, params.registrationCode])

    const isUsingUsedLabel = useMemo(() => {
        return usedLabels.includes(params.label)
    }, [usedLabels, params.label])

    async function submit() {
        setError(undefined)
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            await attachCardReader.run({ ...id, ...params })
            setHasSucceeded.on()
        } catch (error: any) {
            if (
                error instanceof AvokadoAPIError &&
                error.category === 'card-reader.create.missing-stripe-location'
            ) {
                setError('missing-location')
            } else {
                setError('invalid-code')
            }
        } finally {
            setIsProcessing(false)
        }
    }

    function reset() {
        setError(undefined)
        setHasSucceeded.off()
        params.setLabel('')
        params.setRegistrationCode('')
    }

    return {
        isProcessing,
        canSubmit,
        submit,
        error,
        hasSucceeded,
        reset,
        isUsingUsedLabel,
    }
}
