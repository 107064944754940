import Avokado, {
    AvokadoAPIError,
    PayoutSettingsRetrieve,
} from '@avokadoapp/avokado-ts'
import { PayoutPreferences } from 'features/finance/models/PayoutPreferences'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

type RetrievePreferencesPayoutParams = Organization.ID

export interface RetrievePreferencesPayoutAction {
    run(params: RetrievePreferencesPayoutParams): Promise<PayoutPreferences>
}

@injectable()
export class RetrievePreferencesPayoutActionLive
    implements RetrievePreferencesPayoutAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(
        params: RetrievePreferencesPayoutParams
    ): Promise<PayoutPreferences> {
        const request: PayoutSettingsRetrieve.Request = {
            organizationId: params.organizationId,
        }
        const response = await this.avokado.payoutSettings.retrieve(request)
        return { interval: response.payoutInterval }
    }
}

@injectable()
export class RetrievePreferencesPayoutActionFake
    implements RetrievePreferencesPayoutAction
{
    async run(
        params: RetrievePreferencesPayoutParams
    ): Promise<PayoutPreferences> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class RetrievePreferencesPayoutActionFail
    implements RetrievePreferencesPayoutAction
{
    async run(
        params: RetrievePreferencesPayoutParams
    ): Promise<PayoutPreferences> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
