import { LocationOrganizationRetrieve } from '@avokadoapp/avokado-ts'
import { extractSchedule } from 'features/calendar/mapper/InternalMapper'
import { Location } from 'features/location/models/Location'

export function mapCalendarToModel(
    payload?: LocationOrganizationRetrieve.Response.Calendar
): Location.Calendar {
    if (!payload) {
        return {
            weekdays: {
                monday: { type: 'full-day', isActive: false },
                tuesday: { type: 'full-day', isActive: false },
                wednesday: { type: 'full-day', isActive: false },
                thursday: { type: 'full-day', isActive: false },
                friday: { type: 'full-day', isActive: false },
                saturday: { type: 'full-day', isActive: false },
                sunday: { type: 'full-day', isActive: false },
            },
            scheduledClosures: [],
        }
    }

    return {
        weekdays: extractSchedule(
            payload.openingTimes.map((openingTime) => {
                return {
                    from: openingTime.opensAt,
                    to: openingTime.closesAt,
                    weekday: openingTime.weekDay,
                }
            })
        ),
        scheduledClosures: payload.scheduledClosingTimes,
    }
}
