import {
    Button,
    ButtonGroup,
    Checkbox,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    VStack,
} from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import { useEffect, useMemo } from 'react'
import { MenuToolbarProps } from './MenuToolbar'

export function AllergensTool(props: MenuToolbarProps) {
    return (
        <Popover>
            {(popoverProps) => (
                <AllergensToolContent {...popoverProps} {...props} />
            )}
        </Popover>
    )
}

interface AllergensToolContentProps extends MenuToolbarProps {
    isOpen: boolean
    onClose: () => void
}

function AllergensToolContent({
    currentSection,
    formData,
    controller,
    isOpen,
    onClose,
}: AllergensToolContentProps) {
    const t = useT('menu')

    const selectedEntries = useMemo(
        () =>
            Array.from(formData.selectedEntriesIndexes).map(
                (index) => currentSection.entries[index]
            ),
        [currentSection, formData.selectedEntriesIndexes]
    )

    useEffect(() => {
        if (!isOpen) {
            formData.setChangedAllergens(new Map())
        }
    }, [isOpen])

    return (
        <>
            <PopoverTrigger>
                <Button isDisabled={selectedEntries.length === 0}>
                    {t('generic_allergens_label')}
                </Button>
            </PopoverTrigger>
            <PopoverContent bg="sheetBackground1">
                <PopoverArrow bg="sheetBackground1" />
                <PopoverHeader>{t('generic_allergens_label')}</PopoverHeader>
                <PopoverBody>
                    <VStack align="stretch" spacing={0}>
                        {Object.keys(Menu.Section.Entry.Allergen)
                            .map((allergen) => parseInt(allergen))
                            .filter((allergen) => !isNaN(allergen))
                            .map((allergen) => (
                                <AllergenSelector
                                    key={allergen}
                                    allergen={allergen}
                                    forcedStatus={formData.changedAllergens.get(
                                        allergen
                                    )}
                                    selectedEntries={selectedEntries}
                                    onChange={(allergen, status) => {
                                        formData.setChangedAllergens(
                                            (previous) => {
                                                const newMap = new Map(previous)
                                                newMap.set(allergen, status)
                                                return newMap
                                            }
                                        )
                                    }}
                                />
                            ))}
                    </VStack>
                </PopoverBody>
                <PopoverFooter>
                    <ButtonGroup
                        w="full"
                        size="sm"
                        variant="ghost"
                        justifyContent="end"
                    >
                        <Button onClick={onClose}>
                            {t('generic_cancel_button')}
                        </Button>
                        <Button
                            isDisabled={!controller.canUpdateEntries}
                            onClick={() => {
                                controller.updateEntries()
                                onClose()
                            }}
                        >
                            {t('generic_save_button')}
                        </Button>
                    </ButtonGroup>
                </PopoverFooter>
            </PopoverContent>
        </>
    )
}

interface AllergenSelectorProps {
    allergen: Menu.Section.Entry.Allergen
    selectedEntries: Menu.Section.Entry[]
    forcedStatus: boolean | undefined
    onChange: (allergen: Menu.Section.Entry.Allergen, isAdding: boolean) => void
}

function AllergenSelector({
    allergen,
    selectedEntries,
    forcedStatus,
    onChange,
}: AllergenSelectorProps) {
    const t = useT('menu')

    const isChecked = useMemo(
        () =>
            selectedEntries.every((entry) =>
                entry.allergens.includes(allergen)
            ),
        [selectedEntries]
    )

    const isIndeterminate = useMemo(
        () =>
            selectedEntries.some((entry) => entry.allergens.includes(allergen)),
        [selectedEntries]
    )

    return (
        <Checkbox
            isIndeterminate={
                forcedStatus === undefined && !isChecked && isIndeterminate
            }
            isChecked={forcedStatus ?? isChecked}
            onChange={(e) => onChange(allergen, e.target.checked)}
        >
            {t(
                `allergen_${Menu.Section.Entry.Allergen[
                    allergen
                ].toLowerCase()}`
            )}
        </Checkbox>
    )
}
