import { DateDependencies } from 'infra/di/factories/Date'
import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import useAuthProxyContext from '../contexts/AuthProxyContext'
import { AuthDependencies } from '../dependencies'

export function useResendConfirmationCode() {
    const dateGenerator = useInjection(DateDependencies.Generator)
    const resendConfirmationCode = useInjection(
        AuthDependencies.ResendConfirmationCode
    )

    const { email } = useAuthProxyContext()

    const [isProcessing, setIsProcessing] = useState(false)
    const [timeoutDate, setTimeoutDate] = useState<Date>()
    const [timeout, setTimeout] = useState<number>()

    async function submit() {
        if (!email) return
        if (isProcessing) return
        if (timeout) return

        setIsProcessing(true)
        await resendConfirmationCode.run({ email })

        const date = dateGenerator()
        date.setSeconds(date.getSeconds() + 30)

        setTimeoutDate(date)
        setTimeout(30)
        setIsProcessing(false)
    }

    useEffect(() => {
        if (!timeoutDate) return

        const interval = setInterval(() => {
            const now = dateGenerator()
            const timeLeft = (timeoutDate.getTime() - now.getTime()) / 1000

            if (timeLeft <= 0) {
                setTimeout(undefined)
                setTimeoutDate(undefined)
            } else {
                setTimeout(timeLeft)
            }
        }, 250)

        return () => clearInterval(interval)

        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [timeoutDate])

    return { isProcessing, timeout, submit }
}
