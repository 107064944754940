import Avokado, { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { TranslationObject } from 'features/menu/models/TranslationObject'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface UpdateTranslationObjectParams extends TranslationObject.ID {
    translation: string
}

export interface UpdateTranslationObjectAction {
    run(params: UpdateTranslationObjectParams): Promise<void>
}

@injectable()
export class UpdateTranslationObjectActionLive
    implements UpdateTranslationObjectAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateTranslationObjectParams): Promise<void> {
        const request = params
        await this.avokado.translationObject.update(request)
    }
}

@injectable()
export class UpdateTranslationObjectActionFail
    implements UpdateTranslationObjectAction
{
    async run(params: UpdateTranslationObjectParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
