import { QuestionIcon } from '@chakra-ui/icons'
import { Grid, IconButton, VStack } from '@chakra-ui/react'
import { LocationTabProps } from 'features/location/pages/LocationPage'
import useT from 'localization/hooks/useT'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { Container } from 'uikit/container/Container'
import LocalStorageKeys from 'utils/constant/LocalStorageKeys'
import { LoadingIndicator } from 'utils/loading'
import { useTerminals } from '../hooks/useTerminals'
import { Terminal } from '../models/Terminal'
import { ConnectTerminal } from './ConnectTerminal'
import { TerminalCard } from './TerminalCard'
import { TerminalForm } from './TerminalForm'
import { TerminalOnboardingContent } from './TerminalOnboarding'

export function TerminalTab({ headerRef, location }: LocationTabProps) {
    const t = useT('terminal')

    const { isLoading, terminals, addTerminal } = useTerminals(location.id)

    const [shouldHideAlert, setShouldHideAlert] = useLocalStorage<boolean>(
        LocalStorageKeys.HideLocationTerminalAlertHelper
    )

    const formRef = useRef<HTMLDivElement>(null)
    const [showNewForm, setShowNewForm] = useState(false)

    const onNewTerminal = useCallback((terminal: Terminal) => {
        addTerminal(terminal)
        setShowNewForm(false)
    }, [])

    useEffect(() => {
        if (showNewForm) {
            formRef.current?.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            })
        } else {
            window.scrollTo({ top: 0 })
        }
    }, [showNewForm])

    useEffect(() => {
        if (!isLoading && terminals.length === 0) {
            setShowNewForm(true)
        }
    }, [isLoading])

    if (isLoading) {
        return <LoadingIndicator />
    }

    return (
        <>
            <TerminalOnboardingContent
                isOpen={!shouldHideAlert}
                onClose={() => setShouldHideAlert(true)}
            />
            <VStack spacing={0}>
                <Container
                    isVisible={terminals.length > 0}
                    title={t('your_terminals_title')}
                    mb={8}
                >
                    <Grid
                        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                        w="full"
                        gap={6}
                    >
                        {terminals.map((terminal) => (
                            <TerminalCard
                                key={terminal.id.terminalId}
                                terminal={terminal}
                            />
                        ))}
                    </Grid>
                </Container>

                <Container
                    ref={formRef}
                    title={t('add_new_terminal_title')}
                    h="fit-content"
                    mb={8}
                    header={
                        <IconButton
                            onClick={() => setShouldHideAlert(false)}
                            variant="ghost"
                            icon={<QuestionIcon />}
                            aria-label=""
                        />
                    }
                >
                    <TerminalForm
                        location={location}
                        terminals={terminals}
                        dismiss={() => setShowNewForm(false)}
                        onSave={onNewTerminal}
                    />
                </Container>

                <ConnectTerminal isVisible={terminals.length > 0} />
            </VStack>
        </>
    )
}
