import { HStack, IconButton, Text, VStack } from '@chakra-ui/react'
import { Schedule } from 'features/calendar/models/Calendar'
import useT from 'localization/hooks/useT'
import { FiEdit2 } from 'react-icons/fi'
import { Day, formatTimeRange } from '../utils'
import { Labels } from './ScheduleForm'

interface WeekDayOverviewProps {
    labels: Labels
    weekdays: Schedule
    day: Day
    onEdit?: (day: Day) => void
}

export function WeekDayOverview({
    labels,
    weekdays,
    day,
    onEdit,
}: WeekDayOverviewProps) {
    const t = useT('timeschedule')

    const schedule = weekdays[day]

    return (
        <HStack w="full">
            <Text fontWeight="semibold" flex={1}>
                {t(`generic_day_name_${day}`)}
            </Text>

            <VStack spacing={0} align="end">
                {schedule.type === 'full-day' &&
                    (schedule.isActive ? (
                        <Text fontWeight="medium">{labels.fullDayActive}</Text>
                    ) : (
                        <Text fontStyle="italic">{labels.fullDayInactive}</Text>
                    ))}

                {schedule.type === 'time-range' &&
                    (schedule.timeRanges.length === 0 ? (
                        <Text fontStyle="italic">{labels.fullDayInactive}</Text>
                    ) : (
                        schedule.timeRanges.map((range, index) => (
                            <Text key={index} fontWeight="medium">
                                {formatTimeRange(range)}
                            </Text>
                        ))
                    ))}
            </VStack>

            {onEdit && (
                <IconButton
                    aria-label="Edit"
                    icon={<FiEdit2 />}
                    colorScheme="gray"
                    variant="ghost"
                    borderRadius="full"
                    onClick={() => onEdit(day)}
                />
            )}
        </HStack>
    )
}
