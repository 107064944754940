import {
    Location,
    Place,
    SearchPlaceIndexForTextCommand,
} from '@aws-sdk/client-location'
import { ConfigurationDependencies } from 'features/config/dependencies'
import { useInjection } from 'inversify-react'
import useAddressContext from '../contexts/AddressContext'

export function useAddressSearch() {
    const config = useInjection(ConfigurationDependencies.Store)
    const addressManager = useAddressContext()

    async function search(value: string): Promise<Place[]> {
        const credentials = await addressManager.retrieveAWSCredentials()
        const configuration = await config.configuration()

        const location = new Location({
            region: configuration.aws.region,
            credentials: {
                accessKeyId: credentials.AccessKeyId ?? '',
                secretAccessKey: credentials.SecretKey ?? '',
                expiration: credentials.Expiration,
                sessionToken: credentials.SessionToken,
            },
        })

        const places = await location.send(
            new SearchPlaceIndexForTextCommand({
                IndexName: configuration.aws.geo.placeIndexName,
                Text: value,
            })
        )

        if (places.Results) {
            return places.Results.map((x) => x.Place)
                .filter((x): x is Place => x?.Label !== undefined)
                .slice(0, 8)
        }

        return []
    }

    return { canSearch: true, search }
}
