import {
    Alert,
    AlertDescription,
    AlertTitle,
    Button,
    Circle,
    CloseButton,
    HStack,
    Icon,
    VStack,
} from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { IconType } from 'react-icons'
import { AiOutlineCheck, AiOutlineWarning } from 'react-icons/ai'
import { FiX } from 'react-icons/fi'
import { IoMdNotificationsOutline } from 'react-icons/io'

export type NotificationVariant = 'info' | 'warning' | 'success' | 'error'

function describe(variant: NotificationVariant): {
    color: string
    icon: IconType
} {
    switch (variant) {
        case 'info':
            return { color: 'blue', icon: IoMdNotificationsOutline }
        case 'warning':
            return { color: 'yellow', icon: AiOutlineWarning }
        case 'success':
            return { color: 'green', icon: AiOutlineCheck }
        case 'error':
            return { color: 'red', icon: FiX }
    }
}

export interface CustomNotificationProps {
    title: ReactNode
    description?: ReactNode
    variant: NotificationVariant
    action?: { label: string; handler: () => void }
    onClose: () => void
}

export function CustomNotification({
    title,
    description,
    action,
    onClose,
    variant,
}: CustomNotificationProps) {
    const { color, icon } = useMemo(() => describe(variant), [variant])

    return (
        <Alert
            position="relative"
            bg="sheetBackground3"
            borderRadius="md"
            p={4}
            boxShadow="md"
        >
            <CloseButton
                size="sm"
                position="absolute"
                top={1}
                right={1}
                onClick={onClose}
                color="label2"
            />
            <HStack spacing={2} w="full" align="end">
                <HStack spacing={2} w="full">
                    <Circle bg={`${color}.100`} p={1}>
                        <Icon
                            as={icon}
                            color={`${color}.600`}
                            bg={`${color}.200`}
                            boxSize={6}
                            p={1}
                            borderRadius="full"
                        />
                    </Circle>
                    <VStack align="start" spacing={0} w="full">
                        <AlertTitle fontWeight="semibold" textColor="label1">
                            {title}
                        </AlertTitle>
                        <AlertDescription textColor="label2">
                            {description}
                        </AlertDescription>
                    </VStack>
                </HStack>
                {action && (
                    <Button
                        colorScheme={color}
                        variant="ghost"
                        size="sm"
                        maxH="26px"
                        onClick={action.handler}
                    >
                        {action.label}
                    </Button>
                )}
            </HStack>
        </Alert>
    )
}
