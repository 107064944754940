import { Button } from '@chakra-ui/react'
import { useVerifyOrganization } from 'features/organization/hooks/useVerifyOrganization'
import { Organization } from 'features/organization/models/Organization'
import { useTranslation } from 'react-i18next'
import { NextStepCard } from './NextStepCard'

interface StripeNextStepProps {
    organization: Organization
}

export function StripeNextStep({ organization }: StripeNextStepProps) {
    const { t } = useTranslation()

    const { isProcessing, startVerificationProcess } = useVerifyOrganization()

    return (
        <NextStepCard
            title={t('Verify account (KYC)')}
            description={t(
                'To ensure the security and compliance of our platform'
            )}
            image="/home-stripe2.png"
            imageFit="contain"
        >
            <Button
                isLoading={isProcessing}
                isDisabled={organization.status === 'pending'}
                onClick={startVerificationProcess}
            >
                {organization.status === 'pending' ? t('Pending') : t('Verify')}
            </Button>
        </NextStepCard>
    )
}
