import { interfaces } from 'inversify'
import { CreateLocationAction } from './CreateLocationAction'
import { GeodecodeAddressLocationAction } from './GeodecodeAddressAction'
import { ListLocationAction } from './ListLocationAction'
import { PublishLocationAction } from './PublishLocationAction'
import { RetrieveLocationAction } from './RetrieveLocationAction'
import { UpdateLocationAction } from './UpdateLocationAction'
import { UploadMediaLocationAction } from './UploadMediaLocationAction'

interface _LocationDependencies {
    List: interfaces.ServiceIdentifier<ListLocationAction>
    Retrieve: interfaces.ServiceIdentifier<RetrieveLocationAction>
    Create: interfaces.ServiceIdentifier<CreateLocationAction>
    Update: interfaces.ServiceIdentifier<UpdateLocationAction>
    GeodecodeAddress: interfaces.ServiceIdentifier<GeodecodeAddressLocationAction>
    Publish: interfaces.ServiceIdentifier<PublishLocationAction>
    UploadMedia: interfaces.ServiceIdentifier<UploadMediaLocationAction>
}

export const LocationDependencies: _LocationDependencies = {
    List: Symbol('ListLocationAction'),
    Retrieve: Symbol('RetrieveLocationAction'),
    Create: Symbol('CreateLocationAction'),
    Update: Symbol('UpdateLocationAction'),
    GeodecodeAddress: Symbol('GeodecodeAddressAction'),
    Publish: Symbol('PublishLocationAction'),
    UploadMedia: Symbol('UploadMediaLocationAction'),
}

export type {
    CreateLocationAction,
    GeodecodeAddressLocationAction,
    ListLocationAction,
    PublishLocationAction,
    RetrieveLocationAction,
    UpdateLocationAction,
    UploadMediaLocationAction,
}
