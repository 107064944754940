import { createSvg2png, initialize } from 'svg2png-wasm'
import { sleep } from 'utils/asyncawait/sleep'
import Constants from 'utils/constant/Constants'
import LocalStorageKeys from 'utils/constant/LocalStorageKeys'
import {
    Codes,
    CodesTemplateValues,
    DEFAULT_CODES_TEMPLATE_VALUES,
    codesTemplateValuesSchema,
} from '../models/Codes'
import { Room } from '../models/Room'

export async function retrieveTemplateValues(): Promise<CodesTemplateValues> {
    const templateValues = localStorage.getItem(LocalStorageKeys.CodesTemplate)
    if (!templateValues) return DEFAULT_CODES_TEMPLATE_VALUES

    try {
        return await codesTemplateValuesSchema.validate(
            JSON.parse(templateValues)
        )
    } catch (error: any) {
        return DEFAULT_CODES_TEMPLATE_VALUES
    }
}

export function storeTemplateValues(templateValue: CodesTemplateValues) {
    localStorage.setItem(
        LocalStorageKeys.CodesTemplate,
        JSON.stringify(templateValue)
    )
}

function uint8ArrayToBase64(u8Array: Uint8Array): Promise<string> {
    return new Promise((resolve, reject) => {
        // Step 1: Convert to Blob
        const blob = new Blob([u8Array], { type: 'image/png' })

        // Step 2: Use FileReader to read as Data URL
        const reader = new FileReader()
        reader.onload = function (event: ProgressEvent<FileReader>) {
            // Ensure the result is a string before processing
            if (typeof event.target?.result === 'string') {
                // Step 3: Extract Base64 from Data URL
                resolve(event.target.result)
            } else {
                console.warn('no result')
                reject(new Error('Expected a string result from FileReader'))
            }
        }

        reader.onerror = function (error: ProgressEvent<FileReader>) {
            console.warn(error)
            reject(new Error('Failed reading blob as Data URL'))
        }

        reader.readAsDataURL(blob)
    })
}

async function fetchAppClipCode(svgUrl: string): Promise<string> {
    const response = await fetch(svgUrl)
    const svgContent = await response.text()
    const svg2png = createSvg2png({})

    while (true) {
        try {
            const png = await svg2png(svgContent, { scale: 1 })
            const base64png = await uint8ArrayToBase64(png)

            svg2png.dispose()
            return base64png
        } catch (e: unknown) {
            sleep(500)
        }
    }
}

var SVG2PNG_WASM_INITIALIZED = false

export async function generateTableCodes(
    locationName: string,
    roomName: string,
    table: Room.Table
): Promise<Codes[]> {
    try {
        if (!SVG2PNG_WASM_INITIALIZED) {
            await initialize(fetch('/svg2png_wasm_bg.wasm'))
            SVG2PNG_WASM_INITIALIZED = true
        }
    } catch {}

    const { organizationId, locationId, roomId, tableId } = table.id

    let appClipCode = ''

    try {
        const appClipCodeURL = `https://media.${Constants.Host}/appclip/${organizationId}/${locationId}/tables/${roomId}/${tableId}/ffffff-ff4500-badge-cam.svg`
        appClipCode = await fetchAppClipCode(appClipCodeURL)
    } catch (error: any) {
        // no svg available
    }

    return [
        {
            title: 'Scan. Order. Pay.',
            subtitle: 'Powered by Avokado.',
            location: locationName,
            table: `${roomName} - ${table.label}`,
            appclip: appClipCode,
            qrcode: `https://app.${Constants.Host}/join/${organizationId}/${locationId}?osid=T${roomId}@${tableId}`,
            ios: 'iOS',
            android: 'Android',
        },
    ]
}

export async function generateRoomCodes(
    locationName: string,
    room: Room
): Promise<Codes[]> {
    const tables = room.tables.flatMap((table) =>
        generateTableCodes(locationName, room.name, table)
    )

    return (await Promise.all(tables)).flat()
}

export async function generateLocationCodes(
    locationName: string,
    rooms: Room[]
): Promise<Codes[]> {
    const tables = rooms.flatMap((room) =>
        generateRoomCodes(locationName, room)
    )

    return (await Promise.all(tables)).flat()
}
