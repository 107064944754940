import { Schedule } from '../models/Calendar'
import { CompactedDays, compactDays } from './compact'
import { describe } from './describe'
import { formatTime, formatTimeRange } from './format'
import { isEmpty } from './isEmpty'
import { normalized } from './normalized'
import { subtract } from './subtract'

export type Day = keyof Schedule
export const days: Day[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]
export {
    compactDays,
    describe,
    formatTime,
    formatTimeRange,
    isEmpty,
    normalized,
    subtract,
    type CompactedDays,
}
