import {
    Center,
    HStack,
    Heading,
    HeadingProps,
    Spacer,
    StackProps,
    Text,
    VStack,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ReactNode } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

interface ContainerProps extends Omit<StackProps, 'title'> {
    title?: string | ReactNode
    subtitle?: string
    icon?: ReactNode
    header?: ReactNode
    mainContainer?: boolean
    isVisible?: boolean
    childProps?: StackProps
    headerProps?: StackProps
    titleProps?: HeadingProps
    isCompact?: boolean
}

export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
    ({ isVisible, ...props }, ref) => {
        if (isVisible === undefined) {
            return <ContainerContent ref={ref} {...props} />
        } else {
            return (
                <AnimatePresence>
                    {isVisible && (
                        <ContainerContent
                            as={motion.div}
                            // @ts-ignore
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            ref={ref}
                            {...props}
                        />
                    )}
                </AnimatePresence>
            )
        }
    }
)

const ContainerContent = React.forwardRef<HTMLDivElement, ContainerProps>(
    (
        {
            title,
            subtitle,
            icon,
            header,
            children,
            childProps,
            headerProps,
            titleProps,
            isCompact,
            mainContainer = false,
            ...props
        },
        ref
    ) => {
        const screen = useScreenBreakpoint()
        if (screen === 'mobile' || screen === 'tablet') {
            return (
                <VStack
                    ref={ref}
                    w="full"
                    alignItems="center"
                    px={2}
                    {...props}
                >
                    <VStack
                        align="stretch"
                        w="full"
                        maxW="6xl"
                        gap={6}
                        p={4}
                        bg="pageBackground2"
                        borderRadius="lg"
                        boxShadow="lg"
                        {...childProps}
                    >
                        <HStack w="full">
                            {icon}
                            {title && (
                                <Heading
                                    size={mainContainer ? 'xl' : 'md'}
                                    {...titleProps}
                                >
                                    {title}
                                </Heading>
                            )}
                            <Spacer />
                            {header}
                        </HStack>
                        {subtitle && (
                            <Text size={mainContainer ? 'lg' : 'sm'}>
                                {subtitle}
                            </Text>
                        )}
                        {children}
                    </VStack>
                </VStack>
            )
        } else if (isCompact) {
            return (
                <Center>
                    <VStack
                        ref={ref}
                        w="full"
                        minW="6xl"
                        maxW="6xl"
                        alignItems="center"
                        px={0}
                        {...props}
                    >
                        <VStack
                            align="stretch"
                            w="full"
                            minW="6xl"
                            maxW="6xl"
                            gap={6}
                            p={8}
                            bg="pageBackground2"
                            borderRadius="lg"
                            boxShadow="lg"
                            {...childProps}
                        >
                            {title && (
                                <HStack
                                    w="full"
                                    spacing={0}
                                    gap={4}
                                    {...headerProps}
                                >
                                    {icon}
                                    <VStack
                                        align="stretch"
                                        flex={1}
                                        spacing={0}
                                    >
                                        <Heading
                                            size={mainContainer ? 'xl' : 'md'}
                                            {...titleProps}
                                        >
                                            {title}
                                        </Heading>

                                        {subtitle && (
                                            <Text
                                                size={
                                                    mainContainer ? 'lg' : 'sm'
                                                }
                                            >
                                                {subtitle}
                                            </Text>
                                        )}
                                    </VStack>
                                    {header}
                                </HStack>
                            )}
                            {children}
                        </VStack>
                    </VStack>
                </Center>
            )
        } else {
            return (
                <VStack
                    ref={ref}
                    w="full"
                    maxW="8xl"
                    alignItems="start"
                    px={0}
                    {...props}
                >
                    <VStack
                        align="stretch"
                        w="full"
                        maxW="8xl"
                        gap={6}
                        p={8}
                        bg="pageBackground2"
                        borderRadius="lg"
                        boxShadow="lg"
                        {...childProps}
                    >
                        {title && (
                            <HStack
                                w="full"
                                spacing={0}
                                gap={4}
                                {...headerProps}
                            >
                                {icon}
                                <VStack align="stretch" flex={1} spacing={0}>
                                    <Heading
                                        size={mainContainer ? 'xl' : 'md'}
                                        {...titleProps}
                                    >
                                        {title}
                                    </Heading>

                                    {subtitle && (
                                        <Text
                                            size={mainContainer ? 'lg' : 'sm'}
                                        >
                                            {subtitle}
                                        </Text>
                                    )}
                                </VStack>
                                {header}
                            </HStack>
                        )}
                        {children}
                    </VStack>
                </VStack>
            )
        }
    }
)
