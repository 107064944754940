import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import AddressContextProvider from 'features/address/contexts/AddressContextProvider'
import AuthProxyContextProvider from 'features/auth/contexts/AuthProxyContextProvider'
import LocationsContextProvider from 'features/location/contexts/LocationsContextProvider'
import MenusContextProvider from 'features/menu/contexts/MenusContextProvider'
import OrganizationContextProvider from 'features/organization/contexts/OrganizationContextProvider'
import { ErrorPage } from 'infra/navigation/pages/ErrorPage'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import AuthContextProvider from './features/auth/contexts/AuthContextProvider'
import { RoutingRules } from './infra/navigation/RoutingRules'
import theme from './theme'

function App() {
    return (
        <>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <ChakraProvider
                theme={theme}
                toastOptions={{
                    defaultOptions: { position: 'top-right', duration: 7000 },
                }}
            >
                <ErrorBoundary fallback={<GlobalErrorBoundary />}>
                    <BrowserRouter>
                        <LocationsContextProvider>
                            <MenusContextProvider>
                                <OrganizationContextProvider>
                                    <AuthProxyContextProvider>
                                        <AuthContextProvider>
                                            <AddressContextProvider>
                                                <QueryParamProvider
                                                    adapter={
                                                        ReactRouter6Adapter
                                                    }
                                                >
                                                    <RoutingRules />
                                                </QueryParamProvider>
                                            </AddressContextProvider>
                                        </AuthContextProvider>
                                    </AuthProxyContextProvider>
                                </OrganizationContextProvider>
                            </MenusContextProvider>
                        </LocationsContextProvider>
                    </BrowserRouter>
                </ErrorBoundary>
            </ChakraProvider>
        </>
    )
}

export default App

function GlobalErrorBoundary() {
    return (
        <ErrorPage
            title="Error"
            subTitle="Subtitle"
            onPrimaryAction={() => {}}
            primaryActionLabel="Try again"
        />
    )
}
