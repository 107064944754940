import {
    Box,
    Card,
    CardBody,
    CardFooter,
    Heading,
    Icon,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { BsPlusCircle } from 'react-icons/bs'
import { Link } from 'react-router-dom'

interface CreateNewGridCardProps {
    link: string
    label: string
}

export function CreateNewGridCard({ link, label }: CreateNewGridCardProps) {
    return (
        <Box
            as={motion.div}
            whileHover={{ scale: 1.05 }}
            whileFocus={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
        >
            <Box
                as={Link}
                to={link}
                minH="md"
                w="full"
                h="full"
                borderRadius="md"
                overflow="hidden"
                float="left"
                display="inline-block"
                boxShadow="base"
            >
                <Card
                    as={motion.div}
                    bg="pageBackground3"
                    w="full"
                    h="full"
                    whileHover={{ scale: 1.03 }}
                    whileFocus={{ scale: 1.03 }}
                    whileTap={{ scale: 1 }}
                >
                    <CardBody
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Icon
                            as={BsPlusCircle}
                            boxSize={128}
                            color="label2"
                            boxShadow="lg"
                            borderRadius="full"
                        />
                    </CardBody>
                    <CardFooter
                        m={4}
                        borderColor="label2"
                        borderWidth="thin"
                        borderRadius="md"
                        boxShadow="md"
                    >
                        <Heading
                            size="md"
                            fontWeight="semibold"
                            noOfLines={1}
                            color="label2"
                        >
                            {label}
                        </Heading>
                    </CardFooter>
                </Card>
            </Box>
        </Box>
    )
}
