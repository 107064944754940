import { SVGProps } from 'react'

export function MilkIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Milk"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M103.96 199.99c-32.11.42-54.95-10.02-72.96-27.56C7.37 149.41-3.07 120.96.78 88.27c2.71-23.05 12.33-43.03 28.93-59.64C64.58-6.26 117.78-7.5 153.9 15.4c19.89 12.61 33.39 30.4 40.66 52.31 8.35 25.19 7.09 49.8-3.89 74.01-9.46 20.87-24.73 36.29-45.83 47.43-14.65 7.73-29.93 10.26-40.88 10.82zm26.49-75.03c.58-12.28.85-27.68-5.84-42.31-1.4-3.05-2.59-6.27-3.26-9.52-1.9-9.14-5.54-18.13-1.73-27.75 1.79-4.51-.59-7.05-5.8-7.12-9-.13-18.01-.12-27.02-.02-6.77.07-9.14 4.14-5.76 9.97 1.01 1.75 1.5 3.48 1.19 5.31-1.16 6.94-1.55 13.97-3.9 20.78-3.15 9.16-8.03 18.12-8.51 27.73-.86 17.24-.33 34.54-.25 51.82.02 4.9 2.58 7.27 8.13 7.18 14.48-.22 28.97-.03 43.46-.07 7.69-.02 9.13-1.34 9.26-8.86.14-7.99.03-15.98.03-27.14z" />
            <path d="M75.14 123.42c-1.61-14.09.61-27.63 6.24-40.81 3.99-9.35 4.92-19.46 5.66-29.5.13-1.73-.43-4.35 2.45-4.18 2.71.15 2.31 2.73 2.13 4.45-1.17 11.27-1.41 22.83-5.63 33.48-6.89 17.38-4.58 35.18-4.24 52.94.09 4.45.35 8.9.36 13.36 0 2.2-.83 4.16-3.67 4.12-2.98-.05-3.28-2.19-3.29-4.34-.03-9.84-.01-19.68-.01-29.51z" />
        </svg>
    )
}
