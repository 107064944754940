import {
    SignInWithAppleError,
    useSignInWithApple,
} from 'features/socialauth/apple/useSignInWithApple'
import { useState } from 'react'
import Constants from 'utils/constant/Constants'
import useAuthContext from '../contexts/AuthContext'

export default function useSignInWithAppleProxy() {
    const [isProcessing, setIsProcessing] = useState(false)
    const [hasFailed, setHasFailed] = useState(false)
    const signInWithApple = useSignInWithApple({
        clientId: Constants.AppleClientID,
        redirectURI: `https://dashboard.${Constants.Host}/r/sign-in-with-apple`,
        scope: 'email name',
    })

    const auth = useAuthContext()
    async function signIn() {
        if (hasFailed) {
            setHasFailed(false)
        }
        if (isProcessing) {
            return
        }
        setIsProcessing(true)
        const locale = navigator.language || 'en_GB'
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        try {
            const signInWithAppleResult = await signInWithApple()
            await auth.socialSignIn(
                signInWithAppleResult.authorization.id_token,
                locale,
                timeZone,
                signInWithAppleResult.user?.name?.firstName,
                signInWithAppleResult.user?.name?.lastName
            )
        } catch (e: any) {
            if (
                e instanceof SignInWithAppleError.SignIn &&
                e.code === 'popup_closed_by_user'
            ) {
                return
            } else {
                setHasFailed(true)
            }
        } finally {
            setIsProcessing(false)
        }
    }

    return {
        isProcessing,
        hasFailed,
        signIn,
    }
}
