import Avokado, {
    AuthSessionIdentityChallangeCreate,
    AvokadoAPIError,
} from '@avokadoapp/avokado-ts'
import axios from 'axios'
import {
    ConfigurationDependencies,
    ConfigurationStore,
} from 'features/config/dependencies'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { AuthDependencies, AuthStore } from '.'
import { Cognito } from '../models/CognitoModels'

interface SocialSignInParams {
    externalToken: string
    locale: string
    givenName?: string
    familyName?: string
}

export interface SocialSignInAction {
    run(params: SocialSignInParams): Promise<void>
}

@injectable()
export class SocialSignInActionLive implements SocialSignInAction {
    @inject(ConfigurationDependencies.Store) private config!: ConfigurationStore
    @inject(AuthDependencies.Store) private auth!: AuthStore
    @inject(AvokadoClient.Public) private avokado!: Avokado

    async run(params: SocialSignInParams): Promise<void> {
        const configuration = await this.config.configuration()

        const authSessionRequest: AuthSessionIdentityChallangeCreate.Request = {
            idToken: params.externalToken,
            locale: params.locale,
            //   zoneInfo: params.zoneInfo // TODO
        }

        const authSessionResponse =
            await this.avokado.authSession.identityChallangeCreate(
                authSessionRequest
            )

        const initiateAuthRequest: Cognito.Request.IdTokenAuth = {
            AuthFlow: 'CUSTOM_AUTH',
            AuthParameters: {
                USERNAME: authSessionResponse.id,
            },
            ClientId: configuration.aws.userPool.dashboardClientId,
        }

        const authChallenge = await axios.post<Cognito.Response.IdTokenAuth>(
            `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
            initiateAuthRequest,
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target':
                        'AWSCognitoIdentityProviderService.InitiateAuth',
                },
            }
        )

        const respondToAuthRequest: Cognito.Request.RespondToAuthChallenge = {
            ChallengeName: authChallenge.data.ChallengeName,
            Session: authChallenge.data.Session,
            ChallengeResponses: {
                USERNAME: authSessionResponse.id,
                ANSWER: params.externalToken,
            },
            ClientId: configuration.aws.userPool.dashboardClientId,
        }

        const authResponse =
            await axios.post<Cognito.Response.RespondToAuthChallenge>(
                `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
                respondToAuthRequest,
                {
                    headers: {
                        'Content-Type': 'application/x-amz-json-1.1',
                        'X-Amz-Target':
                            'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
                    },
                }
            )

        this.auth.setAccessToken(
            authResponse.data.AuthenticationResult.AccessToken
        )
        this.auth.setIdToken(authResponse.data.AuthenticationResult.IdToken)
        this.auth.setRefreshToken(
            authResponse.data.AuthenticationResult.RefreshToken
        )
    }
}

@injectable()
export class SocialSignInActionFake implements SocialSignInAction {
    async run(params: SocialSignInParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class SocialSignInActionFail implements SocialSignInAction {
    async run(params: SocialSignInParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
