import Avokado, { PricingPlanList } from '@avokadoapp/avokado-ts'

import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'

interface ListPricingPlanParams extends Organization.ID {}

// TODO add model and mapper
export interface ListPricingPlanAction {
    run(params: ListPricingPlanParams): Promise<PricingPlanList.Response>
}

@injectable()
export class ListPricingPlanActionLive implements ListPricingPlanAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(
        params: ListPricingPlanParams
    ): Promise<PricingPlanList.Response> {
        const request: PricingPlanList.Request = {
            organizationId: params.organizationId,
        }
        const response = await this.avokado.pricingPlan.list(request)
        return response
    }
}
