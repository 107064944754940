import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    Tag,
    TagCloseButton,
    TagLabel,
    TagLeftIcon,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { ExtrasModal } from './ExtraModal'

interface ExtrasInputProps extends Pick<InputProps, 'bg' | 'variant'> {
    menu: Menu
    section: Menu.Section
    entry: Menu.Section.Entry
    extras: Menu.Extra[]
    setExtras: Dispatch<SetStateAction<Menu.Extra[]>>
    onCreate: (menu: Menu) => void
}

export function ExtrasInput({
    menu,
    section,
    entry,
    extras,
    setExtras,
    onCreate,
    ...props
}: ExtrasInputProps) {
    const t = useT('menu')
    const containerRef = useRef<HTMLDivElement>(null)

    const presentModal = useDisclosure()

    const [query, setQuery] = useState('')
    const [showList, setShowList] = useState(false)

    const unusedExtras = useMemo(() => {
        const assignedIds = extras.map((extra) => extra.id.extraId)
        return Array.from(menu.extras.values()).filter(
            (extra) => !assignedIds.includes(extra.id.extraId)
        )
    }, [menu, extras])

    const availableExtras = useMemo(() => {
        const lower = query.toLowerCase()
        return unusedExtras.filter((extra) =>
            extra.name.toLowerCase().startsWith(lower)
        )
    }, [unusedExtras, query]) // eslint-disable-line react-hooks/exhaustive-deps

    const onPick = useCallback(
        (extra: Menu.Extra) => {
            setShowList(false)
            setExtras((previous) => [...previous, extra])
            setQuery('')
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const onRemove = useCallback((extra: Menu.Extra) => {
        setExtras((previous) =>
            previous.filter((other) => other.id.extraId !== extra.id.extraId)
        )
    }, [])

    useEffect(() => {
        function handleClick(event: MouseEvent) {
            const current = containerRef.current
            const target = event.target

            if (target === null || !(target instanceof HTMLElement)) {
                setShowList(false)
                return
            }

            let node: HTMLElement | null = target

            while (node !== null) {
                if (node === current) {
                    return
                }

                node = node.parentElement
            }

            setShowList(false)
        }

        document.body.addEventListener('mousedown', handleClick)

        return () => document.body.removeEventListener('mousedown', handleClick)
    }, [])

    /*
<FormControl>
                        <FormLabel>{t('menu_entry_extras_label')}</FormLabel>
                        <ExtrasInput
                            variant="filled"
                            bg="pageBackground2"
                            menu={menu}
                            section={section}
                            entry={entry}
                            extras={formData.extras}
                            setExtras={formData.setExtras}
                            onCreate={update}
                        />
                        <FormHelperText>
                            {t('menu_entry_extras_helper')}
                        </FormHelperText>
                    </FormControl>

*/

    return (
        <VStack w="full" position="relative" ref={containerRef}>
            <FormControl>
                <FormLabel>{t('menu_entry_extras_label')}</FormLabel>
                <InputGroup>
                    <Input
                        {...props}
                        value={query}
                        type="text"
                        placeholder={t('menu_entry_extras_placeholder')}
                        autoComplete="off"
                        transition="all .25s"
                        borderBottomRadius={
                            props.variant === 'flushed' ||
                            (showList && availableExtras.length > 0)
                                ? 'none'
                                : 'md'
                        }
                        onChange={(e) => setQuery(e.target.value)}
                        onFocus={() => setShowList(true)}
                    />
                    <InputRightElement minW="min-content">
                        <Button
                            size="xs"
                            variant="ghost"
                            // colorScheme="gray"
                            me={2}
                            onClick={presentModal.onOpen}
                        >
                            {t('new_extra_button')}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormHelperText>{t('menu_entry_extras_helper')}</FormHelperText>

                <HStack wrap="wrap" align="start" w="full" gap={2} mt={2}>
                    {extras.map((extra) => (
                        <Tag key={extra.id.extraId} borderRadius="full">
                            <TagLeftIcon boxSize="12px" as={InfoOutlineIcon} />
                            <TagLabel>{extra.name}</TagLabel>
                            <TagCloseButton onClick={() => onRemove(extra)} />
                        </Tag>
                    ))}
                </HStack>

                <ExtrasModal
                    menu={menu}
                    section={section}
                    entry={entry}
                    onSave={onCreate}
                    {...presentModal}
                />
            </FormControl>

            {showList && availableExtras.length > 0 && (
                <VStack
                    bg="sheetBackground1"
                    borderWidth={1}
                    borderColor="primary"
                    w="full"
                    top={10}
                    // top={label ? '68px' : 10}
                    borderBottomRadius="md"
                    position="absolute"
                    spacing={0}
                    overflow="scroll"
                    zIndex={1}
                    maxH="xs"
                >
                    {availableExtras.map((extra) => (
                        <HStack
                            key={extra.id.extraId}
                            w="full"
                            py={1}
                            px={3}
                            _hover={{ bg: 'sheetBackground2' }}
                            cursor="pointer"
                            onClick={() => onPick(extra)}
                        >
                            <AvailableRenderer extra={extra} />
                        </HStack>
                    ))}
                </VStack>
            )}
        </VStack>
    )
}

interface ExtrasInputRendererProps {
    extra: Menu.Extra
}

function AvailableRenderer({ extra }: ExtrasInputRendererProps) {
    return <Text>{extra.name}</Text>
}
