import { useEffect, useState } from 'react'

interface NotificationLabels {
    title: string
    description: string
}

export type Failure = true | string | NotificationLabels

export interface TaskFeedbackObservable {
    isSuccess?: undefined | true
    failure?: Failure
}

interface UseTaskFeedbackHook extends TaskFeedbackObservable {
    succeed(): void
    fail(m?: Failure): void
}

export default function useTaskFeedback(): UseTaskFeedbackHook {
    const [isSuccess, setIsSuccess] = useState<true | undefined>()
    const [failure, setFailure] = useState<Failure>()

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                setIsSuccess(undefined)
            }, 3000)
        }
    }, [isSuccess])

    useEffect(() => {
        if (failure) {
            setTimeout(() => {
                setFailure(undefined)
            }, 3000)
        }
    }, [failure])

    const succeed = () => {
        setIsSuccess(true)
    }

    const fail = (m?: Failure) => {
        if (m === undefined) {
            setFailure(true)
        } else {
            setFailure(m)
        }
    }

    return {
        isSuccess,
        failure,
        succeed,
        fail,
    }
}
