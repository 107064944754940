import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Grid,
    HStack,
    IconButton,
    Spinner,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { FiAlertTriangle } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import { CreateNewGridCard } from 'uikit/grid/CreateNewGridCard'
import { GridCard } from 'uikit/grid/GridCard'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import useLocationsContext from '../contexts/LocationsContext'

export default function LocationsPage() {
    const { t } = useTranslation()
    const screen = useScreenBreakpoint()

    const organizationManager = useOrganizationContext()
    const locationsManager = useLocationsContext()

    const quota = organizationManager.organization?.quota

    return (
        <Container
            isCompact
            mainContainer
            title={t('Your locations')}
            subtitle={
                !locationsManager.isLoading &&
                locationsManager.locations.length === 0
                    ? t('You have yet to create your first location')
                    : undefined
            }
            pt={8}
            header={
                quota &&
                locationsManager.locations.length >= 3 && (
                    <HStack>
                        <Text>
                            {locationsManager.locations.length} /{' '}
                            {quota.locations}
                        </Text>

                        {locationsManager.locations.length ===
                            quota.locations && (
                            <Tooltip
                                hasArrow
                                label={
                                    'You have reached the maximum quota for your locations. If you need to create another one, try contacting us.'
                                }
                            >
                                <Box
                                    as={Link}
                                    to="mailto:support@avokadoapp.ch"
                                >
                                    <QuestionOutlineIcon />
                                </Box>
                            </Tooltip>
                        )}
                    </HStack>
                )
            }
        >
            {locationsManager.isLoading ? (
                <Spinner />
            ) : locationsManager.locations.length === 0 ? (
                <>
                    <Text whiteSpace="pre-line">
                        {t(
                            'A location, in the context of Avokado, refers to a physical place'
                        )}
                    </Text>
                    <Box
                        alignSelf="end"
                        as={motion.div}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <Button as={Link} to="new" variant="ghost" size="lg">
                            {t('Get started now')}
                        </Button>
                    </Box>
                </>
            ) : (
                <Grid
                    templateColumns={{
                        base: '1fr',
                        md: `repeat(${screen === 'tablet' ? 2 : 3}, 1fr)`,
                    }}
                    gap={6}
                    w="full"
                >
                    {locationsManager.locations.map((location) => (
                        <GridCard
                            key={location.id.locationId}
                            link={`${location.id.locationId}`}
                            name={location.name ?? 'TODO'}
                            cover={location.medias[0]?.large()}
                        >
                            {!location.published && (
                                <Tooltip
                                    hasArrow
                                    label={`${location.name} is not public yet. Click here to publish it now.`}
                                >
                                    <IconButton
                                        as={Link}
                                        to={`${location.id.locationId}?tab=publishment`}
                                        icon={<FiAlertTriangle />}
                                        aria-label="Publish"
                                        position="absolute"
                                        top={2}
                                        right={2}
                                        variant="ghost"
                                        colorScheme="gray"
                                        borderRadius="full"
                                    />
                                </Tooltip>
                            )}
                        </GridCard>
                    ))}

                    {quota &&
                        locationsManager.locations.length < quota.locations && (
                            <CreateNewGridCard
                                link="new"
                                label={t('Create a new location')}
                            />
                        )}
                </Grid>
            )}
        </Container>
    )
}
