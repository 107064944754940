import {
    Box,
    Button,
    ButtonGroup,
    Center,
    FormControl,
    GridItem,
    Input,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { Location } from 'features/location/models/Location'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { useMemo } from 'react'
import { FormDivider, FormSection, FormStructure } from 'uikit/form'
import { PrecisionInput } from 'uikit/form/input'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import {
    useNewRoomFormController,
    useNewRoomFormData,
} from '../hooks/useNewRoomForm'
import { Room } from '../models/Room'

interface NewRoomContentProps {
    location: Location
    rooms: Room[]
    dismiss: () => void
    onSave: (room: Room) => void
}

export function NewRoomForm({
    location,
    rooms,
    dismiss,
    onSave,
}: NewRoomContentProps) {
    const t = useT('room')
    const formData = useNewRoomFormData()
    const controller = useNewRoomFormController(
        location.id,
        rooms,
        formData,
        onSave
    )

    const screen = useScreenBreakpoint()

    return (
        <FormStructure>
            <FormDivider />

            <FormSection
                title={t('add_room_name_label')}
                subtitle={t('add_room_name_description')}
            >
                <FormControl>
                    <Input
                        type="text"
                        placeholder={t('add_room_name_placeholder')}
                        maxLength={50}
                        value={formData.name}
                        onChange={(e) => formData.setName(e.target.value)}
                    />
                    <Text fontSize="xs" color="label2" textAlign="right">
                        {formData.name.length}/50
                    </Text>
                </FormControl>
            </FormSection>

            <FormDivider />

            <FormSection
                title={t('add_room_size_label')}
                subtitle={t('add_room_size_description')}
            >
                <VStack w="full" align="stretch" spacing={8}>
                    {formData.usingTemplate ? (
                        formData.aspectRatio === undefined ? (
                            <Stack
                                direction={
                                    screen === 'mobile' ? 'column' : 'row'
                                }
                                spacing={4}
                                px={8}
                            >
                                <SizeTemplateCard
                                    template="vertical"
                                    onClick={formData.setAspectRatio}
                                />
                                <SizeTemplateCard
                                    template="squared"
                                    onClick={formData.setAspectRatio}
                                />
                                <SizeTemplateCard
                                    template="horizontal"
                                    onClick={formData.setAspectRatio}
                                />
                            </Stack>
                        ) : (
                            <AdjustAspectRatio
                                aspectRatio={formData.aspectRatio}
                                setAspectRatio={formData.setAspectRatio}
                            />
                        )
                    ) : (
                        <>
                            <PrecisionInput
                                isRequired
                                description={t('add_room_size_width')}
                                leftAddon={t('add_room_size_meters')}
                                value={formData.width}
                                setValue={formData.setWidth}
                                w="full"
                            />
                            <PrecisionInput
                                isRequired
                                description={t('add_room_size_length')}
                                leftAddon={t('add_room_size_meters')}
                                value={formData.height}
                                setValue={formData.setHeight}
                                w="full"
                            />
                        </>
                    )}

                    <Button
                        alignSelf="end"
                        variant="unstyled"
                        size="sm"
                        color="label2"
                        _hover={{ color: 'accent' }}
                        onClick={() =>
                            formData.setUsingTemplate(
                                (previous: boolean) => !previous
                            )
                        }
                    >
                        {formData.usingTemplate
                            ? t('add_room_exact_size_button')
                            : t('add_room_custom_size_button')}
                    </Button>
                </VStack>
            </FormSection>

            <GridItem colSpan={3} display="flex" justifyContent="end">
                <ButtonGroup>
                    <Button
                        isDisabled={!controller.canSubmit}
                        isLoading={controller.isProcessing}
                        onClick={controller.submit}
                    >
                        {t('add_room_create_button')}
                    </Button>
                </ButtonGroup>
            </GridItem>
        </FormStructure>
    )
}

interface SizeTemplateCardProps {
    template: 'vertical' | 'squared' | 'horizontal'
    onClick: (aspectRatio: number) => void
}

function SizeTemplateCard({ template, onClick }: SizeTemplateCardProps) {
    const t = useT('room')

    const [w, h] = useMemo(() => {
        switch (template) {
            case 'horizontal':
                return ['100%', '50%']
            case 'squared':
                return ['100%', '100%']
            case 'vertical':
                return ['50%', '100%']
        }
    }, [template])

    return (
        <VStack
            cursor="pointer"
            flex={1}
            as={motion.div}
            role="group"
            whileHover={{ scale: 1.04 }}
            whileFocus={{ scale: 1.04 }}
            onClick={() => {
                switch (template) {
                    case 'horizontal':
                        onClick(0.5)
                        break
                    case 'squared':
                        onClick(0)
                        break
                    case 'vertical':
                        onClick(-0.5)
                        break
                }
            }}
        >
            <Center
                w="full"
                aspectRatio={1}
                borderRadius="md"
                borderWidth={1}
                borderColor="--chakra-colors-chakra-border-color"
            >
                <Center w="75%" h="75%">
                    <Box w={w} h={h} borderRadius="base" bg="pageBackground3" />
                </Center>
            </Center>
            <Text color="label2" _groupHover={{ color: 'label1' }}>
                {t(`add_room_size_template_${template}`)}
            </Text>
        </VStack>
    )
}

// Aspect ratio in range [-75, 75] where
// - negative value represent vertical rooms
// - zero represent squared rooms
// - positive value represent horizontal rooms
interface AdjustAspectRatioProps {
    aspectRatio: number
    setAspectRatio: (aspectRatio: number) => void
}

function AdjustAspectRatio({
    aspectRatio,
    setAspectRatio,
}: AdjustAspectRatioProps) {
    const t = useT('room')

    const width = useMemo(() => {
        if (aspectRatio >= 0) {
            return 100
        } else {
            return (1 + aspectRatio) * 100
        }
    }, [aspectRatio])

    const height = useMemo(() => {
        if (aspectRatio <= 0) {
            return 100
        } else {
            return (1 - aspectRatio) * 100
        }
    }, [aspectRatio])

    const labelStyles = {
        mt: '2',
        fontSize: 'sm',
        transform: 'translateX(-50%)',
    }

    const screen = useScreenBreakpoint()

    return (
        <VStack
            w={screen === 'mobile' ? '85%' : '50%'}
            alignSelf="center"
            gap={8}
        >
            <Center
                w="full"
                aspectRatio={1}
                borderRadius="md"
                borderWidth={1}
                borderColor="--chakra-colors-chakra-border-color"
            >
                <Center w="75%" h="75%">
                    <Box
                        w={`${width}%`}
                        h={`${height}%`}
                        borderRadius="base"
                        bg="pageBackground3"
                    />
                </Center>
            </Center>
            <Slider
                min={-0.75}
                max={0.75}
                step={0.0001}
                value={aspectRatio}
                onChange={(value) => setAspectRatio(value)}
            >
                <SliderMark value={-0.75} {...labelStyles}>
                    {t('add_room_size_template_vertical')}
                </SliderMark>
                <SliderMark value={0} {...labelStyles}>
                    {t('add_room_size_template_squared')}
                </SliderMark>
                <SliderMark value={0.75} {...labelStyles}>
                    {t('add_room_size_template_horizontal')}
                </SliderMark>

                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
        </VStack>
    )
}
