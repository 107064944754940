import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { AuthDependencies, AuthStore } from '.'

export interface SignOutAction {
    run(): Promise<void>
}

@injectable()
export class SignOutActionLive implements SignOutAction {
    @inject(AuthDependencies.Store) private auth!: AuthStore

    async run(): Promise<void> {
        this.auth.setAccessToken(null)
        this.auth.setIdToken(null)
        this.auth.setRefreshToken(null)
        this.auth.removeFutureUsageIdToken()
    }
}

@injectable()
export class SignOutActionFake implements SignOutAction {
    @inject(AuthDependencies.Store) private auth!: AuthStore

    async run(): Promise<void> {
        this.auth.setAccessToken(null)
        this.auth.setIdToken(null)
        this.auth.setRefreshToken(null)
        this.auth.removeFutureUsageIdToken()
    }
}

@injectable()
export class SignOutActionFail implements SignOutAction {
    async run(): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
