import { inject, injectable } from 'inversify'
import 'reflect-metadata'
import { ConfigurationDependencies, RetrieveConfigurationAction } from '.'
import { Configuration } from '../models/Configuration'
export interface ConfigurationStore {
    configuration(): Promise<Configuration>
}

@injectable()
export class ConfigurationStoreLive implements ConfigurationStore {
    @inject(ConfigurationDependencies.Retrieve)
    private retrieveConfiguration!: RetrieveConfigurationAction

    private _configuration?: Configuration

    async configuration(): Promise<Configuration> {
        if (this._configuration) {
            return this._configuration
        }

        const configuration = await this.retrieveConfiguration.run()
        this._configuration = configuration
        return configuration
    }
}
