import {
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Portal,
    Text,
    Textarea,
    VStack,
} from '@chakra-ui/react'
import { UseNewMenuFormDataHook } from 'features/menu/hooks/new/useNewMenuForm'
import useT from 'localization/hooks/useT'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedAction'

export function NewMenuGeneralStep({
    headerRef,
    general: formData,
}: UseNewMenuFormDataHook & StepPropsWithRef) {
    const t = useT('menu')

    return (
        <>
            <Portal containerRef={headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {t('new_step_1_general_title')}
                    </Heading>
                    <Text>{t('new_step_1_general_description')}</Text>
                </VStack>
            </Portal>

            <VStack w="full" spacing={6}>
                <FormControl>
                    <FormLabel>{t('menu_name_input_label')}</FormLabel>
                    <Input
                        autoFocus
                        type="text"
                        placeholder={t('menu_name_input_placeholder')}
                        autoComplete="off"
                        value={formData.name}
                        onChange={(e) => formData.setName(e.target.value)}
                    />
                    <FormHelperText>
                        {t('menu_name_input_support')}
                    </FormHelperText>
                </FormControl>

                <FormControl>
                    <FormLabel>{t('menu_description_input_label')}</FormLabel>
                    <Textarea
                        resize="none"
                        placeholder={t('menu_description_input_placeholder')}
                        maxLength={250}
                        value={formData.description}
                        onChange={(e) =>
                            formData.setDescription(e.target.value)
                        }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                            }
                        }}
                    />
                    <FormHelperText>
                        {t('menu_description_input_support')}
                    </FormHelperText>
                </FormControl>
            </VStack>
        </>
    )
}
