import { Dispatch, SetStateAction, createContext, useContext } from 'react'

interface _AuthProxyContext {
    email?: string
    password?: string
    setEmail: Dispatch<SetStateAction<string | undefined>>
    setPassword: Dispatch<SetStateAction<string | undefined>>
}

const defaultAuthProxyContext: _AuthProxyContext = {
    email: undefined,
    password: undefined,
    setEmail(email) {},
    setPassword(password) {},
}

export const AuthProxyContext = createContext(defaultAuthProxyContext)
export default function useAuthProxyContext() {
    return useContext(AuthProxyContext)
}
