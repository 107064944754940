import { createContext, useContext } from 'react'
import { EventNotification } from '../models/EventNotification'

interface _EventsContext {
    lastEvent: EventNotification | undefined
}

const defaultEventsContext: _EventsContext = {
    lastEvent: undefined,
}

export const EventsContext = createContext(defaultEventsContext)
export default function useEventsContext() {
    return useContext(EventsContext)
}
