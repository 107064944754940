import Avokado, { AvokadoAPIError, MenuUpdate } from '@avokadoapp/avokado-ts'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface CreateTranslatedMenuParams {
    menu: Menu
    languages: MenuUpdate.Request.Locale[]
}

export interface CreateTranslatedMenuAction {
    run(params: CreateTranslatedMenuParams): Promise<Menu>
}

@injectable()
export class CreateTranslatedMenuActionLive
    implements CreateTranslatedMenuAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateTranslatedMenuParams): Promise<Menu> {
        const menu = params.menu
        const request: MenuUpdate.Request = {
            organizationId: menu.id.organizationId,
            menuId: menu.id.menuId,
            availableTranslations: params.languages.map((l) => ({
                isPublished: false,
                locale: l,
            })),
        }
        const response = await this.avokado.menu.update(request)

        return {
            ...menu,
            availableTranslations: response.availableTranslations,
        }
    }
}

@injectable()
export class CreateTranslatedMenuActionFail
    implements CreateTranslatedMenuAction
{
    async run(params: CreateTranslatedMenuParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
