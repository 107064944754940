import { useCallback } from 'react'
import { DropzoneState, useDropzone } from 'react-dropzone'
import { UseMediaUploadParams, useMediaUpload } from '../hooks/useMediaUpload'
import { MediaUploadTaskStatus } from '../models/MediaUploadTaskStatus'

interface ImageUploaderProps {
    config: UseMediaUploadParams
    renderer: (props: {
        status: MediaUploadTaskStatus
        dropzone: DropzoneState
    }) => JSX.Element | null
}

export function ImageUploader({ config, renderer }: ImageUploaderProps) {
    const { status } = useMediaUpload(config)

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (status.type !== 'idle') return

            acceptedFiles.forEach((file: File) => {
                const reader = new FileReader()
                reader.onload = () => {
                    status.onDrop(file)
                }
                reader.readAsArrayBuffer(file)
            })
        },
        [status]
    )

    const dropzone = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png'],
        },
        onDrop,
    })

    return renderer({ status, dropzone })
}
