import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import { useList } from 'react-use'
import { TerminalDependencies } from '../dependencies'
import { Terminal } from '../models/Terminal'

interface UseTerminalsHook {
    isLoading: boolean
    terminals: Terminal[]
    addTerminal: (terminal: Terminal) => void
}

export function useTerminals(id: Location.ID): UseTerminalsHook {
    const list = useInjection(TerminalDependencies.List)

    const [isLoading, setIsLoading] = useState(true)
    const [terminals, { set: setTerminals, push: addTerminal }] =
        useList<Terminal>()

    useEffect(() => {
        async function init() {
            setTerminals(await list.run(id))
            setIsLoading(false)
        }

        init()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return { isLoading, terminals, addTerminal }
}
