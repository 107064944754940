import {
    Button,
    Divider,
    HStack,
    IconButton,
    Input,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react'

import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { Fragment } from 'react'
import { FaInfinity, FaPlus, FaTimes, FaTrash } from 'react-icons/fa'
import { Day, days } from '../models/Days'
import { formatTime } from '../utils'
import FullDayLabel from './FullDayLabel'
import { ScheduleFormProps } from './ScheduleForm'
import { ScheduleFormQuickActionButtonsDesktop } from './ScheduleFormQuickActionButtonts'
import SelectedDaysLabel from './SelectedDaysLabel'
import { WeekDayOverview } from './WeekDayOverview'

export function ScheduleFormDesktop({
    labels,
    formData,
    checkedOpen,
}: ScheduleFormProps & { checkedOpen: boolean }) {
    const t = useT('timeschedule')

    return (
        <HStack align="start" gap={20}>
            <VStack flex={2}>
                {days.map((day, index) => (
                    <Fragment key={day}>
                        <WeekDayOverview
                            labels={labels}
                            weekdays={formData.weekdays}
                            day={day}
                            onEdit={(day) => formData.setSelectedDays([day])}
                        />
                        {index !== days.length - 1 && <Divider />}
                    </Fragment>
                ))}
            </VStack>

            <VStack flex={3} gap={8}>
                <ScheduleFormQuickActionButtonsDesktop
                    selectedDays={formData.selectedDays}
                    setSelectedDays={formData.setSelectedDays}
                />
                <HStack w="full" justifyContent="space-between">
                    {days.map((day) => (
                        <WeekDaySelectButton
                            key={day}
                            day={day}
                            isSelected={formData.selectedDays.includes(day)}
                            onClick={() => formData.toggleDay(day)}
                        />
                    ))}
                </HStack>
                <SelectedDaysLabel days={formData.selectedDays} />

                <FullDayLabel
                    labels={labels}
                    isCheckedOpen={checkedOpen}
                    timeRangesCount={formData.ranges.length}
                    selectedDaysCount={formData.selectedDays.length}
                />

                <VStack w="full" align="start" as={motion.div}>
                    {formData.ranges.length > 0 && (
                        <HStack w="full" gap={4}>
                            <Text flex={1}>{labels.timeRangeFrom}</Text>
                            <Text flex={1}>{labels.timeRangeTo}</Text>
                            <Spacer maxW={10} />
                        </HStack>
                    )}

                    {formData.ranges.map((range, index) => (
                        <HStack
                            key={index}
                            w="full"
                            gap={4}
                            as={motion.div}
                            layout="position"
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                            }}
                            exit={{
                                opacity: 0,
                            }}
                        >
                            <Input
                                type="time"
                                value={formatTime(range.from)}
                                onChange={(e) => {
                                    const value = e.target.valueAsNumber
                                    if (!isNaN(value)) {
                                        formData.editRange(
                                            index,
                                            value,
                                            undefined
                                        )
                                    }
                                }}
                            />
                            <Input
                                type="time"
                                value={formatTime(range.to)}
                                onChange={(e) => {
                                    const value = e.target.valueAsNumber
                                    if (!isNaN(value)) {
                                        formData.editRange(
                                            index,
                                            undefined,
                                            value
                                        )
                                    }
                                }}
                            />
                            <IconButton
                                aria-label="Remove"
                                icon={<FaTrash />}
                                borderRadius="full"
                                colorScheme="gray"
                                variant="ghost"
                                onClick={() => formData.removeRange(index)}
                            />
                        </HStack>
                    ))}

                    {formData.selectedDays.length > 0 &&
                        formData.ranges.length < 3 && (
                            <HStack
                                as={motion.div}
                                layout="position"
                                w="full"
                                justify="end"
                                gap={4}
                                mt={4}
                            >
                                <Button
                                    variant="outline"
                                    size="sm"
                                    leftIcon={
                                        checkedOpen ? (
                                            <FaTimes />
                                        ) : (
                                            <FaInfinity />
                                        )
                                    }
                                    onClick={() => {
                                        if (checkedOpen) {
                                            formData.markFullDay(false)
                                        } else {
                                            formData.markFullDay(true)
                                        }
                                    }}
                                >
                                    {!checkedOpen && labels.fullDayActive}
                                    {checkedOpen && labels.fullDayInactive}
                                </Button>

                                <Button
                                    variant="outline"
                                    size="sm"
                                    leftIcon={<FaPlus />}
                                    onClick={formData.addRange}
                                >
                                    {t('week_schedule_add_time_range_button')}
                                </Button>

                                {/* For allignment purposes only. Do not judge me. 😒 */}
                                <IconButton
                                    aria-label="Remove"
                                    icon={<FaTrash />}
                                    borderRadius="full"
                                    colorScheme="gray"
                                    variant="ghost"
                                    opacity={0}
                                    pointerEvents="none"
                                />
                            </HStack>
                        )}
                </VStack>
            </VStack>
        </HStack>
    )
}

interface WeekDaySelectButtonProps {
    day: Day
    isSelected: boolean
    onClick: () => void
}

function WeekDaySelectButton({
    day,
    isSelected,
    onClick,
}: WeekDaySelectButtonProps) {
    const t = useT('timeschedule')
    return (
        <Button
            size="lg"
            aspectRatio={1}
            onClick={onClick}
            variant={isSelected ? 'solid' : 'outline'}
            w={50}
            h={50}
        >
            {t(`generic_day_initial_${day}`)}
        </Button>
    )
}
