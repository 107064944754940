import Avokado, { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { TableCreate } from '@avokadoapp/avokado-ts/lib/api/Table/Routes'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Geometry } from 'utils/geometry/Geometry'
import { mapTableToModel } from '../mappers/SDKResponseMapper'
import { Room } from '../models/Room'

interface CreateTableRoomParams extends Room.ID {
    label: string | undefined
    shape: Geometry.Shape
}

export interface CreateTableRoomAction {
    run(params: CreateTableRoomParams): Promise<Room.Table>
}

@injectable()
export class CreateTableRoomActionLive implements CreateTableRoomAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateTableRoomParams): Promise<Room.Table> {
        let request: TableCreate.Request

        if (params.shape.type === 'circle') {
            request = {
                organizationId: params.organizationId,
                locationId: params.locationId,
                roomId: params.roomId,
                label: params.label,
                center: params.shape.center,
                radius: params.shape.radius,
            }
        } else if (params.shape.type === 'rectangle') {
            request = {
                organizationId: params.organizationId,
                locationId: params.locationId,
                roomId: params.roomId,
                label: params.label,
                center: params.shape.center,
                size: params.shape.size,
            }
        } else {
            // should never happen
            throw new Error('Type must be either a rectangle or a circle')
        }

        const response = await this.avokado.table.create(request)
        return mapTableToModel(response)
    }
}

@injectable()
export class CreateTableRoomActionFake implements CreateTableRoomAction {
    async run(params: CreateTableRoomParams): Promise<Room.Table> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class CreateTableRoomActionFail implements CreateTableRoomAction {
    async run(params: CreateTableRoomParams): Promise<Room.Table> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
