import { MenuSectionDependencies } from 'features/menu/dependencies/section'
import { Menu } from 'features/menu/models/Menu'
import { dependencyContainer } from 'infra/di/inversify.config'
import { useCallback } from 'react'
import { useList } from 'react-use'

export const NewMenuCreateSectionsStep = 2

export interface UseNewMenuCreateSectionsStepFormDataHook {
    sections: string[]
    add: () => void
    update: (index: number, value: string) => void
    remove: (index: number) => void
}

export function useNewMenuCreateSectionsStepFormData(): UseNewMenuCreateSectionsStepFormDataHook {
    const [sections, { push, updateAt, removeAt }] = useList<string>([''])

    const add = useCallback(() => push(''), [push])
    const update = useCallback(
        (index: number, value: string) => updateAt(index, value),
        [updateAt]
    )
    const remove = useCallback(
        (index: number) => {
            if (sections.length > 1) removeAt(index)
        },
        [sections, removeAt]
    )

    return { sections, add, update, remove }
}

export async function handleCreateSectionsStep(
    data: UseNewMenuCreateSectionsStepFormDataHook,
    menu: Menu
): Promise<Menu> {
    const create = dependencyContainer.get(MenuSectionDependencies.BatchCreate)

    return await create.run({
        menu: menu,
        sections: data.sections,
    })
}
