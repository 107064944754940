import { Location } from 'features/location/models/Location'
import _ from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

export interface UseTakeAwayPolicyFormDataHook {
    isEnabled: boolean
    hasChanges: boolean
    policy: Location.Policy.TakeAway | undefined
    percentageDiscount: number | undefined
    upfrontPaymentThreshold: number | undefined
    setIsEnabled: Dispatch<SetStateAction<boolean>>
    setPercentageDiscount: Dispatch<SetStateAction<number | undefined>>
    setUpfrontPaymentThreshold: Dispatch<SetStateAction<number | undefined>>
}

export function useTakeAwayPolicyFormData(
    takeAway?: Location.Policy.TakeAway
): UseTakeAwayPolicyFormDataHook {
    const [isEnabled, setIsEnabled] = useState(takeAway !== undefined)
    const [percentageDiscount, setPercentageDiscount] = useState(
        takeAway?.percentageDiscount
    )
    const [upfrontPaymentThreshold, setUpfrontPaymentThreshold] = useState(
        takeAway?.upfrontPaymentThreshold
    )

    const policy: Location.Policy.TakeAway | undefined = useMemo(() => {
        return isEnabled
            ? {
                  percentageDiscount,
                  upfrontPaymentThreshold,
              }
            : undefined
    }, [isEnabled, percentageDiscount, upfrontPaymentThreshold])

    const hasChanges = useMemo(
        () => !_.isEqual(policy, takeAway),
        [policy, takeAway]
    )

    return {
        isEnabled,
        hasChanges,
        policy,
        percentageDiscount,
        upfrontPaymentThreshold,
        setIsEnabled,
        setPercentageDiscount,
        setUpfrontPaymentThreshold,
    }
}
