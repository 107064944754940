import { interfaces } from 'inversify'
import { CreateMenuAssignmentAction } from './CreateMenuAssignmentAction'
import { DeleteMenuAssignmentAction } from './DeleteMenuAssignmentAction'
import { ListMenuAssignmentAction } from './ListMenuAssignmentAction'
import { UpdateMenuAssignmentAction } from './UpdateMenuAssignmentAction'

interface _MenuAssignmentDependencies {
    List: interfaces.ServiceIdentifier<ListMenuAssignmentAction>
    Create: interfaces.ServiceIdentifier<CreateMenuAssignmentAction>
    Update: interfaces.ServiceIdentifier<UpdateMenuAssignmentAction>
    Delete: interfaces.ServiceIdentifier<DeleteMenuAssignmentAction>
}

export const MenuAssignmentDependencies: _MenuAssignmentDependencies = {
    List: Symbol('ListMenuAssignmentAction'),
    Create: Symbol('CreateMenuAssignmentAction'),
    Update: Symbol('UpdateMenuAssignmentAction'),
    Delete: Symbol('DeleteMenuAssignmentAction'),
}

export type {
    CreateMenuAssignmentAction,
    DeleteMenuAssignmentAction,
    ListMenuAssignmentAction,
    UpdateMenuAssignmentAction,
}
