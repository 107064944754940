import { useConst } from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fullParseInt } from 'utils/types'
import useMenusContext from '../contexts/MenusContext'
import { MenuDependencies } from '../dependencies/menu'

export function useMenu(rawMenuId?: string | number) {
    const retrieve = useInjection(MenuDependencies.Retrieve)

    const organizationManager = useOrganizationContext()
    const menusManager = useMenusContext()

    const navigate = useNavigate()

    const [menu, setMenu] = useState<Menu>()

    const { organizationId } = useConst(
        () => organizationManager.assertOrganization().id
    )

    useEffect(() => {
        async function init(menuId: number) {
            const id = { organizationId, menuId }
            const stored = menusManager.retrieve(id)
            setMenu(stored)

            const fetched = await retrieve.run(id)
            update(fetched)
        }

        const menuId =
            rawMenuId === undefined
                ? NaN
                : typeof rawMenuId === 'string'
                ? fullParseInt(rawMenuId)
                : rawMenuId
        if (isNaN(menuId)) {
            navigate('/menus')
        } else {
            init(menuId)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function update(menu: Menu) {
        menusManager.update(menu)
        setMenu(menu)
    }

    return { menu, update }
}
