import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { useNotification } from 'features/notification/hooks/useNotification'
import { useInjection } from 'inversify-react'
import { KonvaEventObject } from 'konva/lib/Node'
import _ from 'lodash'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Geometry } from 'utils/geometry/Geometry'
import { overlap } from 'utils/geometry/overlap'
import useTrigger, { UseTrigger } from 'utils/hooks/useTrigger'
import { RoomDependencies } from '../dependencies'
import { Room } from '../models/Room'

export interface NewTable {
    label: string
    shape: { type: 'circle' | 'rectangle' } | Geometry.Shape
}

export function isFullShape(
    shape: { type: 'circle' | 'rectangle' } | Geometry.Shape | undefined
): shape is Geometry.Shape {
    return shape !== undefined && 'center' in shape
}

function updateShape(
    event: KonvaEventObject<Event>,
    itemShape: Geometry.Shape | undefined,
    scale: number
): Geometry.Shape {
    if (itemShape?.type === 'circle') {
        return {
            ...itemShape,
            center: {
                x: event.target.x() / scale,
                y: event.target.y() / scale,
            },
            radius: itemShape.radius * event.target.scaleX(),
        }
    } else if (itemShape?.type === 'rectangle') {
        const size = {
            width: itemShape.size.width * event.target.scaleX(),
            height: itemShape.size.height * event.target.scaleY(),
        }
        return {
            ...itemShape,
            center: {
                x: event.target.x() / scale + size.width / 2,
                y: event.target.y() / scale + size.height / 2,
            },
            size,
        }
    } else {
        // should never happen
        throw new Error('Type must be either a rectangle or a circle')
    }
}

export interface UseRoomFormDataHook {
    trigger: UseTrigger
    showLayoutView: boolean
    size: Geometry.Size
    tables: Room.Table[]
    overlappingTables: Room.Table.ID[]
    selectedTable: Room.Table.ID | null
    label: string | null
    newTable: NewTable | null
    setShowLayoutView: Dispatch<SetStateAction<boolean>>
    handleFloorClick: (
        event: KonvaEventObject<MouseEvent>,
        scale: number
    ) => void
    selectTable: (table: Room.Table) => void
    setLabel: Dispatch<SetStateAction<string>>
    prepareUpdate: (event: KonvaEventObject<Event>) => void
    handleUpdate: (event: KonvaEventObject<Event>, scale: number) => void
    setTables: Dispatch<SetStateAction<Room.Table[]>>
    changeNewTableLabel: (label: string) => void
    changeNewTableType: (type: 'circle' | 'rectangle') => void
    clearNewTable: (newLabels: string[]) => void
    updateNewTable: (event: KonvaEventObject<Event>, scale: number) => void
}

type TableData = {
    prefix: string
    number: number
}

function getNextTableNumber(tables: string[]): string {
    // If tables array is empty, return '1' as the first table number.
    if (!tables.length) return '1'

    // Extract rightmost numbers and their prefixes.
    const extractedData: TableData[] = tables.map((table) => {
        const match = table.match(/(.*?)(\d+)$/)
        return {
            prefix: match ? match[1] : '',
            number: match ? parseInt(match[2], 10) : 0,
        }
    })

    // Determine the highest number.
    const maxNumber = Math.max(...extractedData.map((data) => data.number))

    // Determine the most common prefix.
    const prefixFrequency: { [key: string]: number } = {}
    let mostCommonPrefix = ''
    let maxFreq = 0

    extractedData.forEach((data) => {
        if (!prefixFrequency[data.prefix]) {
            prefixFrequency[data.prefix] = 0
        }
        prefixFrequency[data.prefix]++
        if (prefixFrequency[data.prefix] > maxFreq) {
            maxFreq = prefixFrequency[data.prefix]
            mostCommonPrefix = data.prefix
        }
    })

    // Generate the new table number.
    return mostCommonPrefix + (maxNumber + 1)
}

export function useRoomFormData(room: Room): UseRoomFormDataHook {
    const trigger = useTrigger()

    const [showLayoutView, setShowLayoutView] = useState(false)
    const [size, setSize] = useState(room.size)

    const [tables, setTables] = useState(room.tables)
    const [overlappingTables, setOverlappingTables] = useState<Room.Table.ID[]>(
        []
    )
    const [selectedTable, setSelectedTable] = useState<Room.Table.ID | null>(
        null
    )
    const [label, setLabel] = useState<string>(
        getNextTableNumber(room.tables.map((t) => t.label))
    )
    const [newTable, setNewTable] = useState<NewTable | null>(null)

    const handleFloorClick = useCallback(
        (event: KonvaEventObject<MouseEvent>, scale: number) => {
            setSelectedTable(null)
        },
        [newTable]
    )

    const changeNewTableType = useCallback(
        (type: 'circle' | 'rectangle') => {},

        [tables]
    )

    const changeNewTableLabel = useCallback((label: string) => {}, [])

    const clearNewTable = (newLabels: string[]) => {
        setNewTable(null)
        setLabel(getNextTableNumber(newLabels))
    }

    const updateNewTable = useCallback(
        (event: KonvaEventObject<Event>, scale: number) => {
            if (!isFullShape(newTable?.shape)) return
            const shape = updateShape(event, newTable?.shape, scale)
            setNewTable({ ...newTable!, shape })
            trigger.fire()
        },
        [newTable]
    )

    const selectTable = useCallback((table: Room.Table) => {
        setTables((previous) => {
            const items = previous.slice()
            const index = items.indexOf(table)
            items.splice(index, 1)
            items.push(table)
            return items
        })
        setSelectedTable(table.id)
        setLabel(table.label)
    }, [])

    function prepareUpdate(event: KonvaEventObject<Event>) {
        const id = event.target.name()
        const items = tables.slice()
        const item = items.find((i) => i.id.tableId.toString() === id)
        const index = items.indexOf(item!)

        if (!_.isEqual(item!.id, selectedTable)) {
            items.splice(index, 1)
            items.push(item!)
            setTables(items)
            setSelectedTable(null)
            setLabel(getNextTableNumber(tables.map((t) => t.label)))
        }
    }

    function handleUpdate(event: KonvaEventObject<Event>, scale: number) {
        const id = event.target.name()
        let itemShape: Geometry.Shape | undefined

        if (isFullShape(newTable?.shape)) {
            itemShape = newTable!.shape
        } else {
            itemShape = tables.find(
                (i) => i.id.tableId.toString() === id
            )?.shape
        }

        const shape = updateShape(event, itemShape, scale)
        updateOverlappingTables(id, shape)
    }

    function updateOverlappingTables(currentId: string, shape: Geometry.Shape) {
        const currentOverlaps = tables
            .filter((other) => {
                if (other.id.tableId.toString() === currentId) return false
                if (other.shape === undefined) return false
                return overlap(shape, other.shape)
            })
            .map((other) => other.id)

        setOverlappingTables(currentOverlaps)
    }

    return {
        trigger,
        showLayoutView,
        size,
        tables,
        overlappingTables,
        selectedTable,
        label,
        newTable,
        setShowLayoutView,
        handleFloorClick,
        selectTable,
        setLabel,
        prepareUpdate,
        handleUpdate,
        setTables,
        changeNewTableLabel,
        changeNewTableType,
        clearNewTable,
        updateNewTable,
    }
}

export interface UseRoomFormControllerHook {
    isProcessing: boolean
    isUpdatingTable: boolean
    isCreatingTable: boolean
    canCreateTable: boolean
    updateTableLabel: () => Promise<void>
    updateTableGeometry: (
        event: KonvaEventObject<Event>,
        scale: number
    ) => Promise<void>
    createTable: () => Promise<void>
}

export function useRoomFormController(
    id: Room.ID,
    params: UseRoomFormDataHook
): UseRoomFormControllerHook {
    const update = useInjection(RoomDependencies.UpdateTable)
    const create = useInjection(RoomDependencies.CreateTable)

    const notification = useNotification()

    const [isUpdatingTable, setIsUpdatingTable] = useState(false)
    const [isCreatingTable, setIsCreatingTable] = useState(false)

    const isProcessing = useMemo(
        () => isUpdatingTable || isCreatingTable,
        [isUpdatingTable, isCreatingTable]
    )

    const canCreateTable = useMemo(() => {
        if (params.overlappingTables.length > 0) return false
        if (!params.newTable) return false
        if (!isFullShape(params.newTable.shape)) return false
        const labels = params.tables.map((table) => table.label)
        if (labels.includes(params.newTable.label)) return false

        return true
    }, [params.newTable, params.tables])

    async function updateTableLabel() {
        if (isProcessing || !params.selectedTable || !params.label) return
        setIsUpdatingTable(true)

        const id = params.selectedTable.tableId
        const items = params.tables.slice()
        const item = params.tables.find((i) => i.id.tableId === id)
        const index = params.tables.indexOf(item!)

        items[index] = { ...item!, label: params.label }

        try {
            await update.run({ ...item!.id, label: params.label })
            params.setTables(items)
        } finally {
            setIsUpdatingTable(false)
        }
    }

    async function updateTableGeometry(
        event: KonvaEventObject<Event>,
        scale: number
    ) {
        if (isProcessing) return
        setIsUpdatingTable(true)

        const id = event.target.name()
        const items = params.tables.slice()
        const item = params.tables.find((i) => i.id.tableId.toString() === id)
        const index = params.tables.indexOf(item!)

        const shape = updateShape(event, item?.shape, scale)
        items[index] = { ...item!, shape }

        try {
            await update.run({ ...item!.id, shape: items[index].shape! })
            params.setTables([...items])
        } catch (error) {
            if (
                error instanceof AvokadoAPIError &&
                error.category === 'validation.room.overlapping-tables'
            ) {
                notification({
                    id: 'overlapping-tables',
                    title: 'Overlapping tables', // TODO
                    description: 'Overlapping tables', // TODO
                    variant: 'error',
                })
            } else {
                // TODO
            }
        } finally {
            setIsUpdatingTable(false)
            params.trigger.fire()
        }
    }

    async function createTable() {
        if (isProcessing || !canCreateTable) return
        setIsCreatingTable(true)

        try {
            const fullShape = params.newTable!.shape as Geometry.Shape
            const newTable = await create.run({
                ...id,
                label: params.newTable!.label,
                shape: fullShape,
            })
            params.clearNewTable([
                ...params.tables.map((t) => t.label),
                newTable.label,
            ])
            params.setTables((previous) => [...previous, newTable])
        } finally {
            setIsCreatingTable(false)
        }
    }

    useEffect(() => {
        if (canCreateTable) {
            createTable()
        }
    }, [canCreateTable])

    return {
        isProcessing,
        isUpdatingTable,
        isCreatingTable,
        canCreateTable,
        updateTableLabel,
        updateTableGeometry,
        createTable,
    }
}
