import Avokado, { AvokadoAPIError, TerminalList } from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapTerminalsToModels } from '../mappers/SDKMapper'
import { Terminal } from '../models/Terminal'

type ListTerminalParams = Location.ID

export interface ListTerminalAction {
    run(params: ListTerminalParams): Promise<Terminal[]>
}

@injectable()
export class ListTerminalActionLive implements ListTerminalAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ListTerminalParams): Promise<Terminal[]> {
        const request: TerminalList.Request = params
        const response = await this.avokado.terminal.list(request)
        return mapTerminalsToModels(response)
    }
}

@injectable()
export class ListTerminalActionFake implements ListTerminalAction {
    async run(params: ListTerminalParams): Promise<Terminal[]> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ListTerminalActionFail implements ListTerminalAction {
    async run(params: ListTerminalParams): Promise<Terminal[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
