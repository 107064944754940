import { LocationDependencies } from 'features/location/dependencies'
import { Location } from 'features/location/models/Location'
import { dependencyContainer } from 'infra/di/inversify.config'
import { Dispatch, SetStateAction, useState } from 'react'
import { cleanUpString } from 'utils/types'

export const NewLocationGeneralStep = 0

export interface UseNewLocationGeneralStepFormDataHook {
    name: string
    setName: Dispatch<SetStateAction<string>>
}

export function useNewLocationGeneralStepFormData(): UseNewLocationGeneralStepFormDataHook {
    const [name, setName] = useState('')

    return { name, setName }
}

export async function handleGeneralStep(
    organizationId: string,
    data: UseNewLocationGeneralStepFormDataHook,
    location?: Location
): Promise<Location> {
    const create = dependencyContainer.get(LocationDependencies.Create)
    const update = dependencyContainer.get(LocationDependencies.Update)

    const name = cleanUpString(data.name)

    if (!location) {
        return await create.run({ organizationId, name })
    } else if (location.name === name) {
        return location
    } else {
        return await update.run({ location, name })
    }
}
