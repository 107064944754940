import Avokado, {
    AvokadoAPIError,
    MenuExtraCreate,
} from '@avokadoapp/avokado-ts'
import { mapExtraToModel } from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface CreateMenuExtraParams extends Menu.ID {
    name: string
    maxOptionSelectable: number | undefined
    minOptionSelectable: number | undefined
    options: { name: string; price: number | undefined }[]
}

export interface CreateMenuExtraAction {
    run(params: CreateMenuExtraParams): Promise<Menu.Extra>
}

@injectable()
export class CreateMenuExtraActionLive implements CreateMenuExtraAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateMenuExtraParams): Promise<Menu.Extra> {
        const request: MenuExtraCreate.Request = {
            organizationId: params.organizationId,
            menuId: params.menuId,
            name: params.name,
            maxOptionSelectable: params.maxOptionSelectable,
            minOptionSelectable: params.minOptionSelectable,
            options: params.options.map((option) => ({
                name: option.name,
                extraPrice: option.price,
            })),
        }

        const response = await this.avokado.menuExtra.create(request)
        return mapExtraToModel(
            { organizationId: params.organizationId, menuId: params.menuId },
            response
        )
    }
}

@injectable()
export class CreateMenuExtraActionFake implements CreateMenuExtraAction {
    async run(params: CreateMenuExtraParams): Promise<Menu.Extra> {
        await sleep(500)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class CreateMenuExtraActionFail implements CreateMenuExtraAction {
    async run(params: CreateMenuExtraParams): Promise<Menu.Extra> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
