import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import { RawMedia } from 'features/media/models/RawMedia'
import { Menu } from 'features/menu/models/Menu'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { cleanUpString } from 'utils/types'
import useMenusContext from '../contexts/MenusContext'
import { MenuDependencies } from '../dependencies/menu'
import { mapMenuMediaToModel } from '../mappers/SDKResponseMapper'

export interface UseMenuHeadingFormDataHook {
    hasChanges: boolean
    name: string
    description: string
    footer: string
    isUploadingCover: boolean
    rawMedia: RawMedia | undefined
    reset: () => void
    setName: Dispatch<SetStateAction<string>>
    setDescription: Dispatch<SetStateAction<string>>
    setFooter: Dispatch<SetStateAction<string>>
    setIsUploadingCover: Dispatch<SetStateAction<boolean>>
    setRawMedia: Dispatch<SetStateAction<RawMedia | undefined>>
}

export function useMenuHeadingFormData(menu: Menu): UseMenuHeadingFormDataHook {
    const [name, setName] = useState(menu.name)
    const [description, setDescription] = useState(menu.description ?? '')
    const [footer, setFooter] = useState(menu.footer ?? '')
    const [isUploadingCover, setIsUploadingCover] = useState(false)
    const [rawMedia, setRawMedia] = useState<RawMedia>()

    const hasChanges = useMemo(() => {
        return (
            !_.isEqual(name, menu.name) ||
            !_.isEqual(description, menu.description ?? '') ||
            !_.isEqual(footer, menu.footer ?? '')
        )
    }, [name, description, footer, menu])

    const reset = useCallback(() => {
        setName(menu.name)
        setDescription(menu.description ?? '')
        setFooter(menu.footer ?? '')
        setRawMedia(undefined)
    }, [menu])

    return {
        hasChanges,
        name,
        description,
        footer,
        isUploadingCover,
        rawMedia,
        reset,
        setName,
        setDescription,
        setFooter,
        setIsUploadingCover,
        setRawMedia,
    }
}

export function useMenuHeadingFormController(
    menu: Menu,
    params: UseMenuHeadingFormDataHook,
    onSave: (menu: Menu) => void
): UseFormControllerHook<void> & {
    buildMediaUploadLink: (media: File) => Promise<MediaUploadLink>
} {
    const update = useInjection(MenuDependencies.Update)
    const upload = useInjection(MenuDependencies.UploadMedia)

    const menusManager = useMenusContext()

    const [isProcessing, setIsProcessing] = useState(false)

    const usedNames = useMemo(
        () =>
            menusManager.menus
                .filter((m) => m.id.menuId !== menu.id.menuId)
                .map((menu) => menu.name),
        [menusManager.menus]
    )

    const canSubmit = useMemo(() => {
        if (!params.hasChanges) return false
        if (params.isUploadingCover) return false

        const cleanedName = cleanUpString(params.name)
        if (cleanedName === '') return false
        if (usedNames.includes(cleanedName)) return false

        return true
    }, [params])

    useEffect(() => {
        if (params.rawMedia) {
            const media = mapMenuMediaToModel(menu.id, params.rawMedia)
            onSave({ ...menu, media })

            params.setRawMedia(undefined)
        }
    }, [params.rawMedia]) // eslint-disable-line react-hooks/exhaustive-deps

    const buildMediaUploadLink = useCallback(
        async (media: File) => {
            return await upload.run({ ...menu.id, media })
        },
        [menu] // eslint-disable-line react-hooks/exhaustive-deps
    )

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)

        if (params.hasChanges) {
            const newMenu = await update.run({
                menu: menu,
                name: params.name,
                description: params.description,
                footer: params.footer,
            })
            onSave(newMenu)
        }

        setIsProcessing(false)
    }

    return { isProcessing, canSubmit, submit, buildMediaUploadLink }
}
