import { RoomList, TableCreate } from '@avokadoapp/avokado-ts'
import { Geometry } from 'utils/geometry/Geometry'
import { Room } from '../models/Room'

export function mapRoomsToModels(payload: RoomList.Response): Room[] {
    return payload.rooms.map(mapRoomToModel)
}

export function mapRoomToModel(payload: RoomList.Response.Room): Room {
    return {
        id: {
            organizationId: payload.organizationId,
            locationId: payload.locationId,
            roomId: payload.id,
        },
        name: payload.name,
        isOpen: payload.isOpen,
        size: payload.size,
        tables: payload.tables.map((table) => ({
            id: {
                organizationId: payload.organizationId,
                locationId: payload.locationId,
                roomId: payload.id,
                tableId: table.id,
            },
            label: table.label,
            shape: mapShapeToModel(table),
        })),
    }
}

export function mapTableToModel(payload: TableCreate.Response): Room.Table {
    return {
        id: {
            organizationId: payload.organizationId,
            locationId: payload.locationId,
            roomId: payload.id,
            tableId: payload.id,
        },
        label: payload.label,
        shape: mapShapeToModel(payload),
    }
}

function mapShapeToModel(
    payload: RoomList.Response.Table
): Geometry.Shape | undefined {
    if (payload.radius && payload.center) {
        return {
            type: 'circle',
            center: payload.center,
            radius: payload.radius,
        }
    }

    if (payload.size && payload.center) {
        return {
            type: 'rectangle',
            center: payload.center,
            size: payload.size,
        }
    }
}
