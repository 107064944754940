import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Center,
    CloseButton,
    Collapse,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

export interface ContainerInfoAlertProxyProps {
    isVisible: boolean
    onClose: () => void
}

interface ContainerInfoAlertProps extends ContainerInfoAlertProxyProps {
    title: string
    description: string | ReactNode
}

export default function ContainerInfoAlert({
    isVisible,
    onClose,
    title,
    description,
}: ContainerInfoAlertProps) {
    const screen = useScreenBreakpoint()
    return (
        <Box
            as={Collapse}
            in={isVisible}
            animateOpacity
            width="full"
            maxW="8xl"
        >
            <Box pb={8} w="full">
                <Center px={screen === 'mobile' ? 2 : 0} w="full">
                    <Alert
                        status="info"
                        w="full"
                        maxW="8xl"
                        borderRadius="lg"
                        variant="left-accent"
                        boxShadow="lg"
                    >
                        <AlertIcon />

                        <Box w="full">
                            <AlertTitle>{title}</AlertTitle>
                            <AlertDescription w="full" whiteSpace="pre-line">
                                {description}
                            </AlertDescription>
                        </Box>

                        <CloseButton
                            alignSelf="flex-start"
                            position="absolute"
                            right={2}
                            top={2}
                            onClick={onClose}
                        />
                    </Alert>
                </Center>
            </Box>
        </Box>
    )
}
