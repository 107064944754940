import * as Sentry from '@sentry/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import LoadingPage from 'infra/navigation/pages/LoadingPage'
import { useInjection } from 'inversify-react'
import jwt_decode from 'jwt-decode'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'reflect-metadata'
import SessionStorageKeys from 'utils/constant/SessionStorageKeys'
import { AuthDependencies } from '../dependencies'
import { IdTokenPayload } from '../dependencies/RetrieveUserAction'
import { SignInResult } from '../dependencies/SignInAction'
import { mapUserToModel } from '../mappers/SDKResponseMapper'
import { ExpiredSession, User } from '../models/User'
import { AuthContext } from './AuthContext'
import useAuthProxyContext from './AuthProxyContext'

function nonCapturingSessionStorage(key: string): string | null {
    return sessionStorage.getItem(key)
}

export default function AuthContextProvider(props: PropsWithChildren) {
    const auth = useInjection(AuthDependencies.Store)

    const retrieveUser = useInjection(AuthDependencies.RetrieveUser)
    const signInAction = useInjection(AuthDependencies.SignIn)
    const socialSignInAction = useInjection(AuthDependencies.SocialSignIn)
    const signOutAction = useInjection(AuthDependencies.SignOut)
    const signUpAction = useInjection(AuthDependencies.SignUp)
    const confirmSignUpAction = useInjection(AuthDependencies.ConfirmAccount)
    const passwordForgottenAction = useInjection(
        AuthDependencies.ForgotPassword
    )
    const confirmPasswordForgottenAction = useInjection(
        AuthDependencies.ConfirmForgotPassword
    )

    const proxy = useAuthProxyContext()
    const organizationManager = useOrganizationContext()
    const navigate = useNavigate()

    const [user, setUser] = useState<User>()
    const [expiredSession, setExpiredSession] = useState<ExpiredSession>()
    const [isInitializing, setIsInitializing] = useState(true)

    const parseIdToken = useCallback(
        async (idToken: string, redirect: boolean) => {
            const { organizations, ...user } = await retrieveUser.run({
                idToken,
            })
            Sentry.setUser({
                id: user.id,
                email: user.email,
                'Session State': 'valid',
            })
            await organizationManager.init(organizations)

            setUser(user)
            setExpiredSession(undefined)
            proxy.setEmail(undefined)
            proxy.setPassword(undefined)

            if (redirect) {
                const lastVisitedPage = nonCapturingSessionStorage(
                    SessionStorageKeys.CurrentSessionPage
                )

                if (lastVisitedPage) {
                    setTimeout(() => {
                        navigate(lastVisitedPage)
                        // We need to delay the navigate call to the first recomposition after the one that instantiates the
                        // right router again (which is the recomposition triggered by user !== undefined).
                        // Adding a setTimeout, even with 0ms of delay, seems to be enough.
                    }, 0)
                } else {
                    navigate('/organizations')
                }
            }
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEffect(() => {
        document.addEventListener(
            'refresh_token_expired',
            prepareForReauthentication
        )

        return () =>
            document.removeEventListener(
                'refresh_token_expired',
                prepareForReauthentication
            )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function init() {
            setIsInitializing(true)
            const idToken = await auth.getIdToken()

            if (idToken) {
                await parseIdToken(idToken, false)
            } else if (auth.getFutureUsageIdToken()) {
                await prepareForReauthentication()
            }

            setIsInitializing(false)
        }

        init()

        // eslint-disable-next-line
    }, [])

    async function signIn(email: string, password: string) {
        const nextStep = await signInAction.run({ email, password })

        if (nextStep === SignInResult.Success) {
            await parseIdToken(await auth.assertIdToken(), true)
        } else {
            proxy.setEmail(email)
            proxy.setPassword(password)
            navigate('/confirm-sign-up')
        }
    }

    async function socialSignIn(
        idToken: string,
        locale: string,
        zoneInfo: string,
        givenName?: string,
        familyName?: string
    ) {
        await socialSignInAction.run({
            externalToken: idToken,
            locale,
            givenName,
            familyName,
        })
        await parseIdToken(await auth.assertIdToken(), true)
    }

    async function prepareForReauthentication() {
        const futureUsageToken = auth.getFutureUsageIdToken()
        if (!futureUsageToken) {
            await signOut()
            return
        }

        auth.setAccessToken(null)
        auth.setIdToken(null)
        auth.setRefreshToken(null)
        setUser(undefined)
        const decodedIdToken = jwt_decode<IdTokenPayload>(futureUsageToken)

        const user: ExpiredSession = {
            ...mapUserToModel(decodedIdToken),
            expiration: new Date(decodedIdToken.exp * 1000),
        }

        setExpiredSession(user)

        Sentry.setUser({
            id: user.id,
            email: user.email,
            'Session State': 'expired',
        })

        organizationManager.clear()
        proxy.setEmail(user.email)
        proxy.setPassword(undefined)

        navigate('/')
    }

    async function signOut() {
        await signOutAction.run()
        setUser(undefined)
        setExpiredSession(undefined)
        proxy.setEmail(undefined)
        proxy.setPassword(undefined)
        organizationManager.clear()
        sessionStorage.removeItem(SessionStorageKeys.CurrentSessionPage)

        Sentry.setUser(null)

        navigate('/')
    }

    async function signUp(
        email: string,
        password: string,
        locale: string,
        zoneInfo: string,
        givenName?: string,
        familyName?: string
    ) {
        await signUpAction.run({
            email,
            password,
            locale,
            zoneInfo,
            givenName,
            familyName,
        })
        proxy.setEmail(email)
        proxy.setPassword(password)
        navigate('/confirm-sign-up')
    }

    async function confirmSignUp(confirmationCode: string) {
        if (!proxy.email || !proxy.password) return

        await confirmSignUpAction.run({
            email: proxy.email,
            password: proxy.password,
            confirmationCode: confirmationCode,
        })

        await parseIdToken(await auth.assertIdToken(), true)
    }

    async function passwordForgotten(email: string) {
        await passwordForgottenAction.run({ email })
        proxy.setEmail(email)
        navigate(`/reset-password`)
    }

    async function confirmPasswordForgotten(
        password: string,
        confirmationCode: string
    ) {
        if (!proxy.email) return

        await confirmPasswordForgottenAction.run({
            email: proxy.email,
            password: password,
            confirmationCode: confirmationCode,
        })

        await parseIdToken(await auth.assertIdToken(), true)
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                expiredSession,
                signIn,
                socialSignIn,
                signOut,
                signUp,
                confirmSignUp,
                passwordForgotten,
                confirmPasswordForgotten,
            }}
        >
            {isInitializing ? <LoadingPage /> : props.children}
        </AuthContext.Provider>
    )
}
