import Avokado, {
    AvokadoAPIError,
    LocationCreate,
} from '@avokadoapp/avokado-ts'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { computeLocationViolations } from '../mappers/InternalMapper'
import { mapLocationToModel } from '../mappers/SDKResponseMapper'
import { Location } from '../models/Location'

interface CreateLocationParams extends Organization.ID {
    name: string
}

export interface CreateLocationAction {
    run(params: CreateLocationParams): Promise<Location>
}

@injectable()
export class CreateLocationActionLive implements CreateLocationAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateLocationParams): Promise<Location> {
        const request: LocationCreate.Request = params
        const response = await this.avokado.location.create(request)
        return mapLocationToModel(response, []) // TODO: pass menu assignments
    }
}

@injectable()
export class CreateLocationActionFake implements CreateLocationAction {
    async run(params: CreateLocationParams): Promise<Location> {
        await sleep(500)

        const location: Omit<Location, 'violations'> = {
            id: { organizationId: params.organizationId, locationId: 1 },
            name: params.name,
            published: false,
            restricted: false,
            medias: [],
            address: undefined,
            remainingAddressChanges: 5,
            policy: {},
            calendar: {
                weekdays: {
                    monday: { type: 'full-day', isActive: false },
                    tuesday: { type: 'full-day', isActive: false },
                    wednesday: { type: 'full-day', isActive: false },
                    thursday: { type: 'full-day', isActive: false },
                    friday: { type: 'full-day', isActive: false },
                    saturday: { type: 'full-day', isActive: false },
                    sunday: { type: 'full-day', isActive: false },
                },
                scheduledClosures: [],
            },
            category: undefined,
            subcategories: [],
            capacity: undefined,
            capabilities: [],
            menuAssignments: [],
            stripe: { id: undefined },
        }

        return { ...location, violations: computeLocationViolations(location) }
    }
}

@injectable()
export class CreateLocationActionFail implements CreateLocationAction {
    async run(params: CreateLocationParams): Promise<Location> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
