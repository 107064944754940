import { RawMedia } from 'features/media/models/RawMedia'
import { Menu } from 'features/menu/models/Menu'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { UseNewEntryFormDataHook, useNewEntryFormData } from './useNewEntryForm'

export const NewMenuConfigSectionsStep = 3

type EditMode = 'configure' | 'entries'
interface UseNewMenuSectionFormDataHook {
    description: string
    isUploading: boolean
    rawMedia: RawMedia | undefined
    setDescription: Dispatch<SetStateAction<string>>
    setIsUploading: Dispatch<SetStateAction<boolean>>
    setRawMedia: Dispatch<SetStateAction<RawMedia | undefined>>
    reset: (section?: Menu.Section) => void
}

function useNewMenuSectionFormData(): UseNewMenuSectionFormDataHook {
    const [description, setDescription] = useState('')
    const [isUploading, setIsUploading] = useState(false)
    const [rawMedia, setRawMedia] = useState<RawMedia>()

    const reset = useCallback((section?: Menu.Section) => {
        setDescription(section?.description ?? '')
        setIsUploading(false)
        setRawMedia(undefined)
    }, [])

    return {
        description,
        isUploading,
        rawMedia,
        setDescription,
        setIsUploading,
        setRawMedia,
        reset,
    }
}

export interface UseNewMenuConfigSectionsStepFormDataHook {
    section: UseNewMenuSectionFormDataHook
    entry: UseNewEntryFormDataHook
    current: { index: number; mode: EditMode } | undefined
    setSection: (params?: {
        section: Menu.Section
        index: number
        mode: EditMode
    }) => void
}

export function useNewMenuConfigSectionsStepFormData(): UseNewMenuConfigSectionsStepFormDataHook {
    const section = useNewMenuSectionFormData()
    const entry = useNewEntryFormData()

    const [current, setCurrent] = useState<{ index: number; mode: EditMode }>()

    const setSection = useCallback(
        (params?: { section: Menu.Section; index: number; mode: EditMode }) => {
            if (params) {
                setCurrent({ index: params.index, mode: params.mode })
            } else {
                setCurrent(undefined)
            }

            section.reset(params?.section)
            entry.reset()
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return { section, entry, current, setSection }
}

export function isConfigured(section: Menu.Section): boolean {
    return (
        (section.description !== undefined && section.description !== '') ||
        section.media !== undefined ||
        section.entries.length > 0
    )
}
