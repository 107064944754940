import { MediaProcessed } from '@avokadoapp/avokado-ts/lib/events/MediaProcessed'
import axios from 'axios'
import { EventDependencies } from 'features/event/dependencies'
import { useEventEffect } from 'features/event/hooks/useEventEffect'
import { useInjection } from 'inversify-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MediaUploadError } from '../errors/MediaUploadError'
import { mapExtensionToLocationFormat } from '../mappers/SDKRequestMapper'
import { MediaUploadLink } from '../models/MediaUploadLink'
import { MediaUploadTaskStatus } from '../models/MediaUploadTaskStatus'
import { RawMedia } from '../models/RawMedia'

export interface UseMediaUploadParams {
    automaticReset: boolean
    automaticUploadMediaAction?: (media: File) => Promise<MediaUploadLink>
    onUploadingStatusChanged: (status: boolean) => void
    onUploadEnded: (rawMedia: RawMedia) => void
}

interface UseMediaUploadHook {
    status: MediaUploadTaskStatus
}

export function useMediaUpload(
    params: UseMediaUploadParams
): UseMediaUploadHook {
    const eventClient = useInjection(EventDependencies.Client)

    const [file, setFile] = useState<File>()
    const [url, setURL] = useState<string>()
    const [mediaId, setMediaId] = useState<string>()
    const [isUploading, setIsUploading] = useState(false)
    const [hasUploaded, setHasUploaded] = useState(false)
    const [error, setError] = useState<any>()

    useEffect(
        () => params.onUploadingStatusChanged(isUploading),
        [isUploading] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEventEffect(
        (event) => {
            if (event.type !== 'media-processed') return
            if (event.media.mediaId !== mediaId) return

            switch (event.media.result) {
                case MediaProcessed.EventPayload.Result.Success:
                    params.onUploadEnded({
                        id: mediaId.split('-')[0],
                        extension: mapExtensionToLocationFormat(file!.type),
                    })

                    if (params.automaticReset) {
                        onReset()
                    } else {
                        setIsUploading(false)
                        setHasUploaded(true)
                    }
                    break

                case MediaProcessed.EventPayload.Result.ProhibitedContent:
                    setIsUploading(false)
                    setError(
                        new MediaUploadError.ProcessingError({
                            type: 'prohibited-content',
                            moderationLabel: event.media.metadata['category'],
                        })
                    )
                    break

                default:
                    setIsUploading(false)
                    setError(
                        new MediaUploadError.ProcessingError({
                            type: 'generic',
                        })
                    )
                    break
            }
        },
        undefined,
        [mediaId]
    )

    const onUpload = useCallback(
        async (
            uploadMediaAction: (media: File) => Promise<MediaUploadLink>
        ) => {
            if (!file || isUploading || hasUploaded || error) return

            setIsUploading(true)

            try {
                const uploadLink = await uploadMediaAction(file)
                setMediaId(uploadLink.id)
                await axios.put(uploadLink.url, file)
            } catch (error: any) {
                setError(error)
                setIsUploading(false)
            }
        },
        [file, isUploading, hasUploaded, error]
    )

    const onReset = useCallback(() => {
        setFile(undefined)
        setURL(undefined)
        setMediaId(undefined)
        setIsUploading(false)
        setHasUploaded(false)
        setError(undefined)
    }, [])

    const onDrop = useCallback((file: File) => {
        setFile(file)
        setURL(URL.createObjectURL(file))
    }, [])

    useEffect(() => {
        if (file && params.automaticUploadMediaAction) {
            onUpload(params.automaticUploadMediaAction)
        }
    }, [file, onUpload, params.automaticUploadMediaAction])

    const status: MediaUploadTaskStatus = useMemo(() => {
        if (!file || !url) return { type: 'idle', onDrop }
        if (isUploading) return { type: 'uploading', url }
        if (hasUploaded) return { type: 'success', url, onReset }
        if (error) return { type: 'failure', error, onReset }

        return { type: 'awaiting-confirmation', url, onUpload, onReset }
    }, [file, url, isUploading, hasUploaded, error, onDrop, onUpload, onReset])

    return { status }
}
