import { Link, useConst, useSteps } from '@chakra-ui/react'
import useLocationsContext from 'features/location/contexts/LocationsContext'
import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import { MediaUploadTaskStatus } from 'features/media/models/MediaUploadTaskStatus'
import useMenusContext from 'features/menu/contexts/MenusContext'
import { MenuEntryDependencies } from 'features/menu/dependencies/entry'
import { MenuDependencies } from 'features/menu/dependencies/menu'
import { TooManyMenuError } from 'features/menu/dependencies/menu/CreateMenuAction'
import { MenuSectionDependencies } from 'features/menu/dependencies/section'
import {
    mapEntryMediaToModel,
    mapMenuMediaToModel,
    mapSectionMediaToModel,
} from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import { newMenuSteps } from 'features/menu/pages/NewMenuPage'
import { NotificationVariant } from 'features/notification/components/CustomNotification'
import { useNotification } from 'features/notification/hooks/useNotification'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useInjection } from 'inversify-react'
import useT from 'localization/hooks/useT'
import _ from 'lodash'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { cleanUpString } from 'utils/types'
import {
    NewMenuAssignStep,
    UseNewMenuAssignStepFormDataHook,
    handleAssignStep,
    useNewMenuAssignStepFormData,
} from './useNewMenuAssignStepFormData'
import {
    NewMenuConfigSectionsStep,
    UseNewMenuConfigSectionsStepFormDataHook,
    isConfigured,
    useNewMenuConfigSectionsStepFormData,
} from './useNewMenuConfigSections'
import {
    NewMenuCreateSectionsStep,
    UseNewMenuCreateSectionsStepFormDataHook,
    handleCreateSectionsStep,
    useNewMenuCreateSectionsStepFormData,
} from './useNewMenuCreateSections'
import {
    NewMenuGeneralStep,
    UseNewMenuGeneralStepFormDataHook,
    handleGeneralStep,
    useNewMenuGeneralStepFormData,
} from './useNewMenuGeneralStep'
import {
    NewMenuImageStep,
    UseNewMenuImageStepFormDataHook,
    useNewMenuImageStepFormData,
} from './useNewMenuImageStep'

export interface UseNewMenuFormDataHook {
    general: UseNewMenuGeneralStepFormDataHook
    image: UseNewMenuImageStepFormDataHook
    createSections: UseNewMenuCreateSectionsStepFormDataHook
    configSections: UseNewMenuConfigSectionsStepFormDataHook
    assign: UseNewMenuAssignStepFormDataHook
}

export function useNewMenuFormData(): UseNewMenuFormDataHook {
    const general = useNewMenuGeneralStepFormData()
    const image = useNewMenuImageStepFormData()
    const createSections = useNewMenuCreateSectionsStepFormData()
    const configSections = useNewMenuConfigSectionsStepFormData()
    const assign = useNewMenuAssignStepFormData()

    return { general, image, createSections, configSections, assign }
}

interface UseNewMenuFormControllerHook extends UseFormControllerHook<void> {
    activeStep: number
    hideControls: boolean
    hideSkip: boolean

    menu?: Menu

    goBack: () => void
    skip: (by: number) => void

    sectionSubmit: (uploadStatus: MediaUploadTaskStatus) => Promise<void>
    buildMenuMediaUploadLink: (media: File) => Promise<MediaUploadLink>
}

export function useNewMenuFormController(
    params: UseNewMenuFormDataHook
): UseNewMenuFormControllerHook {
    const t = useT('menu')

    // Dependencies
    const menuUpload = useInjection(MenuDependencies.UploadMedia)
    const sectionUpload = useInjection(MenuSectionDependencies.UploadMedia)
    const sectionUpdate = useInjection(MenuSectionDependencies.Update)
    const entryUpload = useInjection(MenuEntryDependencies.UploadMedia)
    const entryCreate = useInjection(MenuEntryDependencies.Create)

    // Notification
    const notification = useNotification()

    // Manager
    const organizationManager = useOrganizationContext()
    const locationsManager = useLocationsContext()
    const menusManager = useMenusContext()

    const { organizationId } = useConst(
        () => organizationManager.assertOrganization().id
    )

    // Form data
    const { general, image, createSections, configSections, assign } = params

    // State
    const [menu, setMenu] = useState<Menu>()
    const [isProcessing, setIsProcessing] = useState(false)

    const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: newMenuSteps.length,
    })

    //
    useEffect(() => {
        if (menu) menusManager.update(menu)
    }, [menu]) // eslint-disable-line react-hooks/exhaustive-deps

    // Button status
    const usedMenuNames = useMemo(() => {
        const otherMenus = menu
            ? menusManager.menus.filter(
                  (other) => other.id.menuId !== menu.id.menuId
              )
            : menusManager.menus
        return otherMenus.map((menu) => menu.name)
    }, [menusManager.menus, menu])

    const canSubmit = useMemo(() => {
        switch (activeStep) {
            case NewMenuGeneralStep:
                const cleanedName = cleanUpString(general.name)
                if (cleanedName === '') return false
                if (usedMenuNames.includes(cleanedName)) return false
                break
            case NewMenuImageStep:
                if (!image.rawMedia) return false
                if (image.isUploading) return false
                break
            case NewMenuCreateSectionsStep:
                const sections = createSections.sections
                if (sections.length === 0) return false
                if (sections.some((s) => cleanUpString(s) === '')) return false
                if (
                    sections.some(
                        (key, index) => sections.indexOf(key) !== index
                    )
                )
                    return false
                break
            case NewMenuConfigSectionsStep:
                if (configSections.current !== undefined) return false
                break
            case NewMenuAssignStep:
                if (locationsManager.isLoading) return false
                return true
            default:
                break
        }

        return true
    }, [
        locationsManager,
        usedMenuNames,
        activeStep,
        general,
        image,
        createSections,
        configSections,
    ])

    const hideControls = useMemo(() => {
        switch (activeStep) {
            case NewMenuConfigSectionsStep:
                if (configSections.current !== undefined) return true
                break
            default:
                break
        }

        return false
    }, [activeStep, configSections])

    const hideSkip = useMemo(() => {
        switch (activeStep) {
            case NewMenuImageStep:
                if (menu?.media) return true
                break
            case NewMenuConfigSectionsStep:
                if (configSections.current !== undefined) return true
                for (const section of menu!.sections) {
                    if (isConfigured(section)) return true
                }
                break
            default:
                break
        }

        return false
    }, [activeStep, menu, configSections])

    // Submit
    async function handleSubmit() {
        switch (activeStep) {
            case NewMenuGeneralStep:
                return await handleGeneralStep(organizationId, general, menu)
            case NewMenuCreateSectionsStep:
                return await handleCreateSectionsStep(createSections, menu!)
            case NewMenuAssignStep:
                return await handleAssignStep(assign, menu!)
            default:
                break
        }
    }

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const newMenu = await handleSubmit()
            if (newMenu) {
                setMenu(newMenu)
            }
            goToNext()
        } catch (error: any) {
            let errorTitle = t('create-error.generic.title')
            let errorDescription: ReactNode = t(
                'create-error.generic.description'
            )
            let variant: NotificationVariant = 'error'

            if (error instanceof TooManyMenuError) {
                errorTitle = t('create-error.quota-limit.title')
                errorDescription = (
                    <Trans
                        t={t}
                        i18nKey="create-error.quota-limit.description"
                        components={[
                            <Link
                                isExternal
                                color="accent"
                                href="mailto:support@avokadoapp.ch"
                            />,
                        ]}
                    />
                )
                variant = 'info'
            }

            notification({
                id: 'new-menu-submit',
                title: errorTitle,
                description: errorDescription,
                variant: variant,
            })
        } finally {
            setIsProcessing(false)
        }
    }

    function skip(by: number) {
        if (by <= 0 || activeStep + by > newMenuSteps.length) {
            throw new Error('Invalid step count')
        }

        setActiveStep(activeStep + by)
    }

    // Menu controller
    useEffect(() => {
        setMenu((previous) => {
            if (!previous) return previous
            if (!image.rawMedia) return previous

            return {
                ...previous,
                media: mapMenuMediaToModel(previous.id, image.rawMedia),
            }
        })
    }, [image.rawMedia])

    const buildMenuMediaUploadLink = useCallback(
        async (media: File) => {
            if (!menu) {
                throw new Error('Cannot set media before a menu is created')
            }

            return await menuUpload.run({ ...menu.id, media })
        },
        [menu] // eslint-disable-line react-hooks/exhaustive-deps
    )

    // Section controller
    useEffect(() => {
        if (!menu) return
        if (!configSections.section.rawMedia) return
        if (configSections.current === undefined) return
        if (
            menu.sections[configSections.current.index].media?.id ===
            configSections.section.rawMedia.id
        )
            return

        setMenu((previous) => {
            if (!previous) return

            return {
                ...previous,
                sections: previous.sections.map((section, index) => {
                    if (index === configSections.current?.index) {
                        return {
                            ...section,
                            media: mapSectionMediaToModel(
                                section.id,
                                configSections.section.rawMedia
                            ),
                        }
                    } else {
                        return section
                    }
                }),
            }
        })
        configSections.setSection(undefined)
    }, [menu, configSections])

    const updateSection = useCallback(
        async (uploadStatus: MediaUploadTaskStatus) => {
            if (isProcessing) return
            if (!menu || configSections.current === undefined) return
            const section = menu.sections[configSections.current.index]

            setIsProcessing(true)
            try {
                if (
                    (section.description ?? '') !==
                    configSections.section.description
                ) {
                    const newMenu = await sectionUpdate.run({
                        menu,
                        section,
                        description: configSections.section.description,
                    })
                    setMenu(newMenu)
                }

                if (uploadStatus.type === 'awaiting-confirmation') {
                    uploadStatus.onUpload(
                        async (media: File) =>
                            await sectionUpload.run({ ...section.id, media })
                    )
                } else {
                    configSections.setSection(undefined)
                }
            } finally {
                setIsProcessing(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isProcessing, menu, configSections]
    )

    // Entry controller
    useEffect(() => {
        if (!menu) return
        if (!configSections.entry.rawMedia) return
        if (configSections.current === undefined) return

        setMenu((previous) => {
            if (!previous) return

            return {
                ...previous,
                sections: previous.sections.map((section, index) => {
                    if (index === configSections.current?.index) {
                        const entries = section.entries
                        const old = entries.slice(0, entries.length - 1)
                        const entry = entries[entries.length - 1]

                        return {
                            ...section,
                            entries: [
                                ...old,
                                {
                                    ...entry,
                                    media: mapEntryMediaToModel(
                                        entry.id,
                                        configSections.entry.rawMedia
                                    ),
                                },
                            ],
                        }
                    } else {
                        return section
                    }
                }),
            }
        })
        configSections.entry.reset()
    }, [menu, configSections])

    const createEntry = useCallback(
        async (uploadStatus: MediaUploadTaskStatus) => {
            if (isProcessing) return
            if (!menu || configSections.current === undefined) return
            const section = menu.sections[configSections.current.index]

            setIsProcessing(true)
            try {
                const newMenu = await entryCreate.run({
                    menu,
                    section,
                    name: configSections.entry.name,
                    description: configSections.entry.description,
                    price: configSections.entry.price,
                    allergens: configSections.entry.allergens,
                    features: configSections.entry.features,
                })
                setMenu(newMenu)

                if (uploadStatus.type === 'awaiting-confirmation') {
                    const { entries } =
                        newMenu.sections[configSections.current.index]
                    const entry = entries[entries.length - 1]
                    uploadStatus.onUpload(
                        async (media: File) =>
                            await entryUpload.run({ ...entry.id, media })
                    )
                } else {
                    configSections.entry.reset()
                }
            } finally {
                setIsProcessing(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isProcessing, menu, configSections]
    )

    // Section and Entry submit
    const sectionSubmit = useCallback(
        async (uploadStatus: MediaUploadTaskStatus) => {
            if (configSections.entry.name) {
                await createEntry(uploadStatus)
            } else {
                await updateSection(uploadStatus)
            }
        },
        [updateSection, createEntry, configSections.entry]
    )

    return {
        activeStep,
        canSubmit,
        hideControls,
        hideSkip,
        isProcessing:
            isProcessing ||
            image.isUploading ||
            configSections.section.isUploading ||
            configSections.entry.isUploading,
        menu: _.cloneDeep(menu),
        submit,
        goBack: goToPrevious,
        skip,
        sectionSubmit,
        buildMenuMediaUploadLink,
    }
}
