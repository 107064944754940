import {
    Card,
    CardBody,
    Circle,
    HStack,
    Heading,
    Image,
    Text,
    VStack,
} from '@chakra-ui/react'
import { readerColor, readerImage } from '../mappers/InternalMapper'
import { CardReader } from '../models/CardReader'

interface CardReaderCardProps {
    cardReader: CardReader
}

export function CardReaderCard({ cardReader }: CardReaderCardProps) {
    return (
        <Card bg="pageBackground3" w="full">
            <CardBody position="relative">
                <VStack w="full" align="stretch">
                    <Image
                        draggable={false}
                        w="full"
                        h={120}
                        objectFit="contain"
                        src={readerImage(cardReader)}
                    />
                    <VStack w="full" align="stretch" spacing={0}>
                        <HStack align="end">
                            <Heading fontSize="xl" fontWeight="medium">
                                {cardReader.label}
                            </Heading>
                            <Text color="label2" fontSize="sm">
                                v{cardReader.softwareVersion}
                            </Text>
                        </HStack>

                        <Text color="label2" fontSize="sm">
                            #{cardReader.serialNumber}
                        </Text>
                    </VStack>
                </VStack>

                <Circle
                    position="absolute"
                    top={2}
                    right={2}
                    size={4}
                    bg={readerColor(cardReader)}
                />
            </CardBody>
        </Card>
    )
}
