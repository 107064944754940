import { FormControl, Select } from '@chakra-ui/react'
import { describeCategory as describe } from 'features/location/mappers/category/InternalMapper'
import { Location } from 'features/location/models/Location'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSection } from 'uikit/form'

const options: number[] = Object.keys(Location.Category)
    .map((x) => parseInt(x))
    .filter((x) => !isNaN(x))

interface CategorySectionProps {
    category: Location.Category | undefined
    setCategory: Dispatch<SetStateAction<Location.Category | undefined>>
}

export function CategorySection({
    category,
    setCategory,
}: CategorySectionProps) {
    const { t } = useTranslation()

    return (
        <FormSection
            title={t('Category')}
            subtitle={t('The category that primarily describes your venue.')}
        >
            <FormControl>
                <Select
                    placeholder={
                        category === undefined ? t('Category') : undefined
                    }
                    value={category}
                    onChange={(e) => setCategory(parseInt(e.target.value))}
                >
                    {options.map((option) => (
                        <option key={option} value={option}>
                            {t(describe(option))}
                        </option>
                    ))}
                </Select>
            </FormControl>
        </FormSection>
    )
}
