import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionItemProps,
    AccordionPanel,
    Avatar,
    Button,
    ButtonGroup,
    Center,
    Checkbox,
    Divider,
    Fade,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Input,
    Spinner,
    Stack,
    Text,
    Textarea,
    VStack,
    useAccordionItemState,
    useConst,
} from '@chakra-ui/react'
import { ImageUploader } from 'features/media/components/ImageUploader'
import { UseMediaUploadParams } from 'features/media/hooks/useMediaUpload'
import useT from 'localization/hooks/useT'
import { forwardRef, useEffect, useRef } from 'react'
import { SortableItem, SortableKnob } from 'react-easy-sort'
import { FiPlus } from 'react-icons/fi'
import { TbGripVertical } from 'react-icons/tb'
import { PriceInput } from 'uikit/form/input'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { usePriceFormatter } from 'utils/types/formatters'
import {
    useNewEntryFormController,
    useNewEntryFormData,
} from '../hooks/new/useNewEntryForm'
import { useEntryFormController, useEntryFormData } from '../hooks/useEntryForm'
import { Menu } from '../models/Menu'
import { MenuPageContentProps } from '../pages/MenuPage'
import { MenuImageUploaderRenderer } from './MenuImageUploaderRenderer'
import { Allergens } from './allergen/Allergens'
import { AllergensInput } from './allergen/AllergensInput'
import { ExtrasInput } from './extra/ExtrasInput'
import { FeaturesInput } from './feature/FeaturesInput'

interface EntryProps extends MenuPageContentProps {
    section: Menu.Section
    entry: Menu.Section.Entry
    isGloballyUpdating: boolean
    isDraggable: boolean
    isSelected: boolean
    onSelect: () => void
}

export function Entry({
    mt,
    ...props
}: EntryProps & Pick<AccordionItemProps, 'mt'>) {
    return (
        <SortableItem>
            <AccordionItem
                bg="pageBackground3"
                border="none"
                mt={mt}
                borderRadius="lg"
            >
                <EntryContent {...props} />
            </AccordionItem>
        </SortableItem>
    )
}

function EntryContent({
    menu,
    section,
    entry,
    isGloballyUpdating,
    isDraggable,
    isSelected,
    onSelect,
    update,
}: EntryProps) {
    const t = useT('menu')
    const screen = useScreenBreakpoint()

    const formatter = usePriceFormatter()

    const state = useAccordionItemState()

    const formData = useEntryFormData(entry)
    const controller = useEntryFormController(
        menu,
        section,
        entry,
        isGloballyUpdating,
        formData,
        update
    )

    const config: UseMediaUploadParams = useConst(() => ({
        automaticReset: true,
        automaticUploadMediaAction: controller.buildMediaUploadLink,
        onUploadingStatusChanged: formData.setIsUploadingCover,
        onUploadEnded: formData.setRawMedia,
    }))

    useEffect(() => {
        if (controller.isProcessing || isDraggable) {
            state.onClose()

            if (isSelected) {
                onSelect()
            }
        }
    }, [controller.isProcessing, isDraggable])

    return (
        <>
            <AccordionButton
                py={4}
                px={screen === 'mobile' ? 2 : 4}
                borderRadius="lg"
                textAlign="start"
            >
                <HStack w="full">
                    {controller.isProcessing ? (
                        <Spinner size="sm" />
                    ) : isDraggable ? (
                        <SortableKnob>
                            <DraggableKnob />
                        </SortableKnob>
                    ) : (
                        <Checkbox isChecked={isSelected} onChange={onSelect} />
                    )}

                    <Avatar
                        bg="accentSurface"
                        color="onAccentSurface"
                        size="xs"
                        name={entry.name}
                        src={entry.media?.small()}
                        getInitials={(name) => name[0]}
                    />
                    <Text flex={6} fontWeight="medium" noOfLines={1}>
                        {entry.name}
                    </Text>
                    <Allergens
                        max={2}
                        size="xs"
                        fontSize="xs"
                        allergens={entry.allergens}
                        borderColor="pageBackground3"
                    />
                    <Text flex={1} fontSize="sm" textAlign="end">
                        {formatter.format(entry.price)}
                    </Text>

                    <Fade in={!isDraggable}>
                        <AccordionIcon />
                    </Fade>
                </HStack>
            </AccordionButton>

            <AccordionPanel>
                <VStack spacing={8} w="full" align="stretch">
                    <Stack
                        spacing={8}
                        align="stretch"
                        direction={screen === 'mobile' ? 'column' : 'row'}
                    >
                        <ImageUploader
                            config={config}
                            renderer={({ status, dropzone }) => (
                                <MenuImageUploaderRenderer
                                    size="150px"
                                    bg="pageBackground2"
                                    currentMedia={entry.media}
                                    status={status}
                                    dropzone={dropzone}
                                />
                            )}
                        />

                        <VStack spacing={8} w="full" align="stretch">
                            <FormControl>
                                <FormLabel>
                                    {t('menu_entry_description_label')}
                                </FormLabel>
                                <Textarea
                                    flex={1}
                                    resize="none"
                                    placeholder={t(
                                        'menu_entry_description_placeholder'
                                    )}
                                    variant="filled"
                                    bg="pageBackground2"
                                    maxLength={250}
                                    value={formData.description}
                                    onChange={(e) =>
                                        formData.setDescription(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                        }
                                    }}
                                />
                                <FormHelperText>
                                    {t('menu_entry_description_helper')}
                                </FormHelperText>
                            </FormControl>

                            {screen === 'mobile' && <Divider />}
                            <FormControl>
                                <FormLabel>
                                    {t('menu_entry_price_label')}
                                </FormLabel>
                                <PriceInput
                                    isRequired
                                    variant="filled"
                                    direction="vertical"
                                    bg="pageBackground2"
                                    value={formData.price}
                                    setValue={formData.setPrice}
                                />
                                <FormHelperText>
                                    {t('menu_entry_price_helper')}
                                </FormHelperText>
                            </FormControl>
                        </VStack>
                    </Stack>

                    <Divider />

                    <AllergensInput
                        variant="filled"
                        bg="pageBackground2"
                        allergens={formData.allergens}
                        setAllergens={formData.setAllergens}
                        placeholder={t('generic_allergens_label')}
                        label={t('allergens_input_label')}
                        helperText={t('allergens_input_helper')}
                    />

                    <Divider />

                    <FormControl>
                        <FormLabel>{t('menu_entry_features_label')}</FormLabel>
                        <FeaturesInput
                            features={formData.features}
                            setFeatures={formData.setFeatures}
                        />
                        <FormHelperText>
                            {t('menu_entry_features_helper')}
                        </FormHelperText>
                    </FormControl>

                    <Divider />

                    <ExtrasInput
                        variant="filled"
                        bg="pageBackground2"
                        menu={menu}
                        section={section}
                        entry={entry}
                        extras={formData.extras}
                        setExtras={formData.setExtras}
                        onCreate={update}
                    />

                    <ButtonGroup size="sm" variant="ghost" justifyContent="end">
                        <Button
                            onClick={() => {
                                formData.reset()
                            }}
                            isDisabled={controller.isProcessing}
                        >
                            {t('cancel_button')}
                        </Button>
                        <Button
                            isDisabled={!controller.canSubmit}
                            isLoading={controller.isProcessing}
                            onClick={controller.submit}
                        >
                            {t('save_button')}
                        </Button>
                    </ButtonGroup>
                </VStack>
            </AccordionPanel>
        </>
    )
}

export function NewEntryInput({
    menu,
    section,
    update,
}: MenuPageContentProps & { section: Menu.Section }) {
    const t = useT('menu')
    const screen = useScreenBreakpoint()

    const ref = useRef<HTMLInputElement>(null)

    const formData = useNewEntryFormData()
    const controller = useNewEntryFormController(
        menu,
        section,
        formData,
        update
    )

    return (
        <Accordion allowToggle>
            <AccordionItem
                bg="pageBackground3"
                border="none"
                mt={2}
                borderRadius="lg"
            >
                {({ isExpanded }) => (
                    <>
                        <AccordionButton
                            as={HStack}
                            p={4}
                            borderRadius="lg"
                            textAlign="start"
                            cursor="pointer"
                        >
                            <HStack w="full" spacing={4}>
                                <Stack
                                    w="full"
                                    direction={
                                        screen === 'mobile' ? 'column' : 'row'
                                    }
                                >
                                    <FormControl>
                                        <Input
                                            ref={ref}
                                            variant="filled"
                                            bg="pageBackground2"
                                            value={formData.name}
                                            onChange={(e) =>
                                                formData.setName(e.target.value)
                                            }
                                            maxLength={50}
                                            placeholder={t(
                                                'menu_entry_name_placeholder'
                                            )}
                                            onClick={(event) =>
                                                event.stopPropagation()
                                            }
                                        />
                                        <FormHelperText ps={2}>
                                            {t('menu_entry_name_helper')}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl>
                                        <PriceInput
                                            isRequired
                                            variant="filled"
                                            bg="pageBackground2"
                                            value={formData.price}
                                            setValue={formData.setPrice}
                                            isElastic
                                        />
                                        <FormHelperText ps={2}>
                                            {t('menu_entry_price_helper')}
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>

                                {!isExpanded && (
                                    <IconButton
                                        aria-label="Create"
                                        icon={<FiPlus />}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            controller.submit()
                                        }}
                                        isDisabled={!controller.canSubmit}
                                        isLoading={controller.isProcessing}
                                        colorScheme="gray"
                                        bg="pageBackground2"
                                        size="sm"
                                        borderRadius="full"
                                    />
                                )}
                            </HStack>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            <VStack spacing={4} w="full" align="stretch">
                                <Divider />
                                <FormControl>
                                    <FormLabel>
                                        {t('menu_entry_description_label')}
                                    </FormLabel>
                                    <Textarea
                                        flex={1}
                                        resize="none"
                                        placeholder={t(
                                            'menu_entry_description_placeholder'
                                        )}
                                        variant="filled"
                                        bg="pageBackground2"
                                        maxLength={250}
                                        value={formData.description}
                                        onChange={(e) =>
                                            formData.setDescription(
                                                e.target.value
                                            )
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault()
                                            }
                                        }}
                                    />
                                    <FormHelperText>
                                        {t('menu_entry_description_helper')}
                                    </FormHelperText>
                                </FormControl>

                                <Divider />

                                <AllergensInput
                                    variant="filled"
                                    bg="pageBackground2"
                                    allergens={formData.allergens}
                                    setAllergens={formData.setAllergens}
                                    placeholder={t('generic_allergens_label')}
                                    label={t('allergens_input_label')}
                                    helperText={t('allergens_input_helper')}
                                />

                                <Divider />

                                <FormControl>
                                    <FormLabel>
                                        {t('menu_entry_features_label')}
                                    </FormLabel>
                                    <FeaturesInput
                                        features={formData.features}
                                        setFeatures={formData.setFeatures}
                                    />
                                    <FormHelperText>
                                        {t('menu_entry_features_helper')}
                                    </FormHelperText>
                                </FormControl>
                                <HStack justify="end">
                                    <Button
                                        onClick={controller.submit}
                                        isDisabled={!controller.canSubmit}
                                        isLoading={controller.isProcessing}
                                        size="sm"
                                        variant="ghost"
                                    >
                                        {t('generic_create_button')}
                                    </Button>
                                </HStack>
                            </VStack>
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </Accordion>
    )
}

const DraggableKnob = forwardRef<HTMLDivElement, {}>((_, ref) => {
    return (
        <Center ref={ref}>
            <Icon as={TbGripVertical} />
        </Center>
    )
})
