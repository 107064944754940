import { interfaces } from 'inversify'
import { ConfigurationStore } from './ConfigurationStore'
import { RetrieveConfigurationAction } from './RetrieveConfigurationAction'

interface _ConfigurationDependencies {
    Retrieve: interfaces.ServiceIdentifier<RetrieveConfigurationAction>
    Store: interfaces.ServiceIdentifier<ConfigurationStore>
}

export const ConfigurationDependencies: _ConfigurationDependencies = {
    Retrieve: Symbol('RetrieveConfigurationAction'),
    Store: Symbol('ConfigurationStore'),
}

export type { RetrieveConfigurationAction, ConfigurationStore }
