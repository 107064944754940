import { Link } from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Trans } from 'react-i18next'
import ContainerInfoAlert, {
    ContainerInfoAlertProxyProps,
} from '../shared/ContainerInfoAlert'

export default function GalleryHelperAlert({
    isVisible,
    onClose,
}: ContainerInfoAlertProxyProps) {
    const t = useT('gallery')

    return (
        <ContainerInfoAlert
            isVisible={isVisible}
            onClose={onClose}
            title={t('helper_alert_title')}
            description={
                <Trans
                    t={t}
                    i18nKey={'helper_alert_description'}
                    components={[
                        <Link
                            isExternal
                            fontWeight="bold"
                            href="https://developer.apple.com/app-clips/"
                        />,
                    ]}
                />
            }
        />
    )
}
