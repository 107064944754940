import { Location } from 'features/location/models/Location'

export function mapCapabilitiesToModel(
    payload?: number
): Location.Capability[] {
    if (payload === undefined) {
        return []
    }

    const capabilities: Location.Capability[] = []

    for (const key in Location.Capability) {
        const value = Location.Capability[key]
        if (
            typeof value === 'number' &&
            value !== 0 &&
            (payload & value) === value
        ) {
            capabilities.push(value)
        }
    }

    return capabilities
}
