import { CardReader } from '../models/CardReader'

export function readerImage(cardReader: CardReader) {
    switch (cardReader.type) {
        case 'bbpos_wisepad3':
            return '/stripe-reader-bbpos-wisepad3.png'
        case 'stripe_m2':
            return '/stripe-reader-m2.png'
        case 'bbpos_chipper2x':
            return '/stripe-reader-chipper2x.png'
        case 'bbpos_wisepos_e':
            return '/stripe-reader-bbpos-wisepos-e.png'
        case 'verifone_P400':
            return '/stripe-reader-verifone-p400.png'
    }
}

export function readerColor(cardReader: CardReader) {
    switch (cardReader.status) {
        case 'online':
            return 'green.400'
        case 'offline':
            return 'red.500'
        case 'unknown':
            return 'label3'
    }
}
