import { VStack } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useWindowSize } from 'react-use'

interface ReponsiveComponentProps {
    mobile: ReactNode
    tablet: ReactNode
    desktop: ReactNode
}

export function useScreenBreakpoint() {
    const { width } = useWindowSize()

    if (width <= 576) {
        return 'mobile'
    } else if (width < 992) {
        return 'tablet'
    } else {
        return 'desktop'
    }
}

export default function ReponsiveComponent(props: ReponsiveComponentProps) {
    const screen = useScreenBreakpoint()

    if (screen === 'mobile') {
        return (
            <VStack w="full" flex={1} justify="center">
                {props.mobile}
            </VStack>
        )
    }

    if (screen === 'tablet') {
        return (
            <VStack w="full" flex={1} justify="center">
                {props.tablet}
            </VStack>
        )
    }

    if (screen === 'desktop') {
        return (
            <VStack w="full" flex={1} justify="center">
                {props.desktop}
            </VStack>
        )
    }

    return null
}
