import { AuthDependencies } from 'features/auth/dependencies'
import { AuthStoreLive } from 'features/auth/dependencies/AuthStore'
import {
    ChangePasswordActionFake,
    ChangePasswordActionLive,
} from 'features/auth/dependencies/ChangePasswordAction'
import {
    ConfirmAccountActionFake,
    ConfirmAccountActionLive,
} from 'features/auth/dependencies/ConfirmAccountAction'
import {
    ConfirmForgotPasswordActionFake,
    ConfirmForgotPasswordActionLive,
} from 'features/auth/dependencies/ConfirmForgotPasswordAction'
import {
    ForgotPasswordActionFake,
    ForgotPasswordActionLive,
} from 'features/auth/dependencies/ForgotPasswordAction'
import {
    RefreshTokenActionFake,
    RefreshTokenActionLive,
} from 'features/auth/dependencies/RefreshTokenAction'
import {
    ResendConfirmationCodeActionFake,
    ResendConfirmationCodeActionLive,
} from 'features/auth/dependencies/ResendConfirmationCodeAction'
import {
    RetrieveUserActionFake,
    RetrieveUserActionLive,
} from 'features/auth/dependencies/RetrieveUserAction'
import {
    SignInActionFake,
    SignInActionLive,
} from 'features/auth/dependencies/SignInAction'
import {
    SignOutActionFake,
    SignOutActionLive,
} from 'features/auth/dependencies/SignOutAction'
import {
    SignUpActionFake,
    SignUpActionLive,
} from 'features/auth/dependencies/SignUpAction'
import {
    SocialSignInActionFake,
    SocialSignInActionLive,
} from 'features/auth/dependencies/SocialSignInAction'
import {
    UpdateUserAttributesActionFake,
    UpdateUserAttributesActionLive,
} from 'features/auth/dependencies/UpdateUserAttributesAction'
import { CardReaderDependencies } from 'features/cardreader/dependencies'
import {
    AttachCardReaderActionFake,
    AttachCardReaderActionLive,
} from 'features/cardreader/dependencies/AttachCardReaderAction'
import {
    InitCardReaderActionFake,
    InitCardReaderActionLive,
} from 'features/cardreader/dependencies/InitCardReaderAction'
import { ConfigurationDependencies } from 'features/config/dependencies'
import { ConfigurationStoreLive } from 'features/config/dependencies/ConfigurationStore'
import {
    RetrieveConfigurationActionFake,
    RetrieveConfigurationActionLive,
} from 'features/config/dependencies/RetrieveConfigurationAction'
import { EventDependencies } from 'features/event/dependencies'
import {
    EventClientFake,
    EventClientLive,
} from 'features/event/dependencies/EventClient'
import { PayoutDependencies } from 'features/finance/dependencies/payout'
import {
    ListPayoutActionFake,
    ListPayoutActionLive,
} from 'features/finance/dependencies/payout/ListPayoutAction'
import {
    RetrievePreferencesPayoutActionFake,
    RetrievePreferencesPayoutActionLive,
} from 'features/finance/dependencies/payout/RetrievePreferencesPayoutAction'
import {
    UpdatePreferencesPayoutActionFake,
    UpdatePreferencesPayoutActionLive,
} from 'features/finance/dependencies/payout/UpdatePreferencesPayoutAction'
import { PricingPlanDependencies } from 'features/finance/dependencies/pricingplan'
import { ListPricingPlanActionLive } from 'features/finance/dependencies/pricingplan/ListPricingPlanAction'
import { ReceiptDependencies } from 'features/finance/dependencies/receipt'
import {
    ListReceiptActionFake,
    ListReceiptActionLive,
} from 'features/finance/dependencies/receipt/ListReceiptAction'
import {
    RetrieveReceiptActionFake,
    RetrieveReceiptActionLive,
} from 'features/finance/dependencies/receipt/RetrieveReceiptAction'
import { LocationDependencies } from 'features/location/dependencies'
import {
    CreateLocationActionFake,
    CreateLocationActionLive,
} from 'features/location/dependencies/CreateLocationAction'
import {
    GeodecodeAddressLocationActionFake,
    GeodecodeAddressLocationActionLive,
} from 'features/location/dependencies/GeodecodeAddressAction'
import {
    ListLocationActionFake,
    ListLocationActionLive,
} from 'features/location/dependencies/ListLocationAction'
import {
    PublishLocationActionFake,
    PublishLocationActionLive,
} from 'features/location/dependencies/PublishLocationAction'
import {
    RetrieveLocationActionFake,
    RetrieveLocationActionLive,
} from 'features/location/dependencies/RetrieveLocationAction'
import {
    UpdateLocationActionFake,
    UpdateLocationActionLive,
} from 'features/location/dependencies/UpdateLocationAction'
import {
    UploadMediaLocationActionFake,
    UploadMediaLocationActionLive,
} from 'features/location/dependencies/UploadMediaLocationAction'
import { MenuEntryDependencies } from 'features/menu/dependencies/entry'
import {
    BatchUpdateMenuEntryActionFake,
    BatchUpdateMenuEntryActionLive,
} from 'features/menu/dependencies/entry/BatchUpdateMenuEntryAction'
import {
    CreateMenuEntryActionFake,
    CreateMenuEntryActionLive,
} from 'features/menu/dependencies/entry/CreateMenuEntryAction'
import {
    UpdateMenuEntryActionFake,
    UpdateMenuEntryActionLive,
} from 'features/menu/dependencies/entry/UpdateMenuEntryAction'
import {
    UploadMediaMenuEntryActionFake,
    UploadMediaMenuEntryActionLive,
} from 'features/menu/dependencies/entry/UploadMediaMenuEntryAction'
import { MenuExtraDependencies } from 'features/menu/dependencies/extra'
import {
    CreateMenuExtraActionFake,
    CreateMenuExtraActionLive,
} from 'features/menu/dependencies/extra/CreateMenuExtraAction'
import {
    DeleteMenuExtraActionFake,
    DeleteMenuExtraActionLive,
} from 'features/menu/dependencies/extra/DeleteMenuExtraAction'
import {
    UpdateMenuExtraActionFake,
    UpdateMenuExtraActionLive,
} from 'features/menu/dependencies/extra/UpdateMenuExtraAction'
import { MenuDependencies } from 'features/menu/dependencies/menu'
import {
    CreateMenuActionFake,
    CreateMenuActionLive,
} from 'features/menu/dependencies/menu/CreateMenuAction'
import {
    ListMenuActionFake,
    ListMenuActionLive,
} from 'features/menu/dependencies/menu/ListMenuAction'
import { MenuStoreLive } from 'features/menu/dependencies/menu/MenuStore'
import {
    RetrieveMenuActionFake,
    RetrieveMenuActionLive,
} from 'features/menu/dependencies/menu/RetrieveMenuAction'
import {
    UpdateMenuActionFake,
    UpdateMenuActionLive,
} from 'features/menu/dependencies/menu/UpdateMenuAction'
import {
    UploadMediaMenuActionFake,
    UploadMediaMenuActionLive,
} from 'features/menu/dependencies/menu/UploadMediaMenuAction'
import { MenuSectionDependencies } from 'features/menu/dependencies/section'
import {
    BatchCreateMenuSectionActionFake,
    BatchCreateMenuSectionActionLive,
} from 'features/menu/dependencies/section/BatchCreateMenuSectionAction'
import {
    CreateMenuSectionActionFake,
    CreateMenuSectionActionLive,
} from 'features/menu/dependencies/section/CreateMenuSectionAction'
import {
    UpdateMenuSectionActionFake,
    UpdateMenuSectionActionLive,
} from 'features/menu/dependencies/section/UpdateMenuSectionAction'
import {
    UploadMediaMenuSectionActionFake,
    UploadMediaMenuSectionActionLive,
} from 'features/menu/dependencies/section/UploadMediaMenuSectionAction'
import { MenuTranslationDependencies } from 'features/menu/dependencies/translation'
import {
    BuyTranslationBundleActionFake,
    BuyTranslationBundleActionLive,
} from 'features/menu/dependencies/translation/BuyTranslationBundleAction'
import {
    CreateTranslatedMenuActionFail,
    CreateTranslatedMenuActionLive,
} from 'features/menu/dependencies/translation/CreateTranslatedMenuAction'
import {
    ListTranslationBundlesActionFake,
    ListTranslationBundlesActionLive,
} from 'features/menu/dependencies/translation/ListTranslationBundlesAction'
import { ListTranslationObjectActionLive } from 'features/menu/dependencies/translation/ListTranslationObjectAction'
import {
    UpdateTranslatedMenuVisibilityActionFail,
    UpdateTranslatedMenuVisibilityActionLive,
} from 'features/menu/dependencies/translation/UpdateTranslatedMenuVisibilityAction'
import { UpdateTranslationObjectActionLive } from 'features/menu/dependencies/translation/UpdateTranslationObjectAction'
import { MenuAssignmentDependencies } from 'features/menuassignment/dependencies'
import {
    CreateMenuAssignmentActionFake,
    CreateMenuAssignmentActionLive,
} from 'features/menuassignment/dependencies/CreateMenuAssignmentAction'
import {
    DeleteMenuAssignmentActionFake,
    DeleteMenuAssignmentActionLive,
} from 'features/menuassignment/dependencies/DeleteMenuAssignmentAction'
import {
    ListMenuAssignmentActionFake,
    ListMenuAssignmentActionLive,
} from 'features/menuassignment/dependencies/ListMenuAssignmentAction'
import {
    UpdateMenuAssignmentActionFake,
    UpdateMenuAssignmentActionLive,
} from 'features/menuassignment/dependencies/UpdateMenuAssignmentAction'
import { OrganizationDependencies } from 'features/organization/dependencies'
import {
    CreateOrganizationActionFake,
    CreateOrganizationActionLive,
} from 'features/organization/dependencies/CreateOrganizationAction'
import {
    RetrieveOrganizationActionFake,
    RetrieveOrganizationActionLive,
} from 'features/organization/dependencies/RetrieveOrganizationAction'
import {
    VerifyOrganizationActionFake,
    VerifyOrganizationActionLive,
} from 'features/organization/dependencies/VerifyOrganizationAction'
import { RoomDependencies } from 'features/room/dependencies'
import {
    CreateRoomActionFake,
    CreateRoomActionLive,
} from 'features/room/dependencies/CreateRoomAction'
import {
    CreateTableRoomActionFake,
    CreateTableRoomActionLive,
} from 'features/room/dependencies/CreateTableRoomAction'
import {
    DeleteTableRoomActionFake,
    DeleteTableRoomActionLive,
} from 'features/room/dependencies/DeleteTableRoomAction'
import {
    ListRoomActionFake,
    ListRoomActionLive,
} from 'features/room/dependencies/ListRoomAction'
import {
    UpdateRoomActionFake,
    UpdateRoomActionLive,
} from 'features/room/dependencies/UpdateRoomAction'
import {
    UpdateTableRoomActionFake,
    UpdateTableRoomActionLive,
} from 'features/room/dependencies/UpdateTableRoomAction'
import { TerminalDependencies } from 'features/terminal/dependencies'
import {
    CreateTerminalActionFake,
    CreateTerminalActionLive,
} from 'features/terminal/dependencies/CreateTerminalAction'
import {
    ListTerminalActionFake,
    ListTerminalActionLive,
} from 'features/terminal/dependencies/ListTerminalAction'
import {
    ProvisionTerminalActionFake,
    ProvisionTerminalActionLive,
} from 'features/terminal/dependencies/ProvisionTerminalAction'
import { Container } from 'inversify'
import AvokadoClient, {
    AvokadoClientUsage,
    avokadoClientFactory,
} from './factories/AvokadoClient'
import { DateDependencies, liveDateGenerator } from './factories/Date'

// ****************************************************************
// **                           LIVE                             **
// ****************************************************************

const liveContainer = new Container()

// =============== Common ===============
liveContainer
    .bind(DateDependencies.Generator)
    .toConstantValue(liveDateGenerator)

// =============== Configuration ===============
liveContainer
    .bind(ConfigurationDependencies.Retrieve)
    .to(RetrieveConfigurationActionLive)
    .inSingletonScope()
liveContainer
    .bind(ConfigurationDependencies.Store)
    .to(ConfigurationStoreLive)
    .inSingletonScope()

// =============== Event ===============
liveContainer
    .bind(EventDependencies.Client)
    .to(EventClientLive)
    .inSingletonScope()

// =============== Auth ===============
liveContainer.bind(AuthDependencies.Store).to(AuthStoreLive).inSingletonScope()
liveContainer
    .bind(AuthDependencies.SignIn)
    .to(SignInActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.SignOut)
    .to(SignOutActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.SignUp)
    .to(SignUpActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.SocialSignIn)
    .to(SocialSignInActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.RefreshToken)
    .to(RefreshTokenActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.ConfirmAccount)
    .to(ConfirmAccountActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.ResendConfirmationCode)
    .to(ResendConfirmationCodeActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.ChangePassword)
    .to(ChangePasswordActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.ForgotPassword)
    .to(ForgotPasswordActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.ConfirmForgotPassword)
    .to(ConfirmForgotPasswordActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.UpdateUserAttributes)
    .to(UpdateUserAttributesActionLive)
    .inSingletonScope()
liveContainer
    .bind(AuthDependencies.RetrieveUser)
    .to(RetrieveUserActionLive)
    .inSingletonScope()

// =============== Organization ===============
liveContainer
    .bind(OrganizationDependencies.Retrieve)
    .to(RetrieveOrganizationActionLive)
    .inSingletonScope()
liveContainer
    .bind(OrganizationDependencies.Create)
    .to(CreateOrganizationActionLive)
    .inSingletonScope()
liveContainer
    .bind(OrganizationDependencies.Verify)
    .to(VerifyOrganizationActionLive)
    .inSingletonScope()

// =============== Location ===============
liveContainer
    .bind(LocationDependencies.List)
    .to(ListLocationActionLive)
    .inSingletonScope()
liveContainer
    .bind(LocationDependencies.Retrieve)
    .to(RetrieveLocationActionLive)
    .inSingletonScope()
liveContainer
    .bind(LocationDependencies.Create)
    .to(CreateLocationActionLive)
    .inSingletonScope()
liveContainer
    .bind(LocationDependencies.Update)
    .to(UpdateLocationActionLive)
    .inSingletonScope()
liveContainer
    .bind(LocationDependencies.GeodecodeAddress)
    .to(GeodecodeAddressLocationActionLive)
    .inSingletonScope()
liveContainer
    .bind(LocationDependencies.Publish)
    .to(PublishLocationActionLive)
    .inSingletonScope()
liveContainer
    .bind(LocationDependencies.UploadMedia)
    .to(UploadMediaLocationActionLive)
    .inSingletonScope()

// =============== Room ===============
liveContainer
    .bind(RoomDependencies.List)
    .to(ListRoomActionLive)
    .inSingletonScope()
liveContainer
    .bind(RoomDependencies.Create)
    .to(CreateRoomActionLive)
    .inSingletonScope()
liveContainer
    .bind(RoomDependencies.Update)
    .to(UpdateRoomActionLive)
    .inSingletonScope()
liveContainer
    .bind(RoomDependencies.CreateTable)
    .to(CreateTableRoomActionLive)
    .inSingletonScope()
liveContainer
    .bind(RoomDependencies.UpdateTable)
    .to(UpdateTableRoomActionLive)
    .inSingletonScope()
liveContainer
    .bind(RoomDependencies.DeleteTable)
    .to(DeleteTableRoomActionLive)
    .inSingletonScope()

// =============== Terminal ===============
liveContainer
    .bind(TerminalDependencies.List)
    .to(ListTerminalActionLive)
    .inSingletonScope()
liveContainer
    .bind(TerminalDependencies.Create)
    .to(CreateTerminalActionLive)
    .inSingletonScope()
liveContainer
    .bind(TerminalDependencies.Provision)
    .to(ProvisionTerminalActionLive)
    .inSingletonScope()

// =============== Card Reader ===============
liveContainer
    .bind(CardReaderDependencies.Init)
    .to(InitCardReaderActionLive)
    .inSingletonScope()
liveContainer
    .bind(CardReaderDependencies.Attach)
    .to(AttachCardReaderActionLive)
    .inSingletonScope()

// =============== Menu Assignment ===============
liveContainer
    .bind(MenuAssignmentDependencies.List)
    .to(ListMenuAssignmentActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuAssignmentDependencies.Create)
    .to(CreateMenuAssignmentActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuAssignmentDependencies.Update)
    .to(UpdateMenuAssignmentActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuAssignmentDependencies.Delete)
    .to(DeleteMenuAssignmentActionLive)
    .inSingletonScope()

// =============== Menu ===============
liveContainer.bind(MenuDependencies.Store).to(MenuStoreLive).inSingletonScope()
liveContainer
    .bind(MenuDependencies.List)
    .to(ListMenuActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuDependencies.Retrieve)
    .to(RetrieveMenuActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuDependencies.Create)
    .to(CreateMenuActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuDependencies.Update)
    .to(UpdateMenuActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuDependencies.UploadMedia)
    .to(UploadMediaMenuActionLive)
    .inSingletonScope()

// =============== Menu Translation ===============
liveContainer
    .bind(MenuTranslationDependencies.Create)
    .to(CreateTranslatedMenuActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuTranslationDependencies.ListObjects)
    .to(ListTranslationObjectActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuTranslationDependencies.UpdateObject)
    .to(UpdateTranslationObjectActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuTranslationDependencies.UpdateTranslatedMenuVisibility)
    .to(UpdateTranslatedMenuVisibilityActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuTranslationDependencies.ListTranslationBundles)
    .to(ListTranslationBundlesActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuTranslationDependencies.BuyTranslationBundle)
    .to(BuyTranslationBundleActionLive)
    .inSingletonScope()

// =============== Menu Section ===============
liveContainer
    .bind(MenuSectionDependencies.Create)
    .to(CreateMenuSectionActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuSectionDependencies.BatchCreate)
    .to(BatchCreateMenuSectionActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuSectionDependencies.Update)
    .to(UpdateMenuSectionActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuSectionDependencies.UploadMedia)
    .to(UploadMediaMenuSectionActionLive)
    .inSingletonScope()

// =============== Menu Entry ===============
liveContainer
    .bind(MenuEntryDependencies.Create)
    .to(CreateMenuEntryActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuEntryDependencies.Update)
    .to(UpdateMenuEntryActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuEntryDependencies.BatchUpdate)
    .to(BatchUpdateMenuEntryActionLive)
    .inSingletonScope()
liveContainer
    .bind(MenuEntryDependencies.UploadMedia)
    .to(UploadMediaMenuEntryActionLive)
    .inSingletonScope()

// =============== Menu Extra ===============
liveContainer
    .bind(MenuExtraDependencies.Create)
    .to(CreateMenuExtraActionLive)
    .inSingletonScope()

liveContainer
    .bind(MenuExtraDependencies.Update)
    .to(UpdateMenuExtraActionLive)
    .inSingletonScope()

liveContainer
    .bind(MenuExtraDependencies.Delete)
    .to(DeleteMenuExtraActionLive)
    .inSingletonScope()

// =============== Receipt ===============
liveContainer
    .bind(ReceiptDependencies.List)
    .to(ListReceiptActionLive)
    .inSingletonScope()
liveContainer
    .bind(ReceiptDependencies.Retrieve)
    .to(RetrieveReceiptActionLive)
    .inSingletonScope()

// =============== Payout ===============
liveContainer
    .bind(PayoutDependencies.List)
    .to(ListPayoutActionLive)
    .inSingletonScope()
liveContainer
    .bind(PayoutDependencies.RetrievePreferences)
    .to(RetrievePreferencesPayoutActionLive)
    .inSingletonScope()
liveContainer
    .bind(PayoutDependencies.UpdatePreferences)
    .to(UpdatePreferencesPayoutActionLive)
    .inSingletonScope()

// =============== Pricing Plan ===============
liveContainer
    .bind(PricingPlanDependencies.List)
    .to(ListPricingPlanActionLive)
    .inSingletonScope()

// =============== API Client ===============
liveContainer
    .bind(AvokadoClient.Private)
    .toDynamicValue((context) =>
        avokadoClientFactory(context, AvokadoClientUsage.Private)
    )
    .inSingletonScope()
liveContainer
    .bind(AvokadoClient.Public)
    .toDynamicValue((context) =>
        avokadoClientFactory(context, AvokadoClientUsage.Public)
    )
    .inSingletonScope()

// ****************************************************************
// **                            DEV                             **
// ****************************************************************

const devContainer = new Container()

// =============== Common ===============
devContainer.bind(DateDependencies.Generator).toConstantValue(liveDateGenerator)

// =============== Configuration ===============
devContainer
    .bind(ConfigurationDependencies.Retrieve)
    .to(RetrieveConfigurationActionLive)
    .inSingletonScope()
devContainer
    .bind(ConfigurationDependencies.Store)
    .to(ConfigurationStoreLive)
    .inSingletonScope()

// =============== Event ===============
devContainer
    .bind(EventDependencies.Client)
    .to(EventClientLive)
    .inSingletonScope()

// =============== Auth ===============
devContainer.bind(AuthDependencies.Store).to(AuthStoreLive).inSingletonScope()
devContainer
    .bind(AuthDependencies.SignIn)
    .to(SignInActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.SignOut)
    .to(SignOutActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.SignUp)
    .to(SignUpActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.SocialSignIn)
    .to(SocialSignInActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.RefreshToken)
    .to(RefreshTokenActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.ConfirmAccount)
    .to(ConfirmAccountActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.ResendConfirmationCode)
    .to(ResendConfirmationCodeActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.ChangePassword)
    .to(ChangePasswordActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.ForgotPassword)
    .to(ForgotPasswordActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.ConfirmForgotPassword)
    .to(ConfirmForgotPasswordActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.UpdateUserAttributes)
    .to(UpdateUserAttributesActionLive)
    .inSingletonScope()
devContainer
    .bind(AuthDependencies.RetrieveUser)
    .to(RetrieveUserActionLive)
    .inSingletonScope()

// =============== Organization ===============
devContainer
    .bind(OrganizationDependencies.Retrieve)
    .to(RetrieveOrganizationActionLive)
    .inSingletonScope()
devContainer
    .bind(OrganizationDependencies.Create)
    .to(CreateOrganizationActionLive)
    .inSingletonScope()
devContainer
    .bind(OrganizationDependencies.Verify)
    .to(VerifyOrganizationActionLive)
    .inSingletonScope()

// =============== Location ===============
devContainer
    .bind(LocationDependencies.List)
    .to(ListLocationActionLive)
    .inSingletonScope()
devContainer
    .bind(LocationDependencies.Retrieve)
    .to(RetrieveLocationActionLive)
    .inSingletonScope()
devContainer
    .bind(LocationDependencies.Create)
    .to(CreateLocationActionLive)
    .inSingletonScope()
devContainer
    .bind(LocationDependencies.Update)
    .to(UpdateLocationActionLive)
    .inSingletonScope()
devContainer
    .bind(LocationDependencies.GeodecodeAddress)
    .to(GeodecodeAddressLocationActionLive)
    .inSingletonScope()
devContainer
    .bind(LocationDependencies.Publish)
    .to(PublishLocationActionLive)
    .inSingletonScope()
devContainer
    .bind(LocationDependencies.UploadMedia)
    .to(UploadMediaLocationActionLive)
    .inSingletonScope()

// =============== Room ===============
devContainer
    .bind(RoomDependencies.List)
    .to(ListRoomActionLive)
    .inSingletonScope()
devContainer
    .bind(RoomDependencies.Create)
    .to(CreateRoomActionLive)
    .inSingletonScope()
devContainer
    .bind(RoomDependencies.Update)
    .to(UpdateRoomActionLive)
    .inSingletonScope()
devContainer
    .bind(RoomDependencies.CreateTable)
    .to(CreateTableRoomActionLive)
    .inSingletonScope()
devContainer
    .bind(RoomDependencies.UpdateTable)
    .to(UpdateTableRoomActionLive)
    .inSingletonScope()
devContainer
    .bind(RoomDependencies.DeleteTable)
    .to(DeleteTableRoomActionLive)
    .inSingletonScope()

// =============== Terminal ===============
devContainer
    .bind(TerminalDependencies.List)
    .to(ListTerminalActionLive)
    .inSingletonScope()
devContainer
    .bind(TerminalDependencies.Create)
    .to(CreateTerminalActionLive)
    .inSingletonScope()
devContainer
    .bind(TerminalDependencies.Provision)
    .to(ProvisionTerminalActionLive)
    .inSingletonScope()

// =============== Card Reader ===============
devContainer
    .bind(CardReaderDependencies.Init)
    .to(InitCardReaderActionLive)
    .inSingletonScope()
devContainer
    .bind(CardReaderDependencies.Attach)
    .to(AttachCardReaderActionLive)
    .inSingletonScope()

// =============== Menu Assignment ===============
devContainer
    .bind(MenuAssignmentDependencies.List)
    .to(ListMenuAssignmentActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuAssignmentDependencies.Create)
    .to(CreateMenuAssignmentActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuAssignmentDependencies.Update)
    .to(UpdateMenuAssignmentActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuAssignmentDependencies.Delete)
    .to(DeleteMenuAssignmentActionLive)
    .inSingletonScope()

// =============== Menu ===============
devContainer.bind(MenuDependencies.Store).to(MenuStoreLive).inSingletonScope()
devContainer
    .bind(MenuDependencies.List)
    .to(ListMenuActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuDependencies.Retrieve)
    .to(RetrieveMenuActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuDependencies.Create)
    .to(CreateMenuActionFake)
    .inSingletonScope()
devContainer
    .bind(MenuDependencies.Update)
    .to(UpdateMenuActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuDependencies.UploadMedia)
    .to(UploadMediaMenuActionLive)
    .inSingletonScope()

// =============== Menu Translation ===============
devContainer
    .bind(MenuTranslationDependencies.Create)
    .to(CreateTranslatedMenuActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuTranslationDependencies.ListObjects)
    .to(ListTranslationObjectActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuTranslationDependencies.UpdateObject)
    .to(UpdateTranslationObjectActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuTranslationDependencies.UpdateTranslatedMenuVisibility)
    .to(UpdateTranslatedMenuVisibilityActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuTranslationDependencies.ListTranslationBundles)
    .to(ListTranslationBundlesActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuTranslationDependencies.BuyTranslationBundle)
    .to(BuyTranslationBundleActionLive)
    .inSingletonScope()

// =============== Menu Section ===============
devContainer
    .bind(MenuSectionDependencies.Create)
    .to(CreateMenuSectionActionFake)
    .inSingletonScope()
devContainer
    .bind(MenuSectionDependencies.BatchCreate)
    .to(BatchCreateMenuSectionActionFake)
    .inSingletonScope()
devContainer
    .bind(MenuSectionDependencies.Update)
    .to(UpdateMenuSectionActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuSectionDependencies.UploadMedia)
    .to(UploadMediaMenuSectionActionLive)
    .inSingletonScope()

// =============== Menu Entry ===============
devContainer
    .bind(MenuEntryDependencies.Create)
    .to(CreateMenuEntryActionFake)
    .inSingletonScope()
devContainer
    .bind(MenuEntryDependencies.Update)
    .to(UpdateMenuEntryActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuEntryDependencies.BatchUpdate)
    .to(BatchUpdateMenuEntryActionLive)
    .inSingletonScope()
devContainer
    .bind(MenuEntryDependencies.UploadMedia)
    .to(UploadMediaMenuEntryActionLive)
    .inSingletonScope()

// =============== Menu Extra ===============
devContainer
    .bind(MenuExtraDependencies.Create)
    .to(CreateMenuExtraActionLive)
    .inSingletonScope()

devContainer
    .bind(MenuExtraDependencies.Update)
    .to(UpdateMenuExtraActionLive)
    .inSingletonScope()

devContainer
    .bind(MenuExtraDependencies.Delete)
    .to(DeleteMenuExtraActionLive)
    .inSingletonScope()

// =============== Receipt ===============
devContainer
    .bind(ReceiptDependencies.List)
    .to(ListReceiptActionLive)
    .inSingletonScope()
devContainer
    .bind(ReceiptDependencies.Retrieve)
    .to(RetrieveReceiptActionLive)
    .inSingletonScope()

// =============== Payout ===============
devContainer
    .bind(PayoutDependencies.List)
    .to(ListPayoutActionFake)
    .inSingletonScope()
devContainer
    .bind(PayoutDependencies.RetrievePreferences)
    .to(RetrievePreferencesPayoutActionLive)
    .inSingletonScope()
devContainer
    .bind(PayoutDependencies.UpdatePreferences)
    .to(UpdatePreferencesPayoutActionLive)
    .inSingletonScope()

// =============== Pricing Plan ===============
devContainer
    .bind(PricingPlanDependencies.List)
    .to(ListPricingPlanActionLive)
    .inSingletonScope()

// =============== API Client ===============
devContainer
    .bind(AvokadoClient.Private)
    .toDynamicValue((context) =>
        avokadoClientFactory(context, AvokadoClientUsage.Private)
    )
    .inSingletonScope()
devContainer
    .bind(AvokadoClient.Public)
    .toDynamicValue((context) =>
        avokadoClientFactory(context, AvokadoClientUsage.Public)
    )
    .inSingletonScope()

// ****************************************************************
// **                          OFFLINE                           **
// ****************************************************************

const offlineContainer = new Container()

// =============== Common ===============
offlineContainer
    .bind(DateDependencies.Generator)
    .toConstantValue(liveDateGenerator)

// =============== Configuration ===============
offlineContainer
    .bind(ConfigurationDependencies.Retrieve)
    .to(RetrieveConfigurationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(ConfigurationDependencies.Store)
    .to(ConfigurationStoreLive)
    .inSingletonScope()

// =============== Event ===============
offlineContainer
    .bind(EventDependencies.Client)
    .to(EventClientFake)
    .inSingletonScope()

// =============== Auth ===============
offlineContainer
    .bind(AuthDependencies.Store)
    .to(AuthStoreLive)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.SignIn)
    .to(SignInActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.SignOut)
    .to(SignOutActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.SignUp)
    .to(SignUpActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.SocialSignIn)
    .to(SocialSignInActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.RefreshToken)
    .to(RefreshTokenActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.ConfirmAccount)
    .to(ConfirmAccountActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.ResendConfirmationCode)
    .to(ResendConfirmationCodeActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.ChangePassword)
    .to(ChangePasswordActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.ForgotPassword)
    .to(ForgotPasswordActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.ConfirmForgotPassword)
    .to(ConfirmForgotPasswordActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.UpdateUserAttributes)
    .to(UpdateUserAttributesActionFake)
    .inSingletonScope()
offlineContainer
    .bind(AuthDependencies.RetrieveUser)
    .to(RetrieveUserActionFake)
    .inSingletonScope()

// =============== Organization ===============
offlineContainer
    .bind(OrganizationDependencies.Retrieve)
    .to(RetrieveOrganizationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(OrganizationDependencies.Create)
    .to(CreateOrganizationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(OrganizationDependencies.Verify)
    .to(VerifyOrganizationActionFake)
    .inSingletonScope()

// =============== Location ===============
offlineContainer
    .bind(LocationDependencies.List)
    .to(ListLocationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(LocationDependencies.Retrieve)
    .to(RetrieveLocationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(LocationDependencies.Create)
    .to(CreateLocationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(LocationDependencies.Update)
    .to(UpdateLocationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(LocationDependencies.GeodecodeAddress)
    .to(GeodecodeAddressLocationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(LocationDependencies.Publish)
    .to(PublishLocationActionFake)
    .inSingletonScope()
offlineContainer
    .bind(LocationDependencies.UploadMedia)
    .to(UploadMediaLocationActionFake)
    .inSingletonScope()

// =============== Room ===============
offlineContainer
    .bind(RoomDependencies.List)
    .to(ListRoomActionFake)
    .inSingletonScope()
offlineContainer
    .bind(RoomDependencies.Create)
    .to(CreateRoomActionFake)
    .inSingletonScope()
offlineContainer
    .bind(RoomDependencies.Update)
    .to(UpdateRoomActionFake)
    .inSingletonScope()
offlineContainer
    .bind(RoomDependencies.CreateTable)
    .to(CreateTableRoomActionFake)
    .inSingletonScope()
offlineContainer
    .bind(RoomDependencies.UpdateTable)
    .to(UpdateTableRoomActionFake)
    .inSingletonScope()
offlineContainer
    .bind(RoomDependencies.DeleteTable)
    .to(DeleteTableRoomActionFake)
    .inSingletonScope()

// =============== Terminal ===============
offlineContainer
    .bind(TerminalDependencies.List)
    .to(ListTerminalActionFake)
    .inSingletonScope()
offlineContainer
    .bind(TerminalDependencies.Create)
    .to(CreateTerminalActionFake)
    .inSingletonScope()
offlineContainer
    .bind(TerminalDependencies.Provision)
    .to(ProvisionTerminalActionFake)
    .inSingletonScope()

// =============== Card Reader ===============
offlineContainer
    .bind(CardReaderDependencies.Init)
    .to(InitCardReaderActionFake)
    .inSingletonScope()
offlineContainer
    .bind(CardReaderDependencies.Attach)
    .to(AttachCardReaderActionFake)
    .inSingletonScope()

// =============== Menu Assignment ===============
offlineContainer
    .bind(MenuAssignmentDependencies.List)
    .to(ListMenuAssignmentActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuAssignmentDependencies.Create)
    .to(CreateMenuAssignmentActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuAssignmentDependencies.Update)
    .to(UpdateMenuAssignmentActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuAssignmentDependencies.Delete)
    .to(DeleteMenuAssignmentActionFake)
    .inSingletonScope()

// =============== Menu ===============
offlineContainer
    .bind(MenuDependencies.Store)
    .to(MenuStoreLive)
    .inSingletonScope()
offlineContainer
    .bind(MenuDependencies.List)
    .to(ListMenuActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuDependencies.Retrieve)
    .to(RetrieveMenuActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuDependencies.Create)
    .to(CreateMenuActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuDependencies.Update)
    .to(UpdateMenuActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuDependencies.UploadMedia)
    .to(UploadMediaMenuActionFake)
    .inSingletonScope()

// =============== Menu Translation ===============
offlineContainer
    .bind(MenuTranslationDependencies.Create)
    .to(CreateTranslatedMenuActionFail)
    .inSingletonScope()
offlineContainer
    .bind(MenuTranslationDependencies.ListObjects)
    .to(ListTranslationObjectActionLive)
    .inSingletonScope()
offlineContainer
    .bind(MenuTranslationDependencies.UpdateObject)
    .to(UpdateTranslationObjectActionLive)
    .inSingletonScope()
offlineContainer
    .bind(MenuTranslationDependencies.UpdateTranslatedMenuVisibility)
    .to(UpdateTranslatedMenuVisibilityActionFail)
    .inSingletonScope()
offlineContainer
    .bind(MenuTranslationDependencies.ListTranslationBundles)
    .to(ListTranslationBundlesActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuTranslationDependencies.BuyTranslationBundle)
    .to(BuyTranslationBundleActionFake)
    .inSingletonScope()

// =============== Menu Section ===============
offlineContainer
    .bind(MenuSectionDependencies.Create)
    .to(CreateMenuSectionActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuSectionDependencies.BatchCreate)
    .to(BatchCreateMenuSectionActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuSectionDependencies.Update)
    .to(UpdateMenuSectionActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuSectionDependencies.UploadMedia)
    .to(UploadMediaMenuSectionActionFake)
    .inSingletonScope()

// =============== Menu Entry ===============
offlineContainer
    .bind(MenuEntryDependencies.Create)
    .to(CreateMenuEntryActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuEntryDependencies.Update)
    .to(UpdateMenuEntryActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuEntryDependencies.BatchUpdate)
    .to(BatchUpdateMenuEntryActionFake)
    .inSingletonScope()
offlineContainer
    .bind(MenuEntryDependencies.UploadMedia)
    .to(UploadMediaMenuEntryActionFake)
    .inSingletonScope()

// =============== Menu Extra ===============
offlineContainer
    .bind(MenuExtraDependencies.Create)
    .to(CreateMenuExtraActionFake)
    .inSingletonScope()

offlineContainer
    .bind(MenuExtraDependencies.Update)
    .to(UpdateMenuExtraActionFake)
    .inSingletonScope()

offlineContainer
    .bind(MenuExtraDependencies.Delete)
    .to(DeleteMenuExtraActionFake)
    .inSingletonScope()

// =============== Receipt ===============
offlineContainer
    .bind(ReceiptDependencies.List)
    .to(ListReceiptActionFake)
    .inSingletonScope()
offlineContainer
    .bind(ReceiptDependencies.Retrieve)
    .to(RetrieveReceiptActionFake)
    .inSingletonScope()

// =============== Payout ===============
offlineContainer
    .bind(PayoutDependencies.List)
    .to(ListPayoutActionFake)
    .inSingletonScope()
offlineContainer
    .bind(PayoutDependencies.RetrievePreferences)
    .to(RetrievePreferencesPayoutActionFake)
    .inSingletonScope()
offlineContainer
    .bind(PayoutDependencies.UpdatePreferences)
    .to(UpdatePreferencesPayoutActionFake)
    .inSingletonScope()

// =============== Pricing Plan ===============
offlineContainer
    .bind(PricingPlanDependencies.List)
    .to(ListPricingPlanActionLive)
    .inSingletonScope()

// =============== API Client ===============
offlineContainer
    .bind(AvokadoClient.Private)
    .toDynamicValue((context) =>
        avokadoClientFactory(context, AvokadoClientUsage.Private)
    )
    .inSingletonScope()
offlineContainer
    .bind(AvokadoClient.Public)
    .toDynamicValue((context) =>
        avokadoClientFactory(context, AvokadoClientUsage.Public)
    )
    .inSingletonScope()

export const dependencyContainer = liveContainer
