import {
    Avatar,
    Box,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
    useColorMode,
} from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { LanguageSelector } from 'localization/components/LanguageSelector'
import { useTranslation } from 'react-i18next'
import { FaExchangeAlt, FaMoon, FaSignOutAlt, FaSun } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MenuItemRadio } from 'uikit/menu/MenuItemRadio'
import { useFullName } from 'utils/fullname/useFullName'
import useAuthContext from '../contexts/AuthContext'

export function UserAvatar() {
    const { t } = useTranslation()

    const { user, signOut } = useAuthContext()
    const organizationManager = useOrganizationContext()

    const { colorMode, setColorMode } = useColorMode()
    const navigate = useNavigate()

    const fullName = useFullName(user?.givenName, user?.familyName)

    return (
        <Box>
            <Menu closeOnSelect={false}>
                <MenuButton>
                    <Avatar name={fullName} src={user!.avatar} size="sm" />
                </MenuButton>
                <MenuList>
                    <MenuGroup title={t('Appearance')}>
                        <MenuItemRadio
                            text={t('Dark theme')}
                            icon={<FaMoon />}
                            isSelected={colorMode === 'dark'}
                            onClick={() => setColorMode('dark')}
                        />
                        <MenuItemRadio
                            text={t('Light theme')}
                            icon={<FaSun />}
                            isSelected={colorMode === 'light'}
                            onClick={() => setColorMode('light')}
                        />
                    </MenuGroup>

                    <MenuDivider />

                    <LanguageSelector />

                    <MenuDivider />

                    <MenuGroup title={t('Account')}>
                        {organizationManager.organizations.length > 1 && (
                            <MenuItem
                                closeOnSelect
                                icon={<FaExchangeAlt />}
                                onClick={() => navigate('/organizations')}
                            >
                                {t('Change organization')}
                            </MenuItem>
                        )}
                        <MenuItem
                            closeOnSelect
                            icon={<FaSignOutAlt />}
                            onClick={signOut}
                        >
                            {t('Sign out')}
                        </MenuItem>
                    </MenuGroup>
                </MenuList>
            </Menu>
        </Box>
    )
}
