import {
    Button,
    ButtonGroup,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    UseDisclosureReturn,
    VStack,
    useSteps,
} from '@chakra-ui/react'
import {
    UseExtraFormDataHook,
    UseExtraOption,
    useExtraFormController,
    useExtraFormData,
} from 'features/menu/hooks/useExtraForm'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import { useEffect, useMemo, useState } from 'react'
import { NumberInput, PriceInput } from 'uikit/form/input'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { cleanUpString } from 'utils/types'

interface ExtraModalProps extends UseDisclosureReturn {
    menu: Menu
    section: Menu.Section
    entry: Menu.Section.Entry
    extra?: Menu.Extra
    onSave: (menu: Menu) => void
}

interface NewExtraStepProps {
    formData: UseExtraFormDataHook
}

interface NewExtraStepData {
    title: string
    subtitle: string
    canGoToNext?: (formData: UseExtraFormDataHook) => boolean
    content: (props: NewExtraStepProps) => JSX.Element
}

const steps: NewExtraStepData[] = [
    {
        title: 'extra_new_step_1_name_title',
        subtitle: 'extra_new_step_1_name_subtitle',
        canGoToNext: (formData) => {
            return cleanUpString(formData.name).length > 0
        },
        content: NewExtraStep1Name,
    },
    {
        title: 'extra_new_step_2_options_title',
        subtitle: 'extra_new_step_2_options_subtitle',
        canGoToNext: (formData) => {
            if (formData.options.length === 0) return false

            const names = formData.options.map((option) =>
                cleanUpString(option.name)
            )

            if (names.some((name) => name.length === 0)) return false
            if (names.some((name, index) => names.indexOf(name) !== index))
                return false

            return true
        },
        content: NewExtraStep2Options,
    },
    {
        title: 'extra_new_step_3_config_title',
        subtitle: 'extra_new_step_3_config_subtitle',
        content: NewExtraStep3Config,
    },
]

export function ExtrasModal({
    menu,
    section,
    entry,
    extra,
    isOpen,
    onClose,
    onSave,
}: ExtraModalProps) {
    const t = useT('menu')

    const formData = useExtraFormData(extra)
    const controller = useExtraFormController(
        menu,
        section,
        entry,
        formData,
        onSave
    )

    const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: steps.length,
    })

    const activeStepData = useMemo(() => steps[activeStep], [activeStep])

    useEffect(() => {
        if (!isOpen) {
            setActiveStep(0)
            formData.reset()
        }
    }, [isOpen])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnEsc={!controller.isProcessing}
            closeOnOverlayClick={!controller.isProcessing}
            isCentered
            size="4xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <VStack align="start">
                        <Heading>
                            {t(activeStepData.title, { name: formData.name })}
                        </Heading>
                        <Text>{t(activeStepData.subtitle)}</Text>
                    </VStack>
                </ModalHeader>
                <ModalCloseButton isDisabled={controller.isProcessing} />
                <ModalBody minH="md" maxH="md" overflowY="auto">
                    <activeStepData.content formData={formData} />
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup isDisabled={controller.isProcessing}>
                        {activeStep > 0 && (
                            <Button onClick={goToPrevious}>
                                {t('back_button')}
                            </Button>
                        )}
                        {activeStep === steps.length - 1 ? (
                            <Button
                                onClick={async () => {
                                    await controller.submit()
                                    onClose()
                                }}
                                isLoading={controller.isProcessing}
                                isDisabled={!controller.canSubmit}
                            >
                                {t('create_and_assign_button')}
                            </Button>
                        ) : (
                            <Button
                                onClick={goToNext}
                                isDisabled={
                                    activeStepData.canGoToNext === undefined
                                        ? true
                                        : !activeStepData.canGoToNext(formData)
                                }
                            >
                                {t('next_button')}
                            </Button>
                        )}
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

function NewExtraStep1Name({ formData }: NewExtraStepProps) {
    const t = useT('menu')

    return (
        <FormControl>
            <FormLabel>{t('extra_name_input_label')}</FormLabel>
            <Input
                placeholder={t('extra_name_input_placeholder')}
                value={formData.name}
                onChange={(e) => formData.setName(e.target.value)}
            />
            <FormHelperText>{t('extra_name_input_support')}</FormHelperText>
        </FormControl>
    )
}

interface NewExtraStep2Option extends NewExtraStepProps {
    option: UseExtraOption
    placeholder: string
    onChange: (data: Omit<Menu.Extra.Option, 'id'>) => void
    onRemove: () => void
    count: number
}

function NewExtraStep2Option({
    formData,
    option,
    placeholder,
    count,
    onChange,
    onRemove,
}: NewExtraStep2Option) {
    const t = useT('menu')

    const [isShowingPrice, setIsShowingPrice] = useState(false)

    return (
        <VStack w="full">
            <FormControl w="full">
                <FormLabel>
                    {t('extra_option_label', { optionCount: count })}
                </FormLabel>
                <Input
                    value={option.name}
                    onChange={(e) =>
                        onChange({
                            ...option,
                            name: e.target.value,
                        })
                    }
                    placeholder={placeholder}
                />
            </FormControl>

            {isShowingPrice && (
                <PriceInput
                    direction="vertical"
                    value={option.price}
                    setValue={(price) =>
                        onChange({
                            ...option,
                            price,
                        })
                    }
                />
            )}

            <ButtonGroup alignSelf="end" variant="link">
                {!isShowingPrice ? (
                    <Button onClick={() => setIsShowingPrice(true)} me={4}>
                        {t('extra_set_price_button')}
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            onChange({
                                ...option,
                                price: undefined,
                            })
                            setIsShowingPrice(false)
                        }}
                        me={4}
                    >
                        {t('extra_remove_price_button')}
                    </Button>
                )}
                <Button
                    isDisabled={formData.options.length === 1}
                    onClick={onRemove}
                >
                    {t('extra_remove_option_button')}
                </Button>
            </ButtonGroup>
        </VStack>
    )
}

function NewExtraStep2Options({ formData }: NewExtraStepProps) {
    const t = useT('menu')

    const placeholders = useMemo(
        () => [
            t('option_name_input_placeholder_1_baked_potato_wedges'),
            t('option_name_input_placeholder_2_roasted_vegetables'),
            t('option_name_input_placeholder_3_mediterranean_salad'),
        ],
        [t]
    )

    return (
        <VStack spacing={0} w="full" align="stretch">
            <VStack align="stretch">
                {formData.options.map((option, index) => (
                    <NewExtraStep2Option
                        key={option.key}
                        formData={formData}
                        option={option}
                        count={index + 1}
                        placeholder={
                            placeholders[index] ??
                            t('option_name_input_placeholder', {
                                count: index + 1,
                            })
                        }
                        onChange={(data) =>
                            formData.updateOptionAt(index, data)
                        }
                        onRemove={() => formData.removeOptionAt(index)}
                    />
                ))}
            </VStack>

            <Button mt={4} onClick={formData.addOption}>
                {t('extra_add_option_button')}
            </Button>
        </VStack>
    )
}

function NewExtraStep3Config({ formData }: NewExtraStepProps) {
    const t = useT('menu')
    const screen = useScreenBreakpoint()

    return (
        <Stack direction={screen === 'mobile' ? 'column' : 'row'}>
            <FormControl>
                <NumberInput
                    title={t('extra_min_label')}
                    value={formData.minOptionsSelectable}
                    setValue={formData.setMinOptionsSelectable}
                />
                <FormHelperText>{t('extra_min_helper')}</FormHelperText>
            </FormControl>
            <FormControl>
                <NumberInput
                    title={t('extra_max_label')}
                    value={formData.maxOptionsSelectable}
                    setValue={formData.setMaxOptionsSelectable}
                />
                <FormHelperText>{t('extra_max_helper')}</FormHelperText>
            </FormControl>
        </Stack>
    )
}
