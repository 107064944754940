import useT from 'localization/hooks/useT'
import ContainerInfoAlert, {
    ContainerInfoAlertProxyProps,
} from '../shared/ContainerInfoAlert'

export default function PolicyHelperAlert({
    isVisible,
    onClose,
}: ContainerInfoAlertProxyProps) {
    const t = useT('policy')

    return (
        <ContainerInfoAlert
            isVisible={isVisible}
            onClose={onClose}
            title={t('helper_alert_title')}
            description={t('helper_alert_description')}
        />
    )
}
