import { useInjection } from 'inversify-react'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Geometry } from 'utils/geometry/Geometry'
import { overlap } from 'utils/geometry/overlap'
import useTrigger from 'utils/hooks/useTrigger'
import { RoomDependencies } from '../../dependencies'
import { Room } from '../../models/Room'
import {
    CanvasState,
    getDefaultControllerShape,
    getDefaultControllerState,
} from './getDefaultControllerState'
import { getNextTableNumber } from './getNextTableLabel'
interface UseRoomControllerParams {
    room: Room
}

export interface UseRoomControllerHook {
    canvasState: CanvasState
    tables: Room.Table[]
    overlappingTables: Room.Table.ID[]
    room: Room
    labelInputValue: string
    resetTrigger: number
    createNewTable: (center: Geometry.Point) => Promise<void>
    selectTable: (table: Room.Table) => void
    deselectTable: () => void
    enableCreateMode: (shape: 'circle' | 'rectangle') => void
    updateNextTableLabel: (label: string) => void
    updateSelectedTableLabel: (label: string) => void
    saveNewLabel: () => Promise<void>
    deleteSelectedTable: () => Promise<void>

    updateTableGeometry: (
        tableId: number,
        newGeometry: Geometry.Shape
    ) => Promise<void>

    updateOverlappingTables: (currentId: number, shape: Geometry.Shape) => void
}

export function useRoomController({
    room,
}: UseRoomControllerParams): UseRoomControllerHook {
    const create = useInjection(RoomDependencies.CreateTable)
    const update = useInjection(RoomDependencies.UpdateTable)
    const deleteTable = useInjection(RoomDependencies.DeleteTable)

    const resetTrigger = useTrigger()

    const [tables, setTables] = useState(room.tables)
    const [overlappingTables, setOverlappingTables] = useState<Room.Table.ID[]>(
        []
    )

    const [canvasState, setCanvasState] = useState<CanvasState>(
        getDefaultControllerState(room)
    )

    const createNewTable = async (center: Geometry.Point) => {
        if (canvasState.stateType !== 'create') return
        const newTable: Room.Table = {
            id: {
                ...room.id,
                tableId: 0,
            },
            label: canvasState.nextLabel,
            shape: { ...canvasState.nextShape, center },
        }
        const originalTables = tables
        const candidateTables = [...tables, newTable]
        setTables(candidateTables)

        try {
            const createdTable = await create.run({
                label: newTable.label,
                organizationId: room.id.organizationId,
                locationId: room.id.locationId,
                roomId: room.id.roomId,
                shape: newTable.shape!,
            })

            const newCanvasState = _.clone(canvasState)
            let nextDesiredLabel = getNextTableNumber([newTable.label])
            if (
                candidateTables.map((t) => t.label).includes(nextDesiredLabel)
            ) {
                nextDesiredLabel = getNextTableNumber(
                    candidateTables.map((t) => t.label)
                )
            }
            newCanvasState.nextLabel = nextDesiredLabel
            setCanvasState(newCanvasState)

            const updatedTables = [...candidateTables]
            updatedTables[updatedTables.length - 1].id.tableId =
                createdTable.id.tableId
            setTables(updatedTables)
        } catch (e: unknown) {
            setTables(originalTables)
        }
    }

    const enableCreateMode = (shape: 'circle' | 'rectangle') => {
        if (canvasState.stateType === 'create') {
            if (canvasState.nextShape.type === shape) {
                return
            }

            const newCanvasState = _.clone(canvasState)
            if (canvasState.nextShape.type === 'circle') {
                newCanvasState.nextShape = {
                    type: 'rectangle',
                    center: { x: 0, y: 0 },
                    size: {
                        width: canvasState.nextShape.radius * 2,
                        height: canvasState.nextShape.radius * 2,
                    },
                }
            } else {
                newCanvasState.nextShape = {
                    type: 'circle',
                    center: { x: 0, y: 0 },
                    radius:
                        (canvasState.nextShape.size.width +
                            canvasState.nextShape.size.height) /
                        4,
                }
            }

            setCanvasState(newCanvasState)
        }
    }

    const updateNextTableLabel = (label: string) => {
        if (canvasState.stateType !== 'create') return
        const newCanvasState = _.clone(canvasState)
        newCanvasState.nextLabel = label
        setCanvasState(newCanvasState)
    }

    const updateSelectedTableLabel = (label: string) => {
        if (canvasState.stateType !== 'table-selected') return
        const newCanvasState = _.clone(canvasState)
        newCanvasState.table.label = label
        setCanvasState(newCanvasState)
    }

    const saveNewLabel = async () => {
        if (canvasState.stateType !== 'table-selected') return
        if (canvasState.table.label === canvasState.originalTableLabel) return

        try {
            await update.run({
                organizationId: room.id.organizationId,
                locationId: room.id.locationId,
                roomId: room.id.roomId,
                tableId: canvasState.table.id.tableId,
                label: canvasState.table.label,
            })

            const newCanvasState = _.clone(canvasState)
            newCanvasState.originalTableLabel = canvasState.table.label
            setCanvasState(newCanvasState)
        } catch (e: unknown) {
            updateSelectedTableLabel(canvasState.originalTableLabel)
        }
    }

    const deleteSelectedTable = async () => {
        if (canvasState.stateType !== 'table-selected') return
        try {
            await deleteTable.run({
                organizationId: room.id.organizationId,
                locationId: room.id.locationId,
                roomId: room.id.roomId,
                tableId: canvasState.table.id.tableId,
            })

            setTables(
                tables.filter(
                    (t) => t.id.tableId !== canvasState.table.id.tableId
                )
            )
            deselectTable()
        } catch (e: unknown) {}
    }

    const selectTable = (table: Room.Table) => {
        setCanvasState({
            stateType: 'table-selected',
            table: table,
            originalTableLabel: table.label,
        })
    }

    const deselectTable = () => {
        if (canvasState.stateType !== 'table-selected') return
        canvasState.table.label = canvasState.originalTableLabel
        setCanvasState({
            stateType: 'create',
            nextShape: canvasState.table.shape ?? getDefaultControllerShape(),
            nextLabel: getNextTableNumber(tables.map((t) => t.label)),
        })
    }

    const updateTableGeometry = async (
        tableId: number,
        newGeometry: Geometry.Shape
    ) => {
        try {
            await update.run({
                organizationId: room.id.organizationId,
                locationId: room.id.locationId,
                roomId: room.id.roomId,
                tableId,
                shape: newGeometry,
            })

            // no need to use the setState method because no recomposition is needed
            // and we actually want to preserve the reference if canvasState also points to
            // the same table
            tables.forEach((table) => {
                if (table.id.tableId === tableId) table.shape = newGeometry
            })

            // We trigger the recomposition to fix the text label size issue
            resetTrigger.fire()
        } catch (e: unknown) {
            resetTrigger.fire()
        }
    }

    const updateOverlappingTables = (
        currentId: number,
        shape: Geometry.Shape
    ) => {
        const currentOverlaps = tables
            .filter((other) => {
                if (other.id.tableId === currentId) return false
                if (other.shape === undefined) return false
                return overlap(shape, other.shape)
            })
            .map((other) => other.id)

        setOverlappingTables(currentOverlaps)
    }

    useEffect(() => {
        setOverlappingTables([])
    }, [resetTrigger.value])

    const labelInputValue =
        canvasState.stateType === 'create'
            ? canvasState.nextLabel
            : canvasState.table.label

    return {
        canvasState,
        tables,
        overlappingTables,
        room,
        labelInputValue,
        createNewTable,
        selectTable,
        deselectTable,
        enableCreateMode,
        updateNextTableLabel,
        updateSelectedTableLabel,
        saveNewLabel,
        deleteSelectedTable,
        updateTableGeometry,
        updateOverlappingTables,
        resetTrigger: resetTrigger.value,
    }
}
