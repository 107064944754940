import { Button, Text, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { Link } from 'react-router-dom'

export default function DefaultSignInPage() {
    const t = useT('auth')

    return (
        <VStack
            w="full"
            maxW="xs"
            spacing={6}
            align="start"
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <VStack w="full">
                <Button w="full" type="submit" h="48px" as={Link} to="/sign-up">
                    {t('generic_button_create_an_account')}
                </Button>

                <Text
                    alignSelf="start"
                    mt="48px"
                    fontSize="xl"
                    fontWeight="semibold"
                >
                    {t('main_already_have_an_account_label')}
                </Text>

                <Button
                    w="full"
                    type="submit"
                    h="48px"
                    variant="outline"
                    as={Link}
                    to="/sign-in"
                    border="1px solid var(--chakra-colors-gray-300)"
                >
                    {t('generic_button_sign_in')}
                </Button>
            </VStack>
        </VStack>
    )
}
