import {
    FormControl,
    FormHelperText,
    FormLabel,
    FormLabelProps,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    VStack,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiX } from 'react-icons/fi'

interface AutocompletePickerProps<T>
    extends Pick<InputProps, 'bg' | 'variant'> {
    inputRef?: React.Ref<HTMLInputElement>
    options: T[]
    placeholder?: string
    label?: string
    helperText?: string
    labelProps?: FormLabelProps
    onPick: (value?: T) => void
    optionMapper: (option: T) => string
    AvailableRenderer: (props: { option: T }) => JSX.Element
}

export function AutocompletePicker<T>({
    inputRef,
    options,
    placeholder,
    label,
    helperText,
    labelProps,
    onPick,
    optionMapper,
    AvailableRenderer,
    ...props
}: AutocompletePickerProps<T>) {
    const containerRef = useRef<HTMLDivElement>(null)

    const [query, setQuery] = useState('')
    const [showList, setShowList] = useState(false)

    const availableOptions: T[] = useMemo(() => {
        const lower = query.toLowerCase()
        return options.filter((option) =>
            optionMapper(option).toLowerCase().startsWith(lower)
        )
    }, [options, query]) // eslint-disable-line react-hooks/exhaustive-deps

    const onOptionPicked = useCallback(
        (value?: T) => {
            setShowList(false)
            onPick(value)
            setQuery('')
        },
        [onPick] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEffect(() => {
        function handleClick(event: MouseEvent) {
            const current = containerRef.current
            const target = event.target

            if (target === null || !(target instanceof HTMLElement)) {
                setShowList(false)
                return
            }

            let node: HTMLElement | null = target

            while (node !== null) {
                if (node === current) {
                    return
                }

                node = node.parentElement
            }

            setShowList(false)
        }

        document.body.addEventListener('mousedown', handleClick)

        return () => document.body.removeEventListener('mousedown', handleClick)
    }, [])

    return (
        <VStack w="full" position="relative" ref={containerRef}>
            <FormControl>
                {label && <FormLabel {...labelProps}>{label}</FormLabel>}
                <InputGroup>
                    <Input
                        {...props}
                        ref={inputRef}
                        value={query}
                        type="text"
                        placeholder={placeholder}
                        autoComplete="off"
                        transition="all .25s"
                        isDisabled={
                            availableOptions.length === 0 && query === ''
                        }
                        borderBottomRadius={
                            props.variant === 'flushed' ||
                            (showList && availableOptions.length > 0)
                                ? 'none'
                                : 'md'
                        }
                        onChange={(e) => setQuery(e.target.value)}
                        onFocus={() => setShowList(true)}
                    />
                    <InputRightElement>
                        <IconButton
                            icon={<FiX />}
                            aria-label="Cancel"
                            size="xs"
                            variant="ghost"
                            colorScheme="gray"
                            borderRadius="full"
                            isDisabled={
                                availableOptions.length === 0 && query === ''
                            }
                            onClick={() => onOptionPicked(undefined)}
                        />
                    </InputRightElement>
                </InputGroup>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>

            {showList && availableOptions.length > 0 && (
                <VStack
                    bg="sheetBackground1"
                    borderWidth={1}
                    borderColor="primary"
                    w="full"
                    top={label ? '68px' : 10}
                    borderBottomRadius="md"
                    position="absolute"
                    spacing={0}
                    overflow="scroll"
                    zIndex={1}
                    maxH="xs"
                >
                    {availableOptions.map((option) => (
                        <HStack
                            key={optionMapper(option)}
                            w="full"
                            py={1}
                            px={3}
                            _hover={{ bg: 'sheetBackground2' }}
                            cursor="pointer"
                            onClick={() => onOptionPicked(option)}
                        >
                            <AvailableRenderer option={option} />
                        </HStack>
                    ))}
                </VStack>
            )}
        </VStack>
    )
}
