import Avokado, { AvokadoAPIError, MenuUpdate } from '@avokadoapp/avokado-ts'
import { mapModelToSectionsIds } from 'features/menu/mappers/SDKRequestMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import _ from 'lodash'
import { sleep } from 'utils/asyncawait/sleep'
import takeIf from 'utils/takeif'

interface UpdateMenuParams {
    menu: Menu
    name?: string
    description?: string
    footer?: string
    sections?: Menu.Section[]
}

export interface UpdateMenuAction {
    run(params: UpdateMenuParams): Promise<Menu>
}

@injectable()
export class UpdateMenuActionLive implements UpdateMenuAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateMenuParams): Promise<Menu> {
        const { menu } = params
        const request: MenuUpdate.Request = {
            organizationId: menu.id.organizationId,
            menuId: menu.id.menuId,
            name: _.isEqual(params.name, menu.name) ? undefined : params.name,
            description: takeIf(
                params.description,
                (value) => !_.isEqual(value, menu.description)
            ),
            footer: takeIf(
                params.footer,
                (value) => !_.isEqual(value, menu.footer)
            ),
            sectionsIds: _.isEqual(params.sections, menu.sections)
                ? undefined
                : mapModelToSectionsIds(params.sections),
        }
        const response = await this.avokado.menu.update(request)
        return {
            ...menu,
            name: response.name,
            description: response.description,
            footer: response.footer,
            sections: response.sectionsIds.map(
                (id) =>
                    menu.sections?.find(
                        (section) => section.id.sectionId === id
                    )!
            ),
            availableTranslations: response.availableTranslations,
        }
    }
}

@injectable()
export class UpdateMenuActionFake implements UpdateMenuAction {
    async run(params: UpdateMenuParams): Promise<Menu> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateMenuActionFail implements UpdateMenuAction {
    async run(params: UpdateMenuParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
