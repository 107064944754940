import { TabPanel, TabPanels } from '@chakra-ui/react'
import GalleryTab from 'features/location/components/GalleryTab'
import { PolicyTab } from 'features/location/components/PolicyTab'
import { TimeScheduleTab } from 'features/location/components/TimeScheduleTab'
import { RoomTab } from 'features/room/components/RoomTab'
import { TerminalTab } from 'features/terminal/components/TerminalTab'
import { RefObject, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Scaffold from 'uikit/scaffold/Scaffold'
import { LoadingIndicator } from 'utils/loading'
import { CardReaderTab } from '../../cardreader/components/CardReaderTab'
import { MenuAssignmentTab } from '../../menuassignment/components/Temporary/MenuAssignmentTab'
import { GeneralTab } from '../components/GeneralTab'
import { PublishTab } from '../components/PublishTab'
import { StaffTab } from '../components/StaffTab'

import { useLocation } from '../hooks/useLocation'
import { Location } from '../models/Location'
import { PrinterTab } from '../components/PrinterTab'

export interface LocationTabProps {
    headerRef: RefObject<HTMLDivElement>
    location: Location
    update: (location: Location) => void
}

interface TabEntry {
    id: string
    name: string
    shouldPresentBadge: boolean
    content: (props: LocationTabProps) => JSX.Element
}

export default function LocationPage() {
    const { t } = useTranslation()

    const { locationId } = useParams()
    const { location, update } = useLocation(locationId)

    const headerRef = useRef<HTMLDivElement>(null)

    const requiresAttention = useCallback(
        (matchingViolations: Location.Violation[]) => {
            if (!location) return false

            return matchingViolations.some((violation) =>
                location.violations.has(violation)
            )
        },
        [location]
    )

    const tabs: TabEntry[] = [
        {
            id: 'general',
            name: t('General'),
            shouldPresentBadge: requiresAttention([
                'missing-category',
                'missing-capacity',
                'missing-address',
                'invalid-address',
            ]),
            content: GeneralTab,
        },
        {
            id: 'policy',
            name: t('Services'),
            shouldPresentBadge: requiresAttention(['missing-policies']),
            content: PolicyTab,
        },
        {
            id: 'menus',
            name: t('Menus'),
            shouldPresentBadge: requiresAttention([
                'no-menu-assigned',
                'calendar-uncovered-schedule',
            ]),
            content: MenuAssignmentTab,
        },
        {
            id: 'gallery',
            name: t('Gallery'),
            shouldPresentBadge: requiresAttention(['empty-gallery']),
            content: GalleryTab,
        },
        {
            id: 'rooms',
            name: t('Rooms'),
            shouldPresentBadge: requiresAttention([]),
            content: RoomTab,
        },
        {
            id: 'opening-hours',
            name: t('Opening Hours'),
            shouldPresentBadge: requiresAttention(['calendar-always-closed']),
            content: TimeScheduleTab,
        },
        {
            id: 'staff',
            name: t('Staff'),
            shouldPresentBadge: false,
            content: StaffTab,
        },
        {
            id: 'printers',
            name: t('Printers'),
            shouldPresentBadge: false,
            content: PrinterTab,
        },
        {
            id: 'terminals',
            name: t('Terminal Accounts'),
            shouldPresentBadge: requiresAttention([]),
            content: TerminalTab,
        },
        {
            id: 'card-readers',
            name: t('Card Readers'),
            shouldPresentBadge: requiresAttention([]),
            content: CardReaderTab,
        },
        {
            id: 'publishment',
            name: t('Publishment'),
            shouldPresentBadge: location?.published === false,
            content: PublishTab,
        },
    ]

    if (!location) {
        return <LoadingIndicator />
    }

    return (
        <Scaffold tabs={tabs} title={location.name}>
            <TabPanels>
                {tabs.map((tab) => (
                    <TabPanel key={tab.name} p={0}>
                        <tab.content
                            location={location}
                            update={update}
                            headerRef={headerRef}
                        />
                    </TabPanel>
                ))}
            </TabPanels>
        </Scaffold>
    )
}
