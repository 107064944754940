import {
    Box,
    Button,
    Center,
    Heading,
    Icon,
    Image,
    Portal,
    Spinner,
    Text,
    VStack,
    useConst,
} from '@chakra-ui/react'
import { ImageUploader } from 'features/media/components/ImageUploader'
import { MediaUploadError } from 'features/media/errors/MediaUploadError'
import { UseMediaUploadParams } from 'features/media/hooks/useMediaUpload'
import { Media } from 'features/media/models/Media'
import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import { MediaUploadTaskStatus } from 'features/media/models/MediaUploadTaskStatus'
import { UseNewMenuFormDataHook } from 'features/menu/hooks/new/useNewMenuForm'
import useT from 'localization/hooks/useT'
import { DropzoneState } from 'react-dropzone'
import { FaCloudUploadAlt, FaExclamationCircle } from 'react-icons/fa'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedAction'

export interface MenuImageStepDelegate {
    media?: Media
    buildMenuUploadLink: (media: File) => Promise<MediaUploadLink>
}

export function NewMenuImageStep({
    headerRef,
    image: formData,
    media,
    buildMenuUploadLink,
}: UseNewMenuFormDataHook & MenuImageStepDelegate & StepPropsWithRef) {
    const t = useT('menu')

    const config: UseMediaUploadParams = useConst(() => ({
        automaticReset: false,
        automaticUploadMediaAction: buildMenuUploadLink,
        onUploadingStatusChanged: formData.setIsUploading,
        onUploadEnded: formData.setRawMedia,
    }))

    return (
        <>
            <Portal containerRef={headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {t('new_step_2_cover_title')}
                    </Heading>
                    <Text>{t('new_step_2_cover_description')}</Text>
                </VStack>
            </Portal>

            <Center w="full">
                <ImageUploader
                    config={config}
                    renderer={({ status, dropzone }) => (
                        <NewMenuImageUploaderRenderer
                            currentMedia={media}
                            status={status}
                            dropzone={dropzone}
                        />
                    )}
                />
            </Center>
        </>
    )
}

const size = '400px'

interface NewMenuImageUploaderRendererProps {
    currentMedia?: Media
    status: MediaUploadTaskStatus
    dropzone: DropzoneState
}

function NewMenuImageUploaderRenderer({
    currentMedia,
    status,
    dropzone,
}: NewMenuImageUploaderRendererProps) {
    const t = useT('menu')

    const { getRootProps, getInputProps, isDragActive } = dropzone

    if (status.type === 'idle') {
        return (
            <Box
                w={size}
                h={size}
                minW={size}
                maxW={size}
                minH={size}
                maxH={size}
                borderRadius="3xl"
                boxShadow="base"
                overflow="hidden"
                position="relative"
            >
                {currentMedia && (
                    <Image
                        src={currentMedia.large()}
                        alt="Cover"
                        w="full"
                        h="full"
                        objectFit="cover"
                    />
                )}
                <VStack
                    {...getRootProps()}
                    position="absolute"
                    top={0}
                    left={0}
                    w="full"
                    h="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg={currentMedia ? 'blackAlpha.600' : 'pageBackground3'}
                    cursor="pointer"
                >
                    <Icon
                        as={FaCloudUploadAlt}
                        boxSize={20}
                        color={currentMedia ? 'white' : 'label2'}
                    />
                    <input {...getInputProps()} />
                    <VStack spacing={0}>
                        <Text
                            textAlign="center"
                            fontSize="xl"
                            fontWeight="semibold"
                            color={currentMedia ? 'white' : 'label1'}
                        >
                            {isDragActive
                                ? t('media_uploader_title_drop')
                                : currentMedia
                                ? t('media_uploader_title_change')
                                : t('media_uploader_title_new')}
                        </Text>

                        <Text
                            textAlign="center"
                            fontSize="lg"
                            color={currentMedia ? 'white' : 'label2'}
                        >
                            {t('media_uploader_description')}
                        </Text>
                    </VStack>
                </VStack>
            </Box>
        )
    }

    if (status.type === 'uploading') {
        return (
            <Box
                w={size}
                h={size}
                minW={size}
                maxW={size}
                minH={size}
                maxH={size}
                borderRadius="3xl"
                boxShadow="base"
                overflow="hidden"
                position="relative"
            >
                <Image
                    src={status.url}
                    alt="Cover"
                    w="full"
                    h="full"
                    objectFit="cover"
                    cursor="pointer"
                />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    w="full"
                    h="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="blackAlpha.600"
                    borderRadius="xl"
                >
                    <Spinner size="xl" />
                </Box>
            </Box>
        )
    }

    if (status.type === 'success') {
        return (
            <VStack>
                <Box
                    w={size}
                    h={size}
                    minW={size}
                    maxW={size}
                    minH={size}
                    maxH={size}
                    borderRadius="3xl"
                    boxShadow="base"
                    overflow="hidden"
                >
                    <Image
                        src={status.url}
                        alt="Cover"
                        w="full"
                        h="full"
                        objectFit="cover"
                    />
                </Box>
                <Button
                    variant="unstyled"
                    size="sm"
                    color="label3"
                    _hover={{ color: 'label2' }}
                    onClick={status.onReset}
                >
                    {t('media_uploader_change_image_button')}
                </Button>
            </VStack>
        )
    }

    if (status.type === 'failure') {
        let title: string
        let subtitle: string

        if (
            status.error instanceof MediaUploadError.ProcessingError &&
            status.error.moderationLabel
        ) {
            title = status.error.moderationLabel
            subtitle = t('media_uploader_error_moderation_subtitle')
        } else {
            title = t('media_uploader_error_generic_title')
            subtitle = t('media_uploader_error_generic_subtitle')
        }

        return (
            <Box
                w={size}
                h={size}
                minW={size}
                maxW={size}
                minH={size}
                maxH={size}
                borderRadius="3xl"
                boxShadow="base"
                overflow="hidden"
                cursor="pointer"
                onClick={status.onReset}
                borderWidth={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={4}
                bg="pageBackground3"
            >
                <VStack w="full">
                    <Icon
                        as={FaExclamationCircle}
                        boxSize={20}
                        color="label2"
                    />
                    <VStack spacing={0}>
                        <Text
                            textAlign="center"
                            fontSize="xl"
                            fontWeight="semibold"
                        >
                            {title}
                        </Text>

                        <Text textAlign="center" fontSize="md">
                            {subtitle}
                        </Text>
                    </VStack>
                </VStack>
            </Box>
        )
    }

    return null
}
