import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
    Button,
    Center,
    Divider,
    HStack,
    Heading,
    IconButton,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react'
import useMenusContext from 'features/menu/contexts/MenusContext'
import useT from 'localization/hooks/useT'
import _ from 'lodash'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { LoadingIndicator } from 'utils/loading'
import { Receipt } from '../../models/Receipt'
import { ReceiptCompact } from '../../models/ReceiptCompact'
import { ReceiptContent } from './ReceiptContent'

interface ReceiptsContentProps {
    isLoading: boolean
    isLoadingDetails: boolean
    selectedId: string | undefined
    receipt: Receipt | undefined
    receipts: ReceiptCompact[]
    selectReceipt: (receipt: ReceiptCompact | undefined) => Promise<void>
}

export function ReceiptsContent({ isLoading, ...props }: ReceiptsContentProps) {
    const t = useT('orderhistory')

    const menusManager = useMenusContext()
    const screen = useScreenBreakpoint()

    if (menusManager.isLoading || isLoading) {
        return (
            <Center>
                <Spinner />
            </Center>
        )
    }

    if (props.receipts.length === 0) {
        return <Text>{t('no_receipts_message')}</Text>
    }

    if (screen === 'mobile') {
        return <MobileReceiptsContent {...props} />
    } else {
        return <DesktopReceiptsContent {...props} />
    }
}

function MobileReceiptsContent({
    isLoadingDetails,
    receipt,
    receipts,
    selectReceipt,
}: Omit<ReceiptsContentProps, 'isLoading'>) {
    const { i18n } = useTranslation('orderhistory')

    if (isLoadingDetails) {
        return (
            <Center>
                <Spinner />
            </Center>
        )
    }

    if (receipt !== undefined) {
        return (
            <ReceiptContent
                receipt={receipt}
                heading={
                    <HStack align="center">
                        <IconButton
                            aria-label={'Back'}
                            icon={<ChevronLeftIcon />}
                            variant="ghost"
                            size="sm"
                            onClick={() => selectReceipt(undefined)}
                        />
                        <Heading size="sm">
                            {_.capitalize(
                                receipt.issuingDate.toLocaleString(
                                    i18n.language,
                                    {
                                        dateStyle: 'full',
                                        timeStyle: 'medium',
                                    }
                                )
                            )}
                        </Heading>
                    </HStack>
                }
            />
        )
    }

    return (
        <VStack align="stretch" spacing={0} maxH={500} overflowY="auto">
            {receipts.map((receipt, index) => (
                <Fragment key={receipt.id.receiptId}>
                    <Button
                        minH={8}
                        key={receipt.id.receiptId}
                        w="full"
                        onClick={() => {
                            selectReceipt(receipt)
                        }}
                        borderRadius={0}
                        colorScheme="gray"
                        variant="ghost"
                    >
                        #{index + 1} -{' '}
                        {receipt.issuingDate.toLocaleTimeString()}
                    </Button>

                    {index !== receipts.length - 1 && <Divider />}
                </Fragment>
            ))}
        </VStack>
    )
}

function DesktopReceiptsContent({
    isLoadingDetails,
    selectedId,
    receipt,
    receipts,
    selectReceipt,
}: Omit<ReceiptsContentProps, 'isLoading'>) {
    const { t, i18n } = useTranslation('orderhistory')

    return (
        <HStack align="stretch">
            <VStack flex={3} align="stretch" borderEndWidth={1} spacing={0}>
                {receipts.map((receipt, index) => (
                    <Fragment key={receipt.id.receiptId}>
                        <Button
                            w="full"
                            key={receipt.id.receiptId}
                            onClick={() => selectReceipt(receipt)}
                            borderRadius={0}
                            colorScheme="gray"
                            justifyContent="start"
                            fontWeight={
                                selectedId === receipt.id.receiptId
                                    ? 'semibold'
                                    : 'normal'
                            }
                            variant={
                                selectedId === receipt.id.receiptId
                                    ? 'solid'
                                    : 'ghost'
                            }
                        >
                            #{index + 1} -{' '}
                            {receipt.issuingDate.toLocaleTimeString()}
                        </Button>

                        {index !== receipts.length - 1 && <Divider />}
                    </Fragment>
                ))}
            </VStack>

            <VStack flex={9}>
                {isLoadingDetails ? (
                    <LoadingIndicator />
                ) : receipt === undefined ? (
                    <Text>{t('no_receipt_selected_message')}</Text>
                ) : (
                    <ReceiptContent
                        receipt={receipt}
                        heading={
                            <VStack>
                                <Heading size="md">
                                    {_.capitalize(
                                        receipt.issuingDate.toLocaleString(
                                            i18n.language,
                                            {
                                                dateStyle: 'full',
                                                timeStyle: 'medium',
                                            }
                                        )
                                    )}
                                </Heading>
                            </VStack>
                        }
                    />
                )}
            </VStack>
        </HStack>
    )
}
