import { InputProps, TagLabel, Text } from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import { Dispatch, SetStateAction } from 'react'
import { AutocompleteSelector } from 'uikit/form/input'
import { AllergenIcon } from './AllergenIcon'

interface AllergensInputProps extends Pick<InputProps, 'bg' | 'variant'> {
    label?: string
    helperText?: string
    placeholder?: string
    allergens: Menu.Section.Entry.Allergen[]
    setAllergens: Dispatch<SetStateAction<Menu.Section.Entry.Allergen[]>>
}

export function AllergensInput({
    label,
    helperText,
    placeholder,
    allergens,
    setAllergens,
    ...props
}: AllergensInputProps) {
    return (
        <AutocompleteSelector
            {...props}
            enum={Menu.Section.Entry.Allergen}
            value={allergens}
            placeholder={placeholder}
            label={label}
            helperText={helperText}
            setValue={setAllergens}
            SelectedRenderer={SelectedRenderer}
            AvailableRenderer={AvailableRenderer}
        />
    )
}

interface AllergensInputRendererProps {
    option: Menu.Section.Entry.Allergen
}

function SelectedRenderer({ option }: AllergensInputRendererProps) {
    const t = useT('menu')

    return (
        <>
            <AllergenIcon allergen={option} size="xs" mx={1} />
            <TagLabel>
                {t(
                    `allergen_${Menu.Section.Entry.Allergen[
                        option
                    ].toLowerCase()}`
                )}
            </TagLabel>
        </>
    )
}

function AvailableRenderer({ option }: AllergensInputRendererProps) {
    const t = useT('menu')

    return (
        <>
            <AllergenIcon allergen={option} size="xs" />
            <Text>
                {t(
                    `allergen_${Menu.Section.Entry.Allergen[
                        option
                    ].toLowerCase()}`
                )}
            </Text>
        </>
    )
}
