import { useInjection } from 'inversify-react'
import { suspend } from 'suspend-react'
import { PricingPlanDependencies } from '../dependencies/pricingplan'

export default function usePricingPlans(organizationId: string) {
    const listPricingPlans = useInjection(PricingPlanDependencies.List)

    return suspend(
        async () => listPricingPlans.run({ organizationId }),
        ['usePricingPlans', organizationId]
    )
}
