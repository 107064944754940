import {
    Button,
    ButtonGroup,
    Checkbox,
    Collapse,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Radio,
    RadioGroup,
    VStack,
    useConst,
} from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { MenuToolbarProps } from './MenuToolbar'

export function FeaturesTool(props: MenuToolbarProps) {
    return (
        <Popover>
            {(popoverProps) => (
                <FeaturesToolContent {...popoverProps} {...props} />
            )}
        </Popover>
    )
}

interface FeaturesToolContentProps extends MenuToolbarProps {
    isOpen: boolean
    onClose: () => void
}

function FeaturesToolContent({
    currentSection,
    formData,
    controller,
    isOpen,
    onClose,
}: FeaturesToolContentProps) {
    const t = useT('menu')

    const selectedEntries = useMemo(
        () =>
            Array.from(formData.selectedEntriesIndexes).map(
                (index) => currentSection.entries[index]
            ),
        [currentSection, formData.selectedEntriesIndexes]
    )

    const onChange = useCallback(
        (feature: Menu.Section.Entry.Feature, status: boolean) => {
            formData.setChangedFeatures((previous) => {
                const newMap = new Map(previous)
                newMap.set(feature, status)
                return newMap
            })
        },
        []
    )

    useEffect(() => {
        if (!isOpen) {
            formData.setChangedFeatures(new Map())
        }
    }, [isOpen])

    return (
        <>
            <PopoverTrigger>
                <Button isDisabled={selectedEntries.length === 0}>
                    {t('menu_features_label')}
                </Button>
            </PopoverTrigger>
            <PopoverContent bg="sheetBackground1">
                <PopoverArrow bg="sheetBackground1" />
                <PopoverHeader>{t('menu_features_label')}</PopoverHeader>
                <PopoverBody>
                    <VStack align="stretch" spacing={0}>
                        {Object.keys(Menu.Section.Entry.Feature)
                            .map((feature) => parseInt(feature))
                            .filter((feature) => !isNaN(feature))
                            .filter(
                                (feature) =>
                                    feature !==
                                        Menu.Section.Entry.Feature.Spicy2 &&
                                    feature !==
                                        Menu.Section.Entry.Feature.Spicy3
                            )
                            .map((feature) =>
                                feature ===
                                Menu.Section.Entry.Feature.Spicy1 ? (
                                    <SpicyFeatureSelector
                                        key={feature}
                                        selectedEntries={selectedEntries}
                                        changedFeatures={
                                            formData.changedFeatures
                                        }
                                        setChangedFeatures={
                                            formData.setChangedFeatures
                                        }
                                    />
                                ) : (
                                    <FeatureSelector
                                        key={feature}
                                        feature={feature}
                                        forcedStatus={formData.changedFeatures.get(
                                            feature
                                        )}
                                        selectedEntries={selectedEntries}
                                        onChange={onChange}
                                    />
                                )
                            )}
                    </VStack>
                </PopoverBody>
                <PopoverFooter>
                    <ButtonGroup
                        w="full"
                        size="sm"
                        variant="ghost"
                        justifyContent="end"
                    >
                        <Button onClick={onClose}>
                            {t('generic_cancel_button')}
                        </Button>
                        <Button
                            onClick={() => {
                                controller.updateEntries()
                                onClose()
                            }}
                            isDisabled={!controller.canUpdateEntries}
                        >
                            {t('generic_save_button')}
                        </Button>
                    </ButtonGroup>
                </PopoverFooter>
            </PopoverContent>
        </>
    )
}

interface FeatureSelectorProps {
    feature: Menu.Section.Entry.Feature
    selectedEntries: Menu.Section.Entry[]
    forcedStatus: boolean | undefined
    onChange: (feature: Menu.Section.Entry.Feature, isAdding: boolean) => void
}

function FeatureSelector({
    feature,
    selectedEntries,
    forcedStatus,
    onChange,
}: FeatureSelectorProps) {
    const { t } = useTranslation()

    const isChecked = useMemo(
        () =>
            selectedEntries.every((entry) => entry.features.includes(feature)),
        [selectedEntries]
    )

    const isIndeterminate = useMemo(
        () => selectedEntries.some((entry) => entry.features.includes(feature)),
        [selectedEntries]
    )

    return (
        <Checkbox
            isIndeterminate={
                forcedStatus === undefined && !isChecked && isIndeterminate
            }
            isChecked={forcedStatus ?? isChecked}
            onChange={(e) => onChange(feature, e.target.checked)}
        >
            {t(Menu.Section.Entry.Feature[feature])}
        </Checkbox>
    )
}

interface SpicyFeatureSelectorProps {
    selectedEntries: Menu.Section.Entry[]
    changedFeatures: Map<Menu.Section.Entry.Feature, boolean>
    setChangedFeatures: Dispatch<
        SetStateAction<Map<Menu.Section.Entry.Feature, boolean>>
    >
}

function SpicyFeatureSelector({
    selectedEntries,
    changedFeatures,
    setChangedFeatures,
}: SpicyFeatureSelectorProps) {
    const { t } = useTranslation()

    const levels = useConst(() => [
        Menu.Section.Entry.Feature.Spicy1,
        Menu.Section.Entry.Feature.Spicy2,
        Menu.Section.Entry.Feature.Spicy3,
    ])

    const values = useMemo(() => {
        return selectedEntries
            .map((entry) => {
                const features = entry.features.filter((feature) =>
                    levels.includes(feature)
                )
                if (features.length > 0) {
                    return features[0]
                }
            })
            .filter(
                (feature, index, features) =>
                    features.indexOf(feature) === index
            )
    }, [selectedEntries])

    const selectedValue = useMemo(() => {
        if (levels.some((level) => changedFeatures.get(level) === undefined)) {
            return undefined
        }

        for (const level of levels) {
            if (changedFeatures.get(level)) {
                return level
            }
        }

        return null
    }, [changedFeatures])

    const onChange = useCallback(
        (newLevel: Menu.Section.Entry.Feature | null) => {
            setChangedFeatures((previous) => {
                const newMap = new Map(previous)
                levels.forEach((level) => newMap.set(level, level === newLevel))
                return newMap
            })
        },
        []
    )

    return (
        <>
            <Checkbox
                isChecked={
                    selectedValue === undefined
                        ? values.length === 1 && values[0] !== undefined
                        : selectedValue !== null
                }
                isIndeterminate={
                    selectedValue === undefined && values.length > 1
                }
                onChange={(event) => {
                    onChange(
                        event.target.checked
                            ? Menu.Section.Entry.Feature.Spicy1
                            : null
                    )
                }}
            >
                {t('Spicy')}
            </Checkbox>
            <Collapse
                in={
                    selectedValue === undefined
                        ? values.length === 1 && values[0] !== undefined
                        : selectedValue !== null
                }
                animateOpacity
            >
                <RadioGroup
                    onChange={(rawValue) => {
                        const newLevel = parseInt(
                            rawValue
                        ) as Menu.Section.Entry.Feature
                        onChange(newLevel)
                    }}
                    value={
                        selectedValue === undefined
                            ? values[0]?.toString()
                            : selectedValue?.toString()
                    }
                >
                    <VStack align="start" ps={4} spacing={0}>
                        <Radio
                            value={Menu.Section.Entry.Feature.Spicy1.toString()}
                        >
                            {t('Low')}
                        </Radio>
                        <Radio
                            value={Menu.Section.Entry.Feature.Spicy2.toString()}
                        >
                            {t('Medium')}
                        </Radio>
                        <Radio
                            value={Menu.Section.Entry.Feature.Spicy3.toString()}
                        >
                            {t('High')}
                        </Radio>
                    </VStack>
                </RadioGroup>
            </Collapse>
        </>
    )
}
