import { VStack } from '@chakra-ui/react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { LocationsRecap } from '../components/LocationsRecap'
import { NextSteps } from '../components/NextSteps'
import { Welcome } from '../components/Welcome'

export default function HomePage() {
    const screen = useScreenBreakpoint()

    return (
        <VStack
            gap={screen === 'mobile' ? 2 : 8}
            py={screen === 'mobile' ? 2 : 8}
        >
            <Welcome />
            <NextSteps />
            <LocationsRecap />
        </VStack>
    )
}
