import {
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Portal,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { AddressForm } from 'features/address/components/AddressForm'
import { AddressSearchInput } from 'features/address/components/AddressSearchInput'
import useT from 'localization/hooks/useT'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedAction'
import { UseNewOrganizationFormDataHook } from '../hooks/useNewOrganizationForm'
import { UseNewOrganizationStructureStepFormDataHook } from '../hooks/useNewOrganizationStructureStep'
import { TaxIdInput } from './TaxIdInput'

export function NewOrganizationBillingStep({
    headerRef,
    billing: formData,
    structure: structureForm,
}: UseNewOrganizationFormDataHook & StepPropsWithRef) {
    const t = useT('organization')

    return (
        <>
            <Portal containerRef={headerRef}>
                <Heading size="xl" fontWeight="semibold">
                    {t('new_step_2_billing_title')}
                </Heading>
            </Portal>

            <VStack w="full" spacing={6}>
                <FormControl>
                    <FormLabel>
                        {t(
                            `${structureForm.structure}_account_name_input_label`
                        )}
                    </FormLabel>
                    <Input
                        type="text"
                        placeholder={t(
                            `${structureForm.structure}_account_name_input_placeholder`
                        )}
                        autoComplete="off"
                        value={formData.name}
                        onChange={(e) => formData.setName(e.target.value)}
                    />
                    <FormHelperText>
                        {t(
                            `${structureForm.structure}_account_name_input_support`
                        )}
                    </FormHelperText>
                </FormControl>

                {formData.address ? (
                    <>
                        {structureForm.structure === 'company' && (
                            <TaxIdInput
                                country={formData.address.country}
                                taxId={formData.taxId}
                                taxIdSuffix={formData.swissTaxIdSuffix}
                                setTaxId={formData.setTaxId}
                                setTaxIdSuffix={formData.setSwissTaxIdSuffix}
                            />
                        )}
                        <AddressForm
                            address={formData.address}
                            setAddress={formData.setAddress}
                        />
                    </>
                ) : (
                    <AddressSearchInput onPick={formData.setAddress} />
                )}

                <SwitchStructure {...structureForm} />
            </VStack>
        </>
    )
}

function SwitchStructure({
    structure,
    setStructure,
}: UseNewOrganizationStructureStepFormDataHook) {
    const t = useT('organization')
    const screen = useScreenBreakpoint()

    return (
        <Stack
            mb={8}
            alignItems="center"
            justifyContent="center"
            align="stretch"
            direction={screen === 'mobile' ? 'column' : 'row'}
            spacing={screen === 'mobile' ? 0 : 2}
        >
            <Text fontSize="sm">
                {t(`switch_from_${structure}_account_message`)}
            </Text>

            <Text
                color="accent"
                fontWeight="semibold"
                cursor="pointer"
                fontSize="sm"
                onClick={() => {
                    setStructure(
                        structure === 'company' ? 'individual' : 'company'
                    )
                }}
            >
                {t(`switch_from_${structure}_account_button`)}
            </Text>
        </Stack>
    )
}
