import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { RefObject, useEffect, useRef, useState } from 'react'
import { Circle, Group, Rect, Text, Transformer } from 'react-konva'
import { Geometry } from 'utils/geometry/Geometry'

interface TableShapeProps {
    id: string
    name: string
    shape: Geometry.Shape | undefined
    canShowTableNumber: boolean
    isEditable: boolean
    isSelected: boolean
    isOverlapping: boolean
    canvasSize: Geometry.Size
    canvasScale: number
    onClick?: () => void
    onDragStart?: (event: KonvaEventObject<Event>) => void
    onDragEnd: (event: KonvaEventObject<Event>) => void
    onTransform: (event: KonvaEventObject<Event>) => void
    onTransformEnd: (event: KonvaEventObject<Event>) => void
}

export function TableShape({
    id,
    name,
    shape,
    canShowTableNumber,
    isEditable,
    isSelected,
    isOverlapping,
    canvasSize,
    canvasScale,
    onClick,
    onDragStart,
    onDragEnd,
    onTransform,
    onTransformEnd,
}: TableShapeProps) {
    const [isBeingEdited, setIsBeingEdited] = useState(false)

    const textProps = {
        fill: isOverlapping ? '#ffffff' : '#000000',
    }

    const shapeProps = {
        name: id,
        opacity: isBeingEdited ? 0.75 : 1.0,
        fillLinearGradientColorStops: isOverlapping
            ? [1, '#c51162', 0, '#f48fb1']
            : [1, '#ffffff', 0, '#f1f1ff'],
        shadowColor: 'black',
        shadowBlur: isBeingEdited ? 10 : 5,
        shadowOpacity: 0.25,
    }

    const groupProps = {
        name: id,
        draggable: isEditable,
        onDragStart: (event: KonvaEventObject<DragEvent>) => {
            setIsBeingEdited(true)
            if (onDragStart) {
                onDragStart(event)
            }
        },
        onDragEnd: (event: KonvaEventObject<DragEvent>) => {
            setIsBeingEdited(false)
            onDragEnd(event)
        },
        onMouseEnter: (event: KonvaEventObject<MouseEvent>) => {
            const container = event.target.getStage()?.container()
            if (container) {
                container.style.cursor = 'pointer'
            }
        },
        onMouseLeave: (event: KonvaEventObject<MouseEvent>) => {
            const container = event.target.getStage()?.container()
            if (container) {
                container.style.cursor = 'default'
            }
        },
        onTransformStart: () => {
            setIsBeingEdited(true)
        },
        onTransformEnd: (event: KonvaEventObject<Event>) => {
            setIsBeingEdited(false)
            onTransformEnd(event)
        },
        onTransform: onTransform,
        onClick: onClick,
        onTap: onClick,
    }

    if (shape?.type === 'rectangle') {
        return (
            <TableShapeRectangle
                displayName={name}
                geometry={shape}
                canShowTableNumber={canShowTableNumber}
                isSelected={isSelected}
                canvasSize={canvasSize}
                canvasScale={canvasScale}
                shapeProps={shapeProps}
                groupProps={groupProps}
                textProps={textProps}
            />
        )
    } else if (shape?.type === 'circle') {
        return (
            <TableShapeCircle
                displayName={name}
                geometry={shape}
                canShowTableNumber={canShowTableNumber}
                isSelected={isSelected}
                canvasSize={canvasSize}
                canvasScale={canvasScale}
                shapeProps={shapeProps}
                groupProps={groupProps}
                textProps={textProps}
            />
        )
    } else {
        return null
    }
}

interface TableGeometryShapeProps {
    displayName: string
    canShowTableNumber: boolean
    isSelected: boolean
    canvasSize: Geometry.Size
    canvasScale: number
    shapeProps: object
    groupProps: object
    textProps: object
}

function TableShapeRectangle({
    displayName,
    geometry,
    canShowTableNumber,
    isSelected,
    canvasSize,
    canvasScale,
    shapeProps,
    groupProps,
    textProps,
}: TableGeometryShapeProps & { geometry: Geometry.Shape.Rectangle }) {
    const width = geometry.size.width * canvasScale
    const height = geometry.size.height * canvasScale

    const groupRef: RefObject<Konva.Group> = useRef(null)
    const transformerRef: RefObject<Konva.Transformer> = useRef(null)

    useEffect(() => {
        if (isSelected && transformerRef.current && groupRef.current) {
            transformerRef.current.nodes([groupRef.current])
            transformerRef.current?.getLayer()?.batchDraw()
        }
    }, [isSelected])

    return (
        <>
            <Group
                draggable
                ref={groupRef}
                x={(geometry.center.x - geometry.size.width / 2) * canvasScale}
                y={(geometry.center.y - geometry.size.height / 2) * canvasScale}
                width={width}
                height={height}
                dragBoundFunc={(pos) => {
                    const minX = 20
                    const minY = 20
                    const maxX = canvasSize.width - width - 20
                    const maxY = canvasSize.height - height - 20
                    return {
                        x: pos.x < minX ? minX : pos.x > maxX ? maxX : pos.x,
                        y: pos.y < minY ? minY : pos.y > maxY ? maxY : pos.y,
                    }
                }}
                {...groupProps}
            >
                <Rect
                    cornerRadius={4}
                    width={width}
                    height={height}
                    fillLinearGradientStartPoint={{ x: width / 2, y: 0 }}
                    fillLinearGradientEndPoint={{ x: width / 2, y: height }}
                    {...shapeProps}
                />

                {canShowTableNumber && (
                    <Text
                        text={displayName}
                        fontSize={Math.min(width / 2, height / 2, 24)}
                        fontFamily={`'Barlow', 'sans-serif'`}
                        width={width}
                        height={height}
                        align="center"
                        verticalAlign="middle"
                        {...textProps}
                    />
                )}
            </Group>
            {isSelected && (
                <Transformer
                    ref={transformerRef}
                    rotateEnabled={false}
                    keepRatio={false}
                    flipEnabled={false}
                    anchorCornerRadius={2}
                    boundBoxFunc={(oldBox, newBox) => {
                        const minX = 20
                        const minY = 20
                        const maxX = canvasSize.width - 20
                        const maxY = canvasSize.height - 20

                        if (
                            newBox.x < minX ||
                            newBox.y < minY ||
                            newBox.x + newBox.width > maxX ||
                            newBox.y + newBox.height > maxY
                        ) {
                            return oldBox
                        } else {
                            return newBox
                        }
                    }}
                />
            )}
        </>
    )
}

function TableShapeCircle({
    displayName,
    geometry,
    canShowTableNumber,
    isSelected,
    canvasSize,
    canvasScale,
    shapeProps,
    groupProps,
    textProps,
}: TableGeometryShapeProps & { geometry: Geometry.Shape.Circle }) {
    const radius = geometry.radius * canvasScale

    const groupRef: RefObject<Konva.Group> = useRef(null)
    const transformerRef: RefObject<Konva.Transformer> = useRef(null)

    useEffect(() => {
        if (isSelected && transformerRef.current && groupRef.current) {
            transformerRef.current.nodes([groupRef.current])
            transformerRef.current?.getLayer()?.batchDraw()
        }
    }, [isSelected])

    return (
        <>
            <Group
                draggable
                ref={groupRef}
                x={geometry.center.x * canvasScale}
                y={geometry.center.y * canvasScale}
                dragBoundFunc={(pos) => {
                    const minX = radius + 20
                    const minY = radius + 20
                    const maxX = canvasSize.width - radius - 20
                    const maxY = canvasSize.height - radius - 20
                    return {
                        x: pos.x < minX ? minX : pos.x > maxX ? maxX : pos.x,
                        y: pos.y < minY ? minY : pos.y > maxY ? maxY : pos.y,
                    }
                }}
                {...groupProps}
            >
                <Circle
                    radius={radius}
                    fillLinearGradientStartPoint={{ x: radius / 2, y: 0 }}
                    fillLinearGradientEndPoint={{ x: radius / 2, y: radius }}
                    {...shapeProps}
                />
                {canShowTableNumber && (
                    <Text
                        text={displayName}
                        fontSize={Math.min(radius, 24)}
                        fontFamily={`'Barlow', 'sans-serif'`}
                        align="center"
                        verticalAlign="middle"
                        width={2 * radius}
                        height={2 * radius}
                        x={-radius}
                        y={-radius}
                        {...textProps}
                    />
                )}
            </Group>
            {isSelected && (
                <Transformer
                    ref={transformerRef}
                    rotateEnabled={false}
                    flipEnabled={false}
                    anchorCornerRadius={2}
                    enabledAnchors={[
                        'top-left',
                        'top-right',
                        'bottom-left',
                        'bottom-right',
                    ]}
                    boundBoxFunc={(oldBox, newBox) => {
                        const minX = 20
                        const minY = 20
                        const maxX = canvasSize.width - 20
                        const maxY = canvasSize.height - 20

                        if (
                            newBox.x < minX ||
                            newBox.y < minY ||
                            newBox.x + newBox.width > maxX ||
                            newBox.y + newBox.height > maxY
                        ) {
                            return oldBox
                        } else {
                            return newBox
                        }
                    }}
                />
            )}
        </>
    )
}
