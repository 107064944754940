import { addDays, areIntervalsOverlapping, isSameDay } from 'date-fns'
import { DateRange } from 'features/calendar/models/Calendar'
import { Location } from 'features/location/models/Location'
import _ from 'lodash'
import { useCallback } from 'react'
import { useList } from 'react-use'

function overlaps(left: DateRange, right: DateRange): boolean {
    return areIntervalsOverlapping(
        { start: left.from, end: left.to },
        { start: right.from, end: right.to },
        { inclusive: true }
    )
}

export interface UseScheduledClosuresFormDataHook {
    closures: DateRange[]
    handleNewRange: (range: DateRange) => void
    removeRangeAt: (index: number) => void
}

export function useScheduledClosuresFormData(
    location: Location
): UseScheduledClosuresFormDataHook {
    const [closures, { set: setClosures, removeAt: removeRangeAt }] = useList(
        location.calendar.scheduledClosures
    )

    const handleNewRange = useCallback(
        (range: DateRange) => {
            if (range.from > range.to) {
                ;[range.from, range.to] = [range.to, range.from]
            }

            const [adjacents, others] = _.partition(closures, (closure) => {
                if (isSameDay(addDays(closure.to, 1), range.from)) return true
                if (isSameDay(addDays(range.to, 1), closure.from)) return true

                return false
            })
            const valid = others.filter((closure) => !overlaps(closure, range))

            const rangesToBeMerged = [...adjacents, range]
            const minimum = _.min(rangesToBeMerged.map((range) => range.from))!
            const maximum = _.max(rangesToBeMerged.map((range) => range.to))!

            setClosures([...valid, { from: minimum, to: maximum }])
        },
        [closures]
    )

    return { closures, handleNewRange, removeRangeAt }
}
