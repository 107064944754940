import { useToast } from '@chakra-ui/react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import {
    CustomNotification,
    CustomNotificationProps,
} from '../components/CustomNotification'

export function useNotification() {
    const toast = useToast()
    const screen = useScreenBreakpoint()

    function show({
        id,
        ...options
    }: Omit<CustomNotificationProps, 'onClose'> & { id: string }) {
        if (toast.isActive(id)) return

        toast({
            id: id,
            position: screen === 'mobile' ? 'bottom' : 'top-right',
            render: ({ onClose }) => (
                <CustomNotification {...options} onClose={onClose} />
            ),
        })
    }

    return show
}
