import { Place } from '@aws-sdk/client-location'
import { Address } from '../models/Address'
import { mapISOToCountry } from './InternalMapper'

export function mapPlaceToModel(place: Place): Address {
    return {
        street: place.Street ?? '',
        addressNumber: place.AddressNumber ?? '',
        municipality: place.Municipality ?? '',
        postalCode: place.PostalCode ?? '',
        region: place.Region ?? '',
        subregion: place.SubRegion ?? '',
        country: mapISOToCountry(place.Country),
    }
}
