import Avokado, {
    ArchivedOrderSessionRetrieve,
    AvokadoAPIError,
} from '@avokadoapp/avokado-ts'
import { mapReceiptToModel } from 'features/finance/mapper/receipt/SDKResponseMapper'
import { Receipt } from 'features/finance/models/Receipt'
import {
    MenuDependencies,
    MenuStore,
    RetrieveMenuAction,
} from 'features/menu/dependencies/menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

type RetrieveReceiptParams = Receipt.ID

export interface RetrieveReceiptAction {
    run(params: RetrieveReceiptParams): Promise<Receipt>
}

@injectable()
export class RetrieveReceiptActionLive implements RetrieveReceiptAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado
    @inject(MenuDependencies.Retrieve) private retrieveMenu!: RetrieveMenuAction
    @inject(MenuDependencies.Store) private menuStore!: MenuStore

    async run(params: RetrieveReceiptParams): Promise<Receipt> {
        const request: ArchivedOrderSessionRetrieve.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            archiveId: params.receiptId,
        }
        const response = await this.avokado.archivedOrderSession.retrieve(
            request
        )

        const missingMenus = await Promise.all(
            [...new Set(response.orders.map((order) => order.menuId))]
                .filter((id) => !this.menuStore.contains(id))
                .map(
                    async (id) =>
                        await this.retrieveMenu.run({
                            organizationId: params.organizationId,
                            menuId: id,
                        })
                )
        )
        missingMenus.forEach((menu) => this.menuStore.save(menu))

        return mapReceiptToModel(response, this.menuStore)
    }
}

@injectable()
export class RetrieveReceiptActionFake implements RetrieveReceiptAction {
    async run(params: RetrieveReceiptParams): Promise<Receipt> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class RetrieveReceiptActionFail implements RetrieveReceiptAction {
    async run(params: RetrieveReceiptParams): Promise<Receipt> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
