import Avokado, {
    AvokadoAPIError,
    LocationOrganizationRetrieve,
} from '@avokadoapp/avokado-ts'
import {
    ListMenuAssignmentAction,
    MenuAssignmentDependencies,
} from 'features/menuassignment/dependencies'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { computeLocationViolations } from '../mappers/InternalMapper'
import { mapLocationToModel } from '../mappers/SDKResponseMapper'
import { Location } from '../models/Location'

type RetrieveLocationParams = Location.ID

export interface RetrieveLocationAction {
    run(params: RetrieveLocationParams): Promise<Location>
}

@injectable()
export class RetrieveLocationActionLive implements RetrieveLocationAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado
    @inject(MenuAssignmentDependencies.List)
    private listMenuAssignments!: ListMenuAssignmentAction

    async run(params: RetrieveLocationParams): Promise<Location> {
        const request: LocationOrganizationRetrieve.Request = params
        const menuAssignments = await this.listMenuAssignments.run(params)
        const response = await this.avokado.location.organizationRetrieve(
            request
        )
        return mapLocationToModel(response, menuAssignments)
    }
}

@injectable()
export class RetrieveLocationActionFake implements RetrieveLocationAction {
    async run(params: RetrieveLocationParams): Promise<Location> {
        await sleep(500)

        const location: Omit<Location, 'violations'> = {
            id: params,
            name: 'Location Name',
            published: false,
            restricted: false,
            medias: [],
            address: undefined,
            remainingAddressChanges: 5,
            policy: {
                table: {
                    allowsInAppOrder: true,
                    requiresUpfrontPayment: false,
                    supportsCourseOrdering: true,
                    supportsAutomaticConfirmation: false,
                    supportsInAppPayment: true,
                    coverCost: 200,
                    minimumInAppPaymentAmount: undefined,
                },
                reservation: {
                    allowsRemoteOrder: true,
                    allowsRemoteUpfrontPayment: true,
                    automaticConfirmationThreshold: 200,
                    maximumNumberOfConcurrentReservations: 30,
                    maximumNumberOfPeoplePerReservation: 6,
                    reservationDefaultDuration: 60,
                    tableHoldingDepositAmount: undefined,
                },
            },
            calendar: {
                weekdays: {
                    monday: { type: 'full-day', isActive: false },
                    tuesday: {
                        type: 'time-range',
                        timeRanges: [
                            {
                                from: { hours: 9, minutes: 15 },
                                to: { hours: 14, minutes: 30 },
                            },
                            {
                                from: { hours: 18, minutes: 0 },
                                to: { hours: 23, minutes: 30 },
                            },
                        ],
                    },
                    wednesday: {
                        type: 'time-range',
                        timeRanges: [
                            {
                                from: { hours: 10, minutes: 0 },
                                to: { hours: 14, minutes: 30 },
                            },
                            {
                                from: { hours: 18, minutes: 0 },
                                to: { hours: 23, minutes: 30 },
                            },
                        ],
                    },
                    thursday: {
                        type: 'time-range',
                        timeRanges: [
                            {
                                from: { hours: 10, minutes: 0 },
                                to: { hours: 14, minutes: 30 },
                            },
                            {
                                from: { hours: 18, minutes: 0 },
                                to: { hours: 0, minutes: 30 },
                            },
                        ],
                    },
                    friday: {
                        type: 'time-range',
                        timeRanges: [
                            {
                                from: { hours: 10, minutes: 0 },
                                to: { hours: 14, minutes: 30 },
                            },
                            {
                                from: { hours: 18, minutes: 0 },
                                to: { hours: 23, minutes: 30 },
                            },
                        ],
                    },
                    saturday: { type: 'full-day', isActive: true },
                    sunday: { type: 'full-day', isActive: true },
                },
                scheduledClosures: [
                    {
                        from: new Date('2023-05-12 00:00:00'),
                        to: new Date('2023-05-16 00:00:00'),
                    },
                    {
                        from: new Date('2023-05-22 00:00:00'),
                        to: new Date('2023-05-25 00:00:00'),
                    },
                ],
            },
            category: Location.Category.Sushi,
            subcategories: [],
            capacity: 40,
            capabilities: [],
            menuAssignments: [],
            stripe: { id: undefined },
        }

        return {
            ...location,
            violations: computeLocationViolations(location),
        }
    }
}

@injectable()
export class RetrieveLocationActionFail implements RetrieveLocationAction {
    async run(params: RetrieveLocationParams): Promise<Location> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
