import {
    Box,
    Button,
    HStack,
    Heading,
    IconButton,
    Text,
    VStack,
} from '@chakra-ui/react'
import { describeCapability as describe } from 'features/location/mappers/capability/InternalMapper'
import { Location } from 'features/location/models/Location'
import useT from 'localization/hooks/useT'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FiPlus, FiX } from 'react-icons/fi'
import { FormSection } from 'uikit/form'
import { AutocompletePicker } from 'uikit/form/input'

interface CapabilitySectionProps {
    capabilities: Location.Capability[]
    setCapabilities: Dispatch<SetStateAction<Location.Capability[]>>
}

export function CapabilitySection({
    capabilities,
    setCapabilities,
}: CapabilitySectionProps) {
    const newT = useT('locationgeneral')
    const { t } = useTranslation()

    const [isAdding, setIsAdding] = useState(false)

    const ref = useRef<HTMLInputElement>(null)

    const availableCapabilities: number[] = useMemo(() => {
        return Object.keys(Location.Capability)
            .map((x) => parseInt(x))
            .filter((x) => !isNaN(x))
            .filter((x) => !capabilities.includes(x))
    }, [capabilities])

    const onPick = useCallback((value?: number) => {
        setIsAdding(false)
        if (value !== undefined) {
            setCapabilities((previous) =>
                [...previous, value].sort((x, y) => x - y)
            )
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onRemove = useCallback((value: number) => {
        setCapabilities((previous) => previous.filter((x) => x !== value))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isAdding && ref.current) {
            ref.current.focus()
        }
    }, [isAdding])

    return (
        <FormSection
            title={newT('capabilities_title')}
            subtitle={newT('capabilities_subtitle')}
        >
            <VStack align="stretch" spacing={4}>
                {capabilities.map((capability) => (
                    <HStack key={capability} spacing={4}>
                        <IconButton
                            variant="ghost"
                            size="sm"
                            aria-label="Remove"
                            colorScheme="gray"
                            borderRadius="full"
                            icon={<FiX />}
                            onClick={() => onRemove(capability)}
                        />
                        <VStack align="stretch" spacing={0}>
                            <Heading size="sm" fontWeight="normal">
                                {t(describe(capability))}
                            </Heading>
                            <Text color="label2" fontSize="sm">
                                {t(`${describe(capability)}_description`)}
                            </Text>
                        </VStack>
                    </HStack>
                ))}

                {availableCapabilities.length > 0 &&
                    (isAdding ? (
                        <AutocompletePicker
                            inputRef={ref}
                            placeholder={newT('capabilities_placeholder')}
                            options={availableCapabilities}
                            onPick={onPick}
                            optionMapper={(option) => t(describe(option))}
                            AvailableRenderer={({ option }) => (
                                <Text>{t(describe(option))}</Text>
                            )}
                        />
                    ) : (
                        <Box>
                            <Button
                                variant="unstyle"
                                display="inline-flex"
                                px={0}
                                iconSpacing={4}
                                leftIcon={<FiPlus />}
                                fontWeight="medium"
                                _hover={{ color: 'accent' }}
                                onClick={() => setIsAdding(true)}
                            >
                                {capabilities.length === 0
                                    ? t('Add')
                                    : t('Add another')}
                            </Button>
                        </Box>
                    ))}
            </VStack>
        </FormSection>
    )
}
