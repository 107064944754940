import { Box, useConst } from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import { useTranslation } from 'react-i18next'

interface DatePickerProps {
    date: Date
    onChange: (date: Date) => void
}

export function DatePicker({ date, onChange }: DatePickerProps) {
    const { t } = useTranslation()

    const headings = useConst(() => [
        t('sunday_abbreviated'),
        t('monday_abbreviated'),
        t('tuesday_abbreviated'),
        t('wednesday_abbreviated'),
        t('thursday_abbreviated'),
        t('friday_abbreviated'),
        t('saturday_abbreviated'),
    ])

    const months = useConst(() => [
        t('January'),
        t('February'),
        t('March'),
        t('April'),
        t('May'),
        t('June'),
        t('July'),
        t('August'),
        t('September'),
        t('October'),
        t('November'),
        t('December'),
    ])

    return (
        <Box>
            <SingleDatepicker
                configs={{
                    dateFormat: 'dd-MM-yyyy',
                    dayNames: headings,
                    monthNames: months,
                    firstDayOfWeek: 1,
                }}
                propsConfigs={{
                    dateNavBtnProps: {
                        color: 'label2',
                        variant: 'unstyled',
                        _hover: { color: 'label1' },
                    },
                    dayOfMonthBtnProps: {
                        defaultBtnProps: {
                            color: 'label1',
                            borderRadius: 'full',
                            _hover: {
                                outlineWidth: 1,
                                outlineStyle: 'solid',
                                outlineOffset: -1,
                                outlineColor:
                                    'var(--chakra-colors-accentSurface)',
                                background: 'transparent',
                                color: 'label1',
                            },
                        },
                        selectedBtnProps: {
                            background: 'accentSurface',
                            color: 'onAccentSurface',
                        },
                        todayBtnProps: {
                            _after: {
                                content: "''",
                                position: 'absolute',
                                bottom: 1,
                                left: '50%',
                                transform: 'translate(-50%, 0)',
                                width: '25%',
                                height: 0.5,
                                borderRadius: 'full',
                                background: 'accentSurface',
                            },
                        },
                    },
                    popoverCompProps: {
                        popoverContentProps: {
                            background: 'sheetBackground1',
                        },
                        popoverBodyProps: {
                            padding: 0,
                        },
                    },
                }}
                date={date}
                onDateChange={onChange}
            />
        </Box>
    )
}
