import { useInjection } from 'inversify-react'
import _ from 'lodash'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useList } from 'react-use'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { v4 as uuid } from 'uuid'
import { MenuEntryDependencies } from '../dependencies/entry'
import { MenuExtraDependencies } from '../dependencies/extra'
import { Menu } from '../models/Menu'
export interface UseExtraOption extends Omit<Menu.Extra.Option, 'id'> {
    key: string
    id: Menu.Extra.Option.ID | undefined
}

export interface UseExtraFormDataHook {
    hasChanges: boolean
    name: string
    minOptionsSelectable: number | undefined
    maxOptionsSelectable: number | undefined
    options: UseExtraOption[]
    setName: Dispatch<SetStateAction<string>>
    setMinOptionsSelectable: Dispatch<SetStateAction<number | undefined>>
    setMaxOptionsSelectable: Dispatch<SetStateAction<number | undefined>>
    addOption: () => void
    updateOptionAt: (
        index: number,
        option: Omit<Menu.Extra.Option, 'id'>
    ) => void
    removeOptionAt: (index: number) => void
    reset: () => void
}

export function useExtraFormData(extra?: Menu.Extra): UseExtraFormDataHook {
    const [name, setName] = useState(extra?.name ?? '')
    const [minOptionsSelectable, setMinOptionsSelectable] = useState(
        extra?.minOptionsSelectable
    )
    const [maxOptionsSelectable, setMaxOptionsSelectable] = useState(
        extra?.maxOptionsSelectable
    )
    const [
        options,
        {
            push: pushOption,
            reset: resetOptions,
            updateAt: updateOptionAtWithId,
            removeAt: removeOptionAt,
        },
    ] = useList<UseExtraOption>(
        (
            extra?.options ??
            Array(3).fill({
                id: undefined,
                name: '',
                price: undefined,
            })
        ).map((option) => ({ ...option, key: uuid() }))
    )

    const addOption = useCallback(() => {
        pushOption({ key: uuid(), id: undefined, name: '', price: undefined })
    }, [])

    const updateOptionAt = useCallback(
        (index: number, option: Omit<Menu.Extra.Option, 'id'>) => {
            updateOptionAtWithId(index, { ...options[index], ...option })
        },
        [options]
    )

    const hasChanges = useMemo(() => {
        return (
            !_.isEqual(name, extra?.name ?? '') ||
            !_.isEqual(
                minOptionsSelectable,
                extra?.minOptionsSelectable ?? 0
            ) ||
            !_.isEqual(
                maxOptionsSelectable,
                extra?.maxOptionsSelectable ?? 0
            ) ||
            !_.isEqual(options, extra?.options ?? [])
        )
    }, [name, minOptionsSelectable, maxOptionsSelectable, options, extra])

    const reset = useCallback(() => {
        setName(extra?.name ?? '')
        setMinOptionsSelectable(extra?.minOptionsSelectable)
        setMaxOptionsSelectable(extra?.maxOptionsSelectable)
        resetOptions()
    }, [extra])

    return {
        hasChanges,
        name,
        minOptionsSelectable,
        maxOptionsSelectable,
        options,
        setName,
        setMinOptionsSelectable,
        setMaxOptionsSelectable,
        addOption,
        updateOptionAt,
        removeOptionAt,
        reset,
    }
}

export function useExtraFormController(
    menu: Menu,
    section: Menu.Section,
    entry: Menu.Section.Entry,
    params: UseExtraFormDataHook,
    onSave: (menu: Menu) => void
): UseFormControllerHook<void> {
    const create = useInjection(MenuExtraDependencies.Create)
    const updateEntry = useInjection(MenuEntryDependencies.Update)

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        if (!params.hasChanges) return false
        return true
    }, [params])

    const submit = useCallback(async () => {
        if (isProcessing || !canSubmit) return
        setIsProcessing(true)

        try {
            const oldMenu = _.cloneDeep(menu)
            const extra = await create.run({
                ...oldMenu.id,
                name: params.name,
                minOptionSelectable: params.minOptionsSelectable,
                maxOptionSelectable: params.maxOptionsSelectable,
                options: params.options,
            })
            oldMenu.extras.set(extra.id.extraId, extra)

            const extrasIds = [
                ...entry.extras.map((extra) => extra.id.extraId),
                extra.id.extraId,
            ]

            const newMenu = await updateEntry.run({
                menu: oldMenu,
                section,
                entry,
                extrasIds,
            })

            onSave(newMenu)
        } finally {
            setIsProcessing(false)
        }

        // params.reset()
    }, [isProcessing, canSubmit, menu, section, entry, params])

    return { isProcessing, canSubmit, submit }
}
