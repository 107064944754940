import Avokado, {
    AvokadoAPIError,
    TerminalCreate,
} from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapModelToType, mapTerminalToModel } from '../mappers/SDKMapper'
import { Terminal } from '../models/Terminal'

interface CreateTerminalParams extends Location.ID {
    name: string
    type: Terminal.Type
}

export interface CreateTerminalAction {
    run(params: CreateTerminalParams): Promise<Terminal>
}

@injectable()
export class CreateTerminalActionLive implements CreateTerminalAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateTerminalParams): Promise<Terminal> {
        const request: TerminalCreate.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            name: params.name,
            type: mapModelToType(params.type),
        }
        const response = await this.avokado.terminal.create(request)
        return mapTerminalToModel(params, response)
    }
}

@injectable()
export class CreateTerminalActionFake implements CreateTerminalAction {
    async run(params: CreateTerminalParams): Promise<Terminal> {
        await sleep(2000)
        return {
            id: {
                organizationId: params.organizationId,
                locationId: params.locationId,
                terminalId: '1',
            },
            name: params.name,
            type: params.type,
        }
    }
}

@injectable()
export class CreateTerminalActionFail implements CreateTerminalAction {
    async run(params: CreateTerminalParams): Promise<Terminal> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
