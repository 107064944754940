import { Button, HStack, Select } from '@chakra-ui/react'
import useLocationsContext from 'features/location/contexts/LocationsContext'
import useT from 'localization/hooks/useT'
import { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import { DatePicker } from 'uikit/form/input'
import { LoadingIndicator } from 'utils/loading'
import { useReceipts } from '../hooks/useReceipts'
import { ReceiptsContent } from './receipt/ReceiptsContent'

export function OrderHistoryTab() {
    const t = useT('orderhistory')

    const locationsManager = useLocationsContext()
    const receiptsManager = useReceipts()

    const handleLocationChange = useCallback(
        (rawId: string) => {
            const location = locationsManager.locations.find(
                (location) => location.id.locationId.toString() === rawId
            )

            if (location) {
                receiptsManager.setLocation(location)
            }
        },
        [locationsManager.locations]
    )

    useEffect(() => {
        const location = locationsManager.locations[0]
        if (location) {
            handleLocationChange(location.id.locationId.toString())
        }
    }, [locationsManager.locations])

    return (
        <>
            {locationsManager.isLoading ? (
                <LoadingIndicator />
            ) : locationsManager.locations.length === 0 ? (
                <Container
                    title={t('no_locations_title')}
                    subtitle={t('no_locations_message')}
                    header={
                        <Button as={Link} to={`locations/new`}>
                            {t('show_me_button')}
                        </Button>
                    }
                />
            ) : (
                <Container
                    title={t('order_history_title')}
                    header={
                        <HStack>
                            <DatePicker
                                date={receiptsManager.day}
                                onChange={receiptsManager.setDay}
                            />

                            {locationsManager.locations.length > 1 && (
                                <Select
                                    onChange={(event) =>
                                        handleLocationChange(event.target.value)
                                    }
                                >
                                    {locationsManager.locations.map(
                                        (location) => (
                                            <option
                                                key={location.name}
                                                value={location.id.locationId}
                                            >
                                                {location.name}
                                            </option>
                                        )
                                    )}
                                </Select>
                            )}
                        </HStack>
                    }
                >
                    <ReceiptsContent {...receiptsManager} />
                </Container>
            )}
        </>
    )
}
