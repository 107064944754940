import {
    FormControl,
    FormLabelProps,
    HStack,
    InputGroup,
    InputLeftAddon,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InputLabels } from './InputLabels'

interface DurationInputProps {
    value: number
    title?: string
    description?: string
    labelProps?: FormLabelProps
    setValue: Dispatch<SetStateAction<number>>
}

export function DurationInput({
    value,
    title,
    description,
    setValue,
    labelProps,
}: DurationInputProps) {
    const { t } = useTranslation()

    const [hours, minutes] = useMemo(
        () => [Math.floor(value / 60), value % 60],
        [value]
    )

    return (
        <FormControl>
            <InputLabels
                title={title}
                description={description}
                {...labelProps}
            />

            <HStack>
                <InputGroup>
                    <InputLeftAddon children={t('hours')} />
                    <NumberInput
                        w="full"
                        min={0}
                        max={23}
                        value={hours}
                        onChange={(_, value) => {
                            const actualValue = isNaN(value) ? 0 : value
                            const coerced = Math.max(
                                0,
                                Math.min(actualValue, 23)
                            )
                            setValue(coerced * 60 + minutes)
                        }}
                    >
                        <NumberInputField
                            placeholder={t('Hours')}
                            borderLeftRadius="none"
                        />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </InputGroup>

                <InputGroup>
                    <InputLeftAddon children={t('minutes')} />
                    <NumberInput
                        w="full"
                        min={0}
                        max={59}
                        value={minutes}
                        onChange={(_, value) => {
                            const actualValue = isNaN(value) ? 0 : value
                            const coerced = Math.max(
                                0,
                                Math.min(actualValue, 59)
                            )
                            setValue(hours * 60 + coerced)
                        }}
                    >
                        <NumberInputField
                            placeholder={t('Minutes')}
                            borderLeftRadius="none"
                        />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </InputGroup>
            </HStack>
        </FormControl>
    )
}
