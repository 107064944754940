import {
    MenuCreate,
    MenuList,
    MenuLocationRetrieve,
    MenuSectionBatchCreate,
} from '@avokadoapp/avokado-ts'
import { Media } from 'features/media/models/Media'
import { RawMedia } from 'features/media/models/RawMedia'
import { Menu } from 'features/menu/models/Menu'
import { MenuCompact } from 'features/menu/models/MenuCompact'

export function mapMenuToModel(payload: MenuLocationRetrieve.Response): Menu {
    const menuId = {
        organizationId: payload.organizationId,
        menuId: payload.menuId,
    }

    const extras = mapExtrasToModel(menuId, payload.extras)

    return {
        id: menuId,
        name: payload.name,
        description: payload.description,
        footer: payload.footer,
        media: mapMenuMediaToModel(menuId, payload.cover),
        startingPrice: payload.startingPrice,
        sections: mapSectionsToModel(menuId, extras, payload.sections),
        extras: extras,
        appliedTranslation: payload.appliedTranslation.locale,
        availableTranslations: payload.availableTranslations,
    }
}

export function mapMenuWithoutSectionsToModel(
    payload: MenuCreate.Response
): Menu {
    const menuId = {
        organizationId: payload.organizationId,
        menuId: payload.menuId,
    }

    return {
        id: menuId,
        name: payload.name,
        description: payload.description,
        footer: payload.footer,
        media: mapMenuMediaToModel(menuId, payload.cover),
        startingPrice: payload.startingPrice,
        sections: [],
        extras: new Map(),
        appliedTranslation: payload.appliedTranslation.locale,
        availableTranslations: payload.availableTranslations,
    }
}

export function mapMenusToModel(payload: MenuList.Response): MenuCompact[] {
    return payload.menus
        .sort((a, b) => a.id - b.id)
        .map((menu) => {
            const menuId = {
                organizationId: menu.organizationId,
                menuId: menu.id,
            }
            return {
                id: menuId,
                name: menu.name,
                media: mapMenuMediaToModel(menuId, menu.media),
            }
        })
}

function mapExtrasToModel(
    menuId: Menu.ID,
    payload: MenuLocationRetrieve.Response.Extra[]
): Map<number, Menu.Extra> {
    const map = new Map<number, Menu.Extra>()

    payload
        .map((extra) => mapExtraToModel(menuId, extra))
        .forEach((extra) => map.set(extra.id.extraId, extra))

    return map
}

export function mapExtraToModel(
    menuId: Menu.ID,
    payload: MenuLocationRetrieve.Response.Extra
): Menu.Extra {
    const extraId = { ...menuId, extraId: payload.id }
    return {
        id: extraId,
        name: payload.name,
        maxOptionsSelectable: payload.maxOptionSelectable,
        minOptionsSelectable: payload.minOptionSelectable,
        options: mapOptionsToModel(extraId, payload.options),
    }
}

function mapOptionsToModel(
    extraId: Menu.Extra.ID,
    payload: MenuLocationRetrieve.Response.Option[]
): Menu.Extra.Option[] {
    return payload.map((option) => {
        return {
            id: { ...extraId, optionId: option.id },
            name: option.name,
            price: option.extraPrice,
        }
    })
}

function mapSectionsToModel(
    menuId: Menu.ID,
    extras: Map<number, Menu.Extra>,
    payload: MenuLocationRetrieve.Response.Section[]
): Menu.Section[] {
    return payload.map((section) => {
        const sectionId = { ...menuId, sectionId: section.sectionId }
        return {
            id: sectionId,
            name: section.name,
            description: section.description,
            media: mapSectionMediaToModel(sectionId, section.cover),
            entries: mapEntriesToModel(sectionId, extras, section.entries),
        }
    })
}

export function mapSectionsWithoutEntriesToModel(
    payload: MenuSectionBatchCreate.Response
): Menu.Section[] {
    return payload.sections.map((section) => {
        const sectionId = {
            organizationId: section.organizationId,
            menuId: section.menuId,
            sectionId: section.id,
        }
        return {
            id: sectionId,
            name: section.name,
            description: section.description,
            media: mapSectionMediaToModel(sectionId, section.media),
            entries: [],
        }
    })
}

export function mapEntryToModel(
    sectionId: Menu.Section.ID,
    extras: Map<number, Menu.Extra> | undefined,
    payload: MenuLocationRetrieve.Response.Entry
): Menu.Section.Entry {
    const entryId = { ...sectionId, entryId: payload.entryId }

    return {
        id: entryId,
        name: payload.name,
        description: payload.description,
        media: mapEntryMediaToModel(entryId, payload.cover),
        price: payload.price,
        allergens: mapAllergensToModel(payload.allergens),
        features: mapFeaturesToModel(payload.features),
        extras:
            extras === undefined
                ? []
                : payload.extrasIds
                      .map((id) => extras.get(id))
                      .filter(
                          (extra): extra is Menu.Extra => extra !== undefined
                      ),
    }
}

function mapEntriesToModel(
    sectionId: Menu.Section.ID,
    extras: Map<number, Menu.Extra>,
    payload: MenuLocationRetrieve.Response.Entry[]
): Menu.Section.Entry[] {
    return payload.map((entry) => {
        return mapEntryToModel(sectionId, extras, entry)
    })
}

export function mapAllergensToModel(
    payload: number
): Menu.Section.Entry.Allergen[] {
    const allergens: Menu.Section.Entry.Allergen[] = []

    for (const key in Menu.Section.Entry.Allergen) {
        const value = Menu.Section.Entry.Allergen[key]
        if (
            typeof value === 'number' &&
            value !== 0 &&
            (payload & value) === value
        ) {
            allergens.push(value)
        }
    }

    return allergens
}

export function mapFeaturesToModel(
    payload: number
): Menu.Section.Entry.Feature[] {
    const features: Menu.Section.Entry.Feature[] = []

    for (const key in Menu.Section.Entry.Feature) {
        const value = Menu.Section.Entry.Feature[key]
        if (
            typeof value === 'number' &&
            value !== 0 &&
            (payload & value) === value
        ) {
            features.push(value)
        }
    }

    return features
}

export function mapMenuMediaToModel(
    id: Menu.ID,
    payload?: RawMedia | MenuList.Response.MediaSource
): Media | undefined {
    if (!payload) {
        return undefined
    } else if ('id' in payload) {
        return new Media(
            payload.id,
            `O/${id.organizationId}/M/${id.menuId}/${payload.id}.${payload.extension}`
        )
    } else {
        return new Media(
            payload.source,
            `O/${id.organizationId}/M/${id.menuId}/${payload.source}.${payload.format}`
        )
    }
}

export function mapSectionMediaToModel(
    id: Menu.Section.ID,
    payload?: RawMedia | MenuList.Response.MediaSource
): Media | undefined {
    if (!payload) {
        return undefined
    } else if ('id' in payload) {
        return new Media(
            payload.id,
            `O/${id.organizationId}/M/${id.menuId}/S/${id.sectionId}/${payload.id}.${payload.extension}`
        )
    } else {
        return new Media(
            payload.source,
            `O/${id.organizationId}/M/${id.menuId}/S/${id.sectionId}/${payload.source}.${payload.format}`
        )
    }
}

export function mapEntryMediaToModel(
    id: Menu.Section.Entry.ID,
    payload?: RawMedia | MenuList.Response.MediaSource
): Media | undefined {
    if (!payload) {
        return undefined
    } else if ('id' in payload) {
        return new Media(
            payload.id,
            `O/${id.organizationId}/M/${id.menuId}/S/${id.sectionId}/E/${id.entryId}/${payload.id}.${payload.extension}`
        )
    } else {
        return new Media(
            payload.source,
            `O/${id.organizationId}/M/${id.menuId}/S/${id.sectionId}/E/${id.entryId}/${payload.source}.${payload.format}`
        )
    }
}
