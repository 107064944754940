import { arrayMoveImmutable } from 'array-move'
import { LocationDependencies } from 'features/location/dependencies'
import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { Media } from '../../media/models/Media'

interface UseGalleryFormDataHook {
    hasChanges: boolean
    images: Media[]
    isUploading: boolean
    setIsUploading: Dispatch<SetStateAction<boolean>>
    rearrange: (oldIndex: number, newIndex: number) => void
    remove: (index: number) => void
    restore: () => void
}

export function useGalleryFormData(medias: Media[]): UseGalleryFormDataHook {
    const [images, setImages] = useState(medias)
    const [isUploading, setIsUploading] = useState(false)

    const hasChanges = useMemo(() => {
        return !_.isEqual(images, medias)
    }, [images, medias])

    useEffect(() => {
        if (images.length === medias.length - 1) {
            setImages((previous) => [...previous, medias[medias.length - 1]])
        }
    }, [medias]) // eslint-disable-line react-hooks/exhaustive-deps

    const rearrange = useCallback((oldIndex: number, newIndex: number) => {
        setImages((previous) =>
            arrayMoveImmutable(previous, oldIndex, newIndex)
        )
    }, [])

    const remove = useCallback((index: number) => {
        setImages((previous) => [
            ...previous.slice(0, index),
            ...previous.slice(index + 1),
        ])
    }, [])

    return {
        hasChanges,
        images,
        isUploading,
        setIsUploading,
        rearrange,
        remove,
        restore() {
            setImages(medias)
        },
    }
}

export function useGalleryFormController(
    location: Location,
    params: UseGalleryFormDataHook,
    onSave: (location: Location) => void
): UseFormControllerHook<void> {
    const update = useInjection(LocationDependencies.Update)

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        console.log(params.images)
        if (!params.hasChanges) return false
        if (params.isUploading) return false
        if (params.images.length === 0 && location.medias.length > 0)
            return false
        return true
    }, [params])

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const mediasIds = params.images.map((media) => media.id)
            const newLocation = await update.run({ location, mediasIds })
            onSave(newLocation)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
