import Avokado, { AvokadoAPIError, PayoutList } from '@avokadoapp/avokado-ts'
import { addDays, endOfMonth, startOfMonth } from 'date-fns'
import { mapPayoutsToModel } from 'features/finance/mapper/payout/SDKResponseMapper'
import { Payout } from 'features/finance/models/Payout'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface ListPayoutParams extends Organization.ID {
    month: Date
}

export interface ListPayoutAction {
    run(params: ListPayoutParams): Promise<Payout[]>
}

@injectable()
export class ListPayoutActionLive implements ListPayoutAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ListPayoutParams): Promise<Payout[]> {
        const request: PayoutList.Request = {
            organizationId: params.organizationId,
            start: startOfMonth(params.month),
            end: endOfMonth(params.month),
        }
        const response = await this.avokado.payout.list(request)
        return mapPayoutsToModel(response)
    }
}

@injectable()
export class ListPayoutActionFake implements ListPayoutAction {
    async run(params: ListPayoutParams): Promise<Payout[]> {
        await sleep(500)

        return [
            {
                id: {
                    organizationId: params.organizationId,
                    payoutId: '3',
                    stripeId: 'po_3QwXeRTYuYJcddNNOLA7s7s4',
                    globalTraceId: undefined,
                },
                amount: 140000,
                currency: 'eur',
                status: 'pending',
                executionDate: addDays(startOfMonth(params.month), 14),
                expectedArrivalDate: addDays(startOfMonth(params.month), 16),
                destination: {
                    name: 'Credit Suisse',
                    last4: '1234',
                    type: 'bankAccount',
                },
            },
            {
                id: {
                    organizationId: params.organizationId,
                    payoutId: '2',
                    stripeId: 'po_2QwXeRTYuYJcddNNOLA7s7s4',
                    globalTraceId: undefined,
                },
                amount: 14000,
                currency: 'eur',
                status: 'failed',
                executionDate: addDays(startOfMonth(params.month), 7),
                expectedArrivalDate: addDays(startOfMonth(params.month), 9),
                destination: {
                    name: 'Raiffeisen',
                    last4: '1234',
                    type: 'bankAccount',
                },
            },
            {
                id: {
                    organizationId: params.organizationId,
                    payoutId: '1',
                    stripeId: 'po_1QwXeRTYuYJcddNNOLA7s7s4',
                    globalTraceId: undefined,
                },
                amount: 10000,
                currency: 'eur',
                status: 'paid',
                executionDate: addDays(startOfMonth(params.month), 0),
                expectedArrivalDate: addDays(startOfMonth(params.month), 2),
                destination: {
                    name: 'CASSA DI RISPARMIO DI BOLZANO S.P.A.',
                    last4: '1234',
                    type: 'bankAccount',
                },
            },
        ]
    }
}

@injectable()
export class ListPayoutActionFail implements ListPayoutAction {
    async run(params: ListPayoutParams): Promise<Payout[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
