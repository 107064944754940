import Avokado, {
    AvokadoAPIError,
    MenuExtraUpdate,
} from '@avokadoapp/avokado-ts'
import { mapExtraToModel } from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface UpdateMenuExtraParams extends Menu.Extra.ID {}

export interface UpdateMenuExtraAction {
    run(params: UpdateMenuExtraParams): Promise<Menu.Extra>
}

@injectable()
export class UpdateMenuExtraActionLive implements UpdateMenuExtraAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateMenuExtraParams): Promise<Menu.Extra> {
        const request: MenuExtraUpdate.Request = {
            organizationId: params.organizationId,
            menuId: params.menuId,
            menuExtraId: params.extraId,
        }

        const response = await this.avokado.menuExtra.update(request)
        return mapExtraToModel(
            { organizationId: params.organizationId, menuId: params.menuId },
            response
        )
    }
}

@injectable()
export class UpdateMenuExtraActionFake implements UpdateMenuExtraAction {
    async run(params: UpdateMenuExtraParams): Promise<Menu.Extra> {
        await sleep(500)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateMenuExtraActionFail implements UpdateMenuExtraAction {
    async run(params: UpdateMenuExtraParams): Promise<Menu.Extra> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
