import { Menu } from '../models/Menu'
import { TranslationObject } from '../models/TranslationObject'
import { TranslationPreview } from '../models/TranslationPreview'

export function buildPreview(
    menu: Menu,
    translationObjects: TranslationObject[],
    locale: string
): TranslationPreview {
    const map = new TranslationObjectsMap(
        menu.id.organizationId,
        translationObjects
    )
    const languageCode = locale

    const menuId = menu.id.menuId

    return {
        name: map.get(`TR_${languageCode}_M${menuId}_-n`, menu.name, {
            type: 'menuName',
            menuId,
        }),
        footer: map.getIfSourceExists(
            `TR_${languageCode}_M${menuId}_-f`,
            menu.footer,
            {
                type: 'menuFooter',
                menuId,
            }
        ),
        description: map.getIfSourceExists(
            `TR_${languageCode}_M${menuId}_-d`,
            menu.description,
            {
                type: 'menuDescription',
                menuId,
            }
        ),
        sections: menu.sections.map((section) => {
            const menuSectionId = section.id.sectionId
            return {
                name: map.get(
                    `TR_${languageCode}_M${menuId}_S${menuSectionId}_-n`,
                    section.name,
                    {
                        type: 'menuSectionName',
                        menuId,
                        menuSectionId,
                    }
                ),
                description: map.getIfSourceExists(
                    `TR_${languageCode}_M${menuId}_S${menuSectionId}_-d`,
                    section.description,
                    {
                        type: 'menuSectionDescription',
                        menuId,
                        menuSectionId,
                    }
                ),
                entries: section.entries.map((entry) => {
                    const menuEntryId = entry.id.entryId
                    return {
                        name: map.get(
                            `TR_${languageCode}_M${menuId}_S${menuSectionId}_E${menuEntryId}_-n`,
                            entry.name,
                            {
                                type: 'menuEntryName',
                                menuId,
                                menuSectionId,
                                menuEntryId,
                            }
                        ),
                        description: map.getIfSourceExists(
                            `TR_${languageCode}_M${menuId}_S${menuSectionId}_E${menuEntryId}_-d`,
                            entry.description,
                            {
                                type: 'menuEntryDescription',
                                menuId,
                                menuSectionId,
                                menuEntryId,
                            }
                        ),
                    }
                }),
            }
        }),
        extras: Array.from(menu.extras.values()).map((extra) => {
            const menuExtraId = extra.id.extraId
            return {
                name: map.get(
                    `TR_${languageCode}_M${menuId}_X${String(
                        menuExtraId
                    ).padStart(5, '0')}_-n`,
                    extra.name,
                    {
                        type: 'menuExtraName',
                        menuId,
                        menuExtraId,
                    }
                ),
                options: extra.options.map((option) => {
                    const menuOptionId = option.id.optionId
                    return {
                        name: map.get(
                            `TR_${languageCode}_M${menuId}_X${String(
                                menuExtraId
                            ).padStart(5, '0')}_O${menuOptionId}_-n`,
                            option.name,
                            {
                                type: 'menuOptionName',
                                menuId,
                                menuExtraId,
                                menuOptionId,
                            }
                        ),
                    }
                }),
            }
        }),
    }
}

class TranslationObjectsMap {
    private _organizationId: string
    private _storage: Map<String, TranslationObject>
    constructor(
        organizationId: string,
        translationObjects: TranslationObject[]
    ) {
        this._organizationId = organizationId
        this._storage = new Map()
        translationObjects.forEach((obj) =>
            this._storage.set(obj.id.translationObjectId, obj)
        )
    }

    public get(
        translationObjectId: string,
        source: string,
        resource: TranslationObject.TranslatedResource
    ): TranslationObject {
        return (
            this._storage.get(translationObjectId) ?? {
                id: {
                    organizationId: this._organizationId,
                    translationObjectId,
                },
                resource,
                source: source,
                translation: undefined,
                mode: undefined,
            }
        )
    }

    public getIfSourceExists(
        translationObjectId: string,
        source: string | undefined,
        resource: TranslationObject.TranslatedResource
    ): TranslationObject | undefined {
        if (source === undefined || source === '') return undefined

        return (
            this._storage.get(translationObjectId) ?? {
                id: {
                    organizationId: this._organizationId,
                    translationObjectId,
                },
                resource,
                source: source,
                translation: undefined,
                mode: undefined,
            }
        )
    }
}
