import Avokado, {
    AvokadoAPIError,
    UserOneTimePasswordCreate,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import Constants from 'utils/constant/Constants'
import { Provision } from '../models/Provision'

interface ProvisionTerminalParams {
    userId: string
}

export interface ProvisionTerminalAction {
    run(params: ProvisionTerminalParams): Promise<Provision>
}

@injectable()
export class ProvisionTerminalActionLive implements ProvisionTerminalAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ProvisionTerminalParams): Promise<Provision> {
        const request: UserOneTimePasswordCreate.Request = params
        const response = await this.avokado.user.oneTimePasswordCreate(request)
        return {
            code: `https://app.${Constants.Host}/provision/clip?uid=${params.userId}&otp=${response.otpCode}`,
            expiration: response.expiration,
        }
    }
}

@injectable()
export class ProvisionTerminalActionFake implements ProvisionTerminalAction {
    async run(params: ProvisionTerminalParams): Promise<Provision> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ProvisionTerminalActionFail implements ProvisionTerminalAction {
    async run(params: ProvisionTerminalParams): Promise<Provision> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
