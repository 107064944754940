import { Organization } from 'features/organization/models/Organization'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { PayoutDependencies } from '../dependencies/payout'
import { PayoutPreferences } from '../models/PayoutPreferences'

interface UsePayoutPreferencesFormDataHook {
    hasChanges: boolean
    interval: number
    setInterval: Dispatch<SetStateAction<number>>
}

export function usePayoutPreferencesFormData(
    preferences: PayoutPreferences
): UsePayoutPreferencesFormDataHook {
    const [interval, setInterval] = useState(preferences.interval)

    const hasChanges = useMemo(() => {
        return !_.isEqual(interval, preferences.interval)
    }, [interval, preferences.interval])

    return { hasChanges, interval, setInterval }
}

export function usePayoutPreferencesFormController(
    organization: Organization,
    params: UsePayoutPreferencesFormDataHook,
    onSave: (payout: PayoutPreferences) => void
): UseFormControllerHook<void> {
    const updatePreferences = useInjection(PayoutDependencies.UpdatePreferences)

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        if (!params.hasChanges) return false
        if (params.interval < 1 || params.interval > 14) return false
        return true
    }, [params])

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const updated = await updatePreferences.run({
                ...organization.id,
                interval: params.interval,
            })
            onSave(updated)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
