import {
    Box,
    Button,
    Circle,
    Heading,
    Icon,
    Portal,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { FaRegBuilding, FaRegUser } from 'react-icons/fa'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedAction'
import { UseNewOrganizationFormDataHook } from '../hooks/useNewOrganizationForm'

export function NewOrganizationStructureStep({
    headerRef,
    structure: formData,
}: UseNewOrganizationFormDataHook & StepPropsWithRef) {
    const t = useT('organization')

    return (
        <>
            <Portal containerRef={headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {t('new_step_1_structure_title')}
                    </Heading>
                    <Text>{t('new_step_1_structure_description')}</Text>
                </VStack>
            </Portal>

            <VStack
                align="stretch"
                gap={4}
                w="full"
                flex={1}
                justifyContent="space-between"
            >
                <StructureCard
                    structure="individual"
                    onClick={formData.setStructure}
                />

                <StructureCard
                    structure="company"
                    onClick={formData.setStructure}
                />
            </VStack>
        </>
    )
}

interface StructureCardProps {
    structure: 'company' | 'individual'
    onClick: (structure: 'company' | 'individual') => void
}

function StructureCard({ structure, onClick }: StructureCardProps) {
    const t = useT('organization')

    const [outer, inner] = useColorModeValue([0.1, 0.2], [0.05, 0.1])

    return (
        <VStack
            borderWidth={1}
            borderRadius="2xl"
            borderColor="pageBackground3"
            boxShadow="md"
            overflow="hidden"
            transition="all .5s"
            _hover={{ transform: 'scale(1.05)' }}
            _focus={{ transform: 'scale(1.05)' }}
        >
            <VStack p={5} flex={1}>
                <Box position="relative" boxSize="40px">
                    <Circle
                        position="absolute"
                        bg="primary.300"
                        size="40px"
                        opacity={outer}
                    />
                    <Circle
                        position="absolute"
                        bg="primary.300"
                        size="30px"
                        top="5px"
                        left="5px"
                        opacity={inner}
                    />
                    <Icon
                        position="absolute"
                        as={structure === 'company' ? FaRegBuilding : FaRegUser}
                        boxSize={4}
                        top={3}
                        left={3}
                        color="primary.300"
                    />
                </Box>
                <Text color="accent" fontWeight="semibold" fontSize="xl">
                    {t(`${structure}_account_card_title`)}
                </Text>
                <Text align="center">
                    {t(`${structure}_account_card_description`)}
                </Text>
            </VStack>
            <VStack w="full" bg="pageBackground3" p={5}>
                <Button w="full" onClick={() => onClick(structure)}>
                    {t('get_started_button')}
                </Button>
            </VStack>
        </VStack>
    )
}
