import {
    Button,
    HStack,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Spacer,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

interface NextStepCardProps extends PropsWithChildren {
    title: string
    description: string
    image: string
    imageFit: 'cover' | 'contain'
}

export function NextStepCard({ description, ...props }: NextStepCardProps) {
    const peekDescription = useMemo(
        () => description.split('\n')[0].trim(),
        [description]
    )

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <NextStepCardContent
                isModal={false}
                description={peekDescription}
                onOpen={onOpen}
                onClose={onClose}
                {...props}
            />

            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent
                    borderRadius="2xl"
                    sx={{
                        margin: 'auto',
                    }}
                >
                    <ModalBody p={0}>
                        <NextStepCardContent
                            isModal
                            description={description}
                            onClose={onClose}
                            {...props}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

interface NextStepCardContentProps extends NextStepCardProps {
    isModal: boolean
    onOpen?: () => void
    onClose?: () => void
}

export function NextStepCardContent({
    title,
    description,
    image,
    imageFit,
    isModal,
    children,
    onOpen,
    onClose,
}: NextStepCardContentProps) {
    const { t } = useTranslation()
    const screen = useScreenBreakpoint()

    const CustomStack = isModal || screen === 'mobile' ? VStack : HStack

    return (
        <CustomStack
            as={motion.div}
            spacing={0}
            borderWidth={1}
            borderRadius="2xl"
            bg="pageBackground3"
            borderColor="pageBackground3"
            boxShadow="md"
            overflow="hidden"
            align="stretch"
            whileHover={{ scale: isModal ? 1 : 1.02 }}
            whileFocus={{ scale: isModal ? 1 : 1.02 }}
            onClick={onOpen}
        >
            <Image
                draggable={false}
                bg="pageBackground2"
                w={isModal || screen === 'mobile' ? 'full' : '10rem'}
                h={isModal || screen === 'mobile' ? 240 : undefined}
                aspectRatio={isModal ? undefined : 1}
                objectFit={imageFit}
                src={image}
            />

            <VStack w="full" align="stretch" spacing={0} p={4}>
                <VStack align="stretch" w="full" flex={1} spacing={0}>
                    <Heading fontSize="lg" fontWeight="semibold">
                        {title}
                    </Heading>

                    <Text fontSize="sm" whiteSpace="pre-line" color="label2">
                        {description}
                    </Text>
                </VStack>

                {isModal ? (
                    <Spacer py={2} />
                ) : (
                    <Button
                        display="flex"
                        alignItems="center"
                        alignSelf="end"
                        variant="unstyled"
                        size="sm"
                        color="label1"
                        _hover={{ color: 'accent' }}
                        onClick={onOpen}
                    >
                        {t('See more')}
                    </Button>
                )}

                <HStack w="full" justifyContent="end">
                    {isModal && (
                        <Button variant="outline" onClick={onClose}>
                            {t('Later')}
                        </Button>
                    )}
                    {children}
                </HStack>
            </VStack>
        </CustomStack>
    )
}
