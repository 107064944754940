import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    UseDisclosureReturn,
} from '@chakra-ui/react'
import { Location } from 'features/location/models/Location'
import { MenuAssignment } from 'features/menuassignment/models/MenuAssignment'
import useT from 'localization/hooks/useT'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { GuidedAction, Step } from 'utils/guidedaction'
import ActivateAssignmentStep from './Steps/ActivateAssignmentStep'
import {
    CreateMenuAssignmentController,
    useCreateMenuAssignmentController,
} from './Steps/Controller'
import SelectMenuStep from './Steps/SelectMenuStep'

const steps: Step<CreateMenuAssignmentController>[] = [
    {
        title: 'new_step_1_select_menu_preview_title',
        description: 'new_step_1_select_menu_preview_description',
        content: (props) => (
            <SelectMenuStep {...props.context} headerRef={props.headerRef} />
        ),
    },
    {
        title: 'new_step_2_activate_preview_title',
        description: 'new_step_2_activate_preview_description',
        content: (props) => (
            <ActivateAssignmentStep
                {...props.context}
                headerRef={props.headerRef}
            />
        ),
    },
]

interface MenuAssignmentCreationModalProps {
    newMenuAssignmentModal: UseDisclosureReturn
    location: Location
    onCreated: (m: MenuAssignment) => void
}

export default function MenuAssignmentCreationModal({
    newMenuAssignmentModal,
    location,
    onCreated,
}: MenuAssignmentCreationModalProps) {
    const controller = useCreateMenuAssignmentController(location, onCreated)
    const t = useT('menuassignment')
    const screen = useScreenBreakpoint()

    return (
        <Modal
            isCentered
            onClose={newMenuAssignmentModal.onClose}
            isOpen={newMenuAssignmentModal.isOpen}
            size="full"
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent
                bg="pageBackground1"
                pt={screen !== 'desktop' ? 12 : undefined}
            >
                <ModalCloseButton />
                <ModalBody>
                    {/* <MenuPicker
                        menus={menusManager.menus}
                        onMenuSelected={console.log}
                    /> */}
                    <GuidedAction
                        namespace="menuassignment"
                        isDisabled={false}
                        isProcessing={
                            controller.extendedMenu === undefined ||
                            controller.isCreating
                        }
                        context={controller}
                        steps={steps}
                        index={controller.activeStep}
                        hideControls={controller.activeStep !== 1}
                        hideSkip
                        completion={{
                            handler: () => {
                                newMenuAssignmentModal.onClose()
                                controller.reset()
                            },
                            label: t('menu_assignment_success_button'),
                        }}
                        onNext={() => {
                            controller.create()
                        }}
                        onBack={() =>
                            controller.setSelectedCompactMenu(undefined)
                        }
                        onSkip={() => {}}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
