import _ from 'lodash'
import { Menu } from '../models/Menu'

export function mapModelToSectionsIds(
    sections: Menu.Section[] | undefined
): number[] | undefined {
    if (sections === undefined) {
        return undefined
    }

    return sections.map((section) => section.id.sectionId)
}

export function mapModelToEntriesIds(
    entries: Menu.Section.Entry[] | undefined
): number[] | undefined {
    if (entries === undefined) {
        return undefined
    }

    return entries.map((entry) => entry.id.entryId)
}

export function updateAllergensBatch(
    entries: Menu.Section.Entry[],
    allergens: Map<Menu.Section.Entry.Allergen, boolean>
) {
    const partitions = _.partition(
        Array.from(allergens.entries()),
        ([_, status]) => status
    )

    const [added, removed] = partitions.map((partition) =>
        partition.map(([allergen, _]) => allergen)
    )

    return entries.map((entry) => {
        const modifiedAllergens = entry.allergens
            .filter(
                (allergen) =>
                    !removed.includes(allergen) && !added.includes(allergen)
            )
            .concat(added)
            .sort((a, b) => a - b)
        return { ...entry, allergens: modifiedAllergens }
    })
}

export function updateFeaturesBatch(
    entries: Menu.Section.Entry[],
    features: Map<Menu.Section.Entry.Feature, boolean>
) {
    const partitions = _.partition(
        Array.from(features.entries()),
        ([_, status]) => status
    )

    const [added, removed] = partitions.map((partition) =>
        partition.map(([feature, _]) => feature)
    )

    return entries.map((entry) => {
        const modifiedFeatures = entry.features
            .filter(
                (feature) =>
                    !removed.includes(feature) && !added.includes(feature)
            )
            .concat(added)
            .sort((a, b) => a - b)
        return { ...entry, features: modifiedFeatures }
    })
}
