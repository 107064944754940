import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useAuthContext from '../contexts/AuthContext'
import useAuthProxyContext from '../contexts/AuthProxyContext'

interface UseConfirmSignUpFormDataHook {
    confirmationCode: string
    setConfirmationCode: Dispatch<SetStateAction<string>>
}

export function useConfirmSignUpFormData(): UseConfirmSignUpFormDataHook {
    const [confirmationCode, setConfirmationCode] = useState('')

    return { confirmationCode, setConfirmationCode }
}

export function useConfirmSignUpFormController(
    params: UseConfirmSignUpFormDataHook
): UseFormControllerHook<void> {
    const { email, password } = useAuthProxyContext()
    const { confirmSignUp } = useAuthContext()

    const navigate = useNavigate()

    const [isProcessing, setIsProcessing] = useState(false)

    useEffect(() => {
        if (!email || !password) navigate('/sign-in')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const canSubmit = useMemo(() => {
        if (params.confirmationCode.length < 6) return false
        return true
    }, [params.confirmationCode])

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            await confirmSignUp(params.confirmationCode)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
