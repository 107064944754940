import Avokado, {
    AvokadoAPIError,
    LocationUpdate,
} from '@avokadoapp/avokado-ts'
import { Address } from 'features/address/models/Address'
import { mapModelToCalendar } from 'features/location/mappers/timeschedule/SDKRequestMapper'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import _ from 'lodash'
import { sleep } from 'utils/asyncawait/sleep'
import takeIf from 'utils/takeif'
import { mapLocationToModel } from '../mappers/SDKResponseMapper'
import { mapModelToCapabilities } from '../mappers/capability/SDKRequestMapper'
import { mapModelToSubcategories } from '../mappers/category/SDKRequestMapper'
import { Location } from '../models/Location'

interface UpdateLocationParams {
    location: Location
    name?: string
    mediasIds?: string[]
    policy?: Location.Policy
    calendar?: Location.Calendar
    category?: Location.Category
    subcategories?: Location.Category[]
    capacity?: number
    capabilities?: Location.Capability[]
    address?: Address
}

export interface UpdateLocationAction {
    run(params: UpdateLocationParams): Promise<Location>
}

@injectable()
export class UpdateLocationActionLive implements UpdateLocationAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateLocationParams): Promise<Location> {
        // TODO: send only changed fields (see UpdateMenuAction)
        const { location } = params
        const request: LocationUpdate.Request = {
            organizationId: location.id.organizationId,
            locationId: location.id.locationId,
            name: takeIf(
                params.name,
                (value) => !_.isEqual(value, location.name)
            ),
            mediasIds: takeIf(
                params.mediasIds,
                (value) =>
                    !_.isEqual(
                        value,
                        location.medias.map((media) => media.id)
                    )
            ),
            policy: takeIf(
                params.policy,
                (value) => !_.isEqual(value, location.policy)
            ),
            calendar: mapModelToCalendar(
                takeIf(
                    params.calendar,
                    (value) => !_.isEqual(value, location.calendar)
                )
            ),
            category: takeIf(
                params.category,
                (value) => !_.isEqual(value, location.category)
            ),
            subcategories: takeIf(
                mapModelToSubcategories(params.subcategories),
                (value) =>
                    !_.isEqual(
                        value,
                        mapModelToSubcategories(location.subcategories)
                    )
            ),
            capacity: takeIf(
                params.capacity,
                (value) => !_.isEqual(value, location.capacity)
            ),
            capabilities: takeIf(
                mapModelToCapabilities(params.capabilities),
                (value) =>
                    !_.isEqual(
                        value,
                        mapModelToCapabilities(location.capabilities)
                    )
            ),
        }

        const response = await this.avokado.location.update(request)
        return mapLocationToModel(response, location.menuAssignments)
    }
}

@injectable()
export class UpdateLocationActionFake implements UpdateLocationAction {
    async run(params: UpdateLocationParams): Promise<Location> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateLocationActionFail implements UpdateLocationAction {
    async run(params: UpdateLocationParams): Promise<Location> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
