import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import axios from 'axios'
import {
    ConfigurationDependencies,
    ConfigurationStore,
} from 'features/config/dependencies'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Cognito, UserAttribute } from '../models/CognitoModels'

interface SignUpParams {
    email: string
    password: string
    locale: string
    zoneInfo: string
    givenName?: string
    familyName?: string
}

export interface SignUpAction {
    run(params: SignUpParams): Promise<void>
}

@injectable()
export class SignUpActionLive implements SignUpAction {
    @inject(ConfigurationDependencies.Store) private config!: ConfigurationStore

    async run(params: SignUpParams): Promise<void> {
        const configuration = await this.config.configuration()

        const userAttributes: UserAttribute[] = [
            Cognito.UserAttribute.locale(params.locale),
            Cognito.UserAttribute.zoneInfo(params.zoneInfo),
        ]

        if (params.givenName) {
            userAttributes.push(
                Cognito.UserAttribute.givenName(params.givenName)
            )
        }

        if (params.familyName) {
            userAttributes.push(
                Cognito.UserAttribute.familyName(params.familyName)
            )
        }

        const request: Cognito.Request.SignUp = {
            Username: params.email,
            Password: params.password,
            UserAttributes: userAttributes,
            ClientId: configuration.aws.userPool.dashboardClientId,
        }

        await axios.post(
            `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
            request,
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target': 'AWSCognitoIdentityProviderService.SignUp',
                },
            }
        )
    }
}

@injectable()
export class SignUpActionFake implements SignUpAction {
    async run(params: SignUpParams): Promise<void> {
        await sleep(500)
    }
}

@injectable()
export class SignUpActionFail implements SignUpAction {
    async run(params: SignUpParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
