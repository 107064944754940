import {
    Avatar,
    Box,
    Checkbox,
    HStack,
    Heading,
    Portal,
    Text,
    VStack,
} from '@chakra-ui/react'
import useLocationsContext from 'features/location/contexts/LocationsContext'
import { UseNewMenuAssignStepFormDataHook } from 'features/menu/hooks/new/useNewMenuAssignStepFormData'
import { UseNewMenuFormDataHook } from 'features/menu/hooks/new/useNewMenuForm'
import useT from 'localization/hooks/useT'
import { FaQuestion } from 'react-icons/fa'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedActionDesktop'

export function NewMenuAssignStep({
    headerRef,
    assign: formData,
}: UseNewMenuFormDataHook & StepPropsWithRef) {
    const t = useT('menu')

    const locationsManager = useLocationsContext()

    return (
        <>
            <Portal containerRef={headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {locationsManager.locations.length === 0
                            ? t('new_step_5_assign_title_no_locations')
                            : t('new_step_5_assign_title')}
                    </Heading>
                    <Text>
                        {locationsManager.locations.length === 0
                            ? t('new_step_5_assign_description_no_locations')
                            : t('new_step_5_assign_description')}
                    </Text>
                </VStack>
            </Portal>

            <NewMenuAssignStepContent formData={formData} />
        </>
    )
}

interface NewMenuAssignStepContentProps {
    formData: UseNewMenuAssignStepFormDataHook
}

function NewMenuAssignStepContent({ formData }: NewMenuAssignStepContentProps) {
    const t = useT('menu')

    const locationsManager = useLocationsContext()

    if (locationsManager.locations.length === 0) {
        return <Text>{t('new_step_5_assign_message_no_locations')}</Text>
    }

    return (
        <VStack w="full" align="stretch">
            {locationsManager.locations.map((location) => (
                <Box
                    key={location.id.locationId}
                    p={2}
                    cursor="pointer"
                    bg="pageBackground3"
                    borderRadius="full"
                    boxShadow="base"
                    onClick={() => formData.toggle(location.id)}
                >
                    <HStack gap={4}>
                        <Avatar
                            src={location.medias[0]?.small()}
                            icon={<FaQuestion />}
                            bg="pageBackground2"
                            color="label1"
                        />

                        <VStack flex={1} align="stretch" spacing={0}>
                            <Heading size="md">{location.name}</Heading>
                            <Text color="label2">
                                {t(
                                    formData.selectedLocationsIds.has(
                                        location.id
                                    )
                                        ? 'new_step_5_assign_location_assigned_description'
                                        : 'new_step_5_assign_location_not_assigned_description',
                                    { location: location.name }
                                )}
                            </Text>
                        </VStack>

                        <Checkbox
                            bg="white"
                            borderRadius="sm"
                            size="lg"
                            me={4}
                            isChecked={formData.selectedLocationsIds.has(
                                location.id
                            )}
                            onChange={() => formData.toggle(location.id)}
                        />
                    </HStack>
                </Box>
            ))}
        </VStack>
    )
}
