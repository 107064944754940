import { SVGProps } from 'react'

export function NutsIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Nuts"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M199.99 101.26c.01.55 0 1.11-.03 1.66-1.81 29.4-13.13 56.43-39.7 76.23-31.51 23.47-66.56 26.96-102.25 11.29-22.68-9.96-39.52-27.17-49.23-49.88-12.15-28.43-11.62-56.67 1.16-84.58 6.02-13.15 15.26-24.29 26.81-33.8C53.81 8.12 73.6.88 95.91.08c26.23-.95 49.43 6.68 69.03 23.64 23.47 20.31 34.34 47.22 35.06 77.55zm-49.22-7.32c8.29.04 9.9-1.92 9.08-9.08-1.5-13.17-7.16-19.11-22.72-23.22-3.56-.94-6.92-2.01-9.85-3.68-5.75-3.28-14.95-3.7-20.54-.17-2.68 1.69-5.83 2.78-9.4 3.49-8.76 1.75-16.7 5.19-21.23 13.28-1 1.78-2.77 2.73-4.79 2.77-4.66.09-8.56 2.06-12.61 3.94-21.72 10.09-25.79 38.75-7.9 54.56 4.82 4.26 11.62 4.66 16.32 8.23 7.72 5.87 13.98 6.46 21.58-.19 3.1-2.71 8.52-4.52 12.85-.68 3.96 3.51 9.66 3.96 13.57 7.48 1.18 1.06 2.47.53 3.67-.38 4.27-3.2 9.35-5.19 14-7.76 14.35-7.91 21.75-19.61 24.13-34.74 1.12-7.11.57-7.84-6.82-7.88-10.14-.05-20.27-.05-30.41-.05-2.68 0-4.9-1.03-4.99-3.62-.11-3.33 3.01-2.03 4.83-2.21" />
            <path d="M45.41 108.29c-.25-9.2 3.81-16.02 11.95-20.25 14.33-7.45 28.64-6.83 42.61 1 6.13 3.44 9.24 9.1 9.7 15.9 1.14 16.9-3.68 30.18-20.99 34.8-3.21.86-6.52 1.69-9.19 3.85-1.38 1.12-2.56 1.21-3.86-.14-1.89-1.96-4.53-2.52-7.02-3.19-16.17-4.35-21.9-12.07-23.21-31.96zm41.41.85c-.02-5.12-5.17-13.38-8.99-13.39-4.31-.01-9.89 9.15-9.49 15.39.44 6.8 4.72 11.8 8.04 17.26.68 1.12 1.65 1.5 2.57.13 3.78-5.63 8.15-11.02 7.87-19.39z" />
        </svg>
    )
}
