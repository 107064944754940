import { PricingPlanList } from '@avokadoapp/avokado-ts'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Badge,
    HStack,
    Heading,
    Link,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
} from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { DateDependencies } from 'infra/di/factories/Date'
import { useInjection } from 'inversify-react'
import useT from 'localization/hooks/useT'
import { Suspense, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Container } from 'uikit/container/Container'
import { LoadingIndicator } from 'utils/loading'
import { usePriceFormatter } from 'utils/types/formatters'
import usePricingPlans from '../hooks/usePricingPlans'
import { Currency } from '../models/Currency'

export default function PricingPlansTab() {
    return (
        <Suspense fallback={<LoadingIndicator />}>
            <PricingPlansSuspend />
        </Suspense>
    )
}

function PricingPlansSuspend() {
    const t = useT('pricingplan')
    const organizationId =
        useOrganizationContext().assertOrganization().id.organizationId
    const pricingPlans = usePricingPlans(organizationId)

    return (
        <VStack spacing={8}>
            {pricingPlans.pricingPlans.map((plan, index) => (
                <PricingPlanContainer
                    plan={plan}
                    key={plan.effectiveFrom.getTime()}
                    currency={pricingPlans.currency}
                    validTo={oneSecondBefore(
                        pricingPlans.pricingPlans[index + 1]?.effectiveFrom
                    )}
                />
            ))}
            <VStack maxW="6xl" align="start" gap={4}>
                <Text fontSize="sm">{t('international_cards_footer')}</Text>
            </VStack>
        </VStack>
    )
}

function PricingPlanContainer({
    plan,
    validTo,
    currency,
}: {
    plan: PricingPlanList.Response.PricingPlan
    validTo?: Date
    currency: Currency
}) {
    const { t, i18n } = useTranslation('pricingplan')
    const priceFormatter = usePriceFormatter(currency, 'standard')
    const date = useInjection(DateDependencies.Generator)

    const status = useMemo(() => {
        const now = date()
        if (
            now >= plan.effectiveFrom &&
            (validTo === undefined || now < validTo)
        ) {
            return 'active'
        }
        if (now < plan.effectiveFrom) {
            return 'coming-soon'
        } else {
            return 'expired'
        }
    }, [])

    return (
        <Container
            title={
                <HStack>
                    <Text>{plan.name}</Text>
                    <Badge
                        borderRadius="md"
                        fontSize="0.85em"
                        colorScheme={
                            status === 'active'
                                ? 'green'
                                : status === 'coming-soon'
                                ? 'blue'
                                : 'orange'
                        }
                    >
                        {t(`plan_status_${status.replaceAll('-', '_')}`)}
                    </Badge>
                </HStack>
            }
            subtitle={
                validTo === undefined
                    ? t('valid_from', {
                          fromDate: plan.effectiveFrom.toLocaleString(
                              i18n.language,
                              {
                                  dateStyle: 'long',
                                  timeStyle: 'short',
                              }
                          ),
                      })
                    : plan.effectiveFrom.getTime() < 1000
                    ? t('valid_until', {
                          toDate: validTo.toLocaleString(i18n.language, {
                              dateStyle: 'long',
                              timeStyle: 'short',
                          }),
                      })
                    : t('valid_from_until', {
                          fromDate: plan.effectiveFrom.toLocaleString(
                              i18n.language,
                              {
                                  dateStyle: 'long',
                                  timeStyle: 'short',
                              }
                          ),
                          toDate: validTo.toLocaleString(i18n.language, {
                              dateStyle: 'long',
                              timeStyle: 'short',
                          }),
                      })
            }
        >
            <VStack align="start">
                <Heading size="sm" mt={8}>
                    {t('orders_table_header')}
                </Heading>
                <Table>
                    <Tbody>
                        {plan.table.orders.map((order, index) => (
                            <OrderRow
                                order={order}
                                nextOrder={plan.table.orders[index + 1]}
                                key={order.inclusiveLowerBound}
                                formatCost={priceFormatter.format}
                            />
                        ))}
                    </Tbody>
                </Table>

                <Alert mt={8} borderRadius="lg">
                    <AlertIcon />
                    <AlertDescription>
                        <Trans
                            t={t}
                            i18nKey="stripe_disclaimer_footer"
                            components={[
                                <Link
                                    color="accent"
                                    fontWeight="bold"
                                    href="https://stripe.com/pricing"
                                    isExternal
                                />,
                                <Link
                                    color="accent"
                                    fontWeight="bold"
                                    href="https://stripe.com/connect/pricing"
                                    isExternal
                                />,
                            ]}
                        />
                    </AlertDescription>
                </Alert>

                <Heading size="sm" mt={8}>
                    {t('payment_table_header')}
                </Heading>
                <Table>
                    <Tbody>
                        <Tr>
                            <Td>
                                {t('fixed_cost_label')} {`(${t('in_app')})`}
                            </Td>
                            <Td isNumeric>
                                {priceFormatter.format(
                                    plan.payment.inApp.domestic.fixed
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                {t('volume_cost_label')} {`(${t('in_app')})`}
                            </Td>
                            <Td isNumeric>
                                {(
                                    (plan.payment.inApp.domestic.volume -
                                        (plan.payout.transferVolumeCommission ??
                                            0.0025)) *
                                    100
                                ).toFixed(2)}{' '}
                                %
                            </Td>
                        </Tr>

                        <Tr>
                            <Td>
                                {t('fixed_cost_label')} {`(${t('in_person')})`}
                            </Td>
                            <Td isNumeric>
                                {priceFormatter.format(
                                    plan.payment.inPerson.domestic.fixed
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                {t('volume_cost_label')} {`(${t('in_person')})`}
                            </Td>
                            <Td isNumeric>
                                {(
                                    (plan.payment.inApp.domestic.volume -
                                        (plan.payout.transferVolumeCommission ??
                                            0.0025)) *
                                    100
                                ).toFixed(2)}{' '}
                                %
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
                <Heading size="sm" mt={8}>
                    {t('payout_table_header')}
                </Heading>
                <Table>
                    <Tbody>
                        <Tr>
                            <Td>
                                {t('fixed_cost_label')} {`(${t('per_payout')})`}
                            </Td>
                            <Td isNumeric>
                                {priceFormatter.format(
                                    plan.payout.transferFixedCommission
                                )}
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                {t('volume_cost_label')}{' '}
                                {`(${t('per_payout')})`}
                            </Td>
                            <Td isNumeric>
                                {(
                                    plan.payout.transferVolumeCommission * 100
                                ).toFixed(2)}{' '}
                                %
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </VStack>
        </Container>
    )
}

interface OrderRowProps {
    order: PricingPlanList.Response.TableOrder
    nextOrder?: PricingPlanList.Response.TableOrder
    formatCost: (c: number) => string
}

function OrderRow({ order, nextOrder, formatCost }: OrderRowProps) {
    const t = useT('pricingplan')
    return (
        <Tr>
            <Td>
                {nextOrder === undefined
                    ? t('order_from', {
                          from: formatCost(order.inclusiveLowerBound),
                      })
                    : t('order_from_to', {
                          from: formatCost(order.inclusiveLowerBound),
                          to: formatCost(nextOrder.inclusiveLowerBound - 1),
                      })}
            </Td>

            <Td isNumeric>
                {order.commission === 0
                    ? t('free')
                    : formatCost(order.commission)}
            </Td>
        </Tr>
    )
}

function oneSecondBefore(date?: Date): Date | undefined {
    if (!date) {
        return undefined
    }

    return new Date(date.getTime() - 1000)
}
