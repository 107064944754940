import { useConst } from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { GuidedAction, Step } from 'utils/guidedaction'
import { NewLocationGeneralStep } from '../components/new'
import {
    UseNewLocationFormDataHook,
    useNewLocationFormController,
    useNewLocationFormData,
} from '../hooks/new/useNewLocationForm'

export const newLocationSteps: Step<UseNewLocationFormDataHook>[] = [
    {
        title: 'create_location_step_1_general_information_title',
        description: 'create_location_step_1_general_information_description',
        content: (props) => (
            <NewLocationGeneralStep
                headerRef={props.headerRef}
                {...props.context}
            />
        ),
    },
]

export default function NewLocationPage() {
    const organizationManager = useOrganizationContext()

    const formData = useNewLocationFormData()
    const controller = useNewLocationFormController(formData)

    const { organizationId } = useConst(() => {
        return organizationManager.assertOrganization().id
    })

    return (
        <GuidedAction
            context={{ ...formData }}
            steps={newLocationSteps}
            index={controller.activeStep}
            isProcessing={controller.isProcessing}
            isDisabled={!controller.canSubmit}
            hideControls={controller.hideControls}
            hideSkip={controller.hideSkip}
            completion={{
                modelLink: `/organizations/${organizationId}/locations/${controller.location?.id.locationId}`,
            }}
            onNext={controller.submit}
            onBack={controller.goBack}
            onSkip={controller.skip}
            namespace="location"
        />
    )
}
