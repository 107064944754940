import { TabPanel, TabPanels } from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Scaffold } from 'uikit/scaffold'
import { OrderHistoryTab } from '../components/OrderHistoryTab'
import { PayoutTab } from '../components/PayoutTab'
import PricingPlansTab from '../components/PricingPlansTab'

interface TabEntry {
    id: string
    name: string
    shouldPresentBadge: boolean
    content: () => JSX.Element
}

export default function FinancePage() {
    const t = useT('finance')

    const tabs: TabEntry[] = [
        {
            id: 'order-history',
            name: t('tab_order_history'),
            shouldPresentBadge: false,
            content: OrderHistoryTab,
        },
        {
            id: 'payouts',
            name: t('tab_payouts'),
            shouldPresentBadge: false,
            content: PayoutTab,
        },
        {
            id: 'pricing-plan',
            name: t('tab_pricing_plan'),
            shouldPresentBadge: false,
            content: PricingPlansTab,
        },
    ]

    return (
        <Scaffold tabs={tabs} title={t('finance_title')}>
            <TabPanels>
                {tabs.map((tab) => (
                    <TabPanel key={tab.name} p={0}>
                        <tab.content />
                    </TabPanel>
                ))}
            </TabPanels>
        </Scaffold>
    )
}
