import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useListFormatter() {
    const { t, i18n } = useTranslation()

    const formatter = useMemo(
        () => new Intl.ListFormat(i18n.language),
        [i18n.language]
    )

    const format = useCallback(
        (values: string[], options?: { translate: boolean }) => {
            return options?.translate
                ? formatter.format(values.map((value) => t(value)))
                : formatter.format(values)
        },
        [i18n.language]
    ) // eslint-disable-line react-hooks/exhaustive-deps

    return { format }
}
