import * as Sentry from '@sentry/react'
import 'index.css'
import { dependencyContainer } from 'infra/di/inversify.config'
import { Provider } from 'inversify-react'
import 'localization/i18n'
import * as ReactDOM from 'react-dom/client'
import Constants from 'utils/constant/Constants'
import App from './App'
import reportWebVitals from './reportWebVitals'

Sentry.init({
    dsn: 'https://b8dbbddd064952a7a48f1bcf34bf7fd5@o4506090703028224.ingest.sentry.io/4506104743002112',
    tunnel: '/sentry-tunnel',
    environment: Constants.SentryEnvironment,
    integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
    tracePropagationTargets: ['localhost', `https://api.${Constants.Host}`],

    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions

    // Session Replay
    replaysSessionSampleRate:
        Constants.SentryEnvironment === 'production' ? 1.0 : 0,
    replaysOnErrorSampleRate:
        Constants.SentryEnvironment === 'production' ? 1.0 : 0,
})

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

root.render(
    <Provider container={dependencyContainer}>
        <App />
    </Provider>
)

reportWebVitals()
