import { Button, ButtonGroup, Divider, HStack } from '@chakra-ui/react'
import {
    UseMenuContentFormControllerHook,
    UseMenuContentFormDataHook,
} from 'features/menu/hooks/useMenuContentForm'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import { AllergensTool } from './AllergensTool'
import { FeaturesTool } from './FeaturesTool'

export interface MenuToolbarProps {
    currentSection: Menu.Section
    formData: UseMenuContentFormDataHook
    controller: UseMenuContentFormControllerHook

    hideAllergens?: boolean
    hideFeatures?: boolean
    hideSort?: boolean
}

export function MenuToolbar({
    currentSection,
    formData,
    controller,
    hideAllergens,
    hideFeatures,
    hideSort,
}: MenuToolbarProps) {
    const t = useT('menu')

    return (
        <HStack w="full" justify="end">
            {!(hideAllergens && hideFeatures) && (
                <>
                    <ButtonGroup size="sm" variant="ghost">
                        {!hideAllergens && (
                            <AllergensTool
                                currentSection={currentSection}
                                formData={formData}
                                controller={controller}
                            />
                        )}
                        {!hideFeatures && (
                            <FeaturesTool
                                currentSection={currentSection}
                                formData={formData}
                                controller={controller}
                            />
                        )}
                    </ButtonGroup>

                    {!hideSort && <Divider orientation="vertical" h={8} />}
                </>
            )}

            {!hideSort && (
                <ButtonGroup size="sm" variant="ghost">
                    {formData.isRearranging ? (
                        <>
                            <Button
                                onClick={() => {
                                    formData.stopRearranging({
                                        shouldReset: true,
                                    })
                                }}
                            >
                                {t('generic_cancel_button')}
                            </Button>
                            <Button
                                onClick={controller.rearrange}
                                isDisabled={!controller.canRearrange}
                                isLoading={controller.isRearranging}
                            >
                                {t('generic_save_button')}
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={() => formData.startRearranging()}
                            isDisabled={controller.updatingEntriesIds.size > 0}
                        >
                            {t('generic_sort_button')}
                        </Button>
                    )}
                </ButtonGroup>
            )}
        </HStack>
    )
}
