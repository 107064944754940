import { VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { FadeToLeft } from 'utils/constant/Animation'
import TopBar, { TopBarContext } from '../topbar/TopBar'
import { TopBarItems } from '../topbar/TopBarItems'

export default function MainLayout() {
    const { pathname } = useLocation()
    const [prefersTopBarShadowHidden, setPrefersTopBarShadowHidden] =
        useState(false)

    return (
        <TopBarContext.Provider
            value={{
                prefersTopBarShadowHidden: (v) => {
                    setPrefersTopBarShadowHidden(v)
                },
            }}
        >
            <VStack minH="100vh" align="stretch" spacing={0}>
                <TopBar prefersTopBarShadowHidden={prefersTopBarShadowHidden}>
                    <TopBarItems />
                </TopBar>
                <motion.div
                    key={pathname}
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    {...FadeToLeft}
                >
                    <Outlet />
                </motion.div>
            </VStack>
        </TopBarContext.Provider>
    )
}
