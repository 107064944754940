import Avokado, {
    AvokadoAPIError,
    MenuSectionBatchCreate,
} from '@avokadoapp/avokado-ts'
import { mapSectionsWithoutEntriesToModel } from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface CreateMenuSectionParams {
    menu: Menu
    name: string
    description?: string
}

export interface CreateMenuSectionAction {
    run(params: CreateMenuSectionParams): Promise<Menu>
}

@injectable()
export class CreateMenuSectionActionLive implements CreateMenuSectionAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateMenuSectionParams): Promise<Menu> {
        const request: MenuSectionBatchCreate.Request = {
            organizationId: params.menu.id.organizationId,
            menuId: params.menu.id.menuId,
            sections: [{ name: params.name, description: params.description }],
        }
        const response = await this.avokado.menuSection.batchCreate(request)
        const sections = mapSectionsWithoutEntriesToModel(response)
        return {
            ...params.menu,
            sections: [...params.menu.sections, ...sections],
        }
    }
}

@injectable()
export class CreateMenuSectionActionFake implements CreateMenuSectionAction {
    async run(params: CreateMenuSectionParams): Promise<Menu> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class CreateMenuSectionActionFail implements CreateMenuSectionAction {
    async run(params: CreateMenuSectionParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
