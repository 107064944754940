import { Address } from 'features/address/models/Address'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const NewOrganizationBillingStep = 1

export interface UseNewOrganizationBillingStepFormDataHook {
    name: string
    locale: string
    timeZone: string
    address: Address | undefined
    taxId: string
    swissTaxIdSuffix: 'IVA' | 'TVA' | 'MWST'
    setName: Dispatch<SetStateAction<string>>
    setLocale: Dispatch<SetStateAction<string>>
    setTimeZone: Dispatch<SetStateAction<string>>
    setAddress: Dispatch<SetStateAction<Address | undefined>>
    setTaxId: Dispatch<SetStateAction<string>>
    setSwissTaxIdSuffix: Dispatch<SetStateAction<'IVA' | 'TVA' | 'MWST'>>
}

export function useNewOrganizationBillingStepFormData(): UseNewOrganizationBillingStepFormDataHook {
    const [name, setName] = useState('')
    const [locale, setLocale] = useState(navigator.language || 'en_GB')
    const [timeZone, setTimeZone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const [address, setAddress] = useState<Address>()
    const [taxId, setTaxId] = useState('')
    const [swissTaxIdSuffix, setSwissTaxIdSuffix] = useState<
        'IVA' | 'TVA' | 'MWST'
    >('MWST')

    useEffect(() => setTaxId(''), [address?.country])

    return {
        name,
        locale,
        timeZone,
        address,
        taxId,
        swissTaxIdSuffix,
        setName,
        setLocale,
        setTimeZone,
        setAddress,
        setTaxId,
        setSwissTaxIdSuffix,
    }
}
