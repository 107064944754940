import { Location } from 'features/location/models/Location'
import _ from 'lodash'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

export interface UseReservationPolicyFormDataHook {
    isEnabled: boolean
    hasChanges: boolean
    policy: Location.Policy.Reservation | undefined
    allowsRemoteOrder: boolean
    allowsRemoteUpfrontPayment: boolean
    automaticConfirmationThreshold: number | undefined
    maximumNumberOfConcurrentReservations: number | undefined
    maximumNumberOfPeoplePerReservation: number | undefined
    reservationDefaultDuration: number
    tableHoldingDepositAmount: number | undefined
    setIsEnabled: Dispatch<SetStateAction<boolean>>
    setAllowsRemoteOrder: Dispatch<SetStateAction<boolean>>
    setAllowsRemoteUpfrontPayment: Dispatch<SetStateAction<boolean>>
    setAutomaticConfirmationThreshold: Dispatch<
        SetStateAction<number | undefined>
    >
    setMaximumNumberOfConcurrentReservations: Dispatch<
        SetStateAction<number | undefined>
    >
    setMaximumNumberOfPeoplePerReservation: Dispatch<
        SetStateAction<number | undefined>
    >
    setReservationDefaultDuration: Dispatch<SetStateAction<number>>
    setTableHoldingDepositAmount: Dispatch<SetStateAction<number | undefined>>
}

export function useReservationPolicyFormData(
    reservation?: Location.Policy.Reservation
): UseReservationPolicyFormDataHook {
    const [isEnabled, setIsEnabled] = useState(reservation !== undefined)
    const [allowsRemoteOrder, setAllowsRemoteOrder] = useState(
        reservation?.allowsRemoteOrder ?? false
    )
    const [allowsRemoteUpfrontPayment, setAllowsRemoteUpfrontPayment] =
        useState(reservation?.allowsRemoteUpfrontPayment ?? false)
    const [automaticConfirmationThreshold, setAutomaticConfirmationThreshold] =
        useState(reservation?.automaticConfirmationThreshold)
    const [
        maximumNumberOfConcurrentReservations,
        setMaximumNumberOfConcurrentReservations,
    ] = useState(reservation?.maximumNumberOfConcurrentReservations)
    const [
        maximumNumberOfPeoplePerReservation,
        setMaximumNumberOfPeoplePerReservation,
    ] = useState(reservation?.maximumNumberOfPeoplePerReservation)
    const [reservationDefaultDuration, setReservationDefaultDuration] =
        useState(reservation?.reservationDefaultDuration ?? 0)
    const [tableHoldingDepositAmount, setTableHoldingDepositAmount] = useState(
        reservation?.tableHoldingDepositAmount
    )

    const policy: Location.Policy.Reservation | undefined = useMemo(() => {
        return isEnabled
            ? {
                  allowsRemoteOrder,
                  allowsRemoteUpfrontPayment,
                  automaticConfirmationThreshold,
                  maximumNumberOfConcurrentReservations,
                  maximumNumberOfPeoplePerReservation,
                  reservationDefaultDuration,
                  tableHoldingDepositAmount,
              }
            : undefined
    }, [
        isEnabled,
        allowsRemoteOrder,
        allowsRemoteUpfrontPayment,
        automaticConfirmationThreshold,
        maximumNumberOfConcurrentReservations,
        maximumNumberOfPeoplePerReservation,
        reservationDefaultDuration,
        tableHoldingDepositAmount,
    ])

    const hasChanges = useMemo(
        () => !_.isEqual(policy, reservation),
        [policy, reservation]
    )

    return {
        isEnabled,
        hasChanges,
        policy,
        allowsRemoteOrder,
        allowsRemoteUpfrontPayment,
        automaticConfirmationThreshold,
        maximumNumberOfConcurrentReservations,
        maximumNumberOfPeoplePerReservation,
        reservationDefaultDuration,
        tableHoldingDepositAmount,
        setIsEnabled,
        setAllowsRemoteOrder,
        setAllowsRemoteUpfrontPayment,
        setAutomaticConfirmationThreshold,
        setMaximumNumberOfConcurrentReservations,
        setMaximumNumberOfPeoplePerReservation,
        setReservationDefaultDuration,
        setTableHoldingDepositAmount,
    }
}
