import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NextStepCard } from './NextStepCard'

export function LocationNextStep() {
    const { t } = useTranslation()

    return (
        <NextStepCard
            title={t('Configure your first location')}
            description={t(
                'A location, in the context of Avokado, refers to a physical place'
            )}
            image="/home-location.png"
            imageFit="cover"
        >
            <Button as={Link} to="locations/new">
                {t('Configure')}
            </Button>
        </NextStepCard>
    )
}
