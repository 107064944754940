import { Button, HStack, Heading, Text, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { Trans } from 'react-i18next'

interface PromotionalComponentProps {
    locationName: string
    onSetupClick: () => void
    onContactUsClick: () => void
}

export function PromotionalComponent({
    locationName,
    onSetupClick,
    onContactUsClick,
}: PromotionalComponentProps) {
    const t = useT('cardreader')
    return (
        <VStack
            flex={1}
            align="flex-start"
            justify="center"
            spacing={8}
            as={motion.div}
            initial={{ opacity: 0, scale: 1.05 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 1.05 }}
        >
            <Heading>{t('promotional_title')}</Heading>
            <Text>{t('promotional_content')}</Text>

            <Text>
                <Trans t={t} i18nKey="promotional_no_monthly_fees" />
            </Text>
            <Text>
                <Trans
                    t={t}
                    i18nKey="promotional_being_setup"
                    components={[
                        <Button onClick={onSetupClick} variant="link" />,
                    ]}
                    values={{
                        locationName,
                    }}
                />
            </Text>

            <VStack alignSelf="flex-end" align="end">
                <Text>{t('promotional_want_to_know_more')}</Text>
                <HStack>
                    <Button onClick={onContactUsClick}>
                        {t('promotional_get_in_touch_button')}
                    </Button>
                </HStack>
            </VStack>
        </VStack>
    )
}
