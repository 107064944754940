import { useInjection } from 'inversify-react'
import { useState } from 'react'
import useOrganizationContext from '../contexts/OrganizationContext'
import { OrganizationDependencies } from '../dependencies'

export function useVerifyOrganization(type?: 'onboarding' | 'update') {
    const verify = useInjection(OrganizationDependencies.Verify)

    const organizationManager = useOrganizationContext()

    const [isProcessing, setIsProcessing] = useState(false)

    async function startVerificationProcess() {
        if (isProcessing) return

        setIsProcessing(true)
        const url = await verify.run({
            organizationId:
                organizationManager.assertOrganization().id.organizationId,
            type: type,
        })
        window.open(url, '_blank', 'noopener,noreferrer')
        setIsProcessing(false)
    }

    return { isProcessing, startVerificationProcess }
}
