import {
    Box,
    Divider,
    Grid,
    GridItem,
    Heading,
    Image,
    Text,
    VStack,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Trans } from 'react-i18next'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

export function StaffTab() {
    const t = useT('staff')
    const screen = useScreenBreakpoint()

    return (
        <VStack w="full" spacing={8}>
            <Box px={screen === 'desktop' ? 0 : 2} w="full">
                <Box
                    bg="primary.400"
                    w="full"
                    borderRadius="lg"
                    color="white"
                    p={8}
                    boxShadow="md"
                >
                    <Heading>{t('coming_soon_title')}</Heading>
                    <Text fontSize="xl" fontWeight="medium">
                        {t('coming_soon_body')}
                    </Text>
                </Box>
            </Box>

            <Container title={t('staff_container_title')}>
                <Grid
                    templateColumns={{
                        base: '1fr',
                        md: `repeat(2, 1fr)`,
                    }}
                    gap={6}
                    w="full"
                >
                    <GridItem>
                        <Image
                            src="/assets/staff-placeholder.jpg"
                            borderRadius="lg"
                        />
                    </GridItem>
                    <GridItem>
                        <VStack
                            w="full"
                            align="start"
                            h="sm"
                            justify="space-evenly"
                        >
                            <Text fontSize="lg">
                                <Trans t={t} i18nKey="bullet_point_1" />
                            </Text>
                            <Text fontSize="lg">
                                <Trans t={t} i18nKey="bullet_point_2" />
                            </Text>
                            <Text fontSize="lg">
                                <Trans t={t} i18nKey="bullet_point_3" />
                            </Text>
                        </VStack>
                    </GridItem>
                </Grid>
                <Divider />
                <Text>{t('coming_soon_footer')}</Text>
            </Container>
        </VStack>
    )
}
