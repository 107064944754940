import { MenuAssignmentDependencies } from 'features/menuassignment/dependencies'
import { MenuAssignment } from 'features/menuassignment/models/MenuAssignment'
import { useInjection } from 'inversify-react'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'

export interface UseMenuAssignmentFormDataHook {
    isActive: boolean
    setIsActive: Dispatch<SetStateAction<boolean>>
    hasChanges: boolean
}

export function useMenuAssignmentFormData(
    menuAssignment: MenuAssignment
): UseMenuAssignmentFormDataHook {
    const [isActive, setIsActive] = useState(menuAssignment.isActive)

    useEffect(() => {
        setIsActive(menuAssignment.isActive)
    }, [menuAssignment.isActive])

    const hasChanges = useMemo(() => {
        return isActive !== menuAssignment.isActive
    }, [isActive, menuAssignment.isActive])

    return {
        isActive,
        setIsActive,
        hasChanges,
    }
}

interface UseMenuAssignmentControllerDelegate {
    didUpdate: (menuAssignment: MenuAssignment) => void
}

export type UseMenuAssignmentFormController = UseFormControllerHook<void>

export function useMenuAssignmentController(
    menuAssignment: MenuAssignment,
    formData: UseMenuAssignmentFormDataHook,
    delegate?: UseMenuAssignmentControllerDelegate
): UseMenuAssignmentFormController {
    const update = useInjection(MenuAssignmentDependencies.Update)

    const [isProcessing, setIsProcessing] = useState(false)
    const taskFeedback = useTaskFeedback()
    const canSubmit = formData.hasChanges

    const submit = useCallback(async () => {
        if (isProcessing) return
        setIsProcessing(true)
        try {
            const result = await update.run({
                organizationId: menuAssignment.id.organizationId,
                locationId: menuAssignment.id.locationId,
                menuAssignmentId: menuAssignment.id.menuAssignmentId,
                isActive: formData.isActive,
            })
            taskFeedback.succeed()
            delegate?.didUpdate(result)
        } catch (e: unknown) {
            taskFeedback.fail()
        } finally {
            setIsProcessing(false)
        }
    }, [formData.hasChanges])

    return {
        submit,
        canSubmit,
        isProcessing,
        isSuccess: taskFeedback.isSuccess,
        failure: taskFeedback.failure,
    }
}
