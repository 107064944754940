import { Organization } from 'features/organization/models/Organization'
import { useInjection } from 'inversify-react'
import { useCallback, useEffect, useState } from 'react'
import { PayoutDependencies } from '../dependencies/payout'
import { PayoutPreferences } from '../models/PayoutPreferences'

interface UsePayoutPreferencesHook {
    isLoading: boolean
    preferences: PayoutPreferences | undefined
    update: (preferences: PayoutPreferences) => void
}

export function usePayoutPreferences(
    id: Organization.ID
): UsePayoutPreferencesHook {
    const retrievePreferences = useInjection(
        PayoutDependencies.RetrievePreferences
    )

    const [isLoading, setIsLoading] = useState(true)
    const [preferences, setPreferences] = useState<PayoutPreferences>()

    const update = useCallback(
        (preferences: PayoutPreferences) => setPreferences(preferences),
        []
    )

    useEffect(() => {
        retrievePreferences.run(id).then((data) => {
            setPreferences(data)
            setIsLoading(false)
        })
    }, [])

    return { isLoading, preferences, update }
}
