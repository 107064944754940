import { SVGProps } from 'react'

export function EggsIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Eggs"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M199.96 96.65c-.3 33.07-11.07 58.38-33.34 78.14-16.17 14.34-35.35 23.96-58.5 25.02-13.47.61-26.56 0-39.13-4.49-26.51-9.48-46.27-26.23-58.19-50.93-14.29-29.63-14.51-59.71-.04-88.98C26.68 23.24 53.16 4.03 91.31.48c21.89-2.03 42.26 2.36 60.51 13.12 19.56 11.53 33.16 28.57 41.18 49.24 4.69 12.1 7.34 24.66 6.96 33.8zm-69.24-13.72c-.77.17-2.46 3.77-2.93 4.43-4.62 6.46-3 4.25-9.75.13-3.29-2.01-4.7.16-6.17 2.02-2.61 3.29-5.06 3.7-7.89.3-2.2-2.64-4.78-3.49-7.14-.35-3.1 4.13-5.72 3.34-8.63-.14-1.77-2.12-3.87-4.11-6.63-1.07-3.72 4.09-6.48 2.56-9.26-1.11-3.54-4.67-5.4-4.55-8.47.53-.95 1.56-1.49 3.45-1.8 5.25-1.85 10.68-2.52 21.26.29 32.03 3.93 15.05 17.35 28.03 33.59 29.27 17.59 1.35 29.04-4.56 38.41-20.17 6.3-10.49 6.59-22.33 5.06-34.16-.83-6.44-.09-18.81-8.67-16.95zm1.7-9.08c-.14-.35-.24-.71-.43-1.03-4.09-7.16-7.76-14.62-14.41-20.1-10.75-8.85-22.8-8.92-34.02-.59C77 57.01 73.76 63.98 69.7 70.5c-3.55 5.69 2.8 6.22 4.9 8.82 2.24 2.77 4.61 1.01 6.19-1.07 2.74-3.61 5.44-3.99 8.25-.29 2.75 3.62 5.27 3.07 7.86-.08 2.23-2.71 4.51-2.56 6.71.01 2.67 3.11 5.12 3.18 8.13.15 1.5-1.52 3.89-4.41 6.22-1.28 3.48 4.67 6.37 5.1 9.82.16.56-.8 1.92-1.23 3-1.53.95-.27 1.99-.16 1.63-1.56z" />
        </svg>
    )
}
