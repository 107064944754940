import { Media } from 'features/media/models/Media'
import { Organization } from 'features/organization/models/Organization'

export interface Menu {
    id: Menu.ID
    name: string
    description: string | undefined
    footer: string | undefined
    media?: Media
    startingPrice?: number
    sections: Menu.Section[]
    appliedTranslation: string
    availableTranslations: Menu.TranslationState[]
    extras: Map<number, Menu.Extra>
}

export module Menu {
    export interface TranslationState {
        locale: string
        supportsAutomatic: boolean
        isPublished: boolean
    }

    export interface ID extends Organization.ID {
        menuId: number
    }

    export interface Section {
        id: Section.ID
        name: string
        description?: string
        media?: Media
        entries: Section.Entry[]
    }

    export module Section {
        export interface ID extends Menu.ID {
            sectionId: number
        }

        export interface Entry {
            id: Entry.ID
            name: string
            description?: string
            media?: Media
            price: number
            allergens: Entry.Allergen[]
            features: Entry.Feature[]
            extras: Menu.Extra[]
        }

        export module Entry {
            export interface ID extends Section.ID {
                entryId: number
            }

            export enum Allergen {
                Celery = 1 << 0,
                Cereal = 1 << 1,
                Crustaceans = 1 << 2,
                Egg = 1 << 3,
                Fish = 1 << 4,
                Lupin = 1 << 5,
                Peanuts = 1 << 6,
                Soya = 1 << 7,
                Milk = 1 << 8,
                Molluscs = 1 << 9,
                Mustard = 1 << 10,
                Nuts = 1 << 11,
                Sesame = 1 << 12,
                Sulphites = 1 << 13,
            }

            export enum Feature {
                Frozen = 1 << 0,
                Vegan = 1 << 1,
                Vegetarian = 1 << 2,
                Spicy1 = 1 << 3,
                Spicy2 = 1 << 4,
                Spicy3 = 1 << 5,
                Alcoholic = 1 << 6,
            }
        }
    }

    export interface Extra {
        id: Extra.ID
        name: string
        maxOptionsSelectable: number | undefined
        minOptionsSelectable: number | undefined
        options: Extra.Option[]
    }

    export module Extra {
        export interface ID extends Menu.ID {
            extraId: number
        }

        export interface Option {
            id: Option.ID
            name: string
            price: number | undefined
        }

        export module Option {
            export interface ID extends Extra.ID {
                optionId: number
            }
        }
    }
}
