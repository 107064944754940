import { SVGProps } from 'react'

export function SoybeansIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Soybeans"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M200 97.37c-.48 30.59-10.34 55.45-31.6 75.56-19.31 18.27-43.13 27.43-70.36 27.07-28.26-.37-52.21-11.46-70.87-31.14C5.5 145.97-2.69 118.31.76 87.85c1.72-15.17 7.21-29.18 15.47-42.58 10.31-16.72 25.23-28.42 42.6-36.89C72.64 1.66 88.16-.78 104.18.21c15.32.95 30.02 3.7 43.15 11.21 20.05 11.48 35.35 27.08 44.13 48.12 5.4 12.95 8.13 26.29 8.55 37.83zM27.59 77.71c-.27 4.66 1.08 9.71 2.47 14.58 5.2 22.27 23.71 34.54 42.57 35.43 11.32.54 22.67.46 33.9-1.8 7.61-1.53 15.38-3.57 23.03-3.4 13.24.3 26.35 2.59 38.56 8 2.28 1.01 4.64 2.52 4.44-1.74-.39-8.73-1.17-17.43-4.05-25.8-5.68-16.53-20.26-29.73-40.6-31.27-14.92-1.13-29.18 1.86-43.71 3.19-16.25 1.48-32.4 2.07-47.98-3.8-8.86-3.34-8.84-3.39-8.63 6.6z" />
            <path d="M86.25 99.41c.11-9.9 6.85-17.23 15.6-16.95 8.06.25 15.06 8.32 14.96 17.26-.11 9.73-6.63 17.55-14.59 17.47-9.69-.09-16.08-7.21-15.97-17.78zM78.13 99.55c.04 9.74-6.6 17.73-14.74 17.76-8.69.02-15.72-7.82-15.81-17.64-.08-8.92 7.18-17.23 14.98-17.15 8.76.1 15.53 7.5 15.57 17.03zM154.59 99.79c.03 10.3-6.18 17.59-14.91 17.51-8.86-.08-14.71-7.06-14.76-17.62-.04-9.94 5.91-16.97 14.53-17.15 8.89-.19 15.11 6.91 15.14 17.26z" />
        </svg>
    )
}
