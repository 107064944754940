import { SVGProps } from 'react'

export function FishIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Fish"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <circle
                cx="54.26"
                cy="96.37"
                r="5.06"
                transform="rotate(-45 54.256 96.366)"
            />
            <path d="M199.99 98.33c-.31-27.33-12.26-47.06-15.4-52.02-8.48-13.36-20.46-24.8-36.05-34.2C131.98 2.11 113.25-.72 93.8.15 65.64 1.41 42.76 13.17 25.16 33.4 5.24 56.28-3.21 83.07 1.1 112.79c5.59 38.57 27.2 65.96 64.95 81.28 7.68 3.12 15.95 5.16 25.35 5.42 2.15.17 4.3.35 6.45.52 21.04-.35 38.61-4.92 54.87-15.33 10.35-6.62 18.84-14.29 25.66-22.92 3.28-4.16 16.11-20.95 20.29-46.45 1.29-7.86 1.35-14.08 1.32-16.98zm-45.15 11.69c4.99 5.5 10 10.99 14.85 16.62 1.39 1.61 2.57 1.41 2.51 1.89-.27 2.16-16.77 6.34-25.72-.82-10.37-8.29-11.66-9.23-14.25-11.11-1.79-1.31-2.89.18-3.93 1.34-10.28 11.42-23.08 17.92-38.34 19.59-3.71.41-3.85 1.8-2.36 5.07 3.12 6.86 7.75 12.46 12.99 17.84-9.3-2.39-17.46-6.53-22.71-14.85-3.17-5.02-6.22-9.41-12.79-10.75-12.27-2.51-20.42-11.95-29.92-19.07-1.88-1.41-3.71-2.88-5.64-4.23-5.28-3.68-5.85-7.84-.4-11.55 9.42-6.41 15.24-16.57 24.04-23.07 11.03-8.15 17.54-20.53 28.94-28.01 7.22-4.74 14.81-8.58 24.33-10.71-4.05 5.6-8.28 9.93-10.91 15.32-3.37 6.9-2.89 8.55 4.29 11.02 12.43 4.28 20.3 13.61 27.26 23.96 5.35 7.96 5.1 7.83 13.77 3.11 10.11-5.5 21.17-7.33 33.85-8.04-7 7.83-13.65 14.11-19.18 21.37-1.26 1.65-2.52 3.03-.67 5.07z" />
        </svg>
    )
}
