import { Terminal } from '../models/Terminal'

export function describeTerminal(terminal: Terminal) {
    switch (terminal.type) {
        case 'monitor':
            return 'monitor'
        case 'register-shared':
            return 'register_shared'
        case 'register-standard':
            return 'register_standard'
    }
}
