import Avokado, {
    AvokadoAPIError,
    MenuLocationRetrieve,
} from '@avokadoapp/avokado-ts'
import { mapMenuToModel } from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface RetrieveMenuParams extends Menu.ID {
    locale?: string
}

export interface RetrieveMenuAction {
    run(params: RetrieveMenuParams): Promise<Menu>
}

@injectable()
export class RetrieveMenuActionLive implements RetrieveMenuAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: RetrieveMenuParams): Promise<Menu> {
        const request: MenuLocationRetrieve.Request = params
        const response = await this.avokado.menu.locationRetrieve(request)
        return mapMenuToModel(response)
    }
}

@injectable()
export class RetrieveMenuActionFake implements RetrieveMenuAction {
    async run(params: RetrieveMenuParams): Promise<Menu> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class RetrieveMenuActionFail implements RetrieveMenuAction {
    async run(params: RetrieveMenuParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
