type TableData = {
    prefix: string
    number: number
}

export function getNextTableNumber(tables: string[]): string {
    // If tables array is empty, return '1' as the first table number.
    if (!tables.length) return '1'

    // Extract rightmost numbers and their prefixes.
    const extractedData: TableData[] = tables.map((table) => {
        const match = table.match(/(.*?)(\d+)$/)
        return {
            prefix: match ? match[1] : '',
            number: match ? parseInt(match[2], 10) : 0,
        }
    })

    // Determine the highest number.
    const maxNumber = Math.max(...extractedData.map((data) => data.number))

    // Determine the most common prefix.
    const prefixFrequency: { [key: string]: number } = {}
    let mostCommonPrefix = ''
    let maxFreq = 0

    extractedData.forEach((data) => {
        if (!prefixFrequency[data.prefix]) {
            prefixFrequency[data.prefix] = 0
        }
        prefixFrequency[data.prefix]++
        if (prefixFrequency[data.prefix] > maxFreq) {
            maxFreq = prefixFrequency[data.prefix]
            mostCommonPrefix = data.prefix
        }
    })

    // Generate the new table number.
    return mostCommonPrefix + (maxNumber + 1)
}
