import { interfaces } from 'inversify'
import { AttachCardReaderAction } from './AttachCardReaderAction'
import { InitCardReaderAction } from './InitCardReaderAction'

interface _CardReaderDependencies {
    Init: interfaces.ServiceIdentifier<InitCardReaderAction>
    Attach: interfaces.ServiceIdentifier<AttachCardReaderAction>
}

export const CardReaderDependencies: _CardReaderDependencies = {
    Init: Symbol('InitCardReaderAction'),
    Attach: Symbol('AttachCardReaderAction'),
}

export type { InitCardReaderAction, AttachCardReaderAction }
