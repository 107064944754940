import {
    AvatarGroup,
    AvatarGroupProps,
    Box,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
    VStack,
} from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import useT from 'localization/hooks/useT'
import { AllergenIcon } from './AllergenIcon'

// The `max` property, if provided, is manipulated to ensure that
// we never have a +1 Avatar at the end of the group.
// This means that, if the provided props are `max = n` and
// `allergens.length = n+1`, then max is incremented by one.
interface AllergensProps extends Omit<AvatarGroupProps, 'children'> {
    allergens: Menu.Section.Entry.Allergen[]
    borderColor: Exclude<AvatarGroupProps['borderColor'], undefined>
}

export function Allergens({ allergens, flex, max, ...props }: AllergensProps) {
    const t = useT('menu')

    if (allergens.length === 0) {
        return <Box flex={flex} />
    }

    return (
        <Box display="flex" flex={flex} justifyContent="end">
            <Popover trigger="hover">
                <PopoverTrigger>
                    <AvatarGroup
                        {...props}
                        max={
                            max === undefined
                                ? undefined
                                : max === allergens.length - 1
                                ? max + 1
                                : max
                        }
                    >
                        {allergens.map((allergen) => (
                            <AllergenIcon key={allergen} allergen={allergen} />
                        ))}
                    </AvatarGroup>
                </PopoverTrigger>

                <PopoverContent
                    w="3xs"
                    bg="sheetBackground1"
                    borderColor="sheetBackground2"
                >
                    <PopoverHeader fontWeight="bold">
                        {t('entry_allergens_input_label')}
                    </PopoverHeader>
                    <PopoverArrow bg="sheetBackground1" />
                    <PopoverBody>
                        <VStack align="start">
                            {allergens.map((allergen) => (
                                <HStack key={allergen}>
                                    <AllergenIcon
                                        size="xs"
                                        allergen={allergen}
                                    />
                                    <Text>
                                        {t(
                                            `allergen_${Menu.Section.Entry.Allergen[
                                                allergen
                                            ].toLowerCase()}`
                                        )}
                                    </Text>
                                </HStack>
                            ))}
                        </VStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    )
}
