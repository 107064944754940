import useAuthContext from 'features/auth/contexts/AuthContext'
import useT from 'localization/hooks/useT'
import { Container } from 'uikit/container/Container'

export function Welcome() {
    const t = useT('home')

    const { user } = useAuthContext()

    return (
        <Container
            mainContainer
            isCompact
            title={
                t('welcome_back_title') +
                (user?.givenName ? `, ${user!.givenName}` : '')
            }
        ></Container>
    )
}
