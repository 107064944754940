import { CheckIcon, EditIcon } from '@chakra-ui/icons'
import {
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    HStack,
    Input,
    VStack,
} from '@chakra-ui/react'
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { Address } from '../models/Address'
import { CountrySelector } from './CountrySelector'

interface AddressFormProps {
    isLocked?: boolean
    lockedCountry?: Address.Country
    address: Address
    setAddress: Dispatch<SetStateAction<Address | undefined>>
    onEdit?: () => void
    onSave?: () => void
    isSaving?: boolean
}

export function AddressForm({
    address,
    setAddress,
    lockedCountry,
    isLocked,
    onEdit,
    onSave,
    isSaving,
}: AddressFormProps) {
    const { t } = useTranslation()

    const screen = useScreenBreakpoint()

    const {
        street,
        addressNumber,
        municipality,
        postalCode,
        region,
        subregion,
        country,
    } = address

    const canSave = useMemo(() => {
        return (
            street !== '' &&
            addressNumber !== '' &&
            municipality !== '' &&
            postalCode !== '' &&
            region !== '' &&
            subregion !== '' &&
            country !== ''
        )
    }, [
        street,
        addressNumber,
        municipality,
        postalCode,
        region,
        subregion,
        country,
    ])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>, key: keyof Address) => {
            setAddress((previous: Address | undefined) => {
                if (previous === undefined) return undefined
                return { ...previous, [key]: e.target.value }
            })
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const handleCountryChange = useCallback((country: Address.Country) => {
        setAddress((previous: Address | undefined) => {
            if (previous === undefined) return undefined
            return { ...previous, country }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (screen === 'mobile') {
        return (
            <VStack w="full">
                <FormControl flex={1} isInvalid={street === ''}>
                    <FormLabel>{t('Street')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={street}
                        onChange={(e) => handleChange(e, 'street')}
                    />
                </FormControl>

                <FormControl flex={1} isInvalid={addressNumber === ''}>
                    <FormLabel>{t('Unit number')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={addressNumber}
                        onChange={(e) => handleChange(e, 'addressNumber')}
                    />
                </FormControl>

                <FormControl flex={1} isInvalid={postalCode === ''}>
                    <FormLabel>{t('ZIP Code')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={postalCode}
                        onChange={(e) => handleChange(e, 'postalCode')}
                    />
                </FormControl>

                <FormControl flex={1} isInvalid={municipality === ''}>
                    <FormLabel>{t('City')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={municipality}
                        onChange={(e) => handleChange(e, 'municipality')}
                    />
                </FormControl>

                <FormControl flex={1} isInvalid={subregion === ''}>
                    <FormLabel>{t('Province')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={subregion}
                        onChange={(e) => handleChange(e, 'subregion')}
                    />
                </FormControl>

                <FormControl flex={1} isInvalid={region === ''}>
                    <FormLabel>{t('Region')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={region}
                        onChange={(e) => handleChange(e, 'region')}
                    />
                </FormControl>

                <FormControl
                    flex={2.5}
                    isInvalid={country === ''}
                    isReadOnly={isLocked || lockedCountry !== undefined}
                >
                    <FormLabel>{t('Country')}</FormLabel>
                    <CountrySelector
                        isLocked={isLocked || lockedCountry !== undefined}
                        country={lockedCountry ?? country}
                        onSelect={handleCountryChange}
                    />
                </FormControl>
                <ButtonGroup alignSelf="end">
                    {onEdit && (
                        <Button
                            onClick={onEdit}
                            variant="ghost"
                            leftIcon={<EditIcon />}
                        >
                            {t('address_form_edit_button')}
                        </Button>
                    )}
                    {onSave && (
                        <Button
                            onClick={onSave}
                            variant="ghost"
                            isDisabled={!canSave}
                            isLoading={isSaving}
                            leftIcon={<CheckIcon />}
                        >
                            {t('address_form_save_button')}
                        </Button>
                    )}
                </ButtonGroup>
            </VStack>
        )
    }

    return (
        <VStack w="full">
            <HStack w="full">
                <FormControl flex={4} isInvalid={street === ''}>
                    <FormLabel>{t('Street')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={street}
                        onChange={(e) => handleChange(e, 'street')}
                    />
                </FormControl>

                <FormControl flex={1} isInvalid={addressNumber === ''}>
                    <FormLabel>{t('Unit number')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={addressNumber}
                        onChange={(e) => handleChange(e, 'addressNumber')}
                    />
                </FormControl>
            </HStack>

            <HStack w="full">
                <FormControl flex={1} isInvalid={postalCode === ''}>
                    <FormLabel>{t('ZIP Code')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={postalCode}
                        onChange={(e) => handleChange(e, 'postalCode')}
                    />
                </FormControl>

                <FormControl flex={2} isInvalid={municipality === ''}>
                    <FormLabel>{t('City')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={municipality}
                        onChange={(e) => handleChange(e, 'municipality')}
                    />
                </FormControl>

                <FormControl flex={2} isInvalid={subregion === ''}>
                    <FormLabel>{t('Province')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={subregion}
                        onChange={(e) => handleChange(e, 'subregion')}
                    />
                </FormControl>
            </HStack>
            <HStack w="full">
                <FormControl flex={2.5} isInvalid={region === ''}>
                    <FormLabel>{t('Region')}</FormLabel>
                    <Input
                        isReadOnly={isLocked}
                        value={region}
                        onChange={(e) => handleChange(e, 'region')}
                    />
                </FormControl>

                <FormControl
                    flex={2.5}
                    isReadOnly={isLocked || lockedCountry !== undefined}
                    isInvalid={country === ''}
                >
                    <FormLabel>{t('Country')}</FormLabel>
                    <CountrySelector
                        isLocked={isLocked || lockedCountry !== undefined}
                        country={lockedCountry ?? country}
                        onSelect={handleCountryChange}
                    />
                </FormControl>
            </HStack>
            <ButtonGroup alignSelf="end">
                {onEdit && (
                    <Button
                        onClick={onEdit}
                        variant="ghost"
                        leftIcon={<EditIcon />}
                    >
                        {t('address_form_edit_button')}
                    </Button>
                )}
                {onSave && (
                    <Button
                        onClick={onSave}
                        variant="ghost"
                        isDisabled={!canSave}
                        isLoading={isSaving}
                        leftIcon={<CheckIcon />}
                    >
                        {t('address_form_save_button')}
                    </Button>
                )}
            </ButtonGroup>
        </VStack>
    )
}
