import { AddIcon } from '@chakra-ui/icons'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    HStack,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Stack,
    Text,
    VStack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'

import useT from 'localization/hooks/useT'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { useRoomController } from '../hooks/canvas-related-logic/useRoomController'
import { useGenerateCodes } from '../hooks/useGenerateCodes'
import { generateRoomCodes } from '../mappers/InternalMapper'
import { Room } from '../models/Room'
import EditRoomHeader from './EditRoomHeader'
import { RoomCanvas } from './canvas/RoomCanvas'
import { RoomTemp } from './canvas/RoomTemp'

interface RoomContentProps {
    locationName: string
    room: Room
}

export function RoomContent({ locationName, room }: RoomContentProps) {
    const t = useT('room')

    const canvasModal = useDisclosure()

    const screen = useScreenBreakpoint()
    const controller = useRoomController({ room })

    return (
        <>
            <Modal
                isOpen={canvasModal.isOpen}
                onClose={canvasModal.onClose}
                size="full"
                autoFocus={false}
            >
                <ModalContent h="full">
                    <ModalHeader autoFocus={false}>
                        <EditRoomHeader newC={controller} />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <RoomCanvas newC={controller} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Container isVisible title={room.name}>
                <Stack
                    direction={screen === 'mobile' ? 'column' : 'row'}
                    gap={4}
                >
                    <RoomTemp
                        size={controller.room.size}
                        tables={controller.tables}
                        onClick={canvasModal.onOpen}
                    />
                    <VStack w="full" align="start" gap={4}>
                        <HStack w="full">
                            <Text fontSize="2xl">
                                {t('room_content_layout_tables_count', {
                                    count: controller.tables.length,
                                })}
                            </Text>

                            <IconButton
                                variant="ghost"
                                icon={<AddIcon />}
                                aria-label={t('room_content_add_table')}
                                onClick={canvasModal.onOpen}
                            />
                        </HStack>
                        <RoomInfoAlert
                            tables={controller.tables}
                            isOpen={room.isOpen}
                        />

                        <ButtonGroup variant="outline" w="full" mt={2}>
                            <Stack
                                w="full"
                                align="stretch"
                                direction={
                                    screen === 'mobile' ? 'column' : 'row'
                                }
                            >
                                <DownloadRoomCodesButton
                                    locationName={locationName}
                                    room={room}
                                />
                                <Button onClick={canvasModal.onOpen}>
                                    {t('room_content_layout_button')}
                                </Button>
                                {/* <Button>
                                    {room.isOpen ? 'Close Room' : 'Open Room'}
                                </Button> */}
                            </Stack>
                        </ButtonGroup>
                    </VStack>
                </Stack>
            </Container>
        </>
    )
}

interface RoomInfoAlertProps {
    isOpen: boolean
    tables: Room.Table[]
}

function RoomInfoAlert({ isOpen, tables }: RoomInfoAlertProps) {
    const t = useT('room')
    const status = isOpen
        ? tables.length > 0
            ? 'success'
            : 'warning'
        : 'error'

    const title = isOpen
        ? tables.length > 0
            ? 'room_status_alert_ready_title'
            : 'room_status_alert_no_tables_title'
        : 'room_status_alert_closed_title'

    const description = isOpen
        ? tables.length > 0
            ? 'room_status_alert_ready_description'
            : 'room_status_alert_no_tables_description'
        : 'room_status_alert_closed_description'

    return (
        <Alert status={status} borderRadius={8}>
            <AlertIcon />
            <Box>
                <AlertTitle>{t(title)}</AlertTitle>
                <AlertDescription>{t(description)}</AlertDescription>
            </Box>
        </Alert>
    )
}

interface DownloadRoomCodesButtonProps {
    room: Room
    locationName: string
}

function DownloadRoomCodesButton({
    locationName,
    room,
}: DownloadRoomCodesButtonProps) {
    const toast = useToast()

    const t = useT('room')
    const { isProcessing, process } = useGenerateCodes()

    async function showCodes() {
        toast({
            position: 'bottom',
            status: 'info',
            title: t('room_codes_pdf_download_started_title'),
            description: t('room_codes_pdf_download_started_description'),
            duration: 3000,
        })
        await process(
            `${locationName}-${room.name}.pdf`,
            async () => await generateRoomCodes(locationName, room)
        )
    }

    return (
        <Button
            isDisabled={room.tables.length === 0}
            isLoading={isProcessing}
            onClick={showCodes}
        >
            {t('room_content_download_codes_button')}
        </Button>
    )
}
