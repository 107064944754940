import { Room } from 'features/room/models/Room'
import { Geometry } from 'utils/geometry/Geometry'
import { getNextTableNumber } from './getNextTableLabel'

export interface CreateTableCanvasState {
    stateType: 'create'
    nextShape: Geometry.Shape
    nextLabel: string
}

export interface TableSelectedCanvasState {
    stateType: 'table-selected'
    table: Room.Table
    originalTableLabel: string
}

export type CanvasState = CreateTableCanvasState | TableSelectedCanvasState

export function getDefaultControllerState(room: Room): CanvasState {
    return {
        stateType: 'create',
        nextLabel: getNextTableNumber(room.tables.map((t) => t.label)),
        nextShape: getDefaultControllerShape(),
    }
}

export function getDefaultControllerShape(): Geometry.Shape {
    return {
        type: 'rectangle',
        size: {
            width: 0.05,
            height: 0.05,
        },
        // center is irrelevant, we do not actually use it
        center: {
            x: 0,
            y: 0,
        },
    }
}
