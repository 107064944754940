import { interfaces } from 'inversify'
import { ListPayoutAction } from './ListPayoutAction'
import { RetrievePreferencesPayoutAction } from './RetrievePreferencesPayoutAction'
import { UpdatePreferencesPayoutAction } from './UpdatePreferencesPayoutAction'

interface _PayoutDependencies {
    List: interfaces.ServiceIdentifier<ListPayoutAction>
    RetrievePreferences: interfaces.ServiceIdentifier<RetrievePreferencesPayoutAction>
    UpdatePreferences: interfaces.ServiceIdentifier<UpdatePreferencesPayoutAction>
}

export const PayoutDependencies: _PayoutDependencies = {
    List: Symbol('ListPayoutAction'),
    RetrievePreferences: Symbol('RetrievePreferencesPayoutAction'),
    UpdatePreferences: Symbol('UpdatePreferencesPayoutAction'),
}

export type {
    ListPayoutAction,
    RetrievePreferencesPayoutAction,
    UpdatePreferencesPayoutAction,
}
