import { interfaces } from 'inversify'
import { BatchCreateMenuSectionAction } from './BatchCreateMenuSectionAction'
import { CreateMenuSectionAction } from './CreateMenuSectionAction'
import { UpdateMenuSectionAction } from './UpdateMenuSectionAction'
import { UploadMediaMenuSectionAction } from './UploadMediaMenuSectionAction'

interface _MenuSectionDependencies {
    Create: interfaces.ServiceIdentifier<CreateMenuSectionAction>
    BatchCreate: interfaces.ServiceIdentifier<BatchCreateMenuSectionAction>
    Update: interfaces.ServiceIdentifier<UpdateMenuSectionAction>
    UploadMedia: interfaces.ServiceIdentifier<UploadMediaMenuSectionAction>
}

export const MenuSectionDependencies: _MenuSectionDependencies = {
    Create: Symbol('CreateMenuSectionAction'),
    BatchCreate: Symbol('BatchCreateMenuSectionAction'),
    Update: Symbol('UpdateMenuSectionAction'),
    UploadMedia: Symbol('UploadMediaMenuSectionAction'),
}

export type {
    CreateMenuSectionAction,
    BatchCreateMenuSectionAction,
    UpdateMenuSectionAction,
    UploadMediaMenuSectionAction,
}
