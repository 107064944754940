import { useConst } from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { GuidedAction, Step } from 'utils/guidedaction'
import {
    NewMenuCreateSectionsStep,
    NewMenuGeneralStep,
    NewMenuImageStep,
} from '../components/new'
import { NewMenuAssignStep } from '../components/new/NewMenuAssignStep'
import { MenuConfigSectionsStepDelegate } from '../components/new/NewMenuConfigSectionsStep'
import { MenuCreateSectionsStepDelegate } from '../components/new/NewMenuCreateSectionsStep'
import { MenuImageStepDelegate } from '../components/new/NewMenuImageStep'
import {
    UseNewMenuFormDataHook,
    useNewMenuFormController,
    useNewMenuFormData,
} from '../hooks/new/useNewMenuForm'

export const newMenuSteps: Step<
    UseNewMenuFormDataHook &
        MenuImageStepDelegate &
        MenuCreateSectionsStepDelegate &
        MenuConfigSectionsStepDelegate
>[] = [
    {
        title: 'new_step_1_general_preview_title',
        description: 'new_step_1_general_preview_description',
        content: (props) => (
            <NewMenuGeneralStep
                headerRef={props.headerRef}
                {...props.context}
            />
        ),
    },
    {
        title: 'new_step_2_cover_preview_title',
        description: 'new_step_2_cover_preview_description',
        skipBy: 1,
        content: (props) => (
            <NewMenuImageStep headerRef={props.headerRef} {...props.context} />
        ),
    },
    {
        title: 'new_step_3_sections_preview_title',
        description: 'new_step_3_sections_preview_description',
        skipBy: 2,
        content: (props) => (
            <NewMenuCreateSectionsStep
                headerRef={props.headerRef}
                {...props.context}
            />
        ),
    },
    // {
    //     title: 'new_step_4_config_preview_title',
    //     description: 'new_step_4_config_preview_description',
    //     skipBy: 1,
    //     preventBack: true,
    //     content: (props) => (
    //         <NewMenuConfigSectionsStep
    //             headerRef={props.headerRef}
    //             {...props.context}
    //         />
    //     ),
    // },
    {
        title: 'new_step_5_assign_preview_title',
        description: 'new_step_5_assign_preview_description',
        skipBy: 1,
        content: (props) => (
            <NewMenuAssignStep headerRef={props.headerRef} {...props.context} />
        ),
    },
]

export default function NewMenuPage() {
    const organizationManager = useOrganizationContext()

    const formData = useNewMenuFormData()
    const controller = useNewMenuFormController(formData)

    const { organizationId } = useConst(
        () => organizationManager.assertOrganization().id
    )

    return (
        <GuidedAction
            namespace="menu"
            context={{
                ...formData,
                media: controller.menu?.media,
                sections: controller.menu?.sections ?? [],
                isProcessing: controller.isProcessing,
                sectionSubmit: controller.sectionSubmit,
                buildMenuUploadLink: controller.buildMenuMediaUploadLink,
            }}
            steps={newMenuSteps}
            index={controller.activeStep}
            isProcessing={controller.isProcessing}
            isDisabled={!controller.canSubmit}
            hideControls={controller.hideControls}
            hideSkip={controller.hideSkip}
            completion={{
                modelLink: `/organizations/${organizationId}/menus/${controller.menu?.id.menuId}`,
                fallbackLink: `/organizations/${organizationId}/menus`,
            }}
            onNext={controller.submit}
            onBack={controller.goBack}
            onSkip={controller.skip}
        />
    )
}
