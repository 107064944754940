import { Grid } from '@chakra-ui/react'
import { MenuCompact } from 'features/menu/models/MenuCompact'
import { GridCard } from 'uikit/grid/GridCard'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

interface MenuPickerProps {
    menus: MenuCompact[]
    onMenuSelected: (m: MenuCompact) => void
}

export default function MenuPicker(props: MenuPickerProps) {
    const screen = useScreenBreakpoint()
    return (
        <Grid
            templateColumns="repeat(auto-fill, minmax(250px,1fr))"
            gap={6}
            w="full"
        >
            {props.menus.map((menu) => (
                <GridCard
                    key={menu.id.menuId}
                    name={menu.name}
                    cover={menu.media?.large()}
                    minH={screen === 'mobile' ? '100px' : 'sm'}
                    onClick={() => props.onMenuSelected(menu)}
                />
            ))}
        </Grid>
    )
}
