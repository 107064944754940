import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Divider,
    HStack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { describePaymentMethod as describe } from 'features/finance/mapper/receipt/InternalMapper'
import { Currency } from 'features/finance/models/Currency'
import useT from 'localization/hooks/useT'
import { ReactNode } from 'react'
import { useListFormatter, usePriceFormatter } from 'utils/types/formatters'
import { Receipt } from '../../models/Receipt'

const accordionButtonProps = {
    bg: 'pageBackground3',
    transition: 'all .2s',
    borderRadius: 'lg',
    _hover: { bg: 'accentHover' },
    _focus: { textColor: 'accent' },
    _expanded: { bg: 'pageBackground3', textColor: 'accent' },
}

interface ReceiptContentProps {
    receipt: Receipt
    heading: ReactNode
}

export function ReceiptContent({ receipt, heading }: ReceiptContentProps) {
    const t = useT('orderhistory')

    return (
        <VStack w="full" align="stretch">
            {heading}

            <Accordion w="full" defaultIndex={0}>
                <AccordionItem
                    mt={2}
                    border="none"
                    borderRadius={10}
                    bg="pageBackground3"
                >
                    <AccordionButton
                        cursor={
                            receipt.payments.length > 0 ? 'pointer' : 'default'
                        }
                        {...accordionButtonProps}
                    >
                        <Box as="span" flex={1} textAlign="left">
                            {t('receipt_summary')}
                        </Box>
                        {receipt.payments.length > 0 && <AccordionIcon />}
                    </AccordionButton>

                    <AccordionPanel>
                        <AccordionContent {...receipt} />
                    </AccordionPanel>
                </AccordionItem>

                {receipt.payments.map((payment) => (
                    <AccordionItem
                        key={payment.id}
                        mt={2}
                        border="none"
                        borderRadius={10}
                        bg="pageBackground3"
                    >
                        <AccordionButton {...accordionButtonProps}>
                            <Box as="span" flex={1} textAlign="left">
                                {payment.method
                                    ? t(
                                          describe(
                                              payment.method,
                                              payment.externalMethod
                                          )
                                      )
                                    : t('payment_method_unknown')}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel>
                            <AccordionContent
                                currency={receipt.currency}
                                {...payment}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </VStack>
    )
}

interface AccordionContentProps {
    currency: Currency
    coverCharge: Receipt.CoverCharge | undefined
    tip: number | undefined
    orders: Receipt.Order[]
    totalAmount: number
}

function AccordionContent({
    currency,
    coverCharge,
    tip,
    orders,
    totalAmount,
}: AccordionContentProps) {
    const t = useT('orderhistory')

    const priceFormatter = usePriceFormatter(currency)
    const listFormatter = useListFormatter()

    return (
        <VStack h="full" px={4} pt={4} spacing={4}>
            {coverCharge && (
                <HStack w="full" justify="space-between" align="start">
                    <Text>
                        {t('cover_charge_label', { count: coverCharge.amount })}
                    </Text>

                    <Text>
                        {priceFormatter.format(
                            coverCharge.amount * coverCharge.price
                        )}
                    </Text>
                </HStack>
            )}

            {orders.map((order) => (
                <HStack
                    key={order.id}
                    w="full"
                    justify="space-between"
                    align="start"
                >
                    <VStack align="start">
                        <Text
                            fontStyle={
                                order.entryName === undefined
                                    ? 'italic'
                                    : 'normal'
                            }
                            textDecoration={
                                order.isCanceled ? 'line-through' : undefined
                            }
                        >
                            {order.isPartial
                                ? t('partial_entry_label', {
                                      entry:
                                          order.entryName ??
                                          t('deleted_entry_label'),
                                  })
                                : order.entryName ?? t('deleted_entry_label')}
                        </Text>

                        {order.note && (
                            <Text
                                ps={4}
                                textDecoration={
                                    order.isCanceled
                                        ? 'line-through'
                                        : undefined
                                }
                            >
                                {order.note}
                            </Text>
                        )}

                        {order.optionNames.length > 0 && (
                            <Text
                                ps={4}
                                textDecoration={
                                    order.isCanceled
                                        ? 'line-through'
                                        : undefined
                                }
                            >
                                {listFormatter.format(order.optionNames)}
                            </Text>
                        )}
                    </VStack>

                    <Text
                        textDecoration={
                            order.isCanceled ? 'line-through' : undefined
                        }
                    >
                        {priceFormatter.format(order.price)}
                    </Text>
                </HStack>
            ))}

            {tip && (
                <HStack w="full" justify="space-between" align="start">
                    <Text>{t('tip_label')}</Text>

                    <Text>{priceFormatter.format(tip)}</Text>
                </HStack>
            )}

            <Divider />

            <Text w="full" textAlign="end" fontWeight="semibold">
                {priceFormatter.format(totalAmount)}
            </Text>
        </VStack>
    )
}
