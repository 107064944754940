import { useState } from 'react'

export type Trigger = number

export interface UseTrigger {
    value: Trigger
    fire: () => void
}

export default function useTrigger(): UseTrigger {
    const [value, setValue] = useState(1)

    const fire = () => {
        setValue((v) => v + 1)
    }

    return { value, fire }
}
