import Avokado, {
    AvokadoAPIError,
    TranslationBundleActivate,
} from '@avokadoapp/avokado-ts'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface BuyTranslationBundleParams extends Organization.ID {
    languageCode: TranslationBundleActivate.Request.Locale
}

export interface BuyTranslationBundleAction {
    run(params: BuyTranslationBundleParams): Promise<void>
}

@injectable()
export class BuyTranslationBundleActionLive
    implements BuyTranslationBundleAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: BuyTranslationBundleParams): Promise<void> {
        const request: TranslationBundleActivate.Request = params
        const response = await this.avokado.translationBundle.activate(request)
    }
}

@injectable()
export class BuyTranslationBundleActionFake
    implements BuyTranslationBundleAction
{
    async run(params: BuyTranslationBundleParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class BuyTranslationBundleActionFail
    implements BuyTranslationBundleAction
{
    async run(params: BuyTranslationBundleParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
