import {
    AccordionItemProps,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Center,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Input,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Spinner,
    Stack,
    Text,
    Textarea,
    VStack,
    useConst,
    useDisclosure,
} from '@chakra-ui/react'
import { ImageUploader } from 'features/media/components/ImageUploader'
import { UseMediaUploadParams } from 'features/media/hooks/useMediaUpload'
import useT from 'localization/hooks/useT'
import { forwardRef, useRef } from 'react'
import { SortableItem, SortableKnob } from 'react-easy-sort'
import { FiEdit2, FiPlus } from 'react-icons/fi'
import { TbGripVertical } from 'react-icons/tb'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import {
    useNewSectionFormController,
    useNewSectionFormData,
} from '../hooks/new/useNewSectionForm'
import {
    useSectionFormController,
    useSectionFormData,
} from '../hooks/useSectionForm'
import { Menu } from '../models/Menu'
import { MenuPageContentProps } from '../pages/MenuPage'
import { MenuImageUploaderRenderer } from './MenuImageUploaderRenderer'

interface SectionProps
    extends MenuPageContentProps,
        Pick<AccordionItemProps, 'borderBottomWidth'> {
    section: Menu.Section
    isSelected: boolean
    isDraggable: boolean
    onSelect: () => void
}

export function Section({
    menu,
    section,
    isSelected,
    isDraggable,
    update,
    onSelect,
    ...props
}: SectionProps) {
    const t = useT('menu')
    const screen = useScreenBreakpoint()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const formData = useSectionFormData(section)
    const controller = useSectionFormController(menu, section, formData, update)

    const config: UseMediaUploadParams = useConst(() => ({
        automaticReset: true,
        automaticUploadMediaAction: controller.buildMediaUploadLink,
        onUploadingStatusChanged: formData.setIsUploadingCover,
        onUploadEnded: formData.setRawMedia,
    }))

    return (
        <SortableItem>
            <HStack
                h={screen === 'mobile' ? 16 : 12}
                px={screen === 'mobile' ? 0 : 2}
                cursor="pointer"
                bg={
                    screen !== 'mobile' && isSelected
                        ? 'pageBackground3'
                        : 'pageBackground2'
                }
                _hover={
                    screen !== 'mobile' ? { bg: 'pageBackground3' } : undefined
                }
                onClick={onSelect}
                {...props}
            >
                {isDraggable && (
                    <SortableKnob>
                        <DraggableKnob />
                    </SortableKnob>
                )}
                <ImageUploader
                    config={config}
                    renderer={({ status, dropzone }) => (
                        <>
                            <Avatar
                                size="xs"
                                bg="accentSurface"
                                color="onAccentSurface"
                                src={
                                    status.type === 'uploading'
                                        ? status.url
                                        : section.media?.small()
                                }
                                name={section.name}
                                getInitials={(name) => name[0]}
                                position="relative"
                            >
                                {status.type === 'uploading' && (
                                    <>
                                        <Box
                                            bg="blackAlpha.600"
                                            position="absolute"
                                            w="full"
                                            h="full"
                                            borderRadius="full"
                                        />
                                        <Spinner
                                            position="absolute"
                                            size="sm"
                                        />
                                    </>
                                )}
                            </Avatar>

                            <Text
                                flex={1}
                                fontWeight={isSelected ? 'semibold' : 'normal'}
                            >
                                {section.name}
                            </Text>

                            <IconButton
                                aria-label="Edit"
                                icon={<FiEdit2 />}
                                onClick={(e) => {
                                    if (screen === 'mobile') {
                                        e.stopPropagation()
                                    }
                                    onOpen()
                                }}
                                colorScheme="gray"
                                size="sm"
                                borderRadius="full"
                            />
                            <Modal
                                isOpen={isOpen}
                                onClose={onClose}
                                isCentered
                                size="4xl"
                            >
                                <ModalOverlay />
                                <ModalContent
                                    p={8}
                                    borderRadius="2xl"
                                    bg="sheetBackground1"
                                >
                                    <ModalCloseButton
                                        size="sm"
                                        borderRadius="full"
                                        top={2}
                                        right={2}
                                    />
                                    <Stack
                                        w="full"
                                        align="stretch"
                                        spacing={8}
                                        direction={
                                            screen === 'mobile'
                                                ? 'column'
                                                : 'row'
                                        }
                                    >
                                        <MenuImageUploaderRenderer
                                            bg="pageBackground2"
                                            currentMedia={section.media}
                                            status={status}
                                            dropzone={dropzone}
                                        />

                                        <VStack spacing={8} flex={1}>
                                            <FormControl>
                                                <FormLabel>
                                                    {t(
                                                        'menu_section_form_name_label'
                                                    )}
                                                </FormLabel>
                                                <Input
                                                    type="text"
                                                    placeholder={t(
                                                        'menu_section_form_name_placeholder'
                                                    )}
                                                    variant="filled"
                                                    bg="sheetBackground2"
                                                    maxLength={50}
                                                    value={formData.name}
                                                    size="3xl"
                                                    onChange={(e) =>
                                                        formData.setName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <FormHelperText>
                                                    {t(
                                                        'menu_section_form_name_helper'
                                                    )}
                                                </FormHelperText>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>
                                                    {t(
                                                        'menu_section_form_description_label'
                                                    )}
                                                </FormLabel>
                                                <Textarea
                                                    flex={1}
                                                    resize="none"
                                                    placeholder={t(
                                                        'menu_section_form_description_placeholder'
                                                    )}
                                                    variant="filled"
                                                    bg="sheetBackground2"
                                                    maxLength={50}
                                                    value={formData.description}
                                                    onChange={(e) =>
                                                        formData.setDescription(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <FormHelperText>
                                                    {t(
                                                        'menu_section_form_description_helper'
                                                    )}
                                                </FormHelperText>
                                            </FormControl>

                                            <ButtonGroup
                                                variant="ghost"
                                                size="sm"
                                                alignSelf="end"
                                            >
                                                <Button onClick={onClose}>
                                                    {t('generic_cancel_button')}
                                                </Button>
                                                <Button
                                                    isDisabled={
                                                        !controller.canSubmit
                                                    }
                                                    onClick={() =>
                                                        controller
                                                            .submit()
                                                            .then(onClose)
                                                    }
                                                    isLoading={
                                                        controller.isProcessing
                                                    }
                                                >
                                                    {t('generic_save_button')}
                                                </Button>
                                            </ButtonGroup>
                                        </VStack>
                                    </Stack>
                                </ModalContent>
                            </Modal>
                        </>
                    )}
                />
            </HStack>
        </SortableItem>
    )
}

export function NewSectionInput({ menu, update }: MenuPageContentProps) {
    const t = useT('menu')
    const screen = useScreenBreakpoint()
    const ref = useRef<HTMLInputElement>(null)

    const formData = useNewSectionFormData()
    const controller = useNewSectionFormController(menu, formData, update)

    return (
        <HStack
            px={screen === 'mobile' ? 0 : 2}
            h={screen === 'mobile' ? 16 : 12}
            bg="pageBackground2"
        >
            <Input
                ref={ref}
                variant="filled"
                value={formData.name}
                onChange={(e) => formData.setName(e.target.value)}
                colorScheme="gray"
                maxLength={50}
                placeholder={t('new_section_placeholder')}
                isDisabled={controller.isProcessing}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        controller.submit()
                    }
                }}
            />

            <IconButton
                aria-label={t('add_new_section_aria_label')}
                icon={<FiPlus />}
                onClick={controller.submit}
                isDisabled={!controller.canSubmit}
                isLoading={controller.isProcessing}
                colorScheme="gray"
                size="sm"
                borderRadius="full"
            />
        </HStack>
    )
}

const DraggableKnob = forwardRef<HTMLDivElement, {}>((_, ref) => {
    return (
        <Center ref={ref}>
            <Icon as={TbGripVertical} />
        </Center>
    )
})
