import Avokado, {
    AvokadoAPIError,
    LocationPublish,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapLocationToModel } from '../mappers/SDKResponseMapper'
import { Location } from '../models/Location'

interface PublishLocationParams {
    location: Location
    publish: boolean
}

export interface PublishLocationAction {
    run(params: PublishLocationParams): Promise<Location>
}

@injectable()
export class PublishLocationActionLive implements PublishLocationAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: PublishLocationParams): Promise<Location> {
        const { location } = params
        const request: LocationPublish.Request = {
            organizationId: location.id.organizationId,
            locationId: location.id.locationId,
            action: params.publish ? 'publish' : 'unpublish',
        }
        const response = await this.avokado.location.publish(request)
        return mapLocationToModel(response, location.menuAssignments)
    }
}

@injectable()
export class PublishLocationActionFake implements PublishLocationAction {
    async run(params: PublishLocationParams): Promise<Location> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class PublishLocationActionFail implements PublishLocationAction {
    async run(params: PublishLocationParams): Promise<Location> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
