import Avokado, {
    AvokadoAPIError,
    LocationOrganizationList,
} from '@avokadoapp/avokado-ts'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapLocationsToModel } from '../mappers/SDKResponseMapper'
import { LocationCompact } from '../models/LocationCompact'

type ListLocationParams = Organization.ID

export interface ListLocationAction {
    run(params: ListLocationParams): Promise<LocationCompact[]>
}

@injectable()
export class ListLocationActionLive implements ListLocationAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ListLocationParams): Promise<LocationCompact[]> {
        const request: LocationOrganizationList.Request = params
        const response = await this.avokado.location.organizationList(request)
        return mapLocationsToModel(response)
    }
}

@injectable()
export class ListLocationActionFake implements ListLocationAction {
    async run(params: ListLocationParams): Promise<LocationCompact[]> {
        await sleep(500)

        return [
            {
                id: { ...params, locationId: 1 },
                name: 'Lounge',
                medias: [],
                published: false,
            },
        ]
    }
}

@injectable()
export class ListLocationActionFail implements ListLocationAction {
    async run(params: ListLocationParams): Promise<LocationCompact[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
