import {
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    useColorModeValue,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

interface PasswordInputProps extends Omit<InputProps, 'onChange'> {
    value: string
    setValue: Dispatch<SetStateAction<string>>
    focusTrigger?: boolean
    autoComplete: 'current-password' | 'new-password'
}

export function PasswordInput({
    value,
    setValue,
    focusTrigger,
    ...props
}: PasswordInputProps) {
    const t = useT('auth')
    const passwordInputRef = useRef<HTMLInputElement>(null)
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (focusTrigger) {
            passwordInputRef.current?.focus()
        }
    }, [focusTrigger])

    const bcolor = useColorModeValue('primary.500', 'primary.200')

    return (
        <InputGroup>
            <Input
                ref={passwordInputRef}
                type={show ? 'text' : 'password'}
                placeholder={t('generic_input_password')}
                bg="authBackground"
                border="1px solid var(--chakra-colors-gray-400)"
                _placeholder={{ color: 'label2' }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                {...props}
            />
            <InputRightElement>
                <IconButton
                    alignSelf="end"
                    size="sm"
                    variant="unstyled"
                    color={show ? bcolor : 'label2'}
                    me={2}
                    aria-label={show ? 'Hide' : 'Show'}
                    icon={<Icon as={show ? FaEyeSlash : FaEye} />}
                    onClick={() => setShow((previous) => !previous)}
                />
            </InputRightElement>
        </InputGroup>
    )
}
