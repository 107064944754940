import { VStack } from '@chakra-ui/react'
import ContainerInfoAlert from 'features/location/components/shared/ContainerInfoAlert'
import { LocationTabProps } from 'features/location/pages/LocationPage'
import useT from 'localization/hooks/useT'
import { useCallback } from 'react'
import { useLocalStorage } from 'react-use'
import { Container } from 'uikit/container/Container'
import LocalStorageKeys from 'utils/constant/LocalStorageKeys'
import { LoadingIndicator } from 'utils/loading'
import { useRooms } from '../hooks/useRooms'
import { Room } from '../models/Room'
import { NewRoomForm } from './NewRoomForm'
import { RoomContent } from './RoomContent'

export function RoomTab({ headerRef, location }: LocationTabProps) {
    const t = useT('room')

    const { isLoading, rooms, addRoom } = useRooms(location.id)

    const onNewTerminal = useCallback((room: Room) => {
        addRoom(room)
    }, [])

    const [shouldHideAlert, setShouldHideAlert] = useLocalStorage<boolean>(
        LocalStorageKeys.HideLocationRoomsAlertHelper
    )

    if (isLoading) {
        return <LoadingIndicator />
    }

    return (
        <>
            <ContainerInfoAlert
                isVisible={!shouldHideAlert}
                onClose={() => setShouldHideAlert(true)}
                title={t('room_info_title')}
                description={t('room_info_description')}
            />

            <VStack w="full" spacing={8}>
                {rooms.map((room) => (
                    <RoomContent
                        key={room.id.roomId}
                        locationName={location.name}
                        room={room}
                    />
                ))}

                <Container
                    isVisible={true}
                    title={t('add_room_container_title')}
                    pb={8}
                >
                    <NewRoomForm
                        location={location}
                        rooms={rooms}
                        dismiss={() => {}}
                        onSave={onNewTerminal}
                    />
                </Container>
            </VStack>
        </>
    )
}
