import Avokado, { AvokadoAPIError, MenuList } from '@avokadoapp/avokado-ts'
import { mapMenusToModel } from 'features/menu/mappers/SDKResponseMapper'
import { MenuCompact } from 'features/menu/models/MenuCompact'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

type ListMenuParams = Organization.ID

export interface ListMenuAction {
    run(params: ListMenuParams): Promise<MenuCompact[]>
}

@injectable()
export class ListMenuActionLive implements ListMenuAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ListMenuParams): Promise<MenuCompact[]> {
        const request: MenuList.Request = params
        const response = await this.avokado.menu.list(request)
        return mapMenusToModel(response)
    }
}

@injectable()
export class ListMenuActionFake implements ListMenuAction {
    async run(params: ListMenuParams): Promise<MenuCompact[]> {
        await sleep(500)

        return []
    }
}

@injectable()
export class ListMenuActionFail implements ListMenuAction {
    async run(params: ListMenuParams): Promise<MenuCompact[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
