import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useInjection } from 'inversify-react'
import { useCallback, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'
import { LocationDependencies } from '../dependencies'
import { Location } from '../models/Location'

export function useLocationPublishFormController(
    location: Location,
    onSave: (location: Location) => void
): UseFormControllerHook<void> {
    const publish = useInjection(LocationDependencies.Publish)

    const taskFeedback = useTaskFeedback()
    const organizationManager = useOrganizationContext()

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        if (location.published) return true
        if (location.violations.size > 0) return false
        if (organizationManager.organization?.status !== 'verified')
            return false

        return true
    }, [location, organizationManager.organization])

    const submit = useCallback(async () => {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            const published = await publish.run({
                location: location,
                publish: !location.published,
            })
            onSave(published)
            taskFeedback.succeed()
        } catch (error: any) {
            taskFeedback.fail()
        } finally {
            setIsProcessing(false)
        }
    }, [isProcessing, canSubmit, location])

    return {
        isProcessing,
        canSubmit,
        submit,
        isSuccess: taskFeedback.isSuccess,
        failure: taskFeedback.failure,
    }
}
