import { interfaces } from 'inversify'
import { ListReceiptAction } from './ListReceiptAction'
import { RetrieveReceiptAction } from './RetrieveReceiptAction'

interface _ReceiptDependencies {
    List: interfaces.ServiceIdentifier<ListReceiptAction>
    Retrieve: interfaces.ServiceIdentifier<RetrieveReceiptAction>
}

export const ReceiptDependencies: _ReceiptDependencies = {
    List: Symbol('ListReceiptAction'),
    Retrieve: Symbol('RetrieveReceiptAction'),
}

export type { ListReceiptAction, RetrieveReceiptAction }
