import {
    Box,
    BoxProps,
    Button,
    Center,
    Icon,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Spinner,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import { MediaUploadError } from 'features/media/errors/MediaUploadError'
import { Media } from 'features/media/models/Media'
import { MediaUploadTaskStatus } from 'features/media/models/MediaUploadTaskStatus'
import useT from 'localization/hooks/useT'
import { useEffect, useState } from 'react'
import { DropzoneState } from 'react-dropzone'
import { FaExclamationCircle, FaQuestion } from 'react-icons/fa'

type Size = BoxProps['w']

interface MenuImageUploaderRendererProps {
    currentMedia?: Media
    size?: Size
    bg: 'pageBackground1' | 'pageBackground2' | 'pageBackground3'
    status: MediaUploadTaskStatus
    dropzone: DropzoneState
}

export function MenuImageUploaderRenderer({
    currentMedia,
    status,
    dropzone,
    bg,
    size = '200px',
}: MenuImageUploaderRendererProps) {
    const t = useT('menu')

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isImageValid, setIsImageValid] = useState(currentMedia !== undefined)

    useEffect(() => setIsImageValid(currentMedia !== undefined), [currentMedia])

    return (
        <VStack>
            <Box
                w={size}
                h={size}
                minW={size}
                maxW={size}
                minH={size}
                maxH={size}
                borderRadius="full"
                boxShadow="base"
                overflow="hidden"
                position="relative"
                bg={bg}
            >
                {status.type === 'idle' &&
                    (isImageValid ? (
                        <Image
                            draggable={false}
                            src={currentMedia?.large()}
                            alt="Cover"
                            w="full"
                            h="full"
                            objectFit="cover"
                            cursor="pointer"
                            onClick={onOpen}
                            onError={() => setIsImageValid(false)}
                        />
                    ) : (
                        <Center h="full">
                            <Icon
                                as={FaQuestion}
                                boxSize={16}
                                color={'label1'}
                            />
                        </Center>
                    ))}

                {status.type === 'awaiting-confirmation' && (
                    <Image
                        src={status.url}
                        alt="Cover"
                        w="full"
                        h="full"
                        objectFit="cover"
                        cursor="pointer"
                        onClick={onOpen}
                    />
                )}

                {status.type === 'uploading' && (
                    <>
                        <Image
                            src={status.url}
                            alt="Cover"
                            w="full"
                            h="full"
                            objectFit="cover"
                        />
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            w="full"
                            h="full"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bg="blackAlpha.600"
                            borderRadius="xl"
                        >
                            <Spinner size="xl" />
                        </Box>
                    </>
                )}

                {status.type === 'failure' && (
                    <VStack
                        h="full"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Icon as={FaExclamationCircle} boxSize={16} />
                        <Text
                            textAlign="center"
                            fontSize="xl"
                            fontWeight="semibold"
                        >
                            {status.error instanceof
                                MediaUploadError.ProcessingError &&
                            status.error.moderationLabel
                                ? status.error.moderationLabel
                                : t('media_uploader_error_generic_title')}
                        </Text>
                    </VStack>
                )}
            </Box>

            <Button
                variant="unstyled"
                size="sm"
                color="label3"
                _hover={{
                    color:
                        status.type === 'idle' ||
                        status.type === 'awaiting-confirmation' ||
                        status.type === 'failure'
                            ? 'label2'
                            : 'label3',
                }}
                fontWeight={
                    status.type === 'idle' ||
                    status.type === 'awaiting-confirmation' ||
                    status.type === 'failure'
                        ? 'semibold'
                        : 'normal'
                }
                cursor={
                    status.type === 'idle' ||
                    status.type === 'awaiting-confirmation' ||
                    status.type === 'failure'
                        ? 'pointer'
                        : 'default'
                }
                onClick={
                    status.type === 'idle'
                        ? dropzone.open
                        : status.type === 'awaiting-confirmation'
                        ? status.onReset
                        : status.type === 'failure'
                        ? status.onReset
                        : undefined
                }
            >
                {status.type === 'idle' &&
                    (isImageValid
                        ? t('media_uploader_change_image_button')
                        : t('media_uploader_set_image_button'))}
                {status.type === 'awaiting-confirmation' &&
                    t('media_uploader_remove_image_button')}
                {status.type === 'uploading' &&
                    t('media_uploader_uploading_label')}
                {status.type === 'failure' &&
                    t('media_uploader_try_again_button')}
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="full">
                <ModalOverlay />
                <ModalContent
                    bg="transparent"
                    pointerEvents="none"
                    h="full"
                    p={8}
                >
                    <Center w="full" h="full">
                        <Image
                            src={
                                status.type === 'awaiting-confirmation'
                                    ? status.url
                                    : currentMedia?.original()
                            }
                            alt="Cover"
                            borderRadius="xl"
                            objectFit="contain"
                            maxW="full"
                            maxH="full"
                        />
                    </Center>
                </ModalContent>
            </Modal>
        </VStack>
    )
}
