import useAuthContext from 'features/auth/contexts/AuthContext'
import { useInjection } from 'inversify-react'
import { useCallback, useState } from 'react'
import { TerminalDependencies } from '../dependencies'

interface UseTerminalProvisionHook {
    code: string | undefined
    validity: { from: Date; to: Date } | undefined
    isProcessing: boolean
    retrieve: () => Promise<void>
}

export function useTerminalProvision(): UseTerminalProvisionHook {
    const provisioning = useInjection(TerminalDependencies.Provision)

    const { user } = useAuthContext()

    const [isProcessing, setIsProcessing] = useState(false)
    const [code, setCode] = useState<string>()
    const [validity, setValidity] = useState<{ from: Date; to: Date }>()

    const retrieve = useCallback(async () => {
        if (isProcessing) return

        setIsProcessing(true)
        const { code, expiration } = await provisioning.run({
            userId: user!.id,
        })

        setCode(code)
        setValidity({ from: new Date(), to: expiration })
        setIsProcessing(false)
    }, [isProcessing])

    return { code, validity, isProcessing, retrieve }
}
