import { Center, Container, useColorModeValue } from '@chakra-ui/react'
import { Room } from 'features/room/models/Room'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Stage } from 'react-konva'
import theme from 'theme'
import { Geometry } from 'utils/geometry/Geometry'
import { RoomLayer } from './RoomLayer'
import { TablesLayer } from './TablesLayer'

interface RoomTempProps {
    size: Geometry.Size
    tables: Room.Table[]
    onClick: () => void
}

export function RoomTemp({ size: roomSize, tables, onClick }: RoomTempProps) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number>(1)
    const [height, setHeight] = useState<number>(1)

    useLayoutEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current.clientWidth)
            setHeight(containerRef.current.clientHeight)
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            if (containerRef.current) {
                setWidth(containerRef.current.clientWidth)
                setHeight(containerRef.current.clientHeight)
            }
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const size = useMemo(() => {
        const canvasRatio = width / height
        const roomRatio = roomSize.width / roomSize.height

        let absoluteHeight: number
        let absoluteWidth: number

        if (canvasRatio > roomRatio) {
            absoluteHeight = height
            absoluteWidth = absoluteHeight * roomRatio
        } else {
            absoluteWidth = width
            absoluteHeight = absoluteWidth / roomRatio
        }

        return { width: absoluteWidth, height: absoluteHeight }
    }, [width, height, roomSize])

    const roomBackground = useColorModeValue(
        theme.semanticTokens.colors.pageBackground3.default,
        theme.semanticTokens.colors.pageBackground3._dark
    )

    const scale = useMemo(() => Math.max(size.width, size.height), [size])

    return (
        <Container
            ref={containerRef}
            w="full"
            h="full"
            m={0}
            p={0}
            onClick={onClick}
        >
            <Center w="full" h="md">
                <Stage width={size.width} height={size.height}>
                    <RoomLayer
                        size={size}
                        backgroundColor={roomBackground}
                        onClick={() => {}}
                    />

                    <TablesLayer
                        refreshTrigger={0}
                        canEditTables={false}
                        hidesTableNumber
                        newTable={null}
                        tables={tables}
                        canvasSize={roomSize}
                        canvasScale={scale}
                        selectedTable={null}
                        overlappingTables={[]}
                        onTableClick={() => {}}
                        onUpdate={() => {}}
                        onUpdateStart={() => {}}
                        onUpdateEnd={() => {}}
                        onNewUpdateEnd={() => {}}
                    />
                </Stage>
            </Center>
        </Container>
    )
}
