import { Button, Center, VStack } from '@chakra-ui/react'

import { Box, HStack, Image, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import ReponsiveComponent from 'utils/genericcomponents/ResponsiveComponent'

interface ErrorPageProps {
    title: string
    subTitle: string
    primaryActionLabel: string
    onPrimaryAction: () => void
}

export function ErrorPage(props: ErrorPageProps) {
    const [animationTrigger, setAnimationTrigger] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setAnimationTrigger(true)
        }, 400)
    }, [])

    return (
        <VStack
            opacity={animationTrigger ? 1 : 0}
            transition="0.3s opacity ease-in"
            width="full"
            minHeight="100dvh"
            bg="authBackground"
            justify="center"
        >
            <ReponsiveComponent
                mobile={<MobileComponent {...props} />}
                tablet={<TabletComponent {...props} />}
                desktop={<DesktopComponent {...props} />}
            />
        </VStack>
    )
}

function DesktopComponent({
    title,
    subTitle,
    primaryActionLabel,
    onPrimaryAction,
}: ErrorPageProps) {
    return (
        <HStack height="full" width="full">
            <Center flex="1" flexShrink={1} maxWidth="50%" minWidth="40%">
                <AvokadoHugeLogo />
            </Center>
            <Box>
                <VStack align="start" gap={16} as={motion.div} layout>
                    <VStack align="start">
                        <Text
                            fontSize="6xl"
                            fontWeight="bold"
                            width="full"
                            as={motion.div}
                            layout="position"
                        >
                            {title}
                        </Text>
                        <Text
                            fontSize="2xl"
                            fontWeight="medium"
                            as={motion.div}
                            layout="position"
                            size="xs"
                            pe={16}
                        >
                            {subTitle}
                        </Text>
                    </VStack>

                    <Button
                        borderRadius="full"
                        variant="outline"
                        onClick={onPrimaryAction}
                    >
                        {primaryActionLabel}
                    </Button>
                </VStack>
            </Box>
        </HStack>
    )
}

function TabletComponent({
    title,
    subTitle,
    primaryActionLabel,
    onPrimaryAction,
}: ErrorPageProps) {
    return (
        <HStack height="full" width="full">
            <Box px={16} w="full">
                <VStack align="start" w="full" gap={8} as={motion.div} layout>
                    <VStack align="start" w="full">
                        <Box maxWidth={48} alignSelf="start">
                            <AvokadoHugeLogo />
                        </Box>

                        <Text
                            fontSize="6xl"
                            fontWeight="bold"
                            width="full"
                            as={motion.div}
                            layout="position"
                        >
                            {title}
                        </Text>
                        <Text
                            fontSize="2xl"
                            fontWeight="medium"
                            as={motion.div}
                            layout="position"
                            pe={4}
                        >
                            {subTitle}
                        </Text>
                    </VStack>

                    <Button
                        borderRadius="full"
                        variant="outline"
                        onClick={onPrimaryAction}
                    >
                        {primaryActionLabel}
                    </Button>
                </VStack>
            </Box>
        </HStack>
    )
}

function MobileComponent({
    title,
    subTitle,
    primaryActionLabel,
    onPrimaryAction,
}: ErrorPageProps) {
    return (
        <VStack
            align="center"
            gap={16}
            as={motion.div}
            layout
            mt={16}
            mb={16}
            w="full"
        >
            <VStack align="center" textAlign="center" w="full">
                <Center maxW={32}>
                    <AvokadoHugeLogo />
                </Center>

                <Text
                    fontSize="6xl"
                    fontWeight="bold"
                    width="full"
                    as={motion.div}
                    layout="position"
                >
                    {title}
                </Text>
                <Text
                    fontSize="2xl"
                    fontWeight="medium"
                    as={motion.div}
                    layout="position"
                    px={8}
                >
                    {subTitle}
                </Text>
            </VStack>
            <Center>
                <Button
                    borderRadius="full"
                    variant="outline"
                    width="full"
                    onClick={onPrimaryAction}
                >
                    {primaryActionLabel}
                </Button>
            </Center>
        </VStack>
    )
}

function AvokadoHugeLogo() {
    return <Image width="51%" src="/logo.svg" />
}
