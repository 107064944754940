import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import { RawMedia } from 'features/media/models/RawMedia'
import { Menu } from 'features/menu/models/Menu'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { MenuEntryDependencies } from '../dependencies/entry'
import { mapEntryMediaToModel } from '../mappers/SDKResponseMapper'

export interface UseEntryFormDataHook {
    hasChanges: boolean
    description: string
    price: number
    allergens: Menu.Section.Entry.Allergen[]
    features: Menu.Section.Entry.Feature[]
    extras: Menu.Extra[]
    isUploadingCover: boolean
    rawMedia: RawMedia | undefined
    reset: () => void
    setDescription: Dispatch<SetStateAction<string>>
    setPrice: Dispatch<SetStateAction<number>>
    setAllergens: Dispatch<SetStateAction<Menu.Section.Entry.Allergen[]>>
    setFeatures: Dispatch<SetStateAction<Menu.Section.Entry.Feature[]>>
    setExtras: Dispatch<SetStateAction<Menu.Extra[]>>
    setIsUploadingCover: Dispatch<SetStateAction<boolean>>
    setRawMedia: Dispatch<SetStateAction<RawMedia | undefined>>
}

export function useEntryFormData(
    entry: Menu.Section.Entry
): UseEntryFormDataHook {
    const [description, setDescription] = useState(entry.description ?? '')
    const [price, setPrice] = useState(entry.price)
    const [allergens, setAllergens] = useState(entry.allergens)
    const [features, setFeatures] = useState(entry.features)
    const [extras, setExtras] = useState(entry.extras)
    const [isUploadingCover, setIsUploadingCover] = useState(false)
    const [rawMedia, setRawMedia] = useState<RawMedia>()

    const hasChanges = useMemo(() => {
        return (
            !_.isEqual(description, entry.description ?? '') ||
            !_.isEqual(price, entry.price) ||
            !_.isEqual(allergens, entry.allergens) ||
            !_.isEqual(features, entry.features) ||
            !_.isEqual(extras, entry.extras)
        )
    }, [description, price, allergens, features, extras, entry])

    const reset = useCallback(() => {
        setDescription(entry.description ?? '')
        setPrice(entry.price)
        setAllergens(entry.allergens)
        setFeatures(entry.features)
        setExtras(entry.extras)
        setRawMedia(undefined)
    }, [entry])

    useEffect(reset, [reset])

    return {
        hasChanges,
        description,
        price,
        allergens,
        features,
        extras,
        isUploadingCover,
        rawMedia,
        reset,
        setDescription,
        setPrice,
        setAllergens,
        setFeatures,
        setExtras,
        setIsUploadingCover,
        setRawMedia,
    }
}

export function useEntryFormController(
    menu: Menu,
    section: Menu.Section,
    entry: Menu.Section.Entry,
    isGloballyUpdating: boolean,
    params: UseEntryFormDataHook,
    onSave: (menu: Menu) => void
): UseFormControllerHook<void> & {
    buildMediaUploadLink: (media: File) => Promise<MediaUploadLink>
} {
    const update = useInjection(MenuEntryDependencies.Update)
    const upload = useInjection(MenuEntryDependencies.UploadMedia)

    const [isLocallyProcessing, setIsLocallyProcessing] = useState(false)

    const isProcessing = useMemo(
        () => isGloballyUpdating || isLocallyProcessing,
        [isGloballyUpdating, isLocallyProcessing]
    )

    const canSubmit = useMemo(() => {
        if (!params.hasChanges) return false
        if (params.isUploadingCover) return false
        if (params.price === 0) return false
        return true
    }, [params])

    useEffect(() => {
        if (params.rawMedia) {
            const media = mapEntryMediaToModel(entry.id, params.rawMedia)
            onSave({
                ...menu,
                sections: menu.sections.map((oldSection) => {
                    if (_.isEqual(oldSection.id, section.id)) {
                        return {
                            ...section,
                            entries: section.entries.map((oldEntry) => {
                                if (_.isEqual(oldEntry.id, entry.id)) {
                                    return { ...entry, media }
                                } else {
                                    return oldEntry
                                }
                            }),
                        }
                    } else {
                        return oldSection
                    }
                }),
            })

            params.setRawMedia(undefined)
        }
    }, [params.rawMedia]) // eslint-disable-line react-hooks/exhaustive-deps

    const buildMediaUploadLink = useCallback(
        async (media: File) => {
            return await upload.run({ ...entry.id, media })
        },
        [entry] // eslint-disable-line react-hooks/exhaustive-deps
    )

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsLocallyProcessing(true)

        const newMenu = await update.run({
            menu: menu,
            section: section,
            entry: entry,
            description: params.description,
            price: params.price,
            allergens: params.allergens,
            features: params.features,
            extrasIds: params.extras.map((extra) => extra.id.extraId),
        })
        onSave(newMenu)
        setIsLocallyProcessing(false)
    }

    return { isProcessing, canSubmit, submit, buildMediaUploadLink }
}
