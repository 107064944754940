import Avokado, { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { TableDelete } from '@avokadoapp/avokado-ts/lib/api/Table/Routes'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Room } from '../models/Room'

type DeleteTableRoomParams = Room.Table.ID

export interface DeleteTableRoomAction {
    run(params: DeleteTableRoomParams): Promise<void>
}

@injectable()
export class DeleteTableRoomActionLive implements DeleteTableRoomAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: DeleteTableRoomParams): Promise<void> {
        let request: TableDelete.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            roomId: params.roomId,
            tableId: params.tableId,
        }

        await this.avokado.table.delete(request)
    }
}

@injectable()
export class DeleteTableRoomActionFake implements DeleteTableRoomAction {
    async run(params: DeleteTableRoomParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class DeleteTableRoomActionFail implements DeleteTableRoomAction {
    async run(params: DeleteTableRoomParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
