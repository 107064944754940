import { VStack } from '@chakra-ui/react'
import { UseTakeAwayPolicyFormDataHook } from 'features/location/hooks/policy/useTakeAwayPolicyForm'
import { useTranslation } from 'react-i18next'
import { PriceInput } from 'uikit/form/input'
import { PercentageInput } from 'uikit/form/input/PercentageInput'

interface TakeAwayFormProps {
    formData: UseTakeAwayPolicyFormDataHook
}

export function TakeAwayForm({ formData }: TakeAwayFormProps) {
    const { t } = useTranslation()

    return (
        <VStack align="stretch" pt={8} spacing={12}>
            <PercentageInput
                title={t('Discount')}
                description={t(
                    'Discount percentage applied to each takeout order.'
                )}
                value={formData.percentageDiscount}
                setValue={formData.setPercentageDiscount}
            />
            <PriceInput
                title={t('Upfront Payment Threshold')}
                description={t(
                    'Amount beyond which customers must pay in advance to confirm their order.'
                )}
                value={formData.upfrontPaymentThreshold}
                setValue={formData.setUpfrontPaymentThreshold}
            />
        </VStack>
    )
}
