import Avokado, { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { TableUpdate } from '@avokadoapp/avokado-ts/lib/api/Table/Routes'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Geometry } from 'utils/geometry/Geometry'
import { Room } from '../models/Room'

interface UpdateTableRoomParams extends Room.Table.ID {
    label?: string
    shape?: Geometry.Shape
}

export interface UpdateTableRoomAction {
    run(params: UpdateTableRoomParams): Promise<void>
}

@injectable()
export class UpdateTableRoomActionLive implements UpdateTableRoomAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateTableRoomParams): Promise<void> {
        let request: TableUpdate.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            roomId: params.roomId,
            tableId: params.tableId,
            label: params.label,
        }

        if (params.shape?.type === 'circle') {
            request = {
                ...request,
                center: params.shape.center,
                radius: params.shape.radius,
            }
        } else if (params.shape?.type === 'rectangle') {
            request = {
                ...request,
                center: params.shape.center,
                size: params.shape.size,
            }
        }

        await this.avokado.table.update(request)
    }
}

@injectable()
export class UpdateTableRoomActionFake implements UpdateTableRoomAction {
    async run(params: UpdateTableRoomParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateTableRoomActionFail implements UpdateTableRoomAction {
    async run(params: UpdateTableRoomParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
