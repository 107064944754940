import Avokado, {
    ArchivedOrderSessionList,
    AvokadoAPIError,
} from '@avokadoapp/avokado-ts'
import { endOfDay, startOfDay } from 'date-fns'
import { mapReceiptsToModel } from 'features/finance/mapper/receipt/SDKResponseMapper'
import { ReceiptCompact } from 'features/finance/models/ReceiptCompact'
import { Location } from 'features/location/models/Location'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface ListReceiptParams extends Location.ID {
    day: Date
}

export interface ListReceiptAction {
    run(params: ListReceiptParams): Promise<ReceiptCompact[]>
}

@injectable()
export class ListReceiptActionLive implements ListReceiptAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ListReceiptParams): Promise<ReceiptCompact[]> {
        // TODO: handle hasMore
        const request: ArchivedOrderSessionList.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            bound: startOfDay(params.day),
            before: endOfDay(params.day),
            limit: 80,
        }
        const response = await this.avokado.archivedOrderSession.list(request)
        return mapReceiptsToModel(response)
    }
}

@injectable()
export class ListReceiptActionFake implements ListReceiptAction {
    async run(params: ListReceiptParams): Promise<ReceiptCompact[]> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ListReceiptActionFail implements ListReceiptAction {
    async run(params: ListReceiptParams): Promise<ReceiptCompact[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
