import { Icon, MenuGroup } from '@chakra-ui/react'
import { GB, IT } from 'country-flag-icons/react/3x2'
import { useTranslation } from 'react-i18next'
import { MenuItemRadio } from 'uikit/menu/MenuItemRadio'

export function LanguageSelector() {
    const { t, i18n } = useTranslation()

    return (
        <MenuGroup title={t('Language')}>
            <MenuItemRadio
                text={t('Italian')}
                icon={<Icon as={IT} />}
                isSelected={i18n.resolvedLanguage === 'it'}
                onClick={() => i18n.changeLanguage('it')}
            />
            <MenuItemRadio
                text={t('English')}
                icon={<Icon as={GB} />}
                isSelected={i18n.resolvedLanguage === 'en'}
                onClick={() => i18n.changeLanguage('en')}
            />
        </MenuGroup>
    )
}
