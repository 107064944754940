import { EmailIcon } from '@chakra-ui/icons'
import {
    Button,
    FormControl,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import { AddressForm } from 'features/address/components/AddressForm'
import { AddressSearchInput } from 'features/address/components/AddressSearchInput'
import useAuthContext from 'features/auth/contexts/AuthContext'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import useT from 'localization/hooks/useT'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'uikit/container/Container'
import { FormDivider, FormSection, FormStructure } from 'uikit/form'
import useFormNotification from 'utils/hooks/useFormNotification'
import {
    useLocationAddressFormData,
    useLocationFormController,
    useLocationFormData,
} from '../hooks/useLocationForm'
import { LocationTabProps } from '../pages/LocationPage'
import { CapabilitySection } from './general/CapabilitySection'
import { CapacitySection } from './general/CapacitySection'
import { CategorySection } from './general/CategorySection'
import { SubcategoriesSection } from './general/SubcategoriesSection'

export function GeneralTab({ location, update }: LocationTabProps) {
    const { t: t_legacy } = useTranslation()
    const t = useT('locationgeneral')

    const formData = useLocationFormData(location)
    const addressFormData = useLocationAddressFormData(location)
    const addressChangesAttemptDisclosure = useDisclosure()
    const userGivenName = useAuthContext().user?.givenName

    const controller = useLocationFormController(
        location,
        formData,
        addressFormData,
        update
    )

    const compactName = useMemo(() => {
        const value = formData.name || location.name
        return value.toLowerCase().replace(/\s/g, '')
    }, [formData.name])

    const organization = useOrganizationContext().assertOrganization()

    useFormNotification('general', controller)

    return (
        <>
            <Modal
                isOpen={addressChangesAttemptDisclosure.isOpen}
                onClose={addressChangesAttemptDisclosure.onClose}
                motionPreset="slideInBottom"
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {t('address_changes_exhausted_title')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            {t(
                                'address_changes_exhausted_greetings_description',
                                {
                                    clientName:
                                        userGivenName ??
                                        t('client_name_fallback'),
                                }
                            )}
                        </Text>
                        <Text mt={8}>
                            {t(
                                'address_changes_exhausted_explanation_description'
                            )}
                        </Text>
                        <Text mt={8}>
                            {t(
                                'address_changes_exhausted_solution_description'
                            )}
                        </Text>
                        <Text mt={8}>
                            {t('address_changes_exhausted_thanks_description')}
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="ghost"
                            mr={3}
                            onClick={addressChangesAttemptDisclosure.onClose}
                        >
                            {t('generic_close_button')}
                        </Button>
                        <Button
                            as={Link}
                            href="mailto:support@avokadoapp.ch"
                            leftIcon={<EmailIcon />}
                        >
                            {t('address_changes_exhausted_contact_us_button')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Container
                title={t('generic_information_title')}
                header={
                    <Button
                        alignSelf="end"
                        isLoading={controller.isProcessing}
                        onClick={controller.submit}
                        isDisabled={!controller.canSubmit}
                    >
                        {t('generic_save_button')}
                    </Button>
                }
            >
                <FormStructure>
                    <FormSection
                        title={t_legacy('Name')}
                        subtitle={t_legacy('The name of your establishment.')}
                    >
                        <FormControl>
                            <Input
                                type="text"
                                placeholder={t_legacy('Name')}
                                maxLength={50}
                                value={formData.name}
                                onChange={(e) =>
                                    formData.setName(e.target.value)
                                }
                            />
                            <Text
                                fontSize="xs"
                                color="label2"
                                textAlign="right"
                            >
                                {formData.name.length}/50
                            </Text>
                        </FormControl>
                    </FormSection>

                    <FormDivider />

                    <CategorySection
                        category={formData.category}
                        setCategory={formData.setCategory}
                    />

                    <FormDivider />

                    <SubcategoriesSection
                        category={formData.category}
                        subcategories={formData.subcategories}
                        setSubcategories={formData.setSubcategories}
                    />

                    <FormDivider />

                    <CapacitySection
                        capacity={formData.capacity}
                        setCapacity={formData.setCapacity}
                    />

                    <FormDivider />

                    <CapabilitySection
                        capabilities={formData.capabilities}
                        setCapabilities={formData.setCapabilities}
                    />
                </FormStructure>
            </Container>
            <Container
                title={t('address_label')}
                subtitle={t('address_subtitle')}
                pt={8}
            >
                {addressFormData.address ? (
                    <AddressForm
                        address={addressFormData.address}
                        setAddress={addressFormData.setAddress}
                        onEdit={() => {
                            if (location.remainingAddressChanges > 0)
                                addressFormData.setAddress(undefined)
                            else addressChangesAttemptDisclosure.onOpen()
                        }}
                        onSave={
                            addressFormData.addressLabel !== undefined
                                ? () => {
                                      if (addressFormData.address) {
                                          const address =
                                              addressFormData.address
                                          controller.submitGeodecodeRequest(
                                              `${address.street} ${address.addressNumber}, ${address.postalCode} ${address.municipality} ${address.region}, ${address.country}`
                                          )
                                      }
                                  }
                                : undefined
                        }
                        isSaving={controller.isProcessing}
                        lockedCountry={organization.country}
                    />
                ) : (
                    <AddressSearchInput
                        autoFocus={
                            location.address !== undefined &&
                            addressFormData.address === undefined
                        }
                        onPick={(address, label) => {
                            addressFormData.setAddress({
                                ...address,
                                country: organization.country,
                            })
                            addressFormData.setAddressLabel(label)
                        }}
                        onCancel={
                            location.address !== undefined
                                ? () =>
                                      addressFormData.setAddress(
                                          location.address
                                      )
                                : undefined
                        }
                    />
                )}
            </Container>

            {/* <Container pt={8}>
                <FormStructure>
                    <SocialSection name={compactName} />

                    <FormDivider />

                    <FormSection
                        title={t_legacy('Website')}
                        subtitle={t_legacy(
                            'Connect your private website with our services.'
                        )}
                    >
                        <FormControl>
                            <InputGroup>
                                <InputLeftAddon>
                                    <Code>https://</Code>
                                </InputLeftAddon>
                                <Input
                                    type="url"
                                    placeholder={`www.${compactName}.com`}
                                    maxLength={50}
                                    // value={formData.name}
                                    // onChange={(e) => formData.setName(e.target.value)}
                                />
                            </InputGroup>
                            <Text
                                fontSize="xs"
                                color="label2"
                                textAlign="right"
                            >
                                0/50
                            </Text>
                        </FormControl>
                    </FormSection>
                    <FormDivider />

                    <FormSection
                        title={t_legacy('Contacts')}
                        subtitle={t_legacy(
                            'Add an email to allow customers to reach you.'
                        )}
                    >
                        <FormControl>
                            <InputGroup>
                                <InputLeftElement>
                                    <Icon as={FiMail} />
                                </InputLeftElement>
                                <Input
                                    type="email"
                                    placeholder={t_legacy('contacts_email', {
                                        name: compactName,
                                    })}
                                    maxLength={50}
                                    // value={formData.name}
                                    // onChange={(e) => formData.setName(e.target.value)}
                                />
                            </InputGroup>
                            <Text
                                fontSize="xs"
                                color="label2"
                                textAlign="right"
                            >
                                0/50
                            </Text>
                        </FormControl>
                    </FormSection>
                </FormStructure>
            </Container>  */}
        </>
    )
}
