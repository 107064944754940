import {
    FormControl,
    FormLabelProps,
    InputGroup,
    InputLeftAddon,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction, useState } from 'react'
import { InputLabels } from './InputLabels'

interface BasePercentageInputProps {
    title?: string
    description?: string
    labelProps?: FormLabelProps
}

interface PercentageInputProps {
    value: number | undefined
    isRequired?: false
    setValue: Dispatch<SetStateAction<number | undefined>>
}

interface RequiredPercentageInputProps {
    value: number
    isRequired: true
    setValue: Dispatch<SetStateAction<number>>
}

export function PercentageInput({
    value,
    title,
    description,
    setValue,
    labelProps,
    isRequired = false,
}: (PercentageInputProps | RequiredPercentageInputProps) &
    BasePercentageInputProps) {
    const [rawValue, setRawValue] = useState(
        value === undefined ? (isRequired ? '0' : '') : value
    )
    return (
        <FormControl>
            <InputLabels
                title={title}
                description={description}
                {...labelProps}
            />

            <InputGroup>
                <InputLeftAddon children="%" />
                <NumberInput
                    w="full"
                    min={0}
                    max={100}
                    value={rawValue}
                    onChange={(rawValue, value) => {
                        setRawValue(rawValue)
                        if (isNaN(value)) {
                            // @ts-ignore
                            setValue(isRequired ? 0 : undefined)
                        } else {
                            setValue(Math.max(0, Math.min(value, 100)))
                        }
                    }}
                >
                    <NumberInputField
                        placeholder={title}
                        borderLeftRadius="none"
                    />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </InputGroup>
        </FormControl>
    )
}
