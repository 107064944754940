import { Schedule } from './Calendar'

export type Day = keyof Schedule

export const days: Day[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]
