import { SVGProps } from 'react'

export function MolluscsIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Molluscs"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M98.12.05c22.47-.6 42.05 4.34 58.65 16.36 23.57 17.06 37.88 39.95 42.05 68.13 3.94 26.67-2.02 51.3-17.88 73.55-8.73 12.25-19.93 22.01-33.65 29.44-27.17 14.71-55.37 16.34-83.71 5.23-26.09-10.23-44.49-28.88-55.2-53.83-11.54-26.9-11.01-54.08.71-80.72C15.12 44.5 24.27 32.78 36.1 22.43 49.52 10.68 65.19 4.99 82.17 1.03 87.94-.32 93.81.29 98.12.05zm60.8 96.73c-.4-14.25-5.66-26.82-15.87-37.18-21.07-21.37-72.67-20.5-90.62 5.8-12.39 18.16-14.81 36.71-5.86 56.45 3.78 8.34 10 15.99 4.3 25.8-1.05 1.81-.51 4.43 1.75 5.99 1.93 1.34 3.57.21 5.44-.37 11.25-3.45 22.6-5.69 34.3-1.79 4.91 1.64 9.98 1.98 14.93.39 11.05-3.55 21.87-2.43 32.65.97 8.1 2.55 9.65 1.35 8.62-6.86-.14-1.1-.26-2.27-.75-3.24-2.22-4.34-.94-8.06 1.41-12.05 6.18-10.47 10.25-21.6 9.71-33.89z" />
            <path d="M99.82 51.34c2.61 2.43 2.7 5.47 2.93 8.2 2.05 23.89 2.6 47.8.15 71.7-.27 2.64.34 7.17-2.93 7.27-2.64.08-2.4-4.42-2.65-6.95-2.33-24.09-1.75-48.2.17-72.29.2-2.55.2-5.41 2.32-7.92zM118.74 138.02c4.83-24.76 9.44-48.39 14.19-72.69 7.77 13.06-1.75 63.5-14.19 72.69zM80.56 138.68c-7.34-8.49-9.86-17.57-12.09-26.41-3.43-13.59-4.7-27.57-4.01-41.65.07-1.44-.28-3.6 1.84-3.65 1.7-.04 1.57 1.96 1.81 3.22 4.17 21.55 8.31 43.11 12.44 64.67.14.72.02 1.48.02 3.81z" />
        </svg>
    )
}
