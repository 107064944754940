import { SVGProps } from 'react'

export function SulphitesIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Sulphites"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M111.24 74.21c-2.08 0-3.75.77-4.99 2.31-1.24 1.54-1.86 3.72-1.86 6.56s.62 5.02 1.86 6.56c1.24 1.54 2.91 2.31 4.99 2.31s3.74-.77 4.97-2.31c1.22-1.54 1.83-3.73 1.83-6.56s-.61-5.01-1.84-6.55-2.88-2.32-4.96-2.32z" />
            <path d="M129.25 43.43c-.17-1.5-.63-2-2.1-1.99-9.06.06-18.13.03-27.19.03H72.77c-.95 0-1.79-.01-1.94 1.3-.37 3.31-.8 6.62-1.21 9.92-.96 7.63-1.9 15.26-2.89 22.89-.54 4.2-.75 8.4-.02 12.58 3.41 19.45 21.27 31.96 40.08 28.13 15.37-3.13 26.74-17.27 26.94-33.43.07-5.68-1.05-11.23-1.68-16.83-.86-7.54-1.93-15.05-2.8-22.59zM93.56 94.08c-1.76 1.52-4.25 2.28-7.46 2.28s-5.86-.75-7.74-2.25-2.82-3.56-2.82-6.18h5.02c.16 1.15.47 2.01.94 2.58.85 1.04 2.32 1.55 4.39 1.55 1.24 0 2.25-.14 3.02-.41 1.47-.52 2.2-1.5 2.2-2.92 0-.83-.36-1.48-1.09-1.93-.73-.44-1.87-.84-3.44-1.18l-2.67-.6c-2.62-.59-4.43-1.24-5.43-1.93-1.7-1.16-2.55-2.98-2.55-5.45 0-2.26.82-4.13 2.46-5.62 1.64-1.49 4.05-2.24 7.23-2.24 2.65 0 4.92.7 6.79 2.11s2.86 3.45 2.95 6.13H90.3c-.09-1.52-.75-2.59-1.98-3.23-.82-.42-1.84-.63-3.06-.63-1.36 0-2.44.27-3.25.82-.81.55-1.21 1.31-1.21 2.29 0 .9.4 1.57 1.2 2.02.51.3 1.61.64 3.28 1.04l4.34 1.04c1.9.46 3.34 1.07 4.31 1.83 1.5 1.19 2.26 2.9 2.26 5.14s-.88 4.21-2.64 5.73zm25.93-.61c-1.9 1.96-4.65 2.94-8.25 2.94s-6.35-.98-8.25-2.94c-2.55-2.4-3.83-5.87-3.83-10.39s1.28-8.08 3.83-10.39c1.9-1.96 4.65-2.94 8.25-2.94s6.35.98 8.25 2.94c2.54 2.31 3.81 5.78 3.81 10.39s-1.27 7.99-3.81 10.39z" />
            <path d="M100 0C44.77 0 0 44.77 0 100s44.77 100 100 100 100-44.77 100-100S155.23 0 100 0zm28.93 106.75c-6.65 8.34-15.34 12.9-25.73 13.97-1.06.11-1.33.52-1.32 1.52.02 11.57.03 23.14 0 34.7 0 1.2.36 1.61 1.54 1.6 5.38-.05 10.76-.02 16.14-.02.5 0 1.01-.02 1.48.1.91.25 1.46.9 1.44 1.9-.03.93-.56 1.54-1.42 1.76-.52.13-1.09.11-1.63.11H80.72c-.5 0-1 .03-1.49-.05-1-.17-1.66-.78-1.68-1.85-.02-1.15.67-1.78 1.74-1.94.44-.07.89-.04 1.34-.04 5.23 0 10.46-.04 15.69.02 1.3.01 1.84-.28 1.83-1.78-.06-11.41-.05-22.83 0-34.24 0-1.31-.35-1.7-1.65-1.84-17.15-1.79-30.7-14.88-33.56-32.4-.82-5.02-.34-10.03.31-15.03.89-6.81 1.74-13.63 2.59-20.45.53-4.22 1.02-8.45 1.58-12.67.26-2 .92-2.56 2.86-2.56h59.47c1.94 0 2.6.57 2.85 2.58 1.05 8.29 2.06 16.59 3.08 24.88.52 4.17 1.07 8.34 1.54 12.52 1.22 10.93-1.55 20.71-8.29 29.18z" />
        </svg>
    )
}
