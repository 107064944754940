import { TerminalCreate, TerminalList } from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import { Terminal } from '../models/Terminal'

export function mapTerminalsToModels(
    payload: TerminalList.Response
): Terminal[] {
    return payload.terminals.map((terminal) => {
        return {
            id: {
                organizationId: payload.location.organizationId,
                locationId: payload.location.locationId,
                terminalId: terminal.id,
            },
            name: terminal.name,
            type: mapTypeToModel(terminal.type),
        }
    })
}

export function mapTerminalToModel(
    locationId: Location.ID,
    payload: TerminalCreate.Response
): Terminal {
    return {
        id: {
            organizationId: locationId.organizationId,
            locationId: locationId.locationId,
            terminalId: payload.terminalId,
        },
        name: payload.name,
        type: mapTypeToModel(payload.type),
    }
}

function mapTypeToModel(
    payload: TerminalList.Response.TerminalType
): Terminal.Type {
    switch (payload) {
        case 'monitor':
            return 'monitor'
        case 'registerShared':
            return 'register-shared'
        case 'registerStandard':
            return 'register-standard'
    }
}

export function mapModelToType(
    type: Terminal.Type
): TerminalCreate.Request.TerminalType {
    switch (type) {
        case 'monitor':
            return 'monitor'
        case 'register-shared':
            return 'registerShared'
        case 'register-standard':
            return 'registerStandard'
    }
}
