import { VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { Outlet, useLocation } from 'react-router-dom'
import { FadeToLeft } from 'utils/constant/Animation'

export default function OrganizationLayout() {
    const { pathname } = useLocation()

    return (
        <VStack minH="100vh" align="stretch">
            <motion.div
                key={pathname}
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                {...FadeToLeft}
            >
                <Outlet />
            </motion.div>
        </VStack>
    )
}
