import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import { Schedule } from 'features/calendar/models/Calendar'
import { LocationDependencies } from 'features/location/dependencies'
import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import useT from 'localization/hooks/useT'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'
import {
    UseWeekdayScheduleFormDataHook,
    useWeekdayScheduleFormData,
} from '../../../calendar/hooks/useWeekdayScheduleForm'
import {
    UseScheduledClosuresFormDataHook,
    useScheduledClosuresFormData,
} from './useScheduledClosureForm'

type Day = keyof Schedule

interface UseTimeScheduleFormDataHook {
    hasChanges: boolean
    hasWeekdayScheduleChanges: boolean
    hasScheduledClosuresChanges: boolean
    weekdaySchedule: UseWeekdayScheduleFormDataHook
    scheduledClosures: UseScheduledClosuresFormDataHook
}

export function useTimeScheduleFormData(
    location: Location
): UseTimeScheduleFormDataHook {
    const weekdaySchedule = useWeekdayScheduleFormData(
        location.calendar.weekdays
    )
    const scheduledClosures = useScheduledClosuresFormData(location)

    const hasWeekdayScheduleChanges = useMemo(() => {
        return !_.isEqual(weekdaySchedule.weekdays, location.calendar.weekdays)
    }, [weekdaySchedule, location])

    const hasScheduledClosuresChanges = useMemo(() => {
        return !_.isEqual(
            scheduledClosures.closures,
            location.calendar.scheduledClosures
        )
    }, [scheduledClosures, location])

    const hasChanges = useMemo(() => {
        return hasWeekdayScheduleChanges || hasScheduledClosuresChanges
    }, [hasWeekdayScheduleChanges, hasScheduledClosuresChanges])

    return {
        hasScheduledClosuresChanges,
        hasWeekdayScheduleChanges,
        hasChanges,
        weekdaySchedule,
        scheduledClosures,
    }
}

export function useTimeScheduleFormController(
    location: Location,
    params: UseTimeScheduleFormDataHook,
    onSave: (location: Location) => void
): UseFormControllerHook<void> {
    const update = useInjection(LocationDependencies.Update)
    const t = useT('timeschedule')

    const [isProcessing, setIsProcessing] = useState(false)
    const taskFeedback = useTaskFeedback()

    const canSubmit = useMemo(() => {
        if (!params.hasChanges) return false
        return true
    }, [params, params.scheduledClosures, params.weekdaySchedule])

    const submit = useCallback(async () => {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            for (const day in params.weekdaySchedule.weekdays) {
                if (!params.weekdaySchedule.weekdays.hasOwnProperty(day)) {
                    continue
                }

                const schedule = params.weekdaySchedule.weekdays[day as Day]
                if (schedule.type === 'time-range') {
                    const fullRange = {
                        opensAt: {
                            hours: 0,
                            minutes: 0,
                        },
                        closesAt: {
                            hours: 0,
                            minutes: 0,
                        },
                    }

                    if (schedule.timeRanges.length === 0) {
                        params.weekdaySchedule.markFullDay(false, day as Day)
                    } else if (
                        schedule.timeRanges.length === 1 &&
                        _.isEqual(schedule.timeRanges[0], fullRange)
                    ) {
                        params.weekdaySchedule.markFullDay(true, day as Day)
                    }
                }
            }

            const newLocation = await update.run({
                location: location,
                calendar: {
                    weekdays: params.weekdaySchedule.weekdays,
                    scheduledClosures: params.scheduledClosures.closures,
                },
            })
            onSave(newLocation)
            taskFeedback.succeed()
        } catch (e: unknown) {
            if (
                e instanceof AvokadoAPIError &&
                e.category ===
                    'location.update.invalid-for-published-location' &&
                'failedValidations' in e.metadata &&
                e.metadata.failedValidations ===
                    'menu-assignment.uncovered-time-ranges'
            ) {
                console.log('gotcha')
                taskFeedback.fail({
                    title: t('error_menu_uncovered_time_range_title'),
                    description: t(
                        'error_menu_uncovered_time_range_description',
                        { locationName: location.name }
                    ),
                })
            } else {
                taskFeedback.fail()
            }
        } finally {
            setIsProcessing(false)
        }
    }, [
        params.weekdaySchedule.ranges,
        params.weekdaySchedule.weekdays,
        params.scheduledClosures.closures,
    ])

    return {
        isProcessing,
        canSubmit,
        submit,
        isSuccess: taskFeedback.isSuccess,
        failure: taskFeedback.failure,
    }
}
