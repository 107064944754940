import { useColorModeValue } from '@chakra-ui/react'
import { useScroll, useTransform } from 'framer-motion'
import theme from 'theme'

// TODO
export const MAGIC_NUMBER_TOP_BAR_SIZE_TODO = 53

interface UseContainerScrollHook {
    background: string
    gap: number
    titleOpacity: number
    innerShadow: string
    outerShadow: string
    padding: number
}

export function useContainerScroll(): UseContainerScrollHook {
    const { scrollY } = useScroll()

    const defaultBackground = useColorModeValue(
        theme.semanticTokens.colors.pageBackground2.default,
        theme.semanticTokens.colors.pageBackground2._dark
    )
    const scrollProgress = useTransform(
        scrollY,
        [0, MAGIC_NUMBER_TOP_BAR_SIZE_TODO],
        [0, 1]
    )

    const background = useTransform(
        scrollProgress,
        (latest) =>
            defaultBackground +
            Math.round(latest * 255)
                .toString(16)
                .padStart(2, '0')
    )
    const outerShadow = useTransform(
        scrollProgress,
        (latest) =>
            `0 10px 15px -3px rgba(0, 0, 0, ${latest / 10}), ` +
            `0 4px 6px -2px rgba(0, 0, 0, ${latest / 20})`
    )
    const innerShadow = useTransform(
        scrollProgress,
        (latest) =>
            `0 10px 15px -3px rgba(0, 0, 0, ${(1 - latest) / 10}), ` +
            `0 4px 6px -2px rgba(0, 0, 0, ${(1 - latest) / 20})`
    )
    const gap = useTransform(scrollProgress, (latest) => (1 - latest) * 24)
    const padding = useTransform(scrollProgress, (latest) => (1 - latest) * 4)
    const titleOpacity = useTransform(scrollProgress, (latest) => 1 - latest)

    return {
        // @ts-ignore
        background,
        // @ts-ignore
        gap,
        // @ts-ignore
        titleOpacity,
        // @ts-ignore
        innerShadow,
        // @ts-ignore
        outerShadow,
        // @ts-ignore
        padding,
    }
}
