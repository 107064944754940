import {
    LocationOrganizationList,
    LocationOrganizationRetrieve,
} from '@avokadoapp/avokado-ts'
import { mapRequiredAddressToModel } from 'features/address/mappers/SDKResponseMapper'
import { mapPolicyToModel } from 'features/location/mappers/policy/SDKResponseMapper'
import { mapCalendarToModel } from 'features/location/mappers/timeschedule/SDKResponseMapper'
import { Media } from 'features/media/models/Media'
import { RawMedia } from 'features/media/models/RawMedia'
import { MenuAssignment } from 'features/menuassignment/models/MenuAssignment'
import { Location } from '../models/Location'
import { LocationCompact } from '../models/LocationCompact'
import { computeLocationViolations } from './InternalMapper'
import { mapCapabilitiesToModel } from './capability/SDKResponseMapper'
import {
    mapCategoryToModel,
    mapSubcategoriesToModel,
} from './category/SDKResponseMapper'

export function mapLocationToModel(
    payload: LocationOrganizationRetrieve.Response,
    menuAssignments: MenuAssignment[]
): Location {
    const location: Omit<Location, 'violations'> = {
        id: {
            organizationId: payload.organizationId,
            locationId: payload.locationId,
        },
        name: payload.name,
        published: payload.published,
        restricted: payload.restricted,
        medias: mapLocationMediasToModels(payload, payload.medias),
        address: mapRequiredAddressToModel(payload.address),
        remainingAddressChanges: payload.remainingAddressChangesAttempts,
        policy: mapPolicyToModel(payload.policy),
        calendar: mapCalendarToModel(payload.calendar),
        category: mapCategoryToModel(payload.category),
        subcategories: mapSubcategoriesToModel(payload.subcategories),
        capacity: payload.capacity,
        capabilities: mapCapabilitiesToModel(payload.capabilities),
        menuAssignments: menuAssignments,
        stripe: { id: payload.stripe?.id },
    }

    return { ...location, violations: computeLocationViolations(location) }
}

export function mapLocationsToModel(
    payload: LocationOrganizationList.Response
): LocationCompact[] {
    return payload.locations
        .sort((a, b) => a.locationId - b.locationId)
        .map((location) => {
            return {
                id: {
                    organizationId: location.organizationId,
                    locationId: location.locationId,
                },
                name: location.name,
                published: location.published,
                medias: mapLocationMediasToModels(location, location.medias),
            }
        })
}

function mapLocationMediasToModels(
    id: Location.ID,
    payload: LocationOrganizationList.Response.MediaSource[]
): Media[] {
    return payload.map((media) => mapLocationMediaToModel(id, media))
}

export function mapLocationMediaToModel(
    id: Location.ID,
    payload: RawMedia | LocationOrganizationList.Response.MediaSource
): Media {
    if ('id' in payload) {
        return new Media(
            payload.id,
            `O/${id.organizationId}/L/${id.locationId}/${payload.id}.${payload.extension}`
        )
    } else {
        return new Media(
            payload.source,
            `O/${id.organizationId}/L/${id.locationId}/${payload.source}.${payload.format}`
        )
    }
}
