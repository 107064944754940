import {
    Box,
    Button,
    Center,
    Circle,
    HStack,
    Heading,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Portal,
    Tab,
    Tabs,
    Text,
    VStack,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useTopBarContext } from 'infra/navigation/topbar/TopBar'
import {
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { FaChevronCircleRight } from 'react-icons/fa'
import { Container } from 'uikit/container/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { ScaffoldContext } from './context'
import { ScaffoldAction, TabEntry } from './types'

interface ScaffoldProps extends PropsWithChildren {
    title?: string
    tabs?: TabEntry[]
}

export default function Scaffold(props: ScaffoldProps) {
    const { tabs } = props
    const [primaryAction, setPrimaryAction] = useState<ScaffoldAction>()
    const [secondaryAction, setSecondaryAction] = useState<ScaffoldAction>()

    const [tab, setTab] = useQueryParam('tab', StringParam)
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        if (!tabs) return
        const allowedTabs = tabs.map((tab) => tab.id)
        if (!tab || !allowedTabs.includes(tab)) {
            setTab(allowedTabs[0])
            setTabIndex(0)
        } else {
            setTab(tab)
            setTabIndex(allowedTabs.indexOf(tab))
        }
    }, [tab])

    const onTabSelected = useCallback(
        (index: number) => {
            window.scrollTo({ top: 0, behavior: 'instant' })
            if (!tabs) {
                return
            }
            setTab(tabs[index].id)
            setTabIndex(index)
        },
        [tabs]
    )

    const screen = useScreenBreakpoint()
    const buttonBorderColor = useColorModeValue('primary-500', 'primary-200')

    return (
        <ScaffoldContext.Provider
            value={{
                setPrimaryAction,
                setSecondaryAction,
            }}
        >
            <Tabs
                isLazy
                w="full"
                index={tabIndex}
                onChange={onTabSelected}
                variant="soft-rounded"
                pb={8}
            >
                {screen !== 'desktop' && (
                    <TabsListMobile
                        onTabSelected={onTabSelected}
                        tabIndex={tabIndex}
                        {...props}
                    />
                )}
                {screen === 'desktop' && (
                    <TabsListDesktop
                        primaryAction={primaryAction}
                        onTabSelected={onTabSelected}
                        tabIndex={tabIndex}
                        {...props}
                    />
                )}

                {screen !== 'desktop' && props.children}

                {screen !== 'desktop' && (
                    <>
                        <Button pointerEvents="none" opacity={0} my={8}>
                            _Layout
                        </Button>
                        <Portal>
                            <Box
                                position="fixed"
                                bottom={0}
                                width="100%"
                                zIndex={31}
                                boxShadow="0px -4px 12px rgba(33, 33, 33, 0.1)"
                                sx={{
                                    '-webkit-backdrop-filter': 'blur(10px)',
                                    'backdrop-filter': 'blur(10px)',
                                }}
                            >
                                <Center width="100%">
                                    <AnimatePresence>
                                        {primaryAction && (
                                            <motion.div
                                                initial={{
                                                    right: 30,
                                                    opacity: 0,
                                                }}
                                                animate={{
                                                    right: 0,
                                                    opacity: 1,
                                                }}
                                                exit={{ right: 30, opacity: 0 }}
                                            >
                                                <Button
                                                    width="350px"
                                                    size="lg"
                                                    my={6}
                                                    onClick={
                                                        primaryAction.onClick
                                                    }
                                                    isLoading={
                                                        primaryAction.isLoading
                                                    }
                                                >
                                                    {primaryAction.label}
                                                </Button>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </Center>
                            </Box>
                        </Portal>
                    </>
                )}
            </Tabs>
        </ScaffoldContext.Provider>
    )
}

interface TabsListProps extends PropsWithChildren {
    title?: string
    tabs?: TabEntry[]
    tabIndex: number
    primaryAction?: ScaffoldAction
    onTabSelected: (t: number) => void
}

function TabsListDesktop({
    title,
    tabs,
    tabIndex,
    onTabSelected,
    children,
}: TabsListProps) {
    const badgeColor = useColorModeValue('primary.400', 'primary.300')

    if (!tabs) return null

    return (
        <HStack w="full" align="top">
            <VStack
                align="start"
                w="xs"
                pt={14}
                // mt={-10}
                ps={8}
                top={84}
                position="sticky"
                overflowY="auto"
                h="fit-content"
            >
                <Heading color="accent" size="lg" pb={6}>
                    {title}
                </Heading>
                {tabs.map((t, index) => (
                    <Tab
                        key={t.name}
                        fontWeight="semibold"
                        minW="fit-content"
                        onClick={() => onTabSelected(index)}
                    >
                        <HStack>
                            {t.shouldPresentBadge && (
                                <Circle size={2} bg={badgeColor} />
                            )}
                            <Text>{t.name}</Text>
                        </HStack>
                    </Tab>
                ))}
            </VStack>
            <VStack
                overflowY="auto"
                w="full"
                px={8}
                py={8}
                align="start"
                maxW="8xl"
            >
                <Heading size="3xl" pb={8}>
                    {tabs[tabIndex].name}
                </Heading>
                {children}
            </VStack>
        </HStack>
    )
}

function TabsListMobile({
    title,
    tabs,
    tabIndex,
    onTabSelected,
}: TabsListProps) {
    const divRef = useRef<HTMLDivElement | null>(null)
    const [y, setY] = useState(0)

    const badgeColor = useColorModeValue('primary.400', 'primary.300')

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        const getAbsolutePosition = (element: HTMLDivElement) => {
            return element.getBoundingClientRect().bottom
        }

        const handleEvent = () => {
            if (divRef.current) {
                const newPos = getAbsolutePosition(divRef.current)
                setY(newPos)
            }
        }

        window.addEventListener('resize', handleEvent)
        window.addEventListener('scroll', handleEvent)

        handleEvent()

        return () => {
            window.removeEventListener('resize', handleEvent)
            window.removeEventListener('scroll', handleEvent)
        }
    }, [])

    const tb = useTopBarContext()

    if (!tabs) return null
    return (
        <Box ref={divRef} w="full" pb={8}>
            <Container mt={8}>
                <VStack w="full" align="start">
                    {title && <Heading>{title}</Heading>}
                    <Menu>
                        <MenuButton
                            width="full"
                            bg="pageBackground2"
                            as={Button}
                            variant="link"
                            w="fit-content"
                            rightIcon={<FaChevronCircleRight />}
                        >
                            {tabs[tabIndex].name}
                        </MenuButton>
                        <MenuList>
                            {tabs.map((t, index) => (
                                <MenuItem
                                    key={t.name}
                                    onClick={() => onTabSelected(index)}
                                >
                                    <HStack
                                        flex={1}
                                        justifyContent="space-between"
                                    >
                                        <Text>{t.name}</Text>
                                        {t.shouldPresentBadge && (
                                            <Circle size={2} bg={badgeColor} />
                                        )}
                                    </HStack>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </VStack>
            </Container>
        </Box>
    )
}
