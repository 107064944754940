import { DateDependencies } from 'infra/di/factories/Date'
import { useInjection } from 'inversify-react'
import { DependencyList, useEffect } from 'react'
import useEventsContext from '../contexts/EventsContext'
import { EventPayload } from '../models/EventNotification'

export function useEventEffect(
    handler: (event: EventPayload) => void,
    tollerance: number = 1000,
    deps: DependencyList = []
) {
    const dateGenerator = useInjection(DateDependencies.Generator)
    const eventsContext = useEventsContext()

    useEffect(() => {
        if (!eventsContext.lastEvent) {
            return
        }
        const now = dateGenerator()
        const eventAge: number =
            now.getTime() - eventsContext.lastEvent.timestamp.getTime()

        if (eventAge <= tollerance) {
            handler(eventsContext.lastEvent.payload)
        }
    }, [eventsContext.lastEvent, ...deps])
}
