import { HStack, MenuIcon, MenuItem, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { FaCheck } from 'react-icons/fa'

interface MenuItemRadioProps {
    text: string
    icon: ReactElement
    isSelected: boolean
    onClick: () => void
}

export function MenuItemRadio({
    text,
    icon,
    isSelected,
    onClick,
}: MenuItemRadioProps) {
    return (
        <MenuItem icon={icon} onClick={onClick}>
            <HStack>
                <Text flex={1}>{text}</Text>
                {isSelected && (
                    <MenuIcon
                        fontSize="0.8em"
                        marginStart="0.75rem"
                        justifySelf="end"
                    >
                        <FaCheck />
                    </MenuIcon>
                )}
            </HStack>
        </MenuItem>
    )
}
