import { useNotification } from 'features/notification/hooks/useNotification'
import useT from 'localization/hooks/useT'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { passwordValidation } from 'utils/form/validation'
import useAuthContext from '../contexts/AuthContext'
import useAuthProxyContext from '../contexts/AuthProxyContext'

interface UseResetPasswordFormDataHook {
    password: string
    confirmPassword: string
    confirmationCode: string
    setPassword: Dispatch<SetStateAction<string>>
    setConfirmPassword: Dispatch<SetStateAction<string>>
    setConfirmationCode: Dispatch<SetStateAction<string>>
}

export function useResetPasswordFormData(): UseResetPasswordFormDataHook {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmationCode, setConfirmationCode] = useState('')

    return {
        password,
        confirmPassword,
        confirmationCode,
        setPassword,
        setConfirmPassword,
        setConfirmationCode,
    }
}

export function useResetPasswordFormController(
    params: UseResetPasswordFormDataHook
): UseFormControllerHook<void> {
    const t = useT('auth')
    const notification = useNotification()
    const { email } = useAuthProxyContext()
    const { confirmPasswordForgotten } = useAuthContext()

    const navigate = useNavigate()

    const [isProcessing, setIsProcessing] = useState(false)

    useEffect(() => {
        if (!email) navigate('/sign-in')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const canSubmit = useMemo(() => {
        if (params.confirmationCode.length !== 6) return false
        if (!passwordValidation.isValidSync({ password: params.password })) {
            return false
        }

        return true
    }, [params.confirmationCode, params.password, params.confirmPassword])

    async function submit() {
        if (isProcessing || !canSubmit) return

        setIsProcessing(true)
        try {
            await confirmPasswordForgotten(
                params.password,
                params.confirmationCode
            )
        } catch (e: any) {
            notification({
                id: 'invalid-code',
                title: t('reset_password_invalid_code_notification_title'),
                description: t('reset_password_invalid_code_notification_body'),
                variant: 'error',
            })
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
