import axios, { AxiosError } from 'axios'
import {
    ConfigurationDependencies,
    ConfigurationStore,
} from 'features/config/dependencies'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { AuthError } from '../errors'
import { Cognito } from '../models/CognitoModels'

interface RefreshTokenParams {
    refreshToken: string
}

interface RefreshTokenResult {
    accessToken: string
    idToken: string
}

export interface RefreshTokenAction {
    run(params: RefreshTokenParams): Promise<RefreshTokenResult>
}

@injectable()
export class RefreshTokenActionLive implements RefreshTokenAction {
    @inject(ConfigurationDependencies.Store) private config!: ConfigurationStore

    async run(params: RefreshTokenParams): Promise<RefreshTokenResult> {
        const configuration = await this.config.configuration()

        const request: Cognito.Request.RefreshToken = {
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: {
                REFRESH_TOKEN: params.refreshToken,
            },
            ClientId: configuration.aws.userPool.dashboardClientId,
        }

        try {
            const response = await axios.post<Cognito.Response.RefreshToken>(
                `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/x-amz-json-1.1',
                        'X-Amz-Target':
                            'AWSCognitoIdentityProviderService.InitiateAuth',
                    },
                }
            )

            return {
                accessToken: response.data.AuthenticationResult.AccessToken,
                idToken: response.data.AuthenticationResult.IdToken,
            }
        } catch (e) {
            if (
                e instanceof AxiosError &&
                e.response &&
                'message' in e.response.data
            ) {
                if (e.response.data.message === 'Refresh Token has expired') {
                    const event = new CustomEvent('refresh_token_expired')
                    document.dispatchEvent(event)

                    throw new AuthError.SessionExpired()
                }
            }

            throw e
        }
    }
}

@injectable()
export class RefreshTokenActionFake implements RefreshTokenAction {
    async run(params: RefreshTokenParams): Promise<RefreshTokenResult> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class RefreshTokenActionFail implements RefreshTokenAction {
    async run(params: RefreshTokenParams): Promise<RefreshTokenResult> {
        await sleep(500)

        const event = new CustomEvent('refresh_token_expired')
        document.dispatchEvent(event)

        throw new AuthError.SessionExpired()
    }
}
