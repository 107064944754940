import Avokado, {
    AvokadoAPIError,
    MenuEntryCreate,
} from '@avokadoapp/avokado-ts'
import { mapEntryToModel } from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import _ from 'lodash'
import { sleep } from 'utils/asyncawait/sleep'

interface CreateMenuEntryParams {
    menu: Menu
    section: Menu.Section
    name: string
    description?: string
    price: number
    allergens: Menu.Section.Entry.Allergen[]
    features: Menu.Section.Entry.Feature[]
}

export interface CreateMenuEntryAction {
    run(params: CreateMenuEntryParams): Promise<Menu>
}

@injectable()
export class CreateMenuEntryActionLive implements CreateMenuEntryAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateMenuEntryParams): Promise<Menu> {
        const request: MenuEntryCreate.Request = {
            organizationId: params.section.id.organizationId,
            menuId: params.section.id.menuId,
            menuSectionId: params.section.id.sectionId,
            name: params.name,
            description: params.description,
            price: params.price,
            allergens: _.sum(params.allergens),
            features: _.sum(params.features),
        }

        const response = await this.avokado.menuEntry.create(request)
        const entry = mapEntryToModel(params.section.id, undefined, response)
        return {
            ...params.menu,
            sections: params.menu.sections.map((section) => {
                if (_.isEqual(section.id, params.section.id)) {
                    return {
                        ...params.section,
                        entries: [...params.section.entries, entry],
                    }
                } else {
                    return section
                }
            }),
        }
    }
}

@injectable()
export class CreateMenuEntryActionFake implements CreateMenuEntryAction {
    async run(params: CreateMenuEntryParams): Promise<Menu> {
        await sleep(500)

        return {
            ...params.menu,
            sections: params.menu.sections.map((section) => {
                if (_.isEqual(section.id, params.section.id)) {
                    const entry: Menu.Section.Entry = {
                        id: {
                            ...params.section.id,
                            entryId: params.section.entries.length + 1,
                        },
                        name: params.name,
                        description: params.description,
                        media: undefined,
                        price: params.price,
                        allergens: params.allergens,
                        features: params.features,
                        extras: [],
                    }
                    return {
                        ...params.section,
                        entries: [...params.section.entries, entry],
                    }
                } else {
                    return section
                }
            }),
        }
    }
}

@injectable()
export class CreateMenuEntryActionFail implements CreateMenuEntryAction {
    async run(params: CreateMenuEntryParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
