import { FormLabelProps, InputProps } from '@chakra-ui/react'
import { usePriceFormatter } from 'utils/types/formatters'
import { PrecisionInput } from './PrecisionInput'

interface BasePriceInputProps extends Pick<InputProps, 'variant' | 'bg'> {
    title?: string
    description?: string
    direction?: 'horizontal' | 'vertical'
    moreInfo?: string
    labelProps?: FormLabelProps
    isElastic?: boolean
}

interface PriceInputProps {
    value: number | undefined
    isRequired?: false
    setValue: (value: number | undefined) => void
}

interface RequiredPriceInputProps {
    value: number
    isRequired: true
    setValue: (value: number) => void
}

export function PriceInput({
    isElastic,
    ...props
}: (PriceInputProps | RequiredPriceInputProps) & BasePriceInputProps) {
    const formatter = usePriceFormatter()
    return (
        <PrecisionInput
            leftAddon={formatter.symbol}
            {...props}
            w={isElastic ? 'full' : 'xs'}
        />
    )
}
