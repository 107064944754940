import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import { useList } from 'react-use'
import { RoomDependencies } from '../dependencies'
import { Room } from '../models/Room'

interface UseRoomsHook {
    isLoading: boolean
    rooms: Room[]
    addRoom: (room: Room) => void
}

export function useRooms(id: Location.ID) {
    const list = useInjection(RoomDependencies.List)

    const [isLoading, setIsLoading] = useState(true)
    const [rooms, { set: setRooms, push: addRoom }] = useList<Room>()

    useEffect(() => {
        async function init() {
            setRooms(await list.run(id))
            setIsLoading(false)
        }

        init()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return { isLoading, rooms, addRoom }
}
