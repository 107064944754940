import { days } from '.'
import { Schedule, Weekdays } from '../models/Calendar'

export function normalized(...schedules: Schedule[]) {
    const intervals = days
        .flatMap((day, index) =>
            schedules.flatMap((schedule) =>
                dayScheduleNormalized(schedule[day], index)
            )
        )
        .sort((a, b) => a[0] - b[0])

    if (intervals.length <= 1) {
        return intervals
    }

    const mergedIntervals: [number, number][] = [intervals[0]]

    for (let i = 1; i < intervals.length; i++) {
        const currentInterval = intervals[i]
        const lastMergedInterval = mergedIntervals[mergedIntervals.length - 1]

        if (currentInterval[0] <= lastMergedInterval[1] + 1) {
            lastMergedInterval[1] = Math.max(
                lastMergedInterval[1],
                currentInterval[1]
            )
        } else {
            mergedIntervals.push(currentInterval)
        }
    }
    return mergedIntervals
}

function dayScheduleNormalized(
    schedule: Weekdays.DaySchedule,
    ordinal: number
): [number, number][] {
    if (schedule.type === 'full-day') {
        return schedule.isActive
            ? [[ordinal * 1440, (ordinal + 1) * 1440 - 1]]
            : []
    } else {
        return schedule.timeRanges.flatMap(({ from, to }) => {
            let start = ordinal * 1440 + from.hours * 60 + from.minutes
            let end = ordinal * 1440 + to.hours * 60 + to.minutes

            if (end < start) {
                end += 1440
            }

            if (end >= 1440 * 7) {
                return [
                    [start, 1440 * 7],
                    [0, end - 1440 * 7],
                ]
            } else {
                return [[start, end]]
            }
        })
    }
}
