import { startOfMonth } from 'date-fns'
import { Organization } from 'features/organization/models/Organization'
import { useInjection } from 'inversify-react'
import { useCallback, useEffect, useState } from 'react'
import { useMap } from 'react-use'
import { PayoutDependencies } from '../dependencies/payout'
import { Payout } from '../models/Payout'

interface UsePayoutsHook {
    isLoading: boolean
    payouts: Payout[]
    month: Date
    changeMonth: (month: Date) => Promise<void>
}

export function usePayouts(id: Organization.ID): UsePayoutsHook {
    const list = useInjection(PayoutDependencies.List)

    const [isLoading, setIsLoading] = useState(false)
    const [payouts, setPayouts] = useState<Payout[]>([])
    const [month, setMonth] = useState(startOfMonth(new Date()))
    const [, { get, set }] = useMap<{ [key: string]: Payout[] }>()

    useEffect(() => {
        changeMonth(month)
    }, [])

    const changeMonth = useCallback(
        async (month: Date) => {
            if (isLoading) return
            setIsLoading(true)

            const referenceDate = startOfMonth(month)
            setMonth(referenceDate)

            const key = referenceDate.toLocaleDateString()
            const storedPayouts = get(key)

            if (storedPayouts) {
                setPayouts(storedPayouts)
            } else {
                const data = await list.run({ ...id, month })
                set(key, data)
                setPayouts(data)
            }

            setIsLoading(false)
        },
        [isLoading]
    )

    return {
        isLoading,
        payouts,
        month,
        changeMonth,
    }
}
