import { KonvaEventObject } from 'konva/lib/Node'
import { Geometry } from 'utils/geometry/Geometry'

interface CanvasAdapterType {
    handleFloorClick: (
        event: KonvaEventObject<MouseEvent>,
        scale: number
    ) => Geometry.Point
    getNewGeometry: (
        event: KonvaEventObject<Event>,
        itemShape: Geometry.Shape,
        scale: number
    ) => Geometry.Shape
}

const CanvasAdapter: CanvasAdapterType = {
    handleFloorClick: (event: KonvaEventObject<MouseEvent>, scale: number) => {
        return {
            x: event.evt.offsetX / scale,
            y: event.evt.offsetY / scale,
        }
    },
    getNewGeometry: (
        event: KonvaEventObject<Event>,
        itemShape: Geometry.Shape,
        scale: number
    ) => {
        if (itemShape.type === 'circle') {
            return {
                ...itemShape,
                center: {
                    x: event.target.x() / scale,
                    y: event.target.y() / scale,
                },
                radius: itemShape.radius * event.target.scaleX(),
            }
        } else {
            const size = {
                width: itemShape.size.width * event.target.scaleX(),
                height: itemShape.size.height * event.target.scaleY(),
            }
            return {
                ...itemShape,
                center: {
                    x: event.target.x() / scale + size.width / 2,
                    y: event.target.y() / scale + size.height / 2,
                },
                size,
            }
        }
    },
}

export default CanvasAdapter
