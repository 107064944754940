import Avokado, {
    AvokadoAPIError,
    LocationMediaLinksCreate,
} from '@avokadoapp/avokado-ts'
import { mapExtensionToLocationFormat } from 'features/media/mappers/SDKRequestMapper'
import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Location } from '../models/Location'

interface UploadMediaLocationParams extends Location.ID {
    media: File
}

export interface UploadMediaLocationAction {
    run(params: UploadMediaLocationParams): Promise<MediaUploadLink>
}

@injectable()
export class UploadMediaLocationActionLive
    implements UploadMediaLocationAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UploadMediaLocationParams): Promise<MediaUploadLink> {
        const request: LocationMediaLinksCreate.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            format: mapExtensionToLocationFormat(params.media.type),
        }
        return await this.avokado.location.mediaLinksCreate(request)
    }
}

@injectable()
export class UploadMediaLocationActionFake
    implements UploadMediaLocationAction
{
    async run(params: UploadMediaLocationParams): Promise<MediaUploadLink> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UploadMediaLocationActionFail
    implements UploadMediaLocationAction
{
    async run(params: UploadMediaLocationParams): Promise<MediaUploadLink> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
