import { EditIcon, WarningIcon } from '@chakra-ui/icons'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Input,
    Kbd,
    Select,
    Spacer,
    Stack,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import _ from 'lodash'
import { useRef } from 'react'
import { Trans } from 'react-i18next'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { UseRoomControllerHook } from '../hooks/canvas-related-logic/useRoomController'

interface EditRoomHeaderProps {
    newC: UseRoomControllerHook
}

export default function EditRoomHeader({ newC }: EditRoomHeaderProps) {
    const t = useT('room')
    const screen = useScreenBreakpoint()

    if (newC.canvasState.stateType === 'create') {
        return (
            <VStack w="full" pe={24} align="start">
                <Stack
                    w="full"
                    align="baseline"
                    direction={screen === 'mobile' ? 'column' : 'row'}
                >
                    <Text>{t('edit_room_header_next_table_label')}</Text>
                    <Input
                        variant="filled"
                        value={newC.labelInputValue}
                        onChange={(e) =>
                            newC.updateNextTableLabel(e.target.value)
                        }
                        w="2xs"
                    />
                    <Spacer />
                    <Select
                        w="2xs"
                        value={newC.canvasState.nextShape.type}
                        onChange={(ev) =>
                            // @ts-ignore
                            newC.enableCreateMode(ev.target.value)
                        }
                    >
                        <option value="circle">
                            {t('edit_room_header_circle')}
                        </option>
                        <option value="rectangle">
                            {t('edit_room_header_rectangle')}
                        </option>
                    </Select>
                </Stack>
                <Text fontSize="lg" fontWeight="normal">
                    {t('edit_room_header_create_table_description')}
                    {/* Click anywhere to create a new rounded table. Click on a
                    table to edit it or delete it. Drag tables around to change
                    their position. */}
                </Text>
            </VStack>
        )
    } else if (newC.canvasState.stateType === 'table-selected') {
        // @ts-ignore
        return <SelectedTableEditRoomHeader newC={newC} />
    } else {
        return null
    }
}

function SelectedTableEditRoomHeader({
    newC,
}: EditRoomHeaderProps & {
    newC: {
        canvasState: {
            stateType: 'table-selected'
        }
    }
}) {
    const t = useT('room')
    const screen = useScreenBreakpoint()
    const selectedTable = newC.canvasState.table
    const deleteConfirmation = useDisclosure()
    const cancelRef = useRef(null)

    return (
        <>
            <VStack w="full" pe={24} align="start">
                <Stack
                    w="full"
                    align="baseline"
                    direction={screen === 'mobile' ? 'column' : 'row'}
                >
                    <Text>{t('edit_room_header_selected_table_label')}</Text>
                    <Input
                        variant="filled"
                        value={newC.labelInputValue}
                        onChange={(e) =>
                            newC.updateSelectedTableLabel(e.target.value)
                        }
                        w="2xs"
                    />
                    <Button
                        leftIcon={<EditIcon />}
                        onClick={newC.saveNewLabel}
                        isDisabled={
                            newC.canvasState.originalTableLabel ===
                                newC.labelInputValue ||
                            newC.tables
                                .filter((t) => t !== selectedTable)
                                .map((t) => t.label)
                                .includes(newC.labelInputValue)
                        }
                    >
                        {t('edit_room_header_save_button')}
                    </Button>

                    <Button
                        leftIcon={<WarningIcon />}
                        colorScheme="red"
                        onClick={deleteConfirmation.onOpen}
                    >
                        {t('edit_room_header_delete_button')}
                    </Button>

                    <Spacer />
                    <Select
                        w="2xs"
                        value={newC.canvasState.table.shape!.type}
                        onChange={(ev) => {
                            // @ts-ignore
                            const newType: 'circle' | 'rectangle' =
                                ev.target.value
                            if (newType === selectedTable.shape?.type) return

                            let newGeometry = _.clone(selectedTable.shape)!
                            if (newGeometry.type === 'circle') {
                                newGeometry = {
                                    type: 'rectangle',
                                    center: newGeometry.center,
                                    size: {
                                        width: newGeometry.radius * 2,
                                        height: newGeometry.radius * 2,
                                    },
                                }
                            } else {
                                newGeometry = {
                                    type: 'circle',
                                    center: newGeometry.center,
                                    radius:
                                        (newGeometry.size.width +
                                            newGeometry.size.height) /
                                        4,
                                }
                            }

                            newC.updateTableGeometry(
                                selectedTable.id.tableId,
                                newGeometry
                            )
                        }}
                    >
                        <option value="circle">
                            {t('edit_room_header_circle')}
                        </option>
                        <option value="rectangle">
                            {t('edit_room_header_rectangle')}
                        </option>
                    </Select>
                </Stack>
                <Text fontSize="lg" fontWeight="normal">
                    {t('edit_room_header_edit_table_description')}
                    {/* Drag the small white squares to resize. Switch between
                    rectangle and circle using the above selection. Click on the
                    floor to deselect. */}
                </Text>
            </VStack>
            <AlertDialog
                motionPreset="slideInBottom"
                size="2xl"
                leastDestructiveRef={cancelRef}
                onClose={deleteConfirmation.onClose}
                isOpen={deleteConfirmation.isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>
                        {t('edit_room_header_delete_dialog_title')}
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        <Trans
                            t={t}
                            i18nKey="edit_room_header_delete_dialog_body"
                            components={[<Kbd />]}
                            values={{
                                tableLabel: newC.canvasState.table.label,
                            }}
                        />
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={deleteConfirmation.onClose}
                            colorScheme="teal"
                            variant="outline"
                        >
                            {t('edit_room_header_delete_cancel_button')}
                        </Button>
                        <Button
                            colorScheme="red"
                            ml={3}
                            onClick={newC.deleteSelectedTable}
                        >
                            {t('edit_room_header_delete_confirm_button')}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}
