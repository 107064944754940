import { Box, Button } from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { Organization } from 'features/organization/models/Organization'
import { useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container } from 'uikit/container/Container'

function title(status: Organization.Status) {
    switch (status) {
        case 'verified':
            return 'Congratulations!'

        case 'pending':
            return 'Almost there!'

        case 'moreDocumentsNeeded':
            return 'Documents missing'
    }
}

function subtitle(status: Organization.Status) {
    switch (status) {
        case 'verified':
            return 'You will receive an email shortly. Meanwhile, you can continue to manage your organization.'

        case 'pending':
            return 'Your information is currently being processed. You will receive an email as soon as the process is complete. Meanwhile, you can continue to manage your organization.'

        case 'moreDocumentsNeeded':
            return 'Unfortunately we are missing some documents before we can verify your account. Feel free to resume the procedure at any time.'
    }
}

export default function StripeReturnPage() {
    const organizationManager = useOrganizationContext()

    const navigate = useNavigate()

    const organization = useMemo(
        () => organizationManager.assertOrganization(),
        [organizationManager.assertOrganization] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEffect(() => {
        if (organization.status === 'unverified') {
            navigate(`/organizations/${organization.id.organizationId}`)
        }
    }, [organization.status]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box flex={1} display="flex" alignItems="center">
            <Container
                w="50%"
                title={title(organization.status)}
                subtitle={subtitle(organization.status)}
            >
                <Button
                    alignSelf="end"
                    variant="ghost"
                    as={Link}
                    to={`/organizations/${organization.id.organizationId}`}
                >
                    Homepage
                </Button>
            </Container>
        </Box>
    )
}
