import { GridItem, Heading, Text, VStack } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

interface FormSectionProps extends PropsWithChildren {
    title: string
    subtitle: string
}

export function FormSection({ title, subtitle, children }: FormSectionProps) {
    const screen = useScreenBreakpoint()
    if (screen === 'mobile') {
        return (
            <VStack align="start" w="full">
                <Heading size="sm" fontWeight="normal">
                    {title}
                </Heading>
                <Text color="label2">{subtitle}</Text>
                {children}
            </VStack>
        )
    }
    return (
        <>
            <GridItem colSpan={[1, 1]}>
                <VStack align="stretch">
                    <Heading size="sm" fontWeight="normal">
                        {title}
                    </Heading>
                    <Text color="label2">{subtitle}</Text>
                </VStack>
            </GridItem>
            <GridItem colSpan={[1, 2]}>{children}</GridItem>
        </>
    )
}
