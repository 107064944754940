import { Menu } from 'features/menu/models/Menu'
import { MenuCompact } from 'features/menu/models/MenuCompact'
import { createContext, useContext } from 'react'

interface _MenusContext {
    menus: MenuCompact[]
    isLoading: boolean
    load: (id: string) => Promise<void>
    retrieve: (id: Menu.ID) => Menu | undefined
    update: (menu: Menu) => void
    clear: () => void
}

const defaultMenusContext: _MenusContext = {
    menus: [],
    isLoading: false,
    async load(id) {},
    retrieve(id) {
        return undefined
    },
    update(location) {},
    clear() {},
}

export const MenusContext = createContext(defaultMenusContext)
export default function useMenusContext() {
    return useContext(MenusContext)
}
