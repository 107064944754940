import { useBoolean } from '@chakra-ui/react'
import { useInjection } from 'inversify-react'
import { useEffect, useMemo, useState } from 'react'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'
import { MenuDependencies } from '../dependencies/menu'
import { MenuTranslationDependencies } from '../dependencies/translation'
import { buildPreview } from '../mappers/TranslationPreviewFactory'
import { Menu } from '../models/Menu'
import { TranslationObject } from '../models/TranslationObject'
import { TranslationPreview } from '../models/TranslationPreview'

interface UseTranslationPreviewParams {
    menu: Menu
    locale: string
    automaticTranslationTrigger: boolean
}

interface UseTranslationPreviewHook {
    translationPreview: TranslationPreview | undefined
    isProcessing: boolean
    submit: (obj: TranslationObject) => Promise<void>
}

export default function useTranslationPreview({
    menu,
    locale,
    automaticTranslationTrigger,
}: UseTranslationPreviewParams): UseTranslationPreviewHook {
    const menuId = menu.id
    const [isProcessing, { on, off }] = useBoolean()
    const task = useTaskFeedback()
    const retrieve = useInjection(MenuDependencies.Retrieve)
    const listTranslationObjects = useInjection(
        MenuTranslationDependencies.ListObjects
    )
    const updateTranslationObject = useInjection(
        MenuTranslationDependencies.UpdateObject
    )

    const [translationObjects, setTranslationObjects] =
        useState<TranslationObject[]>()

    useEffect(() => {
        async function load() {
            setTranslationObjects(undefined)
            try {
                await retrieve.run({ ...menuId, locale }) // this is just to trigger a full automatic translation. Not the best solution, but quite enough for now
                const translationObjects = await listTranslationObjects.run({
                    ...menuId,
                    locale,
                })

                setTranslationObjects(translationObjects)
            } catch {}
        }
        load()
    }, [menuId, locale, automaticTranslationTrigger])

    const translationPreview = useMemo(() => {
        if (translationObjects)
            return buildPreview(menu, translationObjects, locale)
        else return undefined
    }, [translationObjects])

    async function submit(obj: TranslationObject) {
        if (isProcessing) return
        const translation = obj.translation
        if (!translation) return
        on()
        try {
            await updateTranslationObject.run({
                organizationId: obj.id.organizationId,
                translationObjectId: obj.id.translationObjectId,
                translation,
            })
            if (translationObjects) {
                const copy = [...translationObjects]
                const updated = copy.find(
                    (o) =>
                        o.id.translationObjectId === obj.id.translationObjectId
                )
                if (updated) {
                    updated.mode = 'manual'
                    updated.translation = translation
                    setTranslationObjects(copy)
                } else {
                    copy.push({
                        ...obj,
                        translation: translation,
                        mode: 'manual',
                    })
                    setTranslationObjects(copy)
                }
            }
            task.succeed()
        } catch (e) {
            task.fail()
        } finally {
            off()
        }
    }

    return {
        translationPreview,
        submit,
        isProcessing,
    }
}
