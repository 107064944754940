import { Center, HStack, useColorModeValue } from '@chakra-ui/react'
import { UseRoomControllerHook } from 'features/room/hooks/canvas-related-logic/useRoomController'
import CanvasAdapter from 'features/room/services/CanvasAdapter'
import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Stage } from 'react-konva'
import theme from 'theme'
import { Geometry } from 'utils/geometry/Geometry'
import { RoomLayer } from './RoomLayer'
import { TablesLayer } from './TablesLayer'

interface RoomCanvasProps {
    newC: UseRoomControllerHook
}

export function RoomCanvas({ newC }: RoomCanvasProps) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number>(1)
    const [height, setHeight] = useState<number>(1)

    useLayoutEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current.clientWidth)
            setHeight(containerRef.current.clientHeight)
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            if (containerRef.current) {
                setWidth(containerRef.current.clientWidth)
                setHeight(containerRef.current.clientHeight)
            }
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const size = useMemo(() => {
        const canvasRatio = width / height
        const roomRatio = newC.room.size.width / newC.room.size.height

        let absoluteHeight: number
        let absoluteWidth: number

        if (canvasRatio > roomRatio) {
            absoluteHeight = height
            absoluteWidth = absoluteHeight * roomRatio
        } else {
            absoluteWidth = width
            absoluteHeight = absoluteWidth / roomRatio
        }

        return { width: absoluteWidth, height: absoluteHeight }
    }, [width, height, newC.room.size])

    return <RoomCanvasContent ref={containerRef} newC={newC} size={size} />
}

interface RoomCanvasContentProps {
    newC: UseRoomControllerHook
    size: Geometry.Size
}

const RoomCanvasContent = React.forwardRef<
    HTMLDivElement,
    RoomCanvasContentProps
>(({ newC, size }, ref) => {
    const { t } = useTranslation()

    const roomBackground = useColorModeValue(
        theme.semanticTokens.colors.pageBackground3.default,
        theme.semanticTokens.colors.pageBackground3._dark
    )

    const scale = useMemo(() => Math.max(size.width, size.height), [size])

    return (
        <Center h="full" w="full" display="flex" justifyContent="center">
            <HStack w="full" h="full" align="stretch" spacing={0}>
                <Center ref={ref} w="full" h="full">
                    <Stage width={size.width} height={size.height}>
                        <RoomLayer
                            size={size}
                            backgroundColor={roomBackground}
                            onClick={(event) => {
                                if (newC.canvasState.stateType === 'create') {
                                    const origin =
                                        CanvasAdapter.handleFloorClick(
                                            event,
                                            scale
                                        )
                                    newC.createNewTable(origin)
                                } else {
                                    newC.deselectTable()
                                }
                            }}
                        />

                        <TablesLayer
                            refreshTrigger={newC.resetTrigger}
                            canEditTables={true}
                            newTable={null}
                            tables={newC.tables}
                            canvasSize={size}
                            canvasScale={scale}
                            selectedTable={
                                newC.canvasState.stateType === 'table-selected'
                                    ? newC.canvasState.table.id
                                    : null
                            }
                            overlappingTables={newC.overlappingTables}
                            onTableClick={(table) => newC.selectTable(table)}
                            onUpdate={(event) => {
                                const tableId = parseInt(event.target.name())
                                const tableGeometry = newC.tables.find(
                                    (t) => t.id.tableId === tableId
                                )?.shape
                                if (!tableGeometry) return
                                const newGeometry =
                                    CanvasAdapter.getNewGeometry(
                                        event,
                                        tableGeometry,
                                        scale
                                    )
                                newC.updateOverlappingTables(
                                    tableId,
                                    newGeometry
                                )
                            }}
                            onUpdateStart={(event) => {}}
                            onUpdateEnd={(event) => {
                                const tableId = parseInt(event.target.name())
                                const tableGeometry = newC.tables.find(
                                    (t) => t.id.tableId === tableId
                                )?.shape
                                if (!tableGeometry) return
                                const newGeometry =
                                    CanvasAdapter.getNewGeometry(
                                        event,
                                        tableGeometry,
                                        scale
                                    )
                                newC.updateTableGeometry(tableId, newGeometry)
                            }}
                            onNewUpdateEnd={(event) => {}}
                        />
                    </Stage>
                </Center>
            </HStack>
        </Center>
    )
})
