import Avokado, {
    AvokadoAPIError,
    MenuSectionBatchCreate,
} from '@avokadoapp/avokado-ts'
import { mapSectionsWithoutEntriesToModel } from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface BatchCreateMenuSectionParams {
    menu: Menu
    sections: string[]
}

export interface BatchCreateMenuSectionAction {
    run(params: BatchCreateMenuSectionParams): Promise<Menu>
}

@injectable()
export class BatchCreateMenuSectionActionLive
    implements BatchCreateMenuSectionAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: BatchCreateMenuSectionParams): Promise<Menu> {
        const request: MenuSectionBatchCreate.Request = {
            organizationId: params.menu.id.organizationId,
            menuId: params.menu.id.menuId,
            sections: params.sections.map((section) => ({ name: section })),
        }
        const response = await this.avokado.menuSection.batchCreate(request)
        const sections = mapSectionsWithoutEntriesToModel(response)
        return {
            ...params.menu,
            sections: [...params.menu.sections, ...sections],
        }
    }
}

@injectable()
export class BatchCreateMenuSectionActionFake
    implements BatchCreateMenuSectionAction
{
    async run(params: BatchCreateMenuSectionParams): Promise<Menu> {
        await sleep(500)

        return {
            ...params.menu,
            sections: params.sections.map((section, index) => ({
                id: {
                    ...params.menu.id,
                    sectionId: params.menu.sections.length + index + 1,
                },
                name: section,
                description: undefined,
                media: undefined,
                entries: [],
            })),
        }
    }
}

@injectable()
export class BatchCreateMenuSectionActionFail
    implements BatchCreateMenuSectionAction
{
    async run(params: BatchCreateMenuSectionParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
