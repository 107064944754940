import { Checkbox, Collapse, Radio, RadioGroup, VStack } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from '../../models/Menu'

interface FeaturesInputProps {
    features: Menu.Section.Entry.Feature[]
    setFeatures: Dispatch<SetStateAction<Menu.Section.Entry.Feature[]>>
}

export function FeaturesInput({ features, setFeatures }: FeaturesInputProps) {
    const { t } = useTranslation()

    const toggleFeature = useCallback((feature: Menu.Section.Entry.Feature) => {
        setFeatures((previous) =>
            previous.includes(feature)
                ? previous.filter((current) => current !== feature)
                : [...previous, feature]
        )
    }, [])

    const isFrozen = useMemo(
        () => features.includes(Menu.Section.Entry.Feature.Frozen),
        [features]
    )

    const isVegan = useMemo(
        () => features.includes(Menu.Section.Entry.Feature.Vegan),
        [features]
    )

    const isVegetarian = useMemo(
        () => features.includes(Menu.Section.Entry.Feature.Vegetarian),
        [features]
    )

    const isAlcoholic = useMemo(
        () => features.includes(Menu.Section.Entry.Feature.Alcoholic),
        [features]
    )

    const spicyLevel = useMemo(() => {
        const spicyFeatures = features.filter(
            (feature) =>
                feature === Menu.Section.Entry.Feature.Spicy1 ||
                feature === Menu.Section.Entry.Feature.Spicy2 ||
                feature === Menu.Section.Entry.Feature.Spicy3
        )
        if (spicyFeatures.length === 0) return undefined
        return spicyFeatures[0]
    }, [features])

    const toggleSpicy = useCallback(() => {
        if (spicyLevel) {
            toggleFeature(spicyLevel)
        } else {
            setFeatures((previous) => [
                ...previous,
                Menu.Section.Entry.Feature.Spicy1,
            ])
        }
    }, [spicyLevel])

    const changeSpicyLevel = useCallback(
        (newLevel: Menu.Section.Entry.Feature) => {
            if (!spicyLevel) return

            toggleFeature(spicyLevel)
            toggleFeature(newLevel)
        },
        [spicyLevel]
    )

    return (
        <VStack align="start">
            <Checkbox
                isChecked={isFrozen}
                onChange={() =>
                    toggleFeature(Menu.Section.Entry.Feature.Frozen)
                }
            >
                {t('Frozen')}
            </Checkbox>

            <Checkbox
                isChecked={isVegan}
                onChange={() => toggleFeature(Menu.Section.Entry.Feature.Vegan)}
            >
                {t('Vegan')}
            </Checkbox>

            <Checkbox
                isChecked={isVegetarian}
                onChange={() =>
                    toggleFeature(Menu.Section.Entry.Feature.Vegetarian)
                }
            >
                {t('Vegetarian')}
            </Checkbox>

            <Checkbox
                isChecked={spicyLevel !== undefined}
                onChange={toggleSpicy}
            >
                {t('Spicy')}
            </Checkbox>
            <Collapse in={spicyLevel !== undefined}>
                <RadioGroup
                    onChange={(rawValue) => {
                        const newLevel = parseInt(
                            rawValue
                        ) as Menu.Section.Entry.Feature
                        changeSpicyLevel(newLevel)
                    }}
                    value={spicyLevel?.toString()}
                >
                    <VStack align="start" ps={8}>
                        <Radio
                            value={Menu.Section.Entry.Feature.Spicy1.toString()}
                        >
                            {t('Low')}
                        </Radio>
                        <Radio
                            value={Menu.Section.Entry.Feature.Spicy2.toString()}
                        >
                            {t('Medium')}
                        </Radio>
                        <Radio
                            value={Menu.Section.Entry.Feature.Spicy3.toString()}
                        >
                            {t('High')}
                        </Radio>
                    </VStack>
                </RadioGroup>
            </Collapse>

            <Checkbox
                isChecked={isAlcoholic}
                onChange={() =>
                    toggleFeature(Menu.Section.Entry.Feature.Alcoholic)
                }
            >
                {t('Alcoholic')}
            </Checkbox>
        </VStack>
    )
}
