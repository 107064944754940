import {
    Box,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuList,
    useDisclosure,
} from '@chakra-ui/react'
import { countries } from 'country-data'
import { useTranslation } from 'react-i18next'
import { MenuItemRadio } from 'uikit/menu/MenuItemRadio'
import { flagForCountry } from '../mappers/InternalMapper'
import { Address } from '../models/Address'

const options: Address.Country[] = ['IT', 'CH']

interface CountrySelectorProps {
    country: Address.Country
    onSelect: (country: Address.Country) => void
    isLocked?: boolean
}

export function CountrySelector({
    country,
    onSelect,
    isLocked,
}: CountrySelectorProps) {
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Box w="full">
            <Menu
                isOpen={isOpen && !isLocked}
                onOpen={onOpen}
                onClose={onClose}
            >
                <MenuButton w="full">
                    <InputGroup>
                        <InputLeftElement>
                            <Icon as={flagForCountry(country)} />
                        </InputLeftElement>
                        <Input
                            readOnly
                            value={country ? t(countries[country].name) : ''}
                            placeholder="Country"
                        />
                    </InputGroup>
                </MenuButton>
                <MenuList>
                    {options.map((option) => (
                        <MenuItemRadio
                            key={option}
                            text={t(countries[option].name)}
                            icon={<Icon as={flagForCountry(option)} />}
                            isSelected={option === country}
                            onClick={() => onSelect(option)}
                        />
                    ))}
                </MenuList>
            </Menu>
        </Box>
    )
}
