import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Button,
    HStack,
    Icon,
    Progress,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useVerifyOrganization } from 'features/organization/hooks/useVerifyOrganization'
import useT from 'localization/hooks/useT'
import { useMemo } from 'react'
import { FaCheck, FaRegCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import useFormNotification from 'utils/hooks/useFormNotification'
import { useLocationPublishFormController } from '../hooks/useLocationPublishForm'
import { LocationTabProps } from '../pages/LocationPage'

type Task = LinkTask | ButtonTask

interface BaseTask {
    id: string
    isCompleted: boolean
    isDisabled?: boolean | undefined
}

interface LinkTask extends BaseTask {
    type: 'link'
    destination: string
}

interface ButtonTask extends BaseTask {
    type: 'button'
    isLoading: boolean
    onClick: () => void
}

interface TaskListProps {
    title: string
    tasks: Task[]
}

export function PublishTab({ location, update }: LocationTabProps) {
    const t = useT('publish')

    const organizationManager = useOrganizationContext()

    const {
        isProcessing: isStartingVerificationProcess,
        startVerificationProcess,
    } = useVerifyOrganization()
    const { organizationId, locationId } = location.id
    const controller = useLocationPublishFormController(location, update)

    const organizationTasks: TaskListProps = useMemo(() => {
        return {
            title: 'task_organization_title',
            tasks: [
                {
                    type: 'button',
                    id: 'task_organization_step_not_verified',
                    isLoading: isStartingVerificationProcess,
                    isCompleted:
                        organizationManager.organization!.status === 'verified',
                    onClick: startVerificationProcess,
                },
            ],
        }
    }, [
        isStartingVerificationProcess,
        startVerificationProcess,
        organizationManager.organization,
    ])

    useFormNotification('publish', controller)

    const locationGeneralTasks: TaskListProps = useMemo(() => {
        return {
            title: 'task_location_general_title',
            tasks: [
                {
                    type: 'link',
                    id: 'task_location_general_step_empty_gallery',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=gallery`,
                    isCompleted: !location.violations.has('empty-gallery'),
                },
                {
                    type: 'link',
                    id: 'task_location_general_step_missing_category',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=general`,
                    isCompleted: !location.violations.has('missing-category'),
                },
                {
                    type: 'link',
                    id: 'task_location_general_step_missing_capacity',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=general`,
                    isCompleted: !location.violations.has('missing-capacity'),
                },
            ],
        }
    }, [location])

    const locationAddressTasks: TaskListProps = useMemo(() => {
        return {
            title: 'task_location_address_title',
            tasks: [
                {
                    type: 'link',
                    id: 'task_location_address_step_missing',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=general`,
                    isCompleted: !location.violations.has('missing-address'),
                },
                {
                    type: 'link',
                    id: 'task_location_address_step_invalid',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=general`,
                    isCompleted:
                        !location.violations.has('missing-address') &&
                        !location.violations.has('invalid-address'),
                },
            ],
        }
    }, [location])

    const locationServicesTasks: TaskListProps = useMemo(() => {
        return {
            title: 'task_location_service_title',
            tasks: [
                {
                    type: 'link',
                    id: 'task_location_service_step_missing_policies',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=policy`,
                    isCompleted: !location.violations.has('missing-policies'),
                },
            ],
        }
    }, [location])

    const locationCalendarTasks: TaskListProps = useMemo(() => {
        return {
            title: 'task_location_calendar_title',
            tasks: [
                {
                    type: 'link',
                    id: 'task_location_calendar_step_always_closed',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=opening-hours`,
                    isCompleted: !location.violations.has(
                        'calendar-always-closed'
                    ),
                },
                {
                    type: 'link',
                    id: 'task_location_calendar_step_uncovered_schedule',
                    destination: `/organizations/${organizationId}/locations/${locationId}?tab=menus`,
                    isDisabled: location.violations.has(
                        'calendar-always-closed'
                    ),
                    isCompleted: !location.violations.has(
                        'calendar-uncovered-schedule'
                    ),
                },
            ],
        }
    }, [location])

    const tasks = useMemo(
        () => [
            organizationTasks,
            locationGeneralTasks,
            locationAddressTasks,
            locationServicesTasks,
            locationCalendarTasks,
        ],
        [
            organizationTasks,
            locationGeneralTasks,
            locationAddressTasks,
            locationServicesTasks,
            locationCalendarTasks,
        ]
    )

    if (location.restricted) {
        return (
            <Container>
                <Text>{t('location_restricted_warning_message')}</Text>
            </Container>
        )
    }

    return (
        <>
            <Container
                title={t('publish_title')}
                header={
                    location.published ? null : (
                        <Button
                            onClick={controller.submit}
                            isLoading={controller.isProcessing}
                            isDisabled={!controller.canSubmit}
                        >
                            {t('publish_button')}
                        </Button>
                    )
                }
            >
                <VStack align="stretch" gap={8}>
                    {tasks.map((task) => (
                        <TaskList
                            key={task.title}
                            title={task.title}
                            tasks={task.tasks}
                        />
                    ))}
                </VStack>
            </Container>
        </>
    )
}

function TaskList({ title, tasks }: TaskListProps) {
    const t = useT('publish')

    const screen = useScreenBreakpoint()

    const completePercentage = useMemo(
        () =>
            Math.round(
                (tasks.filter((task) => task.isCompleted && !task.isDisabled)
                    .length /
                    tasks.length) *
                    100
            ),
        [tasks]
    )

    return (
        <VStack align="stretch">
            <HStack>
                <Text color="label2" fontWeight="semibold">
                    {t(title)}
                </Text>

                <Progress
                    w={32}
                    borderRadius="full"
                    colorScheme="green"
                    size="sm"
                    value={completePercentage}
                />

                <Text>{completePercentage}%</Text>
            </HStack>

            <VStack align="stretch">
                {tasks.map((task, index) => (
                    <HStack key={index}>
                        {task.type === 'button' && task.isLoading ? (
                            <Spinner size="sm" />
                        ) : (
                            <Icon
                                as={
                                    task.isCompleted && !task.isDisabled
                                        ? FaCheck
                                        : FaRegCircle
                                }
                                color={
                                    task.isCompleted && !task.isDisabled
                                        ? 'green'
                                        : 'label2'
                                }
                            />
                        )}

                        <VStack
                            align="start"
                            spacing={0}
                            as={
                                task.type === 'link' &&
                                !task.isCompleted &&
                                !task.isDisabled
                                    ? Link
                                    : undefined
                            }
                            to={
                                task.type === 'link'
                                    ? task.destination
                                    : undefined
                            }
                            onClick={
                                task.type === 'button' &&
                                !task.isCompleted &&
                                !task.isDisabled &&
                                !task.isLoading
                                    ? task.onClick
                                    : undefined
                            }
                            cursor={
                                task.isDisabled
                                    ? 'not-allowed'
                                    : task.type === 'button' &&
                                      !task.isCompleted &&
                                      !task.isLoading
                                    ? 'pointer'
                                    : undefined
                            }
                        >
                            <HStack>
                                <Text
                                    textDecorationLine={
                                        task.isCompleted && !task.isDisabled
                                            ? 'line-through'
                                            : undefined
                                    }
                                    textDecorationThickness="1px"
                                    color={
                                        task.isDisabled ? 'label2' : 'label1'
                                    }
                                >
                                    {t(`${task.id}_title`)}
                                </Text>

                                {screen === 'mobile' && !task.isCompleted && (
                                    <Button
                                        leftIcon={<ExternalLinkIcon />}
                                        variant="link"
                                        size="sm"
                                    >
                                        {t('show_me_button')}
                                    </Button>
                                )}
                            </HStack>

                            <HStack>
                                <Text
                                    textDecorationLine={
                                        task.isCompleted && !task.isDisabled
                                            ? 'line-through'
                                            : undefined
                                    }
                                    textDecorationThickness="0.5px"
                                    fontSize="sm"
                                    fontWeight="light"
                                    color={
                                        task.isDisabled ? 'label2' : 'label1'
                                    }
                                >
                                    {t(`${task.id}_description`)}
                                </Text>

                                {screen !== 'mobile' && !task.isCompleted && (
                                    <Button
                                        leftIcon={<ExternalLinkIcon />}
                                        variant="link"
                                        size="sm"
                                    >
                                        {t('show_me_button')}
                                    </Button>
                                )}
                            </HStack>
                        </VStack>
                    </HStack>
                ))}
            </VStack>
        </VStack>
    )
}
