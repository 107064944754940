import { Spinner, VStack } from '@chakra-ui/react'
import useLocationsContext from 'features/location/contexts/LocationsContext'
import useMenusContext from 'features/menu/contexts/MenusContext'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import useT from 'localization/hooks/useT'
import { useMemo } from 'react'
import { Container } from 'uikit/container/Container'
import { LocationNextStep, MenuNextStep, StripeNextStep } from './cards'

export function NextSteps() {
    const t = useT('home')

    const organizationManager = useOrganizationContext()
    const locationsManager = useLocationsContext()
    const menusManager = useMenusContext()

    const organization = useMemo(
        () => organizationManager.assertOrganization(),
        [organizationManager]
    )

    const isCompleted = useMemo(() => {
        if (locationsManager.isLoading) return undefined
        if (menusManager.isLoading) return undefined
        if (locationsManager.locations.length === 0) return false
        if (menusManager.menus.length === 0) return false
        if (organization.status === 'unverified') return false
        if (organization.status === 'moreDocumentsNeeded') return false

        return true
    }, [
        locationsManager.isLoading,
        locationsManager.locations,
        menusManager.isLoading,
        menusManager.menus,
        organization,
    ])

    return (
        <Container
            mainContainer
            isCompact
            title={t('next_steps_title')}
            subtitle={
                isCompleted === undefined
                    ? undefined
                    : isCompleted
                    ? t('next_steps_subtitle_completed')
                    : t('next_steps_subtitle')
            }
        >
            <VStack gap={4}>
                {isCompleted === undefined ? (
                    <Spinner />
                ) : (
                    <>
                        {organization.status !== 'verified' && (
                            <StripeNextStep organization={organization} />
                        )}
                        {locationsManager.locations.length === 0 && (
                            <LocationNextStep />
                        )}
                        {menusManager.menus.length === 0 && <MenuNextStep />}
                    </>
                )}
            </VStack>
        </Container>
    )
}
