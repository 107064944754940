export function generateRandomString(length: number) {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let randomString = ''

    const cryptoObj = window.crypto
    const randomValues = new Uint32Array(length)
    cryptoObj.getRandomValues(randomValues)

    for (let i = 0; i < length; i++) {
        randomString += characters.charAt(randomValues[i] % charactersLength)
    }

    return randomString
}
