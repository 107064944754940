import {
    Box,
    Button,
    ButtonGroup,
    HStack,
    Progress,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Namespace } from 'localization/i18n'
import { PropsWithChildren, RefObject, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Step as StepModel } from '.'

export interface StepPropsWithRef {
    headerRef: RefObject<HTMLDivElement>
}

interface GuidedActionProps<Context> {
    namespace?: Namespace
    context: Context
    steps: StepModel<Context>[]
    index: number
    isProcessing: boolean
    isDisabled: boolean
    hideControls?: boolean
    hideSkip: boolean
    allowScroll?: boolean
    completion:
        | { modelLink: string; fallbackLink?: string }
        | { label: string; handler: () => void }
    onNext: () => void
    onBack: () => void
    onSkip: (amount: number) => void
    currentStep: StepModel<Context>
}

export function GuidedActionMobile<Context>({
    namespace,
    context,
    steps,
    index,
    isProcessing,
    isDisabled,
    hideControls = false,
    hideSkip,
    allowScroll = true,
    completion,
    onNext,
    onBack,
    onSkip,
    currentStep,
}: GuidedActionProps<Context>) {
    const t = useT(namespace)

    const headerRef = useRef<HTMLDivElement>(null)

    return (
        <VStack w="full" align="stretch">
            <VStack maxH="full" flex={1} align="stretch" p={2}>
                {/* {currentStep ? ( */}
                <StepContainer
                    index={index}
                    namespace={namespace}
                    numberOfSteps={steps.length}
                    title={currentStep?.title ?? 'new_success_title'}
                    headerRef={headerRef}
                    isLoading={isProcessing}
                    hideControls={hideControls}
                    allowScroll={allowScroll}
                    next={isDisabled ? undefined : onNext}
                    back={
                        !currentStep || index === 0 || currentStep.preventBack
                            ? undefined
                            : onBack
                    }
                    skip={
                        !currentStep ||
                        currentStep.skipBy === undefined ||
                        hideSkip
                            ? undefined
                            : () => onSkip(currentStep.skipBy!)
                    }
                    completion={completion}
                >
                    {currentStep && (
                        <currentStep.content
                            headerRef={headerRef}
                            context={context}
                        />
                    )}
                </StepContainer>
            </VStack>
        </VStack>
    )
}

interface StepContainerProps extends PropsWithChildren {
    namespace?: Namespace
    title: string
    isLoading: boolean
    hideControls: boolean
    allowScroll: boolean
    headerRef: RefObject<HTMLDivElement>
    next?: () => void
    back?: () => void
    skip?: () => void
    index: number
    numberOfSteps: number
    completion:
        | { modelLink: string; fallbackLink?: string }
        | { label: string; handler: () => void }
}

function StepContainer({
    namespace,
    title,
    isLoading,
    hideControls,
    allowScroll,
    headerRef,
    children,
    next,
    back,
    skip,
    index,
    numberOfSteps,
    completion,
}: StepContainerProps) {
    const t = useT(namespace)

    return (
        <>
            <VStack
                bg="pageBackground2"
                borderRadius="lg"
                boxShadow="lg"
                p={8}
                spacing={0}
                align="start"
                minH={40}
            >
                <HStack justifyContent="space-between" w="full" h={8}>
                    <Text color="accent" fontSize="sm" fontWeight="semibold">
                        {t(title)}
                    </Text>

                    {skip && (
                        <Button
                            variant="unstyled"
                            size="sm"
                            color="label3"
                            isDisabled={isLoading}
                            _hover={{ color: 'label2' }}
                            onClick={skip}
                        >
                            {t('skip_button')}
                        </Button>
                    )}
                </HStack>
                <Box w="full" ref={headerRef} />
                <Spacer />
                <StepProgressLine
                    currentStep={index}
                    numberOfSteps={numberOfSteps}
                />
            </VStack>

            <VStack
                bg="pageBackground2"
                borderRadius="lg"
                boxShadow="lg"
                flex={1}
                p="31px"
                minH={0}
            >
                <VStack
                    align="start"
                    w="full"
                    flex={1}
                    spacing={0}
                    overflow={allowScroll ? 'auto' : undefined}
                    p="1px"
                >
                    {children}
                </VStack>
                {index === numberOfSteps && (
                    <>
                        {'modelLink' in completion && (
                            <>
                                <Text>{t('new_success_message')}</Text>
                                <ButtonGroup minW="20%">
                                    {completion.fallbackLink && (
                                        <Button
                                            flex={1}
                                            as={Link}
                                            to={completion.fallbackLink}
                                        >
                                            {t('done_button')}
                                        </Button>
                                    )}
                                    <Button
                                        flex={1}
                                        as={Link}
                                        to={completion.modelLink}
                                    >
                                        {t('see_button')}
                                    </Button>
                                </ButtonGroup>
                            </>
                        )}
                        {'handler' in completion && (
                            <>
                                <Text>{t('new_success_message')}</Text>
                                <ButtonGroup minW="20%">
                                    <Button
                                        flex={1}
                                        onClick={completion.handler}
                                    >
                                        {completion.label}
                                    </Button>
                                </ButtonGroup>
                            </>
                        )}
                    </>
                )}
                {index !== numberOfSteps && !hideControls && (
                    <ButtonGroup alignSelf="end">
                        {back && (
                            <Button
                                variant="ghost"
                                onClick={back}
                                isDisabled={isLoading}
                            >
                                {t('back_button')}
                            </Button>
                        )}
                        <Button
                            onClick={next}
                            isLoading={isLoading}
                            isDisabled={next === undefined}
                        >
                            {t('next_button')}
                        </Button>
                    </ButtonGroup>
                )}
            </VStack>
        </>
    )
}

interface StepProgressLineProps {
    currentStep: number
    numberOfSteps: number
}

function StepProgressLine(props: StepProgressLineProps) {
    const { currentStep, numberOfSteps } = props
    const value = useMemo(() => {
        if (numberOfSteps === 0) {
            return 100
        }
        return (currentStep * 100) / numberOfSteps
    }, [currentStep, numberOfSteps])

    return (
        <Progress
            w="full"
            hasStripe
            borderRadius="full"
            mt={4}
            value={value}
            sx={{
                '& > div:first-of-type': {
                    transitionProperty: 'width',
                },
            }}
        />
    )
}
