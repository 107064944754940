import Avokado, {
    AvokadoAPIError,
    MenuAssignmentList,
} from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapMenuAssignmentsToModels } from '../mappers/SDKResponseMapper'
import { MenuAssignment } from '../models/MenuAssignment'

type ListMenuAssignmentParams = Location.ID

export interface ListMenuAssignmentAction {
    run(params: ListMenuAssignmentParams): Promise<MenuAssignment[]>
}

@injectable()
export class ListMenuAssignmentActionLive implements ListMenuAssignmentAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ListMenuAssignmentParams): Promise<MenuAssignment[]> {
        const request: MenuAssignmentList.Request = params
        const response = await this.avokado.menuAssignment.list(request)
        return mapMenuAssignmentsToModels(response)
    }
}

@injectable()
export class ListMenuAssignmentActionFake implements ListMenuAssignmentAction {
    async run(params: ListMenuAssignmentParams): Promise<MenuAssignment[]> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ListMenuAssignmentActionFail implements ListMenuAssignmentAction {
    async run(params: ListMenuAssignmentParams): Promise<MenuAssignment[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
