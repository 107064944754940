import _ from 'lodash'
import { Schedule, Weekdays } from '../models/Calendar'

interface OpeningTime {
    from: number
    to: number
    weekday: keyof Schedule
}

export function extractSchedule(openingTimes: OpeningTime[]): Schedule {
    const dictionary = _.groupBy(openingTimes, (schedule) => schedule.weekday)

    return {
        monday: extractDaySchedule(dictionary, 'monday'),
        tuesday: extractDaySchedule(dictionary, 'tuesday'),
        wednesday: extractDaySchedule(dictionary, 'wednesday'),
        thursday: extractDaySchedule(dictionary, 'thursday'),
        friday: extractDaySchedule(dictionary, 'friday'),
        saturday: extractDaySchedule(dictionary, 'saturday'),
        sunday: extractDaySchedule(dictionary, 'sunday'),
    }
}

function extractDaySchedule(
    dictionary: _.Dictionary<OpeningTime[]>,
    weekDay: string
): Weekdays.DaySchedule {
    const schedule = dictionary[weekDay]

    if (!schedule) {
        return { type: 'full-day', isActive: false }
    }

    if (schedule.find((range) => range.to === 0 && range.from === 0)) {
        return { type: 'full-day', isActive: true }
    }

    const timeRanges = _.sortBy(schedule, (range) => range.from).map(
        (range) => {
            return {
                from: {
                    hours: _.floor(range.from / 60),
                    minutes: range.from % 60,
                },
                to: {
                    hours: _.floor(range.to / 60),
                    minutes: range.to % 60,
                },
            }
        }
    )

    return { type: 'time-range', timeRanges: timeRanges }
}
