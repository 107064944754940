import { VStack } from '@chakra-ui/react'
import TopBar from 'infra/navigation/topbar/TopBar'
import { GuidedAction, Step } from 'utils/guidedaction'
import {
    NewOrganizationBillingStep,
    NewOrganizationStructureStep,
} from '../components'
import {
    UseNewOrganizationFormDataHook,
    useNewOrganizationFormController,
    useNewOrganizationFormData,
} from '../hooks/useNewOrganizationForm'

export const newOrganizationSteps: Step<UseNewOrganizationFormDataHook>[] = [
    {
        title: 'new_step_1_structure_preview_title',
        description: 'new_step_1_structure_preview_description',
        content: (props) => (
            <NewOrganizationStructureStep
                headerRef={props.headerRef}
                {...props.context}
            />
        ),
    },
    {
        title: 'new_step_2_billing_preview_title',
        description: 'new_step_2_billing_preview_description',
        preventBack: true,

        content: (props) => (
            <NewOrganizationBillingStep
                headerRef={props.headerRef}
                {...props.context}
            />
        ),
    },
]

export default function NewOrganizationPage() {
    const formData = useNewOrganizationFormData()
    const controller = useNewOrganizationFormController(formData)

    return (
        <VStack flex={1} align="stretch" spacing={0}>
            <TopBar prefersTopBarShadowHidden />
            <GuidedAction
                namespace="organization"
                context={{ ...formData }}
                steps={newOrganizationSteps}
                index={controller.activeStep}
                isProcessing={controller.isProcessing}
                isDisabled={!controller.canSubmit}
                hideControls={controller.hideControls}
                allowScroll={false}
                hideSkip={controller.hideSkip}
                completion={{
                    modelLink: `/organizations/${controller.organization?.id.organizationId}`,
                }}
                onNext={controller.submit}
                onBack={controller.goBack}
                onSkip={controller.skip}
            />
        </VStack>
    )
}
