import { RawMedia } from 'features/media/models/RawMedia'
import { Dispatch, SetStateAction, useState } from 'react'

export const NewMenuImageStep = 1

export interface UseNewMenuImageStepFormDataHook {
    isUploading: boolean
    rawMedia: RawMedia | undefined
    setIsUploading: Dispatch<SetStateAction<boolean>>
    setRawMedia: Dispatch<SetStateAction<RawMedia | undefined>>
}

export function useNewMenuImageStepFormData(): UseNewMenuImageStepFormDataHook {
    const [isUploading, setIsUploading] = useState(false)
    const [rawMedia, setRawMedia] = useState<RawMedia>()

    return { isUploading, rawMedia, setIsUploading, setRawMedia }
}
