import {
    Alert,
    AlertDescription,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Divider,
    Fade,
    HStack,
    Heading,
    Select,
    Skeleton,
    Spacer,
    Spinner,
    Switch,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'

import {
    NewTranslationModal,
    PreviewCell,
} from 'features/menu/components/translation'
import useTranslationBundles, {
    UseTranslationBundlesHook,
} from 'features/menu/hooks/useTranslationBundles'
import useTranslationPreview from 'features/menu/hooks/useTranslationPreview'
import useTranslationPublishment, {
    UseTranslationPublishmentHook,
} from 'features/menu/hooks/useTranslationPublishment'
import { Menu } from 'features/menu/models/Menu'
import { TranslationObject } from 'features/menu/models/TranslationObject'
import { TranslationPreview } from 'features/menu/models/TranslationPreview'
import useT from 'localization/hooks/useT'
import { Suspense, useMemo, useRef } from 'react'
import { Trans } from 'react-i18next'
import { Container } from 'uikit/container/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { LoadingIndicator } from 'utils/loading'
import { usePriceFormatter } from 'utils/types/formatters'
import { MenuPageContentProps } from '../MenuPage'

const flagsByCode = {
    en: '🇬🇧',
    it: '🇮🇹',
    de: '🇩🇪',
    fr: '🇫🇷',
    es: '🇪🇸',
    ja: '🇯🇵',
    ko: '🇰🇷',
    zh: '🇨🇳',
    no: '🇳🇴',
    sv: '🇸🇪',
    ru: '🇷🇺',
    pt: '🇵🇹',
    nl: '🇳🇱',
    hi: '🇮🇳',
}

export function TranslationTab(props: MenuPageContentProps) {
    const { menu } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const translationBundles = useTranslationBundles()

    return (
        <>
            <VStack w="full" spacing={8}>
                <TranslationHeaderContainer openModal={onOpen} />
                {menu.availableTranslations.length > 1 && (
                    <TranslationSelectorContainer
                        menu={menu}
                        onMenuUpdate={props.update}
                        translationBundles={translationBundles}
                    />
                )}
            </VStack>
            <NewTranslationModal
                isOpen={isOpen}
                onClose={onClose}
                menu={menu}
                onSuccess={(menu) => {
                    props.update(menu)
                    onClose()
                }}
            />
        </>
    )
}

interface TranslationHeaderContainerProps {
    openModal: () => void
}

function TranslationHeaderContainer({
    openModal,
}: TranslationHeaderContainerProps) {
    const t = useT('menutranslation')
    return (
        <Container title={t('translation_container_title')}>
            <VStack w="full">
                <Text>{t('header_container_body')}</Text>
                <Button alignSelf="end" mt={4} onClick={openModal}>
                    {t('header_container_add_language_button')}
                </Button>
            </VStack>
        </Container>
    )
}

interface TranslationSelectorContainerProps {
    menu: Menu
    onMenuUpdate: (menu: Menu) => void
    translationBundles: UseTranslationBundlesHook
}

function TranslationSelectorContainer({
    menu,
    onMenuUpdate,
    translationBundles,
}: TranslationSelectorContainerProps) {
    const [
        rawSelectedLanguage = menu.availableTranslations[1]?.locale ??
            menu.appliedTranslation,
        setRawSelectedLanguage,
    ] = useQueryParam('lang', StringParam)

    const availableLanguages = useMemo(
        () =>
            removeFirstElement(menu.availableTranslations).map((t) => t.locale),
        [menu.availableTranslations]
    )

    const translationPublishment = useTranslationPublishment({
        onUpdate: (locale, isPublished) => {
            const availableTranslationsCopy = [...menu.availableTranslations]
            const translation = availableTranslationsCopy.find(
                (l) => l.locale === locale
            )
            if (translation) {
                translation.isPublished = isPublished
            }
            onMenuUpdate({
                ...menu,
                availableTranslations: availableTranslationsCopy,
            })
        },
    })

    const selectedLanguage = useMemo(() => {
        return (
            menu.availableTranslations.find(
                (t) => t.locale === rawSelectedLanguage
            ) ?? menu.availableTranslations[0]
        )
    }, [rawSelectedLanguage, menu.availableTranslations])

    const screen = useScreenBreakpoint()
    if (screen === 'mobile') {
        return (
            <Container>
                <TranslationSelectorContainerBodyMobile
                    menu={menu}
                    selectedLanguage={selectedLanguage ?? ''}
                    onLanguageSelected={setRawSelectedLanguage}
                    availableLanguages={availableLanguages}
                    publishment={translationPublishment}
                    translationBundles={translationBundles}
                />
            </Container>
        )
    } else {
        return (
            <Container>
                <TranslationSelectorContainerBodyDesktop
                    menu={menu}
                    selectedLanguage={selectedLanguage ?? ''}
                    onLanguageSelected={setRawSelectedLanguage}
                    availableLanguages={availableLanguages}
                    publishment={translationPublishment}
                    translationBundles={translationBundles}
                />
            </Container>
        )
    }
}

interface TranslationSelectorContainerBodyProps {
    menu: Menu
    selectedLanguage: Menu.TranslationState
    onLanguageSelected: (l: string) => void
    availableLanguages: string[]
    publishment: UseTranslationPublishmentHook
    translationBundles: UseTranslationBundlesHook
}

function TranslationSelectorContainerBodyDesktop({
    selectedLanguage,
    onLanguageSelected,
    availableLanguages,
    menu,
    publishment,
    translationBundles,
}: TranslationSelectorContainerBodyProps) {
    const t = useT('menutranslation')
    return (
        <VStack w="full">
            <HStack w="full" justify="space-between">
                <HStack align="center">
                    <Heading size="md">
                        {t(`language_${selectedLanguage.locale}`)}
                    </Heading>
                </HStack>
                <Select
                    value={selectedLanguage.locale}
                    onChange={(e) => onLanguageSelected(e.target.value)}
                    width="xs"
                >
                    {availableLanguages.map((l) => (
                        <option key={l} value={l}>
                            {/* @ts-ignore */}
                            {flagsByCode[l]} {t(`language_${l}`)}
                        </option>
                    ))}
                </Select>
            </HStack>

            <MenuPublishmentToggle
                publishment={publishment}
                isPublished={selectedLanguage.isPublished}
                menuId={menu.id}
                selectedLanguageCode={selectedLanguage.locale}
            />
            <TranslationBundleCard
                languageCode={selectedLanguage.locale}
                languageName={t(`language_${selectedLanguage.locale}`)}
                translationBundles={translationBundles}
                hasBoughtBundle={
                    translationBundles.translationBundles !== undefined &&
                    translationBundles.translationBundles.find(
                        (b) => b.languageCode === selectedLanguage.locale
                    ) !== undefined
                }
                charactersCount={getCharactersCount(menu)}
            />

            {translationBundles.translationBundles !== undefined && (
                <TranslationTabs
                    menu={menu}
                    selectedLanguage={selectedLanguage}
                    automaticTranslationTrigger={
                        translationBundles.translationBundles !== undefined &&
                        translationBundles.translationBundles.find(
                            (b) => b.languageCode === selectedLanguage.locale
                        ) !== undefined
                    }
                />
            )}
        </VStack>
    )
}

function TranslationSelectorContainerBodyMobile({
    selectedLanguage,
    onLanguageSelected,
    availableLanguages,
    menu,
    publishment,
    translationBundles,
}: TranslationSelectorContainerBodyProps) {
    const t = useT('menutranslation')
    return (
        <VStack w={'full'}>
            <Select
                value={selectedLanguage.locale}
                onChange={(e) => onLanguageSelected(e.target.value)}
                width="xs"
            >
                {availableLanguages.map((l) => (
                    <option key={l} value={l}>
                        {/* @ts-ignore */}
                        {flagsByCode[l]} {t(`language_${l}`)}
                    </option>
                ))}
            </Select>

            <MenuPublishmentToggle
                publishment={publishment}
                isPublished={selectedLanguage.isPublished}
                menuId={menu.id}
                selectedLanguageCode={selectedLanguage.locale}
            />
            <TranslationBundleCard
                languageCode={selectedLanguage.locale}
                languageName={t(`language_${selectedLanguage.locale}`)}
                translationBundles={translationBundles}
                hasBoughtBundle={
                    translationBundles.translationBundles !== undefined &&
                    translationBundles.translationBundles.find(
                        (b) => b.languageCode === selectedLanguage.locale
                    ) !== undefined
                }
                charactersCount={getCharactersCount(menu)}
            />

            {translationBundles.translationBundles !== undefined && (
                <TranslationTabs
                    menu={menu}
                    selectedLanguage={selectedLanguage}
                    automaticTranslationTrigger={
                        translationBundles.translationBundles !== undefined &&
                        translationBundles.translationBundles.find(
                            (b) => b.languageCode === selectedLanguage.locale
                        ) !== undefined
                    }
                />
            )}
        </VStack>
    )
}

function getCharactersCount(menu: Menu): number {
    var result = 0
    result += menu.name.length
    result += menu.description?.length ?? 0
    result += menu.footer?.length ?? 0
    menu.sections.forEach((s) => {
        result += s.name.length
        result += s.description?.length ?? 0
        s.entries.forEach((e) => {
            result += e.name.length
            result += e.description?.length ?? 0
        })
    })
    menu.extras.forEach((x) => {
        result += x.name.length
        x.options.map((o) => {
            result += o.name.length
        })
    })
    return result
}

interface MenuPublishmentToggleProps {
    publishment: UseTranslationPublishmentHook
    isPublished: boolean
    menuId: Menu.ID
    selectedLanguageCode: string
}

function MenuPublishmentToggle({
    publishment,
    isPublished,
    menuId,
    selectedLanguageCode,
}: MenuPublishmentToggleProps) {
    const t = useT('menutranslation')
    return (
        <Card bg="pageBackground3" mt={4} w="full">
            <CardBody>
                <HStack align="center" spacing={4}>
                    <Text fontWeight="medium" fontSize="2xl">
                        {t('card_menu_is_published_title')}
                    </Text>
                    <Spacer />
                    {publishment.isProcessing && <Spinner />}
                    <Switch
                        size="lg"
                        isChecked={isPublished}
                        isDisabled={publishment.isProcessing}
                        onChange={(evt) =>
                            publishment.submit(
                                menuId,
                                selectedLanguageCode,
                                evt.target.checked
                            )
                        }
                    />
                </HStack>
                <Text>{t('card_menu_is_published_body')}</Text>
            </CardBody>
        </Card>
    )
}

interface TranslationBundleCardProps {
    languageCode: string
    languageName: string
    hasBoughtBundle: boolean
    translationBundles: UseTranslationBundlesHook
    charactersCount: number
}

function TranslationBundleCard({
    languageCode,
    languageName,
    hasBoughtBundle,
    translationBundles,
    charactersCount,
}: TranslationBundleCardProps) {
    const t = useT('menutranslation')
    const priceFormatter = usePriceFormatter()
    const dialogState = useDisclosure()
    const leastDestructiveRef = useRef(null)
    const isReady =
        !translationBundles.hasFailed &&
        !translationBundles.isLoading &&
        translationBundles.translationBundles !== undefined
    return (
        <>
            <Card bg="pageBackground3" mt={4} w="full">
                <CardBody>
                    <HStack w="full">
                        <Skeleton isLoaded={isReady}>
                            <Text fontWeight="medium" fontSize="2xl">
                                {t('card_translation_bundle_title')}
                            </Text>
                        </Skeleton>
                        <Spacer />
                        <Fade in={!hasBoughtBundle && isReady}>
                            <Button
                                isLoading={translationBundles.isBuying}
                                onClick={dialogState.onOpen}
                            >
                                {t('card_translation_bundle_buy_button')}
                            </Button>
                        </Fade>
                    </HStack>
                    <Skeleton isLoaded={isReady}>
                        <Text>
                            {hasBoughtBundle
                                ? t(
                                      'card_translation_bundle_already_bought_body',
                                      {
                                          languageName,
                                      }
                                  )
                                : t('card_translation_bundle_body', {
                                      languageName,
                                      localizedCost: priceFormatter.format(500),
                                  })}
                        </Text>
                    </Skeleton>
                </CardBody>
            </Card>
            <AlertDialog
                size="2xl"
                isOpen={dialogState.isOpen}
                leastDestructiveRef={leastDestructiveRef}
                onClose={dialogState.onClose}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>
                        {t('buy_bundle_confirmation_dialog_header')}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Trans
                            i18nKey="buy_bundle_confirmation_dialog_body"
                            t={t}
                            values={{
                                localizedCost: priceFormatter.format(500),
                                charactersCount,
                            }}
                        />
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            mx={3}
                            onClick={dialogState.onClose}
                            ref={leastDestructiveRef}
                            variant="outline"
                        >
                            {t('buy_bundle_confirmation_dialog_cancel_button')}
                        </Button>
                        <Button
                            onClick={async () => {
                                // @ts-ignore
                                await translationBundles.buy(languageCode)
                                dialogState.onClose()
                            }}
                            isLoading={translationBundles.isBuying}
                        >
                            {t(
                                'buy_bundle_confirmation_dialog_confirm_button',
                                { localizedCost: priceFormatter.format(500) }
                            )}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}

interface TranslationTabsProps {
    menu: Menu
    selectedLanguage: Menu.TranslationState
    automaticTranslationTrigger: boolean
}

function TranslationTabs({
    selectedLanguage,
    menu,
    automaticTranslationTrigger,
}: TranslationTabsProps) {
    return (
        <VStack w="full" align="start" mt={4}>
            <Suspense fallback={<LoadingIndicator />}>
                <PreviewTranslation
                    menu={menu}
                    selectedLanguage={selectedLanguage}
                    automaticTranslationTrigger={automaticTranslationTrigger}
                />
            </Suspense>
        </VStack>
    )
}

interface PreviewTranslationProps {
    menu: Menu
    selectedLanguage: Menu.TranslationState
    automaticTranslationTrigger: boolean
}

function PreviewTranslation(props: PreviewTranslationProps) {
    const t = useT('menutranslation')
    const {
        translationPreview: menu,
        isProcessing,
        submit,
    } = useTranslationPreview({
        menu: props.menu,
        locale: props.selectedLanguage.locale,
        automaticTranslationTrigger: props.automaticTranslationTrigger,
    })

    if (!menu) {
        return (
            <Center w="full">
                <LoadingIndicator />
            </Center>
        )
    }

    return (
        <VStack w="full">
            <TranslationStatusAlert
                translation={menu}
                translationState={props.selectedLanguage}
            />
            <Heading alignSelf="start" py={12}>
                {t('menu_data_header')}
            </Heading>
            <PreviewCell
                size="xl"
                object={menu.name}
                type="compact"
                isProcessing={isProcessing}
                submit={submit}
            />
            <PreviewCell
                size="lg"
                object={menu.description}
                type="extended"
                isProcessing={isProcessing}
                submit={submit}
            />
            <PreviewCell
                size="lg"
                object={menu.footer}
                type="extended"
                isProcessing={isProcessing}
                submit={submit}
            />
            {menu.sections.map((section) => (
                <VStack w="full" key={section.name.id.translationObjectId}>
                    <Divider mt={12} />
                    <VStack spacing={12} w="full">
                        <Heading alignSelf="start">
                            {t('menu_section_header', {
                                sectionName: section.name.source,
                            })}
                        </Heading>
                        <PreviewCell
                            size="lg"
                            object={section.name}
                            isProcessing={isProcessing}
                            submit={submit}
                            type="compact"
                        />
                        <PreviewCell
                            size="lg"
                            object={section.description}
                            isProcessing={isProcessing}
                            submit={submit}
                            type="extended"
                        />
                        {section.entries.map((entry) => (
                            <VStack
                                w="full"
                                key={entry.name.id.translationObjectId}
                            >
                                <PreviewCell
                                    size="md"
                                    object={entry.name}
                                    isProcessing={isProcessing}
                                    submit={submit}
                                    type="compact"
                                />
                                <PreviewCell
                                    size="md"
                                    object={entry.description}
                                    isProcessing={isProcessing}
                                    submit={submit}
                                    type="extended"
                                />
                            </VStack>
                        ))}
                    </VStack>
                </VStack>
            ))}
            {menu.extras.map((extra) => (
                <VStack w="full" key={extra.name.id.translationObjectId}>
                    <Divider mt={12} />
                    <Heading alignSelf="start">
                        {t('menu_extra_header', {
                            extraName: extra.name.source,
                        })}
                    </Heading>
                    <PreviewCell
                        size="lg"
                        object={extra.name}
                        isProcessing={isProcessing}
                        submit={submit}
                        type="compact"
                    />

                    {extra.options.map((option) => (
                        <VStack
                            w="full"
                            key={option.name.id.translationObjectId}
                        >
                            <PreviewCell
                                size="md"
                                object={option.name}
                                isProcessing={isProcessing}
                                submit={submit}
                                type="compact"
                            />
                        </VStack>
                    ))}
                </VStack>
            ))}
        </VStack>
    )
}

interface TranslationStatusAlertProps {
    translation: TranslationPreview
    translationState: Menu.TranslationState
}

function TranslationStatusAlert({
    translation,
    translationState,
}: TranslationStatusAlertProps) {
    const t = useT('menutranslation')
    const missingCount = useMissingTranslations(translation)

    const hasMissingTranslation = missingCount > 0
    const isUnpublished = !translationState.isPublished

    return (
        <VStack w="full" spacing={8}>
            {hasMissingTranslation && (
                <Alert status="warning" borderRadius={8}>
                    <AlertIcon />
                    <Box w="full">
                        <AlertTitle>
                            {t('alert_warning_missing_translations_title')}
                        </AlertTitle>
                        <AlertDescription>
                            {t(
                                'alert_warning_missing_translations_description',
                                {
                                    count: missingCount,
                                }
                            )}
                        </AlertDescription>
                    </Box>
                </Alert>
            )}

            {isUnpublished && (
                <Alert status="warning" borderRadius={8}>
                    <AlertIcon />
                    <Box w="full">
                        <AlertTitle>
                            {t('alert_warning_unpublished_title')}
                        </AlertTitle>
                        <AlertDescription>
                            {t('alert_warning_unpublished_description')}
                        </AlertDescription>
                    </Box>
                </Alert>
            )}

            {!hasMissingTranslation && !isUnpublished && (
                <Alert status="success" borderRadius={8}>
                    <AlertIcon />
                    <Box w="full">
                        <AlertTitle>{t('alert_success_title')}</AlertTitle>
                        <AlertDescription>
                            {t('alert_success_description')}
                        </AlertDescription>
                    </Box>
                </Alert>
            )}
        </VStack>
    )
}

function useMissingTranslations(translation: TranslationPreview): number {
    var result = 0

    function incrementIfNeeded(obj: TranslationObject | undefined) {
        if (obj && obj.translation === undefined) {
            result += 1
        }
    }
    incrementIfNeeded(translation.name)
    incrementIfNeeded(translation.description)
    incrementIfNeeded(translation.footer)
    translation.sections.forEach((sec) => {
        incrementIfNeeded(sec.name)
        incrementIfNeeded(sec.description)
        sec.entries.forEach((ent) => {
            incrementIfNeeded(ent.name)
            incrementIfNeeded(ent.description)
        })
    })
    Array.from(translation.extras.values()).forEach((ext) => {
        incrementIfNeeded(ext.name)
        ext.options.forEach((opt) => incrementIfNeeded(opt.name))
    })

    return result
}

function removeFirstElement<T>(arr: T[]): T[] {
    const copy = [...arr]
    copy.shift()
    return copy
}
