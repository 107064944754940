import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    VStack,
} from '@chakra-ui/react'
import { useNotification } from 'features/notification/hooks/useNotification'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { FormEvent, useEffect, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { PasswordInput } from 'uikit/form/input'
import useAuthContext from '../contexts/AuthContext'
import {
    useSignInFormController,
    useSignInFormData,
} from '../hooks/useSignInForm'

export default function EmailRefreshSessionPage() {
    const t = useT('auth')

    const notification = useNotification()

    const formData = useSignInFormData()
    const controller = useSignInFormController(formData)
    const { expiredSession } = useAuthContext()

    useEffect(() => {
        if (expiredSession?.email) formData.setEmail(expiredSession.email)
    }, [expiredSession?.email, formData])

    const [isError, setIsError] = useState(false)

    async function handleSubmit(event: FormEvent<HTMLDivElement>) {
        event.preventDefault()
        setIsError(false)

        if (controller.canSubmit) {
            try {
                await controller.submit()
            } catch (error: any) {
                notification({
                    id: 'invalid-credentials',
                    title: t('generic_invalid_credentials_notification_title'),
                    description: t(
                        'generic_invalid_credentials_notification_description'
                    ),
                    variant: 'error',
                })
            }
        } else {
            setIsError(true)
        }
    }

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack
                as={motion.div}
                align="stretch"
                w="full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <FormControl isInvalid={isError && formData.email === ''}>
                    <InputGroup>
                        <Input
                            type="email"
                            placeholder={t('generic_input_email_address')}
                            autoComplete="email"
                            bg="authBackground"
                            h="44px"
                            w="xs"
                            border="1px solid var(--chakra-colors-gray-400)"
                            _placeholder={{ color: 'label2' }}
                            focusBorderColor="primary.200"
                            borderRadius="full"
                            isDisabled
                            value={formData.email}
                            onChange={(e) => formData.setEmail(e.target.value)}
                            _hover={{}}
                        />
                        <InputRightElement>
                            <IconButton
                                alignSelf="end"
                                size="sm"
                                variant="unstyled"
                                color="label2"
                                me={2}
                                icon={<Icon as={FaLock} />}
                                aria-label={''}
                                pointerEvents="none"
                            />
                        </InputRightElement>
                    </InputGroup>
                </FormControl>

                <FormControl isInvalid={isError && formData.password === ''}>
                    <PasswordInput
                        value={formData.password}
                        setValue={formData.setPassword}
                        borderRadius="full"
                        focusBorderColor="primary.200"
                        autoFocus
                        autoComplete="current-password"
                        h="44px"
                        w="xs"
                    />
                    <FormErrorMessage>
                        {t('generic_label_enter_your_password')}
                    </FormErrorMessage>
                </FormControl>

                <Button
                    w="full"
                    type="submit"
                    h="48px"
                    mt={8}
                    isLoading={controller.isProcessing}
                >
                    {t('generic_button_sign_in')}
                </Button>
            </VStack>
        </Box>
    )
}
