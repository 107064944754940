import { useConst } from '@chakra-ui/react'
import { NewTable, isFullShape } from 'features/room/hooks/useRoomForm'
import { Room } from 'features/room/models/Room'
import { KonvaEventObject } from 'konva/lib/Node'
import _ from 'lodash'
import { Layer } from 'react-konva'
import { Geometry } from 'utils/geometry/Geometry'
import { Trigger } from 'utils/hooks/useTrigger'
import { v4 as uuid } from 'uuid'
import { TableShape } from './TableShape'

interface TablesLayerProps {
    refreshTrigger: Trigger
    canEditTables: boolean
    hidesTableNumber?: boolean
    newTable: NewTable | null
    tables: Room.Table[]
    canvasSize: Geometry.Size
    canvasScale: number
    selectedTable: Room.Table.ID | null
    overlappingTables: Room.Table.ID[]
    onTableClick: (table: Room.Table) => void
    onUpdate: (event: KonvaEventObject<Event>) => void
    onUpdateStart: (event: KonvaEventObject<Event>) => void
    onUpdateEnd: (event: KonvaEventObject<Event>) => void
    onNewUpdateEnd: (event: KonvaEventObject<Event>) => void
}

export function TablesLayer({
    refreshTrigger,
    canEditTables,
    hidesTableNumber,
    newTable,
    tables,
    canvasSize,
    canvasScale,
    selectedTable,
    overlappingTables,
    onTableClick,
    onUpdate,
    onUpdateStart,
    onUpdateEnd,
    onNewUpdateEnd,
}: TablesLayerProps) {
    const newTableId = useConst(() => uuid())

    return (
        <Layer
            key={refreshTrigger}
            onDragMove={(event) => {
                if (event.target.name()) {
                    onUpdate(event)
                }
            }}
        >
            {tables.map((table) => (
                <TableShape
                    key={table.id.tableId}
                    id={table.id.tableId.toString()}
                    name={table.label}
                    canShowTableNumber={!hidesTableNumber}
                    shape={table.shape}
                    isEditable={canEditTables}
                    isSelected={_.isEqual(selectedTable, table.id)}
                    isOverlapping={overlappingTables.includes(table.id)}
                    canvasSize={canvasSize}
                    canvasScale={canvasScale}
                    onClick={() => onTableClick(table)}
                    onDragStart={onUpdateStart}
                    onDragEnd={onUpdateEnd}
                    onTransform={onUpdate}
                    onTransformEnd={onUpdateEnd}
                />
            ))}

            {isFullShape(newTable?.shape) && (
                <TableShape
                    id={newTableId}
                    name={newTable!.label}
                    canShowTableNumber={!hidesTableNumber}
                    shape={newTable!.shape}
                    isEditable={canEditTables}
                    isSelected={true}
                    isOverlapping={false}
                    canvasSize={canvasSize}
                    canvasScale={canvasScale}
                    onDragEnd={onNewUpdateEnd}
                    onTransform={onUpdate}
                    onTransformEnd={onNewUpdateEnd}
                />
            )}
        </Layer>
    )
}
