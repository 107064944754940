import { createContext, useContext } from 'react'
import { ManagedOrganization } from '../models/ManagedOrganization'
import { Organization } from '../models/Organization'

interface _OrganizationContext {
    organization?: Organization
    organizations: Organization[]
    assertOrganization: () => Organization
    init: (managedOrganizations: ManagedOrganization[]) => Promise<void>
    load: (id: string) => void
    add: (organization: Organization) => void
    clear: () => void
}

const defaultOrganizationContext: _OrganizationContext = {
    organization: undefined,
    organizations: [],
    assertOrganization() {
        throw new Error('Called from default context')
    },
    async init(user) {},
    load(id) {},
    add(organization) {},
    clear() {},
}

export const OrganizationContext = createContext(defaultOrganizationContext)
export default function useOrganizationContext() {
    return useContext(OrganizationContext)
}
