import { interfaces } from 'inversify'
import { ListPricingPlanAction } from './ListPricingPlanAction'

interface _PricingPlanDependencies {
    List: interfaces.ServiceIdentifier<ListPricingPlanAction>
}

export const PricingPlanDependencies: _PricingPlanDependencies = {
    List: Symbol('ListPricingPlanAction'),
}

export type { ListPricingPlanAction }
