import { Button, Center, GridItem } from '@chakra-ui/react'
import { format, getDay, isSameDay, isSameMonth } from 'date-fns'
import { DateRange } from 'features/calendar/models/Calendar'
import { useMemo } from 'react'
import takeIf from 'utils/takeif'

// Day of week
const monday = 1
const sunday = 0

interface CalendaryDayProps {
    day: Date
    isToday: boolean
    displayDate: Date
    closures: DateRange[]
    editingRange: { start?: Date; end?: Date }
    onClick: (day: Date) => void
    onHover: (day?: Date) => void
}

export function CalendarDay({
    day,
    isToday,
    displayDate,
    closures,
    editingRange,
    onClick,
    onHover,
}: CalendaryDayProps) {
    const status = useMemo(() => {
        return {
            isSelected: closures.some(
                (range) => range.from <= day && day <= range.to
            ),
            isStart: closures.some((range) => isSameDay(range.from, day)),
            isEnd: closures.some((range) => isSameDay(range.to, day)),
        }
    }, [closures]) // eslint-disable-line react-hooks/exhaustive-deps

    const editingStatus = useMemo(() => {
        let { start, end } = editingRange
        if (start === undefined) {
            return { isSelected: false, isStart: false, isEnd: false }
        } else if (end === undefined) {
            end = start
        } else if (end < start) {
            ;[start, end] = [end, start]
        }

        return {
            isSelected: start <= day && day <= end,
            isStart: isSameDay(start, day),
            isEnd: isSameDay(end, day),
        }
    }, [editingRange]) // eslint-disable-line react-hooks/exhaustive-deps

    const boxBorder = useMemo(() => {
        if (!editingStatus.isSelected) {
            return { borderWidth: 0, borderStyle: 'none' }
        }

        const dayOfWeek = getDay(day)
        const isLeftMargin = editingStatus.isStart || dayOfWeek === monday
        const isRightMargin = editingStatus.isEnd || dayOfWeek === sunday

        const left = isLeftMargin ? '3px' : '-3px'
        const right = isRightMargin ? '3px' : '-3px'
        const borderStartRadius = isLeftMargin ? 'full' : 0
        const borderEndRadius = isRightMargin ? 'full' : 0
        const borderStartWidth = isLeftMargin ? 1 : 0
        const borderEndWidth = isRightMargin ? 1 : 0

        return {
            left,
            right,
            borderStartRadius,
            borderEndRadius,
            borderStartWidth,
            borderEndWidth,
        }
    }, [editingStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    const boxBackground = useMemo(() => {
        if (!status.isSelected) {
            return { bg: 'transparent' }
        }

        const dayOfWeek = getDay(day)
        const isLeftMargin = status.isStart || dayOfWeek === monday
        const isRightMargin = status.isEnd || dayOfWeek === sunday

        const left = isLeftMargin ? '6px' : 0
        const right = isRightMargin ? '6px' : 0
        const borderStartRadius = isLeftMargin ? 'full' : 0
        const borderEndRadius = isRightMargin ? 'full' : 0

        return { left, right, borderStartRadius, borderEndRadius }
    }, [status])

    const buttonColors = useMemo(() => {
        const isInMonth = isSameMonth(day, displayDate)

        return {
            color: status.isSelected
                ? 'onAccentSurface'
                : isInMonth
                ? 'label1'
                : 'label2',
            borderBottomColor: status.isSelected
                ? 'onAccentSurface'
                : 'accentSurface',
        }
    }, [status, displayDate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <GridItem py={2}>
            <Center
                position="relative"
                _before={{
                    content: "''",
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    bg: 'var(--chakra-colors-accentSurface)',
                    ...boxBackground,
                }}
            >
                <Button
                    variant="unstyled"
                    fontWeight="normal"
                    borderRadius="full"
                    px="10px"
                    w="full"
                    h={8}
                    color={buttonColors.color}
                    onClick={() => onClick(day)}
                    onMouseEnter={() => onHover(day)}
                    onMouseLeave={() => onHover(undefined)}
                    _hover={{
                        _before: !editingStatus.isSelected
                            ? {
                                  content: "''",
                                  position: 'absolute',
                                  top: '-3px',
                                  left: '3px',
                                  right: '3px',
                                  bottom: '-3px',
                                  borderRadius: 'full',
                                  border: '1px solid var(--chakra-colors-accentSurface)',
                                  bg: takeIf(
                                      'whiteAlpha.300',
                                      () => status.isSelected
                                  ),
                              }
                            : {},
                    }}
                    _before={{
                        content: "''",
                        position: 'absolute',
                        top: '-3px',
                        left: '-3px',
                        right: '-3px',
                        bottom: '-3px',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'var(--chakra-colors-accentSurface)',
                        borderRadius: 'full',
                        ...boxBorder,
                    }}
                    _after={
                        isToday
                            ? {
                                  content: "''",
                                  position: 'absolute',
                                  bottom: 1,
                                  left: '50%',
                                  transform: 'translate(-50%, 0)',
                                  width: '25%',
                                  height: 0.5,
                                  borderRadius: 'full',
                                  bg: buttonColors.borderBottomColor,
                              }
                            : {}
                    }
                >
                    {format(day, 'd')}
                </Button>
            </Center>
        </GridItem>
    )
}
