import {
    Button,
    Center,
    Divider,
    GridItem,
    HStack,
    SimpleGrid,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'
import {
    addDays,
    addMonths,
    addWeeks,
    differenceInDays,
    eachDayOfInterval,
    endOfMonth,
    endOfWeek,
    isSameDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns'
import useT from 'localization/hooks/useT'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { MonthPicker } from 'uikit/form/input'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { UseScheduledClosuresFormDataHook } from '../../hooks/timeschedule/useScheduledClosureForm'
import { CalendarDay } from './CalendarDay'

function getDaysOfMonth(date: Date) {
    const start = startOfWeek(startOfMonth(date), { weekStartsOn: 1 })
    let end = endOfWeek(endOfMonth(date), { weekStartsOn: 1 })

    const duration = differenceInDays(end, start)

    // Add an additional week to ensure that the
    // length of the calendar is always the same
    if (duration === 34) {
        end = addDays(end, 7)
    }

    return eachDayOfInterval({ start, end })
}

interface ClosuresSectionProps {
    formData: UseScheduledClosuresFormDataHook
}

export function ClosuresSection({ formData }: ClosuresSectionProps) {
    const t = useT('timeschedule')

    const today = useMemo(() => startOfDay(new Date()), [])
    const [displayDate, setDisplayDate] = useState(today)
    const [start, setStart] = useState<Date>()
    const [end, setEnd] = useState<Date>()

    const calendarDays = useMemo(
        () => getDaysOfMonth(displayDate),
        [displayDate]
    )

    const screen = useScreenBreakpoint()

    const shortcuts = useMemo(
        () => [
            {
                label: t('scheduled_closures_shortcut_today_button'),
                from: today,
                to: today,
            },
            {
                label: t('scheduled_closures_shortcut_tomorrow_button'),
                from: addDays(today, 1),
                to: addDays(today, 1),
            },
            {
                label: t('scheduled_closures_shortcut_this_week_button'),
                from: startOfWeek(today, { weekStartsOn: 1 }),
                to: endOfWeek(today, { weekStartsOn: 1 }),
            },
            {
                label: t('scheduled_closures_shortcut_next_week_button'),
                from: startOfWeek(addWeeks(today, 1), { weekStartsOn: 1 }),
                to: endOfWeek(addWeeks(today, 1), { weekStartsOn: 1 }),
            },
            {
                label: t('scheduled_closures_shortcut_this_month_button'),
                from: startOfMonth(today),
                to: endOfMonth(today),
            },
            {
                label: t('scheduled_closures_shortcut_next_month_button'),
                from: startOfMonth(addMonths(today, 1)),
                to: endOfMonth(addMonths(today, 1)),
            },
        ],
        []
    )

    const headings = useMemo(
        () => [
            t('generic_day_abbreviated_monday'),
            t('generic_day_abbreviated_tuesday'),
            t('generic_day_abbreviated_wednesday'),
            t('generic_day_abbreviated_thursday'),
            t('generic_day_abbreviated_friday'),
            t('generic_day_abbreviated_saturday'),
            t('generic_day_abbreviated_sunday'),
        ],
        []
    )

    const handleDayClick = useCallback(
        (day: Date) => {
            if (start === undefined) {
                const index = formData.closures.findIndex(
                    (closure) => closure.from <= day && day <= closure.to
                )

                if (index !== -1) {
                    formData.removeRangeAt(index)
                } else {
                    setStart(day)
                }
            } else {
                formData.handleNewRange({ from: start, to: day })
                setStart(undefined)
                setEnd(undefined)
            }
        },
        [start, formData.closures]
    )

    const handleDayHover = useCallback(
        (day?: Date) => {
            if (start !== undefined) {
                setEnd(day)
            }
        },
        [start]
    )

    if (screen === 'mobile' || screen === 'tablet') {
        return (
            <>
                <VStack align="stretch" flex={1} spacing={0}>
                    <MonthPicker date={displayDate} onChange={setDisplayDate} />
                    <SimpleGrid columns={7} w="full" p={0}>
                        {headings.map((heading) => (
                            <GridItem key={heading}>
                                <Center>
                                    <Text fontWeight="medium">{heading}</Text>
                                </Center>
                            </GridItem>
                        ))}

                        {calendarDays.map((day) => (
                            <CalendarDay
                                key={day.toISOString()}
                                day={day}
                                isToday={isSameDay(day, today)}
                                displayDate={displayDate}
                                closures={formData.closures}
                                editingRange={{ start, end }}
                                onClick={handleDayClick}
                                onHover={handleDayHover}
                            />
                        ))}
                    </SimpleGrid>
                    <Text fontWeight="semibold" pt={8}>
                        {t('scheduled_closures_shortcuts_section_title')}
                    </Text>
                    <Wrap>
                        {shortcuts.map((shortcut, index) => (
                            <WrapItem key={shortcut.label}>
                                <Button
                                    variant="ghost"
                                    onClick={() => {
                                        formData.handleNewRange(shortcut)
                                    }}
                                >
                                    {shortcut.label}
                                </Button>
                            </WrapItem>
                        ))}
                    </Wrap>
                </VStack>
            </>
        )
    }

    return (
        <>
            <HStack align="stretch" spacing={0}>
                <VStack
                    align="stretch"
                    flex={1}
                    spacing={0}
                    borderRightWidth={1}
                >
                    {shortcuts.map((shortcut, index) => (
                        <Fragment key={shortcut.label}>
                            <Text
                                px={5}
                                py={2.5}
                                cursor="pointer"
                                _hover={{ bg: 'pageBackground3' }}
                                onMouseEnter={() => {
                                    setStart(shortcut.from)
                                    setEnd(shortcut.to)
                                }}
                                onMouseLeave={() => {
                                    setStart(undefined)
                                    setEnd(undefined)
                                }}
                                onClick={() => {
                                    formData.handleNewRange(shortcut)
                                }}
                            >
                                {shortcut.label}
                            </Text>
                            {index !== shortcuts.length - 1 && <Divider />}
                        </Fragment>
                    ))}
                </VStack>

                <VStack flex={2}>
                    {/* TODO: style month picker (icons as left / right element?) */}
                    <MonthPicker date={displayDate} onChange={setDisplayDate} />

                    <SimpleGrid columns={7} w="full" px={16}>
                        {headings.map((heading) => (
                            <GridItem key={heading}>
                                <Center>
                                    <Text fontWeight="medium">{heading}</Text>
                                </Center>
                            </GridItem>
                        ))}

                        {calendarDays.map((day) => (
                            <CalendarDay
                                key={day.toISOString()}
                                day={day}
                                isToday={isSameDay(day, today)}
                                displayDate={displayDate}
                                closures={formData.closures}
                                editingRange={{ start, end }}
                                onClick={handleDayClick}
                                onHover={handleDayHover}
                            />
                        ))}
                    </SimpleGrid>
                </VStack>
            </HStack>
        </>
    )
}
