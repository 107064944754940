import {
    CognitoIdentity,
    Credentials,
    GetCredentialsForIdentityCommand,
    GetCredentialsForIdentityInput,
    GetIdCommand,
} from '@aws-sdk/client-cognito-identity'
import { AuthDependencies } from 'features/auth/dependencies'
import { ConfigurationDependencies } from 'features/config/dependencies'
import { useInjection } from 'inversify-react'
import { PropsWithChildren, useState } from 'react'
import { AddressContext } from './AddressContext'

export default function AddressContextProvider(props: PropsWithChildren) {
    const auth = useInjection(AuthDependencies.Store)
    const config = useInjection(ConfigurationDependencies.Store)

    const [cachedCredentials, setCachedCredentials] = useState<Credentials>()

    async function retrieveAWSCredentials(): Promise<Credentials> {
        if (
            cachedCredentials &&
            cachedCredentials.Expiration &&
            cachedCredentials.Expiration.getTime() >
                new Date().getTime() + 1000 * 60
        )
            return cachedCredentials
        const credentials = await initializeAWS()
        if (credentials) {
            setCachedCredentials(credentials)
            return credentials
        } else {
            throw new Error('IdentityPool Error')
        }
    }

    async function initializeAWS(): Promise<Credentials | undefined> {
        const configuration = await config.configuration()
        const idToken = await auth.assertIdToken()

        const cognitoIdentity = new CognitoIdentity({
            region: configuration.aws.region,
        })

        try {
            const getId = new GetIdCommand({
                IdentityPoolId: configuration.aws.identityPool.dashboardPoolId,
                Logins: {
                    [`cognito-idp.${configuration.aws.region}.amazonaws.com/${configuration.aws.userPool.id}`]:
                        idToken,
                },
            })

            const identity = await cognitoIdentity.send(getId)
            const input: GetCredentialsForIdentityInput = {
                IdentityId: identity.IdentityId!,
                Logins: {
                    [`cognito-idp.${configuration.aws.region}.amazonaws.com/${configuration.aws.userPool.id}`]:
                        idToken,
                },
            }

            const getCredentials = new GetCredentialsForIdentityCommand(input)

            const credentials = (await cognitoIdentity.send(getCredentials))
                .Credentials

            return credentials
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    return (
        <AddressContext.Provider value={{ retrieveAWSCredentials }}>
            {props.children}
        </AddressContext.Provider>
    )
}
