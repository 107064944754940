import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Collapse,
    Flex,
    HStack,
    Heading,
    Icon,
    IconButton,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Stack,
    Text,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import { UserAvatar } from 'features/auth/components/UserAvatar'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useVerifyOrganization } from 'features/organization/hooks/useVerifyOrganization'
import { motion } from 'framer-motion'
import { PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaExclamationTriangle } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

interface _TopBarContext {
    prefersTopBarShadowHidden: (v: boolean) => void
}

const defaultTopBarContext: _TopBarContext = {
    prefersTopBarShadowHidden(v) {},
}

export const TopBarContext = createContext(defaultTopBarContext)

export function useTopBarContext(): _TopBarContext {
    return useContext(TopBarContext)
}

export default function TopBar({
    children,
    prefersTopBarShadowHidden,
}: PropsWithChildren & { prefersTopBarShadowHidden: boolean }) {
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const { pathname } = useLocation()

    const organizationManager = useOrganizationContext()
    const { isProcessing, startVerificationProcess } = useVerifyOrganization()

    const screen = useScreenBreakpoint()
    useEffect(onClose, [pathname])

    return (
        <>
            {/* <Box
                position="sticky"
                zIndex={99999}
                top={0}
                h={4}
                bg="pageBackground1"
                w="full"
            ></Box> */}
            <Box position="sticky" top={0} zIndex={100} bg="pageBackground1">
                <Flex
                    mt={4}
                    mx={2}
                    borderRadius="lg"
                    px={screen === 'mobile' ? 2 : 8}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={4}
                    py={4}
                    boxShadow={'md'}
                    bg="pageBackground2"
                >
                    <VStack w="full">
                        <HStack alignItems={'center'} w="full">
                            <HStack alignItems={'center'} w="full">
                                <Box>
                                    <Link to="">
                                        <HStack>
                                            <Image
                                                objectFit="cover"
                                                boxSize={8}
                                                src="/logo.png"
                                            />
                                            <Heading
                                                size="lg"
                                                fontFamily="Unica One"
                                                color="accent"
                                            >
                                                Avokado
                                            </Heading>
                                        </HStack>
                                    </Link>
                                </Box>
                                <HStack
                                    as={'nav'}
                                    spacing={4}
                                    display={{ base: 'none', md: 'flex' }}
                                    ps={8}
                                    fontSize="lg"
                                >
                                    {children}
                                </HStack>
                            </HStack>
                            <HStack maxW="6xl" w="full">
                                <Spacer />

                                {organizationManager.organization?.status ===
                                    'unverified' && (
                                    <Popover>
                                        <PopoverTrigger>
                                            <IconButton
                                                size="sm"
                                                aria-label="Verify account"
                                                borderRadius="full"
                                                icon={
                                                    <Icon
                                                        as={
                                                            FaExclamationTriangle
                                                        }
                                                    />
                                                }
                                            />
                                        </PopoverTrigger>

                                        <PopoverContent
                                            w="2xs"
                                            bg="sheetBackground1"
                                            borderColor="sheetBackground2"
                                        >
                                            <PopoverHeader fontWeight="bold">
                                                {t('Attention')}
                                            </PopoverHeader>
                                            <PopoverArrow bg="sheetBackground1" />
                                            <PopoverBody>
                                                <VStack>
                                                    <Text>
                                                        {t(
                                                            'Your account has not been verified yet.'
                                                        )}
                                                    </Text>

                                                    <Text>
                                                        {t(
                                                            'Please verify your identity to unlock access to all Avokado features.'
                                                        )}
                                                    </Text>
                                                </VStack>
                                            </PopoverBody>
                                            <PopoverFooter>
                                                <Button
                                                    size="sm"
                                                    w="full"
                                                    onClick={
                                                        startVerificationProcess
                                                    }
                                                    isLoading={isProcessing}
                                                >
                                                    {t('Verify now')}
                                                </Button>
                                            </PopoverFooter>
                                        </PopoverContent>
                                    </Popover>
                                )}
                                <UserAvatar />
                                {children !== undefined && (
                                    <IconButton
                                        size={'md'}
                                        variant="ghost"
                                        icon={
                                            isOpen ? (
                                                <CloseIcon />
                                            ) : (
                                                <HamburgerIcon />
                                            )
                                        }
                                        aria-label={'Open Menu'}
                                        display={{ md: 'none' }}
                                        onClick={isOpen ? onClose : onOpen}
                                    />
                                )}
                            </HStack>
                        </HStack>
                        <Collapse in={isOpen && screen === 'mobile'}>
                            <Box
                                display={{ md: 'none' }}
                                as={motion.div}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                mx={2}
                            >
                                <Stack
                                    w="full"
                                    as={'nav'}
                                    spacing={4}
                                    bg="pageBackground2"
                                    alignItems="center"
                                >
                                    {children}
                                </Stack>
                            </Box>
                        </Collapse>
                    </VStack>
                </Flex>
            </Box>
        </>
    )
}
