import Avokado, { AvokadoAPIError, UserRetrieve } from '@avokadoapp/avokado-ts'
import { mapRolesToModels } from 'features/organization/mappers/SDKMapper'
import { ManagedOrganization } from 'features/organization/models/ManagedOrganization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import jwt_decode from 'jwt-decode'
import { sleep } from 'utils/asyncawait/sleep'
import { mapUserToModel } from '../mappers/SDKResponseMapper'
import { User } from '../models/User'

export interface IdTokenPayload {
    given_name?: string
    family_name?: string
    picture?: string
    sub: string
    'custom:google_id'?: string
    'custom:apple_id'?: string
    email: string
    exp: number
}

interface Organizations {
    organizations: ManagedOrganization[]
}

interface RetrieveUserParams {
    idToken: string
}

export interface RetrieveUserAction {
    run(params: RetrieveUserParams): Promise<User & Organizations>
}

@injectable()
export class RetrieveUserActionLive implements RetrieveUserAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: RetrieveUserParams): Promise<User & Organizations> {
        const decodedIdToken = jwt_decode<IdTokenPayload>(params.idToken)
        const request: UserRetrieve.Request = { userId: decodedIdToken.sub }
        const response = await this.avokado.user.retrieve(request)
        return {
            ...mapUserToModel(decodedIdToken),
            organizations: mapRolesToModels(response.activeRoles),
        }
    }
}

@injectable()
export class RetrieveUserActionFake implements RetrieveUserAction {
    async run(params: RetrieveUserParams): Promise<User & Organizations> {
        await sleep(500)

        return {
            id: 'user-id',
            givenName: 'John',
            familyName: 'Doe',
            avatar: undefined,
            organizations: [],
            availableFederatedIdentities: [],
            email: 'john.doe@swiss.ch',
        }
    }
}

@injectable()
export class RetrieveUserActionFail implements RetrieveUserAction {
    async run(params: RetrieveUserParams): Promise<User & Organizations> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
