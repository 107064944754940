import { useBoolean } from '@chakra-ui/react'
import { useInjection } from 'inversify-react'
import { useSet } from 'react-use'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'
import { MenuTranslationDependencies } from '../dependencies/translation'
import { Menu } from '../models/Menu'

interface UseNewMenuTranslationControllerParams {
    menu: Menu
    onComplete: (menu: Menu) => void
}

export default function useNewMenuTranslationController({
    menu,
    onComplete,
}: UseNewMenuTranslationControllerParams) {
    const createTranslation = useInjection(MenuTranslationDependencies.Create)

    const [isProcessing, { on, off }] = useBoolean()
    const [selectedLanguages, { toggle, reset }] = useSet<string>()
    const taskFeedback = useTaskFeedback()

    async function submit() {
        if (isProcessing) return
        on()
        try {
            const result = await createTranslation.run({
                menu,
                // @ts-ignore
                languages: Array.from(selectedLanguages),
            })
            taskFeedback.succeed()
            reset()
            onComplete(result)
        } catch {
            taskFeedback.fail()
        } finally {
            off()
        }
    }

    return {
        selectedLanguages,
        toggle,
        taskFeedback,
        submit,
        isProcessing,
    }
}
