import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    Input,
    Text,
    VStack,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { FormEvent, useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
    usePasswordForgottenFormController,
    usePasswordForgottenFormData,
} from '../hooks/usePasswordForgottenForm'

export default function PasswordForgottenPage() {
    const t = useT('auth')

    const formData = usePasswordForgottenFormData()
    const controller = usePasswordForgottenFormController(formData)

    const [isError, setIsError] = useState(false)

    async function handleSubmit(event: FormEvent<HTMLDivElement>) {
        event.preventDefault()
        if (controller.canSubmit) {
            await controller.submit()
        }
    }

    useEffect(() => setIsError(false), [formData.email])

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack
                as={motion.div}
                align="stretch"
                w="full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <Button
                    as={Link}
                    to="/sign-in"
                    display="flex"
                    alignSelf="flex-start"
                    variant="unstyled"
                    size="sm"
                    color="label1"
                    mt={2}
                    leftIcon={<FaArrowLeft />}
                    _hover={{ color: 'accent' }}
                >
                    {t('generic_button_back')}
                </Button>
                <FormControl isInvalid={isError && formData.email === ''}>
                    <Input
                        type="email"
                        placeholder={t('generic_input_email_address')}
                        autoComplete="email"
                        bg="authBackground"
                        h="44px"
                        w="xs"
                        autoFocus
                        border="1px solid var(--chakra-colors-gray-400)"
                        _placeholder={{ color: 'label2' }}
                        borderRadius="full"
                        focusBorderColor="primary.200"
                        value={formData.email}
                        onChange={(e) => formData.setEmail(e.target.value)}
                    />
                    <FormErrorMessage>
                        {t('email_required_field_error')}
                    </FormErrorMessage>
                </FormControl>
                <Text fontSize="xs" color="label2">
                    {t('password_forgotten_enter_email_label')}
                </Text>

                <Button
                    w="full"
                    type="submit"
                    h="48px"
                    mt={8}
                    isLoading={controller.isProcessing}
                >
                    {t('generic_button_send_password_reset_code')}
                </Button>
            </VStack>
        </Box>
    )
}
