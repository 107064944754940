import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translation_en from './locales/en/translation/translation.json'
import translation_it from './locales/it/translation/translation.json'

import auth_en from './locales/en/auth/translation.json'
import auth_it from './locales/it/auth/translation.json'

import home_en from './locales/en/home/translation.json'
import home_it from './locales/it/home/translation.json'

import organization_en from './locales/en/organization/translation.json'
import organization_it from './locales/it/organization/translation.json'

import location_en from './locales/en/location/translation.json'
import location_it from './locales/it/location/translation.json'

import policy_en from './locales/en/policy/translation.json'
import policy_it from './locales/it/policy/translation.json'

import menu_en from './locales/en/menu/translation.json'
import menu_it from './locales/it/menu/translation.json'

import menuassignment_en from './locales/en/menuassignment/translation.json'
import menuassignment_it from './locales/it/menuassignment/translation.json'

import timeschedule_en from './locales/en/timeschedule/translation.json'
import timeschedule_it from './locales/it/timeschedule/translation.json'

import gallery_en from './locales/en/gallery/translation.json'
import gallery_it from './locales/it/gallery/translation.json'

import locationgeneral_en from './locales/en/locationgeneral/translation.json'
import locationgeneral_it from './locales/it/locationgeneral/translation.json'

import cardreader_en from './locales/en/cardreader/translation.json'
import cardreader_it from './locales/it/cardreader/translation.json'

import terminal_en from './locales/en/terminal/translation.json'
import terminal_it from './locales/it/terminal/translation.json'

import room_en from './locales/en/room/translation.json'
import room_it from './locales/it/room/translation.json'

import publish_en from './locales/en/publish/translation.json'
import publish_it from './locales/it/publish/translation.json'

import finance_en from './locales/en/finance/translation.json'
import finance_it from './locales/it/finance/translation.json'

import orderhistory_en from './locales/en/orderhistory/translation.json'
import orderhistory_it from './locales/it/orderhistory/translation.json'

import payout_en from './locales/en/payout/translation.json'
import payout_it from './locales/it/payout/translation.json'

import pricingplan_en from './locales/en/pricingplan/translation.json'
import pricingplan_it from './locales/it/pricingplan/translation.json'

import staff_en from './locales/en/staff/translation.json'
import staff_it from './locales/it/staff/translation.json'

import printer_en from './locales/en/printer/translation.json'
import printer_it from './locales/it/printer/translation.json'

import menutranslation_en from './locales/en/menutranslation/translation.json'
import menutranslation_it from './locales/it/menutranslation/translation.json'

const resources = {
    en: {
        translation: translation_en,
        auth: auth_en,
        home: home_en,
        organization: organization_en,
        location: location_en,
        policy: policy_en,
        menu: menu_en,
        menuassignment: menuassignment_en,
        timeschedule: timeschedule_en,
        gallery: gallery_en,
        locationgeneral: locationgeneral_en,
        cardreader: cardreader_en,
        terminal: terminal_en,
        room: room_en,
        publish: publish_en,
        finance: finance_en,
        orderhistory: orderhistory_en,
        payout: payout_en,
        pricingplan: pricingplan_en,
        staff: staff_en,
        printer: printer_en,
        menutranslation: menutranslation_en,
    },
    it: {
        translation: translation_it,
        auth: auth_it,
        home: home_it,
        organization: organization_it,
        location: location_it,
        policy: policy_it,
        menu: menu_it,
        menuassignment: menuassignment_it,
        timeschedule: timeschedule_it,
        gallery: gallery_it,
        locationgeneral: locationgeneral_it,
        cardreader: cardreader_it,
        terminal: terminal_it,
        room: room_it,
        publish: publish_it,
        finance: finance_it,
        orderhistory: orderhistory_it,
        payout: payout_it,
        pricingplan: pricingplan_it,
        staff: staff_it,
        printer: printer_it,
        menutranslation: menutranslation_it,
    },
}

function missingKeyCustomHandler(
    language: string,
    namespace: string,
    key: string
) {
    if (process.env.NODE_ENV !== 'development') return
    // @ts-ignore
    resources[language][namespace][key] = 'MISSING TRANSLATION'
    i18next.addResource(language, namespace, key, 'MISSING TRANSLATION')

    fetch('http://localhost:5525/missing-translation', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            lng: language,
            namespace: namespace,
            key: key,
        }),
    })
        .then(() => {})
        .catch((e) => {})
}

const namespaces = [
    'tranlsation',
    'auth',
    'home',
    'organization',
    'location',
    'policy',
    'menu',
    'menuassignment',
    'timeschedule',
    'gallery',
    'locationgeneral',
    'cardreader',
    'terminal',
    'room',
    'publish',
    'finance',
    'orderhistory',
    'payout',
    'pricingplan',
    'staff',
    'printer',
    'menutranslation',
] as const

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: false,
        interpolation: {
            escapeValue: false,
        },
        ns: namespaces,
        defaultNS: 'translation',
        saveMissing: true,
        missingKeyHandler: (_, namespace, key) => {
            missingKeyCustomHandler(i18next.resolvedLanguage!!, namespace, key)
        },
    })

export default i18next

export type Namespace = (typeof namespaces)[number]
