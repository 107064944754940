import { interfaces } from 'inversify'
import { BatchUpdateMenuEntryAction } from './BatchUpdateMenuEntryAction'
import { CreateMenuEntryAction } from './CreateMenuEntryAction'
import { UpdateMenuEntryAction } from './UpdateMenuEntryAction'
import { UploadMediaMenuEntryAction } from './UploadMediaMenuEntryAction'

interface _MenuEntryDependencies {
    Create: interfaces.ServiceIdentifier<CreateMenuEntryAction>
    Update: interfaces.ServiceIdentifier<UpdateMenuEntryAction>
    BatchUpdate: interfaces.ServiceIdentifier<BatchUpdateMenuEntryAction>
    UploadMedia: interfaces.ServiceIdentifier<UploadMediaMenuEntryAction>
}

export const MenuEntryDependencies: _MenuEntryDependencies = {
    Create: Symbol('CreateMenuEntryAction'),
    Update: Symbol('UpdateMenuEntryAction'),
    BatchUpdate: Symbol('BatchUpdateMenuEntryAction'),
    UploadMedia: Symbol('UploadMediaMenuEntryAction'),
}

export type {
    BatchUpdateMenuEntryAction,
    CreateMenuEntryAction,
    UpdateMenuEntryAction,
    UploadMediaMenuEntryAction,
}
