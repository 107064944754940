import { Location } from 'features/location/models/Location'
import { Menu } from 'features/menu/models/Menu'
import { MenuAssignmentDependencies } from 'features/menuassignment/dependencies'
import { dependencyContainer } from 'infra/di/inversify.config'
import { useSet } from 'react-use'

export const NewMenuAssignStep = 3

export interface UseNewMenuAssignStepFormDataHook {
    selectedLocationsIds: Set<Location.ID>
    toggle: (id: Location.ID) => void
}

export function useNewMenuAssignStepFormData(): UseNewMenuAssignStepFormDataHook {
    const [selectedLocationsIds, { toggle }] = useSet<Location.ID>()

    return { selectedLocationsIds, toggle }
}

export async function handleAssignStep(
    data: UseNewMenuAssignStepFormDataHook,
    menu: Menu
): Promise<Menu> {
    const create = dependencyContainer.get(MenuAssignmentDependencies.Create)
    const ids = Array.from(data.selectedLocationsIds)
    const tasks = ids.map(async (locationId) =>
        create.run({
            ...menu.id,
            ...locationId,
            restrictions: [],
            incompatibleMenuIds: [],
            availabilities: 'always',
            isActive: true,
        })
    )

    await Promise.all(tasks)

    return menu
}
