import { interfaces } from 'inversify'
import { CreateTerminalAction } from './CreateTerminalAction'
import { ListTerminalAction } from './ListTerminalAction'
import { ProvisionTerminalAction } from './ProvisionTerminalAction'

interface _TerminalDependencies {
    List: interfaces.ServiceIdentifier<ListTerminalAction>
    Create: interfaces.ServiceIdentifier<CreateTerminalAction>
    Provision: interfaces.ServiceIdentifier<ProvisionTerminalAction>
}

export const TerminalDependencies: _TerminalDependencies = {
    List: Symbol('ListTerminalAction'),
    Create: Symbol('CreateTerminalAction'),
    Provision: Symbol('ProvisionTerminalAction'),
}

export type {
    ListTerminalAction,
    CreateTerminalAction,
    ProvisionTerminalAction,
}
