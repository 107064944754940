import { Avatar, AvatarProps, useColorModeValue } from '@chakra-ui/react'
import { Menu } from 'features/menu/models/Menu'
import {
    CeleryIcon,
    CerealsIcon,
    CrustaceansIcon,
    EggsIcon,
    FishIcon,
    LupinIcon,
    MilkIcon,
    MolluscsIcon,
    MustardIcon,
    NutsIcon,
    PeanutsIcon,
    SesameIcon,
    SoybeansIcon,
    SulphitesIcon,
} from './icons'

interface AllergenIconProps extends AvatarProps {
    allergen: Menu.Section.Entry.Allergen
}

export function AllergenIcon({ allergen, ...props }: AllergenIconProps) {
    switch (allergen) {
        case Menu.Section.Entry.Allergen.Celery:
            return <Celery {...props} />
        case Menu.Section.Entry.Allergen.Cereal:
            return <Cereal {...props} />
        case Menu.Section.Entry.Allergen.Crustaceans:
            return <Crustaceans {...props} />
        case Menu.Section.Entry.Allergen.Egg:
            return <Egg {...props} />
        case Menu.Section.Entry.Allergen.Fish:
            return <Fish {...props} />
        case Menu.Section.Entry.Allergen.Lupin:
            return <Lupin {...props} />
        case Menu.Section.Entry.Allergen.Peanuts:
            return <Peanuts {...props} />
        case Menu.Section.Entry.Allergen.Soya:
            return <Soya {...props} />
        case Menu.Section.Entry.Allergen.Milk:
            return <Milk {...props} />
        case Menu.Section.Entry.Allergen.Molluscs:
            return <Molluscs {...props} />
        case Menu.Section.Entry.Allergen.Mustard:
            return <Mustard {...props} />
        case Menu.Section.Entry.Allergen.Nuts:
            return <Nuts {...props} />
        case Menu.Section.Entry.Allergen.Sesame:
            return <Sesame {...props} />
        case Menu.Section.Entry.Allergen.Sulphites:
            return <Sulphites {...props} />
    }
}

function Celery(props: AvatarProps) {
    const color = useColorModeValue('#9CE085', '#9CE085')

    return <Avatar {...props} bg="white" icon={<CeleryIcon fill={color} />} />
}

function Cereal(props: AvatarProps) {
    const color = useColorModeValue('#D5B573', '#FFD88D')

    return <Avatar {...props} bg="white" icon={<CerealsIcon fill={color} />} />
}

function Crustaceans(props: AvatarProps) {
    const color = useColorModeValue('#D77C80', '#D77C78')

    return (
        <Avatar {...props} bg="white" icon={<CrustaceansIcon fill={color} />} />
    )
}

function Egg(props: AvatarProps) {
    const color = useColorModeValue('#E8CD71', '#E8CD71')

    return <Avatar {...props} bg="white" icon={<EggsIcon fill={color} />} />
}

function Fish(props: AvatarProps) {
    const color = useColorModeValue('#9DC0DD', '#9DC0DD')

    return <Avatar {...props} bg="white" icon={<FishIcon fill={color} />} />
}

function Lupin(props: AvatarProps) {
    const color = useColorModeValue('#DAB571', '#DAB571')

    return <Avatar {...props} bg="white" icon={<LupinIcon fill={color} />} />
}

function Peanuts(props: AvatarProps) {
    const color = useColorModeValue('#BF9954', '#BF9954')

    return <Avatar {...props} bg="white" icon={<PeanutsIcon fill={color} />} />
}

function Soya(props: AvatarProps) {
    const color = useColorModeValue('#A2D094', '#A2D094')

    return <Avatar {...props} bg="white" icon={<SoybeansIcon fill={color} />} />
}

function Milk(props: AvatarProps) {
    const color = useColorModeValue('#99B2C4', '#99B2C4')

    return <Avatar {...props} bg="white" icon={<MilkIcon fill={color} />} />
}

function Molluscs(props: AvatarProps) {
    const color = useColorModeValue('#DD9D7C', '#DD9D7C')

    return <Avatar {...props} bg="white" icon={<MolluscsIcon fill={color} />} />
}

function Mustard(props: AvatarProps) {
    const color = useColorModeValue('#DAB654', '#DAB654')

    return <Avatar {...props} bg="white" icon={<MustardIcon fill={color} />} />
}

function Nuts(props: AvatarProps) {
    const color = useColorModeValue('#BFA674', '#BFA674')

    return <Avatar {...props} bg="white" icon={<NutsIcon fill={color} />} />
}

function Sesame(props: AvatarProps) {
    const color = useColorModeValue('#BCAA84', '#BCAA84')

    return <Avatar {...props} bg="white" icon={<SesameIcon fill={color} />} />
}

function Sulphites(props: AvatarProps) {
    const color = useColorModeValue('#FF2072', '#FF2072')

    return (
        <Avatar {...props} bg="white" icon={<SulphitesIcon fill={color} />} />
    )
}
