import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import axios from 'axios'
import { injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import Constants from 'utils/constant/Constants'
import { Configuration } from '../models/Configuration'

export interface RetrieveConfigurationAction {
    run(): Promise<Configuration>
}

@injectable()
export class RetrieveConfigurationActionLive
    implements RetrieveConfigurationAction
{
    async run(): Promise<Configuration> {
        const axiosResponse = await axios.get<Configuration>(
            `https://config.${Constants.Host}/settings.json`
        )
        return axiosResponse.data
    }
}

@injectable()
export class RetrieveConfigurationActionFake
    implements RetrieveConfigurationAction
{
    async run(): Promise<Configuration> {
        await sleep(500)

        return {
            aws: {
                region: 'eu-central-1',
                userPool: {
                    id: 'eu-central-1_rzwhYllWI',
                    appClientId: '5d1qo0kpp44tohssfjfdp2hntm',
                    registerClientId: '1l15cbfhv0p1g3k7sp2nffida8',
                    dashboardClientId: 'ccsgtnrtq63ciqqv0eq908hk5',
                },
                identityPool: {
                    dashboardPoolId:
                        'eu-central-1:95d06adb-52c4-4438-ba0f-8f425de3dba3',
                },
                geo: {
                    placeIndexName: 'avokado-place-index-single-use-dev',
                    mapName: 'avokado-location-dev',
                },
            },
        }
    }
}

@injectable()
export class RetrieveConfigurationActionFail
    implements RetrieveConfigurationAction
{
    async run(): Promise<Configuration> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
