import { useCallback, useEffect, useState } from 'react'
import { generateRandomString } from 'utils/crypto/generateRandomString'

export interface SignInWithAppleResult {
    authorization: Authorization
    user?: User
}

interface Authorization {
    code: string
    id_token: string
    state: string
}

interface User {
    email?: string
    name?: {
        firstName?: string
        lastName?: string
    }
}

export interface UseSignInWithAppleParams {
    clientId: string
    redirectURI: string
    scope: string
}

export module SignInWithAppleError {
    export class InvalidState extends Error {
        message: string = 'SignInWithAppleError.InvalidState'
    }

    export class SignIn extends Error {
        code: string

        constructor(code: string) {
            super('SignInWithAppleError.SignInError')
            this.code = code
        }
    }

    export class Unknown extends Error {
        message: string = 'SignInWithAppleError.InvalidState'
    }
}

export function useSignInWithApple(params: UseSignInWithAppleParams) {
    const [state] = useState(generateRandomString(32))
    useEffect(() => {
        // @ts-ignore
        AppleID.auth.init({
            clientId: params.clientId,
            scope: params.scope,
            redirectURI: params.redirectURI,
            state: state,
            usePopup: true,
        })
    }, [params.clientId, params.scope, params.redirectURI, state])

    const signInWithApple =
        useCallback(async (): Promise<SignInWithAppleResult> => {
            try {
                // @ts-ignore
                const data: SignInWithAppleResult = await AppleID.auth.signIn()
                if (data.authorization.state !== state) {
                    throw new SignInWithAppleError.InvalidState()
                }
                if ('error' in data) {
                    throw new SignInWithAppleError.SignIn('' + data.error)
                }
                return data
            } catch {
                throw new SignInWithAppleError.Unknown()
            }
        }, [state])

    return signInWithApple
}
