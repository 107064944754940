import { SVGProps } from 'react'

export function SesameIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Sesame"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M199.95 100c-.32 23.86-7.21 45.43-23.29 64.39-19.69 23.21-45.06 35.44-76.11 35.61-32.86.17-59.26-13.22-78.94-38.25C-1.65 132.15-5.1 99.72 6.33 65.17c7.42-22.43 23.07-38.89 42.9-51.92C58.93 6.88 70.27 3.4 82 1.59c20.79-3.22 41.32-1.89 60.15 7.21 31.47 15.2 50.08 40.01 56.6 72.92.51 2.55 1 5.13 1.14 7.71.2 3.52.05 7.05.05 10.58zm-78.56 41.33c-.51-14.04-9.58-22.82-21.49-29.96-3.78-2.27-5.69-1.07-7.24 2.16-3.43 7.17-5.36 14.77-5.12 22.56.22 7.13 2.57 13.76 8.66 18.76 9.69 7.95 21.88 4.22 24.55-7.67.4-1.79.41-3.67.64-5.85zM89.4 97.95c.18-12.22-10.47-27.04-22.43-31.4-2.6-.95-4.73-.85-5.86 1.72-5.04 11.54-8.22 23.28-2.42 35.45 3.69 7.75 11.9 12 19.41 10.42 6.96-1.47 11.17-7.49 11.3-16.19zm22.38.17c.07 2.64.21 5.2 1.08 7.71 2.66 7.68 7.82 12.06 14.23 11.92 6.63-.15 14.22-6.01 16.1-12.74 3.67-13.15-.51-24.78-7.98-35.73-1.46-2.14-3.27-2.52-5.39-.91-10.12 7.68-17.27 16.99-18.04 29.74z" />
            <path d="M117.23 140.17c-.06 1.28-.01 2.32-.18 3.33-.7 4.25-2.27 8.14-7.18 9.65-5.11 1.57-9.3-.58-12.67-3.81-6.89-6.58-7.54-25.57-1.43-32.73 1.03-1.21 1.97-1.29 3.36-.66 7.28 3.32 19.42 15.54 18.1 24.21zM84.89 97.01c-.32 1.58-.46 4.38-1.47 6.83-1.41 3.43-3.89 6.27-7.94 6.49-5.22.28-9.08-2.58-11.56-6.79-6.2-10.52-3.92-21.17.13-31.74.52-1.36 1.43-2.15 2.92-1.31 7.97 4.45 17.94 15.15 17.91 26.53zM116.1 97.81c-.09-10 5.92-17.01 12.02-23.89 1.76-1.98 4.21-2.59 5.7.46 4.2 8.6 8.62 17.23 5.14 27.26-.7 2.02-1.58 4.05-2.77 5.81-2.34 3.44-5.51 5.81-9.94 5.38-4.65-.46-7.27-3.52-9.02-7.58-1.03-2.4-1.02-4.91-1.12-7.45zM71.16 76.79s7.97 4.65 7.29 11.58M132.07 79.72s5.98 7.03 3.05 13.34M101.84 121.96s8.14 4.35 7.72 11.3" />
        </svg>
    )
}
