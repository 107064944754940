import { CheckIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    HStack,
    Icon,
    IconButton,
    Switch,
    Text,
    VStack,
} from '@chakra-ui/react'
import { Location } from 'features/location/models/Location'
import { useMenu } from 'features/menu/hooks/useMenu'
import { isValid } from 'features/menu/services/isValid'
import {
    useMenuAssignmentController,
    useMenuAssignmentFormData,
} from 'features/menuassignment/hooks/Temporary/useMenuAssignmentForm'
import { MenuAssignment } from 'features/menuassignment/models/MenuAssignment'

import LoadingPage from 'infra/navigation/pages/LoadingPage'
import useT from 'localization/hooks/useT'
import { useNavigate } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import useFormNotification from 'utils/hooks/useFormNotification'

interface MenuAssignmentsCardProps {
    menuAssignment: MenuAssignment
    location: Location
    onMenuAssignmentUpdated: (m: MenuAssignment) => void
}

export default function MenuAssignmentsCard({
    menuAssignment,
    location,
    onMenuAssignmentUpdated,
}: MenuAssignmentsCardProps) {
    const t = useT('menuassignment')
    const formData = useMenuAssignmentFormData(menuAssignment)
    const controller = useMenuAssignmentController(menuAssignment, formData, {
        didUpdate: onMenuAssignmentUpdated,
    })
    const { menu } = useMenu(menuAssignment.id.menuId)
    const navigate = useNavigate()

    useFormNotification(
        `menu-assignment-${menuAssignment.id.menuAssignmentId}`,
        controller
    )

    if (!menu) {
        return <LoadingPage />
    }

    const menuIsValid = isValid(menu)

    return (
        <Container
            title={menu.name}
            icon={
                <IconButton
                    variant="link"
                    aria-label="Open menu"
                    icon={<ExternalLinkIcon />}
                    onClick={() =>
                        navigate(
                            `/organizations/${menuAssignment.id.organizationId}/menus/${menuAssignment.id.menuId}`
                        )
                    }
                />
            }
            header={
                <HStack>
                    <Text fontWeight="medium">{t('is_active_switch')}</Text>
                    <Switch
                        size="lg"
                        onChange={(v) => formData.setIsActive(v.target.checked)}
                        isChecked={formData.isActive}
                        isDisabled={!menuIsValid}
                    />
                </HStack>
            }
        >
            {!menuIsValid && (
                <Alert status="error" borderRadius="md">
                    <AlertIcon />
                    <Box w="full">
                        <AlertTitle>
                            {t('menu_is_invalid_alert_title')}
                        </AlertTitle>
                        <AlertDescription>
                            {t('menu_is_invalid_alert_description', {
                                menuName: menu.name,
                                locationName: location.name,
                            })}
                        </AlertDescription>
                    </Box>
                </Alert>
            )}

            {menuIsValid && (
                <Alert
                    status={menuAssignment.isActive ? 'success' : 'warning'}
                    borderRadius="md"
                >
                    <AlertIcon />
                    <Box w="full">
                        <AlertTitle>{t('card_alert_title')}</AlertTitle>
                        <AlertDescription>
                            {menuAssignment.isActive &&
                                t('card_menu_is_active_alert_description', {
                                    locationName: location.name,
                                    menuName: menu.name,
                                })}
                            {!menuAssignment.isActive &&
                                t('card_menu_is_not_active_alert_description', {
                                    locationName: location.name,
                                    menuName: menu.name,
                                })}
                        </AlertDescription>
                    </Box>
                </Alert>
            )}

            <MenuAssignmentsCardFooter
                menuIsValid={menuIsValid}
                isDisabled={menuIsValid ? !formData.hasChanges : false}
                isLoading={controller.isProcessing}
                onClick={() => {
                    if (menuIsValid) {
                        controller.submit()
                    } else {
                        navigate(
                            `/organizations/${menuAssignment.id.organizationId}/menus/${menuAssignment.id.menuId}`
                        )
                    }
                }}
            />
        </Container>
    )
}

interface MenuAssignmentsCardFooterProps {
    menuIsValid: boolean
    isDisabled: boolean
    isLoading: boolean
    onClick: () => void
}

function MenuAssignmentsCardFooter(props: MenuAssignmentsCardFooterProps) {
    const { menuIsValid, isDisabled, isLoading, onClick } = props
    const screen = useScreenBreakpoint()
    const t = useT('menuassignment')

    if (screen === 'mobile') {
        return (
            <VStack w="full" align="stretch">
                <Button
                    w="full"
                    leftIcon={
                        <Icon as={menuIsValid ? CheckIcon : ExternalLinkIcon} />
                    }
                    onClick={onClick}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                >
                    {menuIsValid
                        ? t('save_changes_button')
                        : t('view_menu_button')}
                </Button>
            </VStack>
        )
    } else {
        return (
            <HStack w="full" justify="end">
                <ButtonGroup>
                    <Button
                        leftIcon={
                            <Icon
                                as={menuIsValid ? CheckIcon : ExternalLinkIcon}
                            />
                        }
                        onClick={onClick}
                        isLoading={isLoading}
                        isDisabled={isDisabled}
                    >
                        {menuIsValid
                            ? t('save_changes_button')
                            : t('view_menu_button')}
                    </Button>
                </ButtonGroup>
            </HStack>
        )
    }
}
