import Avokado, {
    AvokadoAPIError,
    MenuExtraDelete,
} from '@avokadoapp/avokado-ts'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

type DeleteMenuExtraParams = Menu.Extra.ID

export interface DeleteMenuExtraAction {
    run(params: DeleteMenuExtraParams): Promise<void>
}

@injectable()
export class DeleteMenuExtraActionLive implements DeleteMenuExtraAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: DeleteMenuExtraParams): Promise<void> {
        const request: MenuExtraDelete.Request = {
            organizationId: params.organizationId,
            menuId: params.menuId,
            menuExtraId: params.extraId,
        }

        await this.avokado.menuExtra.delete(request)
    }
}

@injectable()
export class DeleteMenuExtraActionFake implements DeleteMenuExtraAction {
    async run(params: DeleteMenuExtraParams): Promise<void> {
        await sleep(500)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class DeleteMenuExtraActionFail implements DeleteMenuExtraAction {
    async run(params: DeleteMenuExtraParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
