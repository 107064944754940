import { useBoolean } from '@chakra-ui/react'
import { useInjection } from 'inversify-react'
import useTaskFeedback from 'utils/hooks/useTaskFeedback'
import { MenuTranslationDependencies } from '../dependencies/translation'
import { Menu } from '../models/Menu'

interface UseTranslationPublishmentParams {
    onUpdate: (locale: string, isPublished: boolean) => void
}

export interface UseTranslationPublishmentHook {
    isProcessing: boolean
    submit: (
        menuId: Menu.ID,
        locale: string,
        isPublished: boolean
    ) => Promise<void>
}

export default function useTranslationPublishment(
    params: UseTranslationPublishmentParams
): UseTranslationPublishmentHook {
    const [isProcessing, { on, off }] = useBoolean(false)
    const updateVisibility = useInjection(
        MenuTranslationDependencies.UpdateTranslatedMenuVisibility
    )
    const task = useTaskFeedback()

    async function submit(
        menuId: Menu.ID,
        locale: string,
        isPublished: boolean
    ): Promise<void> {
        if (isProcessing) return
        on()
        try {
            await updateVisibility.run({
                menuId,
                // @ts-ignore
                language: locale,
                isPublished,
            })
            params.onUpdate(locale, isPublished)
            task.succeed()
        } catch (e) {
            task.fail()
        } finally {
            off()
        }
    }

    return {
        isProcessing,
        submit,
    }
}
