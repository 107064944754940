import { Payout } from 'features/finance/models/Payout'

export function describePayout(payout: Payout) {
    switch (payout.status) {
        case 'paid':
            return { color: 'green.400' }
        case 'failed':
            return { color: 'red.500' }
        case 'pending':
            return { color: 'yellow.400' }
    }
}
