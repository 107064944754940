import Avokado, {
    AvokadoAPIError,
    MenuAssignmentCreate,
} from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import {
    mapModelToAvailabilityTimes,
    mapModelToRestrictions,
} from '../mappers/SDKRequestMapper'
import { mapMenuAssignmentToModel } from '../mappers/SDKResponseMapper'
import { MenuAssignment } from '../models/MenuAssignment'

interface CreateMenuAssignmentParams extends Location.ID, Menu.ID {
    restrictions: MenuAssignment.Restriction[]
    incompatibleMenuIds: number[]
    availabilities: MenuAssignment.Availabilities
    isActive: boolean
}

export interface CreateMenuAssignmentAction {
    run(params: CreateMenuAssignmentParams): Promise<MenuAssignment>
}

@injectable()
export class CreateMenuAssignmentActionLive
    implements CreateMenuAssignmentAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateMenuAssignmentParams): Promise<MenuAssignment> {
        const request: MenuAssignmentCreate.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            menuId: params.menuId,
            isActive: params.isActive,
            restrictions: mapModelToRestrictions(params.restrictions),
            incompatibleMenusIds: params.incompatibleMenuIds,
            availabilities: mapModelToAvailabilityTimes(params.availabilities),
        }

        const response = await this.avokado.menuAssignment.create(request)
        return mapMenuAssignmentToModel(response)
    }
}

@injectable()
export class CreateMenuAssignmentActionFake
    implements CreateMenuAssignmentAction
{
    async run(params: CreateMenuAssignmentParams): Promise<MenuAssignment> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class CreateMenuAssignmentActionFail
    implements CreateMenuAssignmentAction
{
    async run(params: CreateMenuAssignmentParams): Promise<MenuAssignment> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
