import {
    FormHelperText,
    FormLabel,
    FormLabelProps,
    VStack,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import MoreInfoTooltip from 'utils/genericcomponents/MoreInfoTooltip'

interface InputLabelsProps extends FormLabelProps {
    title?: string
    description?: string
    moreInfo?: string
}

export function InputLabels({
    title,
    description,
    moreInfo,
    ...props
}: InputLabelsProps) {
    const t = useT()
    if (!title && !description) return null

    return (
        <VStack align="stretch" spacing={0} mb={1.5}>
            {title && (
                <FormLabel m={0} {...props}>
                    {title}
                </FormLabel>
            )}

            {description && (
                <FormHelperText m={0}>
                    {description}{' '}
                    {moreInfo && (
                        <>
                            <MoreInfoTooltip
                                moreInfo={moreInfo}
                                title={title}
                            />
                            {'.'}
                        </>
                    )}
                </FormHelperText>
            )}
        </VStack>
    )
}
