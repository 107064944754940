import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import { useInjection } from 'inversify-react'
import { useEffect, useState } from 'react'
import useTaskFeedback, {
    TaskFeedbackObservable,
} from 'utils/hooks/useTaskFeedback'
import { MenuTranslationDependencies } from '../dependencies/translation'
import TranslationBundle from '../models/TranslationBundle'

export interface UseTranslationBundlesHook {
    isLoading: boolean
    hasFailed: boolean
    translationBundles: TranslationBundle[] | undefined
    reload: () => Promise<void>
    isBuying: boolean
    buy: (languageCode: TranslationBundle['languageCode']) => Promise<void>
    buyBundleTaskFeedback: TaskFeedbackObservable
}

export default function useTranslationBundles(): UseTranslationBundlesHook {
    const [isLoading, setIsLoading] = useState(false)
    const [isBuying, setIsBuying] = useState(false)
    const [hasFailed, setHasFailed] = useState(false)
    const buyBundleTaskFeedback = useTaskFeedback()

    const [translationBundles, setTranslationBundles] =
        useState<TranslationBundle[]>()

    const organizationId =
        useOrganizationContext().assertOrganization().id.organizationId

    const listTranslationBundles = useInjection(
        MenuTranslationDependencies.ListTranslationBundles
    )
    const buyTranslationBundle = useInjection(
        MenuTranslationDependencies.BuyTranslationBundle
    )

    async function load() {
        if (isLoading) return
        setIsLoading(true)
        setHasFailed(false)
        try {
            const bundles = await listTranslationBundles.run({ organizationId })
            setTranslationBundles(bundles)
        } catch {
            setHasFailed(true)
        } finally {
            setIsLoading(false)
        }
    }

    async function buy(languageCode: TranslationBundle['languageCode']) {
        if (isBuying) return
        setIsBuying(true)
        try {
            await buyTranslationBundle.run({ organizationId, languageCode })
            if (translationBundles !== undefined) {
                const copy = [...translationBundles]
                copy.push({ languageCode })
                setTranslationBundles(copy)
            }
            buyBundleTaskFeedback.succeed()
        } catch {
            buyBundleTaskFeedback.fail()
        } finally {
            setIsBuying(false)
        }
    }

    useEffect(() => {
        load()
    }, [])

    return {
        isLoading,
        hasFailed,
        translationBundles,
        isBuying,
        reload: load,
        buy,
        buyBundleTaskFeedback: buyBundleTaskFeedback,
    }
}
