import {
    Box,
    Button,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    VStack,
    useConst,
} from '@chakra-ui/react'
import { getMonth, getYear, setMonth, setYear } from 'date-fns'
import useT from 'localization/hooks/useT'
import { useCallback, useMemo } from 'react'
import { FiCalendar } from 'react-icons/fi'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

type AllowedDates = 'all' | 'future' | 'past'

function monthProps(
    isDisabled: boolean,
    allowedDates: AllowedDates,
    date: Date,
    month: number,
    currentMonth: number,
    currentYear: number
) {
    const selectedMonth = getMonth(date)
    const selectedYear = getYear(date)

    if (selectedMonth === month) {
        return {
            color: 'accent',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
        }
    }

    if (isDisabled) {
        return { color: 'label2', cursor: 'not-allowed' }
    }

    if (selectedYear !== currentYear) {
        return { color: 'label1', cursor: 'pointer' }
    }

    if (
        (allowedDates === 'past' && month > currentMonth) ||
        (allowedDates === 'future' && month < currentMonth)
    ) {
        return { color: 'label2', cursor: 'not-allowed' }
    }

    return { color: 'label1', cursor: 'pointer' }
}

interface MonthPickerProps {
    date: Date
    yearRange?: number
    allowedDates?: AllowedDates
    variant?: 'outline' | 'flushed' | 'filled'
    isDisabled?: boolean
    onChange: (date: Date) => void
}

export function MonthPicker({
    date,
    yearRange = 20,
    allowedDates = 'all',
    isDisabled = false,
    onChange,
}: MonthPickerProps) {
    const t = useT('timeschedule')

    const [currentMonth, currentYear] = useConst(() => {
        const now = new Date()
        return [getMonth(now), getYear(now)]
    })

    const months = useConst(() => [
        t('generic_month_name_january'),
        t('generic_month_name_february'),
        t('generic_month_name_march'),
        t('generic_month_name_april'),
        t('generic_month_name_may'),
        t('generic_month_name_june'),
        t('generic_month_name_july'),
        t('generic_month_name_august'),
        t('generic_month_name_september'),
        t('generic_month_name_october'),
        t('generic_month_name_november'),
        t('generic_month_name_december'),
    ])

    const years = useMemo(() => {
        const lowerBound = allowedDates === 'future' ? 0 : yearRange
        const upperBound = allowedDates === 'past' ? 0 : yearRange

        const result: number[] = []

        for (
            let i = currentYear - lowerBound;
            i <= currentYear + upperBound;
            i++
        ) {
            result.push(i)
        }

        return result
    }, [allowedDates, yearRange])

    const handleMonthChange = useCallback(
        (month: number) => {
            if (isDisabled) return
            onChange(setMonth(date, month))
        },
        [isDisabled, date, onChange]
    )

    const handleYearChange = useCallback(
        (year: number) => {
            if (isDisabled) return
            onChange(setYear(date, year))
        },
        [isDisabled, date, onChange]
    )

    return (
        <Box>
            <Popover>
                <MonthPickerPopoverTrigger
                    date={date}
                    isDisabled={isDisabled}
                    months={months}
                    years={years}
                />

                <PopoverContent w="3xs" bg="sheetBackground1">
                    <PopoverArrow bg="sheetBackground1" />
                    <PopoverBody>
                        <HStack maxH="sm" align="stretch">
                            <VStack flex={1} overflow="auto">
                                {months.map((month, index) => {
                                    const textProps = monthProps(
                                        isDisabled,
                                        allowedDates,
                                        date,
                                        index,
                                        currentMonth,
                                        currentYear
                                    )

                                    return (
                                        <Text
                                            key={month}
                                            onClick={
                                                textProps.cursor ===
                                                'not-allowed'
                                                    ? undefined
                                                    : () =>
                                                          handleMonthChange(
                                                              index
                                                          )
                                            }
                                            {...textProps}
                                        >
                                            {month}
                                        </Text>
                                    )
                                })}
                            </VStack>

                            <VStack px={4} overflow="auto">
                                {years.map((year) => (
                                    <Text
                                        key={year}
                                        cursor={
                                            isDisabled
                                                ? 'not-allowed'
                                                : 'pointer'
                                        }
                                        color={
                                            getYear(date) === year
                                                ? 'accent'
                                                : isDisabled
                                                ? 'label2'
                                                : 'label1'
                                        }
                                        onClick={() => handleYearChange(year)}
                                    >
                                        {year}
                                    </Text>
                                ))}
                            </VStack>
                        </HStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    )
}

interface MonthPickerPopoverTriggerProps {
    isDisabled: boolean
    months: string[]
    years: number[]
    date: Date
}

function MonthPickerPopoverTrigger(props: MonthPickerPopoverTriggerProps) {
    const screen = useScreenBreakpoint()
    const { isDisabled, months, years, date } = props

    if (screen === 'mobile' || screen === 'tablet') {
        return (
            <PopoverTrigger>
                <Button
                    w="full"
                    variant="ghost"
                    rightIcon={<FiCalendar />}
                    justifyContent="end"
                >
                    {months[getMonth(date)] + ' ' + getYear(date)}
                </Button>
            </PopoverTrigger>
        )
    }

    return (
        <PopoverTrigger>
            <InputGroup>
                <Input
                    isReadOnly
                    isDisabled={isDisabled}
                    variant="outline"
                    value={months[getMonth(date)] + ' ' + getYear(date)}
                />

                <InputRightElement>
                    <Icon as={FiCalendar} />
                </InputRightElement>
            </InputGroup>
        </PopoverTrigger>
    )
}
