import { Locale } from 'date-fns'
import { enUS, it } from 'date-fns/locale'

export function getLocale(code: string): Locale {
    switch (code) {
        case 'en':
            return enUS
        case 'it':
            return it
        default:
            throw new Error(`Invalid locale code ${code}`)
    }
}
