import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import {
    Box,
    Button,
    ButtonGroup,
    IconButton,
    Image,
    LightMode,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react'
import { LocationDependencies } from 'features/location/dependencies'
import { Location } from 'features/location/models/Location'
import { UseMediaUploadParams } from 'features/media/hooks/useMediaUpload'
import { useInjection } from 'inversify-react'
import { PropsWithChildren, useMemo } from 'react'
import { DropzoneState } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import {
    FaCheckCircle,
    FaCloudUploadAlt,
    FaExclamationCircle,
} from 'react-icons/fa'
import { FiCheck, FiX } from 'react-icons/fi'
import { ImageUploader } from '../../../media/components/ImageUploader'
import { MediaUploadError } from '../../../media/errors/MediaUploadError'
import { MediaUploadTaskStatus } from '../../../media/models/MediaUploadTaskStatus'
import { RawMedia } from '../../../media/models/RawMedia'

interface GalleryImageUploaderProps extends Location.ID {
    onUploadingChanged: (status: boolean) => void
    onComplete: (rawMedia: RawMedia) => void
}

export function GalleryImageUploader({
    onUploadingChanged,
    onComplete,
    ...id
}: GalleryImageUploaderProps) {
    const config: UseMediaUploadParams = useMemo(
        () => ({
            automaticReset: false,
            onUploadingStatusChanged: onUploadingChanged,
            onUploadEnded: onComplete,
        }),
        [onComplete]
    )

    return (
        <ImageUploader
            config={config}
            renderer={(props) => (
                <GalleryImageUploaderRenderer id={id} {...props} />
            )}
        />
    )
}

interface GalleryImageUploaderRendererProps {
    id: Location.ID
    status: MediaUploadTaskStatus
    dropzone: DropzoneState
}

function GalleryImageUploaderRenderer({
    id,
    status,
    dropzone,
}: GalleryImageUploaderRendererProps) {
    const { t } = useTranslation()

    const upload = useInjection(LocationDependencies.UploadMedia)

    const { getRootProps, getInputProps, isDragActive, fileRejections } =
        dropzone

    if (status.type === 'idle') {
        return (
            <GalleryImageUploaderContainer isError={fileRejections.length > 0}>
                <VStack {...getRootProps()} cursor="pointer">
                    <FaCloudUploadAlt size={64} />
                    <input {...getInputProps()} />
                    <VStack spacing={0}>
                        <Text textAlign="center">
                            {isDragActive ? t('Drop') : t('Add a new image')}
                        </Text>

                        <Text textAlign="center" fontSize="xs" color="label2">
                            {t('Click or drag a new image here')}
                        </Text>
                    </VStack>
                </VStack>
            </GalleryImageUploaderContainer>
        )
    }

    if (status.type === 'awaiting-confirmation') {
        return (
            <Box position="relative" w="full" h="full" maxH="sm">
                <Image
                    w="full"
                    h="full"
                    objectFit="cover"
                    src={status.url}
                    userSelect="none"
                    borderRadius="xl"
                />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    w="full"
                    h="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <LightMode>
                        <ButtonGroup>
                            <IconButton
                                colorScheme="blackAlpha"
                                aria-label="Cancel"
                                icon={<FiX />}
                                onClick={status.onReset}
                                size="lg"
                            />
                            <IconButton
                                colorScheme="blackAlpha"
                                aria-label="Upload"
                                size="lg"
                                icon={<FiCheck />}
                                onClick={() =>
                                    status.onUpload(
                                        async (media: File) =>
                                            await upload.run({ ...id, media })
                                    )
                                }
                            />
                        </ButtonGroup>
                    </LightMode>
                </Box>
            </Box>
        )
    }

    if (status.type === 'uploading') {
        return (
            <Box position="relative" w="full" h="full" maxH="sm">
                <Image
                    w="full"
                    h="full"
                    objectFit="cover"
                    src={status.url}
                    userSelect="none"
                    borderRadius="xl"
                />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    w="full"
                    h="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="blackAlpha.600"
                    borderRadius="xl"
                >
                    <Spinner size="xl" />
                </Box>
            </Box>
        )
    }

    if (status.type === 'success') {
        return (
            <GalleryImageUploaderContainer>
                <VStack w="full">
                    <FaCheckCircle size={64} />
                    <Text textAlign="center">
                        {t('Image uploaded successfully')}
                    </Text>
                    <Button onClick={status.onReset} variant="ghost" w="full">
                        {t('Upload another image')}
                    </Button>
                </VStack>
            </GalleryImageUploaderContainer>
        )
    }

    if (status.type === 'failure') {
        let title: string
        let subtitle: string

        if (
            status.error instanceof AvokadoAPIError &&
            status.error.category === 'location-media.create.limit-exceeded'
        ) {
            title = 'Too many images for this location'
            subtitle = 'Please remove one of the other images and try again.'
        } else if (
            status.error instanceof MediaUploadError.ProcessingError &&
            status.error.moderationLabel
        ) {
            title = status.error.moderationLabel
            subtitle =
                "This type of content is not allowed. If you think this is was mistake don't hesitate to contact us."
        } else {
            title = 'Something went wrong'
            subtitle = 'Please try again later.'
        }

        return (
            <GalleryImageUploaderContainer isError>
                <VStack w="full">
                    <FaExclamationCircle size={64} />
                    <VStack spacing={0}>
                        <Text textAlign="center">{title}</Text>
                        <Text fontSize="xs" color="label2" textAlign="center">
                            {subtitle}
                        </Text>
                    </VStack>
                    <Button onClick={status.onReset} variant="ghost" w="full">
                        Reset
                    </Button>
                </VStack>
            </GalleryImageUploaderContainer>
        )
    }

    return null
}

interface GalleryImageUploaderContainerProps extends PropsWithChildren {
    isError?: boolean
}

function GalleryImageUploaderContainer({
    isError,
    children,
}: GalleryImageUploaderContainerProps) {
    return (
        <Box
            w="full"
            h="full"
            maxH="sm"
            p={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="pageBackground3"
            borderWidth={1}
            borderRadius="xl"
            borderColor={isError ? 'accent' : 'transparent'}
        >
            {children}
        </Box>
    )
}
