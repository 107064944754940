import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import axios from 'axios'
import {
    ConfigurationDependencies,
    ConfigurationStore,
} from 'features/config/dependencies'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { AuthDependencies } from '.'
import { Cognito } from '../models/CognitoModels'
import { AuthStore } from './AuthStore'

interface ChangePasswordParams {
    oldPassword: string
    newPassword: string
}

export interface ChangePasswordAction {
    run(params: ChangePasswordParams): Promise<void>
}

@injectable()
export class ChangePasswordActionLive implements ChangePasswordAction {
    @inject(ConfigurationDependencies.Store) private config!: ConfigurationStore
    @inject(AuthDependencies.Store) private auth!: AuthStore

    async run(params: ChangePasswordParams): Promise<void> {
        const configuration = await this.config.configuration()

        const request: Cognito.Request.ChangePassword = {
            PreviousPassword: params.oldPassword,
            ProposedPassword: params.newPassword,
            AccessToken: await this.auth.assertAccessToken(),
        }

        await axios.post(
            `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
            request,
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target':
                        'AWSCognitoIdentityProviderService.ChangePassword',
                },
            }
        )
    }
}

@injectable()
export class ChangePasswordActionFake implements ChangePasswordAction {
    async run(params: ChangePasswordParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ChangePasswordActionFail implements ChangePasswordAction {
    async run(params: ChangePasswordParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
