import {
    Accordion,
    Alert,
    AlertDescription,
    AlertIcon,
    HStack,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    VStack,
    useDisclosure,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { useMemo } from 'react'
import SortableList from 'react-easy-sort'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import {
    useMenuContentFormController,
    useMenuContentFormData,
} from '../hooks/useMenuContentForm'
import { MenuPageContentProps } from '../pages/MenuPage'
import { Entry, NewEntryInput } from './Entry'
import { NewSectionInput, Section } from './Section'
import { MenuToolbar } from './toolbar/MenuToolbar'

export function MenuContent(props: MenuPageContentProps) {
    const screen = useScreenBreakpoint()

    if (screen === 'mobile') {
        return (
            <Container>
                <MenuContentMobile {...props} />
            </Container>
        )
    } else {
        return (
            <Container>
                <MenuContentDesktop {...props} />
            </Container>
        )
    }
}

function MenuContentDesktop({ menu, update }: MenuPageContentProps) {
    const formData = useMenuContentFormData(menu)
    const controller = useMenuContentFormController(menu, formData, update)

    const section = useMemo(() => {
        return formData.sections[formData.selectedSectionIndex]
    }, [formData.sections, formData.selectedSectionIndex])

    return (
        <VStack w="full" align="stretch" spacing={8}>
            <MenuToolbar
                currentSection={section}
                formData={formData}
                controller={controller}
            />

            <HStack align="stretch">
                <VStack flex={3} align="stretch" spacing={0} borderEndWidth={1}>
                    <SortableList
                        allowDrag={formData.isRearranging}
                        onSortEnd={formData.rearrangeSections}
                        lockAxis="y"
                    >
                        {formData.sections.map((section, index) => (
                            <Section
                                key={section.id.sectionId}
                                menu={menu}
                                section={section}
                                isSelected={
                                    index === formData.selectedSectionIndex
                                }
                                isDraggable={formData.isRearranging}
                                onSelect={() => formData.selectSection(index)}
                                update={update}
                                borderBottomWidth={
                                    index !== formData.sections.length - 1 ||
                                    formData.isAddingSections
                                        ? 1
                                        : 0
                                }
                            />
                        ))}

                        <NewSectionInput
                            key={formData.sections.length}
                            menu={menu}
                            update={update}
                        />
                    </SortableList>
                </VStack>

                <VStack flex={9} align="stretch" spacing={0}>
                    {section.entries.length > 0 && (
                        <Accordion
                            allowToggle
                            key={section.id.sectionId}
                            index={formData.isRearranging ? -1 : undefined}
                        >
                            <SortableList
                                allowDrag={formData.isRearranging}
                                onSortEnd={formData.rearrangeEntries}
                                lockAxis="y"
                            >
                                {section.entries.map((entry, index) => (
                                    <Entry
                                        key={entry.id.entryId}
                                        menu={menu}
                                        section={section}
                                        entry={entry}
                                        mt={index === 0 ? 0 : 2}
                                        isGloballyUpdating={controller.updatingEntriesIds.has(
                                            entry.id
                                        )}
                                        isDraggable={formData.isRearranging}
                                        isSelected={formData.selectedEntriesIndexes.has(
                                            index
                                        )}
                                        onSelect={() =>
                                            formData.toggleEntrySelection(index)
                                        }
                                        update={update}
                                    />
                                ))}
                            </SortableList>
                        </Accordion>
                    )}

                    <NewEntryInput
                        menu={menu}
                        section={section}
                        update={update}
                    />
                </VStack>
            </HStack>
        </VStack>
    )
}

function MenuContentMobile({ menu, update }: MenuPageContentProps) {
    const t = useT('menu')
    const formData = useMenuContentFormData(menu)
    const controller = useMenuContentFormController(menu, formData, update)
    const sectionModal = useDisclosure()

    const section = useMemo(() => {
        return formData.sections[formData.selectedSectionIndex]
    }, [formData.sections, formData.selectedSectionIndex])

    const selectedEntries = useMemo(
        () =>
            Array.from(formData.selectedEntriesIndexes).map(
                (index) => section.entries[index]
            ),
        [section, formData.selectedEntriesIndexes]
    )

    return (
        <>
            <VStack w="full" align="stretch" spacing={8}>
                <MenuToolbar
                    currentSection={section}
                    formData={formData}
                    controller={controller}
                    hideAllergens
                    hideFeatures
                />

                <VStack flex={4} align="stretch" spacing={0}>
                    <SortableList
                        allowDrag={formData.isRearranging}
                        onSortEnd={formData.rearrangeSections}
                        lockAxis="y"
                    >
                        {formData.sections.map((section, index) => (
                            <Section
                                key={section.id.sectionId}
                                menu={menu}
                                section={section}
                                isSelected={
                                    index === formData.selectedSectionIndex
                                }
                                isDraggable={formData.isRearranging}
                                onSelect={() => {
                                    formData.selectSection(index)
                                    sectionModal.onOpen()
                                }}
                                update={update}
                                borderBottomWidth={
                                    index !== formData.sections.length - 1 ||
                                    formData.isAddingSections
                                        ? 1
                                        : 0
                                }
                            />
                        ))}

                        <NewSectionInput
                            key={formData.sections.length}
                            menu={menu}
                            update={update}
                        />
                    </SortableList>
                </VStack>
            </VStack>

            <Modal
                size="full"
                isOpen={sectionModal.isOpen}
                onClose={sectionModal.onClose}
                scrollBehavior="inside"
            >
                <ModalContent m={0} p={0}>
                    <ModalHeader>
                        <VStack align="start">
                            <Heading size="md">{section.name}</Heading>
                            <MenuToolbar
                                currentSection={section}
                                formData={formData}
                                controller={controller}
                                hideAllergens
                                hideFeatures
                            />
                        </VStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mx={1} px={0}>
                        <VStack flex={8} align="stretch" spacing={0}>
                            <Alert
                                variant="left-accent"
                                w="full"
                                borderRadius="lg"
                                my={4}
                            >
                                <AlertIcon />
                                <AlertDescription w="full">
                                    {t(
                                        'menu_mobile_alert_select_to_bulk_edit_suggestion'
                                    )}
                                </AlertDescription>
                            </Alert>
                            {section.entries.length > 0 && (
                                <Accordion
                                    allowToggle
                                    key={section.id.sectionId}
                                    index={
                                        formData.isRearranging ? -1 : undefined
                                    }
                                >
                                    <SortableList
                                        allowDrag={formData.isRearranging}
                                        onSortEnd={formData.rearrangeEntries}
                                        lockAxis="y"
                                    >
                                        {section.entries.map((entry, index) => (
                                            <Entry
                                                key={entry.id.entryId}
                                                menu={menu}
                                                section={section}
                                                entry={entry}
                                                mt={index === 0 ? 0 : 2}
                                                isGloballyUpdating={controller.updatingEntriesIds.has(
                                                    entry.id
                                                )}
                                                isDraggable={
                                                    formData.isRearranging
                                                }
                                                isSelected={formData.selectedEntriesIndexes.has(
                                                    index
                                                )}
                                                onSelect={() =>
                                                    formData.toggleEntrySelection(
                                                        index
                                                    )
                                                }
                                                update={update}
                                            />
                                        ))}
                                    </SortableList>
                                </Accordion>
                            )}

                            <NewEntryInput
                                menu={menu}
                                section={section}
                                update={update}
                            />
                        </VStack>
                    </ModalBody>
                    {selectedEntries.length > 0 && (
                        <ModalFooter>
                            <MenuToolbar
                                currentSection={section}
                                formData={formData}
                                controller={controller}
                                hideSort
                            />
                        </ModalFooter>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}
