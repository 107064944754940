import { createContext, useContext } from 'react'
import { Location } from '../models/Location'
import { LocationCompact } from '../models/LocationCompact'

interface _LocationsContext {
    locations: LocationCompact[]
    isLoading: boolean
    load: (id: string) => Promise<void>
    retrieve: (id: Location.ID) => Location | undefined
    update: (location: Location) => void
    clear: () => void
}

const defaultLocationsContext: _LocationsContext = {
    locations: [],
    isLoading: false,
    async load(id) {},
    retrieve(id) {
        return undefined
    },
    update(location) {},
    clear() {},
}

export const LocationsContext = createContext(defaultLocationsContext)
export default function useLocationsContext() {
    return useContext(LocationsContext)
}
