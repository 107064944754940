import { KonvaEventObject } from 'konva/lib/Node'
import { Layer, Rect } from 'react-konva'
import { Geometry } from 'utils/geometry/Geometry'

interface RoomLayerProps {
    size: Geometry.Size
    backgroundColor: string
    onClick: (event: KonvaEventObject<MouseEvent>) => void
}

export function RoomLayer({ size, backgroundColor, onClick }: RoomLayerProps) {
    return (
        <Layer>
            <Rect
                x={0}
                y={0}
                width={size.width}
                height={size.height}
                fill={backgroundColor}
                cornerRadius={24}
                onClick={onClick}
                onTap={onClick}
            />
        </Layer>
    )
}
