import Avokado, {
    AvokadoAPIError,
    MenuSectionUpdate,
} from '@avokadoapp/avokado-ts'
import { mapModelToEntriesIds } from 'features/menu/mappers/SDKRequestMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import _ from 'lodash'
import { sleep } from 'utils/asyncawait/sleep'

interface UpdateMenuSectionParams {
    menu: Menu
    section: Menu.Section
    name?: string
    description?: string
    entries?: Menu.Section.Entry[]
}

export interface UpdateMenuSectionAction {
    run(params: UpdateMenuSectionParams): Promise<Menu>
}

@injectable()
export class UpdateMenuSectionActionLive implements UpdateMenuSectionAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UpdateMenuSectionParams): Promise<Menu> {
        const { menu, section } = params
        const request: MenuSectionUpdate.Request = {
            organizationId: section.id.organizationId,
            menuId: section.id.menuId,
            menuSectionId: section.id.sectionId,
            name: _.isEqual(params.name, section.name)
                ? undefined
                : params.name,
            description: _.isEqual(params.description, section.description)
                ? undefined
                : params.description,
            entriesIds: _.isEqual(params.entries, section.entries)
                ? undefined
                : mapModelToEntriesIds(params.entries),
        }
        const response = await this.avokado.menuSection.update(request)
        return {
            ...menu,
            sections: menu.sections.map((other) => {
                if (_.isEqual(other.id, section.id)) {
                    return {
                        ...section,
                        name: response.name,
                        description: response.description,
                        entries: response.entriesIds.map(
                            (id) =>
                                params.entries?.find(
                                    (entry) => entry.id.entryId === id
                                )!
                        ),
                    }
                } else {
                    return other
                }
            }),
        }
    }
}

@injectable()
export class UpdateMenuSectionActionFake implements UpdateMenuSectionAction {
    async run(params: UpdateMenuSectionParams): Promise<Menu> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateMenuSectionActionFail implements UpdateMenuSectionAction {
    async run(params: UpdateMenuSectionParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
