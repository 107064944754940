import {
    LocationOrganizationRetrieve,
    LocationRetrieve,
} from '@avokadoapp/avokado-ts'
import { Address } from '../models/Address'
import { mapISOToCountry } from './InternalMapper'

export function mapAddressToModel(
    payload: LocationRetrieve.Response.Address | undefined
): Address | undefined {
    if (!payload) {
        return undefined
    }

    return {
        street: payload.street,
        addressNumber: payload.addressNumber,
        municipality: payload.municipality,
        postalCode: payload.postalCode,
        region: payload.region,
        subregion: payload.subregion,
        country: mapISOToCountry(payload.country),
    }
}

export function mapRequiredAddressToModel(
    payload: LocationOrganizationRetrieve.Response.Address | undefined
): Address | undefined {
    if (!payload) {
        return undefined
    }

    return {
        street: payload.street ?? '',
        addressNumber: payload.addressNumber ?? '',
        municipality: payload.municipality ?? '',
        postalCode: payload.postalCode ?? '',
        region: payload.region ?? '',
        subregion: payload.subregion ?? '',
        country: mapISOToCountry(payload.country),
    }
}
