import Avokado, {
    AvokadoAPIError,
    MenuMediaLinksCreate,
} from '@avokadoapp/avokado-ts'
import { mapExtensionToLocationFormat } from 'features/media/mappers/SDKRequestMapper'
import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface UploadMediaMenuParams extends Menu.ID {
    media: File
}

export interface UploadMediaMenuAction {
    run(params: UploadMediaMenuParams): Promise<MediaUploadLink>
}

@injectable()
export class UploadMediaMenuActionLive implements UploadMediaMenuAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: UploadMediaMenuParams): Promise<MediaUploadLink> {
        const request: MenuMediaLinksCreate.Request = {
            organizationId: params.organizationId,
            menuId: params.menuId,
            format: mapExtensionToLocationFormat(params.media.type),
        }
        return await this.avokado.menu.mediaLinksCreate(request)
    }
}

@injectable()
export class UploadMediaMenuActionFake implements UploadMediaMenuAction {
    async run(params: UploadMediaMenuParams): Promise<MediaUploadLink> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UploadMediaMenuActionFail implements UploadMediaMenuAction {
    async run(params: UploadMediaMenuParams): Promise<MediaUploadLink> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
