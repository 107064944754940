import { QuestionOutlineIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Grid,
    HStack,
    Spinner,
    Text,
    Tooltip,
} from '@chakra-ui/react'
import useOrganizationContext from 'features/organization/contexts/OrganizationContext'
import useT from 'localization/hooks/useT'
import { Link } from 'react-router-dom'
import { Container } from 'uikit/container/Container'
import { CreateNewGridCard } from 'uikit/grid/CreateNewGridCard'
import { GridCard } from 'uikit/grid/GridCard'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import useMenusContext from '../contexts/MenusContext'

export default function MenusPage() {
    const t = useT('menu')

    const screen = useScreenBreakpoint()

    const organizationManager = useOrganizationContext()
    const menusManager = useMenusContext()

    const quota = organizationManager.organization?.quota

    return (
        <Container
            isCompact
            mainContainer
            title={t('listing_title')}
            pt={8}
            header={
                quota &&
                menusManager.menus.length >= 3 && (
                    <HStack>
                        <Text>
                            {menusManager.menus.length} / {quota.menus}
                        </Text>

                        {menusManager.menus.length === quota.menus && (
                            <Tooltip
                                hasArrow
                                label={t('listing_quota_exceeded_message')}
                            >
                                <Box
                                    as={Link}
                                    to="mailto:support@avokadoapp.ch"
                                >
                                    <QuestionOutlineIcon />
                                </Box>
                            </Tooltip>
                        )}
                    </HStack>
                )
            }
        >
            {menusManager.isLoading ? (
                <Spinner />
            ) : menusManager.menus.length === 0 ? (
                <>
                    <Text whiteSpace="pre-line">
                        {t('listing_no_menus_message')}
                    </Text>
                    <Button as={Link} to="new" alignSelf="end">
                        {t('get_started_button')}
                    </Button>
                </>
            ) : (
                <Grid
                    templateColumns={{
                        base: '1fr',
                        md: `repeat(${screen === 'tablet' ? 2 : 3}, 1fr)`,
                    }}
                    gap={6}
                    w="full"
                >
                    {menusManager.menus.map((menu) => (
                        <GridCard
                            key={menu.id.menuId}
                            link={`${menu.id.menuId}`}
                            name={menu.name}
                            cover={menu.media?.large()}
                        />
                    ))}

                    {quota && menusManager.menus.length < quota.menus && (
                        <CreateNewGridCard
                            link="new"
                            label={t('listing_new_card_button')}
                        />
                    )}
                </Grid>
            )}
        </Container>
    )
}
