import {
    FormControl,
    FormLabelProps,
    HStack,
    InputGroup,
    InputLeftAddon,
    InputProps,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Spacer,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { InputLabels } from './InputLabels'

interface BasePrecisionInputProps extends Pick<InputProps, 'variant' | 'bg'> {
    title?: string
    description?: string
    direction?: 'horizontal' | 'vertical'
    leftAddon?: string
    labelProps?: FormLabelProps
    moreInfo?: string
    w: 'xs' | 'full' // Applied only to Desktop
}

interface PrecisionInputProps {
    value: number | undefined
    isRequired?: false
    setValue: (value: number | undefined) => void
}

interface RequiredPrecisionInputProps {
    value: number
    isRequired: true
    setValue: (value: number) => void
}

export function PrecisionInput({
    value,
    title,
    description,
    direction,
    leftAddon,
    setValue,
    labelProps,
    moreInfo,
    isRequired = false,
    w,
    ...props
}: (PrecisionInputProps | RequiredPrecisionInputProps) &
    BasePrecisionInputProps) {
    const ref = useRef<HTMLInputElement>(null)
    const screen = useScreenBreakpoint()

    const [rawValue, setRawValue] = useState(
        value === undefined
            ? isRequired
                ? '0.00'
                : ''
            : (value / 100).toFixed(2)
    )

    useEffect(() => {
        if (value === 0) {
            setRawValue('0.00')
        }
    }, [value])

    const handleChange = useCallback((rawValue: string, value?: number) => {
        let dotSafeRawValue: string
        const dotIndex = rawValue.indexOf('.')
        if (dotIndex === -1) {
            dotSafeRawValue = rawValue
        } else {
            const firstPart = rawValue.substring(0, dotIndex + 1)
            const remainingPart = rawValue
                .substring(dotIndex + 1)
                .replace(/\./g, '')
            dotSafeRawValue = firstPart + remainingPart
        }

        setRawValue(dotSafeRawValue)
        if (value !== undefined) {
            if (isNaN(value)) {
                // @ts-ignore
                setValue(isRequired ? 0 : undefined)
            } else {
                setValue(value < 0 ? 0 : Math.round(value * 100))
            }
        }
    }, [])

    if (direction !== 'vertical' && screen === 'desktop') {
        return (
            <FormControl>
                <HStack>
                    <InputLabels
                        title={title}
                        description={description}
                        moreInfo={moreInfo}
                        {...labelProps}
                    />
                    <Spacer />

                    <InputGroup
                        onClick={(event) => event.stopPropagation()}
                        w={w}
                        minW="xs"
                    >
                        {leftAddon && <InputLeftAddon children={leftAddon} />}
                        <NumberInput
                            w="full"
                            variant={props.variant}
                            min={0}
                            precision={2}
                            value={rawValue}
                            onKeyDown={(event) => {
                                if (event.key === ',') {
                                    handleChange(rawValue + '.')
                                }
                            }}
                            onChange={handleChange}
                        >
                            <NumberInputField
                                bg={props.bg}
                                ref={ref}
                                onFocus={() => ref.current?.select()}
                                placeholder={title}
                                borderLeftRadius="none"
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </InputGroup>
                </HStack>
            </FormControl>
        )
    }

    return (
        <FormControl>
            <InputLabels
                title={title}
                description={description}
                moreInfo={moreInfo}
                {...labelProps}
            />

            <InputGroup onClick={(event) => event.stopPropagation()}>
                {leftAddon && <InputLeftAddon children={leftAddon} />}
                <NumberInput
                    w="full"
                    variant={props.variant}
                    min={0}
                    precision={2}
                    value={rawValue}
                    onKeyDown={(event) => {
                        if (event.key === ',') {
                            handleChange(rawValue + '.')
                        }
                    }}
                    onChange={handleChange}
                >
                    <NumberInputField
                        bg={props.bg}
                        ref={ref}
                        onFocus={() => ref.current?.select()}
                        placeholder={title}
                        borderLeftRadius="none"
                    />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </InputGroup>
        </FormControl>
    )
}
