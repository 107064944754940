import Avokado, { AvokadoAPIError, MenuCreate } from '@avokadoapp/avokado-ts'
import { mapMenuWithoutSectionsToModel } from 'features/menu/mappers/SDKResponseMapper'
import { Menu } from 'features/menu/models/Menu'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface CreateMenuParams extends Organization.ID {
    name: string
    description: string
}

export interface CreateMenuAction {
    run(params: CreateMenuParams): Promise<Menu>
}

export class TooManyMenuError extends Error {
    constructor() {
        super('Too Many Menus')
    }
}

@injectable()
export class CreateMenuActionLive implements CreateMenuAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateMenuParams): Promise<Menu> {
        const request: MenuCreate.Request = params
        try {
            const response = await this.avokado.menu.create(request)
            return mapMenuWithoutSectionsToModel(response)
        } catch (e: unknown) {
            if (
                e instanceof AvokadoAPIError &&
                e.category === 'menu.create.quota-limit-reached'
            ) {
                throw new TooManyMenuError()
            }
            throw e
        }
    }
}

@injectable()
export class CreateMenuActionFake implements CreateMenuAction {
    async run(params: CreateMenuParams): Promise<Menu> {
        await sleep(500)

        return {
            id: { organizationId: params.organizationId, menuId: 1 },
            name: params.name,
            description: params.description,
            footer: undefined,
            media: undefined,
            startingPrice: undefined,
            sections: [],
            extras: new Map(),
            appliedTranslation: 'en',
            availableTranslations: [
                {
                    isPublished: true,
                    locale: 'en',
                    supportsAutomatic: false,
                },
            ],
        }
    }
}

@injectable()
export class CreateMenuActionFail implements CreateMenuAction {
    async run(params: CreateMenuParams): Promise<Menu> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
