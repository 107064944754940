import Avokado, {
    AvokadoAPIError,
    MenuEntryBatchUpdate,
} from '@avokadoapp/avokado-ts'
import {
    updateAllergensBatch,
    updateFeaturesBatch,
} from 'features/menu/mappers/SDKRequestMapper'
import { Menu } from 'features/menu/models/Menu'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import _ from 'lodash'
import { sleep } from 'utils/asyncawait/sleep'

interface BatchUpdateMenuEntryParams {
    menu: Menu
    section: Menu.Section
    entries: Menu.Section.Entry[]
    allergens?: Map<Menu.Section.Entry.Allergen, boolean>
    features?: Map<Menu.Section.Entry.Feature, boolean>
}

export interface BatchUpdateMenuEntryAction {
    run(
        params: BatchUpdateMenuEntryParams
    ): Promise<[Menu, Menu.Section.Entry.ID[]]>
}

@injectable()
export class BatchUpdateMenuEntryActionLive
    implements BatchUpdateMenuEntryAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(
        params: BatchUpdateMenuEntryParams
    ): Promise<[Menu, Menu.Section.Entry.ID[]]> {
        const { menu, section, entries, allergens, features } = params
        let modifiedEntries = entries

        if (allergens) {
            modifiedEntries = updateAllergensBatch(modifiedEntries, allergens)
        }

        if (features) {
            modifiedEntries = updateFeaturesBatch(modifiedEntries, features)
        }

        const request: MenuEntryBatchUpdate.Request = {
            organizationId: section.id.organizationId,
            menuId: section.id.menuId,
            menuSectionId: section.id.sectionId,
            entries: modifiedEntries.map((entry) => ({
                entryId: entry.id.entryId,
                allergens: _.sum(entry.allergens),
                features: _.sum(entry.features),
            })),
        }

        const response = await this.avokado.menuEntry.batchUpdate(request)
        const newMenu = {
            ...menu,
            sections: menu.sections.map((otherSection) => {
                if (_.isEqual(otherSection.id, section.id)) {
                    return {
                        ...section,
                        entries: section.entries.map((otherEntry) => {
                            const modifiedEntry = modifiedEntries.find(
                                (entry) => _.isEqual(otherEntry.id, entry.id)
                            )
                            return modifiedEntry ?? otherEntry
                        }),
                    }
                } else {
                    return otherSection
                }
            }),
        }

        return [
            newMenu,
            response.accepted.map((entryId) => ({ ...section.id, entryId })),
        ]
    }
}

@injectable()
export class BatchUpdateMenuEntryActionFake
    implements BatchUpdateMenuEntryAction
{
    async run(
        params: BatchUpdateMenuEntryParams
    ): Promise<[Menu, Menu.Section.Entry.ID[]]> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class BatchUpdateMenuEntryActionFail
    implements BatchUpdateMenuEntryAction
{
    async run(
        params: BatchUpdateMenuEntryParams
    ): Promise<[Menu, Menu.Section.Entry.ID[]]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
