import { useMemo } from 'react'

export function useFullName(
    givenName: string | undefined,
    familyName: string | undefined
) {
    const fullName = useMemo(() => {
        if (!givenName) return familyName
        if (!familyName) return givenName
        return givenName + ' ' + familyName
    }, [givenName, familyName])

    return fullName
}
