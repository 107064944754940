import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import axios from 'axios'
import {
    ConfigurationDependencies,
    ConfigurationStore,
} from 'features/config/dependencies'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { AuthDependencies, AuthStore } from '.'
import { Cognito, UserAttribute } from '../models/CognitoModels'

interface UpdateUserAttributesParams {
    givenName?: string
    familyName?: string
    locale?: string
    zoneInfo?: string
}

export interface UpdateUserAttributesAction {
    run(params: UpdateUserAttributesParams): Promise<void>
}

@injectable()
export class UpdateUserAttributesActionLive
    implements UpdateUserAttributesAction
{
    @inject(ConfigurationDependencies.Store) private config!: ConfigurationStore
    @inject(AuthDependencies.Store) private auth!: AuthStore

    async run(params: UpdateUserAttributesParams): Promise<void> {
        const configuration = await this.config.configuration()

        const userAttributes: UserAttribute[] = []

        if (params.givenName)
            userAttributes.push(
                Cognito.UserAttribute.givenName(params.givenName)
            )
        if (params.familyName)
            userAttributes.push(
                Cognito.UserAttribute.familyName(params.familyName)
            )
        if (params.locale)
            userAttributes.push(Cognito.UserAttribute.locale(params.locale))
        if (params.zoneInfo)
            userAttributes.push(Cognito.UserAttribute.zoneInfo(params.zoneInfo))

        const request: Cognito.Request.UpdateUserAttributes = {
            AccessToken: await this.auth.assertAccessToken(),
            UserAttributes: userAttributes,
        }

        await axios.post(
            `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
            request,
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target':
                        'AWSCognitoIdentityProviderService.UpdateUserAttributes',
                },
            }
        )
    }
}

@injectable()
export class UpdateUserAttributesActionFake
    implements UpdateUserAttributesAction
{
    async run(params: UpdateUserAttributesParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdateUserAttributesActionFail
    implements UpdateUserAttributesAction
{
    async run(params: UpdateUserAttributesParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
