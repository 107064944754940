import { Grid, Image, Stack, VStack } from '@chakra-ui/react'
import { LocationTabProps } from 'features/location/pages/LocationPage'
import { AnimatePresence } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { useEffect, useState } from 'react'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { LoadingIndicator } from 'utils/loading'
import { useCardReaders } from '../hooks/useCardReaders'
import { CardReaderCard } from './CardReaderCard'
import { CardReaderConnect } from './CardReaderConnect'
import { ContactUsForm } from './CardReaderContactUs'
import { PromotionalComponent } from './CardReaderPromotional'

export function CardReaderTab({ location }: LocationTabProps) {
    const t = useT('cardreader')

    const { cardReaders, isUpdatingReaders, isLoadingSdk, updateReaders } =
        useCardReaders(location.id, location.stripe.id)

    const [presentedView, setPresentedView] = useState<
        'promotional' | 'connect-new' | 'contact-us'
    >('promotional')
    const screen = useScreenBreakpoint()

    useEffect(() => {
        if (cardReaders.length > 0) {
            setPresentedView('connect-new')
        }
    }, [cardReaders])

    if (isLoadingSdk || isUpdatingReaders) {
        return <LoadingIndicator />
    }

    return (
        <>
            <VStack spacing={8}>
                <Container
                    isVisible={cardReaders.length > 0}
                    title={t('your_card_readers_title')}
                >
                    <Grid
                        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
                        gridAutoRows="1fr"
                        w="full"
                        gap={6}
                    >
                        {cardReaders.map((reader) => (
                            <CardReaderCard
                                key={reader.id.cardReaderId}
                                cardReader={reader}
                            />
                        ))}
                    </Grid>
                </Container>
                <Container>
                    <Stack direction={screen === 'desktop' ? 'row' : 'column'}>
                        <Image
                            src="/stripe-reader-bbpos-wisepos-e.png"
                            boxSize={screen === 'desktop' ? 'xl' : 'full'}
                            objectFit="scale-down"
                        />

                        <AnimatePresence>
                            {presentedView === 'promotional' && (
                                <PromotionalComponent
                                    locationName={location.name}
                                    onSetupClick={() =>
                                        setPresentedView('connect-new')
                                    }
                                    onContactUsClick={() =>
                                        setPresentedView('contact-us')
                                    }
                                />
                            )}
                            {presentedView === 'connect-new' && (
                                <CardReaderConnect
                                    location={location}
                                    readers={cardReaders}
                                    dismiss={() =>
                                        setPresentedView('promotional')
                                    }
                                    onCardReaderConnected={updateReaders}
                                />
                            )}
                            {presentedView === 'contact-us' && (
                                <ContactUsForm
                                    onCancelClick={() =>
                                        setPresentedView('promotional')
                                    }
                                />
                            )}
                        </AnimatePresence>
                    </Stack>
                </Container>
            </VStack>
        </>
    )
}
