import { AvokadoAPIError } from '@avokadoapp/avokado-ts'
import axios from 'axios'
import {
    ConfigurationDependencies,
    ConfigurationStore,
} from 'features/config/dependencies'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { AuthDependencies } from '.'
import { Cognito } from '../models/CognitoModels'
import { AuthStore } from './AuthStore'

interface SignInParams {
    email: string
    password: string
}

export enum SignInResult {
    Success = 'success',
    RequiresAccountConfirmation = 'requiresAccountConfirmation',
}

export interface SignInAction {
    run(params: SignInParams): Promise<SignInResult>
}

@injectable()
export class SignInActionLive implements SignInAction {
    @inject(ConfigurationDependencies.Store) private config!: ConfigurationStore
    @inject(AuthDependencies.Store) private auth!: AuthStore

    async run(params: SignInParams): Promise<SignInResult> {
        const configuration = await this.config.configuration()

        const request: Cognito.Request.PasswordAuth = {
            AuthFlow: 'USER_PASSWORD_AUTH',
            AuthParameters: {
                USERNAME: params.email,
                PASSWORD: params.password,
            },
            ClientId: configuration.aws.userPool.dashboardClientId,
        }

        try {
            const response = await axios.post<Cognito.Response.PasswordAuth>(
                `https://cognito-idp.${configuration.aws.region}.amazonaws.com`,
                request,
                {
                    headers: {
                        'Content-Type': 'application/x-amz-json-1.1',
                        'X-Amz-Target':
                            'AWSCognitoIdentityProviderService.InitiateAuth',
                    },
                }
            )

            this.auth.setAccessToken(
                response.data.AuthenticationResult.AccessToken
            )
            this.auth.setIdToken(response.data.AuthenticationResult.IdToken)
            this.auth.setRefreshToken(
                response.data.AuthenticationResult.RefreshToken
            )

            return SignInResult.Success
        } catch (error: any) {
            console.log(error)
            if (
                error.response.data &&
                error.response.data.__type === 'UserNotConfirmedException'
            ) {
                return SignInResult.RequiresAccountConfirmation
            } else {
                throw error
            }
        }
    }
}

@injectable()
export class SignInActionFake implements SignInAction {
    @inject(AuthDependencies.Store) private auth!: AuthStore

    async run(params: SignInParams): Promise<SignInResult> {
        await sleep(500)

        this.auth.setIdToken(
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjIwMDAwMDAwMDAsImdpdmVuX25hbWUiOiJKb2huIiwiZmFtaWx5X25hbWUiOiJEb2UiLCJjdXN0b206b3JnYW5pemF0aW9ucyI6IjAwMDAwMDAwIn0.ocbrXWuKuuiMsF-i2IYZHI8q8KW-JuaXxCHyPQYnj7Q'
        )

        return SignInResult.Success
    }
}

@injectable()
export class SignInActionFail implements SignInAction {
    async run(params: SignInParams): Promise<SignInResult> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
