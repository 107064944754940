import { AuthDependencies } from 'features/auth/dependencies'
import { DateDependencies } from 'infra/di/factories/Date'
import { useInjection } from 'inversify-react'
import { PropsWithChildren, useEffect, useState } from 'react'
import { EventDependencies } from '../dependencies'
import { EventNotification } from '../models/EventNotification'
import { EventsContext } from './EventsContext'

export default function EventsContextProvider(props: PropsWithChildren) {
    const [lastEvent, setLastEvent] = useState<EventNotification>()

    const eventsClient = useInjection(EventDependencies.Client)
    const dateGenerator = useInjection(DateDependencies.Generator)
    const authStore = useInjection(AuthDependencies.Store)

    useEffect(() => {
        eventsClient.registerListener(async (eventPayload) => {
            setLastEvent({
                timestamp: dateGenerator(),
                payload: eventPayload,
            })
        })

        if (authStore.getIdToken() !== null) eventsClient.startListening()
        return () => {
            eventsClient.stopListening()
        }
    }, [])

    return (
        <EventsContext.Provider value={{ lastEvent }}>
            {props.children}
        </EventsContext.Provider>
    )
}
