import { interfaces } from 'inversify'

interface DateGenerator {
    (): Date
}

export const liveDateGenerator: DateGenerator = () => {
    return new Date()
}

export const fakeDateGenerator: DateGenerator = () => {
    const now = new Date()
    now.setHours(now.getHours() + 2)
    return now
}

interface _DateDependencies {
    Generator: interfaces.ServiceIdentifier<DateGenerator>
}

export const DateDependencies: _DateDependencies = {
    Generator: Symbol('DateGenerator'),
}

export type { DateGenerator }
