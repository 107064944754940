import { Divider, GridItem } from '@chakra-ui/react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

export function FormDivider() {
    if (useScreenBreakpoint() === 'mobile') {
        return <Divider py={2} />
    }
    return (
        <GridItem colSpan={3}>
            <Divider />
        </GridItem>
    )
}
