import {
    Button,
    HStack,
    MenuItemOption,
    MenuOptionGroup,
} from '@chakra-ui/react'

import useT from 'localization/hooks/useT'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { Day, days } from '../models/Days'

interface ScheduleFormQuickActionButtonsProps {
    selectedDays: Day[]
    setSelectedDays: Dispatch<SetStateAction<Day[]>>
}

export function ScheduleFormQuickActionButtonsDesktop(
    props: ScheduleFormQuickActionButtonsProps
) {
    const { selectedDays, setSelectedDays } = props
    const t = useT('timeschedule')

    const isWholeWeekSelected = useMemo(() => {
        return selectedDays.length === 7
    }, [selectedDays.length])

    const isWorkingDaySelected = useMemo(() => {
        return (
            selectedDays.length === 5 &&
            !selectedDays.includes('saturday') &&
            !selectedDays.includes('sunday')
        )
    }, [selectedDays])

    const isWeekendSelected = useMemo(() => {
        return (
            selectedDays.length === 2 &&
            selectedDays.includes('saturday') &&
            selectedDays.includes('sunday')
        )
    }, [selectedDays])

    return (
        <HStack w="full">
            <Button
                size="sm"
                variant={isWholeWeekSelected ? 'solid' : 'outline'}
                borderRadius="full"
                onClick={() => setSelectedDays(days)}
            >
                {t('weekly_schedule_select_all_days_button')}
            </Button>
            <Button
                size="sm"
                variant={isWorkingDaySelected ? 'solid' : 'outline'}
                borderRadius="full"
                onClick={() => setSelectedDays(days.slice(0, 5))}
            >
                {t('weekly_schedule_select_mon_to_fri_button')}
            </Button>
            <Button
                size="sm"
                variant={isWeekendSelected ? 'solid' : 'outline'}
                borderRadius="full"
                onClick={() => setSelectedDays(days.slice(5, 7))}
            >
                {t('weekly_schedule_select_sat_and_sun_button')}
            </Button>
        </HStack>
    )
}

export function ScheduleFormQuickActionButtonsMobile(
    props: ScheduleFormQuickActionButtonsProps & { onClose: () => void }
) {
    const t = useT('timeschedule')
    const { selectedDays, setSelectedDays, onClose } = props

    const isWholeWeekSelected = useMemo(() => {
        return selectedDays.length === 7
    }, [selectedDays.length])

    const isWorkingDaySelected = useMemo(() => {
        return (
            selectedDays.length === 5 &&
            !selectedDays.includes('saturday') &&
            !selectedDays.includes('sunday')
        )
    }, [selectedDays])

    const isWeekendSelected = useMemo(() => {
        return (
            selectedDays.length === 2 &&
            selectedDays.includes('saturday') &&
            selectedDays.includes('sunday')
        )
    }, [selectedDays])

    const menuOptionGroupValue = useMemo(() => {
        if (isWholeWeekSelected) {
            return '1-7'
        }
        if (isWorkingDaySelected) {
            return '1-5'
        }
        if (isWeekendSelected) {
            return '6-7'
        }
        return 'none'
    }, [isWholeWeekSelected, isWorkingDaySelected, isWeekendSelected])

    return (
        <MenuOptionGroup
            title={t(
                'weekly_schedule_mobile_days_selector_shortcuts_menu_option_group'
            )}
            type="radio"
            value={menuOptionGroupValue}
        >
            <MenuItemOption
                value="1-7"
                onClick={() => {
                    setSelectedDays(days)
                    onClose()
                }}
            >
                {t('weekly_schedule_select_all_days_button')}
            </MenuItemOption>
            <MenuItemOption
                value="1-5"
                onClick={() => {
                    setSelectedDays(days.slice(0, 5))
                    onClose()
                }}
            >
                {t('weekly_schedule_select_mon_to_fri_button')}
            </MenuItemOption>
            <MenuItemOption
                value="6-7"
                onClick={() => {
                    setSelectedDays(days.slice(5, 7))
                    onClose()
                }}
            >
                {t('weekly_schedule_select_sat_and_sun_button')}
            </MenuItemOption>
        </MenuOptionGroup>
    )
}
