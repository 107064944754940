import { Location } from 'features/location/models/Location'
import _ from 'lodash'

export function mapModelToSubcategories(
    subcategories?: Location.Category[]
): number | undefined {
    if (subcategories === undefined) {
        return undefined
    }

    return _.sum(subcategories)
}
