import Avokado from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { EventPayload } from '../models/EventNotification'

export interface EventClient {
    registerListener(handler: (payload: EventPayload) => Promise<void>): void

    startListening: () => void
    stopListening: () => void
}

@injectable()
export class EventClientLive implements EventClient {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    private cancellables: (() => void)[] = []

    registerListener(handler: (payload: EventPayload) => Promise<void>): void {
        const mediaProcessedCancellable =
            this.avokado.events.mediaProcessed.subscribe(async (payload) => {
                handler({
                    type: 'media-processed',
                    media: payload,
                })
            })

        const entryUpdatedCancellable =
            this.avokado.events.menuEntryBatchUpdateTaskCompleted.subscribe(
                async (payload) => {
                    handler({
                        type: 'entry-updated',
                        entry: payload,
                    })
                }
            )

        this.cancellables.push(mediaProcessedCancellable)
        this.cancellables.push(entryUpdatedCancellable)
    }

    startListening(): void {
        this.avokado.events.startListening().catch((err) => {})
    }

    stopListening(): void {
        this.avokado.events.stopListening()
        this.cancellables.forEach((cancel) => cancel())
        this.cancellables = []
    }
}

@injectable()
export class EventClientFake implements EventClient {
    registerListener(handler: (payload: EventPayload) => Promise<void>): void {
        throw new Error('Unimplemented')
    }

    startListening(): void {
        throw new Error('Unimplemented')
    }

    stopListening(): void {
        throw new Error('Unimplemented')
    }
}
