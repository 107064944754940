import { MediaUploadLink } from 'features/media/models/MediaUploadLink'
import { RawMedia } from 'features/media/models/RawMedia'
import { Menu } from 'features/menu/models/Menu'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { MenuSectionDependencies } from '../dependencies/section'
import { mapSectionMediaToModel } from '../mappers/SDKResponseMapper'

export interface UseSectionFormDataHook {
    hasChanges: boolean
    name: string
    description: string
    isUploadingCover: boolean
    rawMedia: RawMedia | undefined
    setName: Dispatch<SetStateAction<string>>
    setDescription: Dispatch<SetStateAction<string>>
    setIsUploadingCover: Dispatch<SetStateAction<boolean>>
    setRawMedia: Dispatch<SetStateAction<RawMedia | undefined>>
}

export function useSectionFormData(
    section: Menu.Section
): UseSectionFormDataHook {
    const [name, setName] = useState(section.name)
    const [description, setDescription] = useState(section.description ?? '')
    const [isUploadingCover, setIsUploadingCover] = useState(false)
    const [rawMedia, setRawMedia] = useState<RawMedia>()

    const hasChanges = useMemo(() => {
        return (
            !_.isEqual(name, section.name) ||
            !_.isEqual(description, section.description ?? '')
        )
    }, [name, description, section])

    return {
        hasChanges,
        name,
        description,
        isUploadingCover,
        rawMedia,
        setName,
        setDescription,
        setIsUploadingCover,
        setRawMedia,
    }
}

export function useSectionFormController(
    menu: Menu,
    section: Menu.Section,
    params: UseSectionFormDataHook,
    onSave: (menu: Menu) => void
): UseFormControllerHook<void> & {
    buildMediaUploadLink: (media: File) => Promise<MediaUploadLink>
} {
    const update = useInjection(MenuSectionDependencies.Update)
    const upload = useInjection(MenuSectionDependencies.UploadMedia)

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        if (!params.hasChanges) return false
        if (params.isUploadingCover) return false
        if (params.name === '') return false
        return true
    }, [params])

    useEffect(() => {
        if (params.rawMedia) {
            const media = mapSectionMediaToModel(section.id, params.rawMedia)
            onSave({
                ...menu,
                sections: menu.sections.map((oldSection) => {
                    if (_.isEqual(oldSection.id, section.id)) {
                        return { ...section, media }
                    } else {
                        return oldSection
                    }
                }),
            })

            params.setRawMedia(undefined)
            setIsProcessing(false)
        }
    }, [params.rawMedia]) // eslint-disable-line react-hooks/exhaustive-deps

    const buildMediaUploadLink = useCallback(
        async (media: File) => {
            return await upload.run({ ...section.id, media })
        },
        [section] // eslint-disable-line react-hooks/exhaustive-deps
    )

    async function submit() {
        if (isProcessing || !canSubmit) return
        setIsProcessing(true)

        const newMenu = await update.run({
            menu: menu,
            section: section,
            name: params.name,
            description: params.description,
        })
        onSave(newMenu)
        setIsProcessing(false)
    }

    return { isProcessing, canSubmit, submit, buildMediaUploadLink }
}
