import { UseWeekdayScheduleFormDataHook } from 'features/calendar/hooks/useWeekdayScheduleForm'
import { useMemo } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { ScheduleFormDesktop } from './ScheduleFormDesktop'
import { ScheduleFormMobile } from './ScheduleFormMobile'

export interface Labels {
    fullDayActive: string
    fullDayInactive: string
    fullDayActiveAlert: string
    fullDayInactiveAlert: string
    timeRangeFrom: string
    timeRangeTo: string
}

export interface ScheduleFormProps {
    labels: Labels
    formData: UseWeekdayScheduleFormDataHook
}

export function ScheduleForm(props: ScheduleFormProps) {
    const formData = props.formData

    const checkedOpen = useMemo(() => {
        const schedules = formData.selectedDays.map(
            (selectedDay) => formData.weekdays[selectedDay]
        )

        return (
            schedules.length > 0 &&
            schedules.every(
                (schedule) => schedule.type === 'full-day' && schedule.isActive
            )
        )
    }, [formData.weekdays, formData.selectedDays])

    const screen = useScreenBreakpoint()

    if (screen === 'mobile' || screen === 'tablet') {
        return <ScheduleFormMobile {...props} checkedOpen={checkedOpen} />
    } else {
        return <ScheduleFormDesktop {...props} checkedOpen={checkedOpen} />
    }
}
