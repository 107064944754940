import { Avatar, AvatarProps, useColorModeValue } from '@chakra-ui/react'
import { useMemo } from 'react'
import { FaCalendarAlt, FaShoppingBag, FaTruck } from 'react-icons/fa'
import { MdTableRestaurant } from 'react-icons/md'

interface PolicyIconProps extends AvatarProps {
    policy: 'table' | 'reservation' | 'delivery' | 'take-away'
}

export function PolicyIcon({ policy, ...props }: PolicyIconProps) {
    const bg = useColorModeValue('primary.400', 'primary.300')

    const icon = useMemo(() => {
        switch (policy) {
            case 'table':
                return <MdTableRestaurant />
            case 'reservation':
                return <FaCalendarAlt />
            case 'delivery':
                return <FaTruck />
            case 'take-away':
                return <FaShoppingBag />
        }
    }, [policy])

    return <Avatar icon={icon} bg={bg} {...props} />
}
