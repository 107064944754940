import { Button } from '@chakra-ui/react'
import { ScheduleForm } from 'features/calendar/components/ScheduleForm'
import useT from 'localization/hooks/useT'
import { Container } from 'uikit/container/Container'
import useFormNotification from 'utils/hooks/useFormNotification'
import {
    useTimeScheduleFormController,
    useTimeScheduleFormData,
} from '../hooks/timeschedule/useTimeScheduleForm'
import { LocationTabProps } from '../pages/LocationPage'
import { ClosuresSection } from './timeschedule/ClosuresSection'

export function TimeScheduleTab({ location, update }: LocationTabProps) {
    const t = useT('timeschedule')

    const formData = useTimeScheduleFormData(location)
    const controller = useTimeScheduleFormController(location, formData, update)

    useFormNotification('time-schedule', controller)

    return (
        <>
            <Container
                title={t('weekly_schedule_title')}
                subtitle={t('weekly_schedule_description')}
                header={
                    <Button
                        isLoading={
                            controller.isProcessing &&
                            formData.hasWeekdayScheduleChanges
                        }
                        onClick={controller.submit}
                        isDisabled={!formData.hasWeekdayScheduleChanges}
                    >
                        {t('save_button')}
                    </Button>
                }
            >
                <ScheduleForm
                    labels={{
                        fullDayActive: t('weekly_schedule_24h_open'),
                        fullDayInactive: t('weekly_schedule_closed'),
                        fullDayActiveAlert: t('weekly_schedule_24h_open_alert'),
                        fullDayInactiveAlert: t('weekly_schedule_closed_alert'),
                        timeRangeFrom: t('weekly_schedule_opens_at_label'),
                        timeRangeTo: t('weekly_schedule_closes_at_label'),
                    }}
                    formData={formData.weekdaySchedule}
                />
            </Container>

            <Container
                pt={8}
                title={t('scheduled_closures_title')}
                subtitle={t('scheduled_closures_description')}
                header={
                    <Button
                        isLoading={
                            controller.isProcessing &&
                            formData.hasScheduledClosuresChanges
                        }
                        onClick={controller.submit}
                        isDisabled={!formData.hasScheduledClosuresChanges}
                    >
                        {t('save_button')}
                    </Button>
                }
            >
                <ClosuresSection formData={formData.scheduledClosures} />
            </Container>
        </>
    )
}
