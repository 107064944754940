import { Text } from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Day } from '../models/Days'

interface SelectedDaysLabelProps {
    days: Day[]
}
export default function SelectedDaysLabel({ days }: SelectedDaysLabelProps) {
    const t = useT('timeschedule')
    if (days.length === 0) {
        return (
            <Text
                fontSize="md"
                fontWeight="medium"
                color="label2"
                textAlign="center"
            >
                {t('week_schedule_no_selected_days_label')}
            </Text>
        )
    }
    return (
        <Text
            fontSize="md"
            fontWeight="medium"
            color="label2"
            textAlign="center"
        >
            {t('week_schedule_selected_days_label', {
                days: days.map((d) => t(`generic_day_name_${d}`)),
            })}
        </Text>
    )
}
