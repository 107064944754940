import { OrganizationRetrieve, UserRetrieve } from '@avokadoapp/avokado-ts'
import _ from 'lodash'
import { ManagedOrganization } from '../models/ManagedOrganization'
import { Organization } from '../models/Organization'

export function mapOrganizationToModel(
    payload: OrganizationRetrieve.Response,
    role: Organization.Role
): Organization {
    return {
        id: { organizationId: payload.id },
        name: payload.name,
        role: role,
        currency: payload.currency,
        status: payload.verificationStatus,
        quota: {
            locations: payload.quota.allowedLocationsCount,
            menus: payload.quota.allowedMenusCount,
        },
        country:
            payload.billingAddress.country === 'it'
                ? 'IT'
                : payload.billingAddress.country === 'ch'
                ? 'CH'
                : '',
    }
}

export function mapRolesToModels(
    payload: UserRetrieve.Response.ActiveRoles
): ManagedOrganization[] {
    const admins: ManagedOrganization[] = payload.organizationAdmin.map(
        (role) => ({
            role: 'admin',
            organizationId: role.organizationId!,
        })
    )

    const managers: ManagedOrganization[] = payload.organizationManager.map(
        (role) => ({
            role: 'manager',
            organizationId: role.organizationId!,
        })
    )

    const dictionary = _.groupBy(
        [...admins, ...managers],
        (x) => x.organizationId
    )

    const organizations = Object.entries(dictionary).map(
        ([organization, roles]) => ({
            role: roles.length === 1 ? roles[0].role : 'admin',
            organizationId: organization,
        })
    )

    return _.sortBy(
        organizations,
        (organization) => organization.organizationId
    )
}
