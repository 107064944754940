import {
    Box,
    Button,
    ButtonGroup,
    HStack,
    Heading,
    Icon,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
    VStack,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { Namespace } from 'localization/i18n'
import { PropsWithChildren, RefObject, useRef } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { MAGIC_NUMBER_TOP_BAR_SIZE_TODO } from 'uikit/container/useContainerScroll'
import { Step as StepModel } from '.'

export interface StepPropsWithRef {
    headerRef: RefObject<HTMLDivElement>
}

interface GuidedActionProps<Context> {
    namespace?: Namespace
    context: Context
    steps: StepModel<Context>[]
    index: number
    isProcessing: boolean
    isDisabled: boolean
    hideControls?: boolean
    hideSkip: boolean
    allowScroll?: boolean
    completion:
        | { modelLink: string; fallbackLink?: string }
        | { label: string; handler: () => void }
    onNext: () => void
    onBack: () => void
    onSkip: (amount: number) => void
    currentStep: StepModel<Context>
}

export function GuidedActionDesktop<Context>({
    namespace,
    context,
    steps,
    index,
    isProcessing,
    isDisabled,
    hideControls = false,
    hideSkip,
    allowScroll = true,
    completion,
    onNext,
    onBack,
    onSkip,
    currentStep,
}: GuidedActionProps<Context>) {
    const t = useT(namespace)

    const headerRef = useRef<HTMLDivElement>(null)

    return (
        <HStack
            py={8}
            flex={1}
            w="full"
            maxW="6xl"
            align="stretch"
            mx="auto"
            px={4}
            maxH={`calc(100vh - ${MAGIC_NUMBER_TOP_BAR_SIZE_TODO}px)`}
            gap={4}
        >
            <VStack
                p={8}
                bg="pageBackground2"
                boxShadow="lg"
                borderRadius="lg"
                maxW="xs"
            >
                <Stepper
                    w="full"
                    size="lg"
                    orientation="vertical"
                    gap={0}
                    index={index}
                >
                    {steps.map((step) => (
                        <Step key={step.title}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box mb={12} mt={-2}>
                                <StepTitle>{t(step.title)}</StepTitle>
                                <StepDescription>
                                    {t(step.description)}
                                </StepDescription>
                            </Box>

                            <StepSeparator />
                        </Step>
                    ))}
                </Stepper>
            </VStack>

            <VStack maxH="full" flex={1} align="stretch" gap={4}>
                {currentStep ? (
                    <StepContainer
                        namespace={namespace}
                        title={currentStep.title}
                        headerRef={headerRef}
                        isLoading={isProcessing}
                        hideControls={hideControls}
                        allowScroll={allowScroll}
                        next={isDisabled ? undefined : onNext}
                        back={
                            index === 0 || currentStep.preventBack
                                ? undefined
                                : onBack
                        }
                        skip={
                            currentStep.skipBy === undefined || hideSkip
                                ? undefined
                                : () => onSkip(currentStep.skipBy!)
                        }
                    >
                        <currentStep.content
                            headerRef={headerRef}
                            context={context}
                        />
                    </StepContainer>
                ) : (
                    <VStack
                        bg="pageBackground2"
                        borderRadius="lg"
                        boxShadow="lg"
                        gap={12}
                        py={20}
                        flex={1}
                    >
                        <Icon as={FaCheckCircle} color="accent" boxSize={128} />

                        {'modelLink' in completion && (
                            <>
                                <VStack spacing={0}>
                                    <Heading>{t('new_success_title')}</Heading>
                                    <Text align="center" px={8}>
                                        {t('new_success_message')}
                                    </Text>
                                </VStack>
                                <ButtonGroup minW="20%">
                                    {completion.fallbackLink && (
                                        <Button
                                            flex={1}
                                            as={Link}
                                            to={completion.fallbackLink}
                                        >
                                            {t('done_button')}
                                        </Button>
                                    )}
                                    <Button
                                        flex={1}
                                        as={Link}
                                        to={completion.modelLink}
                                    >
                                        {t('see_button')}
                                    </Button>
                                </ButtonGroup>
                            </>
                        )}
                        {'handler' in completion && (
                            <>
                                <Text>{t('new_success_message')}</Text>
                                <ButtonGroup minW="20%">
                                    <Button
                                        flex={1}
                                        onClick={completion.handler}
                                    >
                                        {completion.label}
                                    </Button>
                                </ButtonGroup>
                            </>
                        )}
                    </VStack>
                )}
            </VStack>
        </HStack>
    )
}

interface StepContainerProps extends PropsWithChildren {
    namespace?: Namespace
    title: string
    isLoading: boolean
    hideControls: boolean
    allowScroll: boolean
    headerRef: RefObject<HTMLDivElement>
    next?: () => void
    back?: () => void
    skip?: () => void
}

function StepContainer({
    namespace,
    title,
    isLoading,
    hideControls,
    allowScroll,
    headerRef,
    children,
    next,
    back,
    skip,
}: StepContainerProps) {
    const t = useT(namespace)

    return (
        <>
            <VStack
                bg="pageBackground2"
                borderRadius="lg"
                boxShadow="lg"
                p={8}
                spacing={0}
                align="start"
            >
                <HStack justifyContent="space-between" w="full" h={8}>
                    <Text color="accent" fontSize="sm" fontWeight="semibold">
                        {t(title)}
                    </Text>

                    {skip && (
                        <Button
                            variant="unstyled"
                            size="sm"
                            color="label3"
                            isDisabled={isLoading}
                            _hover={{ color: 'label2' }}
                            onClick={skip}
                        >
                            {t('skip_button')}
                        </Button>
                    )}
                </HStack>

                <Box w="full" ref={headerRef} />
            </VStack>

            <VStack
                bg="pageBackground2"
                borderRadius="lg"
                boxShadow="lg"
                flex={1}
                p="31px"
                minH={0}
            >
                <VStack
                    align="start"
                    w="full"
                    flex={1}
                    spacing={0}
                    overflow={allowScroll ? 'auto' : undefined}
                    p="1px"
                >
                    {children}
                </VStack>

                {!hideControls && (
                    <ButtonGroup alignSelf="end">
                        {back && (
                            <Button
                                variant="ghost"
                                onClick={back}
                                isDisabled={isLoading}
                            >
                                {t('back_button')}
                            </Button>
                        )}
                        <Button
                            onClick={next}
                            isLoading={isLoading}
                            isDisabled={next === undefined}
                        >
                            {t('next_button')}
                        </Button>
                    </ButtonGroup>
                )}
            </VStack>
        </>
    )
}
