import { Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'

export function TopBarItems() {
    const { t } = useTranslation()

    const location = useLocation()
    const [localPath, setLocalPath] = useState('')

    const { organizationId } = useParams()

    const items = useMemo(
        () => [
            { name: t('Home'), path: '' },
            { name: t('Locations'), path: '/locations' },
            { name: t('Menus'), path: '/menus' },
            { name: t('Finance'), path: '/finance' },
        ],
        [t]
    )

    useEffect(() => {
        switch (true) {
            case /locations/.test(location.pathname):
                setLocalPath('/locations')
                break

            case /menus/.test(location.pathname):
                setLocalPath('/menus')
                break

            case /finance/.test(location.pathname):
                setLocalPath('/finance')
                break

            default:
                setLocalPath('')
                break
        }
    }, [location])

    return (
        <>
            {items.map((item) => (
                <Link
                    to={`/organizations/${organizationId}${item.path}`}
                    key={item.path}
                >
                    <Text
                        color={localPath === item.path ? 'label1' : 'label2'}
                        _hover={{ color: 'accent' }}
                    >
                        {item.name}
                    </Text>
                </Link>
            ))}
        </>
    )
}
