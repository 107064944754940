import { Collapse, VStack } from '@chakra-ui/react'
import { UseReservationPolicyFormDataHook } from 'features/location/hooks/policy/useReservationPolicyForm'
import { useTranslation } from 'react-i18next'
import { DurationInput, NumberInput, PriceInput } from 'uikit/form/input'
import { PolicyFormSwitch } from './PolicyFormSwitch'

interface ReservationFormProps {
    formData: UseReservationPolicyFormDataHook
}

export function ReservationForm({ formData }: ReservationFormProps) {
    const { t } = useTranslation()

    return (
        <VStack align="stretch" pt={8} spacing={12}>
            <PolicyFormSwitch
                title={t('Remote Order')}
                description={t(
                    'Customers can start ordering before arriving at the location.'
                )}
                isChecked={formData.allowsRemoteOrder}
                onToggle={formData.setAllowsRemoteOrder}
            />
            <Collapse in={formData.allowsRemoteOrder} animateOpacity>
                <PolicyFormSwitch
                    title={t('Upfront Payment')}
                    description={t(
                        'Customers must pay for their order upfront in order to confirm it.'
                    )}
                    isChecked={formData.allowsRemoteUpfrontPayment}
                    onToggle={formData.setAllowsRemoteUpfrontPayment}
                />
            </Collapse>
            <PriceInput
                title={t('Table Holding Deposit')}
                description={t(
                    'Amount clients must pay in advance in order to reserve a table.'
                )}
                value={formData.tableHoldingDepositAmount}
                setValue={formData.setTableHoldingDepositAmount}
            />
            <NumberInput
                title={t('Automatic Confirmation')}
                description={t(
                    'If a slot is available, automatically accept reservations for groups of up to the following size.'
                )}
                value={formData.automaticConfirmationThreshold}
                setValue={formData.setAutomaticConfirmationThreshold}
            />
            <NumberInput
                title={t('Concurrent Reservations')}
                description={t(
                    'Maximum number of reservations per any given time slot.'
                )}
                value={formData.maximumNumberOfConcurrentReservations}
                setValue={formData.setMaximumNumberOfConcurrentReservations}
            />
            <NumberInput
                title={t('Groups')}
                description={t('Maximum number of people per reservation.')}
                value={formData.maximumNumberOfPeoplePerReservation}
                setValue={formData.setMaximumNumberOfPeoplePerReservation}
            />
            <DurationInput
                title={t('Duration')}
                description={t(
                    'Approximate duration of a reservation in the calendar.'
                )}
                value={formData.reservationDefaultDuration}
                setValue={formData.setReservationDefaultDuration}
            />
        </VStack>
    )
}
