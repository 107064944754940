import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    PinInput,
    PinInputField,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useNotification } from 'features/notification/hooks/useNotification'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { FormEvent, useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PasswordInput } from 'uikit/form/input'
import { passwordValidation } from 'utils/form/validation'
import {
    useResetPasswordFormController,
    useResetPasswordFormData,
} from '../hooks/useResetPasswordForm'

export default function ResetPasswordPage() {
    const t = useT('auth')

    const notification = useNotification()

    const formData = useResetPasswordFormData()
    const controller = useResetPasswordFormController(formData)

    const [otpError, setOTPError] = useState(false)
    const [passwordError, setPasswordError] = useState<string>()

    async function handleSubmit(event?: FormEvent<HTMLDivElement>) {
        event?.preventDefault()
        if (controller.canSubmit) {
            await controller.submit()
        } else {
            setOTPError(formData.confirmationCode.length !== 6)

            passwordValidation
                .validate({ password: formData.password })
                .catch((e) => setPasswordError(t(e.errors[0])))
        }
    }

    useEffect(() => setPasswordError(undefined), [formData.password])
    useEffect(() => setOTPError(false), [formData.confirmationCode])

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack
                as={motion.div}
                align="stretch"
                w="full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                gap={4}
            >
                <Button
                    as={Link}
                    to="/password-forgotten"
                    display="flex"
                    alignSelf="flex-start"
                    variant="unstyled"
                    size="sm"
                    color="label1"
                    mt={2}
                    leftIcon={<FaArrowLeft />}
                    _hover={{ color: 'accent' }}
                >
                    {t('generic_button_back')}
                </Button>

                <FormControl>
                    <HStack spacing={4}>
                        <PinInput
                            otp
                            autoFocus
                            isInvalid={otpError}
                            focusBorderColor="primary.200"
                            value={formData.confirmationCode}
                            isDisabled={controller.isProcessing}
                            onChange={(e) => formData.setConfirmationCode(e)}
                        >
                            <PinInputField
                                borderRadius="full"
                                borderColor="gray.400"
                            />
                            <PinInputField
                                borderRadius="full"
                                borderColor="gray.400"
                            />
                            <PinInputField
                                borderRadius="full"
                                borderColor="gray.400"
                            />
                            <PinInputField
                                borderRadius="full"
                                borderColor="gray.400"
                            />
                            <PinInputField
                                borderRadius="full"
                                borderColor="gray.400"
                            />
                            <PinInputField
                                borderRadius="full"
                                borderColor="gray.400"
                            />
                        </PinInput>
                    </HStack>
                </FormControl>

                <FormControl isInvalid={passwordError !== undefined} pt={8}>
                    <PasswordInput
                        value={formData.password}
                        setValue={formData.setPassword}
                        h="44px"
                        w="xs"
                        focusBorderColor="primary.200"
                        autoComplete="new-password"
                        borderRadius="full"
                        focusTrigger={formData.confirmationCode.length === 6}
                    />
                    <FormErrorMessage>{passwordError}</FormErrorMessage>
                </FormControl>
                <Text fontSize="xs" color="label2">
                    {t('reset_password_description_label')}
                </Text>

                <Button
                    w="full"
                    type="submit"
                    borderRadius="full"
                    h="48px"
                    mt={8}
                    isLoading={controller.isProcessing}
                    isDisabled={formData.confirmationCode.length !== 6}
                >
                    {t('generic_button_reset_password')}
                </Button>
            </VStack>
        </Box>
    )
}

function passwordIsInvalid(psw: string): boolean {
    const hasNumber = /\d/
    const hasUppercase = /[A-Z]/
    const hasLowercase = /[a-z]/
    const minLength = 8

    return !(
        hasNumber.test(psw) &&
        hasUppercase.test(psw) &&
        hasLowercase.test(psw) &&
        psw.length >= minLength
    )
}
