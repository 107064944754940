import { LocationCompact } from 'features/location/models/LocationCompact'
import { useInjection } from 'inversify-react'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useMap } from 'react-use'
import { ReceiptDependencies } from '../dependencies/receipt'
import { Receipt } from '../models/Receipt'
import { ReceiptCompact } from '../models/ReceiptCompact'

interface UseReceiptsHook {
    isLoading: boolean
    isLoadingDetails: boolean
    selectedId: string | undefined
    receipt: Receipt | undefined
    receipts: ReceiptCompact[]
    location: LocationCompact | undefined
    day: Date
    setLocation: Dispatch<SetStateAction<LocationCompact | undefined>>
    setDay: Dispatch<SetStateAction<Date>>
    selectReceipt: (receipt: ReceiptCompact | undefined) => Promise<void>
}

export function useReceipts(): UseReceiptsHook {
    const list = useInjection(ReceiptDependencies.List)
    const retrieve = useInjection(ReceiptDependencies.Retrieve)

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingDetails, setIsLoadingDetails] = useState(false)
    const [selectedId, setSelectedId] = useState<string>()
    const [receipt, setReceipt] = useState<Receipt>()
    const [receipts, setReceipts] = useState<ReceiptCompact[]>([])
    const [location, setLocation] = useState<LocationCompact>()
    const [day, setDay] = useState(new Date('07/25/2023'))
    const [, { get: getList, set: setList }] = useMap<{
        [key: string]: ReceiptCompact[]
    }>()
    const [, { get: getDetails, set: setDetails }] = useMap<{
        [key: string]: Receipt
    }>()

    useEffect(() => {
        if (!location) return
        setReceipt(undefined)
        setSelectedId(undefined)
        setIsLoading(true)

        const key = location.id.locationId + ' - ' + day.toLocaleDateString()
        const storedReceipts = getList(key)

        if (storedReceipts) {
            setReceipts(storedReceipts)
            setIsLoading(false)
        } else {
            list.run({ ...location.id, day }).then((data) => {
                setList(key, data)
                setReceipts(data)
                setIsLoading(false)
            })
        }
    }, [day, location])

    const selectReceipt = useCallback(
        async (receipt: ReceiptCompact | undefined) => {
            if (isLoadingDetails) return
            if (receipt === undefined) {
                setSelectedId(undefined)
                setReceipt(undefined)
                return
            }

            setSelectedId(receipt.id.receiptId)
            setIsLoadingDetails(true)

            const storedReceipt = getDetails(receipt.id.receiptId)

            if (storedReceipt) {
                setReceipt(storedReceipt)
            } else {
                const data = await retrieve.run(receipt.id)
                setDetails(receipt.id.receiptId, data)
                setReceipt(data)
            }

            setIsLoadingDetails(false)
        },
        [isLoadingDetails]
    )

    return {
        isLoading,
        isLoadingDetails,
        selectedId,
        receipt,
        receipts,
        location,
        day,
        setLocation,
        setDay,
        selectReceipt,
    }
}
