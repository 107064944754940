import Avokado, { AvokadoAPIError, RoomList } from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapRoomsToModels } from '../mappers/SDKResponseMapper'
import { Room } from '../models/Room'

type ListRoomParams = Location.ID

export interface ListRoomAction {
    run(params: ListRoomParams): Promise<Room[]>
}

@injectable()
export class ListRoomActionLive implements ListRoomAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: ListRoomParams): Promise<Room[]> {
        const request: RoomList.Request = params
        const response = await this.avokado.room.list(request)
        return mapRoomsToModels(response)
    }
}

@injectable()
export class ListRoomActionFake implements ListRoomAction {
    async run(params: ListRoomParams): Promise<Room[]> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ListRoomActionFail implements ListRoomAction {
    async run(params: ListRoomParams): Promise<Room[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
