import {
    ComponentStyleConfig,
    cssVar,
    extendTheme,
    theme,
    withDefaultColorScheme,
    type ThemeConfig,
} from '@chakra-ui/react'
import { Dict } from '@chakra-ui/utils'
import '@fontsource/barlow/100.css'
import '@fontsource/barlow/200.css'
import '@fontsource/barlow/300.css'
import '@fontsource/barlow/400.css'
import '@fontsource/barlow/500.css'
import '@fontsource/barlow/600.css'
import '@fontsource/barlow/700.css'
import '@fontsource/barlow/800.css'
import '@fontsource/barlow/900.css'
import '@fontsource/unica-one'

export const COLOR_THEME = 'primary'

const config: ThemeConfig = {
    initialColorMode: 'system',
    useSystemColorMode: true,
}

const Modal = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Modal,
    baseStyle: {
        ...theme.components.Modal.baseStyle,
        overlay: {
            ...theme.components.Modal.baseStyle.overlay,
            bg: 'sheetOverlay',
            backdropFilter: 'blur(10px)',
        },
        dialog: {
            ...theme.components.Modal.baseStyle.dialog,
            bg: 'sheetBackground1',
        },
    },
})

const Popover = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Popover,
    variants: {
        ...theme.components.Popover.variants,
        responsive: {
            content: {
                width: 'unset',
            },
        },
    },
    baseStyle: {
        ...theme.components.Popover.baseStyle,
        content: {
            ...theme.components.Popover.baseStyle.content,
            shadow: 'base',
        },
    },
})

const $arrowBg = cssVar('popper-arrow-bg')
const Tooltip = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Tooltip,
    baseStyle: {
        ...theme.components.Tooltip.baseStyle,
        bg: 'sheetBackground1',
        color: 'label1',
        [$arrowBg.variable]: 'colors.sheetBackground1',
    },
})

const Divider = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Divider,
    baseStyle: {
        ...theme.components.Divider.baseStyle,
        borderColor: 'label3',
    },
})

const Menu = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Menu,
    baseStyle: {
        ...theme.components.Menu.baseStyle,
        list: {
            ...theme.components.Menu.list,
            bg: 'sheetBackground1',
        },
        item: {
            ...theme.components.Menu.item,
            bg: 'sheetBackground1',
            _hover: { bg: 'sheetBackground2' },
        },
    },
})

const Spinner = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Spinner,
    baseStyle: {
        color: 'accent',
    },
})

const Button = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Button,
    baseStyle: {
        ...theme.components.Button.baseStyle,
        borderRadius: 'full',
    },
})

const Textarea = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Textarea,
    defaultProps: {
        ...theme.components.Textarea.defaultProps,
        focusBorderColor: 'primary.200',
    },
})

const Select = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Select,
    defaultProps: {
        ...theme.components.Select.defaultProps,
        focusBorderColor: 'primary.200',
    },
})

const NumberInput = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.NumberInput,
    defaultProps: {
        ...theme.components.NumberInput.defaultProps,
        focusBorderColor: 'primary.200',
    },
})

const Input = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Input,
    defaultProps: {
        ...theme.components.Input.defaultProps,
        focusBorderColor: 'primary.200',
    },
    sizes: {
        ...theme.components.Input.sizes,
        '3xl': {
            field: {
                fontSize: '3xl',
                px: '4',
                h: '14',
                borderRadius: 'md',
            },
            addon: {
                fontSize: '3xl',
                px: '4',
                h: '14',
                borderRadius: 'md',
            },
        },
        '4xl': {
            field: {
                fontSize: '4xl',
                px: '4',
                h: '14',
                borderRadius: 'md',
            },
            addon: {
                fontSize: '4xl',
                px: '4',
                h: '14',
                borderRadius: 'md',
            },
        },
    },
})

const Table = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Table,
    variants: {
        ...theme.components.Table.variants,
        simple: {
            ...theme.components.Table.variants.simple,
            th: {
                ...theme.components.Table.variants.simple.th,
                borderColor: 'label3',
            },
            td: {
                ...theme.components.Table.variants.simple.td,
                borderColor: 'label3',
            },
        },
    },
})

const Code = (theme: Dict<any>): ComponentStyleConfig => ({
    ...theme.components.Code,
    baseStyle: {
        ...theme.components.Code.baseStyle,
        bg: 'transparent',
        color: 'label1',
    },
})

export default extendTheme(
    {
        ...theme,
        config,
        textStyles: {
            title: {
                // Set the font size, font weight, and line height for the title style
                fontSize: '3xl',
                fontWeight: 'bold',
                lineHeight: 'shorter',
                // Set any other properties you want for the title style
                // For example, you can set the color, margin, or padding
                color: 'gray.800',
                mt: 4,
                mb: 2,
            },
        },
        styles: {
            global: {
                body: {
                    background: `pageBackground1`,
                    color: 'label1',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                },
            },
        },
        colors: {
            primary: {
                15: '#FFF9F5',
                25: '#FFF4ED',
                50: '#FFE6D5',
                100: '#FFD6AE',
                200: '#FF9C66',
                300: '#FF692E',
                400: '#FF4405',
                500: '#E62E05',
                600: '#BC1B06',
                700: '#97180C',
                800: '#771A0D',
                900: '#61150B',
            },
        },
        fonts: {
            heading: `'Barlow', sans-serif`,
            body: `'Barlow', sans-serif`,
        },
        semanticTokens: {
            shadows: {
                accent: {
                    default: `0px 0px 20px 0px var(--chakra-colors-${COLOR_THEME}-200)`,
                    _dark: `0px 0px 15px -5px var(--chakra-colors-${COLOR_THEME}-200)`,
                },
            },
            colors: {
                sheetBackground1: {
                    default: '#FFFFFF',
                    _dark: '#151517',
                },
                sheetBackground2: {
                    default: '#EFEEF6',
                    _dark: '#212123',
                },
                sheetBackground3: {
                    default: '#FFFFFF',
                    _dark: '#2C2C2E',
                },
                sheetOverlay: {
                    default: 'blackAlpha.300',
                    _dark: 'whiteAlpha.200',
                },
                pageBackground1: {
                    default: '#F2F2F7',
                    _dark: '#000000',
                },
                pageBackground2: {
                    default: '#FFFFFF',
                    _dark: '#1C1C1E',
                },
                pageBackground3: {
                    default: '#F2F2F7',
                    _dark: '#2C2C2E',
                },
                label1: {
                    default: '#000000',
                    _dark: '#FFFFFF',
                },
                label2: {
                    default: '#8A8A8E',
                    _dark: '#8D8D93',
                },
                label3: {
                    default: '#C5C5C7',
                    _dark: '#464649',
                },
                accent: {
                    default: `#FF4500`,
                    _dark: `#FF5733`,
                },
                accentHover: {
                    default: `#FF450030`,
                    _dark: `#FF573330`,
                },
                accentOutline: {
                    default: `${COLOR_THEME}.600`,
                    _dark: `${COLOR_THEME}.300`,
                },
                onAccent: {
                    default: '#FFFFFF',
                    _dark: '#FFFFFF',
                },
                accentSurface: {
                    default: `${COLOR_THEME}.300`,
                    _dark: `${COLOR_THEME}.200`,
                },
                onAccentSurface: {
                    default: `${COLOR_THEME}.50`,
                    _dark: `black`,
                },
                onAccentHover: {
                    default: `${COLOR_THEME}.300`,
                    _dark: `${COLOR_THEME}.200`,
                },
                authBackground: {
                    default: '#FFFFFF',
                    _dark: '#000000',
                },
                supportTextareaHoverBackground: {
                    default: 'whiteAlpha.700',
                    _dark: 'whiteAlpha.200',
                },
            },
        },
        components: {
            ...theme.components,
            Modal: Modal(theme),
            Popover: Popover(theme),
            Tooltip: Tooltip(theme),
            Divider: Divider(theme),
            Menu: Menu(theme),
            Spinner: Spinner(theme),
            Button: Button(theme),
            Input: Input(theme),
            Code: Code(theme),
            Textarea: Textarea(theme),
            Select: Select(theme),
            Table: Table(theme),
            NumberInput: NumberInput(theme),
        },
    },
    withDefaultColorScheme({ colorScheme: `${COLOR_THEME}` })
)
