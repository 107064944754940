import { Button, VStack } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSection } from 'uikit/form'
import { NumberInput } from 'uikit/form/input'

interface CapacitySectionProps {
    capacity: number | undefined
    setCapacity: Dispatch<SetStateAction<number | undefined>>
}

export function CapacitySection({
    capacity,
    setCapacity,
}: CapacitySectionProps) {
    const { t } = useTranslation()

    const [hasNoSeats, setHasNoSeats] = useState(capacity === 0)

    const handleSeatStatusChange = useCallback(() => {
        if (hasNoSeats) {
            setHasNoSeats(false)
        } else {
            setHasNoSeats(true)
            setCapacity(0)
        }
    }, [hasNoSeats])

    return (
        <FormSection
            title={t('Seating Capacity')}
            subtitle={t(
                'Maximum number of people that the location can host at a time.'
            )}
        >
            <VStack>
                <NumberInput
                    value={capacity}
                    setValue={setCapacity}
                    isDisabled={hasNoSeats}
                    placeholder="Capacity"
                />

                <Button
                    alignSelf="end"
                    variant="unstyled"
                    size="sm"
                    color="label2"
                    _hover={{ color: 'accent' }}
                    onClick={handleSeatStatusChange}
                >
                    {hasNoSeats ? t('Add seats') : t("I don't have seats")}
                </Button>
            </VStack>
        </FormSection>
    )
}
