import { useMemo } from 'react'

export function isSmartphone() {
    const userAgent = navigator.userAgent

    // Check for iPhone
    const isiPhone = /iPhone/i.test(userAgent) && !/iPad/i.test(userAgent)

    // Check for Android phone
    const isAndroid = /Android/i.test(userAgent) && /Mobile/i.test(userAgent)

    return isiPhone || isAndroid
}

export function isiPhone() {
    const userAgent = navigator.userAgent

    // Check for iPhone
    return /iPhone/i.test(userAgent) && !/iPad/i.test(userAgent)
}

export function isDesktop() {}

export function useIsSmartphone() {
    return useMemo(() => isSmartphone(), [])
}

export function useDetectDevice(): 'iPhone' | 'android' | undefined {
    const userAgent = navigator.userAgent

    // Check for iPhone
    const isiPhone = /iPhone/i.test(userAgent) && !/iPad/i.test(userAgent)
    if (isiPhone) return 'iPhone'

    // Check for Android phone
    const isAndroid = /Android/i.test(userAgent) && /Mobile/i.test(userAgent)
    if (isAndroid) return 'android'

    return undefined
}
