import { interfaces } from 'inversify'
import { CreateMenuAction } from './CreateMenuAction'
import { ListMenuAction } from './ListMenuAction'
import { MenuStore } from './MenuStore'
import { RetrieveMenuAction } from './RetrieveMenuAction'
import { UpdateMenuAction } from './UpdateMenuAction'
import { UploadMediaMenuAction } from './UploadMediaMenuAction'

interface _MenuDependencies {
    Store: interfaces.ServiceIdentifier<MenuStore>
    List: interfaces.ServiceIdentifier<ListMenuAction>
    Retrieve: interfaces.ServiceIdentifier<RetrieveMenuAction>
    Create: interfaces.ServiceIdentifier<CreateMenuAction>
    Update: interfaces.ServiceIdentifier<UpdateMenuAction>
    UploadMedia: interfaces.ServiceIdentifier<UploadMediaMenuAction>
}

export const MenuDependencies: _MenuDependencies = {
    Store: Symbol('MenuStore'),
    List: Symbol('ListMenuAction'),
    Retrieve: Symbol('RetrieveMenuAction'),
    Create: Symbol('CreateMenuAction'),
    Update: Symbol('UpdateMenuAction'),
    UploadMedia: Symbol('UploadMediaMenuAction'),
}

export type {
    CreateMenuAction,
    ListMenuAction,
    MenuStore,
    RetrieveMenuAction,
    UpdateMenuAction,
    UploadMediaMenuAction,
}
