import { Box } from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { GoogleLoginButton } from 'react-social-login-buttons'
import TheUglyGoogleLogin from './TheUglyGoogleLogin'
import { CredentialResponse } from './types'

interface SignInWithGoogleProps {
    onClick: () => void
    onSuccess: (credentialResponse: CredentialResponse) => void
    onError?: () => void
    variant: 'continue' | 'signin' | 'signup'
}

export function SignInWithGoogleButton(props: SignInWithGoogleProps) {
    const t = useT('auth')

    return (
        <Box position="relative" w="full">
            <TheUglyGoogleLogin
                onSuccess={props.onSuccess}
                onError={() => console.warn('Google initialization failed')}
                width="320px"
                click_listener={props.onClick}
            />

            <GoogleLoginButton
                align="center"
                text={t(`social_button_${props.variant}_google`)}
                style={{
                    zIndex: 2,
                    position: 'absolute',
                    margin: 0,
                    top: -5,
                    left: 0,
                    borderRadius: 99,
                    width: 'var(--chakra-sizes-xs)',
                    pointerEvents: 'none',
                    border: '1px solid var(--chakra-colors-gray-300)',
                    boxShadow: 'none',
                    color: 'black',
                }}
            />
        </Box>
    )
}
