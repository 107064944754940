import {
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Select,
} from '@chakra-ui/react'
import { Address } from 'features/address/models/Address'
import useT from 'localization/hooks/useT'
import { Dispatch, SetStateAction } from 'react'

interface TaxIdInputProps {
    country: Address.Country
    taxId: string
    taxIdSuffix: 'IVA' | 'TVA' | 'MWST'
    setTaxId: Dispatch<SetStateAction<string>>
    setTaxIdSuffix: Dispatch<SetStateAction<'IVA' | 'TVA' | 'MWST'>>
}

export function TaxIdInput({ country, ...props }: TaxIdInputProps) {
    const t = useT('organization')

    return (
        <FormControl>
            <FormLabel>{t('tax_id_input_label')}</FormLabel>
            {country === 'CH' ? (
                <SwissInput {...props} />
            ) : country === 'IT' ? (
                <ItalianInput {...props} />
            ) : (
                <GenericInput {...props} />
            )}
        </FormControl>
    )
}

function SwissInput({
    taxId,
    taxIdSuffix,
    setTaxId,
    setTaxIdSuffix,
}: Omit<TaxIdInputProps, 'country'>) {
    return (
        <InputGroup>
            <InputLeftAddon children="CHE-" />
            <Input
                type="text"
                placeholder="123.456.789"
                autoComplete="off"
                maxLength={11}
                value={taxId}
                onChange={(e) => {
                    const value = e.target.value
                    if (value.length === 3 || value.length === 7) {
                        setTaxId(`${value}.`)
                    } else {
                        setTaxId(value)
                    }
                }}
            />
            <InputRightAddon px={0}>
                <Select
                    border="none"
                    borderLeftRadius={0}
                    value={taxIdSuffix}
                    onChange={(e) => {
                        const value = e.target.value
                        if (
                            value === 'IVA' ||
                            value === 'TVA' ||
                            value === 'MWST'
                        ) {
                            setTaxIdSuffix(value)
                        }
                    }}
                >
                    <option value="IVA">IVA</option>
                    <option value="TVA">TVA</option>
                    <option value="MWST">MWST</option>
                </Select>
            </InputRightAddon>
        </InputGroup>
    )
}

function ItalianInput({ taxId, setTaxId }: Omit<TaxIdInputProps, 'country'>) {
    return (
        <InputGroup>
            <InputLeftAddon children="IT" />
            <Input
                type="text"
                placeholder="00000000000"
                autoComplete="off"
                maxLength={11}
                value={taxId}
                onChange={(e) => setTaxId(e.target.value)}
            />
        </InputGroup>
    )
}

function GenericInput({ taxId, setTaxId }: Omit<TaxIdInputProps, 'country'>) {
    return (
        <InputGroup>
            <Input
                type="text"
                autoComplete="off"
                value={taxId}
                onChange={(e) => setTaxId(e.target.value)}
            />
        </InputGroup>
    )
}
