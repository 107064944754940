import { Menu } from 'features/menu/models/Menu'
import { MenuCompact } from 'features/menu/models/MenuCompact'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import { PropsWithChildren, useCallback, useState } from 'react'
import { MenuDependencies } from '../dependencies/menu'
import { MenusContext } from './MenusContext'

export default function MenusContextProvider(props: PropsWithChildren) {
    const store = useInjection(MenuDependencies.Store)
    const list = useInjection(MenuDependencies.List)

    const [isLoading, setIsLoading] = useState(false)
    const [menus, setMenus] = useState<MenuCompact[]>([])
    // const cache = useMemo(() => new Map<number, Menu>(), [])

    const load = useCallback(async (id: string) => {
        setIsLoading(true)
        store.clear()
        setMenus(await list.run({ organizationId: id }))
        setIsLoading(false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const clear = useCallback(() => {
        setIsLoading(false)
        setMenus([])
        store.clear()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const retrieve = useCallback((id: Menu.ID) => {
        const stored = store.retrieve(id.menuId)
        if (stored) return _.cloneDeep(stored)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function update(menu: Menu) {
        const safeMenu = _.cloneDeep(menu)
        store.save(safeMenu)
        setMenus((previous) => {
            if (
                previous.find((item) => item.id.menuId === safeMenu.id.menuId)
            ) {
                return previous.map((item) => {
                    if (item.id.menuId === safeMenu.id.menuId) {
                        return {
                            id: safeMenu.id,
                            name: safeMenu.name,
                            media: safeMenu.media,
                        }
                    } else {
                        return item
                    }
                })
            } else {
                return [
                    ...previous,
                    {
                        id: safeMenu.id,
                        name: safeMenu.name,
                        media: safeMenu.media,
                    },
                ]
            }
        })
    }

    return (
        <MenusContext.Provider
            value={{ menus, isLoading, load, retrieve, update, clear }}
        >
            {props.children}
        </MenusContext.Provider>
    )
}
