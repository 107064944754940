import { Box, Card, CardBody, CardFooter, Heading } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { PropsWithChildren, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface GridCardProps extends PropsWithChildren {
    link?: string
    onClick?: () => void
    name: string
    cover?: string
    isDisabled?: boolean
    minH?: string
}

export function GridCard({
    link,
    onClick,
    name,
    cover,
    isDisabled,
    children,
    minH = 'md',
}: GridCardProps) {
    const [isImageValid, setIsImageValid] = useState(true)

    useEffect(() => {
        if (!cover) {
            setIsImageValid(false)
            return
        }

        const image = new Image()
        image.src = cover

        image.onerror = () => {
            setIsImageValid(false)
        }

        return () => {
            image.onerror = null
        }
    }, [cover])

    return (
        <Box
            as={motion.div}
            whileHover={{ scale: isDisabled ? 1 : 1.05 }}
            whileFocus={{ scale: isDisabled ? 1 : 1.05 }}
            whileTap={{ scale: 1 }}
            minH={minH}
            borderRadius="md"
            overflow="hidden"
            float="left"
            display="inline-block"
            boxShadow="base"
            position="relative"
        >
            <Box
                h="full"
                as={isDisabled || !link ? undefined : Link}
                to={link}
                onClick={onClick}
            >
                <Card
                    as={motion.div}
                    bg="pageBackground3"
                    bgImage={cover}
                    objectFit="cover"
                    w="full"
                    h="100%"
                    bgSize="cover"
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    whileHover={{ scale: isDisabled ? 1 : 1.03 }}
                    whileFocus={{ scale: isDisabled ? 1 : 1.03 }}
                    whileTap={{ scale: 1 }}
                >
                    <CardBody />
                    <CardFooter
                        backdropFilter="blur(10px)"
                        m={4}
                        borderColor={isImageValid ? 'white' : 'label1'}
                        borderWidth="thin"
                        borderRadius="md"
                        boxShadow="md"
                        bg="blackAlpha.300"
                    >
                        <Heading
                            color={isImageValid ? 'white' : 'label1'}
                            size="md"
                            fontWeight="semibold"
                            noOfLines={1}
                        >
                            {name}
                        </Heading>
                    </CardFooter>
                </Card>
            </Box>

            {children}
        </Box>
    )
}
