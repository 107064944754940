import {
    Button,
    Divider,
    HStack,
    Input,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from '@chakra-ui/react'

import { Fragment } from 'react'
import { FaInfinity, FaPlus, FaTimes } from 'react-icons/fa'

import { Weekdays } from '../models/Calendar'

import useT from 'localization/hooks/useT'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { Day, days } from '../models/Days'
import { formatTime } from '../utils'
import FullDayLabel from './FullDayLabel'
import { Labels, ScheduleFormProps } from './ScheduleForm'
import { ScheduleFormQuickActionButtonsMobile } from './ScheduleFormQuickActionButtonts'
import SelectedDaysLabel from './SelectedDaysLabel'
import { WeekDayOverview } from './WeekDayOverview'

export function ScheduleFormMobile({
    labels,
    formData,
    checkedOpen,
}: ScheduleFormProps & {
    checkedOpen: boolean
}) {
    const t = useT('timeschedule')

    return (
        <Tabs variant="soft-rounded" w="full">
            <TabList w="full" justifyContent="center">
                <Tab color="label2">
                    {t('weekly_schedule_mobile_overview_button')}
                </Tab>
                <Tab color="label2">
                    {t('weekly_schedule_mobile_edit_button')}
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <VStack w="full">
                        {days.map((day, index) => (
                            <Fragment key={day}>
                                <WeekDayOverview
                                    labels={labels}
                                    weekdays={formData.weekdays}
                                    day={day}
                                />
                                {index !== days.length - 1 && <Divider />}
                            </Fragment>
                        ))}
                    </VStack>
                </TabPanel>
                <TabPanel>
                    <VStack>
                        <Menu closeOnSelect={false} matchWidth>
                            {({ onClose }) => (
                                <>
                                    <MenuButton
                                        as={Button}
                                        minW="fit-content"
                                        borderRadius="full"
                                        variant="outline"
                                    >
                                        {t(
                                            'weekly_schedule_mobile_days_selector_menu_button'
                                        )}
                                    </MenuButton>
                                    <MenuList w="full">
                                        <ScheduleFormQuickActionButtonsMobile
                                            onClose={onClose}
                                            selectedDays={formData.selectedDays}
                                            setSelectedDays={
                                                formData.setSelectedDays
                                            }
                                        />
                                        <MenuDivider />
                                        <MenuOptionGroup
                                            title={t(
                                                'weekly_schedule_mobile_days_selector_days_menu_option_group'
                                            )}
                                            type="checkbox"
                                            value={formData.selectedDays}
                                        >
                                            {days.map((day) => (
                                                <MenuItemOption
                                                    key={day}
                                                    value={day}
                                                    onClick={() => {
                                                        formData.toggleDay(day)
                                                    }}
                                                >
                                                    {t(
                                                        `generic_day_name_${day}`
                                                    )}
                                                </MenuItemOption>
                                            ))}
                                        </MenuOptionGroup>
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                        <SelectedDaysLabel days={formData.selectedDays} />

                        <FullDayLabel
                            labels={labels}
                            isCheckedOpen={checkedOpen}
                            timeRangesCount={formData.ranges.length}
                            selectedDaysCount={formData.selectedDays.length}
                        />

                        {formData.ranges.length > 0 && (
                            <HStack w="full" gap={4}>
                                <Text flex={1}>{labels.timeRangeFrom}</Text>
                                <Text flex={1}>{labels.timeRangeTo}</Text>
                            </HStack>
                        )}

                        {formData.ranges.map((range, index) => (
                            <VStack mt={4} key={index} w="full">
                                <HStack w="full" gap={4}>
                                    <Input
                                        type="time"
                                        value={formatTime(range.from)}
                                        onChange={(e) => {
                                            const value = e.target.valueAsNumber
                                            if (!isNaN(value)) {
                                                formData.editRange(
                                                    index,
                                                    value,
                                                    undefined
                                                )
                                            }
                                        }}
                                    />
                                    <Input
                                        type="time"
                                        value={formatTime(range.to)}
                                        onChange={(e) => {
                                            const value = e.target.valueAsNumber
                                            if (!isNaN(value)) {
                                                formData.editRange(
                                                    index,
                                                    undefined,
                                                    value
                                                )
                                            }
                                        }}
                                    />
                                </HStack>
                                <Button
                                    alignSelf="end"
                                    color=""
                                    variant="link"
                                    onClick={() => formData.removeRange(index)}
                                >
                                    {t(
                                        'week_schedule_remove_time_range_button'
                                    )}
                                </Button>
                            </VStack>
                        ))}

                        <ActionButtons
                            checkedOpen={checkedOpen}
                            labels={labels}
                            ranges={formData.ranges}
                            selectedDays={formData.selectedDays}
                            onMarkFullDayClick={formData.markFullDay}
                            onAddRangeClick={formData.addRange}
                        />
                    </VStack>
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

interface ActionButtonsProps {
    selectedDays: Day[]
    ranges: Weekdays.TimeRangeSchedule.Range[]
    checkedOpen: boolean
    labels: Labels
    onAddRangeClick: () => void
    onMarkFullDayClick: (b: boolean) => void
}

function ActionButtons({
    selectedDays,
    ranges,
    checkedOpen,
    labels,
    onMarkFullDayClick,
    onAddRangeClick,
}: ActionButtonsProps) {
    const t = useT('timeschedule')
    const screen = useScreenBreakpoint()
    if (selectedDays.length === 0 || ranges.length >= 3) {
        return null
    }

    return (
        <Stack
            direction={screen === 'mobile' ? 'column' : 'row'}
            w="full"
            justify="center"
            mt={4}
            gap={4}
        >
            <Button
                variant="outline"
                flex={screen !== 'mobile' ? 1 : undefined}
                borderRadius="full"
                leftIcon={checkedOpen ? <FaTimes /> : <FaInfinity />}
                onClick={() => {
                    if (checkedOpen) {
                        onMarkFullDayClick(false)
                    } else {
                        onMarkFullDayClick(true)
                    }
                }}
            >
                {!checkedOpen && labels.fullDayActive}
                {checkedOpen && labels.fullDayInactive}
            </Button>

            <Button
                variant="outline"
                flex={screen !== 'mobile' ? 1 : undefined}
                borderRadius="full"
                leftIcon={<FaPlus />}
                onClick={onAddRangeClick}
            >
                {t('week_schedule_add_time_range_button')}
            </Button>
        </Stack>
    )
}
