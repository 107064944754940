import { days } from '.'
import { Schedule, Weekdays } from '../models/Calendar'

export function isEmpty(schedule: Schedule): boolean {
    return days.every((day) => dayScheduleIsEmpty(schedule[day]))
}

function dayScheduleIsEmpty(day: Weekdays.DaySchedule): boolean {
    if (day.type === 'full-day') {
        return !day.isActive
    } else {
        return day.timeRanges.length === 0
    }
}
