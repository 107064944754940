import { createContext, useContext } from 'react'
import { ExpiredSession, User } from '../models/User'

interface _AuthContext {
    user?: User
    expiredSession?: ExpiredSession
    signIn: (email: string, password: string) => Promise<void>
    socialSignIn: (
        idToken: string,
        locale: string,
        zoneInfo: string,
        givenName?: string,
        familyName?: string
    ) => Promise<void>
    signOut: () => Promise<void>
    signUp: (
        email: string,
        password: string,
        locale: string,
        zoneInfo: string,
        givenName?: string,
        familyName?: string
    ) => Promise<void>
    confirmSignUp: (confirmationCode: string) => Promise<void>
    passwordForgotten: (email: string) => Promise<void>
    confirmPasswordForgotten: (
        password: string,
        confirmationCode: string
    ) => Promise<void>
}

const defaultAuthContext: _AuthContext = {
    user: undefined,
    expiredSession: undefined,
    async signIn() {},
    async socialSignIn() {},
    async signOut() {},
    async signUp() {},
    async confirmSignUp() {},
    async passwordForgotten() {},
    async confirmPasswordForgotten() {},
}

export const AuthContext = createContext(defaultAuthContext)
export default function useAuthContext() {
    return useContext(AuthContext)
}
