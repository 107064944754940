import Avokado, {
    AvokadoAPIError,
    TranslationObjectList,
} from '@avokadoapp/avokado-ts'
import { Menu } from 'features/menu/models/Menu'
import { TranslationObject } from 'features/menu/models/TranslationObject'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface ListTranslationObjectParams extends Menu.ID {
    locale: string
}

export interface ListTranslationObjectAction {
    run(params: ListTranslationObjectParams): Promise<TranslationObject[]>
}

@injectable()
export class ListTranslationObjectActionLive
    implements ListTranslationObjectAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(
        params: ListTranslationObjectParams
    ): Promise<TranslationObject[]> {
        const request: TranslationObjectList.Request = params
        const response = await this.avokado.translationObject.list(request)
        return response.translationObjects.map((tr) => ({
            ...tr,
            id: {
                organizationId: params.organizationId,
                translationObjectId: tr.id,
            },
        }))
    }
}

@injectable()
export class ListTranslationObjectActionFake
    implements ListTranslationObjectAction
{
    async run(
        params: ListTranslationObjectParams
    ): Promise<TranslationObject[]> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class ListTranslationObjectActionFail
    implements ListTranslationObjectAction
{
    async run(
        params: ListTranslationObjectParams
    ): Promise<TranslationObject[]> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
