import { Location } from 'features/location/models/Location'
import useMenusContext from 'features/menu/contexts/MenusContext'
import { MenuDependencies } from 'features/menu/dependencies/menu'
import { Menu } from 'features/menu/models/Menu'
import { MenuCompact } from 'features/menu/models/MenuCompact'
import { isValid } from 'features/menu/services/isValid'
import { MenuAssignmentDependencies } from 'features/menuassignment/dependencies'
import { MenuAssignment } from 'features/menuassignment/models/MenuAssignment'
import { useInjection } from 'inversify-react'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useAsync } from 'react-use'

export interface CreateMenuAssignmentController {
    activeStep: number
    setSelectedCompactMenu: Dispatch<SetStateAction<MenuCompact | undefined>>
    menus: MenuCompact[]
    extendedMenu?: Menu
    isActive: boolean
    setIsActive: Dispatch<SetStateAction<boolean>>
    create: () => void
    isCreating: boolean
    reset: () => void
    location: Location
}

export function useCreateMenuAssignmentController(
    location: Location,
    onCreated: (m: MenuAssignment) => void
): CreateMenuAssignmentController {
    const [selectedCompactMenu, setSelectedCompactMenu] =
        useState<MenuCompact>()
    const [isActive, setIsActive] = useState(false)
    const [isCreating, setIsCreating] = useState(false)

    const retrieveMenu = useInjection(MenuDependencies.Retrieve)
    const createMenuAssignment = useInjection(MenuAssignmentDependencies.Create)

    const menusManager = useMenusContext()

    const [isCompleted, setIsCompleted] = useState(false)

    const selectedMenu = useAsync(async () => {
        if (selectedCompactMenu === undefined) {
            return undefined
        }
        try {
            return retrieveMenu.run(selectedCompactMenu.id)
        } catch (e: unknown) {
            return undefined
        }
    }, [selectedCompactMenu])

    useEffect(() => {
        if (selectedMenu.value) {
            const canBeActivated = isValid(selectedMenu.value)
            setIsActive(canBeActivated)
        }
    }, [selectedMenu])

    const create = useCallback(async () => {
        if (isCreating || !selectedCompactMenu?.id) return
        setIsCreating(true)
        try {
            const newAssignment = await createMenuAssignment.run({
                organizationId: location.id.organizationId,
                locationId: location.id.locationId,
                availabilities: 'always',
                incompatibleMenuIds: [],
                isActive: isActive,
                menuId: selectedCompactMenu.id.menuId,
                restrictions: [],
            })
            onCreated(newAssignment)
            setIsCompleted(true)
        } catch (e: unknown) {
            console.log(e)
        } finally {
            setIsCreating(false)
        }
    }, [isActive, selectedCompactMenu?.id.menuId])

    function reset() {
        setSelectedCompactMenu(undefined)
        setIsCompleted(false)
    }

    return {
        activeStep: isCompleted ? 2 : selectedCompactMenu === undefined ? 0 : 1,
        setSelectedCompactMenu,
        menus: menusManager.menus.filter(
            (menu) =>
                !location.menuAssignments.some(
                    (m) => m.id.menuId === menu.id.menuId
                )
        ),
        extendedMenu: selectedMenu.value,
        isActive,
        setIsActive,
        isCreating,
        create,
        reset,
        location,
    }
}

// export type StepProps = {
//     context: CreateMenuAssignmentController
//     headerRef: RefObject<HTMLDivElement>
// }
