import { Location } from 'features/location/models/Location'

export function describeCapability(capability: Location.Capability) {
    switch (capability) {
        case Location.Capability.AnimalFriendly:
            return 'capability_animal_friendly'
        case Location.Capability.CarParking:
            return 'capability_car_parking'
        case Location.Capability.FreeWiFi:
            return 'capability_free_wifi'
        case Location.Capability.AreaKids:
            return 'capability_area_kids'
        case Location.Capability.OpenAir:
            return 'capability_open_air'
        case Location.Capability.ReceptionEvents:
            return 'capability_reception_events'
        case Location.Capability.VeganFriendly:
            return 'capability_vegan_friendly'
        case Location.Capability.MotorImpairmentAccessible:
            return 'capability_motor_impairment_accessible'
    }
}
