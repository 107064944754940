import { SVGProps } from 'react'

export function MustardIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Mustard"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M99.19.13c22.1-.79 41.41 4.85 58.01 16.75 18.78 13.46 31.83 31.21 38.28 53.08 8.93 30.27 4.55 58.92-12.54 85.45-8.79 13.65-21.05 24.28-36.09 32.38-27.52 14.81-55.83 15.93-84.17 4.58-24.65-9.87-42.77-27.12-53.41-51.02-10.09-22.7-12.18-46.07-5.1-69.57C13.5 40.81 32.8 17.44 65.29 5.5 76.51 1.38 88.17-.53 99.19.13zm43.14 74.88c0-10.8-.02-19.13 0-27.47.01-3.67-1.23-6.05-5.67-6.03-14.21.07-28.42.05-42.63.02-3.25 0-5.18 1.09-5.17 4.49.03 19.26 0 38.52.03 57.78 0 2.55.73 5.07 3.93 5.32 4.12.32 5.13 2.25 4.8 5.91-.27 3.06.49 6.49 4.68 6.33 6.41-.24 7.11 3.47 7.62 8.12.78 7.17 1.93 14.31 3.11 21.43.24 1.47.05 3.91 2.36 3.88 1.96-.03 1.63-2.29 1.87-3.62 1.49-8.2 2.93-16.42 4.16-24.66.39-2.65.9-4.96 4.24-4.84 5.75.2 7.52-2.81 7.39-7.65-.07-2.47.68-4.44 3.74-4.64 3.86-.25 4.38-2.71 4.78-5.76 1.36-10.35.49-20.72.76-28.61zm-27.99 87.91c-.76-.82-1.32-1.54-2.01-2.14-4.02-3.52-7.53-6.38-13.5-1.86-3.67 2.78-8.48-6.42-13.61-.83-1.9 2.07-3.64 2.11-5.84.03-2.76-2.61-5.91-2.52-8.76.11-1.53 1.41-3.12 2.54-4.99.77-4.68-4.44-8.57-2.81-12.36 1.15-1.24 1.29-3.8 2.2-1.83 4.34 1.55 1.69 3.77.98 5.21-.39 2.38-2.26 4.33-2.62 6.84-.1 2.66 2.68 5.77 2.08 8.27-.22 2.26-2.07 3.79-2.39 6.21-.08 2.39 2.28 5.72 2.84 8.39.36 2.54-2.36 4.47-2.07 7.01-.11 2.21 1.71 5.22 2.66 7.48.56 3.08-2.86 5.51-2.65 8.39-.01 1 .91 2.51 1.15 3.79.3.55-.37.82-1.12 1.33-1.88z" />
            <path d="M115.85 60.13c4.48 0 8.98.28 13.42-.08 4.01-.32 5.29 1.34 5.23 4.75-.3 17.53 2.84 16.64-16.26 16.73-5.64.03-11.29-.07-16.93-.22-3.73-.1-5.55-1.89-5.52-5.63.12-18.51-1.83-15.08 15.38-15.41 1.56-.03 3.11 0 4.67 0v-.12z" />
        </svg>
    )
}
