import Avokado, { AvokadoAPIError, RoomCreate } from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Geometry } from 'utils/geometry/Geometry'
import { mapRoomToModel } from '../mappers/SDKResponseMapper'
import { Room } from '../models/Room'

interface CreateRoomParams extends Location.ID {
    name: string
    size: Geometry.Size
}

export interface CreateRoomAction {
    run(params: CreateRoomParams): Promise<Room>
}

@injectable()
export class CreateRoomActionLive implements CreateRoomAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: CreateRoomParams): Promise<Room> {
        const request: RoomCreate.Request = {
            organizationId: params.organizationId,
            locationId: params.locationId,
            name: params.name,
            size: params.size,
            isOpen: true,
        }
        const response = await this.avokado.room.create(request)
        return mapRoomToModel(response)
    }
}

@injectable()
export class CreateRoomActionFake implements CreateRoomAction {
    async run(params: CreateRoomParams): Promise<Room> {
        await sleep(2000)
        return {
            id: {
                organizationId: params.organizationId,
                locationId: params.locationId,
                roomId: 1,
            },
            name: params.name,
            size: params.size,
            isOpen: true,
            tables: [],
        }
    }
}

@injectable()
export class CreateRoomActionFail implements CreateRoomAction {
    async run(params: CreateRoomParams): Promise<Room> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
