import useT from 'localization/hooks/useT'
import { useLocalStorage } from 'react-use'
import { Container } from 'uikit/container/Container'
import LocalStorageKeys from 'utils/constant/LocalStorageKeys'
import useFormNotification from 'utils/hooks/useFormNotification'
import {
    usePolicyFormController,
    usePolicyFormData,
} from '../hooks/policy/usePolicyForm'
import { LocationTabProps } from '../pages/LocationPage'
import { DeliveryForm } from './policy/DeliveryForm'
import PolicyHelperAlert from './policy/PolicyHelperAlert'
import { PolicyIcon } from './policy/PolicyIcon'
import { PolicySection, PolicySectionProps } from './policy/PolicySection'
import { ReservationForm } from './policy/ReservationForm'
import { TableForm } from './policy/TableForm'
import { TakeAwayForm } from './policy/TakeAwayForm'

export function PolicyTab({ location, update }: LocationTabProps) {
    const t = useT('policy')

    const formData = usePolicyFormData(location.policy)
    const controller = usePolicyFormController(location, formData, update)

    const [shouldHideAlert, setShouldHideAlert] = useLocalStorage<boolean>(
        LocalStorageKeys.HideLocationPolicyAlertHelper
    )

    const sections: PolicySectionProps[] = [
        {
            name: t('table_section_title'),
            description: t('table_section_description'),
            icon: <PolicyIcon policy="table" />,
            content: <TableForm formData={formData.table} />,
            isChecked: formData.table.isEnabled,
            onToggle: formData.table.setIsEnabled,
            isAvailable: true,
            hasChanges: formData.table.hasChanges,
        },
        {
            name: t('reservation_section_title'),
            description: t('reservation_section_description'),
            icon: <PolicyIcon policy="reservation" />,
            content: <ReservationForm formData={formData.reservation} />,
            isChecked: formData.reservation.isEnabled,
            onToggle: formData.reservation.setIsEnabled,
            isAvailable: false,
            hasChanges: formData.reservation.hasChanges,
        },
        {
            name: t('delivery_section_title'),
            description: t('delivery_section_description'),
            icon: <PolicyIcon policy="delivery" />,
            content: <DeliveryForm formData={formData.delivery} />,
            isChecked: formData.delivery.isEnabled,
            onToggle: formData.delivery.setIsEnabled,
            isAvailable: true,
            hasChanges: formData.delivery.hasChanges,
        },
        {
            name: t('take_away_section_title'),
            description: t('take_away_section_description'),
            icon: <PolicyIcon policy="take-away" />,
            content: <TakeAwayForm formData={formData.takeAway} />,
            isChecked: formData.takeAway.isEnabled,
            onToggle: formData.takeAway.setIsEnabled,
            isAvailable: true,
            hasChanges: formData.takeAway.hasChanges,
        },
    ]

    useFormNotification('policy', controller)

    return (
        <>
            <PolicyHelperAlert
                isVisible={!shouldHideAlert}
                onClose={() => setShouldHideAlert(true)}
            />
            {sections.map((section, index) => (
                <Container key={section.name} pt={index === 0 ? 0 : 8}>
                    <PolicySection
                        key={section.name}
                        {...section}
                        isSaving={controller.isProcessing}
                        onSaveChanges={controller.submit}
                    />
                </Container>
            ))}
        </>
    )
}
