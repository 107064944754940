import { Grid, VStack } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

export function FormStructure({ children }: PropsWithChildren) {
    const screen = useScreenBreakpoint()

    if (screen === 'mobile') {
        return (
            <VStack w="full" align="stretch">
                {children}
            </VStack>
        )
    }

    return (
        <VStack w="full">
            <Grid w="full" templateColumns={'repeat(3, 1fr)'} gap={10}>
                {children}
            </Grid>
        </VStack>
    )
}
