import { InferType, object, string } from 'yup'

export interface Codes {
    title: string /* Scan. Order. Pay. */
    subtitle: string /* Powered by Avokado */
    location: string
    table: string
    appclip: string
    qrcode: string
    ios: string /* iOS */
    android: string /* Android */
}

export const codesTemplateValuesSchema = object({
    backgroundColor: string()
        .required()
        .matches(/^#[A-F0-9]{8}$/),
    textColor: string()
        .required()
        .matches(/^#[A-F0-9]{6}$/),
    locationNameColor: string()
        .required()
        .matches(/^#[A-F0-9]{6}$/),
})

export type CodesTemplateValues = InferType<typeof codesTemplateValuesSchema>

export const DEFAULT_CODES_TEMPLATE_VALUES: CodesTemplateValues = {
    backgroundColor: '#FFFFFFFF',
    textColor: '#000000',
    locationNameColor: '#000000',
}
