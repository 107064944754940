import { Schedule } from 'features/calendar/models/Calendar'
import { isEmpty, normalized, subtract } from 'features/calendar/utils'
import { MenuAssignment } from 'features/menuassignment/models/MenuAssignment'
import { Location } from '../models/Location'

export function computeLocationViolations(
    location: Omit<Location, 'violations'>
): Set<Location.Violation> {
    const violations = new Set<Location.Violation>()

    if (location.medias.length === 0) {
        violations.add('empty-gallery')
    }

    if (location.menuAssignments.length === 0) {
        violations.add('no-menu-assigned')
    }

    if (isEmpty(location.calendar.weekdays)) {
        violations.add('calendar-always-closed')
    } else if (
        !isFullyCoveredByAssignments(
            location.calendar.weekdays,
            location.policy,
            location.menuAssignments
        )
    ) {
        violations.add('calendar-uncovered-schedule')
    }

    if (location.category === undefined) {
        violations.add('missing-category')
    }

    if (location.capacity === undefined) {
        violations.add('missing-capacity')
    }

    if (
        location.policy.table === undefined &&
        location.policy.reservation === undefined &&
        location.policy.takeAway === undefined &&
        location.policy.delivery === undefined
    ) {
        violations.add('missing-policies')
    }

    // if location.coordinate == nil {
    //     violations.append("coordinate.undefined")
    // }

    if (location.address === undefined) {
        violations.add('missing-address')
    } else if (
        !location.address.street ||
        !location.address.postalCode ||
        !location.address.region
    ) {
        violations.add('invalid-address')
    }

    return violations
}

function isFullyCoveredByAssignments(
    schedule: Schedule,
    policy: Location.Policy,
    menuAssignments: MenuAssignment[]
) {
    const activeMenuAssignments = menuAssignments.filter(
        (menuAssignment) => menuAssignment.isActive
    )
    const intervals = normalized(schedule)

    if (
        policy.delivery &&
        isPolicyUncovered(intervals, activeMenuAssignments, 'delivery')
    ) {
        return false
    }

    if (
        policy.takeAway &&
        isPolicyUncovered(intervals, activeMenuAssignments, 'take-away')
    ) {
        return false
    }

    if (
        policy.table &&
        isPolicyUncovered(intervals, activeMenuAssignments, 'table')
    ) {
        return false
    }

    return true
}

function isPolicyUncovered(
    intervals: [number, number][],
    menuAssignments: MenuAssignment[],
    restriction: MenuAssignment.Restriction
) {
    const assignments = menuAssignments.filter(
        (assignment) => !assignment.restrictions.includes(restriction)
    )

    if (
        menuAssignments.some(
            (assignment) => assignment.availabilities === 'always'
        )
    ) {
        return false
    }

    const coveredIntervals = normalized(
        ...assignments.map(
            (assignment) => assignment.availabilities as Schedule
        )
    )

    const unconveredIntervals = subtract(intervals, coveredIntervals)
    return unconveredIntervals.length > 0
}
