import { SVGProps } from 'react'

export function CerealsIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Cereals"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M192.65 62.61c-9.41-24.38-26.61-42.09-50.57-53.69C123.05-.3 102.39-1.69 81.44 1.66c-12.25 1.96-23.99 6-34.08 12.62-12.49 8.2-23.06 18.36-31.27 30.94-5.66 8.67-9.91 17.79-12.44 27.31-6.05 22.75-4.61 45.15 4.5 67.46 13.89 34.03 52.27 60.06 88.37 60.01 25-.46 45.39-6.52 63.32-19.98 12.86-9.66 22.84-21.36 29.58-35.35 12.93-26.84 14.01-54.15 3.23-82.05zm-66.99-2.35c6.06-3.78 7.59-2.86 7.77 4.45.02.78 0 1.56 0 2.33-.11 13.86-12.62 27.47-26.41 28.9-3 .31-3.95-1.16-3.9-3.65.16-9.18 1.51-17.92 9.91-23.65 4.17-2.85 8.35-5.71 12.63-8.39zm-59.09 1.83c.2-3.78 2.11-4.84 5.44-3.13 6.61 3.39 13.06 7.2 18.25 12.45 5.81 5.89 6.72 13.78 6.46 21.72-.08 2.5-1.77 3.48-4.06 2.7-15.16-5.15-26.1-11.63-26.11-29.08 0-1.56-.07-3.11.01-4.67zm25.05 104.45c-3.93-1.71-8-3.2-11.73-5.29-10.83-6.07-15.68-17.07-13.31-29.3.38-1.98.8-3.24 3.37-2.65 13.97 3.2 26.98 19.18 26.86 33.18.09 3.67-.98 5.9-5.19 4.07zm.27-34.72c-9.04-2.87-16.58-7.54-21.9-15.52-4.32-6.48-3.75-13.71-3.27-20.9.15-2.23 1.54-2.75 3.74-2.18 13.33 3.43 26.41 20.65 26.39 35.3.55 3.77-.87 4.61-4.96 3.31zm-.15-70.19c-5.07-9.09-2.46-22.36 5.34-28.96 1.99-1.68 3.84-1.93 5.66-.03 4.89 5.1 8.15 10.95 8.04 19.04-.52 2.85-.62 6.6-2.57 9.92-2.02 3.42-4.35 7.29-8.51 7.17-3.83-.11-6.17-3.93-7.96-7.15zm41.71 76.01c-.03 14.84-12.41 28.38-26.95 29.64-3.91.34-3.44-2.42-3.36-4.61.37-10.35 2.99-19.61 12.81-25.22 3.71-2.12 7.24-4.55 10.99-6.58 5.26-2.84 6.2-2.21 6.5 3.86.05.97 0 1.94 0 2.91zm-26.39-5.7c-2.96.82-3.97-.64-3.98-3.3 0-8.41 1.29-16.27 8.06-22.34 4.65-4.17 9.28-8.24 14.85-11.25 6.35-3.43 7.23-3.09 7.45 4.04.02.78 0 1.56 0 1.96 1.58 16.61-13.26 27.26-26.38 30.88z" />
        </svg>
    )
}
