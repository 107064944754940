import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'

interface MoreInfoTooltipProps {
    title?: string
    moreInfo: string
}

export default function MoreInfoTooltip({
    title,
    moreInfo,
}: MoreInfoTooltipProps) {
    const t = useT()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Tooltip label={moreInfo} fontSize="md">
                <Text
                    as="span"
                    color="accent"
                    fontWeight="semibold"
                    onClick={onOpen}
                >
                    {t('more_info')}
                </Text>
            </Tooltip>
            <Modal
                onClose={onClose}
                size="full"
                isOpen={isOpen}
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    {title && <ModalHeader>{title}</ModalHeader>}
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>{moreInfo}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>{t('close_button')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
