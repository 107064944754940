import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Input,
    Textarea,
    VStack,
    useConst,
} from '@chakra-ui/react'
import { ImageUploader } from 'features/media/components/ImageUploader'
import { UseMediaUploadParams } from 'features/media/hooks/useMediaUpload'
import {
    useMenuHeadingFormController,
    useMenuHeadingFormData,
} from 'features/menu/hooks/useMenuHeadingForm'
import useT from 'localization/hooks/useT'
import { Container } from 'uikit/container/Container'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import { MenuPageContentProps } from '../pages/MenuPage'
import { MenuImageUploaderRenderer } from './MenuImageUploaderRenderer'

export function MenuHeading(props: MenuPageContentProps) {
    const screen = useScreenBreakpoint()

    if (screen === 'mobile') {
        return <MenuHeadingMobile {...props} />
    } else {
        return <MenuHeadingDesktop {...props} />
    }
}

function MenuHeadingDesktop({ menu, update }: MenuPageContentProps) {
    const t = useT('menu')

    const formData = useMenuHeadingFormData(menu)
    const controller = useMenuHeadingFormController(menu, formData, update)

    const config: UseMediaUploadParams = useConst(() => ({
        automaticReset: true,
        automaticUploadMediaAction: controller.buildMediaUploadLink,
        onUploadingStatusChanged: formData.setIsUploadingCover,
        onUploadEnded: formData.setRawMedia,
    }))

    return (
        <Container>
            <HStack w="full" position="relative" align="stretch" spacing={8}>
                <ImageUploader
                    config={config}
                    renderer={({ status, dropzone }) => (
                        <MenuImageUploaderRenderer
                            bg="pageBackground3"
                            currentMedia={menu.media}
                            status={status}
                            dropzone={dropzone}
                        />
                    )}
                />
                <VStack w="full" align="stretch" spacing={8}>
                    <FormControl>
                        <FormLabel>
                            {t('menu_heading_form_name_label')}
                        </FormLabel>
                        <Input
                            type="text"
                            placeholder={menu.name}
                            variant="filled"
                            size="lg"
                            maxLength={50}
                            value={formData.name}
                            onChange={(e) => formData.setName(e.target.value)}
                        />
                        <FormHelperText>
                            {t('menu_heading_form_name_helper')}
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>
                            {t('menu_heading_form_description_label')}
                        </FormLabel>
                        <Textarea
                            resize="none"
                            variant="filled"
                            placeholder={t(
                                'menu_heading_form_description_placeholder'
                            )}
                            maxLength={50}
                            value={formData.description}
                            onChange={(e) =>
                                formData.setDescription(e.target.value)
                            }
                        />
                        <FormHelperText>
                            {t('menu_heading_form_description_helper')}
                        </FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            {t('menu_heading_form_footer_label')}
                        </FormLabel>
                        <Textarea
                            resize="none"
                            placeholder={t(
                                'menu_heading_form_footer_placeholder'
                            )}
                            variant="filled"
                            maxLength={500}
                            value={formData.footer}
                            onChange={(e) => formData.setFooter(e.target.value)}
                        />
                        <FormHelperText>
                            {t('menu_heading_form_footer_helper')}
                        </FormHelperText>
                    </FormControl>

                    <Button
                        alignSelf="end"
                        variant="ghost"
                        isLoading={controller.isProcessing}
                        isDisabled={
                            !controller.canSubmit || !formData.hasChanges
                        }
                        onClick={controller.submit}
                    >
                        {t('generic_save_button')}
                    </Button>
                </VStack>
            </HStack>
        </Container>
    )
}

function MenuHeadingMobile({ menu, update }: MenuPageContentProps) {
    const t = useT('menu')

    const formData = useMenuHeadingFormData(menu)
    const controller = useMenuHeadingFormController(menu, formData, update)

    const config: UseMediaUploadParams = useConst(() => ({
        automaticReset: true,
        automaticUploadMediaAction: controller.buildMediaUploadLink,
        onUploadingStatusChanged: formData.setIsUploadingCover,
        onUploadEnded: formData.setRawMedia,
    }))

    return (
        <VStack w="full" position="relative" align="stretch" spacing={8}>
            <ImageUploader
                config={config}
                renderer={({ status, dropzone }) => (
                    <MenuImageUploaderRenderer
                        bg="pageBackground3"
                        currentMedia={menu.media}
                        status={status}
                        dropzone={dropzone}
                    />
                )}
            />
            <VStack w="full" align="stretch" spacing={8}>
                <FormControl>
                    <FormLabel>{t('menu_heading_form_name_label')}</FormLabel>
                    <Input
                        type="text"
                        placeholder={menu.name}
                        variant="filled"
                        size="lg"
                        maxLength={50}
                        value={formData.name}
                        onChange={(e) => formData.setName(e.target.value)}
                    />
                    <FormHelperText>
                        {t('menu_heading_form_name_helper')}
                    </FormHelperText>
                </FormControl>

                <FormControl>
                    <FormLabel>
                        {t('menu_heading_form_description_label')}
                    </FormLabel>
                    <Textarea
                        resize="none"
                        variant="filled"
                        placeholder={t(
                            'menu_heading_form_description_placeholder'
                        )}
                        maxLength={50}
                        value={formData.description}
                        onChange={(e) =>
                            formData.setDescription(e.target.value)
                        }
                    />
                    <FormHelperText>
                        {t('menu_heading_form_description_helper')}
                    </FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel>{t('menu_heading_form_footer_label')}</FormLabel>
                    <Textarea
                        resize="none"
                        placeholder={t('menu_heading_form_footer_placeholder')}
                        variant="filled"
                        maxLength={500}
                        value={formData.footer}
                        onChange={(e) => formData.setFooter(e.target.value)}
                    />
                    <FormHelperText>
                        {t('menu_heading_form_footer_helper')}
                    </FormHelperText>
                </FormControl>

                <Button
                    alignSelf="end"
                    variant="ghost"
                    isLoading={controller.isProcessing}
                    isDisabled={!controller.canSubmit || !formData.hasChanges}
                    onClick={controller.submit}
                >
                    {t('generic_save_button')}
                </Button>
            </VStack>
        </VStack>
    )
}
