import { interfaces } from 'inversify'
import { BuyTranslationBundleAction } from './BuyTranslationBundleAction'
import { CreateTranslatedMenuAction } from './CreateTranslatedMenuAction'
import { ListTranslationBundlesAction } from './ListTranslationBundlesAction'
import { ListTranslationObjectAction } from './ListTranslationObjectAction'
import { UpdateTranslatedMenuVisibilityAction } from './UpdateTranslatedMenuVisibilityAction'
import { UpdateTranslationObjectAction } from './UpdateTranslationObjectAction'

interface _MenuTranslationDependencies {
    Create: interfaces.ServiceIdentifier<CreateTranslatedMenuAction>
    ListObjects: interfaces.ServiceIdentifier<ListTranslationObjectAction>
    UpdateObject: interfaces.ServiceIdentifier<UpdateTranslationObjectAction>
    UpdateTranslatedMenuVisibility: interfaces.ServiceIdentifier<UpdateTranslatedMenuVisibilityAction>
    ListTranslationBundles: interfaces.ServiceIdentifier<ListTranslationBundlesAction>
    BuyTranslationBundle: interfaces.ServiceIdentifier<BuyTranslationBundleAction>
}

export const MenuTranslationDependencies: _MenuTranslationDependencies = {
    Create: Symbol('CreateTranslatedMenuAction'),
    ListObjects: Symbol('ListTranslationObjectAction'),
    UpdateObject: Symbol('UpdateTranslationObjectAction'),
    UpdateTranslatedMenuVisibility: Symbol(
        'UpdateTranslatedMenuVisibilityAction'
    ),
    ListTranslationBundles: Symbol('ListTranslationBundlesAction'),
    BuyTranslationBundle: Symbol('BuyTranslationBundleAction'),
}

export type {
    BuyTranslationBundleAction,
    CreateTranslatedMenuAction,
    ListTranslationBundlesAction,
    ListTranslationObjectAction,
    UpdateTranslatedMenuVisibilityAction,
    UpdateTranslationObjectAction,
}
