import {
    Button,
    Divider,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    VStack,
} from '@chakra-ui/react'
import { UseDeliveryPolicyFormDataHook } from 'features/location/hooks/policy/useDeliveryPolicyForm'
import { useTranslation } from 'react-i18next'
import { FiX } from 'react-icons/fi'
import { ListActions } from 'react-use/lib/useList'
import { PriceInput } from 'uikit/form/input'

interface DeliveryFormProps {
    formData: UseDeliveryPolicyFormDataHook
}

// interface PolicyDelivery {
//     deliveryCosts: {
//         [key: string]: number;
//     };
// }

export function DeliveryForm({ formData }: DeliveryFormProps) {
    const { t } = useTranslation()

    return (
        <VStack align="stretch" pt={8} spacing={12}>
            <PriceInput
                title={t('Free Delivery')}
                description={t(
                    'Threshold above which customers must not pay delivery fees.'
                )}
                value={formData.freeDeliveryThreshold}
                setValue={formData.setFreeDeliveryThreshold}
            />
            <PriceInput
                title={t('Minimum Amount')}
                description={t(
                    'Threshold below which delivery orders are not accepted.'
                )}
                value={formData.minimumRequiredAmount}
                setValue={formData.setMinimumRequiredAmount}
            />
            <PriceInput
                title={t('Upfront Payment Threshold')}
                description={t(
                    'Amount beyond which customers must pay in advance to confirm their order.'
                )}
                value={formData.upfrontPaymentThreshold}
                setValue={formData.setUpfrontPaymentThreshold}
            />

            <Divider my={4} borderWidth={1.5} />

            {/* <DeliveryCostsInputs
                deliveryCosts={formData.deliveryCosts}
                handler={formData.setDeliveryCosts}
            /> */}
        </VStack>
    )
}

interface DeliveryCostsInputsProps {
    deliveryCosts: [string, number][]
    handler: ListActions<[string, number]>
}

function DeliveryCostsInputs({
    deliveryCosts,
    handler,
}: DeliveryCostsInputsProps) {
    const { t } = useTranslation()

    return (
        <VStack gap={4}>
            {deliveryCosts.map(([key, cost], index) => (
                <HStack w="full" key={/* TODO */ index} gap={2}>
                    <IconButton
                        size="xs"
                        aria-label="Remove"
                        colorScheme="gray"
                        borderRadius="full"
                        variant="ghost"
                        icon={<FiX />}
                        onClick={() => handler.removeAt(index)}
                    />

                    <InputGroup>
                        <InputLeftAddon children="CAP" />
                        <Input
                            value={key}
                            onChange={(e) =>
                                handler.updateAt(index, [e.target.value, cost])
                            }
                        />
                    </InputGroup>
                    <PriceInput
                        isRequired
                        value={cost}
                        setValue={(value) =>
                            handler.updateAt(index, [key, value])
                        }
                    />
                </HStack>
            ))}

            <Button alignSelf="end" onClick={() => handler.push(['', 0])}>
                {t('Add destination')}
            </Button>
        </VStack>
    )
}
