import { CH, FlagComponent, IT } from 'country-flag-icons/react/3x2'
import { Address } from '../models/Address'

export function mapISOToCountry(iso: string | undefined): Address.Country {
    if (!iso || (iso.length !== 2 && iso.length !== 3)) return ''

    const value = iso.toUpperCase()
    if (value === 'IT' || value === 'ITA') return 'IT'
    if (value === 'CH' || value === 'CHE') return 'CH'

    return ''
}

export function flagForCountry(
    country: Address.Country
): FlagComponent | undefined {
    switch (country) {
        case '':
            return undefined
        case 'IT':
            return IT
        case 'CH':
            return CH
    }
}
