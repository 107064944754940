import { arrayMoveImmutable } from 'array-move'
import { useEventEffect } from 'features/event/hooks/useEventEffect'
import { Menu } from 'features/menu/models/Menu'
import { useInjection } from 'inversify-react'
import _ from 'lodash'
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useList, useSet } from 'react-use'
import { MenuEntryDependencies } from '../dependencies/entry'
import { MenuDependencies } from '../dependencies/menu'

export interface UseMenuContentFormDataHook {
    isRearranging: boolean
    isAddingSections: boolean
    isAdddingEntries: boolean
    sections: Menu.Section[]
    selectedSectionIndex: number
    selectedEntriesIndexes: Set<number>
    changedAllergens: Map<Menu.Section.Entry.Allergen, boolean>
    changedFeatures: Map<Menu.Section.Entry.Feature, boolean>
    startRearranging: () => void
    stopRearranging: (options?: { shouldReset: boolean }) => void
    setIsAddingSections: Dispatch<SetStateAction<boolean>>
    setIsAddingEntries: Dispatch<SetStateAction<boolean>>
    selectSection: (index: number) => void
    toggleEntrySelection: (index: number) => void
    unselectAllEntries: () => void
    setChangedAllergens: Dispatch<
        SetStateAction<Map<Menu.Section.Entry.Allergen, boolean>>
    >
    setChangedFeatures: Dispatch<
        SetStateAction<Map<Menu.Section.Entry.Feature, boolean>>
    >
    rearrangeSections: (oldIndex: number, newIndex: number) => void
    rearrangeEntries: (oldIndex: number, newIndex: number) => void
}

export function useMenuContentFormData(menu: Menu): UseMenuContentFormDataHook {
    const [isRearranging, setIsRearranging] = useState(false)
    const [isAddingSections, setIsAddingSections] = useState(false)
    const [isAdddingEntries, setIsAddingEntries] = useState(false)
    const [sections, { set: setSections, updateAt: updateSectionAt }] = useList(
        menu.sections
    )
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)
    const [
        selectedEntriesIndexes,
        { reset: unselectAllEntries, toggle: toggleEntrySelection },
    ] = useSet<number>()
    const [changedAllergens, setChangedAllergens] = useState(
        new Map<Menu.Section.Entry.Allergen, boolean>()
    )
    const [changedFeatures, setChangedFeatures] = useState(
        new Map<Menu.Section.Entry.Feature, boolean>()
    )

    const selectSection = useCallback((index: number) => {
        setSelectedSectionIndex(index)
        unselectAllEntries()
    }, [])

    const startRearranging = useCallback(() => setIsRearranging(true), [])
    const stopRearranging = useCallback(
        (options?: { shouldReset: boolean }) => {
            setIsRearranging(false)
            if (options?.shouldReset) {
                setSelectedSectionIndex((previous) => {
                    const current = sections[previous]
                    return menu.sections.findIndex(
                        (section) =>
                            section.id.sectionId === current.id.sectionId
                    )
                })

                setSections(menu.sections)
            }
        },
        [menu, sections]
    )

    const rearrangeSections = useCallback(
        (oldIndex: number, newIndex: number) => {
            setSelectedSectionIndex((previous) => {
                if (oldIndex === previous) {
                    return newIndex
                } else if (oldIndex < previous && previous <= newIndex) {
                    return previous - 1
                } else if (oldIndex > previous && previous >= newIndex) {
                    return previous + 1
                } else {
                    return previous
                }
            })

            setSections((previous) =>
                arrayMoveImmutable(previous, oldIndex, newIndex)
            )
        },
        []
    )

    const rearrangeEntries = useCallback(
        (oldIndex: number, newIndex: number) => {
            updateSectionAt(selectedSectionIndex, {
                ...sections[selectedSectionIndex],
                entries: arrayMoveImmutable(
                    sections[selectedSectionIndex].entries,
                    oldIndex,
                    newIndex
                ),
            })
        },
        [sections, selectedSectionIndex]
    )

    useEffect(() => {
        if (!_.isEqual(menu.sections, sections)) {
            setSections(menu.sections)
        }
    }, [menu])

    return {
        isRearranging,
        isAddingSections,
        isAdddingEntries,
        sections,
        selectedSectionIndex,
        selectedEntriesIndexes,
        changedAllergens,
        changedFeatures,
        startRearranging,
        stopRearranging,
        setIsAddingSections,
        setIsAddingEntries,
        selectSection,
        toggleEntrySelection,
        unselectAllEntries,
        setChangedAllergens,
        setChangedFeatures,
        rearrangeSections,
        rearrangeEntries,
    }
}

export interface UseMenuContentFormControllerHook {
    isRearranging: boolean
    canRearrange: boolean
    rearrange: () => Promise<void>
    canUpdateEntries: boolean
    updatingEntriesIds: Set<Menu.Section.Entry.ID>
    updateEntries: () => Promise<void>
}

export function useMenuContentFormController(
    menu: Menu,
    params: UseMenuContentFormDataHook,
    onSave: (menu: Menu) => void
): UseMenuContentFormControllerHook {
    const updateMenu = useInjection(MenuDependencies.Update)
    const batchUpdateEntries = useInjection(MenuEntryDependencies.BatchUpdate)

    // Rearrenge
    const [isRearranging, setIsRearranging] = useState(false)

    const canRearrange = useMemo(() => {
        if (_.isEqual(menu.sections, params.sections)) return false
        return true
    }, [menu, params])

    async function rearrange() {
        if (isRearranging || !canRearrange) return
        setIsRearranging(true)

        const newMenu = await updateMenu.run({
            menu: menu,
            sections: params.sections,
        })
        onSave(newMenu)

        setIsRearranging(false)
        params.stopRearranging()
    }

    // Batch Update
    const canUpdateEntries = useMemo(() => {
        const section = params.sections[params.selectedSectionIndex]
        const entries = Array.from(params.selectedEntriesIndexes).map(
            (index) => section.entries[index]
        )

        if (params.changedAllergens.size > 0) {
            const hasChanges = entries.some((entry) => {
                for (const change of params.changedAllergens.entries()) {
                    const [allergen, status] = change

                    if (entry.allergens.includes(allergen) !== status) {
                        return true
                    }
                }

                return false
            })

            if (hasChanges) {
                return true
            }
        }

        if (params.changedFeatures.size > 0) {
            const hasChanges = entries.some((entry) => {
                for (const change of params.changedFeatures.entries()) {
                    const [feature, status] = change

                    if (entry.features.includes(feature) !== status) {
                        return true
                    }
                }

                return false
            })

            if (hasChanges) {
                return true
            }
        }

        return false
    }, [params])

    const [
        updatingEntriesIds,
        {
            has: isUpdatingEntryId,
            add: addUpdatingEntryId,
            remove: removeUpdatingEntryId,
        },
    ] = useSet<Menu.Section.Entry.ID>()

    async function updateEntries() {
        if (!canUpdateEntries) return

        const allergens = params.changedAllergens
        const features = params.changedFeatures
        const section = params.sections[params.selectedSectionIndex]
        const entries = Array.from(params.selectedEntriesIndexes)
            .map((index) => section.entries[index])
            .filter((entry) => !isUpdatingEntryId(entry.id))

        entries.forEach((entry) => addUpdatingEntryId(entry.id))

        params.unselectAllEntries()
        params.setChangedAllergens(new Map())
        params.setChangedFeatures(new Map())
        const [newMenu, acceptedEntries] = await batchUpdateEntries.run({
            menu,
            section,
            entries,
            allergens,
            features,
        })

        onSave(newMenu)

        entries.forEach((entry) => {
            if (!acceptedEntries.includes(entry.id)) {
                removeUpdatingEntryId(entry.id)
            }
        })
    }

    useEventEffect((event) => {
        if (event.type !== 'entry-updated') return

        const id: Menu.Section.Entry.ID = {
            organizationId: event.entry.organizationId,
            menuId: event.entry.menuId,
            sectionId: event.entry.sectionId,
            entryId: event.entry.id,
        }
        removeUpdatingEntryId(id)
    })

    return {
        isRearranging,
        canRearrange,
        rearrange,
        canUpdateEntries,
        updatingEntriesIds,
        updateEntries,
    }
}
