import {
    Fade,
    FormControl,
    FormHelperText,
    FormLabel,
    Icon,
    Spacer,
    Switch,
    Tooltip,
    VStack,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { FaExclamationTriangle } from 'react-icons/fa'
import MoreInfoTooltip from 'utils/genericcomponents/MoreInfoTooltip'

interface PolicyFormSwitchProps {
    title: string
    description: string
    isChecked: boolean
    onToggle: (value: boolean) => void
    isDisabled?: boolean
    warningTooltip?: string
    moreInfo?: string
}

export function PolicyFormSwitch({
    title,
    description,
    isChecked,
    onToggle,
    isDisabled,
    warningTooltip,
    moreInfo,
}: PolicyFormSwitchProps) {
    const t = useT()
    return (
        <FormControl display="flex" alignItems="center" w="full">
            <VStack align="stretch" spacing={0}>
                <FormLabel m={0}>{title}</FormLabel>

                <FormHelperText m={0}>
                    {description}{' '}
                    {moreInfo && (
                        <>
                            <MoreInfoTooltip
                                moreInfo={moreInfo}
                                title={title}
                            />
                            {'.'}
                        </>
                    )}
                </FormHelperText>
            </VStack>
            <Spacer />
            <Fade in={warningTooltip !== undefined}>
                <Tooltip label={warningTooltip} fontSize="md" me={4}>
                    <span>
                        <Icon
                            me={4}
                            as={FaExclamationTriangle}
                            color="primary.200"
                        />
                    </span>
                </Tooltip>
            </Fade>

            <Switch
                size="lg"
                isChecked={isChecked}
                onChange={(e) => onToggle(e.target.checked)}
                isDisabled={isDisabled}
            />
        </FormControl>
    )
}
