interface UserRequest {
    AccessToken: string
}

interface ClientRequest {
    ClientId: string
}

interface AuthenticationResult {
    AccessToken: string
    IdToken: string
    RefreshToken: string
}

interface UserAttribute {
    Name: string
    Value: string
}

export type { UserAttribute }

export module Cognito {
    export module UserAttribute {
        export function givenName(value: string): UserAttribute {
            return { Name: 'given_name', Value: value }
        }

        export function familyName(value: string): UserAttribute {
            return { Name: 'family_name', Value: value }
        }

        export function locale(value: string): UserAttribute {
            return { Name: 'locale', Value: value }
        }

        export function zoneInfo(value: string): UserAttribute {
            return { Name: 'zoneinfo', Value: value }
        }

        export function custom(name: string, value: string): UserAttribute {
            return { Name: `custom:${name}`, Value: value }
        }
    }

    export module Request {
        export interface PasswordAuth extends ClientRequest {
            AuthFlow: 'USER_PASSWORD_AUTH'
            AuthParameters: {
                USERNAME: string
                PASSWORD: string
            }
        }

        export interface IdTokenAuth extends ClientRequest {
            AuthFlow: 'CUSTOM_AUTH'
            AuthParameters: {
                USERNAME: string
            }
        }

        export interface SignUp extends ClientRequest {
            Username: string
            Password: string
            UserAttributes: UserAttribute[]
        }

        export interface ConfirmSignUp extends ClientRequest {
            Username: string
            ConfirmationCode: string
        }

        export interface RefreshToken extends ClientRequest {
            AuthFlow: 'REFRESH_TOKEN_AUTH'
            AuthParameters: {
                REFRESH_TOKEN: string
            }
        }

        export interface ResendConfirmationCode extends ClientRequest {
            Username: string
        }

        export interface ForgotPassword extends ClientRequest {
            Username: string
        }

        export interface ConfirmForgotPassword extends ClientRequest {
            Username: string
            ConfirmationCode: string
            Password: string
        }

        export interface UpdateUserAttributes extends UserRequest {
            UserAttributes: UserAttribute[]
        }

        export interface ChangePassword extends UserRequest {
            PreviousPassword: string
            ProposedPassword: string
        }

        export interface RespondToAuthChallenge extends ClientRequest {
            ChallengeName: string
            Session: string
            ChallengeResponses: {
                USERNAME: string
                ANSWER: string
            }
        }
    }

    export module Response {
        export interface PasswordAuth {
            AuthenticationResult: AuthenticationResult
        }

        export interface IdTokenAuth {
            ChallengeName: string
            Session: string
            ChallengeParameters: {
                USERNAME: string
                requirement: string
            }
        }

        export interface RefreshToken {
            AuthenticationResult: {
                AccessToken: string
                IdToken: string
            }
        }

        export interface RespondToAuthChallenge {
            AuthenticationResult: AuthenticationResult
        }
    }
}
