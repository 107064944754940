import { VStack } from '@chakra-ui/react'
import { UseTablePolicyFormDataHook } from 'features/location/hooks/policy/useTablePolicyForm'
import useT from 'localization/hooks/useT'
import { PriceInput } from 'uikit/form/input'
import { PolicyFormSwitch } from './PolicyFormSwitch'

interface TableFormProps {
    formData: UseTablePolicyFormDataHook
}

export function TableForm({ formData }: TableFormProps) {
    const t = useT('policy')

    return (
        <VStack align="stretch" pt={8} spacing={12}>
            <PolicyFormSwitch
                title={t('table_courses_title')}
                description={t('table_courses_description')}
                moreInfo={t('table_courses_more_info')}
                isChecked={formData.supportsCourseOrdering}
                onToggle={formData.setSupportsCourseOrdering}
            />

            <PolicyFormSwitch
                title={t('table_inapp_payments_title')}
                description={t('table_inapp_payments_description')}
                isChecked={formData.supportsInAppPayment}
                onToggle={formData.setSupportsInAppPayment}
            />

            <PolicyFormSwitch
                title={t('table_upfront_payments_title')}
                description={t('table_upfront_payments_description')}
                moreInfo={t('table_upfront_payments_more_info')}
                isChecked={formData.requiresUpfrontPayment}
                onToggle={formData.setRequiresUpfrontPayment}
                isDisabled={!formData.supportsInAppPayment}
            />

            <PolicyFormSwitch
                title={t('table_automatic_confirmation_title')}
                description={t('table_automatic_confirmation_description')}
                moreInfo={t('table_automatic_confirmation_more_info')}
                isChecked={formData.supportsAutomaticConfirmation}
                onToggle={formData.setSupportsAutomaticConfirmation}
                isDisabled={formData.requiresUpfrontPayment}
                warningTooltip={
                    formData.requiresUpfrontPayment
                        ? t(
                              'table_automatic_confirmation_and_upfront_payments_incompatible_explanation'
                          )
                        : undefined
                }
            />

            <PriceInput
                title={t('table_minimum_inapp_payments_amount_title')}
                description={t(
                    'table_minimum_inapp_payments_amount_description'
                )}
                moreInfo={t('table_minimum_inapp_payments_amount_more_info')}
                value={formData.minimumInAppPaymentAmount}
                setValue={formData.setMinimumInAppPaymentAmount}
            />

            <PriceInput
                title={t('table_cover_charge_title')}
                description={t('table_cover_charge_description')}
                moreInfo={t('table_cover_charge_more_info')}
                value={formData.coverCost}
                setValue={formData.setCoverCost}
            />
        </VStack>
    )
}
