import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    FormControl,
    FormLabel,
    Heading,
    Portal,
    Skeleton,
    Spacer,
    Switch,
    Text,
    VStack,
} from '@chakra-ui/react'
import { isValid } from 'features/menu/services/isValid'
import useT from 'localization/hooks/useT'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedActionDesktop'
import { CreateMenuAssignmentController } from './Controller'

export default function ActivateAssignmentStep(
    props: CreateMenuAssignmentController & StepPropsWithRef
) {
    const t = useT('menuassignment')
    const menu = props.extendedMenu
    const canBeActivated = menu !== undefined ? isValid(menu) : false

    const descriptionKey = canBeActivated
        ? props.isActive
            ? 'new_assignment_can_be_activated_alert_description'
            : 'new_assignment_can_be_activated_prefers_inactive_alert_description'
        : 'new_assignment_cannot_be_activated_alert_description'

    return (
        <>
            <Portal containerRef={props.headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {t('new_step_2_activate_title')}
                    </Heading>
                    <Text>{t('new_step_2_activate_description')}</Text>
                </VStack>
            </Portal>

            <VStack w="full" mb={8}>
                <FormControl display="flex" alignItems="center" w="full">
                    <Skeleton isLoaded={menu !== undefined} borderRadius="sm">
                        <FormLabel m={0}>
                            {t('new_activate_assignment_switch')}
                        </FormLabel>
                    </Skeleton>
                    <Spacer />

                    <Switch
                        size="lg"
                        isChecked={props.isActive}
                        onChange={(e) => props.setIsActive(e.target.checked)}
                        isDisabled={!canBeActivated}
                    />
                </FormControl>
                <Alert
                    status={
                        menu === undefined
                            ? 'loading'
                            : canBeActivated
                            ? 'success'
                            : 'warning'
                    }
                    borderRadius="md"
                >
                    <AlertIcon />
                    <Box w="full">
                        <Skeleton display="flex" isLoaded={menu !== undefined}>
                            <AlertTitle>
                                {canBeActivated
                                    ? t(
                                          'new_assignment_can_be_activated_alert_title'
                                      )
                                    : t(
                                          'new_assignment_cannot_be_activated_alert_title'
                                      )}
                            </AlertTitle>
                        </Skeleton>
                        <Skeleton display="flex" isLoaded={menu !== undefined}>
                            <AlertDescription>
                                {t(descriptionKey, {
                                    menuName: menu?.name,
                                    locationName: props.location.name,
                                })}
                            </AlertDescription>
                        </Skeleton>
                    </Box>
                </Alert>
            </VStack>
        </>
    )
}
