import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Collapse,
    FormControl,
    FormLabel,
    HStack,
    Heading,
    Switch,
    Text,
    VStack,
} from '@chakra-ui/react'
import useT from 'localization/hooks/useT'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

export interface PolicySectionProps {
    name: string
    description: string
    icon: JSX.Element
    content: JSX.Element
    isChecked: boolean
    onToggle: (value: boolean) => void
    isAvailable: boolean
    hasChanges: boolean
}

interface PolicySectionControllerProps {
    onSaveChanges: () => void
    isSaving: boolean
}

export function PolicySection({
    name,
    description,
    icon,
    content,
    isChecked,
    onToggle,
    isAvailable,
    hasChanges,
    onSaveChanges,
    isSaving,
}: PolicySectionProps & PolicySectionControllerProps) {
    const t = useT('policy')
    const screen = useScreenBreakpoint()

    if (!isAvailable) {
        if (screen === 'desktop') {
            return (
                <VStack w="full" align="stretch">
                    <HStack w="full" spacing={6}>
                        {icon}

                        <VStack align="stretch" spacing={0} w="full">
                            <Heading size="sm" fontWeight="semibold">
                                {name}
                            </Heading>
                            <Text w="full" fontSize="sm">
                                {description}
                            </Text>
                        </VStack>

                        <FormControl
                            as={HStack}
                            w="fit-content"
                            alignItems="center"
                            justifyContent="end"
                        >
                            <FormLabel mb={1}>
                                {t('enable_policy_switch')}
                            </FormLabel>
                            <Switch size="lg" isChecked={false} isDisabled />
                        </FormControl>
                    </HStack>
                    <Alert w="full" maxW="6xl" borderRadius="lg" mt={8}>
                        <AlertIcon />

                        <Box w="full">
                            <AlertTitle>{t('coming_soon_title')}</AlertTitle>
                            <AlertDescription w="full">
                                {t('coming_soon_description')}
                            </AlertDescription>
                        </Box>
                    </Alert>
                </VStack>
            )
        } else {
            return (
                <VStack w="full" align="stretch">
                    <HStack w="full" spacing={6}>
                        {icon}

                        <Heading size="sm" fontWeight="semibold" w="full">
                            {name}
                        </Heading>

                        <FormControl
                            display="flex"
                            alignItems="center"
                            justifyContent="end"
                            w="fit-content"
                        >
                            <FormLabel mb={1}>
                                {t('enable_policy_switch')}
                            </FormLabel>
                            <Switch size="lg" isDisabled isChecked={false} />
                        </FormControl>
                    </HStack>
                    <Text fontSize="sm">{description}</Text>
                    <Alert w="full" maxW="6xl" borderRadius="lg" mt={8}>
                        <AlertIcon />

                        <Box w="full">
                            <AlertTitle>{t('coming_soon_title')}</AlertTitle>
                            <AlertDescription w="full">
                                {t('coming_soon_description')}
                            </AlertDescription>
                        </Box>
                    </Alert>
                </VStack>
            )
        }
    }

    if (screen === 'desktop') {
        return (
            <VStack w="full" align="stretch">
                <HStack w="full" spacing={6}>
                    {icon}

                    <VStack align="stretch" spacing={0} w="full">
                        <Heading size="sm" fontWeight="semibold">
                            {name}
                        </Heading>
                        <Text fontSize="sm">{description}</Text>
                    </VStack>

                    <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        w="fit-content"
                    >
                        <FormLabel mb={1}>
                            {t('enable_policy_switch')}
                        </FormLabel>
                        <Switch
                            size="lg"
                            onChange={(e) => onToggle(e.target.checked)}
                            isChecked={isChecked}
                        />
                    </FormControl>
                </HStack>

                <Collapse in={isChecked} animateOpacity>
                    {content}
                </Collapse>

                <Button
                    w="fit-content"
                    mt={8}
                    alignSelf="end"
                    isDisabled={!hasChanges}
                    isLoading={isSaving}
                    onClick={onSaveChanges}
                >
                    {t('save_button')}
                </Button>
            </VStack>
        )
    } else {
        return (
            <VStack w="full" align="stretch">
                <HStack w="full" spacing={6}>
                    {icon}

                    <Heading size="sm" fontWeight="semibold" w="full">
                        {name}
                    </Heading>

                    <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        w="fit-content"
                    >
                        <FormLabel mb={1}>
                            {t('enable_policy_switch')}
                        </FormLabel>
                        <Switch
                            size="lg"
                            onChange={(e) => onToggle(e.target.checked)}
                            isChecked={isChecked}
                        />
                    </FormControl>
                </HStack>
                <Text fontSize="sm">{description}</Text>

                <Collapse in={isChecked} animateOpacity>
                    {content}
                </Collapse>
            </VStack>
        )
    }
}
