import { Address } from 'features/address/models/Address'
import { DateRange, Schedule } from 'features/calendar/models/Calendar'
import { Media } from 'features/media/models/Media'
import { MenuAssignment } from 'features/menuassignment/models/MenuAssignment'
import { Organization } from 'features/organization/models/Organization'

export interface Location {
    id: Location.ID
    name: string
    published: boolean
    restricted: boolean
    medias: Media[]
    address: Address | undefined
    remainingAddressChanges: number
    policy: Location.Policy
    calendar: Location.Calendar
    category?: Location.Category
    subcategories: Location.Category[]
    capacity: number | undefined
    capabilities: Location.Capability[]
    menuAssignments: MenuAssignment[]
    violations: Set<Location.Violation>
    stripe: Location.Stripe
}

export module Location {
    export interface ID extends Organization.ID {
        locationId: number
    }

    // Policy
    export interface Policy {
        delivery?: Policy.Delivery
        reservation?: Policy.Reservation
        table?: Policy.Table
        takeAway?: Policy.TakeAway
    }

    export module Policy {
        export interface Delivery {
            freeDeliveryThreshold: number | undefined
            minimumRequiredAmount: number | undefined
            upfrontPaymentThreshold: number | undefined
        }

        export interface Reservation {
            allowsRemoteOrder: boolean
            allowsRemoteUpfrontPayment: boolean
            automaticConfirmationThreshold: number | undefined
            maximumNumberOfConcurrentReservations: number | undefined
            maximumNumberOfPeoplePerReservation: number | undefined
            reservationDefaultDuration: number
            tableHoldingDepositAmount: number | undefined
        }

        export interface Table {
            allowsInAppOrder: boolean
            requiresUpfrontPayment: boolean
            supportsAutomaticConfirmation: boolean
            supportsCourseOrdering: boolean
            supportsInAppPayment: boolean
            coverCost: number | undefined
            minimumInAppPaymentAmount: number | undefined
        }

        export interface TakeAway {
            percentageDiscount: number | undefined
            upfrontPaymentThreshold: number | undefined
        }
    }

    // Calendar
    export interface Calendar {
        weekdays: Schedule
        scheduledClosures: DateRange[]
    }

    // Category
    export enum Category {
        Restaurant = 1 << 0,
        RestaurantPizzeria = 1 << 1,
        Pizzeria = 1 << 2,
        Sushi = 1 << 3,
        FastFood = 1 << 4,
        Tavern = 1 << 5,
        Trattoria = 1 << 6,
        Bar = 1 << 7,
        Bistrot = 1 << 8,
        Pub = 1 << 9,
        Steakhouse = 1 << 10,
        Grotto = 1 << 11,
        Imbiss = 1 << 12,
        Rotisserie = 1 << 13,
        HamburgerShop = 1 << 14,
        KebabShop = 1 << 15,
        Piadineria = 1 << 16,
        SandwichShop = 1 << 17,
        IceCreamParlor = 1 << 18,
        PastryShop = 1 << 19,
        MeatSpecialties = 1 << 20,
        SeafoodSpecialties = 1 << 21,
        LocalCuisine = 1 << 22,
        MediterraneanCuisine = 1 << 23,
        ItalianCuisine = 1 << 24,
        FrenchCuisine = 1 << 25,
        GreekCuisine = 1 << 26,
        AmericanCuisine = 1 << 27,
        MexicanCuisine = 1 << 28,
        ChineseCuisine = 1 << 29,
        ThaiCuisine = 1 << 30,
        IndianCuisine = 1 << 31,
    }

    // Capability
    export enum Capability {
        AnimalFriendly = 1 << 4,
        CarParking = 1 << 5,
        FreeWiFi = 1 << 6,
        AreaKids = 1 << 7,
        OpenAir = 1 << 8,
        ReceptionEvents = 1 << 9,
        VeganFriendly = 1 << 10,
        MotorImpairmentAccessible = 1 << 11,
    }

    // Violation
    export type Violation =
        | 'empty-gallery'
        | 'calendar-always-closed'
        | 'calendar-uncovered-schedule'
        | 'no-menu-assigned'
        | 'missing-category'
        | 'missing-capacity'
        | 'missing-policies'
        | 'missing-address'
        | 'invalid-address'

    // Stripe
    export interface Stripe {
        id: string | undefined
    }
}
