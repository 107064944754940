import Avokado, {
    AvokadoAPIError,
    PayoutSettingsUpdate,
} from '@avokadoapp/avokado-ts'
import { PayoutPreferences } from 'features/finance/models/PayoutPreferences'
import { Organization } from 'features/organization/models/Organization'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

interface UpdatePreferencesPayoutParams extends Organization.ID {
    interval: number
}

export interface UpdatePreferencesPayoutAction {
    run(params: UpdatePreferencesPayoutParams): Promise<PayoutPreferences>
}

@injectable()
export class UpdatePreferencesPayoutActionLive
    implements UpdatePreferencesPayoutAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(
        params: UpdatePreferencesPayoutParams
    ): Promise<PayoutPreferences> {
        const request: PayoutSettingsUpdate.Request = {
            organizationId: params.organizationId,
            payoutInterval: params.interval,
        }
        const response = await this.avokado.payoutSettings.update(request)
        return { interval: response.payoutInterval }
    }
}

@injectable()
export class UpdatePreferencesPayoutActionFake
    implements UpdatePreferencesPayoutAction
{
    async run(
        params: UpdatePreferencesPayoutParams
    ): Promise<PayoutPreferences> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class UpdatePreferencesPayoutActionFail
    implements UpdatePreferencesPayoutAction
{
    async run(
        params: UpdatePreferencesPayoutParams
    ): Promise<PayoutPreferences> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
