import { Location } from 'features/location/models/Location'
import { useInjection } from 'inversify-react'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { Geometry } from 'utils/geometry/Geometry'
import { RoomDependencies } from '../dependencies'
import { Room } from '../models/Room'

interface UseNewRoomFormDataHook {
    name: string
    usingTemplate: boolean
    width: number
    height: number
    aspectRatio: number | undefined
    setName: Dispatch<SetStateAction<string>>
    setUsingTemplate: Dispatch<SetStateAction<boolean>>
    setWidth: Dispatch<SetStateAction<number>>
    setHeight: Dispatch<SetStateAction<number>>
    setAspectRatio: Dispatch<SetStateAction<number | undefined>>
}

export function useNewRoomFormData(): UseNewRoomFormDataHook {
    const [name, setName] = useState('')
    const [usingTemplate, setUsingTemplate] = useState(true)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    // Aspect ratio in range [-0.75, 0.75] where
    // - negative value represent vertical rooms
    // - zero represent squared rooms
    // - positive value represent horizontal rooms
    const [aspectRatio, setAspectRatio] = useState<number | undefined>(
        undefined
    )

    return {
        name,
        usingTemplate,
        width,
        height,
        aspectRatio,
        setName,
        setUsingTemplate,
        setWidth,
        setHeight,
        setAspectRatio,
    }
}

export function useNewRoomFormController(
    id: Location.ID,
    rooms: Room[],
    params: UseNewRoomFormDataHook,
    onSave: (room: Room) => void
): UseFormControllerHook<void> {
    const create = useInjection(RoomDependencies.Create)

    const [isProcessing, setIsProcessing] = useState(false)

    const usedNames = useMemo(() => rooms.map((room) => room.name), [rooms])

    const canSubmit = useMemo(() => {
        if (params.name === '') return false
        if (usedNames.includes(params.name)) return false

        if (params.usingTemplate) {
            if (params.aspectRatio === undefined) return false
            if (params.aspectRatio < -0.75) return false
            if (params.aspectRatio > 0.75) return false
        } else {
            if (params.width <= 0) return false
            if (params.height <= 0) return false
        }
        return true
    }, [
        usedNames,
        params.name,
        params.usingTemplate,
        params.aspectRatio,
        params.width,
        params.height,
    ])

    async function submit() {
        if (isProcessing || !canSubmit) return
        setIsProcessing(true)

        try {
            let size: Geometry.Size

            if (params.usingTemplate) {
                const aspectRatio = params.aspectRatio!
                if (aspectRatio > 0) {
                    size = {
                        width: 1,
                        height: 1 - aspectRatio,
                    }
                } else if (aspectRatio < 0) {
                    size = {
                        width: 1 + aspectRatio,
                        height: 1,
                    }
                } else {
                    size = {
                        width: 1,
                        height: 1,
                    }
                }
            } else {
                const max = Math.max(params.height, params.width)
                size = {
                    width: params.width / max,
                    height: params.height / max,
                }
            }

            const newRoom = await create.run({
                ...id,
                name: params.name,
                size: size,
            })
            onSave(newRoom)
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
