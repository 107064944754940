import {
    FormControl,
    FormLabel,
    Heading,
    Input,
    Portal,
    Text,
    VStack,
} from '@chakra-ui/react'
import { UseNewLocationFormDataHook } from 'features/location/hooks/new/useNewLocationForm'
import useT from 'localization/hooks/useT'
import { StepPropsWithRef } from 'utils/guidedaction/GuidedAction'

export function NewLocationGeneralStep({
    headerRef,
    general: formData,
}: UseNewLocationFormDataHook & StepPropsWithRef) {
    const t = useT('location')

    return (
        <>
            <Portal containerRef={headerRef}>
                <VStack align="start" w="full" spacing={0}>
                    <Heading size="xl" fontWeight="semibold">
                        {t('new_location_title')}
                    </Heading>
                    <Text>{t('new_location_description')}</Text>
                </VStack>
            </Portal>

            <VStack w="full" spacing={6}>
                <FormControl>
                    <FormLabel>{t('new_location_name_label')}</FormLabel>
                    <Input
                        type="text"
                        placeholder={t('new_location_name_placeholder')}
                        autoComplete="off"
                        value={formData.name}
                        onChange={(e) => formData.setName(e.target.value)}
                    />
                </FormControl>
            </VStack>
        </>
    )
}
