import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import UseFormControllerHook from 'utils/form/UseFormControllerHook'
import { emailValidation } from 'utils/form/validation'
import useAuthContext from '../contexts/AuthContext'

interface UsePasswordForgottenFormDataHook {
    email: string
    setEmail: Dispatch<SetStateAction<string>>
}

export function usePasswordForgottenFormData(): UsePasswordForgottenFormDataHook {
    const [email, setEmail] = useState('')

    return { email, setEmail }
}

export function usePasswordForgottenFormController(
    params: UsePasswordForgottenFormDataHook
): UseFormControllerHook<void> {
    const { passwordForgotten } = useAuthContext()

    const [isProcessing, setIsProcessing] = useState(false)

    const canSubmit = useMemo(() => {
        if (!emailValidation.isValidSync({ email: params.email })) {
            return false
        }

        return true
    }, [params.email])

    async function submit() {
        if (isProcessing) return

        setIsProcessing(true)
        try {
            await passwordForgotten(params.email)
        } catch (e: any) {
            // TODO: show a toast
        } finally {
            setIsProcessing(false)
        }
    }

    return { isProcessing, canSubmit, submit }
}
