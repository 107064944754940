import Constants from 'utils/constant/Constants'

export class Media {
    constructor(public id: string, private suffix: string) {}

    small(): string {
        return `https://media.${Constants.Host}/small/${this.suffix}`
    }

    medium(): string {
        return `https://media.${Constants.Host}/medium/${this.suffix}`
    }

    large(): string {
        return `https://media.${Constants.Host}/large/${this.suffix}`
    }

    original(): string {
        return `https://media.${Constants.Host}/original/${this.suffix}`
    }
}
