import { interfaces } from 'inversify'
import { AuthStore } from './AuthStore'
import { ChangePasswordAction } from './ChangePasswordAction'
import { ConfirmAccountAction } from './ConfirmAccountAction'
import { ConfirmForgotPasswordAction } from './ConfirmForgotPasswordAction'
import { ForgotPasswordAction } from './ForgotPasswordAction'
import { RefreshTokenAction } from './RefreshTokenAction'
import { ResendConfirmationCodeAction } from './ResendConfirmationCodeAction'
import { SignInAction } from './SignInAction'
import { SignOutAction } from './SignOutAction'
import { SignUpAction } from './SignUpAction'
import { SocialSignInAction } from './SocialSignInAction'
import { UpdateUserAttributesAction } from './UpdateUserAttributesAction'
import { RetrieveUserAction } from './RetrieveUserAction'

interface _AuthDependencies {
    Store: interfaces.ServiceIdentifier<AuthStore>
    SignIn: interfaces.ServiceIdentifier<SignInAction>
    SignOut: interfaces.ServiceIdentifier<SignOutAction>
    SignUp: interfaces.ServiceIdentifier<SignUpAction>
    SocialSignIn: interfaces.ServiceIdentifier<SocialSignInAction>
    RefreshToken: interfaces.ServiceIdentifier<RefreshTokenAction>
    ConfirmAccount: interfaces.ServiceIdentifier<ConfirmAccountAction>
    ResendConfirmationCode: interfaces.ServiceIdentifier<ResendConfirmationCodeAction>
    ChangePassword: interfaces.ServiceIdentifier<ChangePasswordAction>
    ForgotPassword: interfaces.ServiceIdentifier<ForgotPasswordAction>
    ConfirmForgotPassword: interfaces.ServiceIdentifier<ConfirmForgotPasswordAction>
    UpdateUserAttributes: interfaces.ServiceIdentifier<UpdateUserAttributesAction>
    RetrieveUser: interfaces.ServiceIdentifier<RetrieveUserAction>
}

export const AuthDependencies: _AuthDependencies = {
    Store: Symbol('AuthStore'),
    SignIn: Symbol('SignInAction'),
    SignOut: Symbol('SignOutAction'),
    SignUp: Symbol('SignUpAction'),
    SocialSignIn: Symbol('SocialSignInAction'),
    RefreshToken: Symbol('RefreshTokenAction'),
    ConfirmAccount: Symbol('ConfirmAccountAction'),
    ResendConfirmationCode: Symbol('ResendConfirmationCodeAction'),
    ChangePassword: Symbol('ChangePasswordAction'),
    ForgotPassword: Symbol('ForgotPasswordAction'),
    ConfirmForgotPassword: Symbol('ConfirmForgotPasswordAction'),
    UpdateUserAttributes: Symbol('UpdateUserAttributesAction'),
    RetrieveUser: Symbol('RetrieveUserAction'),
}

export type {
    AuthStore,
    SignInAction,
    SignOutAction,
    SignUpAction,
    SocialSignInAction,
    RefreshTokenAction,
    ConfirmAccountAction,
    ResendConfirmationCodeAction,
    ChangePasswordAction,
    ForgotPasswordAction,
    ConfirmForgotPasswordAction,
    UpdateUserAttributesAction,
    RetrieveUserAction,
}
