import Avokado, {
    AvokadoAPIError,
    OrganizationRetrieve,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { mapOrganizationToModel } from '../mappers/SDKMapper'
import { Organization } from '../models/Organization'

interface RetrieveOrganizationParams extends Organization.ID {
    role: Organization.Role
}

export interface RetrieveOrganizationAction {
    run(params: RetrieveOrganizationParams): Promise<Organization>
}

@injectable()
export class RetrieveOrganizationActionLive
    implements RetrieveOrganizationAction
{
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: RetrieveOrganizationParams): Promise<Organization> {
        const request: OrganizationRetrieve.Request = {
            organizationId: params.organizationId,
        }
        const response = await this.avokado.organization.retrieve(request)
        return mapOrganizationToModel(response, params.role)
    }
}

@injectable()
export class RetrieveOrganizationActionFake
    implements RetrieveOrganizationAction
{
    async run(params: RetrieveOrganizationParams): Promise<Organization> {
        await sleep(500)

        return {
            id: params,
            name: 'Organization Name',
            role: 'admin',
            currency: 'chf',
            status: 'verified',
            quota: { locations: 7, menus: 7 },
            country: 'CH',
        }
    }
}

@injectable()
export class RetrieveOrganizationActionFail
    implements RetrieveOrganizationAction
{
    async run(params: RetrieveOrganizationParams): Promise<Organization> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
