import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    ButtonGroup,
    Collapse,
    Flex,
    FormControl,
    FormHelperText,
    HStack,
    Input,
    Text,
    Textarea,
    VStack,
} from '@chakra-ui/react'
import { TranslationObject } from 'features/menu/models/TranslationObject'

import useT from 'localization/hooks/useT'
import { useState } from 'react'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'

interface PreviewCellProps {
    size: 'md' | 'lg' | 'xl' | '2xl'
    type: 'compact' | 'extended'
    object: TranslationObject | undefined
    isProcessing: boolean
    submit: (obj: TranslationObject) => Promise<void>
}
export default function PreviewCell({
    size,
    object,
    type,
    isProcessing,
    submit,
}: PreviewCellProps) {
    const t = useT('menutranslation')
    const screen = useScreenBreakpoint()
    const [value, setValue] = useState(object?.translation)
    if (!object) return null
    const { source, translation } = object
    console.log(value)
    return (
        <VStack w="full">
            <HStack w="full" align="stretch">
                <Text fontSize={size} flex={1} fontWeight="semibold">
                    {source}
                </Text>
                <FormControl flex={1}>
                    <HStack w="full" align="center">
                        {type === 'extended' || screen === 'mobile' ? (
                            <Textarea
                                fontSize={size}
                                placeholder={translation ?? source}
                                value={value ?? ''}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        ) : (
                            <Input
                                fontSize={size}
                                placeholder={translation ?? source}
                                value={value ?? ''}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        )}
                        {(translation === undefined || translation === '') && (
                            <WarningIcon alignSelf="center" color="orange" />
                        )}
                        {translation !== undefined && translation !== '' && (
                            <CheckCircleIcon
                                alignSelf="center"
                                color="green.400"
                            />
                        )}
                    </HStack>
                    {object.mode && (
                        <FormHelperText>
                            {t(`form_helper_generation_mode_${object.mode}`)}
                        </FormHelperText>
                    )}
                    <Box w="full" pe={4}>
                        <Collapse in={value !== object?.translation}>
                            <Flex justifyContent="end">
                                <ButtonGroup>
                                    <Button
                                        variant="ghost"
                                        type="submit"
                                        disabled={isProcessing}
                                        onClick={() => setValue(translation)}
                                    >
                                        {t('generic_cancel_button')}
                                    </Button>
                                    <Button
                                        variant="ghost"
                                        type="submit"
                                        isLoading={isProcessing}
                                        onClick={() =>
                                            submit({
                                                ...object,
                                                translation: value,
                                            })
                                        }
                                    >
                                        {t('generic_save_button')}
                                    </Button>
                                </ButtonGroup>
                            </Flex>
                        </Collapse>
                    </Box>
                </FormControl>
            </HStack>
        </VStack>
    )
}
