import { CloseIcon, EmailIcon } from '@chakra-ui/icons'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Heading,
    Text,
    VStack,
} from '@chakra-ui/react'
import useAuthContext from 'features/auth/contexts/AuthContext'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import { useCallback, useState } from 'react'
import { useLocalStorage } from 'react-use'
import Constants from 'utils/constant/Constants'
import LocalStorageKeys from 'utils/constant/LocalStorageKeys'

interface ContactUsFormProps {
    onCancelClick: () => void
}

export function ContactUsForm({ onCancelClick }: ContactUsFormProps) {
    const t = useT('cardreader')
    const [isSending, setIsSending] = useState(false)
    const [hasSentRequest, setHasSentRequest] = useLocalStorage<boolean>(
        LocalStorageKeys.HasSentCardRequestRequest
    )
    const { user } = useAuthContext()

    const sendRequest = useCallback(async () => {
        if (isSending) return
        try {
            setIsSending(true)
            await fetch(`https://${Constants.Host}/api/contact-us`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    category: 'CardReaderInfo',
                    email: user!.email,
                }),
                mode: 'no-cors',
            })
            setHasSentRequest(true)
        } catch (e: unknown) {
        } finally {
            setIsSending(false)
        }
    }, [])

    return (
        <VStack
            flex={1}
            align="flex-start"
            justify="center"
            as={motion.div}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            spacing={12}
        >
            <Heading>{t('contact_us_title')}</Heading>
            {!hasSentRequest && (
                <>
                    <Text>{t('contact_us_description')}</Text>
                    <ButtonGroup alignSelf="flex-end">
                        <Button
                            variant="outline"
                            onClick={onCancelClick}
                            leftIcon={<CloseIcon />}
                        >
                            {t('contact_us_cancel_button')}
                        </Button>
                        <Button
                            leftIcon={<EmailIcon />}
                            onClick={sendRequest}
                            isLoading={isSending}
                        >
                            {t('contact_us_confirm_button')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
            {hasSentRequest && (
                <>
                    <Alert status="success" borderRadius={8}>
                        <AlertIcon />
                        <Box w="full">
                            <AlertTitle>
                                {t('contact_us_request_received_title')}
                            </AlertTitle>
                            <AlertDescription>
                                {t('contact_us_request_received_description')}
                            </AlertDescription>
                        </Box>
                    </Alert>
                    <ButtonGroup alignSelf="flex-end">
                        <Button
                            variant="outline"
                            onClick={onCancelClick}
                            leftIcon={<CloseIcon />}
                        >
                            {t('contact_us_close_button')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </VStack>
    )
}
