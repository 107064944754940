import {
    Box,
    Button,
    HStack,
    PinInput,
    PinInputField,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useNotification } from 'features/notification/hooks/useNotification'
import { motion } from 'framer-motion'
import useT from 'localization/hooks/useT'
import _ from 'lodash'
import { FormEvent, useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useScreenBreakpoint } from 'utils/genericcomponents/ResponsiveComponent'
import {
    useConfirmSignUpFormController,
    useConfirmSignUpFormData,
} from '../hooks/useConfirmSignUpForm'
import { useResendConfirmationCode } from '../hooks/useResendConfirmationCode'

export default function ConfirmSignUpPage() {
    const t = useT('auth')

    const screen = useScreenBreakpoint()

    const notification = useNotification()

    const formData = useConfirmSignUpFormData()
    const controller = useConfirmSignUpFormController(formData)

    const resendConfirmationCode = useResendConfirmationCode()

    const [otpError, setOTPError] = useState(false)

    async function handleSubmit(event?: FormEvent<HTMLDivElement>) {
        event?.preventDefault()
        if (controller.canSubmit) {
            try {
                await controller.submit()
            } catch (error: any) {
                notification({
                    id: 'invalid-code',
                    title: t('confirm_signup_invalid_code_notification_title'),
                    description: t(
                        'confirm_signup_invalid_code_notification_description'
                    ),
                    variant: 'error', // mmmmmh...
                })
            }
        } else {
            setOTPError(formData.confirmationCode.length !== 6)
        }
    }

    useEffect(() => {
        setOTPError(false)

        if (controller.canSubmit) {
            handleSubmit()
        }
    }, [formData.confirmationCode]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box as="form" onSubmit={handleSubmit}>
            <VStack
                as={motion.div}
                align="stretch"
                w="full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                gap={4}
            >
                <Button
                    as={Link}
                    to="/sign-up"
                    display="flex"
                    alignSelf="flex-start"
                    variant="unstyled"
                    size="sm"
                    color="label1"
                    mt={2}
                    leftIcon={<FaArrowLeft />}
                    _hover={{ color: 'accent' }}
                >
                    {t('generic_button_back')}
                </Button>

                <HStack spacing={4}>
                    <PinInput
                        otp
                        isInvalid={otpError}
                        focusBorderColor="primary.200"
                        value={formData.confirmationCode}
                        isDisabled={controller.isProcessing}
                        autoFocus
                        onChange={(e) => formData.setConfirmationCode(e)}
                    >
                        <PinInputField
                            borderRadius="full"
                            borderColor="gray.400"
                        />
                        <PinInputField
                            borderRadius="full"
                            borderColor="gray.400"
                        />
                        <PinInputField
                            borderRadius="full"
                            borderColor="gray.400"
                        />
                        <PinInputField
                            borderRadius="full"
                            borderColor="gray.400"
                        />
                        <PinInputField
                            borderRadius="full"
                            borderColor="gray.400"
                        />
                        <PinInputField
                            borderRadius="full"
                            borderColor="gray.400"
                        />
                    </PinInput>
                </HStack>

                <Button
                    w="full"
                    type="submit"
                    borderRadius="full"
                    h="48px"
                    mt={8}
                    isLoading={controller.isProcessing}
                >
                    {t('confirm_signup_button')}
                </Button>

                <Stack
                    alignItems="center"
                    justifyContent="center"
                    align="stretch"
                    direction={screen === 'mobile' ? 'column' : 'row'}
                    spacing={screen === 'mobile' ? 0 : 2}
                >
                    {resendConfirmationCode.timeout === undefined ? (
                        <>
                            <Text>
                                {t('confirm_signup_no_email_received_label')}
                            </Text>

                            <Button
                                variant="unstyled"
                                flex={1}
                                size="sm"
                                color="label1"
                                display="flex"
                                alignItems="center"
                                justifyContent="start"
                                _hover={{ color: 'accent' }}
                                onClick={resendConfirmationCode.submit}
                                isLoading={resendConfirmationCode.isProcessing}
                                isDisabled={
                                    resendConfirmationCode.timeout !==
                                        undefined || controller.isProcessing
                                }
                            >
                                {t('confirm_signup_resend_code_button')}
                            </Button>
                        </>
                    ) : (
                        <Text fontSize="sm" color="label2" cursor="not-allowed">
                            {t('confirm_signup_resend_code_timeout_label', {
                                count: _.floor(resendConfirmationCode.timeout),
                            })}
                        </Text>
                    )}
                </Stack>
            </VStack>
        </Box>
    )
}
