import { Location } from 'features/location/models/Location'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

export interface UseTablePolicyFormDataHook {
    isEnabled: boolean
    hasChanges: boolean
    policy: Location.Policy.Table | undefined
    allowsInAppOrder: boolean
    requiresUpfrontPayment: boolean
    supportsCourseOrdering: boolean
    supportsInAppPayment: boolean
    supportsAutomaticConfirmation: boolean
    coverCost: number | undefined
    minimumInAppPaymentAmount: number | undefined
    setIsEnabled: Dispatch<SetStateAction<boolean>>
    setAllowsInAppOrder: Dispatch<SetStateAction<boolean>>
    setRequiresUpfrontPayment: Dispatch<SetStateAction<boolean>>
    setSupportsCourseOrdering: Dispatch<SetStateAction<boolean>>
    setSupportsAutomaticConfirmation: Dispatch<SetStateAction<boolean>>
    setSupportsInAppPayment: Dispatch<SetStateAction<boolean>>
    setCoverCost: Dispatch<SetStateAction<number | undefined>>
    setMinimumInAppPaymentAmount: Dispatch<SetStateAction<number | undefined>>
}

export function useTablePolicyFormData(
    table?: Location.Policy.Table
): UseTablePolicyFormDataHook {
    const [isEnabled, setIsEnabled] = useState(table !== undefined)
    const [allowsInAppOrder, setAllowsInAppOrder] = useState(
        table?.allowsInAppOrder ?? false
    )
    const [requiresUpfrontPayment, setRequiresUpfrontPayment] = useState(
        table?.requiresUpfrontPayment ?? false
    )
    const [supportsCourseOrdering, setSupportsCourseOrdering] = useState(
        table?.supportsCourseOrdering ?? false
    )
    const [supportsInAppPayment, setSupportsInAppPayment] = useState(
        table?.supportsInAppPayment ?? false
    )
    const [supportsAutomaticConfirmation, setSupportsAutomaticConfirmation] =
        useState(table?.supportsAutomaticConfirmation ?? false)

    const [coverCost, setCoverCost] = useState(table?.coverCost)

    const [minimumInAppPaymentAmount, setMinimumInAppPaymentAmount] = useState(
        table?.minimumInAppPaymentAmount
    )

    useEffect(() => {
        if (requiresUpfrontPayment) {
            setSupportsAutomaticConfirmation(false)
        }
    }, [requiresUpfrontPayment])

    useEffect(() => {
        if (!supportsInAppPayment) {
            setRequiresUpfrontPayment(false)
        }
    }, [supportsInAppPayment])

    const policy: Location.Policy.Table | undefined = useMemo(() => {
        return isEnabled
            ? {
                  allowsInAppOrder: true /* currently not supported */,
                  requiresUpfrontPayment,
                  supportsCourseOrdering,
                  supportsInAppPayment,
                  supportsAutomaticConfirmation,
                  coverCost,
                  minimumInAppPaymentAmount,
              }
            : undefined
    }, [
        isEnabled,
        allowsInAppOrder,
        requiresUpfrontPayment,
        supportsCourseOrdering,
        supportsInAppPayment,
        coverCost,
        minimumInAppPaymentAmount,
        supportsAutomaticConfirmation,
    ])

    const hasChanges = useMemo(() => !_.isEqual(policy, table), [policy, table])

    return {
        isEnabled,
        hasChanges,
        policy,
        allowsInAppOrder,
        requiresUpfrontPayment,
        supportsCourseOrdering,
        supportsInAppPayment,
        coverCost,
        minimumInAppPaymentAmount,
        supportsAutomaticConfirmation,
        setIsEnabled,
        setAllowsInAppOrder,
        setRequiresUpfrontPayment,
        setSupportsCourseOrdering,
        setSupportsInAppPayment,
        setCoverCost,
        setMinimumInAppPaymentAmount,
        setSupportsAutomaticConfirmation,
    }
}
