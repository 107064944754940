import Avokado, {
    AvokadoAPIError,
    LocationCardReaderAttach,
} from '@avokadoapp/avokado-ts'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'
import { Location } from '../../location/models/Location'

interface AttachCardReaderParams extends Location.ID {
    label: string
    registrationCode: string
}

export interface AttachCardReaderAction {
    run(params: AttachCardReaderParams): Promise<void>
}

@injectable()
export class AttachCardReaderActionLive implements AttachCardReaderAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: AttachCardReaderParams): Promise<void> {
        const request: LocationCardReaderAttach.Request = params
        await this.avokado.location.cardReaderAttach(request)
    }
}

@injectable()
export class AttachCardReaderActionFake implements AttachCardReaderAction {
    async run(params: AttachCardReaderParams): Promise<void> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class AttachCardReaderActionFail implements AttachCardReaderAction {
    async run(params: AttachCardReaderParams): Promise<void> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
