import { SVGProps } from 'react'

export function PeanutsIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Peanuts"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M199.96 100.32c-.78 23.55-7.6 44.97-23.45 63.66-28.84 34.02-73.35 44.91-113.92 28.52-32.21-13.01-52.03-37.18-59.81-70.01C-2.6 99.8-.07 77.95 9.54 56.85c5.68-12.47 14.06-23.29 24.7-32.62C53.42 7.41 76.09-.44 101.92.02c16.8.3 33.25 3.54 47.46 12.26 28.37 17.41 45.3 42.13 49.6 74.22.39 2.93.72 5.88.95 8.83.13 1.66.02 3.33.02 4.99zm-99.85 63.92c18.19-.02 27.84-7.71 30.97-24.62 2.11-11.37-.31-22.14-5.16-32.43-6.08-12.89-7.83-25.74-2.17-39.23 2.2-5.25 1.89-10.98.16-16.39-5.59-17.47-27.01-22.42-39.17-11.84-8.76 7.62-12.27 17.44-8.38 28.51 3.89 11.07 4.73 21.89-.06 33.02-4.04 9.38-7.25 18.88-8 29.33-1.49 20.74 10.12 33.66 31.82 33.64z" />
            <path d="M96.96 144.22c-.09 2.53 1.12 5.7-3.48 5.67-5.36-.04-7.73-2.45-7.99-8.35-.15-3.47 1.41-4.92 4.8-4.97 5.21-.08 6.51 1.34 6.67 7.65zM103.69 142.5c-.81-4 .98-6.13 5.62-5.99 3.25.1 5.09 1.29 5.09 4.84 0 5.5-2.16 8.22-6.77 8.16-3.68-.05-3.96-.51-3.95-7.01zM96.97 122.65c-.23 3.74.18 7.85-5.36 7.78-2.85-.04-4.6-1.69-3.94-4.42.92-3.8-.24-9.49 5.43-10.11 4.66-.5 3.5 3.97 3.87 6.75zM103.5 122.65c.23 3.74-.18 7.85 5.36 7.78 2.85-.04 4.6-1.69 3.94-4.42-.92-3.8.24-9.49-5.43-10.11-4.66-.5-3.5 3.97-3.87 6.75zM97.02 50.47c-.09-2.53 1.12-5.7-3.48-5.67-5.36.04-7.73 2.45-7.99 8.35-.15 3.47 1.41 4.92 4.8 4.97 5.21.08 6.51-1.34 6.67-7.65zM103.75 52.19c-.81 4 .98 6.13 5.62 5.99 3.25-.1 5.09-1.29 5.09-4.84 0-5.5-2.16-8.22-6.77-8.16-3.68.05-3.96.51-3.95 7.01zM97.03 72.04c-.23-3.74.18-7.85-5.36-7.78-2.85.04-4.6 1.69-3.94 4.42.92 3.8-.24 9.49 5.43 10.11 4.66.5 3.5-3.97 3.87-6.75zM103.56 72.04c.23-3.74-.18-7.85 5.36-7.78 2.85.04 4.6 1.69 3.94 4.42-.92 3.8.24 9.49-5.43 10.11-4.66.5-3.5-3.97-3.87-6.75zM96.74 97.84c-.23-3.74.18-7.85-5.36-7.78-2.85.04-4.6 1.69-3.94 4.42.92 3.8-.24 9.49 5.43 10.11 4.66.5 3.5-3.97 3.87-6.75zM103.27 97.84c.23-3.74-.18-7.85 5.36-7.78 2.85.04 4.6 1.69 3.94 4.42-.92 3.8.24 9.49-5.43 10.11-4.66.5-3.5-3.97-3.87-6.75z" />
        </svg>
    )
}
