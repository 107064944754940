import { Location } from 'features/location/models/Location'
import _ from 'lodash'

export function mapModelToCapabilities(
    capabilities?: Location.Capability[]
): number | undefined {
    if (capabilities === undefined) {
        return undefined
    }

    return _.sum(capabilities)
}
