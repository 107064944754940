import { SVGProps } from 'react'

export function LupinIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Lupin"
            viewBox="0 0 200 200"
            height="100%"
            {...props}
        >
            <path d="M.03 100.01C-.65 72.58 9.11 48.66 29 28.52 52.9 4.31 82.86-3.44 116.61 1.34c45.7 6.47 76.46 43.48 82.28 83.88 3.67 25.45-1.78 49.09-16.29 70.62-9.37 13.91-21.98 24.67-37.45 32.82-25.95 13.68-52.75 14.46-79.77 5.14-17.86-6.16-32.52-17.37-44.07-32.02C6.89 143.48-.47 123.11.03 100.01zm153.11 16.93c.15-8.71-3.15-12.99-11.78-13.32-7.73-.3-14.97-1.59-22.15-4.34-4.69-1.79-9.78-1.88-13 3.04-6.89 10.54-4.14 28.87 11.95 32.41 3.61.79 7.19 1.85 10.85 2.28 13.91 1.64 23.89-6.75 24.12-20.07zM92.19 98.47c12.12 1.38 20.64-7.82 21.77-18.81.73-7.1-3.13-11.62-10.65-12.45-7.58-.84-14.99-1.89-21.23-6.94-3.44-2.78-8.17-3.15-11.97-.21-10.26 7.94-9.22 25.98 1.83 32.56 6.22 3.7 12.97 6.06 20.25 5.86zm-23.83 42.2c1.98-.24 5.08-.64 8.18-1 12.47-1.45 17.85-5.83 20.41-17.34 1.6-7.19-1.4-13.34-7.01-17.87-4.14-3.35-8.47-.33-12.48 1.53-2.52 1.17-5.25 1.45-8.01 1.44-4.31-.01-8.62-.07-12.92-.12-4.99-.06-8.42 2.22-9.52 6.71-3.43 14 4.32 26.18 21.37 26.66z" />
            <path d="M129.41 133.12c-7.18-.39-15.87-1.1-20.89-9.88-3.22-5.64-3.16-13.89.76-18.98 1.53-1.98 3.57-2.94 6.12-2.28 3.03.79 2.14 2.91 1.81 4.97-.94 5.88 2.19 9.25 9.74 10.71 5.49 1.06 9.31-1.04 10.66-6.55.71-2.93 2.58-2.84 4.74-2.8 3.79.06 6.23 1.71 7.08 5.38 2.53 10.93-5.56 19.58-20.02 19.42zM91.52 95.18c-4.57-.17-10.95-2.02-16.77-5.13-8.33-4.46-10.31-18.52-3.84-24.92 1.92-1.9 4.2-2.91 6.86-1.9 3.24 1.24.97 3.29.61 5.12-1.03 5.3 2.46 7.84 6.95 9.49 6.4 2.34 11.38 1.36 13.11-3.22 1.4-3.72 3.4-4.6 7-3.56 3.68 1.06 5.39 3.31 5.08 7.06-.89 10.77-7.04 17.08-19 17.06zM49.65 119.2c.15-.74.32-2.6.92-4.33.86-2.46 2.66-4.22 5.6-4.05 3.07.18 2.54 2.71 2.97 4.62 1.19 5.27 4.4 6.97 11.66 6.29 5.46-.51 9.3-3.73 9.07-8.23-.14-2.77.12-4.68 3.34-5.41 3.2-.72 5.74-.08 7.65 2.58 5.04 7 1.97 19.34-5.92 23.29-8.26 4.13-16.99 3.84-25.62 1.61-5.51-1.43-9.58-8.5-9.67-16.36z" />
        </svg>
    )
}
