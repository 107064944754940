import Avokado, {
    AvokadoAPIError,
    CardReaderConnectionTokenCreate,
} from '@avokadoapp/avokado-ts'
import { Location } from 'features/location/models/Location'
import AvokadoClient from 'infra/di/factories/AvokadoClient'
import { inject, injectable } from 'inversify'
import { sleep } from 'utils/asyncawait/sleep'

type InitCardReaderParams = Location.ID

export interface InitCardReaderAction {
    run(params: InitCardReaderParams): Promise<string>
}

@injectable()
export class InitCardReaderActionLive implements InitCardReaderAction {
    @inject(AvokadoClient.Private) private avokado!: Avokado

    async run(params: InitCardReaderParams): Promise<string> {
        const request: CardReaderConnectionTokenCreate.Request = params
        const response = await this.avokado.cardReader.connectionTokenCreate(
            request
        )
        return response.secret
    }
}

@injectable()
export class InitCardReaderActionFake implements InitCardReaderAction {
    async run(params: InitCardReaderParams): Promise<string> {
        await sleep(2000)
        throw new Error('Unimplemented')
    }
}

@injectable()
export class InitCardReaderActionFail implements InitCardReaderAction {
    async run(params: InitCardReaderParams): Promise<string> {
        await sleep(2000)
        throw new AvokadoAPIError('Internal Server Error', 500, 'private')
    }
}
