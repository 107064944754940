import { interfaces } from 'inversify'
import { EventClient } from './EventClient'

interface _EventDependencies {
    Client: interfaces.ServiceIdentifier<EventClient>
}

export const EventDependencies: _EventDependencies = {
    Client: Symbol('EventClient'),
}

export type { EventClient }
