import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { NextStepCard } from './NextStepCard'

export function MenuNextStep() {
    const { t } = useTranslation()

    return (
        <NextStepCard
            title={t('Create your first menu')}
            description={t(
                'A menu is an essential component of your restaurant management on Avokado'
            )}
            image="/home-menu.png"
            imageFit="cover"
        >
            <Button as={Link} to="menus/new">
                {t('Create')}
            </Button>
        </NextStepCard>
    )
}
